import { Button, Typography } from '@material-ui/core'
import React from 'react'

function NoProviderComponent({ goToRequiredStep, clinicalPracticeNumber }) {
    return (
        <div className="no-provider-container">
            <Typography>Please choose another date or modify your search!</Typography>

            <Typography>
                If you need assistance, please call us at {clinicalPracticeNumber}
            </Typography>

            <Button
                onClick={() => goToRequiredStep(2)} //go to the care selection page
                style={{
                    background: '#5571C6',
                    color: '#fff',
                    height: 35,
                    width: 300,
                }}>
                Modify search criteria
            </Button>
        </div>
    )
}

export default NoProviderComponent
