import React, { PureComponent, useEffect } from 'react'
import debounce from 'lodash/debounce'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import './Search.scss'

export default class Search extends PureComponent {
    debouncedOnChange = debounce(this.props.onChange, 300, {
        leading: true,
        trailing: true,
    })

    handleChange = (event) => {
        const { value } = event.target
        this.props.onChange(value)
    }

    render() {
        const { className, placeholder, value } = this.props

        return (
            <div className={`search_input ${className}`}>
                <SearchIcon />
                <InputBase
                    className={`searchInput ${
                        className && `${className}_input`
                    }`}
                    placeholder={placeholder}
                    inputProps={{
                        'aria-label': { placeholder },
                    }}
                    onChange={this.handleChange}
                    value={value}
                />
            </div>
        )
    }
}
