export const SET_USER = 'SET_USER'
export const SET_SESSION = 'SET_SESSION'
export const SET_LOADER = 'SET_LOADER'
export const SET_PATIENT_DATA = 'SET_PATIENT_DATA'
export const SET_PATIENT_DATA_AFTER_CHANGE = 'SET_PATIENT_DATA_AFTER_CHANGE'
export const SET_ERROR = 'SET_ERROR'
export const SET_ERROR_MSG = 'SET_ERROR_MSG'
export const SET_OTP_ERROR = 'SET_OTP_ERROR'
export const LOAD_SPECIALITIES = 'LOAD_SPECIALITIES'
export const SET_NEW_USER = 'SET_NEW_USER'
export const HANDLE_BACK = 'HANDLE_BACK'
export const SET_RESET_DATA = 'SET_RESET_DATA'
export const SET_EMAIL_VALID = 'SET_EMAIL_VALID'
export const SET_IS_USER_EXSIST = 'SET_IS_USER_EXSIST'
export const SET_MAX_ATTEMPT = 'SET_MAX_ATTEMPT'
export const SET_ANOTHER_WAY_OTP_FIELD = 'SET_ANOTHER_WAY_OTP_FIELD'
export const LOGOUT = 'LOGOUT'
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'

//appointments
export const SET_SPECIALITIES = 'SET_SPECIALITIES'
export const SET_SELECTED_PROVIDER_DETAILS = 'SET_SELECTED_PROVIDER_DETAILS'
export const SET_CLINIC_LIST = 'SET_CLINIC_LIST'
export const SET_SEARCH_PROVIDER_DATA = 'SET_SEARCH_PROVIDER_DATA'
export const SET_APPOINTMENT_FLOW_TYPE = 'SET_APPOINTMENT_FLOW_TYPE'
export const SET_BOOK_APPOINTMENT_DATA = 'SET_BOOK_APPOINTMENT_DATA'
export const SET_APPOINTMENT_LIST = 'SET_APPOINTMENT_LIST'
export const SET_APPOINTMENT_ERROR = 'SET_APPOINTMENT_ERROR'
export const SET_IS_RESCHEDULE_APPOINTMENT = 'SET_IS_RESCHEDULE_APPOINTMENT'
export const SET_SELECTED_APPOINTMENT_DATA = 'SET_SELECTED_APPOINTMENT_DATA'
export const RESET_APPOINTMENTS_DATA = 'RESET_APPOINTMENTS_DATA'
export const SET_APPOINTMENT_DETAILS = 'SET_APPOINTMENT_DETAILS'
export const SET_SELECTED_PATIENT_DETAILS = 'SET_SELECTED_PATIENT_DETAILS'
export const SET_SELECTED_MINOR_DETAILS = 'SET_SELECTED_MINOR_DETAILS'
export const SET_CLINIC_DETAILS = 'SET_CLINIC_DETAILS'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_CONFIRMED_APPOINTMENT_START = 'SET_CONFIRMED_APPOINTMENT_START'

//Provider
export const SET_TIMEOFFS_LIST = 'SET_TIMEOFFS_LIST'
export const SET_PROVIDER_DETAILS = 'SET_PROVIDER_DETAILS'
export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST'
export const SET_CALENDAR_DATES = 'SET_CALENDAR_DATES'
export const SET_CALENDAR_SLOTS = 'SET_CALENDAR_SLOTS'
export const SET_SEARCH_PROVIDER_LIST = 'SET_SEARCH_PROVIDER_LIST'
export const UPDATE_SEARCH_PROVIDER_STATUS = 'UPDATE_SEARCH_PROVIDER_STATUS'
export const SET_PROVIDER_PROFILE = 'SET_PROVIDER_PROFILE'
export const SET_EDIT_PROVIDER_PROFILE = 'SET_EDIT_PROVIDER_PROFILE'
export const RESET_PROVIDER_PROFILE = 'RESET_PROVIDER_PROFILE'
export const SET_SPECIALITY_LIST = 'SET_SPECIALITY_LIST'
export const SET_CLIENT_LIST = 'SET_CLIENT_LIST'
export const SET_ACTIVE_PROVIDER_LIST = 'SET_ACTIVE_PROVIDER_LIST'
export const TIMEOFFS_SORT_DATA = 'TIMEOFFS_SORT_DATA'

//CLINIC
export const SET_SEARCH_CLINIC_LIST = 'SET_SEARCH_CLINIC_LIST'
export const UPDATE_SEARCH_CLINIC_STATUS = 'UPDATE_SEARCH_CLINIC_STATUS'
export const SET_CLINIC_DETAIL = 'SET_CLINIC_DETAIL'
export const RESET_CLINIC_DETAIL = 'RESET_CLINIC_DETAIL'
export const SET_EDIT_CLINIC_DETAIL = 'SET_EDIT_CLINIC_DETAIL'

//Clinic Admin
export const SET_CLINIC_ADMIN_DETAIL = 'SET_CLINIC_ADMIN_DETAIL'
export const SET_CLINIC_ADMIN_PROFILE = 'SET_CLINIC_ADMIN_PROFILE'
export const SET_CLINIC_ADMIN_PROFILE_EDIT = 'SET_CLINIC_ADMIN_PROFILE_EDIT'

// Appointment list
export const SET_APPOINTMENTLIST = 'SET_APPOINTMENTLIST'
export const SET_APPOINTMENTLIST_COUNT = 'SET_APPOINTMENTLIST_COUNT'
export const SET_MARK_CONSULTATION_STATUS = 'SET_MARK_CONSULTATION_STATUS'

//menu
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'

//Patient
export const SET_PATIENT_DETAIL = 'SET_PATIENT_DETAIL'
export const SET_PATIENT_PROFILE = 'SET_PATIENT_PROFILE'
export const RESET_PATIENT_PROFILE = 'RESET_PATIENT_PROFILE'

export const SET_EDIT_PATIENT_PROFILE = 'SET_EDIT_PATIENT_PROFILE'

//TM2UAdmin
export const SET_TM2UADMIN_PROFILE = 'SET_TM2UADMIN_PROFILE'
export const SET_EDIT_TM2UADMIN_PROFILE = 'SET_EDIT_TM2UADMIN_PROFILE'

export const SET_PATIENT_LIST = 'SET_PATIENT_LIST'

export const SET_SEARCH_PATIENT_LIST = 'SET_SEARCH_PATIENT_LIST'
export const UPDATE_SEARCH_PATIENT_STATUS = 'UPDATE_SEARCH_PATIENT_STATUS'
export const SET_PATIENTLIST = 'SET_PATIENTLIST'
export const SET_ADDPATIENTLIST = 'SET_ADDPATIENTLIST'
export const RESET_TM2U_PROFILE = 'RESET_TM2U_PROFILE'

//Fax
export const SET_FAX_NUMBERS = 'SET_FAX_NUMBERS'
export const SET_FAX_NUMBER = 'SET_FAX_NUMBER'
export const SET_FAX_LIST = 'SET_FAX_LIST'
export const SET_FAX_COUNT = 'SET_FAX_COUNT'
export const SET_FAX_PAGE_CHANGE = 'SET_FAX_PAGE_CHANGE'
export const SET_FAX_SEARCH_CRITERIA = 'SET_FAX_SEARCH_CRITERIA'
export const SET_FAX_LOADING = 'SET_FAX_LOADING'

//Notes
export const SET_NOTES_LIST = 'SET_NOTES_LIST'
export const SET_ADD_NOTES = 'SET_ADD_NOTES'
export const SET_NOTES_PAGE_CHANGE = 'SET_NOTES_PAGE_CHANGE'

//Forms
export const SET_FORMS_ACTIVE_TAB = 'SET_FORMS_ACTIVE_TAB'
export const SET_FORMS_LIST = 'SET_FORMS_LIST'
export const SET_FORMS_COUNT = 'SET_FORMS_COUNT'
export const SET_FORMS_PAGE_CHANGE = 'SET_FORMS_PAGE_CHANGE'
export const SET_FORMS_SEARCH_CRITERIA = 'SET_FORMS_SEARCH_CRITERIA'
export const SET_FORMS_LOADING = 'SET_FORMS_LOADING'
export const SET_FORMS_DETAIL_ID = 'SET_FORMS_DETAIL_ID'
export const SET_FORMS_SEARCH_DATE = 'SET_FORMS_SEARCH_DATE'
export const SET_FORMS_SEARCH_FILTER_STRING = 'SET_FORMS_SEARCH_FILTER_STRING'
export const SET_FORMS_DETAIL_PAGE_DATA = 'SET_FORMS_DETAIL_PAGE_DATA'
export const RESET_FORMS_STATE = 'RESET_FORMS_STATE'
export const UPDATE_TIMEOFFS_STATUS = 'UPDATE_TIMEOFFS_STATUS'
//Admin
export const SET_ADMIN_PROVIDER_LIST = 'SET_ADMIN_PROVIDER_LIST'

// staff
export const SET_STAFF_LIST = 'SET_STAFF_LIST'
export const SET_SEARCH_STAFF_LIST = 'SET_SEARCH_STAFF_LIST'

export const SET_STAFF_PROFILE = 'SET_STAFF_PROFILE'
export const SET_STAFF_EDIT_PROFILE = 'SET_STAFF_EDIT_PROFILE'
export const SET_UPDATE_STAFF_STATUS = 'SET_UPDATE_STAFF_STATUS'
export const SET_STAFF_RESULT_LIST = 'SET_STAFF_RESULT_LIST'
export const SET_STAFF_PROFILE_BY_ID = 'SET_STAFF_PROFILE_BY_ID'
//Form Builder
export const SET_FORM_TYPES = 'SET_FORM_TYPES'
export const SET_SUB_FORM_TYPES = 'SET_SUB_FORM_TYPES'
export const SET_FORM_LIST = 'SET_FORM_LIST'
export const SET_SELECTED_FORM_ID = 'SET_SELECTED_FORM_ID'
export const SET_FORM_DATA = 'SET_FORM_DATA'
export const RESET_FORM_BUILDER = 'RESET_FORM_BUILDER'
export const SET_FORM_TYPE = 'SET_FORM_TYPE'

//Documents

export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST'

export const SET_REFRESH_NOTF_BADGE_COUNT = 'SET_REFRESH_NOTF_BADGE_COUNT'

//Macros
export const SET_MACRO_LIST = 'SET_MACRO_LIST'
export const SET_TOTAL_MACRO_COUNT = 'SET_TOTAL_MACRO_COUNT'

//Clnical Notes
export const SET_CLINICAL_NOTES_LIST = 'SET_CLINICAL_NOTES_LIST'
export const SET_CLINICAL_NOTES_COUNT = 'SET_CLINICAL_NOTES_COUNT'

//common
export const COMMON_MASTER_DATA = 'COMMON_MASTER_DATA'
export const SHOW_CONSULTATION_LIST = 'SHOW_CONSULTATION_LIST'
export const GET_LIST_ENCOUNTER_QUEUE = 'GET_LIST_ENCOUNTER_QUEUE'

export const GET_TOKEN_VIDEO_PROVIDER = 'GET_TOKEN_VIDEO_PROVIDER'
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
export const ACTION_ADMIT = 'ACTION_ADMIT'
export const ACTION_END_ROOM = 'ACTION_END_ROOM'
export const ACTION_KICK_PARTICIPANT = 'ACTION_KICK_PARTICIPANT'
export const SET_ENCOUNTER_MEETING_ID = 'SET_ENCOUNTER_MEETING_ID'
export const SET_LOADING_ADMIT = 'SET_LOADING_ADMIT'
export const SET_LOADING_END_ROOM = 'SET_LOADING_END_ROOM'
export const SET_HEADER_TAB = 'SET_HEADER_TAB'
