import React from 'react'

import {
    Grid,
    Typography,
    FormLabel,
} from '@material-ui/core'
import { capitalizeFirstLetter } from '../../../../utilities'
import moment from 'moment'
const PatientPrimaryInsurer = (props) => {
    const { patientProfile } = props
    return (
        <>
            <Grid container className="borderBottom">
                <Grid item xs={6} lg={6} className="pTB3">
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="card-title-align">
                            <Typography
                                variant="subtitle1"
                                className="card-title">
                                Primary Insurer
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title">
                                    First Name
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .primaryInsurer
                                            ?.first_name ||
                                            '',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Last Name
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .primaryInsurer
                                            ?.last_name ||
                                            '',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} className="dFlex p5">
                        <Grid
                            item
                            xs={12} md={5} lg={4} 
                        >
                            <Typography className="detail-item-title ">
                                Last Name
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="ml1">
                            <Typography className="detail-item-title colorDark">
                            {capitalizeFirstLetter(patientProfile.primaryInsurer?.last_name || '')}
                            </Typography>
                        </Grid>
                    </Grid> */}

                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Date of Birth
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="detail-item-title colorDark">
                                    {/* {patientProfile.primaryInsurer?.dob} */}
                                    {patientProfile
                                        .primaryInsurer?.dob
                                        ? moment(
                                              patientProfile
                                                  .primaryInsurer
                                                  ?.dob,
                                          ).format(
                                              'MM/DD/YYYY',
                                          )
                                        : ''}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Gender
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .primaryInsurer
                                            ?.gender || '',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Patient Relation to
                                    Insured
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .primaryInsurer
                                            ?.patient_relation_to_insured ||
                                            '',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default PatientPrimaryInsurer
