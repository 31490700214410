import React, { useState } from 'react'

import {
    Grid,
    Typography,
    Button,
    FormLabel,
} from '@material-ui/core'
const PatientSecurityQuestions = (props) => {
    const { patientProfile } = props

    return (
        <>
            <Grid container className="borderBottom">
                <Grid item xs={12} lg={12} className="pTB3">
                    <Grid
                        item
                        xs={12}
                        md={5}
                        lg={4}
                        className="card-title-align">
                        <Typography
                            variant="subtitle1"
                            className="card-title">
                            Security Questions
                        </Typography>
                    </Grid>

                    <Grid item xs={12} container>
                        <Grid item xs={5}>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                className="mb1">
                                <FormLabel
                                    variant="subtitle2"
                                    className="detail-item-title">
                                    Security Question 1
                                </FormLabel>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        className="detail-item-title colorDark colorDark mt05">
                                        {
                                            patientProfile
                                                ?.security_qus_ans
                                                ?.question1
                                        }
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        className="detail-item-title colorDark colorDark">
                                        {
                                            patientProfile
                                                ?.security_qus_ans
                                                ?.answer1
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid
                                item
                                xs={12}
                                className="mb1">
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    lg={4}>
                                    <FormLabel
                                        variant="subtitle2"
                                        className="detail-item-title">
                                        Security Question 2
                                    </FormLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    lg={8}>
                                    <Typography
                                        variant="subtitle2"
                                        className="detail-item-title colorDark colorDark mt05">
                                        {
                                            patientProfile
                                                ?.security_qus_ans
                                                ?.question2
                                        }
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        className="detail-item-title colorDark colorDark">
                                        {
                                            patientProfile
                                                ?.security_qus_ans
                                                ?.answer2
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={5} className="mb1">
                            <Grid
                                item
                                xs={12}
                                md={5}
                                lg={4}>
                                <FormLabel
                                    variant="subtitle2"
                                    className="detail-item-title">
                                    Security Question 3
                                </FormLabel>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                lg={8}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark colorDark mt05">
                                    {
                                        patientProfile
                                            ?.security_qus_ans
                                            ?.question3
                                    }
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark colorDark">
                                    {
                                        patientProfile
                                            ?.security_qus_ans
                                            ?.answer3
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default PatientSecurityQuestions
