import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import { UserRoles } from '../../constants/user-roles'
import { SelectEl, TabsViewEl } from '../../shared/elements'
import AppointmentList from '../AppointmentList'
import { TABS, INTERVALS } from './constant'

function AppointmentListCard(props) {
    const [activeTab, setActiveTab] = React.useState(
        TABS[0],
    )
    const [interval, setInterval] = React.useState(
        INTERVALS[INTERVALS.length - 1].key,
    )

    return (
        <Grid container direction="column">
            <Grid item xs={12}>
                <TabsViewEl
                    data={TABS}
                    active={activeTab}
                    onChange={(tab) => setActiveTab(tab)}
                    action={
                        <Grid
                            container
                            justifyContent="flex-end">
                            {props.role !==
                                UserRoles.PATIENT && (
                                <Grid item xs={3}>
                                    <Paper elevation="0">
                                        <SelectEl
                                            name="intervals"
                                            placeholder="Select Interval"
                                            value={interval}
                                            fullWidth
                                            onChange={(
                                                event,
                                            ) =>
                                                setInterval(
                                                    event
                                                        .target
                                                        .value,
                                                )
                                            }
                                            options={
                                                INTERVALS
                                            }
                                        />
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    }>
                    <AppointmentList
                        type={activeTab.key}
                        interval={interval}
                        role={props.role}
                        userId={props.userId}
                    />
                </TabsViewEl>
            </Grid>
        </Grid>
    )
}

export default AppointmentListCard
