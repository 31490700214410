import { USPhoneRegex } from './regex'
import moment from 'moment'
import { config } from '../constants'
import crypto, { createDecipheriv } from 'crypto'
import base64url from 'base64url' // Import base64url library
const roleOptions = {
    tm2u_super_admin: 'Super Admin',
    tm2u_admin: 'Admin',
    billing_admin: 'Billing Admin',
}

export const resetLocalStorage = () => {
    localStorage.clear()
}

export const getIsLoggedIn = () => !!localStorage.getItem('access_token')

export const getAuthToken = () => localStorage.getItem('access_token')

export const getIsPatientDetailCompleted = () =>
    localStorage.getItem('isProfileComplete') === 'true'

export const getLoggedInUserRole = () => localStorage.getItem('role')

export const getLoggedInUserId = () => localStorage.getItem('userId')

export const getLoggedInUserName = () => localStorage.getItem('name')

export const capitalizeFirstLetter = (str = '') => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}

export const maskPhoneNumber = (str = '') => {
    const splittedNumber = str.substr(str.length - 10).match(USPhoneRegex)
    return splittedNumber?.length === 4
        ? `(${splittedNumber[1]}) ${splittedNumber[2]} ${splittedNumber[3]}`
        : ''
}

export function formatPhoneNumber(phoneNumber) {
    // Remove non-digit characters from input
    const cleaned = phoneNumber.replace(/\D/g, '')

    // Format the phone number
    const formatted = cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')

    return formatted
}

export const maskPhoneNumberAlt = (str = '') => {
    const splittedNumber = str.substr(str?.length - 10).match(USPhoneRegex)
    return splittedNumber?.length === 4
        ? `${splittedNumber[1]} ${splittedNumber[2]} ${splittedNumber[3]}`
        : ''
}

export const formatSlotTime = (slot) => {
    return slot ? moment(slot, 'HH:mm:ss').format('hh:mm A') : ''
}

export const getLocalDateTime = (date, time = '', format = 'MM/DD/YYYY') => {
    return moment.utc(`${date} ${time}`.trim()).local().format(format)
}

export const getQueryParams = (searchString, qsArr = []) => {
    const urlSearchParams = new URLSearchParams(searchString)
    const queryStrings = {}
    qsArr.forEach((qsa) => {
        queryStrings[qsa] = urlSearchParams.get(qsa)
    })
    return queryStrings
}

export const decryptUrl = (encryptedIv, encryptedData) => {
    try {
        if (config.KEY) {
            let iv = Buffer.from(encryptedIv, 'hex')
            let encryptedText = Buffer.from(encryptedData, 'hex')
            let decipher = createDecipheriv('aes-256-cbc', Buffer.from(config.KEY), iv)
            let decrypted = decipher.update(encryptedText)
            decrypted = Buffer.concat([decrypted, decipher.final()])

            return decrypted.toString()
        }
    } catch (e) {
        console.log('error===>', e)
        return ''
    }
}

export const bytesToMB = (bytes, roundTo) =>
    roundTo ? (bytes / (1024 * 1024)).toFixed(roundTo) : bytes / (1024 * 1024)

export const validatePdfFile = (files) => files.every((f) => f.type.includes('pdf'))

export const calculateAge = (dob) => moment().diff(dob, 'years')

export const getTZoffset = () => {
    const pad = (number, length) => {
        var str = '' + number
        while (str.length < length) {
            str = '0' + str
        }
        return str
    }
    let offset = new Date().getTimezoneOffset()
    offset =
        (offset < 0 ? '+' : '-') + // Note the reversed sign!
        pad(parseInt(Math.abs(offset / 60)), 2) +
        ':' +
        pad(Math.abs(offset % 60), 2)

    return offset
}

export const sortTimeSlot = (slots) => {
    const newArr = slots.map((s) => ({
        ...s,
        slot: getLocalDateTime(moment(s.date).format('YYYY-MM-DD'), s.slot, 'HH:mm:ss'),
    }))

    const sortedSlot = newArr.sort((slotA, slotB) => {
        const slotATime = moment(`${slotA.date} ${slotA.slot}`.trim())
        const slotBTime = moment(`${slotB.date} ${slotB.slot}`.trim())
        const diff = moment(slotATime).diff(moment(slotBTime))
        return diff
    })

    return sortedSlot
}

export const formatDate = (date, format = 'MM/DD/YYYY') => moment(date).format(format)

export const getNameFormat = (firstName, lastName) =>
    `${firstName} ${lastName}`
        .toString()
        .replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())

// Need to move in constant file @anand
export const maxCharLength = 40
export const maxCharLengthZip = 5

export const formatPhone = (phone) => {
    if (!phone) return ''
    return phone
    // if (phone && phone !== '') {
    //     const char = {
    //         0: '(',
    //         3: ') ',
    //         6: ' ',
    //     }
    //     let number = ''
    //     phone = phone.replace(/\D/g, '')
    //     for (let i = 0; i < phone.length; i++) {
    //         number += (char[i] || '') + phone[i]
    //     }
    //     return number
    // }
    // return ''
}

// masking underscore '_' and replace with ' ' ,
export const formatRoleName = (role_name) => {
    if (role_name) {
        /* return role_name
            .split('_')
            .map(
                (word) =>
                    word.charAt(0).toUpperCase() +
                    word.slice(1),
            )
            .join(' '); */
        return roleOptions[role_name]
    }
    return ''
}

export const getCountryCode = (phone) => {
    //check dev qa and uat and return value
    //for now hardcoded for testing
    return process.env.REACT_APP_COUNTRY_CODE
}

export function returnLevelOf(value) {
    if (value == 0) return 'not at all'
    if (value >= 1 && value <= 3) return 'mildly'
    if (value >= 4 && value <= 6) return 'moderately'
    if (value >= 7 && value <= 9) return 'markedly'
    if (value == 10) return 'extremely'
}

// Function to decrypt data
export const decryptData = (encodedData) => {
    // Decode the base64url encoded payload
    const decodedPayload = base64url.decode(encodedData)

    // Split the payload into IV, encrypted data, and authentication tag
    const [ivHex, encryptedData, authenticationTagHex] = decodedPayload.split(':')

    // Convert IV and authentication tag to Buffer
    const iv = Buffer.from(ivHex, 'hex')
    const authenticationTag = Buffer.from(authenticationTagHex, 'hex')
    // Create a decipher with AES-256-GCM
    const decipher = crypto.createDecipheriv(
        'aes-256-gcm',
        Buffer.from('da0b2f07e85d2e5944bf4a101c7cbd76a9af3be201f53ce9e81547878ef37e3d', 'hex'),
        iv,
    )

    // Set the authentication tag
    decipher.setAuthTag(authenticationTag)

    // Decrypt the data
    let decryptedData = decipher.update(encryptedData, 'hex', 'utf-8')
    decryptedData += decipher.final('utf-8')

    // Parse the decrypted JSON string
    const decryptedContentInfo = JSON.parse(decryptedData)

    return decryptedContentInfo
}
