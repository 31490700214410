export const clinicAdminProfileForm = [
    {
        sectionTitle: 'Profile',
        mainGrid: 12,
        isSpacing: false,
        fieldItems: [
            {
                id: 'first_name',
                label: 'First Name',
                name: 'first_name',
                placeHolder: 'First Name',
                inputType: 'textField',
                value: '',
                required: true,
                readOnly: false,
            },
            {
                id: 'last_name',
                label: 'Last Name',
                name: 'last_name',
                placeHolder: 'Last Name',
                inputType: 'textField',
                value: '',
                required: true,
                readOnly: false,
            },
            {
                id: 'cellphone',
                label: 'Cellphone',
                name: 'mob',
                placeHolder: 'Cellphone',
                inputType: 'textField',
                value: '',
                required: true,
                readOnly: false,
            },
            {
                id: 'email',
                label: 'Email',
                name: 'email',
                placeHolder: 'Email',
                inputType: 'textField',
                value: '',
                required: true,
                readOnly: false,
            },
        ],
    },
    {
        sectionTitle: 'Notifications',
        mainGrid: 12,
        isSpacing: false,
        fieldItems: [
            {
                id: 'provider-sms-notification',
                label: 'SMS',
                name: 'sms_notification',
                placeHolder: 'SMS',
                inputType: 'toggle',
                value: false,
                required: false,
                gridSize: 4,
                readOnly: false,
            },
            {
                id: 'provider-email-notification',
                label: 'Email',
                name: 'email_notification',
                placeHolder: 'Email',
                inputType: 'toggle',
                value: false,
                required: false,
                gridSize: 4,
                readOnly: false,
            },
            {
                id: 'provider-in-app-notification',
                label: 'In-App',
                name: 'app_notification',
                placeHolder: 'In-App',
                inputType: 'toggle',
                value: false,
                required: false,
                gridSize: 4,
                readOnly: false,
            },
        ],
    },
]
