import { Box, Grid, makeStyles } from '@material-ui/core'
import my_health_Record from '../../../assests/images/health_record.svg'
import healthCircle from '../../../assests/images/health_circle.svg'
import call_icon from '../../../assests/images/call_icon.svg'
import mail_icon from '../../../assests/images/mail_icon.svg'
import home_icon from '../../../assests/images/home_icon.svg'
import edit_icon from '../../../assests/images/edit_icon.svg'
import camera_icon from '../../../assests/images/camera_icon.svg'
import { getCharOfName } from '../../../utilities/profile'
import './headerModuleStyle.scss'
import { useEffect, useState } from 'react'
import PatientEditForm from '../Forms/PatientEditForm'
import PatientPhotoUpdate from '../Forms/PatientPhotoUpdate'
import { useSelector } from 'react-redux'
import { nameAvatar } from '../../utils'
import CheckboxOption from '../../ClinicalNotes/reusable/CheckboxOptions'
import Checkbox from '@mui/material/Checkbox'
import SharedContactModal from './sharedContactModal/SharedContactModal'
import { updateMe } from '../../../services/Login/login'
import { useFormik } from 'formik'
import UncheckSharedModal from './sharedContactModal/UncheckSharedModal'
import { useNotification } from '../../Appointments/MaterialUi/Notification'

const useStyles = makeStyles((theme) => ({
    menuItem: {
        // Add your custom styles here
        fontSize: '16px',
        paddingLeft: '8px',
    },
    profilePictxtContainer: {
        display: 'flex',
        width: '38.877px',
        height: '32.548px',
        flexDirection: 'column',
        justifyContent: 'center',
        flexShrink: 0,
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '26px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        left: '61px',
        top: '162px',
        position: 'absolute',
    },
}))
const HeaderModule = ({ patient, afterComplete, profilePic }) => {
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenModalContact, setisOpenModalContact] = useState(false)
    const [isOpenModalContactUncheck, setisOpenModalContactUncheck] = useState(false)
    const notification = useNotification()

    const [isOpenModalEmailContact, setisOpenModalEmailContact] = useState(false)
    const [isOpenModalEmailContactUncheck, setisOpenModalEmailContactUncheck] = useState(false)
    const [isHover, setIsHover] = useState(false)
    const [isEditPic, setisEditPic] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const classes = useStyles()

    const openModal = () => {
        setIsOpenModal(true)
    }
    const closeModal = () => {
        setIsOpenModal(false)
    }

    const closePhotoModal = () => {
        setisEditPic(false)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            shared_phone: false,
            shared_secondary_phone: false,
            phone_number: '',
            number_type: '',
            shared_email: false,
        },
    })

    useEffect(() => {
        formik.setFieldValue('shared_phone', patient?.shared_phone)
        formik.setFieldValue('shared_secondary_phone', patient?.shared_secondary_phone)
        formik.setFieldValue('shared_email', patient?.shared_email)
    }, [])

    const SubmitFunction = async () => {
        setisLoading(true)
        const updatedPtientInfo = {
            ...patient,
            shared_phone: formik.values.shared_phone,
            shared_secondary_phone: formik.values.shared_secondary_phone,
        }
        const data = await updateMe(updatedPtientInfo)
        if (!data?.data?.status) {
            notification(data?.data?.message || 'Something went wrong!', 'error')
        }
        setisOpenModalContact(false)
        afterComplete()
        setisLoading(false)
    }

    const uncheckNo = () => {
        formik.setFieldValue(formik.values.number_type, patient?.shared_phone)
        setisOpenModalContactUncheck(false)
    }

    const uncheckYes = async () => {
        setisLoading(true)
        const updatedPtientInfo = {
            ...patient,
            shared_phone: formik.values.shared_phone,
            shared_secondary_phone: formik.values.shared_secondary_phone,
        }
        const data = await updateMe(updatedPtientInfo)
        if (!data?.data?.status) {
            notification(data?.data?.message || 'Something went wrong!', 'error')
        }
        setisOpenModalContactUncheck(false)
        afterComplete()
        setisLoading(false)
    }

    const contactModalClose = () => {
        formik.setFieldValue(formik.values.number_type, false)
        setisOpenModalContact(false)
    }

    const contactModalEmail = () => {
        formik.setFieldValue(formik.values.number_type, false)
        setisOpenModalEmailContact(false)
    }

    const SubmitFunctionEmailShared = async () => {
        setisLoading(true)
        const updatedPtientInfo = {
            ...patient,
            shared_email: formik.values.shared_email,
        }
        const data = await updateMe(updatedPtientInfo)
        if (!data?.data?.status) {
            notification(data?.data?.message || 'Something went wrong!', 'error')
        }
        setisOpenModalContact(false)
        afterComplete()
        setisLoading(false)
    }

    const uncheckEmailNo = () => {
        formik.setFieldValue(formik.values.number_type, patient?.shared_email)
        setisOpenModalEmailContactUncheck(false)
    }
    const uncheckEmailYes = async () => {
        setisLoading(true)
        const updatedPtientInfo = {
            ...patient,
            shared_email: false,
        }
        const data = await updateMe(updatedPtientInfo)
        if (!data?.data?.status) {
            notification(data?.data?.message || 'Something went wrong!', 'error')
        }
        setisOpenModalEmailContactUncheck(false)
        afterComplete()
        setisLoading(false)
    }
    return (
        <>
            {/* <Box className='edit_icon'>
                    <img src={edit_icon} height={13.444} width={11} />
                    </Box> */}
            <Grid container className="header-top-main">
                <Grid item className="header-top-left-sub">
                    <Box className="header-top-left-main" style={{ overflow: 'auto' }}>
                        <Box
                            className="profilepic"
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}>
                            {!patient?.avatar ? (
                                nameAvatar(`${patient?.first_name} ${patient?.last_name}`)
                            ) : (
                                <img
                                    style={{
                                        border: '1px solid #D8D8D8',
                                        borderRadius: '100px',
                                        cursor: 'pointer',
                                        opacity: `${isHover ? '0.5' : '1'}`,
                                    }}
                                    src={profilePic ?? healthCircle}
                                    height={66}
                                    width={66}
                                />
                            )}
                            <div
                                className="camera-icon"
                                style={{
                                    position: 'absolute',
                                    top: '164px',
                                    left: '65px',
                                    display: `${!isHover ? 'none' : ''}`,
                                }}>
                                <img
                                    style={{ cursor: 'pointer' }}
                                    src={camera_icon}
                                    height={25}
                                    width={23}
                                    onClick={() => {
                                        setisEditPic(!isEditPic)
                                    }}
                                />
                            </div>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Box className="profile-details">
                                <Box className="mainName">
                                    {patient?.first_name} {patient?.last_name}
                                </Box>
                                {patient?.preferred_name && (
                                    <Box className="othersdetails">
                                        Preferred Name: {patient?.preferred_name}
                                    </Box>
                                )}
                                <Box className="othersdetails">
                                    <div>
                                        <img src={call_icon} height={10.5} width={10} />{' '}
                                        <span style={{ marginLeft: '2px' }}>Primary</span>:
                                        <span style={{ marginLeft: '4px' }}>
                                            {patient.phone_number}
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <CheckboxOption
                                            checked={formik.values.shared_phone}
                                            sx={{
                                                '& .MuiFormControlLabel': {
                                                    marginRight: '0px',
                                                },
                                            }}
                                            customStyle={{
                                                marginLeft: '10px',
                                                marginRight: '0px',
                                                height: '14px',
                                                width: '14px',
                                            }}
                                            option={{
                                                label: '',
                                                value: false,
                                            }}
                                            // checked={values.harmingOthers.present}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setisOpenModalContact(event.target.checked)
                                                }
                                                formik.setFieldValue(
                                                    'shared_phone',
                                                    event.target.checked,
                                                )
                                                formik.setFieldValue(
                                                    'phone_number',
                                                    patient?.phone_number,
                                                )
                                                if (!event.target.checked) {
                                                    setisOpenModalContactUncheck(true)
                                                }
                                                formik.setFieldValue('number_type', 'shared_phone')
                                            }}
                                        />
                                        <span>Shared phone</span>
                                    </div>
                                </Box>
                                <Box className="othersdetails">
                                    {patient?.secondary_phone_number && (
                                        <div>
                                            <img src={call_icon} height={10.5} width={10} />
                                            <span style={{ marginLeft: '4px' }}>Secondary</span>:
                                            <span style={{ marginLeft: '4px' }}>
                                                {patient?.secondary_phone_number}
                                            </span>
                                        </div>
                                    )}
                                    {patient?.secondary_phone_number && (
                                        <div style={{ display: 'flex' }}>
                                            <CheckboxOption
                                                checked={formik.values.shared_secondary_phone}
                                                sx={{
                                                    '& .MuiFormControlLabel': {
                                                        marginRight: '0px',
                                                    },
                                                }}
                                                customStyle={{
                                                    marginLeft: '10px',

                                                    height: '14px',
                                                    width: '14px',
                                                }}
                                                option={{
                                                    label: '',
                                                    value: false,
                                                }}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setisOpenModalContact(event.target.checked)
                                                    }
                                                    formik.setFieldValue(
                                                        'shared_secondary_phone',
                                                        event.target.checked,
                                                    )
                                                    formik.setFieldValue(
                                                        'phone_number',
                                                        patient?.secondary_phone_number,
                                                    )
                                                    formik.setFieldValue(
                                                        'number_type',
                                                        'shared_secondary_phone',
                                                    )
                                                    if (!event.target.checked) {
                                                        setisOpenModalContactUncheck(true)
                                                    }
                                                }}
                                            />
                                            <span>Shared phone</span>
                                        </div>
                                    )}
                                </Box>
                                <Box className="othersdetails" style={{ marginBottom: '22px' }}>
                                    <img
                                        src={mail_icon}
                                        height={13.444}
                                        width={11}
                                        style={{ marginTop: '5px' }}
                                    />
                                    <span style={{ marginLeft: '5px' }}>{patient?.email}</span>
                                    <div style={{ display: 'flex' }}>
                                        <CheckboxOption
                                            checked={formik.values.shared_email}
                                            sx={{
                                                '& .MuiFormControlLabel': {
                                                    marginRight: '0px',
                                                },
                                            }}
                                            customStyle={{
                                                marginLeft: '10px',
                                                marginRight: '0px',
                                                height: '14px',
                                                width: '14px',
                                            }}
                                            option={{
                                                label: '',
                                                value: false,
                                            }}
                                            // checked={values.harmingOthers.present}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setisOpenModalEmailContact(event.target.checked)
                                                }
                                                formik.setFieldValue(
                                                    'shared_email',
                                                    event.target.checked,
                                                )
                                                formik.setFieldValue(
                                                    'phone_number',
                                                    patient?.phone_number,
                                                )
                                                if (!event.target.checked) {
                                                    setisOpenModalEmailContactUncheck(true)
                                                }
                                                formik.setFieldValue('number_type', 'shared_email')
                                            }}
                                        />
                                        <span>Shared email</span>
                                    </div>
                                </Box>
                            </Box>
                            <Box>
                                <Box className="mainName"></Box>
                                <Box className="othersdetails"></Box>
                                <Box
                                    className="othersdetails"
                                    style={{
                                        marginTop: '34px',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        width: '100px',
                                    }}>
                                    <img src={home_icon} height={26} width={21} />
                                    <span
                                        style={{
                                            color: '#303E4E',
                                            fontFamily: 'Rubik',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            lineHeight: '150%',
                                        }}>
                                        <Box style={{ width: '300px' }}>{patient.address1} </Box>
                                        <Box>{patient.address2} </Box>
                                        <Box>
                                            {patient.city},{patient.state},{patient.zip}{' '}
                                        </Box>
                                    </span>
                                </Box>
                                <Box className="othersdetails"></Box>
                            </Box>
                            <Box className="edit_icon" onClick={openModal}>
                                <img
                                    src={edit_icon}
                                    height={17}
                                    width={17}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item className="header-top-right-main">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <img src={my_health_Record} width={214} />
                        <span style={{ alignSelf: 'center', fontSize: 10, color: '#FFFFFF' }}>
                            Image by &nbsp;
                            <a
                                target="_blank"
                                style={{ cursor: 'pointer', color: '#FFFFFF' }}
                                href="https://www.freepik.com/free-vector/flat-working-day-scene-with-laptop_15443148.htm#&position=0&from_view=search&track=ais&uuid=2c872af8-7437-44df-b345-5b2cd44ceec0"
                                rel="noopener noreferrer">
                                Freepik
                            </a>
                        </span>
                    </div>
                </Grid>
            </Grid>
            <PatientEditForm
                afterComplete={afterComplete}
                isOpenModal={isOpenModal}
                patientInfo={patient}
                handleClose={closeModal}
            />
            <PatientPhotoUpdate
                afterComplete={afterComplete}
                isOpenModal={isEditPic}
                patientInfo={patient}
                handleClose={closePhotoModal}
                profilePic={profilePic}
            />
            <SharedContactModal
                isOpenModalContact={isOpenModalContact}
                contactModalClose={contactModalClose}
                patientInfo={patient}
                phone_number={formik.values.phone_number}
                SubmitFunction={SubmitFunction}
                isLoading={isLoading}
                heading={'Shared Phone Number'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalContactUncheck}
                contactModalClose={uncheckNo}
                patientInfo={patient}
                SubmitFunction={uncheckYes}
                isLoading={isLoading}
                heading={'Shared Phone Number'}
                text="You are about to uncheck your shared phone number consent and indicate
                this is no longer a shared number."
                height={'342px'}
            />
            <SharedContactModal
                isOpenModalContact={isOpenModalEmailContact}
                contactModalClose={contactModalEmail}
                patientInfo={patient}
                phone_number={loggedinPatiendata?.email}
                SubmitFunction={SubmitFunctionEmailShared}
                isLoading={isLoading}
                heading={'Shared Email Inbox'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalEmailContactUncheck}
                contactModalClose={uncheckEmailNo}
                patientInfo={patient}
                SubmitFunction={uncheckEmailYes}
                isLoading={isLoading}
                heading={'Shared Email Inbox'}
                text="You are about to uncheck your shared email consent and indicate this is no longer a shared email inbox."
                height={'342px'}
            />
        </>
    )
}
export default HeaderModule
