let { config } = require('../../constants')
let axios = require('axios')

const axiosInstance = axios.create({
    baseURL: config.dev.baseURL,
})

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        let token = localStorage.getItem('token')
        let tempToken = localStorage.getItem('tempToken')
        if (!token && !!tempToken) {
            requestConfig.headers[
                'Authorization'
            ] = `Bearer ${tempToken}`
        } else if (!!token) {
            requestConfig.headers[
                'Authorization'
            ] = `Bearer ${token}`
        }
        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] =
                requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error.response)
    },
)

function getService(url) {
    return axiosInstance.get(url)
}

function postService(url, params) {
    return axiosInstance.post(url, params)
}

function putService(url, params) {
    return axiosInstance.request({
        url: url,
        method: 'PUT',
        data: params,
    })
}

function deleteService(url, params) {
    return axiosInstance.request({
        url: url,
        method: 'DELETE',
        data: params,
    })
}

module.exports = {
    getService: getService,
    postService: postService,
    putService: putService,
    deleteService: deleteService,
}
