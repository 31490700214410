import React from "react";

import Button from "@material-ui/core/Button";
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';

export default function FlipCameraButton(props: {
  className?: string;
  onClick: () => void;
  disabled?:boolean;
}) {
  return (
    <Button
      className={props.className}
      onClick={props.onClick}
      // startIcon={ <FlipCameraIcon />}
      data-cy-disconnect-toggle
      disabled={props.disabled}
    >
      <FlipCameraAndroidIcon style={{fontSize:'32px'}} />
    </Button>
  );
}
