import AppointmentDetails from '../Dashboard/AppointmentDetails'
import HeaderNew from '../Header/HeaderNew'
import Main from './Main'
function MedicationAllergies() {
    return (
        <>
            <Main />
        </>
    )
}

export default MedicationAllergies
