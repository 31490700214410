import { CardHeader } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export default withStyles((theme) => ({
    root: {
        alignItems: 'center',
        maxHeight: 60,
        minHeight: 60,
    },
    title: {
        fontWeight: 700,
    },
    action: {
        alignSelf: 'center',
        margin: 0,
    },
}))(CardHeader)
