export const mobileRegex = new RegExp(
    /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/i,
)
export const emailRegex = new RegExp(
    /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
)
export const charRegex = new RegExp(/^[A-Za-z ]+$/i)
export const numberRegex = new RegExp(/^\d+$/)
export const USPhoneRegex = new RegExp(
    /(\d{3})(\d{3})(\d{4})/,
)
export const usNumberRegex = new RegExp(
    /^(\()?[2-9]{1}\d{2}(\))?(\s)?\d{3}(\s)\d{4}$/,
)
