import React, { PureComponent } from 'react'
import { ReactComponent as SortIcon } from '../../../assests/images/icon_sort.svg'
import Typography from '@material-ui/core/Typography'
export default class TableHeader extends PureComponent {
    handleToggleSort = (event, option) => {
        const { toggleSort } = this.props
        toggleSort(option)
    }

    noOp = () => {}

    render() {
        const { headerOption, className } = this.props
        return (
            <thead className={className}>
                <tr>
                    {headerOption.map((option, index) => {
                        const { name, isSortable } = option
                        return (
                            <th
                                className={`custom_table__cell ${
                                    isSortable
                                        ? 'custom_table__cell-sortable'
                                        : ''
                                }`}
                                key={`option-${index}`}
                                onClick={
                                    isSortable
                                        ? (e) =>
                                              this.handleToggleSort(
                                                  e,
                                                  option,
                                              )
                                        : this.noOp
                                }>
                                <div
                                    className={
                                        isSortable
                                            ? 'custom_table__cell-wrapper'
                                            : 'custom_table__cell-wrapper'
                                    }>
                                    <Typography
                                        variant="body2"
                                        className="custom_table__cell-name">
                                        {name}
                                    </Typography>
                                    {isSortable && (
                                        <span
                                            id="custom-sort-cell-sort_icon"
                                            className="custom_table__cell-icon">
                                            <SortIcon />
                                        </span>
                                    )}
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
        )
    }
}
