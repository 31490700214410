import React, { useState, useCallback, useEffect } from 'react'
import Video from 'twilio-video'
import Room from './Room'
import './styles.scss'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import { getVideoChatToken } from '../../services/Provider/provider'

const VideoChat = () => {
    const location = useLocation()
    const encounter_id = location?.state?.infoRoom?.encounter_id
    const [roomName, setRoomName] = useState('')
    const [room, setRoom] = useState(null)
    const tokenVideoProvider = useSelector((state) => state.provider.tokenVideoProvider)
    const encounterQueueList = useSelector((state) => state.provider.encounterQueueListGet?.res)
    const handleLogout = useCallback(() => {
        setRoom((prevRoom) => {
            if (prevRoom) {
                prevRoom.localParticipant.tracks.forEach((trackPub) => {
                    trackPub.track.stop()
                })
                prevRoom.disconnect()
            }
            return null
        })
    }, [])

    useEffect(async () => {
        setRoomName(encounterQueueList?.[0].room_id)
        const token = tokenVideoProvider
        if (!isEmpty(token)) {
            Video.connect(token, {
                // name: 'vanhuyba',
            })
                .then((room) => {
                    setRoom(room)
                })
                .catch((err) => {
                    console.error(err)
                })
        }
        // else {
        //     if (encounter_id) {
        //         getVideoChatToken(encounter_id).then((response) => {
        //             Video.connect(response?.data?.twilio_token, {
        //                 // name: 'vanhuyba',
        //             })
        //                 .then((room) => {
        //                     setRoom(room)
        //                 })
        //                 .catch((err) => {
        //                     console.error(err)
        //                 })
        //         })
        //     }
        // }
    }, [tokenVideoProvider])
    useEffect(() => {
        if (room) {
            const tidyUp = (event) => {
                if (event.persisted) {
                    return
                }
                if (room) {
                    handleLogout()
                }
            }
            window.addEventListener('pagehide', tidyUp)
            window.addEventListener('beforeunload', tidyUp)
            return () => {
                window.removeEventListener('pagehide', tidyUp)
                window.removeEventListener('beforeunload', tidyUp)
            }
        }
    }, [room, handleLogout])

    let render
    if (room) {
        render = <Room roomName={roomName} room={room} handleLogout={handleLogout} />
    } else {
        render = ''
    }
    return render
}

export default VideoChat
