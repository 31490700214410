import React from 'react'

import Button from '@material-ui/core/Button'
import TeleMicIcon from '../../../icons/TeleMicIcon'
import ProhabitedWhite from '../../../icons/ProhabitedWhite'

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'
import { IconMuteMic } from '../../../../TwilioVideo/icon'
import useDevices from '../../../hooks/useDevices/useDevices'
// import micIconWhite from "../../../assets/icon_mic.png";
export default function ToggleAudioButton(props: {
    disabled?: boolean
    className?: string
    hideLabel?: boolean
}) {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
    const { localTracks } = useVideoContext()
    const { hasAudioInputDevices } = useDevices()
    const hasAudioTrack = localTracks.some((track) => track.kind === 'audio')
    const audioTrack = localTracks.find((track) => track.kind === 'audio')
    console.log('AUDIO_1 :: ', hasAudioInputDevices, hasAudioTrack)

    return (
        <Button
            className={props.className}
            onClick={toggleAudioEnabled}
            disabled={!hasAudioInputDevices || props.disabled}
            data-cy-audio-toggle>
            {isAudioEnabled ? (
                <TeleMicIcon />
            ) : (
                <>
                    <IconMuteMic />
                </>
            )}
            {!props.hideLabel && (!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute')}
        </Button>
    )
}
