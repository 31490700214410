import React from 'react'

//components
import loginAnotherWay from '../containers/Login/LoginAnotherWay'
import StaffLogin from '../containers/Login/StaffLogin'
import LoginComponent from '../containers/Login/Login'
import RegisterComponent from '../containers/SignUp/Register'
import PatientDetail from '../containers/SignUp/PatientDetail'
import VerifyEmail from '../containers/VerifyEmail'
import SignUpLink from '../containers/SignUp/SignUpLink'
import SignUp from '../containers/SignUp/SignUpForm'
import { authNotRequired, authRequired } from '../utilities/guards'
import Routes from '../constants/routes'
import PatientBookAppointment from '../containers/Appointments/PatientBookAppointment'
import { Redirect } from 'react-router-dom'
import MainIndex from '../components/Consultation/MainIndex'
import Dashboard from '../containers/Dashboard'
import { getAllUserRoles } from '../constants/user-roles'
import SignInContainer from '../containers/SignIn/SignIn'
import ForgotPasswordContainer from '../containers/SignIn/ForgotPassword'
import MagicLinkContainer from '../containers/SignIn/MagicLink'
import HealthReport from '../components/HealthReport'
import HealthConditions from '../components/HeathConditions'
import ClinicalNotes from '../components/ClinicalNotesGuest/index'
import LabResults from '../components/LabResults'
import MedicationAllergies from '../components/MedicationAllergies'
import Medications from '../components/Medications'
import AppointmentNew from '../components/AppointmentNew'
import MessageCareTeam from '../components/MessageCareTeam'
import Contacts from '../components/Contacts'

const meta = {
    auth: false,
    guards: [authNotRequired],
}

const guestRoutes = [
    // {
    //     path: [Routes.DASHBOARD],
    //     roles: getAllUserRoles(),
    //     component: Dashboard,
    //     meta,
    // },
    // {
    //     path: [Routes.MY_HEALTH_RECORD],
    //     roles: getAllUserRoles(),
    //     component: HealthReport,
    //     meta,
    // },
    // {
    //     path: [Routes.HEALTH_CONDITIONS],
    //     roles: getAllUserRoles(),
    //     component: HealthConditions,
    //     meta,
    // },
    // {
    //     path: [Routes.LAB_RESULTS],
    //     roles: getAllUserRoles(),
    //     component: LabResults,
    //     meta,
    // },
    // {
    //     path: [Routes.MEDICATIONS],
    //     roles: getAllUserRoles(),
    //     component: Medications,
    //     meta,
    // },
    // {
    //     path: [Routes.CLINICAL_NOTES_VIEW.VIEW_LIST],
    //     roles: getAllUserRoles(),
    //     component: ClinicalNotes,
    //     meta,
    // },
    // {
    //     path: [Routes.MEDICATION_ALLERRGIES],
    //     roles: getAllUserRoles(),
    //     component: MedicationAllergies,
    //     meta,
    // },
    // {
    //     path: [Routes.APPOINTMENT],
    //     roles: getAllUserRoles(),
    //     component: AppointmentNew,
    //     meta,
    // },
    // {
    //     path: [Routes.MESSAGE_CARE_TEAM],
    //     roles: getAllUserRoles(),
    //     component: MessageCareTeam,
    //     meta,
    // },
    {
        path: [Routes.HOME, Routes.LOGIN.BASIC],
        component: LoginComponent,
        meta,
    },
    {
        path: [Routes.LOGIN.STAFF],
        component: StaffLogin,
        meta,
    },
    // {
    //     path: Routes.PATIENT_DETAIL,
    //     component: PatientDetail,
    //     meta: { auth: true, guards: [authRequired] },
    // },
    {
        path: [Routes.REGISTER],
        component: RegisterComponent,
        meta,
    },
    {
        path: [Routes.SIGNUP_VERIFY_EMAIL],
        component: SignUpLink,
        meta,
    },
    {
        path: [Routes.SIGNUP],
        component: SignUp,
        meta,
    },
    // {
    //     path: [Routes.PATAIENTBOOKAPPOINTMENT],
    //     component: PatientBookAppointment,
    //     meta,
    // },
    {
        path: [Routes.SIGNIN],
        component: SignInContainer,
        meta,
    },
    {
        path: [Routes.MAGIC_LINK],
        component: MagicLinkContainer,
        meta,
    },
    {
        path: [Routes.FORGOT_PASSWORD],
        component: ForgotPasswordContainer,
        meta,
    },
    {
        path: Routes.LOGIN.ALTERNATE,
        component: loginAnotherWay,
        meta,
    },
    {
        path: [
            Routes.CONSULTATION.URL,
            Routes.CONSULTATION.VIDEO,
            Routes.CONSULTATION.JOIN,
            Routes.CONSULTATION.ROOM,
            Routes.CONSULTATION.SHORT_LINK,
        ],
        component: MainIndex,
        meta: { ...meta, isVideo: true },
    },
    {
        path: [Routes.VERIFY_EMAIL],
        component: VerifyEmail,
        meta,
    },
    {
        path: '*',
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to={Routes.HOME}></Redirect>,
        meta,
    },
]

export default guestRoutes
