import * as actionType from '../actionType'

const initialState = {
    headerTab: 0,
}

const header = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_HEADER_TAB: {
            return {
                ...state,
                headerTab: action.value,
            }
        }
        default:
            return state
    }
}

export default header
