import * as actionType from '../actionType'
import { setLoading, setError } from './loader'
import { uploadDocuments } from '../../services/Document/documents'
import {
    getDocumentList,
    postEditDocument,
    postDeleteDocument,
} from '../../services/Document/queries'

export const getAllDocumentList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getDocumentList(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setDocumentlist(res.data))
                console.log(res.data, 'testing')
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setDocumentlist([]))
            })
    }
}

export const editDocument = (payload, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        postEditDocument(payload)
            .then((res) => {
                dispatch(setLoading(false))
                console.log('Edited')
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const deleteDocument = (payload, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        postDeleteDocument(payload)
            .then((res) => {
                dispatch(setLoading(false))
                console.log('deleted')
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}
export const uploadDocs = (payload, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        uploadDocuments(payload)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess(res.data)
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const setDocumentlist = (value) => {
    return {
        type: actionType.SET_DOCUMENTS_LIST,
        value,
    }
}
