import {
    CircularProgress,
    FormHelperText,
    Grid,
    InputAdornment,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import * as Yup from 'yup'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useFormik } from 'formik'
import React from 'react'
import closeIcon from '../../assests/images/icon_close.svg'
import { useSelector } from 'react-redux'

const CustomTextField = withStyles({
    // root: {
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             borderColor: '#e2e2e1',
    //         },
    //     },
    // },
})(TextField)

const PrimaryButton = withStyles((theme) => ({
    root: {
        color: '#FFF',
        backgroundColor: theme.palette.primary,
        width: '160px',
    },
}))(Button)

export function CancellationChargeModal(props) {
    const parentClass = 'worklist'
    const { rowData, isCancellationChargeDone } =
        props || {}
    const cancellationChargeSchema = Yup.object().shape({
        chargeAmount: Yup.number()
            .required('Cancellation charge is required')
            .positive()
            .integer()
            .min(
                0,
                'Cancellation charge should be more than 0',
            )
            .max(
                rowData?.amount,
                `Cancellation charge should not exceed ${rowData?.amount}$`,
            ),
    })
    const formik = useFormik({
        initialValues: {
            chargeAmount: props.status,
            remarks: '',
        },
        onSubmit: props.onSubmit,
        validationSchema: cancellationChargeSchema,
    })

    return (
        <Modal
            open={props.opened}
            onClose={() => {
                props.onClose()
                formik.resetForm()
            }}>
            <div
                className={`${parentClass}__modal__container`}
                style={{ maxHeight: '85%' }}>
                <div
                    className={`${parentClass}__modal__header`}>
                    <Typography
                        id="modal-modal-title"
                        className="worklist__modal__title"
                        variant="inherit"
                        component="h3">
                        <strong>Cancellation Charge</strong>
                    </Typography>

                    <img
                        className={`${parentClass}__modal__cancel-btn`}
                        src={closeIcon}
                        role="presentation"
                        onClick={() => {
                            props.onClose()
                            formik.resetForm()
                        }}
                        alt="close-icon"
                    />
                </div>
                <div
                    className={`${parentClass}__modal__body`}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            spacing={4}
                            alignItems="center"
                            justifyContent="center">
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        color: '#516983',
                                        paddingBottom:
                                            '8px',
                                    }}>
                                    <strong>
                                        Enter cancellation
                                        amount
                                    </strong>
                                </Typography>
                                <FormControl
                                    style={{
                                        width: '100%',
                                        paddingTop: '8px',
                                        paddingBottom:
                                            '8px',
                                        borderRadius: '4px',
                                    }}>
                                    <TextField
                                        id="chargeAmount"
                                        aria-label="chargeAmount"
                                        name="chargeAmount"
                                        disableUnderline
                                        type="number"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment:
                                                (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                ),
                                        }}
                                        onChange={
                                            formik.handleChange
                                        }
                                        value={
                                            formik.values
                                                .chargeAmount
                                        }
                                    />
                                    {formik.errors
                                        .chargeAmount && (
                                        <FormHelperText
                                            error>
                                            {
                                                formik
                                                    .errors
                                                    .chargeAmount
                                            }
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    style={{
                                        width: '100%',
                                    }}>
                                    <Typography
                                        style={{
                                            color: '#516983',
                                            paddingBottom:
                                                '8px',
                                        }}>
                                        <strong>
                                            Remarks
                                        </strong>
                                    </Typography>
                                    <CustomTextField
                                        id="remarks"
                                        aria-label="remarks"
                                        name="remarks"
                                        hiddenLabel
                                        size="medium"
                                        variant="outlined"
                                        onChange={
                                            formik.handleChange
                                        }
                                        inputProps={{
                                            maxLength: 500,
                                        }}
                                        helperText="Max 500 characters only"
                                        multiline
                                        rows={6}
                                        value={
                                            formik.values
                                                .remarks
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div
                            className={`${parentClass}__modal__footer`}>
                            {isCancellationChargeDone ? (
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        justifyContent:
                                            'center',
                                    }}
                                    type="submit">
                                    Submit
                                </PrimaryButton>
                            ) : (
                                <CircularProgress
                                    size={24}
                                />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
