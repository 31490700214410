/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react'
import Participant from './Participant'

const Room = ({ roomName, room, handleLogout }) => {
    return (
        <div className="room video-call">
            <div className="local-participant">
                {room ? (
                    <Participant
                        // key={room.localParticipant.sid}
                        // participant={room.localParticipant}
                        // lisParticipants={participants}
                        room={room}
                        // toggleVideo={(item) =>
                        //     toggleVideo(item)
                        // }
                        // toggleMute={(item) =>
                        //     toggleMute(item)
                        // }
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default Room
