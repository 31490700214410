import { combineReducers } from 'redux'
import login from './login'
import loader from './loader'
import register from './register'
import appointments from './appointments'
import provider from './provider'
import menu from './menu'
import patient from './patient'
import user from './user'
import clinicAdmin from './clinicAdmin'
import tm2uAdmin from './tm2uAdmin'
import fax from './fax'
import forms from './forms'
import admin from './admin'
import formBuilder from './formBuilder'
import documents from './documents'
import clinic from './clinic'
import macros from './macros'
import clinicalNotes from './clinicalNotes'
import header from './header'

export default combineReducers({
    login,
    loader,
    register,
    appointments,
    provider,
    menu,
    patient,
    user,
    clinicAdmin,
    tm2uAdmin,
    fax,
    forms,
    admin,
    formBuilder,
    documents,
    clinic,
    macros,
    clinicalNotes,
    header,
})
