import React, { Component } from 'react'
import './TabComponent.scss'

export default class TabComponent extends Component {
    handleTabclick = (index, option) => {
        const { activeTabIndex, onChange } = this.props

        if (activeTabIndex === index) {
            onChange({ index, option })
        }
    }

    render() {
        const { activeTabIndex, options, className } =
            this.props

        return (
            <ul className={`${className} tabs-wrapper`}>
                {options.map((option, index) => {
                    const { label } = option
                    return (
                        <li
                            key={`tab-${index}`}
                            className={`tabs-wrapper__tab ${
                                activeTabIndex === index
                                    ? 'active'
                                    : ''
                            }`}
                            onClickCapture={(e) => {
                                this.handleTabclick(
                                    index,
                                    option,
                                )
                            }}>
                            {label}
                        </li>
                    )
                })}
            </ul>
        )
    }
}
