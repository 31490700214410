import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import RegisterForm from '../../components/SignUp/register'
import Routes from '../../constants/routes'
import * as loaderActions from '../../store/actions/loader'
import * as actions from '../../store/actions/register'
import { formatPhone } from '../../utilities'
import Modal from '../../utilities/modal/modal'
import {
    charRegex,
    mobileRegex,
    usNumberRegex,
} from '../../utilities/regex'

const emailSchema = Yup.string().email()
class Register extends Component {
    state = {
        errorFlag: {
            firstName: false,
            lastName: false,
            dob: -1,
            email: false,
            phone: false,
            gender: false,
        },
        dob: null,
        gender: null,
        phone: null,
        isError: false,
        otpErrMsg: 'Please enter a valid OTP',
        showModal: false,
        errMsgContent: '',
        errMsgHeader: '',
        resendButtonDisabledTime: 30,
        intervalID: null,
        email: '',
    }
    componentDidMount() {
        this.props.setNewUser(false)
        this.props.setError(false)
        let date = new Date()
        date.setFullYear(new Date().getFullYear() - 18)
        this.setState({ startDate: date })
    }

    componentDidUpdate(nextProps) {
        const {
            isExistingUser,
            error,
            patientData,
            isNewUser,
            errorMessage,
        } = this.props
        console.log('=======================', this.props)
        if (
            nextProps.isExistingUser !== isExistingUser &&
            isExistingUser
        ) {
            this.setState({
                buttonType: 'Login',
                showModal: true,
                errMsgContent:
                    'You already have an account please login.',
            })
        }
        if (
            nextProps.isNewUser !== isNewUser &&
            isNewUser
        ) {
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID)
            }
            this.setState({ timmerRunning: false }, () => {
                this.startResendOtpTimer()
            })
            // this.startResendOtpTimer();
        }
        if (
            error &&
            (nextProps.error !== error ||
                nextProps.errorMessage !== errorMessage)
        ) {
            this.setState({
                buttonType: 'Close',
                showModal: true,
                errMsgContent:
                    errorMessage ||
                    'Something went wrong please try after sometime!!',
            })
        }
        if (nextProps.patientData !== patientData) {
            if (patientData.isUserExist) {
                this.setState({
                    buttonType: 'Login',
                    showModal: true,
                    errMsgContent:
                        'Email already used please try another.',
                })
            } else {
                localStorage.setItem(
                    'token',
                    patientData.accessToken,
                )
                this.props.history.push(
                    Routes.PATIENT_DETAIL,
                )
            }
        }
    }

    registerWithOtp = (values) => {
        const data = {
            phoneNumber: values.mob,
            // `${process.env.REACT_APP_COUNTRY_CODE}${this.state.phone}`.replace(
            //     /[ )(]/g,
            //     '',
            // ),
            fname: values.firstName.trim(),
            lname: values.lastName.trim(),
            dob: values.dob,
            gender: values.gender,
            otp: values.otp,
        }
        if (this.state.email !== '') {
            data.email = values.email
        }
        this.setState({ otp: values.otp })
        this.props.registerPatient(data)
    }

    sendOtp = (phone) => {
        if (this.props.isNewUser) {
            this.startResendOtpTimer()
        }
        this.props.sendOtpRegister({
            to: phone,
            // `${process.env.REACT_APP_COUNTRY_CODE}${this.state.phone}`.replace(
            //     /[ )(]/g,
            //     '',
            // ),
            channel: 'sms',
            type: 'r',
        })
    }

    goBack = () => {
        this.props.setNewUser(false)
    }

    handleSubmit = (values) => {
        // let errField = [
        //     'firstName',
        //     'lastName',
        //     'dob',
        //     'phone',
        //     'gender',
        // ]
        // let errFlag = this.state.errorFlag
        // errField.map((field) => {
        //     if (field === 'dob') {
        //         errFlag[field] = this.calculateAge(
        //             this.state.dob,
        //         )
        //     } else if (!this.state[field]) {
        //         errFlag[field] = true
        //     }
        // })
        // const { firstName, lastName, dob, phone, gender } =
        //     this.state
        // if (
        //     !firstName ||
        //     !lastName ||
        //     !phone ||
        //     !gender ||
        //     this.calculateAge(dob) != -1
        // ) {
        //     this.setState({ errorFlag: errFlag })
        // } else {
        //     this.sendOtp()
        // }

        this.sendOtp(values.mob)
    }

    handleChangeAll = (event) => {
        // event.preventDefault();
        const { name, value } = event.target
        let updatedValue = value
        if (name === 'email') {
            value.trim()
        }
        // else if (name === 'phone') {
        //     updatedValue = formatPhone(value)
        // }
        let eFlags = this.validate(name, updatedValue)
        this.setState({
            [name]: updatedValue,
            errorFlag: eFlags,
        })
    }

    handleDateChange = (date) => {
        let errFlag = this.state.errorFlag
        let dob = this.state.dob
        if (!moment(date).isValid()) {
            dob = date
            errFlag.dob = 0
        } else if (
            moment(date).diff(moment(), 'years') * -1 >=
            18
        ) {
            dob = date
            errFlag.dob = -1
        } else {
            dob = date
            errFlag.dob = 1
        }
        this.setState({ dob: dob, errorFlag: errFlag })
    }

    calculateAge = (value) => {
        if (!value) {
            return 2
        }
        const isValid = moment(value).isValid()
        if (!isValid) {
            return 0
        }
        return moment().diff(moment(value), 'years') >= 18
            ? -1
            : 1
    }

    setNewUserChange = () => {
        let user = {
            isUserExist: false,
            message: '',
        }
        this.props.setNewUser(user)
    }

    handleClose = () => {
        this.props.setNewUser(false)
        this.setState({ showModal: false })
    }

    handleModalChange = (type) => {
        switch (type) {
            case 'Close':
                this.handleClose()
                break
            case 'Login':
                this.props.history.push(Routes.HOME)
                break
            default:
                break
        }
    }

    validate = (field, value) => {
        let eFlags = this.state.errorFlag
        switch (field) {
            case 'firstName':
            case 'lastName':
                eFlags[field] =
                    value === '' || !charRegex.test(value)
                        ? true
                        : false
                break
            case 'email':
                eFlags[field] =
                    value !== '' &&
                    !emailSchema.isValidSync(value)
                        ? true
                        : false
                break
            case 'phone':
                eFlags[field] = false
                // eFlags[field] =
                //     value === '' ||
                //     !usNumberRegex.test(value)
                //         ? true
                //         : false
                break
            case 'gender':
                eFlags[field] = false
                break
            default:
        }
        return eFlags
    }

    handleModalClose = () => {
        return false
    }

    startResendOtpTimer = () => {
        if (!this.state.timmerRunning) {
            var timesRun = 30
            this.state.intervalID = setInterval(() => {
                if (timesRun === 0) {
                    clearInterval(this.state.intervalID)
                }
                timesRun -= 1
                this.setResendButtonDisabledTime(timesRun)
            }, 1000)
        } else {
            return
        }
    }

    setResendButtonDisabledTime = (time) => {
        this.setState({
            resendButtonDisabledTime: time,
            timmerRunning: true,
        })
        if (time === -1) {
            this.setState({ timmerRunning: false })
        }
    }

    render() {
        const { isNewUser, patientData } = this.props

        return (
            <div>
                <Modal
                    openModal={this.state.showModal}
                    content={this.state.errMsgContent}
                    buttonType={this.state.buttonType}
                    handleModalChange={
                        this.handleModalChange
                    }
                    handleClose={this.handleClose}></Modal>
                <Header />
                {/* <OtherHeader /> */}
                <RegisterForm
                    handleSubmit={this.handleSubmit}
                    handleChange={this.handleChangeAll}
                    errorFlag={this.state.errorFlag}
                    isNewUser={isNewUser}
                    registerPatient={this.registerWithOtp}
                    goBack={this.goBack}
                    otpError={this.props.otpError}
                    otpErrMsg={
                        this.props.otpError
                            ? this.state.otpErrMsg
                            : ''
                    }
                    handleDateChange={this.handleDateChange}
                    dateValue={this.state.dob}
                    genderValue={this.state.gender}
                    phoneValue={this.state.phone}
                    startDate={this.state.startDate}
                    resendButtonDisabledTime={
                        this.state.resendButtonDisabledTime
                    }
                    loading={this.props.loading}
                    setOtpError={this.props.setOtpError}
                    email={this.state.email}
                    otp={this.state.otp}
                />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loader.loading,
        isNewUser: state.register.isNewUser,
        isExistingUser: state.register.isExistingUser,
        errorMsg: state.register.errorMsg,
        error: state.loader.error,
        errorMessage: state.loader.errorMsg,
        otpError: state.register.otpError,
        patientData: state.login.userData,
    }
}

const mapDispatchtoProp = (dispatch) => {
    return {
        sendOtpRegister: (data) =>
            dispatch(actions.sendOtpRegister(data)),
        setNewUser: (val) =>
            dispatch(actions.handleBack(val)),
        registerPatient: (data) =>
            dispatch(actions.register(data)),
        setError: (val) =>
            dispatch(loaderActions.setError(val)),
        setOtpError: (val) =>
            dispatch(actions.setOtpError(val)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchtoProp,
)(Register)
