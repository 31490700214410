import React, { useEffect, useState } from 'react'
import HeaderNew from '../Header/HeaderNew'
import { Box, CircularProgress, Grid } from '@material-ui/core'
import AppointmentDetails from '../Dashboard/AppointmentDetails'
import './style.scss'
import HeaderModule from './Module/HeaderModule'
import PatientDetails from './Module/PatientDetails'
import HealthAccount from './Module/HealthAccount'
import { getProfilePic, me } from '../../services/Login/login'
import { useLocation } from 'react-router-dom'
import { getLoggedInUserDetails } from '../../store/actions/login'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getAppointmentListUpcomingPrevious } from '../../services/Appointments/appointments'
export default () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const isMenu = location.state && location.state.isMenu
    const [patientDetails, setPatientDetails] = useState([])
    const [previousAppointmentStartTime, setPreviousAppointmentStartTime] = useState('')
    const [isLoading, setisLoading] = useState(false)
    const [profilePic, setProfilePic] = useState(null)

    useEffect(() => {
        getPateintDetails()
    }, [loggedinPatiendata])

    const getPateintDetails = async () => {
        setisLoading(true)
        const data = await me()
        if (data?.data?.data?.avatar) {
            const res = await getProfilePic({ image_name: data?.data?.data?.avatar })
            setProfilePic(res?.data?.data?.url)
        }
        setPatientDetails(data?.data?.data)
        const payload = {
            page: 1,
            size: 1,
            status: 'fulfilled',
            end: moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }
        const res = await getAppointmentListUpcomingPrevious(payload)
        const value = res?.data?.data?.data.length ? res?.data?.data?.data[0].start : ''
        setPreviousAppointmentStartTime(value)
        setisLoading(false)
    }

    const afterComplete = async () => {
        setisLoading(true)
        const data = await me()
        setPatientDetails(data?.data?.data)
        setisLoading(false)
    }

    return (
        <>
            {isLoading ? (
                <div className="health-record-circular-progress-container">
                    <CircularProgress
                        style={{
                            color: '#667689',
                            marginRight: 3,
                        }}
                    />
                </div>
            ) : (
                <Grid conatiner style={{ background: '#F5F6F7' }}>
                    <Grid container className="container-main">
                        <Grid className="sub-container">
                            <Box className="header-name">
                                <span className="text-style">My Health Record</span>
                            </Box>
                            <HeaderModule
                                patient={patientDetails}
                                afterComplete={afterComplete}
                                profilePic={profilePic}
                            />
                            <Grid container item className="container-two">
                                <Grid item className="container-two-1">
                                    <PatientDetails
                                        patient={patientDetails}
                                        previousAppointmentStartTime={previousAppointmentStartTime}
                                    />
                                </Grid>
                                <Grid item className="container-two-2">
                                    <HealthAccount isMenu={isMenu} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
