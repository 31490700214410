import * as actionType from '../actionType'
import { UserRoles } from '../../constants/user-roles'
import { setLoading, setError } from './loader'
import {
    sendOtpService,
    verifyOtpService,
    getDetailsService,
    sendOtpToEmailService,
    verifyQuestionsService,
    verifyTokenService,
    getPatientProfileById,
    getAccountInfo,
    me,
    getProfilePic,
} from '../../services/Login/login'
import { resetLocalStorage } from '../../utilities'

export const setMaxAttempt = (value) => {
    return {
        type: actionType.SET_MAX_ATTEMPT,
        value: value,
    }
}
export const setIsUserExist = (value) => {
    return {
        type: actionType.SET_IS_USER_EXSIST,
        value: value,
    }
}
export const setIsValidEmail = (value) => {
    return {
        type: actionType.SET_EMAIL_VALID,
        value: value,
    }
}

export const setResetData = (value) => {
    return {
        type: actionType.SET_RESET_DATA,
        value: value,
    }
}
export const setUser = (value) => {
    return {
        type: actionType.SET_USER,
        value: value,
    }
}
export const setSession = (value) => {
    return {
        type: actionType.SET_SESSION,
        value: value,
    }
}
export const setNewUser = (value) => {
    return {
        type: actionType.SET_NEW_USER,
        value: value,
    }
}

export const setPatientData = (value) => {
    return {
        type: actionType.SET_PATIENT_DATA,
        value: value,
    }
}
export const setPatientDataAfterChange = (value) => {
    return {
        type: actionType.SET_PATIENT_DATA_AFTER_CHANGE,
        value: value,
    }
}
export const setOtpError = (value) => {
    return {
        type: actionType.SET_OTP_ERROR,
        value: value,
    }
}

export const setShowAnotherWayOtpField = (value) => ({
    type: actionType.SET_ANOTHER_WAY_OTP_FIELD,
    value,
})

export const logout = () => {
    return {
        type: actionType.LOGOUT,
    }
}

export const setRedirectURL = (url) => ({
    type: actionType.SET_REDIRECT_URL,
    url,
})

export const redirectTo = (url) => {
    return (dispatch) => {
        dispatch(setRedirectURL(url))
    }
}
export const sendOtp = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        sendOtpService(data)
            .then((res) => {
                dispatch(setLoading(false))
                if (res && res.data) {
                    dispatch(setUser(!!res.data.Session))
                    dispatch(setSession(res.data.Session))
                } else {
                    dispatch(setError(true))
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
                if (err.status === 404) {
                    dispatch(setUser(false))
                    return
                }
                dispatch(setError(err.data))
            })
    }
}

export const verifyLogin = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        verifyOtpService(payload)
            .then((res) => {
                dispatch(setLoading(false))
                const { data } = res
                if (!data.isOtpValid && data.isOtpValid !== undefined) {
                    dispatch(setOtpError(true))
                } else {
                    localStorage.setItem('token', data?.IdToken)
                    return getAccountInfo().then((response) => {
                        dispatch(
                            setPatientData({
                                ...response.data,
                                accessToken: data?.IdToken,
                                name: `${response?.data.given_name} ${response?.data.family_name}`,
                                role: response?.data['custom:role'],
                            }),
                        )
                        dispatch(setError(false))
                        dispatch(setOtpError(false))
                        // if (
                        //     response?.data?.role ===
                        //     UserRoles.PATIENT
                        // ) {
                        //     return getPatientProfileById(
                        //         response?.data?.fhirId,
                        //     ).then((patientData) => {
                        //         dispatch(
                        //             setPatientData({
                        //                 ...response.data,
                        //                 accessToken:
                        //                     data
                        //                         ?.AuthenticationResult
                        //                         ?.IdToken,
                        //                 ...patientData.data,
                        //                 user_id:
                        //                     patientData
                        //                         .data
                        //                         .id,
                        //                 name: `${response?.data.fistName} ${response?.data.lastName}`,
                        //             }),
                        //         )
                        //         dispatch(
                        //             setError(false),
                        //         )
                        //         dispatch(
                        //             setOtpError(false),
                        //         )
                        //     })
                        // }
                        // if (
                        //     response?.data?.role ===
                        //     UserRoles.TM2U_SUPER_ADMIN
                        // ) {
                        //     dispatch(
                        //         setPatientData({
                        //             ...response.data,
                        //             accessToken:
                        //                 data
                        //                     ?.AuthenticationResult
                        //                     ?.IdToken,
                        //             user_id:
                        //                 response?.data
                        //                     .fhirId,
                        //             id: response?.data
                        //                 .fhirId,
                        //             name: `${response?.data.fistName} ${response?.data.lastName}`,
                        //         }),
                        //     )
                        //     dispatch(setError(false))
                        //     dispatch(setOtpError(false))
                        // }

                        // if (
                        //     response?.data?.role ===
                        //     UserRoles.PROVIDER
                        // ) {
                        //     dispatch(
                        //         setPatientData({
                        //             ...response.data,
                        //             accessToken:
                        //                 data?.IdToken,
                        //             user_id:
                        //                 response?.data
                        //                     .fhirId,
                        //             id: response?.data
                        //                 .fhirId,
                        //             name: `${response?.data.fistName} ${response?.data.lastName}`,
                        //         }),
                        //     )
                        // }
                    })
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
                console.log(err, 'err')
                if (err && err.data) {
                    if (err.data.te) {
                        dispatch(setMaxAttempt(true))
                    }
                    if (err.data.statusCode === 400) {
                        dispatch(setOtpError(true))
                    } else {
                        dispatch(setError(err.data))
                    }
                } else {
                    dispatch(setError(true))
                }
            })
    }
}

export const verifyToken = () => {
    let getData = {}
    return (dispatch) => {
        dispatch(setLoading(true))
        return me()
            .then(async (response) => {
                localStorage.setItem(
                    'name',
                    response.data.data.first_name + ' ' + response.data.data.last_name,
                )
                localStorage.setItem('isProfileComplete', 'true')

                if (response?.data?.data?.avatar) {
                    try {
                        const viewUrl = await getProfilePic({
                            image_name: response?.data?.data?.avatar,
                            me,
                        })
                        getData['photo'] = viewUrl?.data?.data?.url
                    } catch (error) {
                        console.log('Profile image fetch error')
                    }
                }
                dispatch(
                    setPatientData({
                        ...response.data.data,
                        accessToken: localStorage.getItem('access_token'),
                        name: `${response?.data.given_name} ${response?.data.family_name}`,
                        role: response?.data['custom:role'],
                        ...getData,
                    }),
                )
                dispatch(setLoading(false))
                dispatch(setError(false))
                dispatch(setOtpError(false))
                // if (
                //     response?.data?.role ===
                //     UserRoles.PATIENT
                // ) {
                //     return getPatientProfileById(
                //         response?.data?.fhirId,
                //     ).then((patientData) => {
                //         dispatch(
                //             setPatientData({
                //                 ...response.data,
                //                 accessToken:
                //                     localStorage.getItem(
                //                         'token',
                //                     ),
                //                 ...patientData.data,
                //                 user_id:
                //                     patientData.data.id,
                //                 photo: localStorage.getItem(
                //                     'photo',
                //                 ),
                //             }),
                //         )
                //         dispatch(setError(false))
                //         dispatch(setOtpError(false))
                //     })
                // }
                // if (
                //     response?.data?.role ===
                //         UserRoles.TM2U_SUPER_ADMIN ||
                //     response?.data?.role ===
                //         UserRoles.PROVIDER
                // ) {
                //     dispatch(
                //         setPatientData({
                //             ...response.data,
                //             accessToken:
                //                 localStorage.getItem(
                //                     'token',
                //                 ),
                //             user_id: response.data.fhirId,
                //             id: response.data.fhirId,
                //             photo: localStorage.getItem(
                //                 'photo',
                //             ),
                //         }),
                //     )
                //     dispatch(setError(false))
                //     dispatch(setOtpError(false))
                // }
            })
            .catch((err) => {
                console.log(err, 'error')
                dispatch(setLoading(false))
                // reset localStorage
                resetLocalStorage()
                //dispatch(logout())
                dispatch(setError(true))
            })
        // verifyTokenService()
        //     .then((res) => {
        //         dispatch(setLoading(false))
        //         let { data } = res
        //         // set patient data
        //         console.log('verifytoken', data)
        //         const photo = localStorage.getItem('photo')
        //         dispatch(
        //             setPatientData({
        //                 ...data.response,
        //                 photo,
        //             }),
        //         )
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         dispatch(setLoading(false))
        //         // reset localStorage
        //         resetLocalStorage()
        //         dispatch(logout())
        //         dispatch(setError(true))
        //     })
    }
}

export const getDetails = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getDetailsService(data)
            .then((res) => {
                let { data } = res

                dispatch(setLoading(false))
                dispatch(setError(false))
                dispatch(setResetData(data))
            })
            .catch((err) => {
                dispatch(setError(true))
                dispatch(setLoading(false))
            })
    }
}

export const sendOtpToEmail = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        sendOtpToEmailService(data)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setIsValidEmail(res.data))
                dispatch(setShowAnotherWayOtpField(true))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                if (err && err.data && err.data.isMaxAttempts) {
                    dispatch(setMaxAttempt(true))
                } else if (
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.isMaxAttempts
                ) {
                    dispatch(setMaxAttempt(true))
                } else {
                    dispatch(setError(true))
                }
            })
    }
}
export const verifyQuestions = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        verifyQuestionsService(data)
            .then((res) => {
                let { data } = res
                dispatch(setLoading(false))
                dispatch(setPatientData(data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                if (err && err.data && err.data.isMaxAttempts) {
                    dispatch(setMaxAttempt(true))
                } else {
                    dispatch(setMaxAttempt(false))
                    dispatch(setError(true))
                }
            })
    }
}
export const getPatientProfileByUserId = (userId, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getPatientProfileById(userId)
            .then((res) => {
                let { data } = res
                dispatch(setLoading(false))
                onSuccess && onSuccess(data)
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const getLoggedInUserDetails = () => {
    return (dispatch) => {
        me()
            .then(async (res) => {
                dispatch(setLoading(true))
                localStorage.setItem(
                    'name',
                    res.data.data.first_name + ' ' + res.data.data.last_name,
                )
                localStorage.setItem('isProfileComplete', 'true')
                const getData = { ...res.data.data }
                if (getData?.avatar) {
                    try {
                        const viewUrl = await getProfilePic({ image_name: getData.avatar })
                        getData['photo'] = viewUrl?.data?.data?.url
                    } catch (error) {
                        console.log('Profile image fetch error')
                    }
                }

                dispatch(setLoading(false))
                dispatch(setPatientData({ ...getData }))
                dispatch(setError(false))
                dispatch(setOtpError(false))
            })
            .catch((err) => {
                dispatch(logout())
                dispatch(setError(false))
                dispatch(setOtpError(false))
                dispatch(setLoading(false))
            })
    }
}
