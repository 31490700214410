import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
    root: {
        minHeight: (props) => props.height,
        '& input::placeholder': {
            fontSize: theme.typography.body1,
            color: theme.palette.common.black,
        },
    },
    input: {
        fontSize: theme.typography.body1,
        color: theme.palette.common.black,
    },
    cssFocused: {
        border: (props) => `${props.border} !important`,
    },
    notchedOutline: {
        border: (props) => `${props.border} !important`,
    },
}))
