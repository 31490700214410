import Card from '@mui/material/Card'
import React, { useCallback, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Booking from '../../Booking'
import { Link } from 'react-router-dom'
import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import calenderTick from '../../../../../assests/images/calenderTick.svg'
import closeIcon from '../../../../../assests/images/closeIcon.svg'

import SingleCalenderComp from '../../../../../shared/elements/DatePickerCustom/SingleCalenderComp'
import moment from 'moment'
import {
    endDateFilterToUTC,
    FilterToUTC,
    patientTimeZone,
    specialityOfPrivider,
    startDateFilterToUTC,
} from '../../../../../containers/AppointmentList/utils'
import {
    getAvailableAppointmentList,
    providerListForBook,
    requestGetAvatarUrl,
} from '../../../../../services/Appointments/appointments'
import LoadingNew from '../../../../../utilities/loading-page/LoadingNew'
import { ReactComponent as DeleteIcon } from '../../../../../assests/icons/closeIcon.svg'
import { useSelector } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { nameAvatar } from '../../../../utils'
import NoProviderComponent from './NoProviderComponent'
import ProviderFilter from './ProviderFilter'
import { useNotification } from '../../../MaterialUi/Notification'
import { stateList } from '../../../../../containers/AppointmentBooking/constant'

const Filters = ['Morning', 'Afternoon', 'Evening']

const Provider = ({
    findYourCareValues,
    selectATimeValues,
    providerDetails,
    setProviderVisitType,
    returnedErrorMessage = false,
    setReturnedErrorMessage,
    handlePreviousStep,
    clinicalPracticeNumber,
    handleSelectATimeValues,
    goToRequiredStep,
}) => {
    const notification = useNotification()

    const [timeBooking, setTimeBooking] = React.useState({
        0: false,
    })
    const [openCalender, setOpenCalender] = React.useState(false)
    const [displayDate, setDisplayDate] = React.useState(
        selectATimeValues?.date?.format('MM/DD/YYYY'),
    )
    const [timeSelected, setTimeSelected] = React.useState({
        0: false,
    })
    const [loading, setLoading] = React.useState(false)
    const [listOfProvider, setListOfProvider] = React.useState([])
    const [filterTime, setFilterTime] = React.useState({
        statrtTime: null,
        endTime: null,
    })
    const [visitType, setVisitType] = React.useState(null)
    const [availableList, setAvailableList] = React.useState([])
    const [appointmentList, setAppointmentList] = React.useState([])
    const [showErrorMessage, setShowErrorMessage] = React.useState(false)
    const [availabilityLoading, setAvailabilityLoading] = React.useState(false)

    const [filterProps, setFilterProps] = React.useState({
        open: false,
        filters: Filters.filter((item) => {
            if (selectATimeValues[item?.toLowerCase()]) return item
        }),
    })

    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const setSelectedTime = () => {
        if (
            (selectATimeValues?.morning &&
                selectATimeValues?.afternoon &&
                selectATimeValues?.evening) ||
            (selectATimeValues?.morning && selectATimeValues?.evening)
        ) {
            return setFilterTime({
                statrtTime: FilterToUTC(displayDate, 8, loggedinPatiendata?.timezone),
                endTime: FilterToUTC(displayDate, 22, loggedinPatiendata?.timezone),
            })
        } else if (selectATimeValues?.morning && selectATimeValues?.afternoon) {
            return setFilterTime({
                statrtTime: FilterToUTC(displayDate, 8, loggedinPatiendata?.timezone),
                endTime: FilterToUTC(displayDate, 17, loggedinPatiendata?.timezone),
            })
        } else if (selectATimeValues?.afternoon && selectATimeValues?.evening) {
            return setFilterTime({
                statrtTime: FilterToUTC(displayDate, 12, loggedinPatiendata?.timezone),
                endTime: FilterToUTC(displayDate, 22, loggedinPatiendata?.timezone),
            })
        } else if (selectATimeValues?.morning) {
            return setFilterTime({
                statrtTime: FilterToUTC(displayDate, 8, loggedinPatiendata?.timezone),
                endTime: FilterToUTC(displayDate, 12, loggedinPatiendata?.timezone),
            })
        } else if (selectATimeValues?.afternoon) {
            return setFilterTime({
                statrtTime: FilterToUTC(displayDate, 12, loggedinPatiendata?.timezone),
                endTime: FilterToUTC(displayDate, 17, loggedinPatiendata?.timezone),
            })
        } else if (selectATimeValues?.evening) {
            return setFilterTime({
                statrtTime: FilterToUTC(displayDate, 17, loggedinPatiendata?.timezone),
                endTime: FilterToUTC(displayDate, 22, loggedinPatiendata?.timezone),
            })
        }
    }
    const setTheVisitType = () => {
        if (
            findYourCareValues?.bookingWith === 'yes' &&
            findYourCareValues?.lookingFor === 'Psychiatry'
        ) {
            setVisitType('PSY90792')
        } else if (
            findYourCareValues?.bookingWith === 'no' &&
            findYourCareValues?.lookingFor === 'Psychiatry'
        ) {
            setVisitType('PSY99214')
        } else if (
            findYourCareValues?.bookingWith === 'yes' &&
            findYourCareValues?.lookingFor === 'therapy'
        ) {
            setVisitType('THR90791')
        } else if (
            findYourCareValues?.bookingWith === 'no' &&
            findYourCareValues?.lookingFor === 'therapy'
        ) {
            setVisitType('THR90837')
        }
    }
    const handleSelectTime = (index, date, ProviderDetail) => {
        if (returnedErrorMessage) {
            setReturnedErrorMessage(false)
        }
        setTimeSelected(() => {
            if (timeSelected[index]) {
                providerDetails({}, {})
                if (showErrorMessage) {
                    setShowErrorMessage(false)
                }
                return {
                    [index]: false,
                }
            }
            return {
                [index]: true,
            }
        })
        if (!timeSelected[index]) {
            const hasOverlap = appointmentList.some((item) => {
                return (
                    moment(item.start).tz(loggedinPatiendata?.timezone || 'US/Pacific') <
                        moment(date.end_time).tz(loggedinPatiendata?.timezone || 'US/Pacific') &&
                    moment(item.end).tz(loggedinPatiendata?.timezone || 'US/Pacific') >
                        moment(date.start_time).tz(loggedinPatiendata?.timezone || 'US/Pacific')
                )
            })

            if (hasOverlap) {
                setShowErrorMessage(true)
                providerDetails({}, {})
            } else {
                setShowErrorMessage(false)
                providerDetails(date, ProviderDetail)
            }
        }
    }

    const handleCloseTimeBookingMode = () => {
        setTimeBooking(() => {
            return {
                0: false,
            }
        })
        setTimeSelected(() => {
            return {
                0: false,
            }
        })
        providerDetails({}, {})
        closeErrorMessage()
    }

    const handleOpenTimeBookingMode = async (index, id) => {
        try {
            setAvailableList([])
            setTimeSelected(() => {
                return {
                    0: false,
                }
            })
            setTimeBooking(() => {
                return {
                    [index]: true,
                }
            })
            const payload = {
                provider_id: id,
                start: filterTime?.statrtTime.format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: filterTime?.endTime.format('YYYY-MM-DDTHH:mm:ss[Z]'),
                visit_type: visitType,
                timezone: loggedinPatiendata?.timezone || 'US/Pacific'
            }
            setAvailabilityLoading(true)
            const res = await getAvailableAppointmentList(payload)
            let availableList =
                res?.data?.data?.availableSlotLists?.length &&
                res?.data?.data?.availableSlotLists.filter((item) =>
                    patientTimeZone(item?.start_time, loggedinPatiendata?.timezone).isSameOrAfter(
                        moment().tz(loggedinPatiendata?.timezone || 'US/Pacific'),
                    ),
                )
            if (
                selectATimeValues?.morning &&
                !selectATimeValues?.afternoon &&
                selectATimeValues?.evening
            ) {
                const freeTimeStart = FilterToUTC(displayDate, 12, loggedinPatiendata?.timezone)
                const freeTimeEnd = FilterToUTC(displayDate, 17, loggedinPatiendata?.timezone)
                const filterTime =
                    res?.data?.data?.availableSlotLists.length &&
                    res?.data?.data?.availableSlotLists.filter(
                        (item) =>
                            (moment(item.start_time).utc() < freeTimeStart &&
                                moment(item.end_time).utc() < freeTimeStart) ||
                            (moment(item.start_time).utc() > freeTimeEnd &&
                                moment(item.end_time).utc() > freeTimeEnd),
                    )
                availableList = filterTime
            }
            const filteredAvailable =
                availableList.length &&
                (res?.data?.data?.appointments?.length > 0
                    ? availableList?.filter((slot) => {
                          return !res?.data?.data?.appointments.some((appointment) => {
                              return (
                                  moment(slot.start_time) < moment(appointment.end) &&
                                  moment(slot.end_time) > moment(appointment.start)
                              )
                          })
                      })
                    : availableList)
            setProviderVisitType(res?.data?.data?.visitType)
            setAvailableList(filteredAvailable || [])
            setAppointmentList(res?.data?.data?.appointments || [])
            setAvailabilityLoading(false)
        } catch (err) {
            setAvailabilityLoading(false)
        }
    }

    const openTheCalender = () => {
        setOpenCalender(true)
    }
    const closeTheCalender = () => {
        setOpenCalender(false)
    }
    const dateSelected = (value) => {
        setDisplayDate(value)
    }
    const getListOfProviderToBook = async () => {
        try {
            setLoading(true)
            setListOfProvider([])
            const payload = {
                start_date: startDateFilterToUTC(displayDate, loggedinPatiendata?.timezone),
                end_date: endDateFilterToUTC(displayDate, loggedinPatiendata?.timezone),
                is_morning: selectATimeValues?.morning,
                is_afternoon: selectATimeValues?.afternoon,
                is_evening: selectATimeValues?.evening,
                timezone: loggedinPatiendata?.timezone || 'US/Pacific',
            }
            const res = await providerListForBook(payload)
            const location = stateList.find((item)=>(item?.stateLabel === findYourCareValues?.locatedTime))
            const providerListCheckState = res?.data?.data.length && res?.data?.data.filter((provider) => {
                const hasMatchedState = provider?.states?.some((stateItem) => (
                    stateItem.state === location.state
                ));
                return hasMatchedState;
            });
            const providerListCheckSpecialities = providerListCheckState.length && providerListCheckState.filter((provider) => {
                return provider.specialities.length > 0 && provider.specialities.some((speciality) => {
                    if (typeof speciality === 'object') {
                        return speciality.value.toLowerCase() === findYourCareValues?.lookingFor.toLowerCase();
                    } else {
                        return speciality.toLowerCase() === findYourCareValues?.lookingFor.toLowerCase();
                    }
                });
            });
            // console.log("providerListCheckState",providerListCheckState)
            // console.log("providerListCheckSpecialities",providerListCheckSpecialities)
            
            const providerData = providerListCheckSpecialities

            const avatarIds = providerData.length > 0 ? providerData
                ?.filter((item) => item?.avatar !== '')
                ?.map((item) => {
                    return `provider-user-avatar/${item?.avatar}`
                })
                :[]

            if (avatarIds?.length === 0) {
                setListOfProvider(providerData || [])
            } else {
                const signedUrlRes = await requestGetAvatarUrl(avatarIds)

                const signedUrlDataObj = {}
                signedUrlRes?.data?.data?.forEach((item) => {
                    const key = item?.key?.split('/')[1]
                    signedUrlDataObj[key] = item?.url
                })

                const formattedProviderData = providerData?.map((item) => {
                    return {
                        ...item,
                        avatar: signedUrlDataObj[item?.avatar] || '',
                    }
                })

                setListOfProvider(formattedProviderData || [])
            }
            setLoading(false)
        } catch (err) {
            console.log('err', err)
            setLoading(false)
        }
    }
    const closeErrorMessage = () => {
        setShowErrorMessage(false)
    }

    const handleFilterOpenOrClose = (anchorEl = null) =>
        setFilterProps((prev) => ({
            ...prev,
            open: anchorEl,
        }))

    const handleFilterControl = useCallback(
        (fieldValue, fieldName) => {
            let filters = filterProps?.filters

            if (fieldValue) {
                filters = [
                    ...filters,
                    Filters.filter((item) => item?.toLowerCase() === fieldName)[0],
                ]
            } else {
                filters = filters?.filter((item) => item?.toLowerCase() !== fieldName)
            }

            if (filters?.length === 0) {
                notification('Atleast one filter should be selected!', 'warning')
                return
            }

            setFilterProps((prev) => ({ ...prev, filters }))
            handleSelectATimeValues(fieldValue, fieldName)
        },
        [selectATimeValues],
    )

    const headerText = useMemo(
        () =>
            listOfProvider?.length > 0
                ? 'Select the provider you want to book with!'
                : !loading
                ? 'Sorry! No provider was found that matched your selections.'
                : 'Select the provider you want to book with!',
        [listOfProvider, loading],
    )

    useEffect(() => {
        getListOfProviderToBook()
        setSelectedTime()
        handleCloseTimeBookingMode()
    }, [displayDate, filterProps?.filters])

    useEffect(() => {
        setTheVisitType()
    }, [])
    useEffect(() => {
        showErrorMessage && setReturnedErrorMessage(false)
    }, [showErrorMessage])
    return (
        <div className="booking_boxes provider_component">
            {showErrorMessage && !returnedErrorMessage && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '8px',
                        background: '#FFD9D8',
                        padding: '7px',
                    }}>
                    <img
                        src={closeIcon}
                        alt=""
                        onClick={() => {
                            closeErrorMessage()
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                    <Typography className="error-message">
                        Sorry this appointment has been booked! Please select another.{' '}
                    </Typography>
                </div>
            )}
            {!returnedErrorMessage && (
                <div>
                    <Typography className="step-headers">{headerText} </Typography>
                </div>
            )}
            {!showErrorMessage && returnedErrorMessage && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            background: '#FFD9D8',
                            padding: '7px',
                        }}>
                        <img
                            src={closeIcon}
                            alt=""
                            onClick={() => {
                                setReturnedErrorMessage(false)
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        <Typography className="error-message">
                            Sorry this appointment has been booked!
                        </Typography>
                    </div>
                    <Typography className="step-headers">
                        Please select another appointment!.{' '}
                    </Typography>
                </>
            )}
            
            <ProviderFilter
                filterProps={filterProps}
                handleFilterOpenOrClose={handleFilterOpenOrClose}
                selectATimeProps={{selectATimeValues, handleSelectATimeValues}}
                onChangeCheckboxValue={handleFilterControl}
            />

            <ProviderFilter
                filterProps={filterProps}
                handleFilterOpenOrClose={handleFilterOpenOrClose}
                selectATimeProps={{ selectATimeValues, handleSelectATimeValues }}
                onChangeCheckboxValue={handleFilterControl}
            />

            <div className="body card-raw">
                <div className="provider_header">
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.091 2.90862C15.9028 5.72047 15.9026 10.2793 13.091 13.091C10.2791 15.9027 5.7204 15.9027 2.90856 13.091C0.0968847 10.2793 0.0968847 5.72047 2.90856 2.90862C5.72024 0.0969447 10.279 0.0967847 13.091 2.90862ZM10.2705 5.96079L7.36728 8.86415L5.72904 7.22575C5.21352 6.71022 4.43032 7.49343 4.94584 8.00895L6.97576 10.0389C7.19208 10.2552 7.54264 10.2552 7.75896 10.0389L11.0538 6.74399C11.5694 6.22847 10.7862 5.44527 10.2705 5.96079ZM14.0921 7.99983C14.0921 4.63503 11.3644 1.9075 7.99976 1.9075C4.63496 1.9075 1.90744 4.63503 1.90744 7.99983C1.90744 11.3646 4.63496 14.0921 7.99976 14.0921C11.3646 14.0921 14.0921 11.3646 14.0921 7.99983Z"
                            fill="#15A452"
                        />
                    </svg>
                    <Typography>
                        We found{' '}
                        <b>
                            {listOfProvider.length}{' '}
                            {listOfProvider.length > 1 ? 'providers' : 'provider'}
                        </b>{' '}
                        for you to choose from!
                    </Typography>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
                    <Grid
                        container
                        className={`grey_btn_like_holder select-a-time-body selectTheDate${
                            listOfProvider?.length > 0 ? '' : ' selectTheDate-highlighted'
                        }`}
                        onClick={openTheCalender}
                        style={{ position: 'relative', minWidth: '150px' }}>
                        <img src={calenderTick} alt="" style={{ paddingLeft: '5px' }} />
                        <Typography className="dateText">
                            {moment(displayDate).format('MM/DD/YYYY')}
                        </Typography>
                        <Box
                            className="task-user-popper-containerTwo"
                            style={{
                                position: 'absolute',
                                zIndex: 10,
                                top: '33px',
                                left: '-2px',
                            }}>
                            <SingleCalenderComp
                                selectedDate={dateSelected}
                                displayDate={displayDate}
                                openCalender={openCalender}
                                closeTheCalender={closeTheCalender}
                                minDate={moment().add(1, 'days')}
                            />
                        </Box>
                    </Grid>

                    <div className='grey_btn_like_holder d-lg-none' onClick={(event) => handleFilterOpenOrClose(event?.currentTarget)}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 5.5C4.5 5.22386 4.72386 5 5 5H16C16.2761 5 16.5 5.22386 16.5 5.5C16.5 5.77614 16.2761 6 16 6H5C4.72386 6 4.5 5.77614 4.5 5.5ZM6.5 9.5C6.5 9.22386 6.72386 9 7 9H14C14.2761 9 14.5 9.22386 14.5 9.5C14.5 9.77614 14.2761 10 14 10H7C6.72386 10 6.5 9.77614 6.5 9.5ZM8.5 13.5C8.5 13.2239 8.72386 13 9 13H12C12.2761 13 12.5 13.2239 12.5 13.5C12.5 13.7761 12.2761 14 12 14H9C8.72386 14 8.5 13.7761 8.5 13.5Z" fill="#303E4E"/>
                        </svg> 
                        <span>Filter</span>                   
                    </div>
                </div>

                <div className="provider_filter">
                    <span
                        className="title_select d-md-none"
                        onClick={(event) => handleFilterOpenOrClose(event?.currentTarget)}
                        style={{ cursor: 'pointer' }}>
                        <Typography>Filters</Typography>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="7"
                            viewBox="0 0 10 7"
                            fill="none">
                            <path
                                d="M5.22006 6.01795L0.701193 0.0335299L9.69746 0.00244053L5.22006 6.01795Z"
                                fill="#667689"
                            />
                        </svg>
                    </span>
                    {filterProps?.filters?.length &&
                        filterProps?.filters?.map((filterItem) => {
                            return (
                                <Chip
                                    key={filterItem}
                                    label={filterItem}
                                    onDelete={() =>
                                        handleFilterControl(false, filterItem?.toLowerCase())
                                    }
                                    deleteIcon={<DeleteIcon style={{ width: 12, height: 12 }} />}
                                />
                            )
                        })}
                </div>

                <div
                    className="provider_card_container"
                    style={{
                        position: 'relative',
                        maxHeight: showErrorMessage ? '70%' : '100%',
                        maxWidth: 930,
                    }}>
                    {loading && (
                        <div className="providerLoading">
                            <CircularProgress />
                        </div>
                    )}
                    {listOfProvider.length > 0 &&
                        listOfProvider.map((providerItem, providerItemIndex) => {
                            return (
                                <div
                                    key={providerItemIndex}
                                    className={`provider_card ${
                                        timeBooking[providerItemIndex] ? 'provider_card_diff' : ''
                                    }`}>
                                    <div className="card_content">
                                        <div className="provider_card_content">
                                            <div className={`left_section ${timeBooking[providerItemIndex] ? 'mb-0' : ''}`}>
                                                <div>
                                                    {providerItem?.avatar ? (
                                                        <img
                                                            style={{
                                                                borderRadius: '80px',
                                                                border: '2px solid #D8D8D8',
                                                            }}
                                                            alt="avatar"
                                                            src={providerItem?.avatar}
                                                            height={63}
                                                            width={60}
                                                        />
                                                    ) : (
                                                        nameAvatar(
                                                            `${providerItem?.first_name} ${providerItem?.last_name}`,
                                                        )
                                                    )}
                                                </div>
                                                <div className="about_doctor">
                                                    <Typography className="name">
                                                        {`${providerItem?.first_name} ${providerItem?.last_name}`}
                                                    </Typography>
                                                    <Typography className="practice">
                                                        {specialityOfPrivider(providerItem?.specialities)}
                                                    </Typography>
                                                    {/* <Typography className="details">
                                                        View provider details
                                                    </Typography> */}
                                                </div>
                                            </div>
                                            <div className="right_section position-md-static">
                                                {timeBooking[providerItemIndex] && (
                                                    <IconButton
                                                        className="close_button"
                                                        onClick={() =>
                                                            handleCloseTimeBookingMode(
                                                                providerItemIndex,
                                                            )
                                                        }>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 12 12"
                                                            fill="none">
                                                            <path
                                                                d="M1 1L11 11M1 11L11 1"
                                                                stroke="#667689"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                        </svg>
                                                    </IconButton>
                                                )}
                                                {!timeBooking[providerItemIndex] && <Button
                                                    className="book_button"
                                                    onClick={() =>
                                                        handleOpenTimeBookingMode(
                                                            providerItemIndex,
                                                            providerItem?.id,
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="success">
                                                    Book now
                                                </Button>}
                                            </div>
                                        </div>
                                        {timeBooking[providerItemIndex] && (
                                            <>
                                                <Divider />
                                                <div
                                                    className="time_select_container"
                                                    style={{ height: '138px', overflow: 'auto' }}>
                                                    {availabilityLoading && (
                                                        <div className="loadingAvailability">
                                                            <CircularProgress />
                                                        </div>
                                                    )}
                                                    {availableList.length > 0 &&
                                                        availableList.map(
                                                            (timeItem, timeItemIndex) => {
                                                                return (
                                                                    <Button
                                                                        className={`time_item ${
                                                                            timeSelected[
                                                                                timeItemIndex
                                                                            ]
                                                                                ? 'time_item_selected'
                                                                                : ''
                                                                        }`}
                                                                        disabled={patientTimeZone(
                                                                            timeItem?.start_time,
                                                                            loggedinPatiendata?.timezone,
                                                                        ).isSameOrBefore(
                                                                            moment().tz(
                                                                                loggedinPatiendata?.timezone ||
                                                                                    'US/Pacific',
                                                                            ),
                                                                        )}
                                                                        onClick={() =>
                                                                            handleSelectTime(
                                                                                timeItemIndex,
                                                                                timeItem,
                                                                                providerItem,
                                                                            )
                                                                        }
                                                                        key={timeItemIndex}
                                                                        variant="outlined"
                                                                        size="small">
                                                                        {patientTimeZone(
                                                                            timeItem?.start_time,
                                                                            loggedinPatiendata?.timezone,
                                                                        ).format('h:mm A')}
                                                                    </Button>
                                                                )
                                                            },
                                                        )}
                                                    {availableList.length === 0 &&
                                                        !availabilityLoading && (
                                                            <Typography>No record found</Typography>
                                                        )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    {listOfProvider.length === 0 && !loading && (
                        <NoProviderComponent
                            goToRequiredStep={goToRequiredStep}
                            clinicalPracticeNumber={clinicalPracticeNumber}
                        />
                    )}
                </div>
            </div>
            {/* <div><input type='radio'/><span>Yes, this is my first time</span></div> */}
        </div>
    )
}

export default Provider
