export const clinicProfileForm = [
    {
        id: 'clinic-name',
        label: 'Clinic Name',
        name: 'clinic_name',
        placeHolder: 'Clinic Name',
        inputType: 'textField',
        value: '',
        required: true,
    },
    {
        id: 'clinic-add1',
        label: 'Address Line 1',
        name: 'add1',
        placeHolder: 'Address Line 1',
        inputType: 'textField',
        value: '',
        required: true,
        view: true,
    },
    {
        id: 'clinic-add2',
        label: 'Address Line 2',
        name: 'add2',
        placeHolder: 'Address Line 2',
        inputType: 'textField',
        value: '',
        required: false,
        view: true,
    },
    {
        id: 'clinic-city',
        label: 'City',
        name: 'city',
        placeHolder: 'City',
        inputType: 'textField',
        value: '',
        required: true,
        view: true,
    },
    {
        id: 'clinic-state',
        label: 'State',
        name: 'state',
        placeHolder: 'state',
        inputType: 'autoComplete',
        value: '',
        required: true,
    },
    {
        id: 'clinic-zip',
        label: 'Zip code',
        name: 'zip',
        placeHolder: 'Zip code',
        inputType: 'textField',
        value: '',
        required: true,
        type: 'number',
        view: true,
    },
    {
        id: 'clinic_type',
        label: 'Clinic Type',
        name: 'clinic_type',
        placeHolder: 'Clinic Type',
        inputType: 'dropDown',
        options: [
            { name: 'Owned', value: 'owned' },
            { name: 'Contracted', value: 'contracted' },
        ],
        value: '',
        required: true,
        type: 'number',
        view: true,
    },
]
