import {
    Container,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core'
import EmailIcon from '@mui/icons-material/Email'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { errorMessages } from '../../constants/errorMessages'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import { ButtonSpinner } from '../custom'
import './signin.scss'
import { useDispatch } from 'react-redux'
import { getLoggedInUserDetails } from '../../store/actions/login'
import bg1 from '../../assests/images/bg1.png'
import bg2 from '../../assests/images/bg2.png'
import bg3 from '../../assests/images/bg3.png'
import bg4 from '../../assests/images/bg4.png'
import bg5 from '../../assests/images/bg5.png'

const bgImages = [bg1, bg2, bg3, bg4, bg5]
// Computation
const randomBgImage = bgImages[Math.floor(Math.random() * bgImages.length)]

const SignIn = ({ handleSubmit, handleSendLink, handleMagicLink }) => {
    const formikRef = useRef()
    const [showPassword, setShowPassword] = React.useState(false)
    const [error, setError] = React.useState('')
    const dispatch = useDispatch()
    const [step, setStep] = React.useState(1)
    const notification = useNotification()
    const history = useHistory()
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const validateSchema = Yup.object().shape({
        // email: Yup.string().required().email(errorMessages.validEmailId),
        username: Yup.string().when('email', {
            is: (email) => !email, // When email is filled
            then: Yup.string().required().email(errorMessages.validEmailId),
        }),
        password: Yup.string()
            .when('email', {
                is: (email) => !email, // When email is filled
                then: Yup.string().required('Password is required'),
            })
            .when('step', {
                is: 1,
                then: Yup.string().required('Password is required'),
            }),
    })

    const handleForgotPassword = async (values) => {
        if (!values.username) {
            notification('Please Enter the email', 'error')
        } else {
            try {
                const res = await handleSendLink({ email: values.username })
                if (res && res?.data?.status) {
                    setStep(2)
                } else {
                    notification(res?.data?.message || 'Something is wrong', 'error')
                }
            } catch (error) {
                notification('Something is wrong', 'error')
            }
        }
    }
    return (
        <Container className="sign-in-wrapper">
            <Grid
                container
                className="form-wrapper"
                style={{ height: step === 1 ? 'auto' : '90vh' }}>
                <Grid item xs={12} sm={6} span={12}>
                    <div
                        className="form-img"
                        style={{
                            // background:
                            // 'url(http://source.unsplash.com/620x700/?medical)',
                            background: `url(${randomBgImage})`,
                            borderRadius: '10px',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} span={12} ml={2} className="right-panel">
                    <Grid className="logo-wrapper">
                        <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                    </Grid>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            email: '',
                            username: '',
                            password: '',
                        }}
                        validationSchema={validateSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                if (values.email) {
                                    const res = await handleMagicLink({
                                        email: values.email,
                                    })
                                    if (res && res?.data?.data?.status) {
                                        notification('Link send in the mail successful!', 'success')
                                        history.push(
                                            `/verify-magic-link?data=${res?.data?.data?.data}`,
                                        )
                                    } else {
                                        notification(
                                            res?.data?.data.message || 'Something is wrong',
                                            'error',
                                        )
                                    }
                                } else {
                                    if (step === 1) {
                                        const res = await handleSubmit({
                                            username: values.username,
                                            password: values.password,
                                        })
                                        if (res && res?.data?.status) {
                                            const { accessToken, refreshToken } = res?.data?.data
                                            localStorage.setItem('access_token', accessToken)
                                            localStorage.setItem('refresh_token', refreshToken)
                                            dispatch(getLoggedInUserDetails())
                                            history.push('/dashboard')
                                        } else {
                                            setError(
                                                res?.data?.error || 'Something is wrong',
                                                'error',
                                            )
                                        }
                                    }
                                    if (step === 2) {
                                        const res = await handleSendLink({
                                            email: values.username,
                                        })
                                        if (res && res?.data?.status) {
                                            notification(
                                                'Link resend in the mail successful!',
                                                'success',
                                            )
                                        } else {
                                            notification(
                                                res?.data?.message || 'Something is wrong',
                                                'error',
                                            )
                                        }
                                    }
                                }
                            } catch (error) {
                                notification('Something is wrong', 'error')
                            }
                            setSubmitting(false)
                        }}>
                        {({
                            errors,
                            touched,
                            values,
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                        }) => {
                            return (
                                <form
                                    className="form registration"
                                    onSubmit={handleSubmit}
                                    noValidate>
                                    <div className="inner-wrapper-logo">
                                        <img
                                            className="mobile-logo"
                                            src={logo}
                                            alt="TeleMed2U"></img>
                                    </div>
                                    <h1>
                                        {step === 1
                                            ? 'Sign in to your patient portal!'
                                            : step === 2
                                            ? 'Forgot password link sent!'
                                            : ''}
                                    </h1>
                                    {step === 1 && (
                                        <p className="para-tag">
                                            Enter your email information to sign in to your account.
                                        </p>
                                    )}
                                    {step === 2 && (
                                        <p className="resend-email-paragraph">
                                            {`We have sent you a link to ${values.username}.Please follow the instructions reset the password to your patient portal.`}
                                        </p>
                                    )}
                                    {step === 1 && (
                                        <>
                                            {' '}
                                            <h2>Sign in with a magic link</h2>
                                            <FormLabel className="input-label" component="legend">
                                                Email Address
                                            </FormLabel>
                                            <TextField
                                                id="auto-reg-email"
                                                required
                                                // error={errors.email && touched.email}
                                                // helperText={
                                                //     errors.email && touched.email ? errors.email : ''
                                                // }
                                                variant="outlined"
                                                size="small"
                                                className="sign-in-text-input"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={values.email}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                aria-label="toggle  visibility"
                                                                edge="start">
                                                                <EmailIcon
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: '12px',
                                                                        marginLeft: '5px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <div className="submit">
                                                <ButtonSpinner
                                                    disabled={!values.email}
                                                    isLoading={
                                                        values?.email && Boolean(isSubmitting)
                                                    }
                                                    label={'Send Magic link'}
                                                    className="send-magic-submit"
                                                    sx={{
                                                        width: '100%',
                                                        opacity: !values.email ? '0.7' : 1,
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                <span style={{ textAlign: 'center' }}>OR</span>
                                            </div>
                                            <h2>Sign in with your email</h2>
                                            <FormLabel className="input-label" component="legend">
                                                Email Address
                                            </FormLabel>
                                            <TextField
                                                id="auto-reg-email"
                                                required
                                                error={Boolean(
                                                    (errors.username && touched.username) || error,
                                                )}
                                                helperText={
                                                    errors.username && touched.username
                                                        ? errors.username
                                                        : ''
                                                }
                                                variant="outlined"
                                                size="small"
                                                className="sign-in-text-input"
                                                name="username"
                                                placeholder="Enter your email"
                                                value={values.username}
                                                type="email"
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                aria-label="toggle  visibility"
                                                                edge="start">
                                                                <EmailIcon
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: '12px',
                                                                        marginLeft: '5px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <FormLabel className="input-label" component="legend">
                                                Password
                                            </FormLabel>
                                            <TextField
                                                placeholder="Enter your password"
                                                id="auto-reg-password"
                                                required
                                                type={showPassword ? 'text' : 'password'}
                                                error={Boolean(
                                                    (errors.password && touched.password) || error,
                                                )}
                                                helperText={
                                                    (errors.password && touched.password) || error
                                                        ? errors.password || error
                                                        : ''
                                                }
                                                variant="outlined"
                                                size="small"
                                                className="sign-in-text-input"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                edge="end">
                                                                {showPassword ? (
                                                                    <VisibilityOffOutlinedIcon
                                                                        style={{
                                                                            color: '#667689',
                                                                            marginRight: '10px',
                                                                            fontSize: '18px',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <VisibilityOutlinedIcon
                                                                        style={{
                                                                            color: '#667689',
                                                                            marginRight: '10px',
                                                                            fontSize: '18px',
                                                                        }}
                                                                    />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />{' '}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}>
                                                <span
                                                    style={{
                                                        textAlign: 'end',
                                                        color: 'rgba(85, 113, 198, 0.70)',
                                                        fontSize: '14px',
                                                        fontWeight: '400',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleForgotPassword(values)}>
                                                    Forgot password?
                                                </span>
                                            </div>
                                            <div className="submit">
                                                <ButtonSpinner
                                                    disabled={
                                                        values.email ||
                                                        (!values.email &&
                                                            (!values.username ||
                                                                !values.password)) ||
                                                        Boolean(isSubmitting)
                                                    }
                                                    isLoading={
                                                        !values?.email && Boolean(isSubmitting)
                                                    }
                                                    label={'Sign in with password'}
                                                    className="signup-submit"
                                                    sx={{
                                                        width: '100%',
                                                        opacity:
                                                            values.email ||
                                                            (!values.email &&
                                                                (!values.username ||
                                                                    !values.password))
                                                                ? '0.5'
                                                                : 1,
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {step === 2 && (
                                        <>
                                            <FormLabel
                                                className="input-label"
                                                component="legend"
                                                style={{
                                                    marginTop: 25,
                                                    color: '#303E4E',
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                }}>
                                                Didn’t get an email?
                                            </FormLabel>
                                            <div className="submit">
                                                <ButtonSpinner
                                                    disabled={Boolean(isSubmitting)}
                                                    isLoading={Boolean(isSubmitting)}
                                                    label={'Resend reset password link'}
                                                    className="btn-resend"
                                                    sx={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                </form>
                            )
                        }}
                    </Formik>
                    <Grid className="form-footer">
                        <p className="footer-tag">
                            If you have any questions or need assistance, please call us at{' '}
                            <a>(562) 268-0955</a>.{' '}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SignIn
