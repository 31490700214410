import * as actionType from '../actionType'
import {
    getNameFormat,
    formatPhone,
    formatRoleName,
} from './../../utilities'

const initialState = {
    adminProviderList: [],

    addStaffProfile: [],
    staffSearchList: [],
    editStaffProfile: [],

    searchMeta: {
        currentPage: 1,
        totalCount: 0,
    },
}

const formatStaffSearchResult = (staffList) =>
    staffList.map((staff) => {
        const {
            name,

            user_id,
            user: { mob, email, is_active },
            role: { role_name },
        } = staff

        const staffName = getNameFormat(name, '')

        return {
            staffName,

            phoneNumber: formatPhone(
                mob?.replace(/\D/g, '')?.slice(-10),
            ),
            email: email.toLowerCase(),
            role_name: formatRoleName(role_name),
            is_active,
            user_id,
        }
    }) ?? []
const admin = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_ADMIN_PROVIDER_LIST: {
            return {
                ...state,
                adminProviderList: action.value,
            }
        }

        case actionType.SET_SEARCH_STAFF_LIST: {
            const {
                page,
                totalCount: totalStaffRecord,
                staffAdmins,
            } = action.value

            const staffSearchList = staffAdmins?.length
                ? formatStaffSearchResult(staffAdmins)
                : []
            const totalCount =
                Math.ceil(totalStaffRecord / 10) ?? 0

            return {
                ...state,
                staffSearchList,

                searchMeta: {
                    currentPage: page ?? 1,
                    totalCount,
                },
            }
        }
        case actionType.SET_STAFF_PROFILE: {
            return {
                ...state,
                addStaffProfile: action.value,
            }
        }
        case actionType.SET_STAFF_RESULT_LIST: {
            const { page, totalCount, staffAdmins } =
                action.value

            const staffSearchList = staffAdmins?.length
                ? formatStaffSearchResult(staffAdmins)
                : []
            // const totalCount =
            //     Math.ceil(totalStaffRecord / 10) ?? 0;
            return {
                ...state,
                staffSearchList,

                searchMeta: {
                    currentPage: page ?? 1,
                    totalCount,
                },
            }
        }
        case actionType.SET_STAFF_EDIT_PROFILE: {
            return {
                ...state,
                editStaffProfile: action.value,
            }
        }
        case actionType.SET_UPDATE_STAFF_STATUS: {
            const { userId, status } = action.value
            return {
                ...state,
                staffSearchList: state.staffSearchList.map(
                    (admin) => {
                        const { user_id, is_active } = admin

                        return {
                            ...admin,
                            is_active:
                                user_id === userId
                                    ? status
                                    : is_active,
                        }
                    },
                ),
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

export default admin
