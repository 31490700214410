import React from 'react'
import MagicLink from '../../components/SignIn/MagicLink'
import { verifyMagicLink } from '../../services/Login/login'
import { decryptData } from '../../utilities'
const MagicLinkContainer = () => {
    const [decryptedData, setDecryptedData] = React.useState(null)
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const encryptedData = queryParams.get('data')
        try {
            const parsedData = decryptData(encryptedData)
            const redirect = queryParams.get('redirect')
            setDecryptedData({
                ...parsedData,
                isSSO: redirect && redirect === 'ok',
            })
        } catch (error) {
            console.error('Error parsing decrypted data:', error)
        }
    }, [])

    const handleResendLink = async (values) => {
        try {
            const res = await verifyMagicLink(values)
            return res
        } catch (error) {
            return error
        }
    }
    return (
        <div>
            <MagicLink decryptedData={decryptedData} handleResendLink={handleResendLink} />
        </div>
    )
}

export default MagicLinkContainer
