import React from 'react'

import {
    Grid,
    Typography,
    FormLabel,
} from '@material-ui/core'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../../../utilities'

const PatientDepents = (props) => {
    const { patientProfile } = props
    const minorArr = patientProfile?.minors
    return (
        <>
            <Grid container className="borderBottom">
                <Grid item xs={12} lg={12} className="pTB3">
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className="card-title-align">
                        <Typography
                            variant="subtitle1"
                            className="card-title">
                            Dependents
                        </Typography>
                    </Grid>
                    <Grid container>
                        {minorArr?.map((item, i) => (
                            <>
                                <Grid
                                    container
                                    xs={6}
                                    lg={5}>
                                    <Grid
                                        item
                                        lg={6}
                                        xs={12}
                                        className="">
                                        <Grid item xs={12}>
                                            <FormLabel
                                                variant="subtitle2"
                                                className="detail-item-title">
                                                First Name
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                className="detail-item-title colorDark">
                                                {capitalizeFirstLetter(
                                                    item
                                                        ?.patient
                                                        ?.first_name,
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        xs={12}
                                        className="">
                                        <Grid item xs={12}>
                                            <FormLabel
                                                variant="subtitle2"
                                                className="detail-item-title ">
                                                Last Name
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                className="detail-item-title colorDark">
                                                {/* {patientProfile.last_name} */}
                                                {
                                                    item
                                                        ?.patient
                                                        ?.last_name
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={6}
                                        xs={12}
                                        className="">
                                        <Grid item xs={12}>
                                            <FormLabel
                                                variant="subtitle2"
                                                className="detail-item-title ">
                                                Date of
                                                Birth
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                className="detail-item-title colorDark">
                                                {/* {patientProfile.dob} */}
                                                {moment(
                                                    item
                                                        ?.patient
                                                        ?.dob,
                                                ).format(
                                                    'MM/DD/YYYY',
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={6}
                                        xs={12}
                                        className="">
                                        <Grid item xs={12}>
                                            <FormLabel
                                                variant="subtitle2"
                                                className="detail-item-title ">
                                                Gender
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                className="detail-item-title colorDark capitalize">
                                                {/* {patientProfile.gender} */}
                                                {
                                                    item
                                                        ?.patient
                                                        ?.gender
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={6}
                                        xs={12}
                                        className="">
                                        <Grid item xs={12}>
                                            <FormLabel
                                                variant="subtitle2"
                                                className="detail-item-title ">
                                                Relation
                                            </FormLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                className="detail-item-title colorDark capitalize">
                                                {
                                                    item
                                                        ?.patient
                                                        ?.relation
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default PatientDepents
