import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Typography } from '@material-ui/core'
import { stateList } from '../../../../../containers/AppointmentBooking/constant'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const LocatedTime = ({ value, setValue, name }) => {
    return (
        <div className="booking_boxes">
            <div>
                <Typography className="step-headers">
                    Where will you be located during the time of your appointment?{' '}
                </Typography>
                <Typography className="step-sub-headers">
                    This selection is to ensure you are working with a provider licensed in the state you will be in at the time of your appointment.
                </Typography>
            </div>
            <div className="card-raw">
                <Typography className='heading_label d-lg-none'>Location <span className='required'>*</span></Typography>
                <Autocomplete
                    className="located_state_autocomplete"
                    value={value}
                    options={stateList.map((stateListItem) => stateListItem.stateLabel)}
                    getOptionLabel={(states) => states}
                    onChange={(_, newValue) => {
                        setValue(newValue ?? '', name)
                    }}
                    popupIcon={<ExpandMoreIcon />}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField
                            name="Textname"
                            {...params}
                            placeholder="Enter your state"
                            variant="outlined"
                        />
                    )}
                />
            </div>
        </div>
    )
}

export default LocatedTime
