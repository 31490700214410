export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm:ss'

export const VIEW = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
    YEAR: 'year',
}

export const EVENT_TYPE = {
    AVAILABILITY: 'availability',
    TIMEOFF: 'timeoff',
    BUSY_SLOT: 'busy_slot',
    FREE_SLOT: 'free_slot',
}

export const VIEWS = [
    {
        key: VIEW.DAY,
        value: VIEW.DAY,
    },
    {
        key: VIEW.WEEK,
        value: VIEW.WEEK,
    },
    {
        key: VIEW.MONTH,
        value: VIEW.MONTH,
    },
]

export const REPEAT_INTERVALS = [
    {
        key: VIEW.DAY,
        value: VIEW.DAY,
    },
    {
        key: VIEW.WEEK,
        value: VIEW.WEEK,
    },
    {
        key: VIEW.MONTH,
        value: VIEW.MONTH,
    },
    {
        key: VIEW.YEAR,
        value: VIEW.YEAR,
    },
]

export const TIMEOFF_STATUS = {
    PENDING: 'pending',
    REJECTED: 'rejected',
    ACCEPTED: 'approved',
}

export const SLOT_STATUS = {
    BUSY: 'busy',
    FREE: 'free',
    BUSY_TENTATIVE: 'busy-tentative',
    BUSY_UNAVAILABLE: 'busy-unavailable',
}

export const SLOT_TYPE = {
    OPEN: 'open',
    EXCLUSIVE: 'exclusive',
    SHARED: 'shared',
}

export const APPOINTMENT_TYPE = {
    TELE_HEALTH: 'tele-consultant',
    IN_CLINIC: 'in-clinic',
}

export const TIMEZONES = [
    {
        abbr: 'AST',
        value: 'Atlantic Standard Time (AST)',
        key: 'America/Montserrat',
    },
    {
        abbr: 'EST',
        value: 'Eastern Standard Time (EST)',
        key: 'America/New_York',
    },
    {
        abbr: 'CST',
        value: 'Central Standard Time (CST)',
        key: 'America/Chicago',
    },
    {
        abbr: 'MST',
        value: 'Mountain Standard Time (MST)',
        key: 'America/Phoenix',
    },
    {
        abbr: 'PST',
        value: 'Pacific Standard Time (PST)',
        key: 'America/Los_Angeles',
    },
    {
        abbr: 'SST',
        value: 'Samoa standard time (UTC-11)',
        key: 'Pacific/Pago_Pago',
    },
    {
        abbr: 'HST',
        value: 'Hawaii-Aleutian Standard Time (HST)',
        key: 'Pacific/Johnston',
    },
    {
        abbr: 'CHST',
        value: 'Chamorro Standard Time (CHST)',
        key: 'Pacific/Guam',
    },
]

export const SLOT_TYPES = [
    {
        key: SLOT_TYPE.EXCLUSIVE,
        value: SLOT_TYPE.EXCLUSIVE,
    },
    {
        value: SLOT_TYPE.SHARED,
        key: SLOT_TYPE.SHARED,
    },
    {
        value: SLOT_TYPE.OPEN,
        key: SLOT_TYPE.OPEN,
    },
]
