const CustomeCheckBoxChecked = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 5C0 2.23858 2.23858 0 5 0H13C15.7614 0 18 2.23858 18 5V13C18 15.7614 15.7614 18 13 18H5C2.23858 18 0 15.7614 0 13V5Z"
                fill="#5571C6"
            />
            <path
                d="M13.7157 4.94775C13.5194 4.9542 13.3334 5.03838 13.197 5.18266C11.3253 7.10026 9.32159 9.29758 7.45172 11.237L4.78235 8.99008C4.63135 8.83881 4.42512 8.7592 4.21385 8.77077C4.00274 8.78246 3.80597 8.88412 3.67165 9.05099C3.53735 9.218 3.47776 9.43501 3.50749 9.64895C3.53709 9.86302 3.65323 10.0545 3.82765 10.1769L7.03329 12.8756C7.17785 12.9978 7.36187 13.0606 7.54917 13.0517C7.7366 13.0428 7.91405 12.9628 8.04689 12.8274C10.0737 10.751 12.2735 8.31801 14.2697 6.27328V6.27315C14.4171 6.12752 14.5004 5.92689 14.5 5.71729C14.4997 5.50766 14.4159 5.30717 14.268 5.16208C14.12 5.01686 13.9206 4.93954 13.7158 4.94788L13.7157 4.94775Z"
                fill="white"
            />
        </svg>
    )
}
export default CustomeCheckBoxChecked
