import React from 'react';

export default function TeleCameraIcon() {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1124 26.6979H9.44063C4.95383 26.6979 3.36133 23.5508 3.36133 20.6186V10.3811C3.36133 6.00801 5.06758 4.30176 9.44063 4.30176H17.1124C21.4855 4.30176 23.1917 6.00801 23.1917 10.3811V20.6186C23.1917 24.9916 21.4855 26.6979 17.1124 26.6979ZM9.44063 6.22287C6.14188 6.22287 5.28244 7.08231 5.28244 10.3811V20.6186C5.28244 22.1731 5.82591 24.7768 9.44063 24.7768H17.1124C20.4112 24.7768 21.2706 23.9173 21.2706 20.6186V10.3811C21.2706 7.08231 20.4112 6.22287 17.1124 6.22287H9.44063Z" fill="#303E4E"/>
    <path d="M27.097 23.2224C26.5535 23.2224 25.8584 23.0455 25.0621 22.4893L21.6875 20.1259C21.4347 19.9489 21.2831 19.6582 21.2831 19.3423V11.6578C21.2831 11.3418 21.4347 11.0512 21.6875 10.8742L25.0621 8.51073C26.5661 7.46171 27.6657 7.76504 28.1839 8.03046C28.7021 8.30851 29.5868 9.02893 29.5868 10.8616V20.1259C29.5868 21.9585 28.7021 22.6916 28.1839 22.957C27.9438 23.096 27.5646 23.2224 27.097 23.2224ZM23.1916 18.8367L26.1617 20.9095C26.7304 21.3013 27.1349 21.3392 27.2992 21.2507C27.4761 21.1623 27.6657 20.8084 27.6657 20.1259V10.8742C27.6657 10.1791 27.4635 9.83782 27.2992 9.74935C27.1349 9.66087 26.7304 9.69879 26.1617 10.0906L23.1916 12.1634V18.8367Z" fill="#303E4E"/>
    <path d="M15.3682 15.1838C13.8009 15.1838 12.5244 13.9073 12.5244 12.3401C12.5244 10.7729 13.8009 9.49634 15.3682 9.49634C16.9354 9.49634 18.2119 10.7729 18.2119 12.3401C18.2119 13.9073 16.9354 15.1838 15.3682 15.1838ZM15.3682 11.3922C14.85 11.3922 14.4202 11.8219 14.4202 12.3401C14.4202 12.8583 14.85 13.288 15.3682 13.288C15.8864 13.288 16.3161 12.8583 16.3161 12.3401C16.3161 11.8219 15.8864 11.3922 15.3682 11.3922Z" fill="#303E4E"/>
    </svg>
    

  );
}
