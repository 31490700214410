import {
    getService,
    postService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'

const events = [
    {
        clinic_id: null,
        created_by: 68,
        day_number: null,
        day_of_month: {},
        days_of_week: 'Mon,Tue,Wed,Thu,Fri',
        end: '21:00:00',
        end_date: '2022-01-28',
        is_active: 1,
        is_repeat: false,
        provider_id: 4,
        repeat_number: null,
        repeat_type: null,
        schedule_id: 375,
        slot_type: 'open',
        start: '16:00:00',
        start_date: '2022-01-01',
        tz_offset: '-07:00',
    },
    // {
    //     clinic_id: null,
    //     created_by: 68,
    //     day_number: null,
    //     day_of_month: {},
    //     days_of_week: 'Mon,Tue,Wed,Thu,Fri,Sat',
    //     end: '21:00:00',
    //     end_date: '03-15-2022',
    //     is_active: 1,
    //     is_repeat: false,
    //     provider_id: 4,
    //     repeat_number: null,
    //     repeat_type: null,
    //     schedule_id: 375,
    //     slot_type: 'open',
    //     start: '16:00:00',
    //     start_date: '12-15-2021',
    //     tz_offset: '-07:00',
    // },
]

const timeoffs = [
    // {
    //     start_date: '2021-12-01',
    //     end_date: '2021-12-03',
    //     status: 'pending',
    //     start_time: '11:00:00',
    //     end_time: '12:00:00',
    //     appointments: [],
    // },
    // {
    //     start_date: '2022-01-01',
    //     end_date: '2022-01-28',
    //     status: 'accepted',
    //     start: '16:00:00',
    //     end: '19:00:00',
    //     appointments: [],
    // },
]

export function getAvailability(payload) {
    return postService(
        `${config.dev.baseURL}/availability/get-schedule`,
        payload,
    )
}

export function createAvailability(payload) {
    return postService(
        `${config.dev.baseURL}/availability/create`,
        payload,
    )
}

export function getTimeOffs(payload) {
    return getService(
        `${config.dev.baseURL}/time-off/list/${payload.provider_id}?start_date=${payload.start_date}&end_date=${payload.end_date}`,
    )
}

export function getClinics(providerId) {
    return getService(
        `${config.dev.baseURL}/clinic/list?provider_id=${providerId}`,
    )
}

export function setTimeoff(payload) {
    return postService(
        `${config.dev.baseURL}/time-off/create`,
        payload,
    )
}

export function getAppointmentCount(payload) {
    return getService(
        `${config.dev.baseURL}/appointment/fetch-appointment-count?provider_id=${payload.provider_id}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
    )
}

export function fetchSlotDetails(payload) {
    const query = payload.date
        ? `date=${payload.date}`
        : `start_date=${payload.start_date}&end_date=${payload.end_date}`
    const status = payload.status
        ? `&status=${payload.status}`
        : ''
    return getService(
        `${config.dev.baseURL}/providers/fetch-slot-details?provider_id=${payload.provider_id}&${query}${status}`,
    )
}
