import { UserRoles, getAllUserRoles } from './user-roles'
import Routes from './routes'
import dashboardIcon from '../assests/sidebar/icon_dashboard.svg'
import activeDashboardIcon from '../assests/sidebar/icon_dashboard_pressed.svg'
import scheduleIcon from '../assests/sidebar/icon_schedule_normal.svg'
import activeScheduleIcon from '../assests/sidebar/icon_schedule_pressed.svg'
import healthReportIcon from '../assests/sidebar/icon_report_normal.svg'
import activeHealthReportIcon from '../assests/sidebar/icon_report_pressed.svg'
import appointmentsIcon from '../assests/sidebar/icon_appointments.svg'
import activeAppointmentsIcon from '../assests/sidebar/icon_appointments_pressed.svg'
import reportIcon from '../assests/sidebar/icon_report_normal.svg'
import reportActiveIcon from '../assests/sidebar/icon_report_pressed.svg'
import billingIcon from '../assests/sidebar/icon_billing.svg'
import billingActiveIcon from '../assests/sidebar/icon_billing_pressed.svg'
import messageIcon from '../assests/sidebar/icon_messages.svg'
import messageActiveIcon from '../assests/sidebar/icon_messages_pressed..svg'
import supportActiveIcon from '../assests/sidebar/Support_Active.svg'
import supportIcon from '../assests/sidebar/Support_Normal.svg'
import eRxIcon from '../assests/sidebar/eRx_Normal.svg'
import eRxActiveIcon from '../assests/sidebar/eRx_Active.svg'
import clinicalNotesIcon from '../assests/sidebar/icon_notes.svg'
import clinicalNotesActiveIcon from '../assests/sidebar/icon_notes_pressed..svg'
import labsIcon from '../assests/sidebar/icon_lab orders.svg'
import labsActiveIcon from '../assests/sidebar/icon_lab orders_pressed..svg'
import imagingIcon from '../assests/sidebar/icon_imaging.svg'
import imagingActiveIcon from '../assests/sidebar/icon_imaging_pressed..svg'
import documentsIcon from '../assests/sidebar/icon_documents.svg'
import documentsActiveIcon from '../assests/sidebar/icon_documents_pressed..svg'
import SettingIcon from '../assests/sidebar/Settings_Normal.svg'
import SettingActiveIcon from '../assests/sidebar/Settings_Active.svg'
import PatientIcon from '../assests/sidebar/Patients_Normal.svg'
import PatientActiveIcon from '../assests/sidebar/Patients_Active.svg'
import ActionIcon from '../assests/sidebar/ActionItems.svg'
import ActionActiveIcon from '../assests/sidebar/ActionItems.svg'
import FaxIcon from '../assests/sidebar/fax_icon.svg'
import FaxActiveIcon from '../assests/sidebar/fax_icon_active.svg'
import bhscale from '../assests/sidebar/bhscale.svg'
import bhscaleActive from '../assests/sidebar/bhscale_pressed.svg'
import SettingsNew from '../assests/sidebar/SettingsNew.svg'
import Newlogout from '../assests/sidebar/Newlogout.svg'
//header menu icons
import profileIcon from '../assests/images/icon_profile.svg'
import LogOutIcon from '../assests/sidebar/icon_log_out.svg'
import ProviderDashboard from '../components/Provider/ProviderDetails/Provider-dashboard'
import ProviderAppintment from '../components/Provider/ProviderDetails/Provider-appointments'

const allUserRoles = getAllUserRoles()
const adminUserRoles = [
    UserRoles.TM2U_ADMIN,
    UserRoles.TM2U_SUPER_ADMIN,
    UserRoles.CLINIC_CORDINATION,
]

export const DashboardMenu = {
    role: [...allUserRoles],
    component: ProviderDashboard,
    name: 'Dashboard',
    path: Routes.DASHBOARD,
    icon: dashboardIcon,
    activeIcon: activeDashboardIcon,
}

export const Menu = [
    DashboardMenu,

    // {
    //     role: [
    //         UserRoles.TM2U_SUPER_ADMIN,
    //         UserRoles.TM2U_ADMIN,
    //         UserRoles.PATIENT,
    //         UserRoles.PROVIDER,
    //     ],
    //     component: ProviderAppintment,
    //     name: 'Appointments',
    //     path: Routes.APPOINTMENTS.HOME,
    //     icon: appointmentsIcon,
    //     activeIcon: activeAppointmentsIcon,
    // },
    // {
    //     role: [
    //         UserRoles.TM2U_SUPER_ADMIN,
    //         UserRoles.TM2U_ADMIN,
    //     ],
    //     component: ProviderAppintment,
    //     name: 'Scheduler',
    //     path: Routes.SCHEDULER,
    //     icon: scheduleIcon,
    //     activeIcon: activeScheduleIcon,
    // },
    {
        role: adminUserRoles,
        component: '',
        name: 'Users',
        path: Routes.USERS,
        icon: messageIcon,
        activeIcon: PatientActiveIcon,
    },
    // {
    //     role: [...allUserRoles],
    //     component: '',
    //     name: 'Messages',
    //     path: Routes.MESSSAGES,
    //     icon: messageIcon,
    //     activeIcon: messageActiveIcon,
    // },
    {
        role: [adminUserRoles],
        component: '',
        name: 'Patients',
        path: Routes.PATIENT,
        icon: PatientIcon,
        activeIcon: PatientActiveIcon,
    },
    // {
    //     role: [UserRoles.PROVIDER],
    //     component: '',
    //     name: 'Billing',
    //     path: Routes.BILLING,
    //     icon: billingIcon,
    //     activeIcon: billingActiveIcon,
    // },

    // {
    //     role: [UserRoles.PATIENT],
    //     component: '',
    //     name: 'Payments',
    //     path: Routes.PAYMENTS,
    //     icon: billingIcon,
    //     activeIcon: billingActiveIcon,
    // },
    // {
    //     role: [UserRoles.PROVIDER, UserRoles.PATIENT],
    //     component: '',
    //     name: 'Documents',
    //     path: Routes.DOCUMENTS,
    //     icon: documentsIcon,
    //     activeIcon: documentsActiveIcon,
    // },
    // {
    //     role: adminUserRoles,
    //     component: '',
    //     name: 'Billing & Payments',
    //     path: Routes.BILLING,
    //     icon: billingIcon,
    //     activeIcon: billingActiveIcon,
    // },

    // {
    //     role: [UserRoles.PROVIDER],
    //     component: '',
    //     name: 'eRX',
    //     path: Routes.ERX,
    //     icon: eRxIcon,
    //     activeIcon: eRxActiveIcon,
    // },

    // {
    //     role: [...adminUserRoles, UserRoles.PROVIDER],
    //     component: '',
    //     name: 'Labs',
    //     path: Routes.LABS,
    //     icon: labsIcon,
    //     activeIcon: labsActiveIcon,
    // },

    // {
    //     role: [UserRoles.PATIENT],
    //     component: '',
    //     name: 'Labs orders',
    //     path: Routes.LABS,
    //     icon: labsIcon,
    //     activeIcon: labsActiveIcon,
    // },

    // {
    //     role: adminUserRoles,
    //     component: '',
    //     name: 'eRX',
    //     path: Routes.ERX,
    //     icon: eRxIcon,
    //     activeIcon: eRxActiveIcon,
    // },

    // {
    //     role: [...allUserRoles],
    //     component: '',
    //     name: 'Imaging',
    //     path: Routes.IMAGING,
    //     icon: imagingIcon,
    //     activeIcon: imagingActiveIcon,
    // },
    // {
    //     role: adminUserRoles,
    //     component: '',
    //     name: 'Reports',
    //     path: Routes.REPORTS,
    //     icon: reportIcon,
    //     activeIcon: reportActiveIcon,
    // },
    // {
    //     role: [UserRoles.PATIENT],
    //     component: '',
    //     name: 'Health Summary',
    //     path: Routes.REPORTS,
    //     icon: reportIcon,
    //     activeIcon: reportActiveIcon,
    // },

    // {
    //     role: [...adminUserRoles, UserRoles.PROVIDER],
    //     component: '',
    //     name: 'Settings',
    //     path: Routes.SETTINGS.MAIN,
    //     icon: SettingIcon,
    //     activeIcon: SettingActiveIcon,
    // },

    {
        role: [UserRoles.PROVIDER, UserRoles.PATIENT, UserRoles.MID_PROVIDER],
        component: '',
        name: 'Clinical Notes',
        path: Routes.CLINICAL_NOTES.LISTING,
        icon: clinicalNotesIcon,
        activeIcon: clinicalNotesActiveIcon,
    },

    // {
    //     role: [...adminUserRoles, UserRoles.PATIENT],
    //     component: '',
    //     name: 'Help & Support',
    //     path: Routes.SUPPORTS,
    //     icon: supportIcon,
    //     activeIcon: supportActiveIcon,
    // },

    // {
    //     role: [UserRoles.PROVIDER],
    //     component: '',
    //     name: 'Support',
    //     path: Routes.SUPPORTS,
    //     icon: supportIcon,
    //     activeIcon: supportActiveIcon,
    // },
    // {
    //     role: [
    //         UserRoles.TM2U_ADMIN,
    //         UserRoles.TM2U_SUPER_ADMIN,
    //     ],
    //     component: '',
    //     name: 'Fax',
    //     path: Routes.FAX.LISTING,
    //     icon: FaxIcon,
    //     activeIcon: FaxActiveIcon,
    // },
    // {
    //     role: [
    //         UserRoles.TM2U_ADMIN,
    //         UserRoles.TM2U_SUPER_ADMIN,
    //     ],
    //     component: '',
    //     name: 'Forms',
    //     path: Routes.FORMS,
    //     icon: clinicalNotesIcon,
    //     activeIcon: clinicalNotesActiveIcon,
    // },
    // {
    //     role: [UserRoles.PATIENT, UserRoles.PROVIDER],
    //     component: '',
    //     name: 'BH Scales',
    //     path: Routes.SCALES,
    //     icon: bhscale,
    //     activeIcon: bhscaleActive,
    // },
]

export const getMenusByUserRole = (userRole) => Menu.filter(({ role }) => role.includes(userRole))

export const userMenus = [
    {
        name: 'Settings',
        icon: SettingsNew,
        action: 'onSettingsClick',
    },
    {
        name: 'Log out',
        icon: Newlogout,
        action: 'onLogoutClick',
    },
]

export const profileTabsList = {
    patient: [
        'profile',
        'appointments',
        'Health summary',
        'clinical notes',
        'labs',
        'imaging',
        'eRx',
    ],
    provider: ['profile', 'Working Hours', 'Billing & Payments'],
}

export const rolesToiewFaxModule = [UserRoles.TM2U_ADMIN, UserRoles.TM2U_SUPER_ADMIN]
