import React, { useContext, useEffect, useRef, useState, createContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import { useHistory, useLocation } from 'react-router'
import {
    Container,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    styled,
    Box,
    Paper,
} from '@material-ui/core'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import * as VideoExpress from '@vonage/video-express'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Divider from '@mui/material/Divider'
import useStyles from './styles'
import usePreviewPublisher from '../hooks/usePreviewPublisher'
import AudioSettings from '../AudioSetting'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import VideoSettings from '../VideoSetting'
import DeviceAccessAlert from '../DeviceAccessAlert'
import { UserContext } from '../context/UserContext'
// import LinearProgress, { linearProgressClasses } from "@material-ui/core/LinearProgress";
import { DEVICE_ACCESS_STATUS } from '../constants'
import VideoFilter from '../VideoFilter'
import appLogo from '../../../assests/Logos/TM2U_Logo_250px_Width.svg'
import ShapeIcon from '../../../assests/icons/Shape.svg'
import CheckIcon from '../../../assests/icons/check.svg'
import MicWhiteIcon from '../../../assests/icons/white_mic.svg'
import VideoWhiteIcon from '../../../assests/icons/white_video.svg'
import ProgressBar from '../../Consultation/components/ProgressBar/ProgressBar'
import axios from 'axios'
import { config } from '../../../constants'
import LunchModalForm from '../../Dashboard/modal/LunchModal'
import { postPatientConsent } from '../../../services/Consultation/consultation'
import { useNotification } from '../../Appointments/MaterialUi/Notification'
import { useSelector } from 'react-redux'

function useQuery(location) {
    return new URLSearchParams(location.search)
}

export default function WaitingRoom() {
    console.log('50=>','passoingh')
    const classes = useStyles()
    const { push } = useHistory()
    const location = useLocation()
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    //   let query = useQuery(location)
    //   let linkJoin = query.get('data')
    const { user, setUser } = useContext(UserContext)
    const waitingRoomVideoContainer = useRef()
    const roomToJoin = location?.state?.room || ''
    const [roomName, setRoomName] = useState(roomToJoin)
    const [userName, setUserName] = useState('')
    const [open, setOpen] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState(0)
    const tabContentRefs = React.useRef([])
    const [scrolledTabs, setScrolledTabs] = React.useState([])
    const [sign, setSign] = React.useState('')
    const [value, setValue] = React.useState(false)
    const [isCheckboxEnabled, setIsCheckboxEnabled] = React.useState(false)
    const [isRoomNameInvalid, setIsRoomNameInvalid] = useState(false)
    const [isUserNameInvalid, setIsUserNameInvalid] = useState(false)
    const [localAudio, setLocalAudio] = useState(user.defaultSettings.publishAudio)
    const [localVideo, setLocalVideo] = useState(user.defaultSettings.publishVideo)
    const [localVideoSource, setLocalVideoSource] = useState(undefined)
    const [localAudioSource, setLocalAudioSource] = useState(undefined)
    const [localAudioOutput, setLocalAudioOutput] = useState(undefined)
    /* const [devices, setDevices] = useState(null); */
    let [audioDevice, setAudioDevice] = useState('')
    let [videoDevice, setVideoDevice] = useState('')
    let [audioOutputDevice, setAudioOutputDevice] = useState('')
    // const [backgroundBlur, setBackgroundBlur] = useState(user.videoEffects.backgroundBlur);
    const [videoFilter, setVideoFilter] = useState({ filterName: '', filterPayload: '' })
    const {
        createPreview,
        destroyPreview,
        previewPublisher,
        logLevel,
        previewMediaCreated,
        deviceInfo,
        accessAllowed,
    } = usePreviewPublisher()
    const [progress, setProgress] = React.useState(10)
    const [openLoadingTypo, setopenLoadingTypo] = useState(false)
    const notification = useNotification()

    const handleVideoSource = React.useCallback(
        (e) => {
            const videoDeviceId = e.target.value
            setVideoDevice(e.target.value)
            previewPublisher.setVideoDevice(videoDeviceId)
            setLocalVideoSource(videoDeviceId)
        },
        [previewPublisher, setVideoDevice, setLocalVideoSource],
    )

    const handleAudioSource = React.useCallback(
        (e) => {
            const audioDeviceId = e.target.value
            setAudioDevice(audioDeviceId)
            previewPublisher.setAudioDevice(audioDeviceId)
            setLocalAudioSource(audioDeviceId)
        },
        [previewPublisher, setAudioDevice, setLocalAudioSource],
    )

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        width: 350,
        marginTop: 32,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'gray',
            borderRadius: 5,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: 'white',
        },
    }))

    const handleAudioOutput = React.useCallback(
        (e) => {
            const audioOutputId = e.target.value
            setAudioOutputDevice(audioOutputId)
            // await VideoExpress.setAudioOutputDevice(audioOutputId);
            setLocalAudioOutput(audioOutputId)
        },
        [setLocalAudioOutput, setAudioOutputDevice],
    )

    const redirectHttps = React.useCallback(() => {
        const url = window.location.href
        if (
            url.toString().indexOf('http://') === 0 &&
            url.toString().indexOf('http://localhost') !== 0
        ) {
            window.location.href = window.location.href.toString().replace('http://', 'https://')
        } else {
            return
        }
    }, [])

    const handleJoinClick = () => {
        if (validateForm()) {
            localStorage.setItem('username', userName)
            push(`room/${roomName}`)
        }
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5))
        }, 500)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const getValidateLink = async (payload) => {
        try {
            setopenLoadingTypo(true)
            const searchParams = new URLSearchParams(location.search)
            console.log('Validate :: ', searchParams, searchParams.get('data'), location.search)
            const data = searchParams.get('data')
            const res = await axios.post(
                `${config.dev.baseURLV2}/patient_portal/telehealth/validateLink`,
                { data: data },
            )
            // const newToken = await getVideoChatToken(res?.data?.validateLink?.encounter_id)
            const newData = res.data
            if (res?.data && Object.keys(res.data).length === 0) {
                push('/invalid-link', { message: 'Please try joining in again...' })
            } else {
                // const keywordPopulusSt = []
                // const ifSpecialty = res.data?.validateLink?.specialty
                // if (ifSpecialty) {
                //     if (ifSpecialty.specialty) {
                //         keywordPopulusSt.push(res.data.validateLink?.specialty?.specialty)
                //     }
                //     if (!!ifSpecialty?.conditions_treated.length) {
                //         ifSpecialty.conditions_treated.forEach((element) => {
                //             keywordPopulusSt.push(element.code)
                //         })
                //     }
                // }

                // if (keywordPopulusSt.length > 0) {
                //     setKeywordPopulus(keywordPopulusSt.join(','))
                // }

                // const targetDatetime = new Date(res.data.start)
                // // targetDatetime.setMinutes(targetDatetime.getMinutes() - 10)

                // const currentTime = new Date()

                // console.log(targetDatetime)
                // console.log(currentTime)

                // console.log('Is time allowed?')
                // console.log(currentTime >= targetDatetime)

                // // Compare the current time to the start time
                // if (currentTime >= targetDatetime) {
                //     // Allow the user to access the link
                //     console.log('going to main room as 10 mins done')
                //     history.push(`/join?data=${encodeURIComponent(data)}`, {
                //         infoRoom: newData,
                //     })
                // } else {
                //     // Deny the user access to the link
                //     console.log('in wait room before 10 mins')

                //     history.push(`/join-wait?data=${encodeURIComponent(data)}`, {
                //         infoRoom: newData,
                //     })
                if(payload){
                    payload.term_signed = newData.patient_first_name +" "+newData?.patient_last_name 
                    payload.patient_id = newData.patient_id
                    payload.encounter_id = newData.encounter_id
                    if(!payload.timezone){
                        payload.timezone = newData.patient_timezone
                    }
                    postPatientConsent(payload).then(res => {
                        setOpen(false)
                        push(`/room`, {
                            infoRoom: newData,
                        })
                        setopenLoadingTypo(false)
                    })?.catch(() => {
                        setopenLoadingTypo(false)
                        notification('Something went wrong!', 'error')
                    })
                }else{
                    push(`/room`, {
                        infoRoom: newData,
                    })
                    setopenLoadingTypo(false)
                }
                
            }
            // }
        } catch (error) {
            console.log('ERROR :: ', error)
            console.log('ERROR_MSG :: ', JSON.stringify(error?.response?.data?.message?.message))
            push('/invalid-link', {
                message: error?.response?.data?.message?.message || 'Please try joining in again.',
            })
            setopenLoadingTypo(false)
        } finally {
            // setopenLoadingTypo(false)
        }
    }

    const validateForm = () => {
        if (userName === '') {
            setIsUserNameInvalid(true)
            return false
        } else if (roomName === '') {
            setIsRoomNameInvalid(true)
            return false
        }
        return true
    }

    const onChangeRoomName = (e) => {
        const roomName = e.target.value
        if (roomName === '' || roomName.trim() === '') {
            // Space detected
            setRoomName('')
            return
        }
        setIsRoomNameInvalid(false)
        setRoomName(roomName)
    }

    const onChangeParticipantName = (e) => {
        const userName = e.target.value
        if (userName === '' || userName.trim() === '') {
            // Space detected
            setUserName('')
            return
        }
        setIsUserNameInvalid(false)
        setUserName(userName)
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleJoinClick()
        }
    }

    const handleAudioChange = React.useCallback((e) => {
        setLocalAudio((localAudio) => !localAudio)
    }, [])

    const handleVideoChange = React.useCallback((e) => {
        setLocalVideo((localVideo) => !localVideo)
    }, [])

    const handleChangeVideoFilter = React.useCallback(
        async (filter, filterPayload) => {
            if (previewPublisher && filter) {
                switch (filter) {
                    case 'reset':
                        await previewPublisher.clearVideoFilter()
                        setVideoFilter({ filterName: '', filterPayload: '' })
                        break
                    case 'blur':
                        await previewPublisher.setVideoFilter({
                            type: 'backgroundBlur',
                            blurStrength: filterPayload,
                        })
                        setVideoFilter({ filterName: filter, filterPayload })
                        break
                    case 'backgroundImage':
                        await previewPublisher.setVideoFilter({
                            type: 'backgroundReplacement',
                            backgroundImgUrl: filterPayload,
                        })
                        setVideoFilter({ filterName: filter, filterPayload })
                        break
                    default:
                    // do nothing
                }
            }
        },
        [previewPublisher],
    )

    // const handleChangeBackgroundBlur = React.useCallback(async () => {
    //   try {
    //     if (backgroundBlur) {
    //       setBackgroundBlur(false);
    //       destroyPreview();
    //       stopEffect();
    //       createPreview(waitingRoomVideoContainer.current, {
    //         videoSource: localVideoSource,
    //       });
    //     } else {
    //       setBackgroundBlur(true);
    //       destroyPreview();
    //       const outputVideoStream = await startBackgroundBlur(videoDevice);
    //       console.log(outputVideoStream);
    //       createPreview(waitingRoomVideoContainer.current, {
    //         videoSource: outputVideoStream.getVideoTracks()[0],
    //         mirror: true,
    //       });
    //     }
    //   } catch (e) {
    //     console.log(`Could not send background blurring - ${e}`);
    //   }
    // }, [
    //   backgroundBlur,
    //   destroyPreview,
    //   stopEffect,
    //   createPreview,
    //   localVideoSource,
    //   videoDevice,
    //   startBackgroundBlur,
    // ]);

    useEffect(() => {
        redirectHttps()
        if (localStorage.getItem('username')) {
            setUserName(localStorage.getItem('username'))
        }
    }, [redirectHttps])

    useEffect(() => {
        if (
            localAudio !== user.defaultSettings.publishAudio ||
            localVideo !== user.defaultSettings.publishVideo ||
            localAudioSource !== user.defaultSettings.audioSource ||
            localVideoSource !== user.defaultSettings.videoSource ||
            videoFilter.filterName !== user.videoFilter.filterName ||
            videoFilter.filterPayload !== user.videoFilter.filterPayload ||
            localAudioOutput !== user.defaultSettings.audioOutput
        ) {
            setUser({
                ...user,
                videoFilter: {
                    filterName: videoFilter.filterName,
                    filterPayload: videoFilter.filterPayload,
                },
                defaultSettings: {
                    publishAudio: localAudio,
                    publishVideo: localVideo,
                    audioSource: localAudioSource,
                    videoSource: localVideoSource,
                    audioOutput: localAudioOutput,
                },
            })
        }
    }, [
        localAudio,
        localVideo,
        user,
        setUser,
        localAudioSource,
        localVideoSource,
        videoFilter,
        localAudioOutput,
    ])

    useEffect(() => {
        if (userName !== user.userName) {
            setUser({ ...user, userName: userName })
        }
    }, [userName, user, setUser])

    useEffect(() => {
        if (previewPublisher && previewMediaCreated && deviceInfo) {
            previewPublisher.getAudioDevice().then((currentAudioDevice) => {
                setAudioDevice(currentAudioDevice.deviceId)
            })
            const currentVideoDevice = previewPublisher.getVideoDevice()
            setVideoDevice(currentVideoDevice.deviceId)

            VideoExpress.getActiveAudioOutputDevice().then((currentAudioOutputDevice) => {
                setAudioOutputDevice(currentAudioOutputDevice.deviceId)
            })
        }
    }, [
        deviceInfo,
        previewPublisher,
        setAudioDevice,
        setVideoDevice,
        previewMediaCreated,
        setAudioOutputDevice,
    ])

    useEffect(() => {
        if (previewPublisher) {
            if (localAudio && !previewPublisher.isAudioEnabled()) {
                previewPublisher.enableAudio()
            } else if (!localAudio && previewPublisher.isAudioEnabled()) {
                previewPublisher.disableAudio()
            }
        }
    }, [localAudio, previewPublisher])

    useEffect(() => {
        if (previewPublisher) {
            if (localVideo && !previewPublisher.isVideoEnabled()) {
                previewPublisher.enableVideo()
            } else if (!localVideo && previewPublisher.isVideoEnabled()) {
                previewPublisher.disableVideo()
            }
        }
    }, [localVideo, previewPublisher])

    const openToggle = () => {
        const payload = {
            term_signed: sign,
            timezone: loggedinPatiendata?.timezone,
        }
        getValidateLink(payload)
        // setOpen(true)
        // setCurrentTab(0)
    }

    useEffect(() => {
        if (waitingRoomVideoContainer.current) {
            createPreview(waitingRoomVideoContainer.current, { resolution: '1920 x 1080' })
        }

        return () => {
            // stopEffect();
            destroyPreview()
        }
    }, [createPreview, destroyPreview])

    return (
        <>
            <Grid
                container
                item
                alignItems="center"
                direction="column"
                className={classes.container}>
                <div className={classes.logoContainer}>
                    <img className={classes.appLogo} src={appLogo} alt="TeleMed2U" />
                </div>
                <>
                    {!openLoadingTypo && (
                        <Container>
                            <Box className={classes.conTitleContainer}>
                                <Typography variant="subtitle2" className={classes.conTitle}>
                                    <strong>Test your connection!</strong>
                                </Typography>
                            </Box>
                            <div className={classes.mainContainer}>
                                <Grid item lg={6} className={classes.videoTest}>
                                    <Paper className={classes.paper}>
                                        {/* {mediaError && (
                                        <Box className={classes.errorContainer}>
                                            <img src={ShapeIcon} alt="TeleMed2U" />
                                            <Typography variant="subtitle2" className={classes.instrucTitle}>
                                                <p style={{ cursor: 'pointer', fontSize: '16px' }}>
                                                    Audio/video not connected. Please troubleshoot your browser.
                                                </p>
                                            </Typography>
                                        </Box>
                                    )} */}

                                        <Grid container direction="column" alignItems="center">
                                            <div className={classes.aspectRatioContainer}>
                                                <div
                                                    id="waiting-room-video-container"
                                                    className={classes.waitingRoomVideoPreview}
                                                    ref={waitingRoomVideoContainer}></div>
                                            </div>
                                        </Grid>
                                        <Grid className={classes.gridIconContainer}>
                                            {/* <Box className={classes.boxIconContainer} onClick={toggleVideoEnabled}>
                                            {isVideoEnabled ? (
                                                <img src={VideoIcon} alt="TeleMed2U" />
                                            ) : (
                                                <IconDisableVideo />
                                            )}
                                        </Box>
                                        <Box className={classes.boxIconContainer} onClick={toggleAudioEnabled}>
                                            {isAudioEnabled ? (
                                                <img src={MicIcon} alt="TeleMed2U" />
                                            ) : (
                                                <IconMuteMic />
                                            )}
                                        </Box> */}
                                        </Grid>
                                        <Grid className={classes.gridIconContainer}>
                                            <AudioSettings
                                                className={classes.boxIconContainer}
                                                hasAudio={localAudio}
                                                onAudioChange={handleAudioChange}
                                            />
                                            <VideoSettings
                                                className={classes.boxIconContainer}
                                                hasVideo={localVideo}
                                                onVideoChange={handleVideoChange}
                                            />
                                        </Grid>
                                    </Paper>
                                    <Box className={classes.instrucContainer}>
                                        <img src={ShapeIcon} alt="TeleMed2U" />
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.instrucTitle}>
                                            <p
                                                style={{ cursor: 'pointer' }}
                                                onClick={(event) => handleClick(event)}>
                                                Need help trouble shooting your browser?
                                            </p>
                                        </Typography>
                                        {/* <Popover
                                        open={open}
                                        anchorEl={anchorEl}
                                        classes={classes}
                                        browser={browser}
                                        handleClose={() => handleClose()}
                                    /> */}
                                    </Box>
                                </Grid>
                                <Grid item lg={6} className={classes.cameraAudioTest}>
                                    <Grid
                                        item
                                        className={classes.cameraAudioTestContainer}
                                        direction="column">
                                        <Grid
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={classes.formListContainer}>
                                            <Typography
                                                variant="subtitle2"
                                                className={classes.audioTitle}>
                                                <img src={MicWhiteIcon} alt="TeleMed2U" />
                                                <strong>Audio Settings</strong>
                                            </Typography>
                                            <Grid
                                                item
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className={classes.selectContainer}>
                                                <Grid md={10} sm={12} xs={12}>
                                                    {/* <Select labelId="demo-simple-select-label" id="demo-simple-select" value={audioDevice} onChange={handleAudioSource}>
                                                {deviceInfo.audioInputDevices.map((device) => (
                                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                                    {device.label}
                                                    </MenuItem>
                                                ))}
                                                </Select> */}
                                                    <Select
                                                        value={audioDevice}
                                                        onChange={handleAudioSource}
                                                        className={classes.selectInput}
                                                        disableUnderline>
                                                        {deviceInfo.audioInputDevices.map(
                                                            (device) => (
                                                                <MenuItem
                                                                    value={device.deviceId}
                                                                    key={device.deviceId}>
                                                                    {device.label}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </Select>
                                                </Grid>
                                                {deviceInfo && previewMediaCreated && (
                                                    <Grid md={2}>
                                                        <img
                                                            src={CheckIcon}
                                                            width={22}
                                                            alt="TeleMed2U"
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Typography
                                                variant="subtitle2"
                                                className={classes.audioTitle}
                                                style={{ fontSize: '16px', fontWeight: '400' }}>
                                                <strong>Microphone Level</strong>
                                            </Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={logLevel}
                                            />
                                            <ProgressBar
                                                position={logLevel}
                                                duration={0.1}
                                                style={{ width: '82%' }}
                                            />
                                        </Grid>

                                        <Grid
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={classes.formListContainer}>
                                            <Typography
                                                variant="subtitle2"
                                                className={classes.audioTitle}>
                                                <img src={VideoWhiteIcon} alt="TeleMed2U" />
                                                <strong>Video Settings</strong>
                                            </Typography>
                                            <Grid
                                                item
                                                md={12}
                                                sm={12}
                                                xs={12}
                                                className={classes.selectContainer}>
                                                <Grid md={10} sm={12} xs={12}>
                                                    {/* <Select labelId="video" id="demo-simple-select" value={videoDevice} onChange={handleVideoSource}>
                                                {deviceInfo.videoInputDevices.map((device) => (
                                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                                    {device.label}
                                                    </MenuItem>
                                                ))}
                                                </Select> */}
                                                    <Select
                                                        onChange={handleVideoSource}
                                                        value={videoDevice}
                                                        variant="outlined"
                                                        disableUnderline
                                                        className={classes.selectInput}>
                                                        {deviceInfo.videoInputDevices.map(
                                                            (device) => (
                                                                <MenuItem
                                                                    value={device.deviceId}
                                                                    key={device.deviceId}>
                                                                    {device.label}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </Select>
                                                </Grid>
                                                {deviceInfo && previewMediaCreated && (
                                                    <Grid md={2}>
                                                        <img
                                                            src={CheckIcon}
                                                            width={22}
                                                            alt="TeleMed2U"
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            md={10}
                                            sm={12}
                                            xs={12}
                                            className={classes.formListContainer}>
                                            <Divider
                                                sx={{
                                                    height: '2px !important',
                                                    background: '#fff !important',
                                                }}
                                            />
                                            <Box className={classes.buttonContainer}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    // onClick={getValidateLink}
                                                    onClick={openToggle}
                                                    className={classes.continueButton}>
                                                    Continue
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    )}
                    {openLoadingTypo && (
                        <div style={{ height: '100vh' }}>
                            <Typography
                                variant="body2"
                                style={{ color: 'white', fontSize: '19px', marginTop: 80 }}>
                                Connecting with your care provider ...
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={progress} />
                            <Typography style={{ fontSize: 14, color: 'white', marginTop: 32 }}>
                                {progress} %
                            </Typography>
                        </div>
                    )}
                </>
            </Grid>
            {open && (
                    <LunchModalForm
                        open={open}
                        toggleOpen={setOpen}
                        onModalClose={() => setOpen(false)}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        tabContentRefs={tabContentRefs}
                        getValidateLink={getValidateLink}
                        isCheckboxEnabled={isCheckboxEnabled}
                        setIsCheckboxEnabled={setIsCheckboxEnabled}
                        scrolledTabs={scrolledTabs}
                        setScrolledTabs={setScrolledTabs}
                        value={value}
                        setValue={setValue}
                        sign={sign}
                        setSign={setSign}
                    />
                )}
            {/* <div className={classes.waitingRoomContainer}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled={roomToJoin !== ""}
              id="room-name"
              label="Room Name"
              name="roomName"
              autoComplete="Room Name"
              error={isRoomNameInvalid}
              autoFocus
              helperText={roomName === "" ? "Empty Field" : " "}
              value={roomName}
              onChange={onChangeRoomName}
              onKeyDown={onKeyDown}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="publisher-name"
              label="Name"
              name="name"
              error={isUserNameInvalid}
              required
              autoComplete="Name"
              helperText={userName === "" ? "Empty Field" : " "}
              value={userName}
              onChange={onChangeParticipantName}
              onKeyDown={onKeyDown}
            />
            <div className={classes.mediaSources}>
              {deviceInfo && previewMediaCreated && (
                <>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Select Audio Source</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={audioDevice} onChange={handleAudioSource}>
                      {deviceInfo.audioInputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="video">Select Audio Output</InputLabel>
                    {deviceInfo.audioOutputDevices && (
                      <Select labelId="video" id="demo-simple-select" value={audioOutputDevice} onChange={handleAudioOutput}>
                        {deviceInfo.audioOutputDevices.map((device) => (
                          <MenuItem key={device.deviceId} value={device.deviceId}>
                            {device.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </>
              )}

              {deviceInfo && previewMediaCreated && (
                <FormControl>
                  <InputLabel id="video">Select Video Source</InputLabel>
                  {deviceInfo.videoInputDevices && (
                    <Select labelId="video" id="demo-simple-select" value={videoDevice} onChange={handleVideoSource}>
                      {deviceInfo.videoInputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              )}
            </div>
          </form>
          <div id="waiting-room-video-container" className={classes.waitingRoomVideoPreview} ref={waitingRoomVideoContainer}></div>
          <div className={classes.deviceContainer}>
            <AudioSettings className={classes.deviceSettings} hasAudio={localAudio} onAudioChange={handleAudioChange} />
            <LinearProgress variant="determinate" value={logLevel} />
            <VideoSettings className={classes.deviceSettings} hasVideo={localVideo} onVideoChange={handleVideoChange} />
          </div>
          <VideoFilter handleChangeVideoFilter={handleChangeVideoFilter} />
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Button variant="contained" color="primary" onClick={handleJoinClick} disabled={!roomName || !userName}>
            Join Call
          </Button>
        </Grid>
      </div> */}
            {accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED && (
                <DeviceAccessAlert accessStatus={accessAllowed}></DeviceAccessAlert>
            )}
        </>
    )
}
