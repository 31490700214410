import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import StaffLoginForm from '../../components/Login/StaffLoginForm'
import * as actions from '../../store/actions/login'
import * as loaderActions from '../../store/actions/loader'
import { connect } from 'react-redux'
import React from 'react'

function StaffLogin(props) {
    return (
        <div>
            <Header />
            <StaffLoginForm onError={props.setError} />
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.loader.loading,
        error: state.loader.error,
        // isValidUser: state.login.isValidUser,
        // isUserExist: state.login.isUserExist,
        // session: state.login.session,
        // patientData: state.login.userData,
        // otpError: state.login.otpError,
        // maxAttempt: state.login.maxAttempt,
        // redirectUrl: state.login.redirectUrl,
    }
}

const mapDispatchToProp = (dispatch) => {
    return {
        // sendOtp: (mob) => dispatch(actions.sendOtp(mob)),
        // setIsUserExist: (val) =>
        //   dispatch(actions.setIsUserExist(val)),
        // setIsValidUser: (val) =>
        //   dispatch(actions.setUser(val)),
        setError: (val) =>
            dispatch(loaderActions.setError(val)),
        // verifyLogin: (data) =>
        //   dispatch(actions.verifyLogin(data)),
        // setMaxAttempt: (data) =>
        //   dispatch(actions.setMaxAttempt(data)),
        // setOtpError: (val) =>
        //   dispatch(actions.setOtpError(val)),
        // setRedirectURL: (val) =>
        //   dispatch(actions.setRedirectURL(val)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProp,
)(StaffLogin)
