import React from 'react'
import { Grid } from '@material-ui/core'
import Select from 'react-select'
import { useSelector } from 'react-redux'

const ProviderAssociatedClinicView = ({
    associatedClinic,
    index,
    fieldArrayProps,
    setFieldValue,
    initialAssociatedClinic,
    allValues,
}) => {
    const { push, remove } = fieldArrayProps
    const clinicList = useSelector(
        (state) => state.provider.clinicList,
    )
    clinicList &&
        clinicList.map((item) => {
            item['value'] = item.id
            item['label'] = item.clinic_name
        })

    const findVal = (id) => {
        if (id?.value?.value) {
            return clinicList.filter(
                (item) => item.value === id.value.value,
            )
        }
        return clinicList.filter(
            (item) => item.value === id,
        )
    }
    return (
        <Grid
            container
            key={index}
            style={{
                alignItems: 'center',
                marginBottom: '1em',
            }}>
            <Grid item xs={5}>
                <Select
                    value={
                        findVal(associatedClinic) ||
                        findVal(
                            allValues.associated_clinic_id[
                                index
                            ],
                        )
                    }
                    onChange={(e) => {
                        setFieldValue(
                            `associated_clinic_id[${index}].value`,
                            e,
                        )
                    }}
                    options={clinicList}
                    placeholder="Type Clinic Name"
                />
            </Grid>
            <Grid item xs={2} className="flex">
                {index !== 0 &&
                    index === allValues.length - 1 && (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(
                                    initialAssociatedClinic,
                                )
                            }>
                            +
                        </button>
                    )}

                {index === 0 &&
                    (allValues.length === 1 ? (
                        <>
                            <button
                                type="button"
                                className="plus-wrap"
                                onClick={() =>
                                    push(
                                        initialAssociatedClinic,
                                    )
                                }>
                                +
                            </button>
                            <button
                                type="button"
                                className="plus-wrap"
                                onClick={() =>
                                    setFieldValue(
                                        `associated_clinic_id[${index}].value`,
                                        '',
                                    )
                                }>
                                -
                            </button>
                        </>
                    ) : (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() => remove(index)}>
                            -
                        </button>
                    ))}
                {index !== 0 && (
                    <button
                        type="button"
                        className="plus-wrap "
                        onClick={() => remove(index)}>
                        -
                    </button>
                )}
            </Grid>
        </Grid>
    )
}

export default ProviderAssociatedClinicView
