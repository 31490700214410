import { getIsLoggedIn, getIsPatientDetailCompleted, getLoggedInUserRole } from './index'

import Routes from './../constants/routes'

export const authRequired = async (to, from, next) => {
    const {
        meta: { auth, allowedUsers, verifyToken, isAuthenticated },
    } = to

    if (auth) {
        if (isAuthenticated === null) {
            await verifyToken()
        }
        if (getIsLoggedIn()) {
            if (getIsPatientDetailCompleted()) {
                next()
                // const loggedInUserRole =
                //     getLoggedInUserRole()

                // if (
                //     allowedUsers.includes(loggedInUserRole)
                // ) {
                //     next()
                // } else {
                //     next.redirect(Routes.DASHBOARD)
                // }
            } else {
                next.redirect(Routes.DASHBOARD)
            }
        }

        next.redirect(Routes.SIGNIN)
    } else {
        next()
    }
}

export const authNotRequired = (to, from, next) => {
    if (!to.meta.auth && getIsLoggedIn() && JSON.parse(localStorage.getItem('isProfileComplete'))) {
        if (to.meta.isVideo) {
            next()
        }
        next.redirect(Routes.APPOINTMENT)
    } else if (
        localStorage.getItem('isProfileComplete') &&
        !JSON.parse(localStorage.getItem('isProfileComplete'))
    ) {
        next.redirect(Routes.PATIENT_DETAIL)
    } else {
        next()
    }
}
