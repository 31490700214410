import { Grid, Hidden, Paper } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ReactComponent as Filter } from '../../assests/sidebar/filterfortmtu.svg'
import { ProfileModes } from '../../constants/profileMode'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import { ConsultationTrackingCard } from '../../containers/ConsultationTracking'
import { WorkListCard } from '../../containers/WorkList'
import { getPatientListForUsers } from '../../services/Patient/patient'
import { getProviderSearchList } from '../../services/Provider/provider'
import {
    AutoCompleteBoxEl,
    SelectEl,
} from '../../shared/elements'
import { useDebounce } from '../../shared/hooks'
import { Button } from '../custom'

const headerOptionRole = [
    {
        key: UserRoles.PATIENT,
        value: 'Patient',
    },
    {
        key: UserRoles.PROVIDER,
        value: 'Provider',
    },
]

const SuperAdminDashboard = () => {
    const history = useHistory()
    const [searchData, setSearchData] = React.useState([])
    const [searchText, setSearchText] = React.useState('')
    const debouncedSearchText = useDebounce(searchText, 500)

    const [selectedRole, setSelectedRole] = React.useState(
        UserRoles.PATIENT,
    )

    React.useEffect(() => {
        onSearch(debouncedSearchText)
    }, [selectedRole, debouncedSearchText])

    const handleChangeRole = (e) => {
        setSelectedRole(e.target.value)
    }

    async function onSearch(filterString) {
        try {
            if (selectedRole === UserRoles.PATIENT) {
                const result = await getPatientListForUsers(
                    {
                        page: 1,
                        filterString,
                    },
                )
                setSearchData(
                    result.data.patient.map((p) => ({
                        ...p,
                        title: `${p.first_name} ${p.last_name}`,
                        subtitle: moment(p.dob).format(
                            'MM/DD/YYYY',
                        ),
                        user_id: p.patientId,
                    })),
                )
            } else {
                const result = await getProviderSearchList({
                    page: 1,
                    filterString,
                })
                setSearchData(
                    result.data.provider.map((p) => ({
                        ...p,
                        title: `${p.first_name} ${p.last_name}`,
                        subtitle: p.speciality.name,
                    })),
                )
            }
        } catch (err) {
            setSearchData([])
        }
    }

    function onOptionSelect(_, selected) {
        if (selected?.user_id) {
            if (
                (selectedRole === UserRoles.PATIENT ||
                    selectedRole === UserRoles.PROVIDER) &&
                !selected?.user?.is_active
            ) {
                Swal.fire({
                    text: 'This account is inactive.',
                    confirmButtonText: 'Ok',
                })
                return
            }
            history.push(Routes.USERS, {
                userId: selected.user_id,
                role: selectedRole,
                mode: ProfileModes.VIEW,
            })
        }
    }

    function onTextChange(e) {
        setSearchText(e.target.value)
    }

    return (
        <Grid container style={{ padding: '16px 20px' }}>
            Dashboard
            {/* <Grid container item spacing={2}>
                <Grid
                    container
                    item
                    md={2}
                    xs={3}
                    alignItems="center">
                    <Hidden mdDown>
                        <Grid item xs={3}>
                            <Filter
                                width={24}
                                height={24}
                            />
                        </Grid>
                    </Hidden>
                    <Grid item xs>
                        <Paper elevation="0">
                            <SelectEl
                                name="role"
                                placeholder="Select Role"
                                value={selectedRole}
                                // selectProps={{
                                //     style: {
                                //         height: 50,
                                //     },
                                // }}
                                onChange={handleChangeRole}
                                options={headerOptionRole}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item md={8} xs={5}>
                    <AutoCompleteBoxEl
                        options={searchData}
                        placeholder={
                            selectedRole ===
                            UserRoles.PATIENT
                                ? 'Search Patient'
                                : 'Search Provider'
                        }
                        onInputChange={onTextChange}
                        onChange={onOptionSelect}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <Button
                        onClick={() => {
                            history.push(
                                Routes.APPOINTMENTS
                                    .SEARCH_PROVIDER,
                            )
                        }}
                        fullWidth>
                        <strong>Book Appointment</strong>
                    </Button>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item md={8} xs={12}>
                    <WorkListCard />
                </Grid>
                <Grid item md={4} xs={12}>
                    <ConsultationTrackingCard />
                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default SuperAdminDashboard
