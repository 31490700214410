import React, { Component } from 'react'
import './Dropdown.scss'

import {
    Select,
    MenuItem,
    FormControl,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

class Dropdown extends Component {
    renderOptions = (options) =>
        options.map((option, index) => (
            <MenuItem
                key={`${option}-${index}`}
                value={option}>
                {option}
            </MenuItem>
        ))

    handleChange = (event) => {
        const { onChange } = this.props
        onChange(event.target.value)
    }

    render() {
        const { id, defaultValue, options, className } =
            this.props

        return (
            <FormControl
                className={`dropdown-container ${className}`}>
                <Select
                    id={id}
                    className={`dropdown-container__select ${className}`}
                    value={defaultValue ?? ''}
                    onChange={this.handleChange}
                    displayEmpty
                    disableUnderline
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    IconComponent={ExpandMoreIcon}>
                    {this.renderOptions(options)}
                </Select>
            </FormControl>
        )
    }
}

export default Dropdown
