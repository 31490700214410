export const UserRoles = Object.freeze({
    PATIENT: 'patient',
    PROVIDER: 'provider',
    SUPERVISOR: 'supervisor_physician',
    TM2U_ADMIN: 'tm2u_admin',
    CLINIC_CORDINATION: 'clinic_coordination',
    TM2U_SUPER_ADMIN: 'tm2u_super_admin',
    BILLING_ADMIN: 'billing_admin',
    MID_PROVIDER: 'midprovider',
})

export const getAllUserRoles = () =>
    Object.values(UserRoles)

export const UserOptions = Object.freeze({
    PATIENT: 'Patient',
    PROVIDER: 'Provider',
    STAFF: 'Staff',
    CLINICAL_ADMIN: 'Clinic',
})

export const getAllUserOptions = () =>
    Object.values(UserOptions)
export const adminOptions = Object.freeze({
    ADMIN: 'Admin',
    SUPERADMIN: 'Super Admin',
    BILING_ADMIN: 'Biling Admin',
})
export const getAllAdmin = () => Object.values(adminOptions)
export const getUserListAccess = (role, userType) => {
    let access = {
        view: true,
        edit: true,
        status: true,
    }

    if (role === UserRoles.TM2U_ADMIN) {
        if (UserOptions.CLINICAL_ADMIN === userType) {
            access = {
                view: true,
                edit: false,
                status: false,
            }
        }
        if (UserOptions.STAFF === userType) {
            access = {
                view: true,
                edit: false,
                status: false,
            }
        }
        if (UserOptions.PROVIDER === userType) {
            access = {
                view: true,
                edit: false,
                status: false,
            }
        }
    }
    if (role === UserRoles.CLINIC_CORDINATION) {
        if (UserOptions.CLINICAL_ADMIN === userType) {
            access = {
                view: true,
                edit: false,
                status: false,
            }
        }
        if (UserOptions.STAFF === userType) {
            access = {
                view: false,
                edit: false,
                status: false,
            }
        }
        if (UserOptions.PROVIDER === userType) {
            access = {
                view: true,
                edit: false,
                status: false,
            }
        }
        if (UserOptions.PATIENT === userType) {
            access = {
                view: true,
                edit: true,
                status: false,
            }
        }
    }
    return access
}
