import * as actionType from '../actionType'
import {
    setLoading,
    setError,
    setErrorMessage,
    setLoadingAdmit,
    setLoadingEndRoom,
} from './loader'

import {
    getProviderDetail,
    getProviderList,
    getCalendarSlots,
    getCalendarDates,
    getProviderSearchList,
    getProviderProfile,
    updateProviderStatus as updateProviderStatusService,
    saveProviderProfile,
    editProviderProfile,
    getProviderSpecialityList,
    getProviderClientList,
    getActiveProviderList,
    getTimeoffsList,
    getTimeoffsListStatus,
    updateTimeoffsStatus,
    getEncounterQueueListAPI,
    getTokenVideoProvider,
    postAdmitUrl,
    postEndRoomUrl,
    postKickParticipantUrl,
    addParticipantService,
} from '../../services/Provider/provider'
import { isEmpty } from 'lodash'

export const setProviderList = (value) => ({
    type: actionType.SET_PROVIDER_LIST,
    value,
})

export const setProviderDetails = (value) => {
    return {
        type: actionType.SET_PROVIDER_DETAILS,
        value,
    }
}

export const setCalendarDates = (value) => ({
    type: actionType.SET_CALENDAR_DATES,
    value,
})

export const setSpeciality = (value) => ({
    type: actionType.SET_SPECIALITY_LIST,
    value,
})
export const setClientList = (value) => ({
    type: actionType.SET_CLIENT_LIST,
    value,
})
export const setActiveProviderList = (value) => ({
    type: actionType.SET_ACTIVE_PROVIDER_LIST,
    value,
})

export const setCalendarSlots = (value) => ({
    type: actionType.SET_CALENDAR_SLOTS,
    value,
})

export const setProviderProfile = (value) => {
    return {
        type: actionType.SET_PROVIDER_PROFILE,
        value,
    }
}
export const updateProviderTimeoffsStatus = (
    id,
    status,
    comments,
    onSuccess,
    onFailure,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        updateTimeoffsStatus(id, status, comments)
            .then((res) => {
                dispatch(setLoading(false))
                //dispatch(getProviderTimeoffs());
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                onFailure && onFailure()
                console.log('updateTimeoffsStatus error')
            })
    }
}
export const getProviderDetails = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setProviderDetails(null))
        getProviderDetail(id)
            .then((res) => {
                if (res.data) {
                    dispatch(setProviderDetails(res.data))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                console.log(
                    'Inside getProviderDetails error',
                    err,
                )
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getProvidersList = (
    speciality,
    visitType,
    patient,
    clinicId,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setProviderList([]))
        getProviderList(
            speciality,
            visitType,
            patient,
            clinicId,
        )
            .then((res) => {
                if (res.data) {
                    dispatch(setProviderList(res.data))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                console.log(
                    'Inside getProviderList error',
                    err,
                )
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getProviderCalendarDates = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getCalendarDates(payload)
            .then((res) => {
                dispatch(setLoading(false))
                console.log(
                    '=======calendar slots================',
                    res,
                )
                if (res && res.data) {
                    dispatch(setCalendarDates(res.data))
                }
            })
            .catch((err) => {
                console.log('getCalendarSlots error', err)
                dispatch(setLoading(false))
            })
    }
}

export const getSpecialityList = (onSuccess) => {
    return (dispatch) => {
        getProviderSpecialityList()
            .then((res) => {
                if (res && res.data) {
                    dispatch(setSpeciality(res.data))
                }
                onSuccess && onSuccess()
            })
            .catch((err) => {
                console.log('getSpecialityList error', err)
            })
    }
}

export const getClientList = (onSuccess) => {
    return (dispatch) => {
        getProviderClientList()
            .then((res) => {
                if (res && res.data) {
                    dispatch(setClientList(res.data))
                }
                onSuccess && onSuccess()
            })
            .catch((err) => {
                console.log('getClientList error', err)
            })
    }
}

export const getActiveProvidersList = (onSuccess) => {
    return (dispatch) => {
        getActiveProviderList()
            .then((res) => {
                if (res && res.data) {
                    dispatch(
                        setActiveProviderList(res.data),
                    )
                }
                onSuccess && onSuccess()
            })
            .catch((err) => {
                console.log(
                    'getActiveProvidersList error',
                    err,
                )
            })
    }
}

export const getProviderCalendarSlots = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getCalendarSlots(payload)
            .then((res) => {
                dispatch(setLoading(false))
                if (res && res.data) {
                    dispatch(setCalendarSlots(res.data))
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const setSearchProviderList = (value) => {
    return {
        type: actionType.SET_SEARCH_PROVIDER_LIST,
        value,
    }
}
export const resetProviderProfile = () => {
    return {
        type: actionType.RESET_PROVIDER_PROFILE,
    }
}
export const handleGetProviderProfile = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setErrorMessage(''))
        getProviderProfile(id)
            .then((res) => {
                if (res.data) {
                    dispatch(setProviderProfile([res.data]))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const searchProviderList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getProviderSearchList(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(
                    setSearchProviderList(res.data ?? {}),
                )
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setSearchProviderList({}))
            })
    }
}

export const setUpdateProviderStatus = (value) => {
    return {
        type: actionType.UPDATE_SEARCH_PROVIDER_STATUS,
        value,
    }
}
export const updateProviderStatus = (payload) => {
    return (dispatch) => {
        setLoading(true)
        updateProviderStatusService(payload)
            .then((res) => {
                setLoading(false)

                // update row user status based on userId
                dispatch(setUpdateProviderStatus(payload))
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
            })
    }
}

export const handleSaveProviderProfile = (
    payload,
    onSuccess,
    onError,
) => {
    return (dispatch) => {
        setLoading(true)
        saveProviderProfile({ ...payload })
            .then((res) => {
                setLoading(false)
                dispatch(setEditProviderProfile(res.data))
                onSuccess && onSuccess(res.data)
            })
            .catch((err) => {
                onError && onError(err)
                setLoading(false)
                dispatch(setError(true))
            })
    }
}
export const getProviderTimeoffs = (id, status) => {
    return (dispatch) => {
        setLoading(true)

        getTimeoffsListStatus(id, status?.toLowerCase())
            .then((res) => {
                setLoading(false)
                dispatch(setTimeoffsList(res.data))
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
                dispatch(setTimeoffsList([]))
            })
    }
}
export const handleEditProviderProfile = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        setLoading(true)
        dispatch(setErrorMessage(''))
        editProviderProfile(payload)
            .then((res) => {
                setLoading(false)
                dispatch(setEditProviderProfile(res.data))

                dispatch(
                    handleGetProviderProfile(
                        payload?.user_id,
                    ),
                )
                onSuccess && onSuccess()
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
                dispatch(setErrorMessage(err?.data.message))
            })
    }
}

export const setEditProviderProfile = (value) => {
    return {
        type: actionType.SET_EDIT_PROVIDER_PROFILE,
        value,
    }
}

export const setTimeoffsList = (value) => {
    return {
        type: actionType.SET_TIMEOFFS_LIST,
        value,
    }
}

export const handleOpenListConsultation = (payload) => {
    return (dispatch) => {
        dispatch(updateOpenListConsultation(payload))
    }
}

export const updateOpenListConsultation = (value) => {
    return {
        type: actionType.SHOW_CONSULTATION_LIST,
        value,
    }
}

export const handleGetEncounterQueueList = (
    phoneNumber,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getEncounterQueueListAPI()
            .then((res) => {
                if (res) {
                    dispatch(getEncounterQueueList(res))
                    let params = {
                        phone_number: phoneNumber,
                        room_id: res?.res[0]?.room_id,
                    }
                    dispatch(
                        actionsGetTokenVideoProvider(
                            params,
                        ),
                    )
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getEncounterQueueList = (value) => {
    return {
        type: actionType.GET_LIST_ENCOUNTER_QUEUE,
        value,
    }
}

export const updateEncounterQueueList = (
    encounterQueueList,
    messageSocket,
) => {
    if (!isEmpty(encounterQueueList)) {
        const action = messageSocket?.action
        const message = messageSocket.data
        const dataEncounterQueueList =
            encounterQueueList?.res // list encouter Queue
        switch (action) {
            case 'member':
                for (
                    let i = 0;
                    i < dataEncounterQueueList?.length;
                    i++
                ) {
                    if (
                        dataEncounterQueueList[i]
                            .encounter_id ===
                        message?.encounter_id
                    ) {
                        // compare encounterQueueList with encounter_id socket
                        let participants =
                            message?.participants
                        for (
                            let x = 0;
                            x < participants.length;
                            x++
                        ) {
                            //check case status === NEUTRAL
                            if (
                                participants[x].status ===
                                'NEUTRAL'
                            ) {
                                dataEncounterQueueList[
                                    i
                                ].participants.push(
                                    participants[x],
                                )
                            } else {
                                let findPhoneNumber =
                                    dataEncounterQueueList[
                                        i
                                    ].participants
                                for (
                                    let k = 0;
                                    k <
                                    findPhoneNumber.length;
                                    k++
                                ) {
                                    // compare by phone number -> update
                                    if (
                                        findPhoneNumber[k]
                                            ?.phone_number ===
                                        participants[x]
                                            .phone_number
                                    ) {
                                        findPhoneNumber[k][
                                            'status'
                                        ] =
                                            participants[
                                                x
                                            ].status
                                    }
                                }
                            }
                        }
                    }
                }
                break
            default:
                break
        }
    }
    return {
        type: actionType.GET_LIST_ENCOUNTER_QUEUE,
        value: encounterQueueList,
    }
}

export const actionsGetTokenVideoProvider = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getTokenVideoProvider(payload)
            .then((res) => {
                dispatch(setLoading(false))
                if (res && res.data) {
                    dispatch(
                        setTokenVideoProvider(
                            res.data?.token,
                        ),
                    )
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const setTokenVideoProvider = (value) => ({
    type: actionType.GET_TOKEN_VIDEO_PROVIDER,
    value,
})

export const addParticipant = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        addParticipantService(payload)
            .then((response) => {
                dispatch(setLoading(false))
                if (response) {
                    handleGetEncounterQueueList()
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const postAdmit = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingAdmit(true))
        const params = {
            phone_number: payload?.phone_number,
        }
        postAdmitUrl(payload?.encounter_id, params)
            .then((res) => {
                console.log(
                    'call api admit successful',
                    res,
                )
                if (res) {
                    dispatch(postAdmitAction(res))
                    dispatch(
                        setEncounterMeetingId(
                            payload?.encounter_id,
                        ),
                    )
                }
                dispatch(setLoadingAdmit(false))
            })
            .catch((err) => {
                console.log('call api admit err', err)
                dispatch(setLoadingAdmit(false))
                dispatch(setError(true))
            })
    }
}

export const postAdmitAction = (value) => {
    return {
        type: actionType.ACTION_ADMIT,
        value,
    }
}

export const postEndRoom = (payload) => {
    return (dispatch) => {
        dispatch(setLoadingEndRoom(true))
        const params = {
            phone_number: payload?.phone_number,
        }
        postEndRoomUrl(payload?.encounter_id, params)
            .then((res) => {
                console.log(
                    'call api end room successful',
                    res,
                )
                if (res) {
                    dispatch(postEndRoomAction(res))
                }
                dispatch(setLoadingEndRoom(false))
            })
            .catch((err) => {
                console.log('call api end room err', err)
                dispatch(setLoadingEndRoom(false))
                dispatch(setError(true))
            })
    }
}

export const postEndRoomAction = (value) => {
    return {
        type: actionType.ACTION_END_ROOM,
        value,
    }
}

export const postKickParticipant = (encounter_id) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        postKickParticipantUrl(encounter_id)
            .then((res) => {
                console.log(
                    'call api kick participant successful',
                    res,
                )
                if (res) {
                    dispatch(postKickParticipantAction(res))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                console.log(
                    'call api kick participant err',
                    err,
                )
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const postKickParticipantAction = (value) => {
    return {
        type: actionType.ACTION_KICK_PARTICIPANT,
        value,
    }
}

export const setEncounterMeetingId = (value) => {
    return {
        type: actionType.SET_ENCOUNTER_MEETING_ID,
        value,
    }
}
