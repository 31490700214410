import {
    Box,
    Divider,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import cross_icon from '../../../assests/images/cross_icon.svg'
import { SelectEl } from '../../../shared/elements'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { useFormik } from 'formik'
import { styled } from '@mui/material/styles'
import { formatPhoneNumber } from '../../../utilities'
import { states } from '../../../constants/states'
import { useEffect, useState } from 'react'
import { stateList } from '../../../containers/AppointmentBooking/constant'
import * as Yup from 'yup'
import { updateMe } from '../../../services/Login/login'
import CheckboxOption from '../../ClinicalNotes/reusable/CheckboxOptions'
import SharedContactModal from '../Module/sharedContactModal/SharedContactModal'
import UncheckSharedModal from '../Module/sharedContactModal/UncheckSharedModal'
import { useNotification } from '../../Appointments/MaterialUi/Notification'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiPaper-root': {
                height: '814px;',
                position: 'relative',
            },
            '& .MuiDialogContent-root': {
                overflow: 'auto',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '780px',
            },
        },
        modalBody: {
            width: '100%',
            boxSizing: 'border-box',
            background: 'white',
            borderColor: '#FFF',
            padding: '0.3rem 1.5rem 1.5rem 1.5rem',
            borderRadius: '8px',
            overflowY: 'auto',
            paddingBottom: '6rem',
            '&:focus': {
                outline: 'none', // Remove the focus outline
            },
        },
        label2: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        labelStyle: {
            color: ' #303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%' /* 22.4px */,
            marginBottom: '10px',
        },
        typoGraphy: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        select: {
            '& .MuiTypography-root': {
                color: '#303E4E !important',
            },
        },
    }),
)

const PatientEditForm = ({ handleClose, isOpenModal, patientInfo, afterComplete }) => {
    const notification = useNotification()
    const classes = useStyles()
    const [generatedStates, setGeneratedStates] = useState([])
    const [isOpenModalContact, setisOpenModalContact] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isOpenModalContactUncheck, setisOpenModalContactUncheck] = useState(false)

    const [isOpenModalSecondaryContact, setisOpenModalSecondaryContact] = useState(false)
    const [isOpenModalEmailSecondaryUncheck, setisOpenModalSeondaryUncheck] = useState(false)

    const funcCloseModal = () => {
        handleClose()
    }

    const formSubmit = async () => {
        setisLoading(true)
        const updatedPtientInfo = {
            ...patientInfo,
            address1: formik.values.addressLine1,
            address2: formik.values.addressLine2,
            state: formik.values.state,
            city: formik.values.city,
            zip: formik.values.zipcode,
            phone_number: formik.values.phone_number,
            preferred_name: formik.values.preferred_name,
            shared_phone: formik.values.shared_phone,
            shared_secondary_phone: formik.values.secondary_phone_number
                ? formik.values.shared_secondary_phone
                : false,
            secondary_phone_number: formik.values.secondary_phone_number,
        }
        try {
            const data = await updateMe(updatedPtientInfo)
            if (!data?.data?.status) {
                notification(data?.data?.message || 'Something went wrong!', 'error')
            }
        } catch (error) {
            console.error(error)
        }

        afterComplete()
        funcCloseModal()
        setisLoading(false)
    }

    const generatingStates = () => {
        const data = []
        for (const singleState of stateList) {
            const ObjCreate = {
                key: singleState.state,
                value: singleState.stateLabel,
            }

            data.push(ObjCreate)
        }
        setGeneratedStates([...data])
    }
    useEffect(() => {
        generatingStates()
        fieldSetter()
    }, [isOpenModal])

    const uncheckNo = () => {
        formik.setFieldValue('shared_phone', patientInfo?.shared_phone)
        setisOpenModalContactUncheck(false)
    }

    const uncheckSecondaryNo = () => {
        formik.setFieldValue('shared_secondary_phone', true)
        setisOpenModalSeondaryUncheck(false)
    }

    const uncheckSecondaryYes = async () => {
        formik.setFieldValue('shared_secondary_phone', false)
        setisOpenModalSeondaryUncheck(false)
    }

    const uncheckYes = async () => {
        formik.setFieldValue('shared_phone', false)
        setisOpenModalContactUncheck(false)
    }

    const validationSchema = Yup.object().shape({
        phone_number: Yup.string().required('Phone number is required'),
        addressLine1: Yup.string().required('Address Line 1 password is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        zipcode: Yup.string().required('zipcode is required'),
    })
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            preferred_name: '',
            phone_number: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipcode: '',
            shared_phone: '',
            secondary_phone_number: '',
            shared_secondary_phone: '',
        },
        onSubmit: formSubmit,
    })

    const SubmitFunctionSecondary = () => {
        setisOpenModalSecondaryContact(false)
    }

    const SubmitFunction = () => {
        setisOpenModalContact(false)
    }

    const fieldSetter = () => {
        formik.setFieldValue('preferred_name', patientInfo.preferred_name)
        formik.setFieldValue('phone_number', patientInfo.phone_number)
        formik.setFieldValue('addressLine1', patientInfo.address1)
        formik.setFieldValue('addressLine2', patientInfo.address2)
        formik.setFieldValue('city', patientInfo.city)
        formik.setFieldValue('state', patientInfo.state)
        formik.setFieldValue('zipcode', patientInfo.zip)
        formik.setFieldValue('shared_phone', patientInfo.shared_phone)
        formik.setFieldValue('secondary_phone_number', patientInfo.secondary_phone_number)
        formik.setFieldValue('shared_secondary_phone', patientInfo.shared_secondary_phone)
    }
    const contactModalClose = () => {
        setisOpenModalContact(false)
        formik.setFieldValue('shared_phone', false)
    }

    const contactModalClosesecondary = () => {
        setisOpenModalSecondaryContact(false)
        formik.setFieldValue('shared_secondary_phone', false)
    }
    return (
        <Dialog
            open={isOpenModal}
            fullWidth
            className={classes.root}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '780px',
                },
            }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '5px 0px -8px 1px',
                }}>
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    className={classes.typoGraphy}
                    id="customized-dialog-title">
                    <span
                        style={{
                            fontSize: '20px',
                        }}>
                        Edit Your Information
                    </span>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    style={{
                        marginRight: '2em',
                    }}>
                    <img src={cross_icon} style={{ cursor: 'pointer' }} onClick={funcCloseModal} />
                </IconButton>
            </div>
            <Divider style={{ marginTop: '7px', color: '#EAECF0' }} />
            <Box className={classes.modalBody}>
                <Grid
                    container
                    style={{
                        gap: '12px',
                        maxHeight: '690px',
                        overflowY: 'scroll',
                        padding: '10px',
                    }}>
                    <Grid item xs={12}>
                        <label className={classes.labelStyle}>Preferred name</label>
                        <TextField
                            name="prefferd_name"
                            placeholder="Enter preferred name"
                            value={formik.values.preferred_name}
                            variant="outlined"
                            onChange={(e) => {
                                formik.setFieldValue('preferred_name', e.target.value)
                            }}
                        />
                        {/* <Divider style={{ marginTop: '21px', color: '#EAECF0', width: '525px' }} /> */}
                    </Grid>
                    <Grid item xs={12}>
                        <label className={classes.labelStyle}>Primary phone number</label>
                        <TextField
                            name="phone_number"
                            placeholder="Enter Phone number"
                            variant="outlined"
                            value={formik.values.phone_number}
                            inputProps={{
                                maxLength: 10,
                            }}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    'phone_number',
                                    formatPhoneNumber(e.target.value),
                                )
                            }}
                            error={formik.errors.phone_number && formik.touched.phone_number}
                            helperText={
                                formik.errors.phone_number && formik.touched.phone_number
                                    ? formik.errors.phone_number
                                    : ''
                            }
                        />
                        <div style={{ display: 'flex' }}>
                            <CheckboxOption
                                checked={formik.values.shared_phone}
                                sx={{
                                    '& .MuiFormControlLabel': {
                                        marginRight: '0px',
                                    },
                                }}
                                customStyle={{
                                    marginLeft: '2px',
                                    marginRight: '0px',
                                    height: '14px',
                                    width: '14px',
                                }}
                                option={{
                                    label: '',
                                    value: false,
                                }}
                                onChange={(event) => {
                                    formik.setFieldValue('shared_phone', event.target.checked)
                                    setisOpenModalContact(event.target.checked)
                                    if (!event.target.checked) {
                                        setisOpenModalContactUncheck(true)
                                    }
                                }}
                            />
                            <span style={{ marginTop: '9px' }}>Shared phone</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <label className={classes.labelStyle}>Secondary phone number</label>
                        <TextField
                            name="phone_number"
                            placeholder="Enter Phone Number"
                            variant="outlined"
                            value={formik.values.secondary_phone_number}
                            inputProps={{
                                maxLength: 10,
                            }}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    'secondary_phone_number',
                                    formatPhoneNumber(e.target.value),
                                )
                            }}
                        />
                        <div style={{ display: 'flex' }}>
                            <CheckboxOption
                                checked={formik.values.shared_secondary_phone}
                                disabled={formik.values.secondary_phone_number ? false : true}
                                sx={{
                                    '& .MuiFormControlLabel': {
                                        marginRight: '0px',
                                    },
                                }}
                                customStyle={{
                                    marginLeft: '2px',
                                    marginRight: '0px',
                                    height: '14px',
                                    width: '14px',
                                }}
                                option={{
                                    label: '',
                                    value: false,
                                }}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        'shared_secondary_phone',
                                        event.target.checked,
                                    )
                                    setisOpenModalSecondaryContact(event.target.checked)
                                    if (!event.target.checked) {
                                        setisOpenModalSeondaryUncheck(true)
                                    }
                                }}
                            />
                            <span style={{ marginTop: '9px' }}>Shared phone</span>
                        </div>
                        <Divider style={{ marginTop: '12px', color: '#EAECF0' }} />
                    </Grid>
                    <Box
                        style={{
                            width: '303px',
                            // height: '34px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <Box className={classes.label2}>Address</Box>
                    </Box>
                    <Grid item xs={12} style={{marginTop: '-3px'}}>
                        <label className={classes.labelStyle}>Address Line 1 </label>
                        <TextField
                            name="address_line_1"
                            placeholder="Address Line 1"
                            value={formik.values.addressLine1}
                            variant="outlined"
                            onChange={(e) => {
                                formik.setFieldValue('addressLine1', e.target.value)
                            }}
                            error={formik.errors.addressLine1 && formik.touched.addressLine1}
                            helperText={
                                formik.errors.addressLine1 && formik.touched.addressLine1
                                    ? formik.errors.addressLine1
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label className={classes.labelStyle}>Address Line 2</label>
                        <TextField
                            name="address_line_2"
                            placeholder="Address Line 2"
                            variant="outlined"
                            value={formik.values.addressLine2}
                            onChange={(e) => {
                                formik.setFieldValue('addressLine2', e.target.value)
                            }}
                            error={formik.errors.addressLine2 && formik.touched.addressLine2}
                            helperText={
                                formik.errors.addressLine2 && formik.touched.addressLine2
                                    ? formik.errors.addressLine2
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelStyle}>City</label>
                        <TextField
                            name="city"
                            placeholder="Select City"
                            value={formik.values.city}
                            variant="outlined"
                            onChange={(e) => {
                                formik.setFieldValue('city', e.target.value)
                            }}
                            error={formik.errors.city && formik.touched.city}
                            helperText={
                                formik.errors.city && formik.touched.city ? formik.errors.city : ''
                            }
                        />
                    </Grid>
                    <Grid className={classes.select} item xs={5}>
                        <label className={classes.labelStyle}>State</label>
                        <SelectEl
                            style={{ height: '44px', width: '113%' }}
                            name="state"
                            options={generatedStates}
                            placeholder="Select State"
                            value={formik.values.state}
                            onChange={(e) => {
                                formik.setFieldValue('state', e.target.value)
                            }}
                            error={formik.errors.state && formik.touched.state}
                            helperText={
                                formik.errors.state && formik.touched.state
                                    ? formik.errors.state
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label className={classes.labelStyle}>Zipcode</label>
                        <TextField
                            name="zip_code"
                            placeholder="Enter Zipcode"
                            value={formik.values.zipcode}
                            variant="outlined"
                            inputProps={{
                                maxLength: 5,
                            }}
                            onChange={(e) => {
                                formik.setFieldValue('zipcode', e.target.value)
                            }}
                            error={formik.errors.zipcode && formik.touched.zipcode}
                            helperText={
                                formik.errors.zipcode && formik.touched.zipcode
                                    ? formik.errors.zipcode
                                    : ''
                            }
                        />
                    </Grid>
                    <div className="absolute-card">
                        <LoadingButton
                            onClick={() => formik.handleSubmit()}
                            loading={isLoading}
                            type="submit"
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                                height: '43px',
                                background: '#5571C6',
                                borderRadius: '5px',
                                color: '#FFF',
                                textTransform: 'none',
                            }}>
                            {'Save Changes'}
                        </LoadingButton>
                    </div>
                </Grid>
            </Box>
            <SharedContactModal
                isOpenModalContact={isOpenModalContact}
                contactModalClose={contactModalClose}
                SubmitFunction={SubmitFunction}
                patientInfo={patientInfo}
                phone_number={patientInfo?.phone_number}
                height={'380px'}
                heading={'Shared Phone Number'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalContactUncheck}
                contactModalClose={uncheckNo}
                patientInfo={patientInfo}
                SubmitFunction={uncheckYes}
                height={'350px'}
                heading={'Shared Phone Number'}
                // isLoading={isLoading}
                text="You are about to uncheck your shared phone number consent and indicate this is no longer a shared number."
            />
            {/**Secondary Number modal start*/}

            <SharedContactModal
                isOpenModalContact={isOpenModalSecondaryContact}
                contactModalClose={contactModalClosesecondary}
                SubmitFunction={SubmitFunctionSecondary}
                patientInfo={patientInfo}
                phone_number={formik.values?.secondary_phone_number}
                height={'380px'}
                heading={'Shared Phone Number'}
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalEmailSecondaryUncheck}
                contactModalClose={uncheckSecondaryNo}
                patientInfo={patientInfo}
                SubmitFunction={uncheckSecondaryYes}
                height={'340px'}
                // isLoading={isLoading}
                text="You are about to uncheck your shared phone number consent and indicate this is no longer a shared number."
                heading={'Shared Phone Number'}
            />
            {/**Secondary Number modal end*/}
        </Dialog>
    )
}

export default PatientEditForm
