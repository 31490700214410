import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { Field, getIn } from 'formik'
import { maxCharLength } from '../../../../constants'

const ProviderTrainingView = ({
    training,
    index,
    fieldArrayProps,
    setFieldValue,
    initialTraining,
    allValues,
    onBlur,
    setFieldTouched,
}) => {
    const { university } = training

    const { push, remove } = fieldArrayProps
    const ErrorMessage = ({ name }) => (
        <Field
            name={name}
            render={({ form }) => {
                const error = getIn(form.errors, name)
                const touch = getIn(form.touched, name)
                return touch && error ? (
                    <div className="errormsg">{error}</div>
                ) : null
            }}
        />
    )
    return (
        <Grid container spacing={2} key={index}>
            <Grid item xs={5} className={'mb1'}>
                <Field
                    name={`training[${index}].training`}
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    placeholder="Diploma/Degree/Master/Doctorate"
                    inputProps={{
                        maxLength: maxCharLength,
                    }}
                    onChange={(e) => {
                        setFieldValue(
                            `training[${index}].training`,
                            e.target.value,
                        )
                    }}
                    value={training.training}
                    onBlur={(e) =>
                        setFieldTouched(
                            `training[${index}].training`,
                            true,
                        )
                    }
                />
                <ErrorMessage
                    name={`training[${index}].training`}
                />
            </Grid>
            <Grid item xs={5} className={'mb1'}>
                <Field
                    name={`training[${index}].university`}
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    placeholder="School/College/University Name"
                    inputProps={{
                        maxLength: maxCharLength,
                    }}
                    onChange={(e) => {
                        setFieldValue(
                            `training[${index}].university`,
                            e.target.value,
                        )
                    }}
                    value={university}
                    onBlur={(e) =>
                        setFieldTouched(
                            `training[${index}].university`,
                            true,
                        )
                    }
                />
                <ErrorMessage
                    name={`training[${index}].university`}
                />
            </Grid>

            <Grid item xs={2} className="flex">
                {index !== 0 &&
                    index === allValues.length - 1 && (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(initialTraining)
                            }>
                            +
                        </button>
                    )}

                {index === 0 &&
                    (allValues.length === 1 ? (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(initialTraining)
                            }>
                            +
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() => remove(index)}>
                            -
                        </button>
                    ))}
                {index !== 0 && (
                    <button
                        type="button"
                        className="plus-wrap "
                        onClick={() => remove(index)}>
                        -
                    </button>
                )}
            </Grid>
        </Grid>
    )
}

export default ProviderTrainingView
