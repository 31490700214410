import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        borderColor: `#E9ECF1 !important`,
    },
    root: {
        background: '#FBFCFC',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '&:focus': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '& .MuiSelect-select': {
            backgroundColor: 'unset',
        },

        '& .MuiInputBase-input': {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            paddingRight: '20px !important',
        },
    },
    '& .MuiMenu-paper': {
        boxShadow:
            '0px 5px 20px rgba(165, 172, 181, 0.15) !important',
        borderRadius: 8,
        padding: '8px 0',
        width: 224,
        marginTop: 46,
    },

    icon: {
        fontSize: 20,
        // marginTop: -2,
        color: '#303E4E',
    },
    text: {
        color: '#667689',
        fontSize: 14,
    },
}))
