import React, { useState } from 'react'
import { Typography, CircularProgress } from '@material-ui/core'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import clinical_notes from '../../assests/images/clinical_notes.svg'
import downloadIcon from '../../assests/icons/downloadIcon.svg'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import {
    fetchClinicalNotePdfData,
    getClinicalNotesGuest,
    getClinicalNotesPreview,
} from '../../services/ClinicalNotesGuest'
import { ReactComponent as SortIcon } from '../../assests/icons/Sorting.svg'
import moment from 'moment'
import './ClinicalNotesStyles.scss'
import {
    cgiMapper,
    clinical_note_master,
    devEduHistroyMapper,
    familyEnvMapper,
    interVationMapper,
    mapperFunction,
    sessionMapper,
} from '../utils'

function Main() {
    const history = useHistory()
    const notification = useNotification()
    const [currentTab, setCurrentTab] = React.useState('active') // values: 'active', 'inactive'

    const [loading, setLoading] = React.useState(false)
    const [downloadLoading, setDownloadLoading] = React.useState({
        0: false,
    })
    const [sort, setSort] = React.useState({
        active: false,
        inactive: false,
    })

    const [clinicalData, setClinicalData] = useState([])
    const flattenData = (data) => {
        if (!data || data.length === 0) return []

        return data.map((item) => {
            return {
                name: `${item?.practitioner_first_name} ${item?.practitioner_last_name}`,
                date: item?.encounter?.end
                    ? moment(item?.encounter?.end).format('MMMM D, YYYY')
                    : 'N/A',
                id: item?.id,
            }
        })
    }
    const getClinicalNotesData = () => {
        setLoading(true)
        const payload = {
            page: 1,
            limit: 100,
        }
        getClinicalNotesGuest(payload)
            ?.then((res) => {
                const responseData = res?.data?.data?.data
                setClinicalData(flattenData(responseData))
            })
            .catch(() => {
                notification('Something went wrong!', 'error')
            })
            .finally(() => setLoading(false))
    }

    const handleSort = () => {
        setSort((state) => {
            return {
                ...state,
                [currentTab]: !state[currentTab],
            }
        })
        clinicalData?.reverse()
    }

    const handleDownloadPDF = (noteId) => {
        if (!noteId) return
        setDownloadLoading(() => ({
            [noteId]: true,
        }))
        getClinicalNotesPreview(noteId)
            .then((res) => {
                const data = res.data?.data?.data
                if (!data) {
                    notification('Something went wrong!', 'error')
                    return
                }
                const previewData = JSON.parse(data)
                if (!previewData) {
                    notification('Something went wrong!', 'error')
                    return
                }
                const cloneCategoryNotes = _.cloneDeep(previewData?.clinicalNoteModel)
                if (cloneCategoryNotes.system_reviews) {
                    const systemReview = mapperFunction(
                        clinical_note_master.systemReviewMasters,
                        cloneCategoryNotes.system_reviews.system_reviews_opt,
                    )
                    cloneCategoryNotes.system_reviews['system_reviews_opt'] = systemReview
                }
                if (cloneCategoryNotes?.dev_and_edu_history) {
                    const devEduHistry = devEduHistroyMapper(
                        clinical_note_master.devEduHistoryMasters,
                        cloneCategoryNotes.dev_and_edu_history,
                    )
                    cloneCategoryNotes.dev_and_edu_history = devEduHistry
                }
                if (cloneCategoryNotes?.family_history) {
                    if (!cloneCategoryNotes?.family_history?.psych_illness_history) {
                        cloneCategoryNotes.family_history.history_list = []
                    }
                }
                if (cloneCategoryNotes?.interven_utilized) {
                    const intervationMapperprepare = interVationMapper(
                        clinical_note_master.interventionMasters,
                        cloneCategoryNotes.interven_utilized.interventions,
                    )
                    cloneCategoryNotes.interven_utilized['interventions'] = intervationMapperprepare
                }

                if (cloneCategoryNotes?.session_focus) {
                    const sessionmapperPrepare = sessionMapper(
                        clinical_note_master.sessionFocusMasters,
                        cloneCategoryNotes.session_focus.session,
                    )
                    cloneCategoryNotes.session_focus['session'] = sessionmapperPrepare
                }

                if (cloneCategoryNotes?.clinical_global_impression) {
                    const cgi = cgiMapper(
                        clinical_note_master.clinicalImpMasters,
                        cloneCategoryNotes.clinical_global_impression,
                    )
                    cloneCategoryNotes.clinical_global_impression = cgi
                }

                if (cloneCategoryNotes?.family_env_strategy) {
                    const familyEnvMapperPrepare = familyEnvMapper(
                        clinical_note_master.envStrategyMasters,
                        cloneCategoryNotes?.family_env_strategy.fam_env_strategy,
                    )
                    cloneCategoryNotes.family_env_strategy['fam_env_strategy'] =
                        familyEnvMapperPrepare
                }
                fetchClinicalNotePdfData(noteId, {
                    clinicalNoteModel: cloneCategoryNotes,
                    clinicalNoteTemplate: previewData.clinicalNoteTemplate,
                })
                    ?.then((pdfDataRes) => {
                        const blob = new Blob([pdfDataRes.data], { type: 'application/pdf' })
                        const url = window.URL.createObjectURL(blob)
                        window.open(url)
                    })
                    ?.catch((err) => {
                        notification('Something went wrong!', 'error')
                    })
            })
            ?.catch(() => {
                notification('Something went wrong!', 'error')
            })
            ?.finally(() =>
                setDownloadLoading(() => ({
                    [noteId]: false,
                })),
            )
    }

    React.useEffect(() => {
        getClinicalNotesData()
    }, [])

    return (
        <div>
            <div className="clinical_note_main_container">
                <div className="clinical_note_sub_container">
                    <div className="clinical_note_back_container" onClick={() => history.goBack()}>
                        <BackIcon />
                        <Typography>Back to health record</Typography>
                    </div>
                    <div className="clinical_note_body">
                        <div className="clinical_note_body_header">
                            <img
                                src={clinical_notes}
                                alt="clinical_report"
                                height={21}
                                width={17}
                            />
                            <Typography>Clinical Notes</Typography>
                        </div>
                        <div className="clinical_notes_body_contents">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                <p
                                    style={{
                                        color: '#667689',
                                        fontFamily: 'Rubik',
                                        fontSize: '15px',
                                        fontWeight: '400',
                                    }}>
                                    {clinicalData?.length} Clinical Notes
                                </p>
                                <span
                                    onClick={handleSort}
                                    style={{
                                        pointerEvents:
                                            loading || clinicalData?.length === 0 ? 'none' : 'auto',
                                    }}
                                    className="clinical_note_tab_content_sorting">
                                    <SortIcon />
                                    <Typography>
                                        {sort[currentTab] ? 'Oldest to newest' : 'Newest to oldest'}
                                    </Typography>
                                </span>
                            </div>
                            {loading ? (
                                <div className="clinical_note_cards_loader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="clinical_notes_tab_contents">
                                    <div className="clinical_notes_card_container">
                                        {clinicalData?.length > 0 &&
                                            clinicalData?.map((clinical_data, indx) => {
                                                return (
                                                    <div
                                                        key={indx}
                                                        className="clinical_notes_cards">
                                                        <div className="clinical_notes_cards_contents">
                                                            <Typography
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 17,
                                                                    fontWeight: 500,
                                                                }}>
                                                                {clinical_data?.date}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: '#667689',
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                }}></Typography>
                                                            <Typography
                                                                style={{
                                                                    color: '#667689',
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                }}>
                                                                Provider
                                                            </Typography>
                                                            <Typography
                                                                className="drName"
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Rubik',
                                                                }}>
                                                                {clinical_data?.name}
                                                            </Typography>
                                                        </div>
                                                        {downloadLoading[clinical_data?.id] ? (
                                                            <div className="clinical_notes_card_type clinical_notes_card_type_loader">
                                                                <CircularProgress
                                                                    style={{
                                                                        height: 20,
                                                                        width: 20,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="clinical_notes_card_type"
                                                                onClick={() =>
                                                                    handleDownloadPDF(
                                                                        clinical_data?.id,
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}>
                                                                <img
                                                                    src={downloadIcon}
                                                                    alt="download"
                                                                    height={13}
                                                                    width={11}
                                                                />{' '}
                                                                <span
                                                                    style={{
                                                                        color: '#5571C6',
                                                                        fontSize: '16px',
                                                                        fontFamily: 'Rubik',
                                                                        fontWeight: '400',
                                                                    }}>
                                                                    {' '}
                                                                    Download visit note
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
