import React from "react";

import Button from "@material-ui/core/Button";
import AddPersonWhite from "../../../icons/AddPersonWhite";
import TeleAddParticipantIcon from "../../../icons/TeleAddParticipantIcon";

export default function AddPersonButton(props: {
  className?: string;
  isMobile?: boolean;
  onClick: () => void;
}) {
  return (
    <Button
    className={props.className}
    onClick={props.onClick}
    // startIcon={<AddPersonWhite/>}
    data-cy-add-person-toggle
    >
      <TeleAddParticipantIcon stroke={props.isMobile && 'white'} width={props.isMobile? 22:32} height={props.isMobile? 22:32}/>
    </Button>
  );
}
