import Axios from "axios";
import { config } from "../../../constants";

const methods = {
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};

async function setCurrentApp(data: any) {
    try {
        return await Axios.post(
            `${config.dev.baseURL}/consultation-timing/create`,
            data,
            methods
        );
    } catch (err) {
        throw err;
    }
}
async function getCurrentApp(id: any) {
    try {
        return await Axios.get(
            `${config.dev.baseURL}/consultation-timing/findByAppointmentId/${id}`
        );
    } catch (err) {
        throw err;
    }
}

export { setCurrentApp, getCurrentApp };
