import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        border: (props) => `${props.border} !important`,
    },
    cssFocused: {
        color: theme.palette.common.black,
    },
    option: {
        '&[data-focus="true"]': {
            borderColor: 'transparent',
        },
        '&[aria-selected="true"]': {
            borderColor: 'transparent',
        },
    },
    root: {
        height: (props) => props.height,
        backgroundColor: theme.palette.common.white,
        '& input::placeholder': {
            fontSize: theme.typography.body1,
            color: theme.palette.common.black,
        },
        '& .MuiInputBase-input.MuiAutocomplete-input': {
            color: theme.palette.common.black,
            fontSize: 14,
        },
    },
    popupIndicator: {
        '& span': {
            '& svg': {
                '& path': {
                    d: "path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z')",
                },
            },
        },
    },
}))
