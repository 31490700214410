import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import React from 'react'
import CheckboxOption from '../../../../ClinicalNotes/reusable/CheckboxOptions'
import { ReactComponent as RadioInvertedCheck } from '../../../../../assests/icons/RadioInvertedCheck.svg'
import IntakeModalForm from './IntakeModalForm'
function IntakeForm({
    values,
    setValues,
    errors,
    touched,
    sign,
    setSign,
    isCheckboxEnabled,
    setIsCheckboxEnabled,
}) {
    const [open, setOpen] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState(0)
    const tabContentRefs = React.useRef([])
    const [scrolledTabs, setScrolledTabs] = React.useState([])

    const toggleOpen = () => {
        setOpen(true)
        setCurrentTab(0)
    }
    const goToPage = (url) => window.open(url, '_blank')
    const renderError = (fieldName) => {
        if (!errors[fieldName] || !touched[fieldName]) return <></>
        return (
            errors[fieldName] &&
            touched[fieldName] && (
                <Typography style={{ color: 'red', marginLeft: 20 }}>
                    {errors[fieldName]}
                </Typography>
            )
        )
    }

    return (
        <div className="booking_boxes intake_form_box">
            <div className='heading_holder'>
                <Typography className="step-headers">
                    Please fill out the new patient form.{' '}
                </Typography>
            </div>

            <div className="body">
                {/* <Typography className="appo_details_title">New Patient Intake Form</Typography> */}
                <div className="provider_card intake_form_card">
                    <div className="card_content">
                        <div className="intake_form_container">
                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">1.</Typography>
                                    <Typography className="question_text">
                                        Do you regularly consult a Behavioral Health provider or
                                        seek regular care?
                                        <span className='required'>*</span>
                                    </Typography>
                                </span>
                                <RadioGroup
                                    row
                                    value={values.regularConsultation}
                                    onChange={(event) =>
                                        setValues('regularConsultation', event.target.value)
                                    }
                                    className="intake_form_radio_group"
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group">
                                    <FormControlLabel
                                        className='cstm_label'
                                        value="yes"
                                        control={<Radio checkedIcon={<RadioInvertedCheck />} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        className='cstm_label'
                                        value="no"
                                        control={<Radio checkedIcon={<RadioInvertedCheck />} />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {values.regularConsultation === 'yes' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            allignItem: 'center',
                                            marginLeft: 20,
                                        }}>
                                        <Typography style={{ marginTop: '12px' }} className="question_text">
                                            Please specify where:{' '}
                                        </Typography>
                                        <span style={{ width: '50%', marginLeft: 10 }} className='input_holder'>
                                            <TextField
                                                variant="outlined"
                                                // InputProps={{
                                                //     startAdornment: (
                                                //         <Typography style={{ width: '60%' }}>
                                                //             Please specify where:{' '}
                                                //         </Typography>
                                                //     ),
                                                // }}
                                                value={values?.regularConsultationPlace}
                                                onChange={(event) =>
                                                    setValues(
                                                        'regularConsultationPlace',
                                                        event.target.value,
                                                    )
                                                }
                                            />
                                            {errors?.regularConsultationPlace && (
                                                <Typography
                                                    style={{
                                                        color: 'red',
                                                        marginLeft: 2,
                                                        marginTop: 2,
                                                    }}>
                                                    {errors?.regularConsultationPlace}
                                                </Typography>
                                            )}
                                        </span>
                                    </div>
                                )}

                                {/* {renderError('regularConsultationPlace')}
                                {renderError('regularConsultation')} */}
                            </div>

                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">2.</Typography>
                                    <Typography className="question_text">
                                        Is the patient using insurance?
                                        <span className='required'>*</span>
                                    </Typography>
                                </span>
                                <RadioGroup
                                    row
                                    value={values.insurance}
                                    onChange={(event) => setValues('insurance', event.target.value)}
                                    className="intake_form_radio_group"
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group">
                                    <FormControlLabel
                                        className='cstm_label'
                                        value="yes"
                                        control={<Radio checkedIcon={<RadioInvertedCheck />} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        className='cstm_label'
                                        value="no"
                                        control={<Radio checkedIcon={<RadioInvertedCheck />} />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {/* {renderError('insurance')} */}
                            </div>

                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">3.</Typography>
                                    <span className="question">
                                        <Typography className="question_text">
                                            Do you have any current or pending legal issues or are
                                            being advised by a court judge,parole officer, or CPS
                                        </Typography>
                                        <span style={{ display: 'flex', gap: 7 }}>
                                            <Typography className="question_text">
                                                to seek mental health care or assessment?
                                                <span className='required'>*</span>
                                            </Typography>
                                        </span>
                                    </span>
                                </span>
                                <RadioGroup
                                    row
                                    value={values.legalIssues}
                                    onChange={(event) =>
                                        setValues('legalIssues', event.target.value)
                                    }
                                    className="intake_form_radio_group"
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group">
                                    <FormControlLabel
                                        className='cstm_label'
                                        value="yes"
                                        control={<Radio checkedIcon={<RadioInvertedCheck />} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        className='cstm_label'
                                        value="no"
                                        control={<Radio checkedIcon={<RadioInvertedCheck />} />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {/* {renderError('legalIssues')} */}
                            </div>

                            <span className="question">
                                <Typography className="question_text sub_heading_text">
                                    Please select if you have ever experienced or suffered from any
                                    of the following in the past or present? (check one or all that applies)
                                </Typography>
                            </span>

                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">
                                        Thoughts of harming others
                                        <span className='required'>*</span>
                                    </Typography>
                                </span>
                                <div className="answer_boxes">
                                    <CheckboxOption
                                        option={{
                                            label: 'Past',
                                            value: true,
                                        }}
                                        checked={values.harmingOthers.past}
                                        onChange={(event) => {
                                            setValues('harmingOthers.past', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Present',
                                            value: false,
                                        }}
                                        checked={values.harmingOthers.present}
                                        onChange={(event) => {
                                            setValues('harmingOthers.present', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Never',
                                            value: false,
                                        }}
                                        checked={values.harmingOthers.never}
                                        onChange={(event) => {
                                            setValues('harmingOthers.never', event.target.checked)
                                        }}
                                    />
                                </div>
                                {renderError('harmingOthers')}
                            </div>

                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">
                                        Suicide ideation
                                        <span className='required'>*</span>
                                    </Typography>
                                </span>
                                <div className="answer_boxes">
                                    <CheckboxOption
                                        option={{
                                            label: 'Past',
                                            value: true,
                                        }}
                                        checked={values.suicidal.past}
                                        onChange={(event) => {
                                            setValues('suicidal.past', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Present',
                                            value: false,
                                        }}
                                        checked={values.suicidal.present}
                                        onChange={(event) => {
                                            setValues('suicidal.present', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Never',
                                            value: false,
                                        }}
                                        checked={values.suicidal.never}
                                        onChange={(event) => {
                                            setValues('suicidal.never', event.target.checked)
                                        }}
                                    />
                                </div>
                                {/* {renderError('suicidal')} */}
                            </div>

                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">
                                        Alcohol abuse
                                        <span className='required'>*</span>
                                    </Typography>
                                </span>
                                <div className="answer_boxes">
                                    <CheckboxOption
                                        option={{
                                            label: 'Past',
                                            value: true,
                                        }}
                                        checked={values.alcoholAbuse.past}
                                        onChange={(event) => {
                                            setValues('alcoholAbuse.past', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Present',
                                            value: false,
                                        }}
                                        checked={values.alcoholAbuse.present}
                                        onChange={(event) => {
                                            setValues('alcoholAbuse.present', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Never',
                                            value: false,
                                        }}
                                        checked={values.alcoholAbuse.never}
                                        onChange={(event) => {
                                            setValues('alcoholAbuse.never', event.target.checked)
                                        }}
                                    />
                                </div>
                                {/* {renderError('alcoholAbuse')} */}
                            </div>

                            <div className="question">
                                <span style={{ display: 'flex', gap: 7 }}>
                                    <Typography className="question_text">
                                        Substance abuse
                                        <span className='required'>*</span>
                                    </Typography>
                                </span>
                                <div className="answer_boxes">
                                    <CheckboxOption
                                        option={{
                                            label: 'Past',
                                            value: true,
                                        }}
                                        checked={values.substanceAbuse.past}
                                        onChange={(event) => {
                                            setValues('substanceAbuse.past', event.target.checked)
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Present',
                                            value: false,
                                        }}
                                        checked={values.substanceAbuse.present}
                                        onChange={(event) => {
                                            setValues(
                                                'substanceAbuse.present',
                                                event.target.checked,
                                            )
                                        }}
                                    />
                                    <CheckboxOption
                                        option={{
                                            label: 'Never',
                                            value: false,
                                        }}
                                        checked={values.substanceAbuse.never}
                                        onChange={(event) => {
                                            setValues('substanceAbuse.never', event.target.checked)
                                        }}
                                    />
                                </div>
                                {/* {renderError('substanceAbuse')} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="terms">
                <CheckboxOption
                    customStyle={{ marginTop: 2 }}
                    option={{
                        label: '',
                        value: true,
                    }}
                    checked={values.releaseOfInfo}
                    onChange={(event) => {
                        toggleOpen()
                        // setValues('termsAgree', event.target.checked)
                    }}
                />
                <span className="agreement">
                    I agree to the TeleMed2U{' '}
                    <span
                        onClick={() => {
                            setOpen(true), setCurrentTab(0)
                        }}>
                        Terms & Conditions
                    </span>
                    ,{' '}
                    <span
                        onClick={() => {
                            setOpen(true), setCurrentTab(1)
                        }}>
                        Informed Consent for Medical Services
                    </span>
                    ,{' '}
                    <span
                        onClick={() => {
                            setOpen(true), setCurrentTab(2)
                        }}>
                        Telehealth Consent
                    </span>
                    ,{' '}
                    <span
                        onClick={() => {
                            setOpen(true), setCurrentTab(3)
                        }}>
                        Privacy Policy
                    </span>{' '}
                    and{' '}
                    <span
                        onClick={() => {
                            setOpen(true), setCurrentTab(4)
                        }}>
                        Notice of Privacy Practices
                    </span>
                    {/* ,{' '}
                    <span
                        onClick={() =>
                            goToPage('https://www.telemed2u.com/release-of-information')
                        }>
                        Release of Information
                    </span> */}
                    , and understand all of these can be accessed at any time on{' '}
                    <span onClick={() => goToPage('https://www.telemed2u.com')}>telemed2u.com</span>
                    . I have read and give my permission for the{' '}
                    <span
                        onClick={() => {
                            setOpen(true), setCurrentTab(5)
                        }}>
                        Release of Information
                    </span>
                    .
                </span>
                {open && (
                    <IntakeModalForm
                        open={open}
                        toggleOpen={setOpen}
                        onModalClose={() => setOpen(false)}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        tabContentRefs={tabContentRefs}
                        isCheckboxEnabled={isCheckboxEnabled}
                        setIsCheckboxEnabled={setIsCheckboxEnabled}
                        scrolledTabs={scrolledTabs}
                        setScrolledTabs={setScrolledTabs}
                        values={values}
                        setValues={setValues}
                        sign={sign}
                        setSign={setSign}
                    />
                )}
            </div>

            {/* <div className="terms" style={{ marginTop: 5 }}>
                <CheckboxOption
                    customStyle={{ marginTop: 2 }}
                    option={{
                        label: '',
                        value: true,
                    }}
                    checked={values.releaseOfInfo}
                    onChange={(event) => {
                        setValues('releaseOfInfo', event.target.checked)
                    }}
                />
                <span className="agreement">
                    I have read and give my permission for the{' '}
                    <span
                        onClick={() =>
                            goToPage('https://www.telemed2u.com/release-of-information')
                        }>
                        Release of Information
                    </span>
                    .
                </span>
            </div> */}
        </div>
    )
}

export default IntakeForm
