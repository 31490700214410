import {
    getClinicList,
    getClinicByClinicId,
    addClinicDetails,
    editClinicDetails,
} from '../../services/Clinic/clinic'
import * as actionType from '../actionType'
import { setLoading, setError } from './loader'

export const setSearchClinicList = (value) => {
    return {
        type: actionType.SET_SEARCH_CLINIC_LIST,
        value,
    }
}

export const setClinicDetails = (value) => {
    return {
        type: actionType.SET_CLINIC_DETAIL,
        value,
    }
}

export const setEditClinicDetails = (value) => {
    return {
        type: actionType.SET_EDIT_CLINIC_DETAIL,
        value,
    }
}

export const resetClinicDetails = () => {
    return {
        type: actionType.RESET_CLINIC_DETAIL,
    }
}

export const searchClinicList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getClinicList(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setSearchClinicList(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setSearchClinicList({}))
            })
    }
}

export const getClinicDetails = (clinicId) => {
    return (dispatch) => {
        setLoading(true)
        getClinicByClinicId(clinicId)
            .then((res) => {
                setLoading(false)
                dispatch(setClinicDetails(res.data))
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
            })
    }
}

export const handleAddClinicDetails = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        setLoading(true)
        addClinicDetails(payload)
            .then((res) => {
                setLoading(false)
                dispatch(setEditClinicDetails(res.data))
                onSuccess && onSuccess(res)
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
            })
    }
}

export const handleEditClinicDetails = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        setLoading(true)
        editClinicDetails(payload)
            .then((res) => {
                setLoading(false)
                dispatch(setEditClinicDetails(res.data))
                dispatch(
                    searchClinicList({
                        page: 1,
                        filterString: '',
                    }),
                )
                onSuccess && onSuccess()
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
            })
    }
}
