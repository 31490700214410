import * as actionType from '../actionType'

const initialState = {
    documents: {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
        data: [],
        totalCount: 0,
        sort: {
            provider_name: 'asc',
            speciality_name: 'asc',
            time: 'asc',
            date: 'asc',
        },
    },
    pagination: {
        page: 1,
        limit: 10,
    },
}

const documents = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_DOCUMENTS_LIST:
            return {
                ...state,
                documents: {
                    isLoading: false,
                    isSuccess: true,
                    data: action.value.documentList,
                    totalCount: action.value.totalCount,
                },
            }

        default:
            return state
    }
}
export default documents
