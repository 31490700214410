import store from '../../store'
import { setLoading } from '../../store/actions/loader'
import { uploadImage } from '../../services/Document/documents'

export const uploadFormDocuments = async (file, userId) => {
    store.dispatch(setLoading(true))
    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('jpg', file)
    formData.append('category', 'identity')

    try {
        const res = await uploadImage(formData)
        store.dispatch(setLoading(false))
        return res.data
    } catch (err) {
        console.log(err)
        store.dispatch(setLoading(false))
        throw err
    }
}

export const getCharOfName = (name) =>
    name
        ? name
              .split(' ')
              .map((item) => item.charAt(0))
              .join('')
              .toUpperCase()
        : null
