import {
    Box,
    Card,
    CardContent,
    Grid,
    Hidden,
    IconButton,
    Link,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import HeaderIcon from '../../../assests/sidebar/Mobile Icons_1 copy 6.svg'
import Routes from '../../../constants/routes'
import { UserRoles } from '../../../constants/user-roles'
import {
    getFilteredWorkList,
    markWorkList,
} from '../../../services/WorkList/queries'
import {
    CardHeaderEl,
    NoRowsOverlayEl,
    SelectEl,
} from '../../../shared/elements'
import {
    WORKLIST_FILTER_FAILURE,
    WORKLIST_FILTER_LOADING,
    WORKLIST_FILTER_SUCCESS,
    WORKLIST_MARK_STATUS,
    WORKLIST_MODAL_CLOSE,
    WORKLIST_MODAL_OPEN,
    WORKLIST_STATUS_FILTER,
    WORKLIST_TYPE_FILTER,
} from '../constant'
import { MarkStatusModal } from '../MarkStatusModal'
import { initialState, reducer } from '../reducer'
import {
    RequestStatusMapper,
    RequestType,
    StatusColorMapper,
    WorkListStatus,
} from '../utils'
import './workList.scss'

const useStyles = makeStyles({})
const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
        color: '#737980',
    },
    calender: {
        width: 200,
    },
}))

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles()
    return (
        <div className={classes.placeholder}>
            {children}
        </div>
    )
}

function WorkListCard() {
    const classes = useStyles()
    const history = useHistory()
    const role = localStorage.getItem('role')

    const columns = [
        {
            headerName: (
                <Typography variant="body2">
                    Request Date
                </Typography>
            ),
            sortable: false,
            field: 'created_at',
            flex: 1,
            renderCell: ({ value }) => {
                return moment(value).format('MM/DD/YYYY')
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Request Type
                </Typography>
            ),
            flex: 1.5,
            sortable: false,
            field: 'reason',
        },
        {
            headerName: (
                <Typography variant="body2">
                    Requested by
                </Typography>
            ),
            sortable: false,
            field: 'user_name',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Link
                        noWrap
                        component="button"
                        color="primary"
                        className="capitalize"
                        underline="none"
                        onClick={() =>
                            onRowClick(params.row.user)
                        }>
                        {params.value}
                    </Link>
                )
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Status
                </Typography>
            ),
            field: 'status',
            flex: 1,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <Typography variant="body1">
                        <span
                            style={StatusColorMapper(
                                cellValues.value,
                            )}
                            className="square"></span>
                        {RequestStatusMapper(
                            cellValues.value,
                        )}
                    </Typography>
                )
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Action
                </Typography>
            ),
            field: 'action',
            flex: 1,
            sortable: false,
            renderCell: (cellValues) => (
                <Typography
                    variant="body1"
                    color="primary"
                    onClick={() =>
                        onMarkClick(cellValues.row)
                    }
                    className="content-align-center innerwrap dash-appt-table-superadmin bold cursor-pointer">
                    <strong>
                        {' '}
                        {cellValues.row.status !==
                        'completed'
                            ? 'Mark'
                            : 'Unmark'}
                    </strong>
                </Typography>
            ),
        },
    ]

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    React.useEffect(() => {
        const {
            worklist: {
                filters: { status, requestType },
            },
        } = state
        fetchWorkList({
            status,
            request_type: requestType,
        })
    }, [state.worklist.filters])

    async function fetchWorkList({ status, request_type }) {
        try {
            dispatch({ type: WORKLIST_FILTER_LOADING })
            const worklist = await getFilteredWorkList({
                page: 1,
                limit:
                    role === UserRoles.TM2U_SUPER_ADMIN
                        ? 5
                        : 10,
                status,
                request_type,
            })

            if (worklist.status === 404) {
                return dispatch({
                    type: WORKLIST_FILTER_SUCCESS,
                    data: {
                        adminWorkList: [],
                        totalCount: 0,
                    },
                })
            }

            dispatch({
                type: WORKLIST_FILTER_SUCCESS,
                data: worklist.data,
            })
        } catch (error) {
            if (error?.status === 404) {
                return dispatch({
                    type: WORKLIST_FILTER_SUCCESS,
                    data: {
                        adminWorkList: [],
                        totalCount: 0,
                    },
                })
            }

            dispatch({ type: WORKLIST_FILTER_FAILURE })
        }
    }

    function onRowClick(user) {
        history.push(Routes.PROFILE, {
            userId: user.id,
            role: user.role_name,
        })
    }

    function onModalClose() {
        dispatch({ type: WORKLIST_MODAL_CLOSE })
    }

    function onTypeChange(value) {
        dispatch({ type: WORKLIST_TYPE_FILTER, value })
    }

    function onMarkClick(selected) {
        dispatch({ type: WORKLIST_MODAL_OPEN, selected })
    }

    function onStatusChange(value) {
        dispatch({ type: WORKLIST_STATUS_FILTER, value })
    }

    async function onMarkWorklist(data) {
        const { status, comments } = data
        markWorkList({
            id: state.worklist.selected.id,
            status,
            comment: comments,
        })
        dispatch({ type: WORKLIST_MARK_STATUS, data })
    }

    const {
        worklist: {
            filters: { requestType, status },
        },
        modal: { opened },
    } = state

    return (
        <div className="MuiDataGrid-table-card-wrapper">
            <Card
                style={{
                    height:
                        role === UserRoles.TM2U_SUPER_ADMIN
                            ? 386
                            : 646,
                    width: '100%',
                }}>
                <MarkStatusModal
                    opened={opened}
                    status={state.worklist.selected?.status}
                    onClose={onModalClose}
                    onSubmit={onMarkWorklist}
                />
                <CardHeaderEl
                    title="Worklist"
                    avatar={
                        <Box
                            style={{
                                height: 28,
                                width: 28,
                            }}>
                            <img src={HeaderIcon} />
                        </Box>
                    }
                    action={
                        <Grid
                            container
                            spacing={1}
                            alignItems="center">
                            <Grid item>
                                <Hidden mdDown={true}>
                                    <SelectEl
                                        name="request-type"
                                        placeholder="Request Type"
                                        value={requestType}
                                        fullWidth
                                        // selectProps={{
                                        //     style: {
                                        //         height: 40,
                                        //         minWidth: 200,
                                        //     },
                                        // }}
                                        onChange={(
                                            event,
                                        ) => {
                                            onTypeChange(
                                                event.target
                                                    .value,
                                            )
                                        }}
                                        options={
                                            RequestType
                                        }
                                    />
                                </Hidden>
                            </Grid>
                            <Grid item>
                                <Hidden mdDown={true}>
                                    <SelectEl
                                        fullWidth
                                        name="status"
                                        placeholder="Status"
                                        value={status}
                                        selectProps={{
                                            style: {
                                                height: 40,
                                                minWidth: 120,
                                            },
                                        }}
                                        onChange={(
                                            event,
                                        ) => {
                                            onStatusChange(
                                                event.target
                                                    .value,
                                            )
                                        }}
                                        options={
                                            WorkListStatus
                                        }
                                    />
                                </Hidden>
                            </Grid>
                            <Grid
                                item
                                justifyContent="flex-end">
                                <IconButton
                                    disableFocusRipple={
                                        true
                                    }
                                    disableRipple={true}
                                    centerRipple={false}
                                    size={'small'}
                                    onClick={() =>
                                        history.push(
                                            Routes.WORKLIST,
                                        )
                                    }>
                                    <Typography
                                        color="primary"
                                        variant="body2">
                                        View All
                                    </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent style={{ padding: 0 }}>
                    <div
                        style={{
                            height:
                                role ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? 350
                                    : 590,
                            width: '100%',
                        }}>
                        <DataGrid
                            className={classes.dataGrid}
                            getRowId={(row) => row.id}
                            rows={state.worklist.data}
                            columns={columns}
                            disableColumnSelector
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <NoRowsOverlayEl title="No Records Found." />
                                ),
                            }}
                            rowHeight={
                                role ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? 55
                                    : 53
                            }
                            hideFooter
                            loading={
                                state.worklist.isLoading
                            }
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default WorkListCard
