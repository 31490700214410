const CustomeCheckBoxUnCheck = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.6"
                y="0.6"
                width="16.8"
                height="16.8"
                rx="4.4"
                stroke="#667689"
                stroke-width="1.2"
            />
        </svg>
    )
}
export default CustomeCheckBoxUnCheck
