import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import Participant from '../Participant/Participant'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Button, Grid, Theme, Typography } from '@material-ui/core'
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant'
import useParticipants from '../../hooks/useParticipants/useParticipants'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant'
import { styled, useMediaQuery } from '@material-ui/core'
import useChatContext from './../../hooks/useChatContext/useChatContext'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import image4 from '../../icons/image_28.png'
import { useLocation } from 'react-router-dom'
import TeleDisableVideoUserIcon from '../../icons/TeleDisableVideoUserIcon'
import useProviderContext from '../../hooks/useProviderContext/useProviderContext'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { StateContext } from '../../state'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: ({ isChatWindowOpen, openAddParticipant }) => {
            let right = openAddParticipant ? 388 : 10
            right = isChatWindowOpen ? right + 378 : right
            return {
                right,
                position: 'absolute',
                top: 10,
                height: 'calc(100vh - 50px)',
                overflowY: 'auto',
                zIndex: 5,
                [theme.breakpoints.down('sm')]: {
                    right: 'unset',
                    left: 20,
                    marginTop: 20,
                    height: 'calc(100vh - 150px)',
                },
            }
        },
        collapse__participants: {
            background: 'rgba(3, 12, 37, 0.75)',
            borderRadius: '18px',
            padding: 16,
            width: 280,
            marginBottom: 28,
            position: 'relative',
        },
        avatarContainer: {
            display: 'flex',
            height: 175,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
            background: '#303E4E',
            boxShadow: '0px 5px 16px rgba(39, 46, 65, 0.25)',
            borderRadius: 16,
            marginBottom: '2em',
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
                '& svg': {
                    transform: 'scale(0.7)',
                },
            },
        },
        text__overlay: {
            position: 'absolute',
            background: 'rgba(3, 12, 37, 0.5)',
            top: 0,
            width: '100%',
            height: '100%',
            left: 0,
            zIndex: 1,
        },

        transparentBackground: {
            background: 'transparent',

            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: '14%',
            },
        },
        textStyling: {
            fontSize: '19px',
            color: 'white',
            margin: 0,
            '&:hover': {
                color: 'white',
            },
            width: 168,
        },
        collapse__buttons: {
            margin: 'auto',
            padding: '0px',
            background: 'rgba(3, 12, 37, 0.75)',
            width: '32px',
            height: '32px',
            borderRadius: '18px',
            display: 'flex',
            alignItems: 'center',
            minWidth: 'fit-content',
            marginBottom: 32,
            '&:hover': {
                background: 'rgba(34,43,55,0.5) !important',
            },
        },
        scrollContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2em',
            // paddingRight: '10%',
            // [theme.breakpoints.down('sm')]: {
            //   paddingRight: '50%',
            // }
        },
        innerScrollContainer: {
            maxHeight: '639px',
            width: `calc(${theme.sidebarWidth}px - 10em)`,
            padding: '0',
            [theme.breakpoints.down('sm')]: {
                width: '120px',
                padding: 0,
                // padding: `${theme.sidebarMobilePadding}px`,
                // display: 'flex',
            },
        },
        avatar__backward: {
            width: '40px',
            height: '40px',
            marginRight: 8,
            background: '#FFFFFF',
            border: '2px solid #00000080',
            borderRadius: '80px',
            overflow: 'hidden',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '& svg': {
                width: '42px',
                height: 'auto',
            },
        },
        avatar__forward: {
            position: 'absolute',
            top: 16,
            left: 48,
            width: '40px',
            height: '40px',
            marginRight: 8,
            background: '#FFFFFF',
            border: '2px solid rgba(255, 255, 255, 0.5)',
            borderRadius: '80px',
            overflow: 'hidden',
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                width: '42px',
                height: 'auto',
            },
        },
    }),
)

export default function ParticipantList({ openAddParticipant }) {
    const { setCurrentNameLocal, currentNameLocal } = useContext(StateContext)

    const location = useLocation()
    const { isChatWindowOpen } = useChatContext()
    const classes = useStyles({ isChatWindowOpen, openAddParticipant })
    const { room } = useVideoContext()
    const localParticipant = room!.localParticipant
    const participants = useParticipants()
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant()
    const screenShareParticipant = useScreenShareParticipant()
    const mainParticipant = useMainParticipant()
    const isRemoteParticipantScreenSharing =
        screenShareParticipant && screenShareParticipant !== localParticipant
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    // console.log(room, 'log room')
    // console.log(participants, 'log participants')

    useEffect(() => {
        setCurrentNameLocal(
            `${localParticipant?.identity?.split('_')[0]}  ${
                localParticipant?.identity?.split('_')[1]
            }`,
        )
    }, [localParticipant])

    // console.log(localParticipant,'log localParticipant')

    const [isCollapseParticipant, setIsCollapseParticipant] = useState(false)
    const getPaddingRight = isMobile ? 0 : 30

    if (participants.length === 0) return null // Don't render this component if there are no remote participants.

    return (
        <aside
            className={clsx(classes.container, {
                [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
            })}>
            <div
                className={classes.scrollContainer}
                style={{
                    paddingRight: `${getPaddingRight}px`,
                }}>
                <div className={classes.innerScrollContainer}>
                    {!isCollapseParticipant ? (
                        <>
                            <Participant participant={localParticipant} isLocalParticipant={true} />

                            <Grid
                                container
                                className={classes.cover_participant}
                                alignItems="center">
                                {participants.map((participant) => {
                                    const isSelected = participant === selectedParticipant
                                    const hideParticipant =
                                        participant === mainParticipant &&
                                        participant !== screenShareParticipant &&
                                        !isSelected

                                    return (
                                        <Participant
                                            key={participant.sid}
                                            participant={participant}
                                            isSelected={participant === selectedParticipant}
                                            onClick={() => setSelectedParticipant(participant)}
                                            hideParticipant={hideParticipant}
                                        />
                                    )
                                })}
                            </Grid>
                        </>
                    ) : isMobile ? (
                        <>
                            <Participant participant={localParticipant} isLocalParticipant={true} />
                            <div className={classes.avatarContainer}>
                                <TeleDisableVideoUserIcon />
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                    className={classes.text__overlay}>
                                    <Typography style={{ color: 'white', fontSize: 16 }}>
                                        +{participants.length - 1}
                                    </Typography>
                                </Grid>
                            </div>
                        </>
                    ) : (
                        <Grid
                            container
                            className={classes.collapse__participants}
                            alignItems="center">
                            <div className={classes.avatar__backward}>
                                {/* <img src={image4} alt="alternative_video" /> */}
                                <AccountCircleIcon />
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                    className={classes.text__overlay}>
                                    <Typography style={{ color: 'white', fontSize: 16 }}>
                                        + {participants.length}
                                    </Typography>
                                </Grid>
                            </div>
                            <div className={classes.avatar__forward}>
                                {/* <img src={image4} alt="alternative_video" /> */}
                                <AccountCircleIcon />
                            </div>
                            <Typography className={classes.textStyling} style={{ marginLeft: 32 }}>
                                {`${localParticipant?.identity?.split('_')[0]}  ${
                                    localParticipant?.identity?.split('_')[1]
                                }`}
                            </Typography>
                        </Grid>
                    )}
                </div>
            </div>
            {participants.length >= 2 && (
                <Button
                    className={classes.collapse__buttons}
                    onClick={() => {
                        setIsCollapseParticipant(!isCollapseParticipant)
                    }}>
                    {isCollapseParticipant ? (
                        <KeyboardArrowDownRoundedIcon style={{ color: 'white' }} />
                    ) : (
                        <KeyboardArrowUpRoundedIcon style={{ color: 'white' }} />
                    )}
                </Button>
            )}
        </aside>
    )
}
