import MomentUtils from '@date-io/moment'
import { Icon, ThemeProvider } from '@material-ui/core'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'
import { materialTheme as theme } from '../../../utilities/calendar/style'
import DatePickerIcon from '../../../assests/icons/datepicker.svg'

function DatePicker(props) {
    return (
        <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}>
            <ThemeProvider theme={theme}>
                <KeyboardDatePicker
                    id={props.name}
                    aria-label={props.name}
                    inputProps={{
                        style: {
                            height: 3,
                        },
                    }}
                    name={props.name}
                    variant="inline"
                    placeholder={'From'}
                    disableToolbar
                    inputVariant="outlined"
                    {...props}
                    autoOk
                    keyboardIcon={
                        <img
                            src={props.keyboardIcon}
                            alt="calendar"
                            width="25px"
                            height="25px"
                        />
                    }
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}
DatePicker.defaultProps = {
    keyboardIcon: DatePickerIcon,
}

export default DatePicker
