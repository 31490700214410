import moment from 'moment'
import { UserRoles } from '../../constants/user-roles'
import * as actionTypes from './actionTypes'
import {
    DATE_FORMAT,
    EVENT_TYPE,
    TIMEOFF_STATUS,
    TIMEZONES,
    TIME_FORMAT,
    VIEW,
} from './constant'
import {
    convertUtcToTz,
    generateSlots,
    getSlotTypeColor,
    getTimeoffTitle,
} from './utils'

const tz = moment.tz.guess()

export const initialState = {
    timezone: {
        selected: TIMEZONES.find(
            (zone) =>
                zone.abbr === moment.tz(tz).format('z'),
        ) || {
            abbr: '',
            value: '',
            key: tz,
        },
        data: TIMEZONES,
    },
    providers: {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
        data: [],
    },
    provider: {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
        data: {
            id: null,
            name: null,
            timezone: null,
            speciality: {},
        },
    },
    view: {
        selected: VIEW.MONTH,
        date: moment(),
        start_date: moment().startOf(VIEW.MONTH),
        end_date: moment().endOf(VIEW.MONTH),
    },
    events: {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
        data: [],
        availability: [],
    },
    appointments: {
        isLoading: false,
        isSuccess: false,
        isFailure: false,
        data: [],
        count: [],
    },
}

export function reducer(state, action) {
    switch (action.type) {
        case actionTypes.CHANGE_TIMEZONE: {
            return {
                ...state,
                timezone: {
                    ...state.timezone,
                    selected: action.tz,
                },
            }
        }

        case actionTypes.CHANGE_CALENDAR_VIEW: {
            return {
                ...state,
                view: {
                    ...state.view,
                    selected: action.view,
                    start_date: moment(
                        state.view.date,
                    ).startOf(action.view),
                    end_date: moment(state.view.date).endOf(
                        action.view,
                    ),
                },
                events: {
                    ...state.events,
                    data: [],
                    availability: [],
                },
            }
        }

        case actionTypes.NAVIGATE_TO_DATE: {
            return {
                ...state,
                view: {
                    ...state.view,
                    selected: action.view,
                    date: moment(action.date),
                    start_date: moment(action.date).startOf(
                        action.view,
                    ),
                    end_date: moment(action.date).endOf(
                        action.view,
                    ),
                },
                events: {
                    ...state.events,
                    data: [],
                    availability: [],
                },
            }
        }

        case actionTypes.PROVIDER_LOADING: {
            return {
                ...state,
                provider: {
                    ...state.provider,
                    isLoading: true,
                },
            }
        }

        case actionTypes.SELECT_PROVIDER_DETAILS: {
            const data = action.data
            return {
                ...state,
                provider: {
                    ...state.provider,
                    data: {
                        ...state.provider.data,
                        id: data.user_id,
                        name: `${data.first_name} ${data.last_name}`,
                        timezone: data.time_zone,
                        speciality: data.speciality,
                    },
                },
                timezone: {
                    ...state.timezone,
                    selected: TIMEZONES.find(
                        (zone) =>
                            zone.abbr ===
                            moment
                                .tz(data.time_zone)
                                .format('z'),
                    ) || {
                        abbr: '',
                        value: '',
                        key: tz,
                    },
                },
            }
        }

        case actionTypes.SEARCH_PROVIDERS_LOADING: {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    isLoading: true,
                },
            }
        }

        case actionTypes.SEARCH_PROVIDERS_SUCCESS: {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    isLoading: false,
                    isSuccess: true,
                    data: action.data.provider.map((p) => ({
                        ...p,
                        title: `${p.first_name} ${p.last_name}`,
                        subtitle: p.speciality?.name,
                    })),
                },
            }
        }

        case actionTypes.SEARCH_PROVIDERS_FAILURE: {
            return {
                ...state,
                providers: {
                    ...state.providers,
                    isLoading: false,
                    isFailure: true,
                },
            }
        }

        case actionTypes.PROVIDER_SUCCESS: {
            const data = action.data
            return {
                ...state,
                provider: {
                    ...state.provider,
                    isLoading: false,
                    isSuccess: true,
                    data: {
                        ...state.provider.data,
                        id: data.user_id,
                        name: `${data.first_name} ${data.last_name}`,
                        timezone: data.time_zone,
                        speciality: data.speciality,
                    },
                },
                timezone: {
                    ...state.timezone,
                    selected: TIMEZONES.find(
                        (zone) =>
                            zone.abbr ===
                            moment
                                .tz(data.time_zone)
                                .format('z'),
                    ) || {
                        abbr: '',
                        value: '',
                        key: tz,
                    },
                },
            }
        }

        case actionTypes.PROVIDER_FAILURE: {
            return {
                ...state,
                provider: {
                    ...state.provider,
                    isLoading: false,
                    isFailure: true,
                },
            }
        }

        case actionTypes.SLOTS_LOADING: {
            return {
                ...state,
                events: {
                    ...state.events,
                    isLoading: true,
                    data: [],
                    availability: [],
                },
            }
        }

        case actionTypes.SLOTS_SUCCESS: {
            const timeoff_events =
                action.timeoffs
                    ?.filter(
                        (timeoff) =>
                            timeoff.status !==
                            TIMEOFF_STATUS.REJECTED,
                    )
                    ?.map((range) =>
                        formTimeoffEvents({
                            range,
                            view: state.view,
                        }),
                    )
                    ?.flat() ?? []

            const busy_slot_events = action.busy_slots
                ? action.busy_slots?.map((appt, index) =>
                      formBusySlotDetails({
                          ...appt,
                          index,
                          provider: state.provider.data,
                      }),
                  ) ?? []
                : []

            const free_slot_events = action.free_slots
                ? action.free_slots?.map((appt, index) =>
                      formFreeSlotDetails({
                          ...appt,
                          index,
                          provider: state.provider.data,
                      }),
                  ) ?? []
                : []

            const availability_events =
                action.availability
                    ?.map((range) =>
                        formAvailabilityEvents({
                            range,
                            view: state.view,
                            tz: state.timezone.selected.key,
                        }),
                    )
                    ?.flat() ?? []

            const data =
                action.role === UserRoles.PROVIDER
                    ? state.view.selected === VIEW.MONTH
                        ? [
                              ...busy_slot_events,
                              ...timeoff_events,
                          ]
                        : [
                              ...busy_slot_events,
                              ...free_slot_events,
                          ]
                    : state.view.selected === VIEW.MONTH
                    ? [
                          ...availability_events,
                          ...timeoff_events,
                      ]
                    : [
                          ...busy_slot_events,
                          ...free_slot_events,
                      ]

            return {
                ...state,
                events: {
                    ...state.events,
                    isLoading: false,
                    isSuccess: true,
                    data,
                    availability: [
                        ...availability_events,
                        ...timeoff_events,
                    ],
                },
            }
        }

        case actionTypes.SLOTS_FAILURE: {
            return {
                ...state,
                events: {
                    ...state.events,
                    isLoading: false,
                    isFailure: true,
                    data: [],
                    availability: [],
                },
            }
        }

        case actionTypes.APPOINTMENTS_LOADING: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: true,
                },
            }
        }

        case actionTypes.APPOINTMENTS_SUCCESS: {
            const appointments = action.data.map(
                (appt, index) =>
                    formBusySlotDetails({
                        ...appt,
                        index,
                        provider: state.provider.data,
                    }),
            )

            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: false,
                    isSuccess: true,
                    data: appointments,
                },
            }
        }

        case actionTypes.APPOINTMENTS_FAILURE: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: false,
                    isFailure: true,
                },
            }
        }

        case actionTypes.APPOINTMENTS_COUNT_SUCCESS: {
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    count: action.data,
                },
            }
        }

        default:
            return state
    }
}

function formBusySlotDetails(appt) {
    const start_date = convertUtcToTz(
        `${appt.slot.date} ${appt.slot.start}`,
    )
    const end_date = convertUtcToTz(
        `${appt.slot.date} ${appt.slot.end}`,
    )

    return {
        id: appt.index + 1,
        type: EVENT_TYPE.BUSY_SLOT,
        title: `${start_date.format(
            'hh:mm A',
        )} - ${end_date.format('hh:mm A')}`,
        start: start_date.toDate(),
        end: end_date.toDate(),
        color: getSlotTypeColor(appt.appointment_type),
        slot: {
            start_date,
            end_date,
            type: appt.slot?.slot_type,
            status: appt.slot?.status,
        },
        appointment: {
            id: appt.appointment_id,
            type: appt.appointment_type,
            clinic: {
                id: appt.clinic?.id,
                name: appt.clinic?.clinic_name,
                type: appt.clinic?.clinic_type,
            },
            patient: {
                id: appt.patient?.id,
                name: `${appt.patient?.first_name} ${appt.patient?.last_name}`,
            },
            provider: appt.provider,
        },
    }
}

function formFreeSlotDetails(slot) {
    const start_date = convertUtcToTz(
        `${slot.slot.date} ${slot.slot.start}`,
    )

    const end_date = convertUtcToTz(
        `${
            slot.slot.end === '00:00:00'
                ? moment
                      .utc(slot.slot.date)
                      .add(1, VIEW.DAY)
                      .format(DATE_FORMAT)
                : slot.slot.date
        } ${slot.slot.end}`,
    )

    return {
        id: slot.index + 1,
        type: EVENT_TYPE.FREE_SLOT,
        title: `${start_date.format('hh:mm A')}`,
        start: start_date.toDate(),
        end: end_date.toDate(),
        color: getSlotTypeColor(slot.slot.slot_type),
        slot: {
            start_date,
            end_date,
            type: slot.slot.slot_type,
            status: slot.slot.status,
        },
    }
}

function formTimeoffEvents({ range, view }) {
    let output = []
    // NOTE: these variable represents entire timeoff range
    const range_start = convertUtcToTz(
        `${range.start_date} ${range.start}`,
    )
    const range_end = convertUtcToTz(
        `${range.end_date} ${range.end}`,
    )

    const dates = generateSlots({
        ...range,
        repeat_type: range.repeat_type || VIEW.DAY,
        start_date: range_start,
        end_date: range_end,
    })

    dates.forEach((date) => {
        const day_start_date = date.format(DATE_FORMAT)
        const day_end_date = date.format(DATE_FORMAT)

        const start_time = range_start.format(TIME_FORMAT)
        const end_time = range_end.format(TIME_FORMAT)

        if (
            moment(end_time, TIME_FORMAT).isBefore(
                moment(start_time, TIME_FORMAT),
            )
        ) {
            const start_one = moment(
                `${day_start_date} ${start_time}`,
            )
            const end_one = moment(day_end_date).endOf(
                VIEW.DAY,
            )
            const start_two = moment(
                `${day_start_date} ${start_time}`,
            )
                .add(1, VIEW.DAY)
                .set('hour', '0')
                .set('minute', '0')
            const end_two = moment(
                `${day_end_date} ${end_time}`,
            ).add(1, VIEW.DAY)

            output = [
                ...output,
                {
                    id: 0,
                    type: EVENT_TYPE.TIMEOFF,
                    title: getTimeoffTitle(range.status),
                    start: start_one.toDate(),
                    end: end_one.toDate(),
                    allDay: view.selected === VIEW.MONTH,
                    status: range.status,
                    color: '#eeeeee',
                    span: {
                        start: range_start,
                        end: range_end,
                    },
                },
            ]

            output = [
                ...output,
                {
                    id: 1,
                    type: EVENT_TYPE.TIMEOFF,
                    title: getTimeoffTitle(range.status),
                    start: start_two.toDate(),
                    end: end_two.toDate(),
                    allDay: view.selected === VIEW.MONTH,
                    status: range.status,
                    color: '#eeeeee',
                    span: {
                        start: range_start,
                        end: range_end,
                    },
                },
            ]
        } else {
            const timeoff_start = moment(
                `${day_start_date} ${start_time}`,
            )

            const timeoff_end = moment(
                `${day_end_date} ${end_time}`,
            )

            output = [
                ...output,
                {
                    id: 0,
                    type: EVENT_TYPE.TIMEOFF,
                    title: getTimeoffTitle(range.status),
                    start: timeoff_start.toDate(),
                    end: timeoff_end.toDate(),
                    allDay: view.selected === VIEW.MONTH,
                    status: range.status,
                    color: '#eeeeee',
                    span: {
                        start: range_start,
                        end: range_end,
                    },
                },
            ]
        }
    })

    return output
}

function formAvailabilityEvents({ range, tz }) {
    let output = []

    const range_start = moment.utc(
        `${range.start_date} ${range.start}`,
    )
    const range_end = moment.utc(
        `${range.end_date} ${range.end}`,
    )

    const dates = generateSlots({
        ...range,
        repeat_type: range.repeat_type || VIEW.DAY,
        start_date: range_start,
        end_date: range_end,
    })

    dates.forEach((date) => {
        const start_date = date.format(DATE_FORMAT)
        const end_date = date.format(DATE_FORMAT)

        const start_time = range_start.format(TIME_FORMAT)
        const end_time = range_end.format(TIME_FORMAT)

        const availability_start = moment(
            moment
                .utc(`${start_date} ${start_time}`)
                .format(),
        ).tz(tz)

        const availability_end = moment(
            moment
                .utc(end_time, TIME_FORMAT)
                .isBefore(
                    moment.utc(start_time, TIME_FORMAT),
                )
                ? moment
                      .utc(`${end_date} ${end_time}`)
                      .add(1, 'day')
                      .format()
                : moment
                      .utc(`${end_date} ${end_time}`)
                      .format(),
        ).tz(tz)

        if (
            availability_end.isAfter(
                availability_start.clone().endOf('day'),
            )
        ) {
            const start_one = availability_start
            const end_one = availability_start
                .clone()
                .endOf(VIEW.DAY)

            const start_two = end_one
                .clone()
                .add(1, VIEW.DAY)
                .set('hour', '0')
                .set('minute', '0')

            const end_two = availability_end

            output = [
                ...output,
                {
                    id: 0,
                    type: EVENT_TYPE.AVAILABILITY,
                    title: `${start_one.format(
                        'hh:mm A',
                    )} - ${end_one.format('hh:mm A')}`,
                    start: start_one.toDate(),
                    end: end_one.toDate(),
                    color: getSlotTypeColor(
                        range.slot_type,
                    ),
                    slot_type: range.slot_type,
                },
            ]

            output = [
                ...output,
                {
                    id: 0,
                    type: EVENT_TYPE.AVAILABILITY,
                    title: `${start_two.format(
                        'hh:mm A',
                    )} - ${end_two.format('hh:mm A')}`,
                    start: start_two.toDate(),
                    end: end_two.toDate(),
                    color: getSlotTypeColor(
                        range.slot_type,
                    ),
                    slot_type: range.slot_type,
                },
            ]
        } else {
            output = [
                ...output,
                {
                    id: 0,
                    type: EVENT_TYPE.AVAILABILITY,
                    title: `${availability_start.format(
                        'hh:mm A',
                    )} - ${availability_end.format(
                        'hh:mm A',
                    )}`,
                    start: availability_start.toDate(),
                    end: availability_end.toDate(),
                    color: getSlotTypeColor(
                        range.slot_type,
                    ),
                    slot_type: range.slot_type,
                },
            ]
        }
    })

    return output
}
