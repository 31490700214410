import React from 'react'
import './DashboardHome.scss'
import TeleMed_Logo from '../../assests/images/dashboard/TM2U Logo - 500px Width 2.png'
import HumanWork from '../../assests/images/landing page with no_background 1.png'
import { Card, Divider, Grid } from '@material-ui/core'
import calenderAppoint from '../../assests/images/dashboard/calendar 1.png'
import pay_Bill from '../../assests/images/dashboard/bills 1.png'
import message from '../../assests/images/dashboard/communication 1.png'
import Refill from '../../assests/images/dashboard/medicines 1.png'
import MemberProfile from './MemberProfile'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const DashboardHome = () => {
    const history = useHistory()
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const goToPage = (page) => history.push(page)

    return (
        <>
            <div className="dashboard_Home">
                <Grid container item spacing={1} xs={12} className="dashboard_Home_Struct">
                    <Grid item xs={4} className="leftSide_Dashboard">
                        <div className="leftSide_Text">
                            <div>
                                {' '}
                                <img
                                    src={TeleMed_Logo}
                                    alt="teleMed-Logo"
                                    className="teleMed_Logo"
                                />
                            </div>
                            <div>
                                <span className="leftTop">
                                    What would you <br />
                                    like to do?{' '}
                                </span>
                            </div>
                            <div>
                                <span className="leftMiddle">
                                    TM2U is here to provide you with quality care.{' '}
                                </span>
                            </div>
                            <div>
                                {' '}
                                <span className="assist_text">
                                    If you need assistance call{' '}
                                    <span style={{ color: '#5571C6', fontWeight: '500' }}>
                                        {loggedinPatiendata?.practice?.patient_support_number ||
                                            '(562) 268-0955'}
                                        {/* (562) 268-0955 */}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="leftBottom">
                            <img
                                src={HumanWork}
                                alt="human"
                                style={{
                                    width: '500px',
                                    height: '450px',
                                    // marginBlockStart: '-74px',
                                    backgroundRepeat: 'no-repeat',
                                    // marginLeft: '-42px',
                                }}
                            />
                            <span style={{ alignSelf: 'center', fontSize: 15, color: '#FAFCFE' }}>
                                Image by &nbsp;
                                <a
                                    target="_blank"
                                    style={{ cursor: 'pointer', color: '#FAFCFE' }}
                                    href="https://www.freepik.com/free-vector/hand-drawn-essay-illustration_40478850.htm#&position=0&from_view=search&track=ais&uuid=324d6669-f7f3-4d87-8323-2f6a1e68c67e"
                                    rel="noopener noreferrer">
                                    Freepik
                                </a>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={8} className="rightSide_Dashboard">
                        <div className="notification">
                            <Card className="notificationCard">
                                <span className="notificationText">Notifications</span>
                            </Card>
                        </div>
                        <Grid container item xs={12} className="appointment_Bill_Card">
                            <Grid item lg={6} xs={12}>
                                <Card
                                    className="appointment_Card go_to_page w-md-100 fullwidth_card"
                                    onClick={() => goToPage('/patient-book-appointment')}>
                                    <div className="calender_Logo">
                                        <img src={calenderAppoint} />
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textHead">Book an appointment</span>
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textBody">
                                            Schedule an appointment to connect virtually with a
                                            clinician.
                                        </span>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Card className="bill_Card w-md-100 fullwidth_card">
                                    <div className="bill_Logo">
                                        <img src={pay_Bill} />
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textHead">Pay your bill</span>
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textBody">
                                            Manage your account or set up a payment plan.{' '}
                                        </span>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} className="msg_refill_Card">
                            <Grid item lg={6} xs={12}>
                                <Card className="msg_Card w-md-100 fullwidth_card">
                                    <div className="msg_Logo">
                                        <img src={message} />
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textHead">
                                            Message your care team
                                        </span>
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textBody">
                                            Contact us to interact with your care team.
                                        </span>
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Card className="refill_Card w-md-100 fullwidth_card">
                                    <div className="refill_Logo">
                                        <img src={Refill} />
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textHead">
                                            Refill your prescriptions
                                        </span>
                                    </div>
                                    <div style={{ marginTop: '8px' }}>
                                        <span className="card_textBody">
                                            Let us know what prescriptions you need refilled. .{' '}
                                        </span>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider style={{ margin: '20px 0px', color: '#D9D9D9', padding: '0.3px 0px' }} />
                {/* <hr style={{color: "#D9D9D9"}} /> */}
                <MemberProfile />
            </div>
        </>
    )
}

export default DashboardHome
