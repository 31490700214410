import {
    Grid,
    InputAdornment,
    Popper,
    TextField,
    Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete } from '@material-ui/lab'
import { capitalizeFirstLetter } from '../../utils'
import { useStyles } from './styles'

const CustomPopper = function (props) {
    return <Popper {...props} placement="bottom" />
}

function AutoCompleteBox(props, disabled) {
    const classes = useStyles(props)

    return (
        <Autocomplete
            options={props.options}
            getOptionLabel={(option) =>
                capitalizeFirstLetter(option.title)
            }
            value={props.value}
            onChange={props.onChange}
            disableClearable
            fullWidth
            PopperComponent={CustomPopper}
            ListboxProps={{
                style: { maxHeight: '25rem' },
                position: 'bottom-start',
            }}
            classes={{
                popupIndicator: classes.popupIndicator,
                option: classes.option,
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder}
                    disabled={disabled}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: props.freeSolo && (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        classes: {
                            root: classes.root,
                            focused: classes.cssFocused,
                            notchedOutline:
                                classes.notchedOutline,
                        },
                    }}
                />
            )}
            renderOption={(option) => (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item>
                        <Typography
                            noWrap
                            variant="body1"
                            className="capitalize">
                            <b>{option.title}</b>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            noWrap
                            variant="body2"
                            className="capitalize">
                            {option.subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {...props}
        />
    )
}

AutoCompleteBox.defaultProps = {
    freeSolo: true,
    options: [],
    height: 40,
    border: '1px solid $secondaryGray',
}

export default AutoCompleteBox
