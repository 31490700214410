import { getService } from '../../utilities/request/restPatientPortalClient'
import { config } from '../../constants'

export const getHealthConditions = ({ page, limit, type }) => {
    return getService(`/patient_portal/condition?page=${page}&size=${limit}&status=${type}`)
}

export const getLabResults = ({ page, limit }) => {
    return getService(`patient_portal/lab-order?page=${page}&limit=${limit}&sort=oldest`)
}

export const getMedications = ({ page, limit, type }) => {
    return getService(`/patient_portal/medications?page=${page}&size=${limit}&type=${type}`)
}

export const getMedicationAllergies = ({ page, limit, type }) => {
    return getService(`/patient_portal/allergy?page=${page}&size=${limit}&status=${type}`)
}

export const getInsuranceApi = ({ page, limit, bundleId, patient_id }) => {
    const bundle = page > 1 ? `&bundle_id=${bundleId}` : ''
    return getService(
        `patient_portal/${patient_id}/insurance-policy?page=${page}&size=${limit}${bundle}`,
    )
}
