import { createTheme } from '@material-ui/core/styles'
import color from '../themes/color.module.scss'
import sizing from '../themes/sizing.scss'
import palette from '../themes/palette'
export default createTheme({
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        fontFamily: 'Rubik',
        h1: {
            //Fetch from palette -> color: palette.primary.main,
            //Fetch from color.scss -> color: color.primaryBlue,
            color: color.primaryDarkBlue,
            fontFamily: 'Rubik',
            fontWeight: 700,
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            letterSpacing: '0.01em',
            textTransform: 'capitalize',
            display: 'flex',
            alignItems: 'center',
        },
        h2: {
            color: color.primaryDarkBlue,
            fontFamily: 'Rubik',
            fontWeight: 700,
            fontSize: '1.5rem',
            lineHeight: '2.25rem',
            letterSpacing: '0.01em',
            textTransform: 'capitalize',
            display: 'flex',
            alignItems: 'center',
        },
        h3: {
            color: color.primaryDarkBlue,
            fontFamily: 'Rubik',
            fontWeight: 500,
            fontSize: '1.375rem',
            lineHeight: '2.125rem',
            letterSpacing: '0.01em',
            textTransform: 'capitalize',
        },
        h4: {
            color: color.textGray,
            fontFamily: 'Rubik',
            fontWeight: 500,
            fontSize: 19,
            lineHeight: '1.75rem',
            letterSpacing: '0.01em',
            textTransform: 'capitalize',
        },
        h5: {
            color: color.primaryDarkBlue,
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '1.125rem',
            lineHeight: '3.375rem',
            letterSpacing: '0.01em',
            textTransform: 'capitalize',
        },
        h6: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '1.375rem',
            letterSpacing: '0.01em',
            textTransform: 'capitalize',
        },
        body1: {
            color: '#667689',
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            letterSpacing: '0.01em',
            textTransform: 'inherit',
        },
        body2: {
            color: color.primaryDarkGray,
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.375rem',
            letterSpacing: '0.02em',
            textTransform: 'capitalize',
        },
        subtitle1: {
            color: color.primaryGray,
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '1.125rem',
            lineHeight: '1.625rem',
            letterSpacing: '0.02em',
            textTransform: 'capitalize',
        },
        subtitle2: {
            color: color.primaryDarkGray,
            fontFamily: 'Rubik',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.02em',
            textTransform: 'capitalize',
            marginBottom: '10px',
        },
        caption: {
            color: color.primaryDarkGray,
            fontFamily: 'Rubik',
            fontWeight: 700,
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            letterSpacing: '0.02em',
            textTransform: 'capitalize',
        },
        button: {
            color: color.primaryWhite,
            background: color.primaryBlue,
            fontFamily: 'Rubik',
            fontSize: '1rem',
            lineHeight: '1.375rem',
            letterSpacing: '0.01em',
            padding: '15.3px 16px',
            borderRadius: '6px',
            height: '40px',
        },
        overline: {},
    },
    shadows: [
        // MUI requires all 25 shadow values be reset, when overriding any one value, starts at 0
        'none',
        `${sizing.none} ${sizing.xs} ${sizing.xs} ${sizing.none} rgba(0, 0, 0, 0.16)`, // [ 1 ] - Bottom, Right
        '7px 9px 20px 0 rgba(0, 0, 0, 0.16)', // [ 2 ] - ?
        '2px 4px 9px 1px rgba(165, 165, 165, 0.05)',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        appBar: 0,
        drawer: 1200,
        snackbar: 1400,
        tooltip: 1500,
    },
    shape: {
        borderRadius: sizing.none,
    },
    props: {
        MuiButton: {
            disableRipple: true,
            disableElevation: true,
        },
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true,
            '&:hover': {
                backgroundColor: color.transparent,
            },
        },
        MuiCheckbox: {
            // color: 'secondary',
            disableRipple: true,
        },
        MuiRadio: {
            // color: 'secondary',
            disableRipple: true,
        },
        MuiTab: {
            disableFocusRipple: true,
            disableRipple: true,
        },
        MuiLink: {
            underline: 'none',
            fontSize: 'inherit',
        },
        MuiTypography: {
            fontFamily: 'Rubik',
            root: {
                fontFamily: 'Rubik',
            },
        },
        MuiWithWidth: {
            initialWidth: 'lg',
        },
    },
    overrides: {
        /**
         * Override styles should be added only if they are global, meaning they are
         * consistent across all implementations. We should keep this section as small
         * as possible so that we have a minimal amount of custom overrides in a component's
         * specific implementation. This will take time to develop and refine as we begin
         * utilizing the Material-UI Components in our codebase.
         */
        MuiAlert: {
            root: {
                padding: `${sizing.xs} ${sizing.xl} ${sizing.xs} ${sizing.sm}`,
                borderRadius: sizing.none,
            },
            icon: {
                fontSize: sizing.xl,
                marginRight: sizing.nm,
            },
            message: {
                width: '100%',
            },
            standardError: {
                // borderLeft: `${sizing.sm} solid ${color.danger}`,
            },
            standardInfo: {
                // borderLeft: `${sizing.sm} solid ${color.info}`,
            },
            standardSuccess: {
                // borderLeft: `${sizing.sm} solid ${color.success}`,
            },
            standardWarning: {
                // borderLeft: `${sizing.sm} solid ${color.warning}`,
            },
        },
        MuiAutocomplete: {
            root: {
                '&[aria-expanded="true"] .MuiAutocomplete-inputRoot':
                    {
                        borderRadius: sizing.none,
                    },
            },
            input: {
                color: color.primaryDarkGray,
            },
            endAdornment: {
                // fontSize: fontSize.sm,
                // top: 'auto',
            },
            listbox: {
                // border: `1px solid ${color.grey}`,
                borderRadius: sizing.none,
                margin: 0,
                padding: 0,
                maxHeight: 'inherit',
            },
            option: {
                height: '40px',
                '&:hover': {
                    // backgroundColor: color.silver,
                },
            },
            popper: {
                margin: sizing.none,
                borderRadius: sizing.none,
                marginLeft: '1px',
                marginTop: '-5px',
            },
        },
        MuiAvatar: {
            root: {
                height: '100%',
                width: '100%',
            },
        },
        MuiBadge: {
            badge: {
                height: 'auto',
                borderRadius: sizing.none,
                padding: sizing.none,
            },
        },
        MuiButton: {
            root: {
                color: color.primaryWhite,
                backgroundColor: color.primaryBlue,
                fontSize: '16px',
                lineHeight: '22px',
                borderRadius: '4px',
                textTransform: 'capitalize',
                padding: '14px 12px',
            },
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: color.secondaryBlue,
            },
            contained: {
                color: color.primaryWhite,
                backgroundColor: color.primaryBlue,
                fontSize: '16px',
                lineHeight: '22px',
                borderRadius: '4px',
                textTransform: 'capitalize',
                padding: '14px 12px',
                boxShadow: 'none',
                height: '40px',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: color.secondaryBlue,
                },
            },
            outlined: {},
            outlinedPrimary: {
                border: '2px solid #027AC5',
                '&:hover': {
                    border: '2px solid rgb(1, 85, 137)',
                },
            },
            text: {
                padding: '6px 14px',
            },
            sizeSmall: {},
            outlinedSizeSmall: {
                padding: `7px ${sizing.lg}`,
            },
            containedSizeSmall: {
                padding: `${sizing.xs} ${sizing.lg}`,
            },
            textSizeSmall: {
                padding: `${sizing.xs} ${sizing.xs}`,
            },
            sizeLarge: {},
            outlinedSizeLarge: {
                padding: '15px 48px',
            },
            containedSizeLarge: {
                padding: `${sizing.nm} 50px`,
            },
            textSizeLarge: {
                padding: `${sizing.nm} ${sizing.lg}`,
            },
            startIcon: {
                marginRight: '6px',
            },
        },
        MuiCard: {
            root: {
                // height: '100%',
                border: 'solid 1px #e5e5e5',
                borderRadius: sizing.none,
            },
        },
        MuiFormLabel: {
            root: {
                color: '#516983',
                padding: '0',
                fontSize: '1rem',
                fontFamily: 'Rubik',
                fontWeight: '400',
                lineHeight: '1.5rem',
                letterSpacing: '0.02em',
                textTransform: 'capitalize',
            },
        },
        MuiCardMedia: {
            root: {
                height: '100%',
            },
        },
        MuiCardActions: {
            root: {
                padding: sizing.none,
            },
        },
        MuiCardContent: {
            root: {
                padding: '10px',
                '&:last-child': {
                    paddingBottom: sizing.sm,
                },
            },
        },
        MuiCardHeader: {
            root: {
                height: '100%',
                padding: '0 12px',
            },
            action: {
                alignSelf: 'unset',
            },
            title: {
                fontSize: '20px',
                lineHeight: '28px',
            },
        },
        MuiCheckbox: {
            root: {
                // color: color.secondary,
                // MUI Bug: for hover on checked and color opts
                // https://github.com/mui-org/material-ui/issues/16866
                '&:hover': {
                    // backgroundColor: color.transparent,
                },
                '&.MuiCheckbox-colorSecondary.Mui-checked:hover':
                    {
                        // backgroundColor: color.transparent,
                    },
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
        },
        MuiChip: {
            root: {
                borderRadius: sizing.xs,
                // backgroundColor: color.lightBlue,
                margin: sizing.none,
                paddingLeft: sizing.xs,
                paddingRight: sizing.xs,
                paddingTop: sizing.xs,
                paddingBottom: sizing.xs,
                // color: color.primary,
                // fontWeight: fontWeight.regular,
                // fontSize: fontSize.caption,
                border: 'none',
                height: sizing.auto,
            },
            label: {
                paddingLeft: sizing.none,
                paddingRight: sizing.none,
                lineHeight: sizing.sm,
            },
        },
        MuiDialog: {
            paperWidthXs: {
                maxWidth: '464px',
            },
            paperWidthSm: {
                maxWidth: '520px',
            },
            paperWidthMd: {
                maxWidth: '704px',
            },
            paperWidthLg: {
                maxWidth: '808px',
            },
            paperWidthXl: {
                maxWidth: '840px',
            },
        },
        MuiDivider: {
            root: {
                // backgroundColor: color.black,
                // color: color.black,
                marginTop: sizing.none,
                marginBottom: sizing.none,
            },
            light: {
                // backgroundColor: color.silver,
            },
            middle: {
                marginLeft: sizing.xs,
                marginRight: sizing.xs,
            },
        },
        MuiDrawer: {
            root: {
                width: '240px',
                flexShrink: sizing.none,
            },
            paper: {
                width: '240px',
            },
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: sizing.none,
                minHeight: 'auto',
            },
        },
        MuiExpansionPanelSummary: {
            root: {
                minHeight: 'auto',
                padding: sizing.none,
                '&$expanded': {
                    margin: sizing.none,
                    minHeight: 'auto',
                },
            },
            content: {
                margin: sizing.none,
                '&$expanded': {
                    margin: sizing.none,
                    minHeight: 'auto',
                },
            },
        },
        MuiFormControl: {
            root: {
                padding: '0',
            },
        },
        MuiFormControlLabel: {
            labelPlacementStart: {
                justifyContent: 'space-between',
                marginLeft: sizing.none,
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '11px',
                lineHeight: 1.5,
                letterSpacing: '0.4px',
                fontWeight: 500,
            },
            contained: {
                marginLeft: sizing.none,
                marginRight: sizing.none,
                marginTop: sizing.xs,
            },
        },
        MuiGrid: {
            container: {},
        },
        MuiIcon: {
            fontSizeSmall: {
                // fontSize: sizing.nm,
                // color: color.primary,
            },
        },
        MuiIconButton: {
            root: {
                fontSize: 'inherit',
                padding: '0',
                color: color.primaryBlue,
                '&:hover': {
                    backgroundColor: color.transparent,
                },
            },
            edgeEnd: {},
        },
        MuiLinearProgress: {
            colorPrimary: {
                // backgroundColor: `${color.primary} !important`,
            },
        },
        MuiCssBaseline: {
            '@global': {
                'html, body, #root': {
                    height: '100%',
                },
            },
        },
        MuiDialogActions: {
            root: {
                padding: '16px',
            },
        },
        MuiTextField: {
            root: {
                color: 'rgb(40, 42, 43)',
                width: '100%',
            },
        },
        MuiInputLabel: {
            root: {
                // color: 'rgb(40, 42, 43)',
                fontSize: '1.1rem',
                marginBottom: '0.2em',
                fontWeight: 500,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '4px',
                // border: `1px solid ${color.secondaryGray}`,
                color: '#667689',
                backgroundColor: color.primaryWhite,
                padding: '12px 12px !important',
                height: '40px',
            },
            notchedOutline: {
                // borderWidth: '0px !important',
                border: `1px solid ${color.secondaryGray}`,
            },
            // '&-input': {
            //     padding: '12px',
            // },
            input: {
                padding: '0 !important',
                // border: `1px solid ${color.secondaryGray}`,
                // color:color.primaryDarkGray,
                // backgroundColor:color.primaryWhite,
            },
            adornedEnd: {
                paddingRight: 0,
            },
            inputAdornedStart: {
                paddingLeft: '0 !important',
                color: color.labelColor,
            },
            multiline: {
                padding: 0,
            },
        },
        MuiLink: {
            root: {
                fontSize: '14px',
                lineHeight: '22px',
                fontFamily: 'Rubik',
                fontWeight: 700,
                '&:hover': {},
                '&:active': {
                    borderBottom: '1px solid',
                    paddingBottom: '1px',
                },
                '&.active': {
                    borderBottom: '1px solid',
                    paddingBottom: '1px',
                },
                '&.active-primary': {
                    // borderBottom: `1px solid ${color.primary}`,
                    paddingBottom: '1px',
                },
            },
            button: {
                fontFamily: 'Rubik',
            },
        },
        MuiDataGrid: {
            root: {
                border: `1px solid ${color.tertiaryGray}`,
                borderTop: `1px solid ${color.tertiaryGray} !important`,
                '& .MuiDataGrid-renderingZone': {
                    maxHeight: 'none !important',
                },
                '& .MuiDataGrid-cell': {
                    color: color.primaryGray,
                    fontSize: 14,
                    lineHeight: '20px !important',
                    maxHeight: 'none !important',
                    whiteSpace: 'normal',
                    fontWeight: 'normal',
                },
                '& .MuiDataGrid-row': {
                    maxHeight: 'none !important',
                },
                '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                },
                '& .MuiDataGrid-menuIcon': {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                    lineHeight: '60px',
                    minHeight: '60px',
                    maxHeight: '60px',
                    background: 'rgba(243,246,253,0.75)',
                },
                '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell':
                    {
                        borderRight: `1px solid ${color.tertiaryGray}`,
                        '&:last-child': {
                            borderRight: 0,
                        },
                    },
                '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell':
                    {
                        borderBottom: `1px solid ${color.tertiaryGray}`,
                        alignItems: 'center',
                        display: 'flex',
                    },
                '& .MuiDataGrid-columnHeaderTitleContainer':
                    {
                        padding: 0,
                    },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 700,
                },
                '& .MuiDataGrid-windowContainer': {
                    // borderBottom: `1px solid ${color.tertiaryGray}`,
                },
                '&.MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus-within':
                    {
                        outline: 'none',
                    },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
            },
        },
        MuiTablePagination: {
            root: {
                color: color.primaryDarkGray,
            },
            caption: {
                color: color.primaryGray,
                fontSize: '14px',
                lineHeight: '22px',
                textTransform: 'lowercase',
            },
            actions: {
                marginLeft: '10px',
                '& .MuiButtonBase-root': {
                    padding: '0',
                },
            },
        },
        MuiListItem: {
            root: {
                width: 'auto',
                paddingTop: sizing.sm,
                paddingBottom: sizing.sm,
            },
            dense: {
                paddingTop: sizing.xs,
                paddingBottom: sizing.xs,
            },
            divider: {
                // borderBottom: `solid ${color.silver} 1px`,
            },
        },
        MuiMenu: {
            paper: {
                boxShadow:
                    '0px 0px 2px 0px rgb(85 113 198 / 50%)',
            },
        },
        MuiMenuItem: {
            root: {
                paddingLeft: sizing.nm,
                paddingRight: sizing.nm,
                paddingTop: '6px',
                paddingBottom: '6px',
                lineHeight: '24px',
                color: color.labelColor,
            },
        },
        MuiPagination: {
            ul: {
                '& > li:first-of-type .MuiPaginationItem-page:after':
                    {
                        content: "'Prev'",
                        paddingLeft: sizing.xs,
                    },
                '& > li:last-of-type .MuiPaginationItem-page:before':
                    {
                        content: "'Next'",
                        paddingRight: sizing.xs,
                    },
            },
        },
        MuiPaginationItem: {
            outlined: {
                '&$selected': {
                    // backgroundColor: color.white,
                    // borderColor: color.primary,
                },
            },
            page: {
                '&$selected': {
                    // backgroundColor: color.white,
                    // borderColor: color.primary,
                },
            },
        },
        MuiRadio: {
            root: {
                // color: color.secondary,
                '&:hover': {
                    // backgroundColor: color.transparent,
                },
                // MUI Bug: for hover on checked and color opts
                '&.MuiRadio-colorSecondary.Mui-checked:hover':
                    {
                        // backgroundColor: color.transparent,
                    },
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                },
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '14px',
                minHeight: '40px',
                color: color.labelColor,
                '&:focus': {
                    boxShadow:
                        '0px 0px 2px 0px rgb(85 113 198 / 50%)',
                },
                '&:focus-within': {
                    boxShadow:
                        '0px 0px 2px 0px rgb(85 113 198 / 50%)',
                },
                outlined: {
                    padding: '12px 12px',
                },
                input: {
                    border: '0' /** tan */,
                    padding: 0,
                    height: '40px',
                },
                // color: #777 !important;
                // color: rgba(0, 0, 0, 0.87);
                // Targets select component under error in TextField component
                // '&$error > select': {
                //     // border: `solid 1px ${color.danger}`,
                //     '&:hover': {
                //         // borderColor: color.danger,
                //     },
                //     '&:focus': {
                //         // borderColor: color.danger,
                //     },
                // }
                // ,
                MuiInputAdornment: {
                    positionEnd: {
                        marginLeft: 0,
                    },
                    positionStart: {
                        marginRight: 0,
                    },
                },
            },
        },
        MuiSelect: {
            root: {
                padding: '0.95em',
                borderRadius: '4px',
                '&$disabled': {
                    '&:hover': {
                        // backgroundColor: color.light,
                        // borderColor: color.light,
                    },
                },
                '&:focus': {
                    // borderColor: color.cyan,
                    '&:hover': {
                        // borderColor: color.cyan,
                    },
                },
            },
            select: {
                // backgroundColor: color.light,
                // border: `1px solid ${color.light}`,
                padding: '11px',
                borderRadius: '4px',
                '&:hover': {
                    // backgroundColor: color.silver,
                    // borderColor: color.silver,
                },
            },
            icon: {
                top: 'calc(50% - 10px)',
                right: 8,
                fontSize: 20,
            },
        },
        MuiStepConnector: {
            alternativeLabel: {
                top: '22px',
            },
            active: {
                '& $line': {
                    // backgroundColor: color.primary,
                },
            },
            completed: {
                '& $line': {
                    // backgroundColor: color.primary,
                },
            },
            line: {
                height: '2px',
                border: sizing.none,
                // backgroundColor: color.silver,
                borderRadius: sizing.none,
            },
            lineHorizontal: {
                borderTopWidth: sizing.none,
            },
        },
        MuiStepLabel: {
            iconContainer: {
                cursor: 'pointer',
            },
        },
        MuiStepper: {
            root: {
                padding: sizing.none,
            },
        },
        MuiSvgIcon: {
            fontSizeSmall: {
                fontSize: sizing.nm,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'uppercase',
                fontSize: '16px',
                lineHeight: '24px',
                // fontWeight: fontWeight.regular,
                '&$selected': {
                    // fontWeight: fontWeight.medium,
                },
            },
        },
        MuiToggleButton: {
            root: {
                // border: `1px solid ${color.primary}`,
                '&$selected': {
                    // backgroundColor: color.primary,
                    color: color.white,
                },
                '&:hover': {
                    backgroundColor: 'inherit',
                },
            },
            sizeSmall: {
                height: '32px',
            },
        },
        MuiTooltip: {
            tooltipPlacementBottom: {
                marginTop: '0.375rem !important',
            },
            tooltipPlacementTop: {
                marginBottom: '0.5rem !important',
            },
            tooltipPlacementRight: {
                marginLeft: '0.5rem !important',
            },
            tooltipPlacementLeft: {
                marginRight: '0.5rem !important',
            },
            tooltip: {
                // backgroundColor: color.info,
            },
            arrow: {
                // color: color.info,
                fontSize: '10px',
            },
        },
    },
    factor: 1.75,
    breakpoints: {
        keys: [
            'xs',
            'sm',
            'md',
            'lg',
            'xl',
            'mobileSmall',
            'mobileMedium',
            'mobileLarge',
            'tablet',
            'tabletPro',
            'desktopSmall',
            'desktopMedium',
            'desktopLarge',
        ],
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            mobileSmall: 320,
            mobileMedium: 360,
            mobileLarge: 582,
            tablet: 768,
            tabletPro: 1024,
            desktopSmall: 1180,
            desktopMedium: 1366,
            desktopLarge: 2200,
        },
    },
    brand: '#E22525',
    footerHeight: 72,
    mobileFooterHeight: 56,
    sidebarWidth: 455,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    mobileTopBarHeight: 52,
    chatWindowWidth: 420,
})
