import * as Yup from 'yup'

export function ConsultationStatusMapper(status) {
    if (status === 'fulfilled') {
        return { color: '#4C8080', label: 'Completed' }
    } else if (status === 'pending') {
        return {
            color: '#F15A5F',
            label: 'Pending',
        }
    } else if (status === 'cancelled') {
        return {
            color: '#FF0303',
            label: 'Cancelled',
        }
    } else if (status === 'noshow') {
        return {
            color: '#FF0303',
            label: 'No Show',
        }
    }
}

export const MarkConsultationSchema = Yup.object().shape({
    appointmentStatus: Yup.string()
        .required('Appointment Status is required')
        .oneOf(
            ['fulfilled', 'noshow'],
            'Appointment Status must be one of the values: complete, no show',
        ),
})
