import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    CircularProgress,
    FormLabel,
} from '@material-ui/core'
import cameraIcon from '../../../assests/images/icon_camera.svg'
import userIcon from '../../../assests/images/icon_user.svg'
import moment from 'moment'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserRole,
} from '../../../utilities'

import '../MainProfile/MainProfile.scss'

import { ProfilePhotoModal } from '../TM2UAdmin/ProfilePhotoUpload'
import '../TM2UAdmin/TM2UAdmin.scss'
import { getCharOfName } from '../../../utilities/profile'
import { UserRoles } from '../../../constants/user-roles'

const ProfileTitle = (props) => {
    const {
        profileDetails,
        isCreate,
        isProvider,
        onEditCallback,
        photoEditAccess,
    } = props
    const role = getLoggedInUserRole()
    const nameInitials = getCharOfName(
        `${profileDetails?.first_name} ${profileDetails?.last_name}`,
    )
    // Modal
    const [isImageLoaded, setIsImageLoaded] =
        useState(false)
    const onEditCallbackWithImgLoad = (x, y) => {
        onEditCallback(x, y)
    }

    // end modal
    useEffect(() => {
        if (profileDetails?.photo) {
            setIsImageLoaded(false)
        }
    }, [profileDetails?.photo])
    return (
        <>
            {!isCreate && (
                <Grid
                    container
                    className="borderBottom pb3">
                    <Grid
                        item
                        xs={5}
                        className="dFlexVc profileTitle">
                        <div className="upload-wrap">
                            <div className="empty-bg">
                                {profileDetails?.photo ? (
                                    <>
                                        {!isImageLoaded && (
                                            <CircularProgress />
                                        )}
                                        <img
                                            style={{
                                                display:
                                                    isImageLoaded
                                                        ? 'block'
                                                        : 'none',
                                            }}
                                            src={
                                                profileDetails?.photo
                                            }
                                            alt="Profile"
                                            className="profile-img"
                                            onLoad={() =>
                                                setIsImageLoaded(
                                                    true,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <div class="profile-img-placeholder">
                                        {nameInitials}
                                    </div>
                                )}

                                {photoEditAccess && (
                                    <ProfilePhotoModal
                                        profilePhoto={
                                            profileDetails?.photo
                                        }
                                        profileDetails={
                                            profileDetails
                                        }
                                        nameInitials={
                                            nameInitials
                                        }
                                        userId={
                                            profileDetails
                                                ?.user?.id
                                        }
                                        whois={
                                            isProvider
                                                ? 'provider'
                                                : 'admin-user'
                                        }
                                        onEditCallback={
                                            onEditCallbackWithImgLoad
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <Grid className="profile-name-wrap">
                            <Typography
                                variant="h2"
                                className="main-name">{`${capitalizeFirstLetter(
                                profileDetails?.first_name,
                            )} ${
                                profileDetails?.last_name
                            }`}</Typography>
                            <Typography
                                className="type"
                                variant="subtitle1">
                                {/* {!isProvider &&
                                    role !== 'provider' &&
                                    profileDetails?.dob && (
                                        <>
                                            DOB:
                                            {/* {profileDetails?.dob} */}
                                {
                                    /* &nbsp;
                                            {moment(
                                                profileDetails?.dob,
                                            ).format(
                                                'MM/DD/YYYY',
                                            )}
                                        </>
                                    )} */
                                    profileDetails?.speciality ? (
                                        <>
                                            {
                                                profileDetails
                                                    ?.speciality
                                                    .name
                                            }
                                        </>
                                    ) : (
                                        <>
                                            Date of birth:
                                            &nbsp;
                                            {moment(
                                                profileDetails?.dob,
                                            ).format(
                                                'MM/DD/YYYY',
                                            )}
                                        </>
                                    )
                                }
                            </Typography>

                            {Array.isArray(
                                profileDetails?.appointment_type,
                            ) &&
                                profileDetails?.appointment_type.map(
                                    (item, i) => (
                                        <>
                                            <Typography
                                                className="dFlexVc mt1"
                                                variant="subtitle2">
                                                <img
                                                    src={
                                                        item
                                                            .toLowerCase()
                                                            .includes(
                                                                'in-clinic',
                                                            )
                                                            ? userIcon
                                                            : cameraIcon
                                                    }
                                                    height={
                                                        16
                                                    }
                                                    width={
                                                        25
                                                    }
                                                    alt="camera"
                                                    className="mr75"
                                                />
                                                <span>
                                                    {`${capitalizeFirstLetter(
                                                        item,
                                                    )} visit`}
                                                </span>
                                            </Typography>
                                        </>
                                    ),
                                )}
                            {Array.isArray(
                                profileDetails?.appointment_type,
                            ) &&
                                profileDetails
                                    ?.appointment_type
                                    .length > 0 && (
                                    <div className="borderBottom pb1" />
                                )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        className="flex"
                        alignItems="center">
                        <Grid item xs={10}>
                            {/* {role === 'provider' || role === 'tm2u_super_admin' && ( */}
                            <>
                                {/* Commented by TS - moved tele-consultant visit to LHS */}
                                {/* {Array.isArray(
                                    profileDetails?.appointment_type,
                                ) &&
                                    profileDetails?.appointment_type.map(
                                        (item, i) => (
                                            <>
                                                <Typography className="dFlexVc ">
                                                    <img
                                                        src={
                                                            item
                                                                .toLowerCase()
                                                                .includes(
                                                                    'in-clinic',
                                                                )
                                                                ? userIcon
                                                                : cameraIcon
                                                        }
                                                        height={
                                                            16
                                                        }
                                                        width={
                                                            25
                                                        }
                                                        alt="camera"
                                                        className="mr75"
                                                    />
                                                    <span className="detail-key-label">
                                                        {`${capitalizeFirstLetter(
                                                            item,
                                                        )} visit`}
                                                    </span>
                                                </Typography>
                                            </>
                                        ),
                                    )} */}
                                {/* {Array.isArray(
                                    profileDetails?.appointment_type,
                                ) &&
                                    profileDetails
                                        ?.appointment_type
                                        .length > 0 && (
                                        <div className="borderBottom pb1" />
                                    )} */}
                            </>
                            {/* )} */}
                            <Grid container xs={12} md={12}>
                                <Grid item xs={12} md={5}>
                                    {profileDetails?.gender && (
                                        <>
                                            <FormLabel className="mt1">
                                                Gender
                                            </FormLabel>
                                            <Typography variant="subtitle2">
                                                {' '}
                                                <span className="capitalize">
                                                    {
                                                        profileDetails?.gender
                                                    }
                                                </span>
                                            </Typography>{' '}
                                        </>
                                    )}
                                </Grid>{' '}
                                <Grid item xs={12} md={5}>
                                    {role ===
                                        UserRoles.PATIENT ||
                                        role ===
                                            UserRoles.TM2U_ADMIN ||
                                        role ===
                                            UserRoles.CLINIC_CORDINATION ||
                                        (role ===
                                            UserRoles.TM2U_SUPER_ADMIN &&
                                            !isProvider &&
                                            profileDetails?.user_id && (
                                                <>
                                                    <FormLabel className="mt1">
                                                        Chart
                                                        ID:
                                                    </FormLabel>
                                                    <Typography variant="subtitle2">
                                                        {
                                                            profileDetails?.user_id
                                                        }
                                                    </Typography>
                                                </>
                                            ))}
                                </Grid>
                            </Grid>

                            {profileDetails?.user && (
                                <Grid
                                    container
                                    xs={12}
                                    md={12}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}>
                                        <FormLabel>
                                            Cellphone
                                        </FormLabel>
                                        <Typography variant="subtitle2">
                                            {formatPhone(
                                                profileDetails?.user?.mob
                                                    .replace(
                                                        /\D/g,
                                                        '',
                                                    )
                                                    .slice(
                                                        -10,
                                                    ),
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}>
                                        <FormLabel>
                                            Email
                                        </FormLabel>
                                        <Typography variant="subtitle2">
                                            {' '}
                                            {
                                                profileDetails
                                                    ?.user
                                                    ?.email
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
export default ProfileTitle
