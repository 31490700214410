/* eslint-disable react/react-in-jsx-scope */
import {
    CircularProgress,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { maxCharLength } from '../../../constants'
import { errorMessages } from '../../../constants/errorMessages'
import { tm2uAdminProfileForm } from '../../../constants/tm2uAdminProfileForm'
import { UserRoles } from '../../../constants/user-roles'
import NotificationPreferences from '../../../containers/NotificationPreferences'
import { saveNotificationPreferences } from '../../../services/Notification'
import { staffProfile } from '../../../store/actions/admin'
import {
    setError,
    setErrorMessage,
} from '../../../store/actions/loader'
import { handleEditTM2UAdminProfile } from '../../../store/actions/tm2uAdmin'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserRole,
} from '../../../utilities'
import LoadingPage from '../../../utilities/loading-page'
import { getCharOfName } from '../../../utilities/profile'
import { usNumberRegex } from '../../../utilities/regex'
import { ProfilePhotoModal } from './ProfilePhotoUpload'
import './TM2UAdmin.scss'
import { ButtonEl } from '../../../shared/elements'
const TM2UAdminProfileEdit = (props) => {
    const {
        isCreate,
        onEditCallback,
        onSaveCallback,
        isStaff,
        preferences,
    } = props
    const { profileDetails, handleEditClose } = props
    const [successMsg, setSuccessMsg] = useState('')
    const [btnDisable, isDisable] = useState(true)
    const userRole = getLoggedInUserRole()
    const dispatch = useDispatch()
    const editSuccessMsg = useSelector(
        (state) => state.tm2uAdmin.succesMsg,
    )
    const loading = useSelector(
        (state) => state.loader.loading,
    )
    const errMsg = useSelector(
        (state) => state.loader.errorMsg,
    )

    const initialValues = {
        name: capitalizeFirstLetter(profileDetails?.name),
        staff_id: profileDetails?.role?.id,
        email: profileDetails?.user?.email,
        mob: profileDetails?.user?.mob
            ? formatPhone(
                  profileDetails?.user?.mob
                      .replace(/\D/g, '')
                      .slice(-10),
              )
            : '',
        user_id: profileDetails?.user?.id,
        email_notification: preferences.email,
        sms_notification: preferences.sms,
        app_notification: preferences.app_notification,
    }

    const validateSchema = Yup.object().shape({
        name: Yup.string()
            .required(errorMessages.staff_admin_name)
            .trim()
            .matches(
                /^[aA-zZ\s]+$/,
                errorMessages.staff_admin_name,
            ),
        mob: Yup.string()
            .required(errorMessages.phoneNumber)
            .matches(usNumberRegex, 'Invalid phone number'),
        staff_id: Yup.string().required(
            errorMessages.admin_role,
        ),
        email: Yup.string()
            .email('Please enter valid email')
            .required(errorMessages.validEmailId),
        email_notification: Yup.boolean(),
        sms_notification: Yup.boolean(),
    })
    const getChangedValues = (values, initial) => {
        return Object.entries(values).reduce(
            (acc, [key, value]) => {
                const hasChanged = initial[key] !== value
                if (hasChanged) {
                    if (!key.includes('_notification')) {
                        acc[key] = value
                    }
                }
                return acc
            },
            {},
        )
    }

    const saveProfile = async (val, touched) => {
        const changedValues = getChangedValues(
            val,
            initialValues,
        )
        changedValues['user_id'] = val?.user_id
        if (changedValues['mob']) {
            changedValues['mob'] =
                process.env.REACT_APP_COUNTRY_CODE +
                val.mob.replace(/\D/g, '').slice(-10)
        }
        const createPayload = {
            name: val.name,
            mob:
                process.env.REACT_APP_COUNTRY_CODE +
                val.mob.replace(/\D/g, '').slice(-10),
            email: val.email,
            staff_id: val.staff_id,
        }

        const actionMessage = isCreate
            ? 'Profile Created Successfully'
            : 'Profile Updated Successfully'
        if (isCreate) {
            await dispatch(
                staffProfile(createPayload, (res) => {
                    const { isUserExist } = res.data
                        ? res.data
                        : res

                    const tm2uAdminCreationResponse =
                        isUserExist
                            ? 'User Already Existed'
                            : actionMessage
                    Swal.fire(
                        '',
                        tm2uAdminCreationResponse,
                    ).then((result) => {
                        onSaveCallback()
                    })
                }),
            )
        } else {
            await dispatch(
                handleEditTM2UAdminProfile(
                    changedValues,
                    () => {
                        Swal.fire('', actionMessage).then(
                            (result) => {
                                onEditCallback()
                            },
                        )
                    },
                ),
            )
        }
    }

    // Modal
    const [isImageLoaded, setIsImageLoaded] =
        useState(false)
    const onEditCallbackWithImgLoad = (x, y) => {
        onEditCallback(x, y)
    }

    // end modal
    useEffect(() => {
        if (errMsg) {
            Swal.fire('', 'User Already Existed', 'error')
        }
        if (profileDetails?.photo) {
            setIsImageLoaded(false)
        }
        dispatch(setError(false))
        dispatch(setErrorMessage(''))
    }, [errMsg, profileDetails?.photo])

    async function savePreferences(values) {
        await saveNotificationPreferences({
            user_id: profileDetails?.user?.id,
            email: values.email_notification,
            sms: values.sms_notification,
            app_notification: values.app_notification,
        })
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    variant="h3"
                    className="edit-main-title ">
                    {isCreate ? (
                        isStaff ? (
                            <div className="editStaff-strip">
                                Add Staff
                            </div>
                        ) : (
                            <>Add Admin</>
                        )
                    ) : isStaff ? (
                        <div className="editStaff-strip">
                            Edit Staff
                        </div>
                    ) : (
                        <div className="editStaff-strip">
                            Edit Admin
                        </div>
                    )}
                </Typography>
                <Grid
                    item
                    xs={12}
                    style={{ padding: '1em' }}>
                    <Grid container>
                        <Grid item>
                            {!isCreate && (
                                <div className="upload-wrap">
                                    <div className="empty-bg">
                                        {profileDetails?.photo ? (
                                            <>
                                                {!isImageLoaded && (
                                                    <CircularProgress />
                                                )}
                                                <img
                                                    style={{
                                                        display:
                                                            isImageLoaded
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    src={
                                                        profileDetails?.photo
                                                    }
                                                    alt="Profile"
                                                    className="profile-img"
                                                    onLoad={() =>
                                                        setIsImageLoaded(
                                                            true,
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div class="placeholder-text">
                                                {getCharOfName(
                                                    profileDetails?.name,
                                                )}
                                            </div>
                                        )}

                                        <ProfilePhotoModal
                                            profilePhoto={
                                                profileDetails?.photo
                                            }
                                            nameInitials={getCharOfName(
                                                profileDetails?.name,
                                            )}
                                            userId={
                                                profileDetails
                                                    ?.user
                                                    ?.id
                                            }
                                            onEditCallback={
                                                onEditCallbackWithImgLoad
                                            }
                                            whois="admin-self"
                                        />
                                    </div>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Formik
                                initialValues={
                                    initialValues
                                }
                                onSubmit={(values) => {
                                    saveProfile(values)

                                    if (
                                        userRole ===
                                        UserRoles.TM2U_SUPER_ADMIN
                                    ) {
                                        savePreferences(
                                            values,
                                        )
                                    }
                                }}
                                validationSchema={
                                    validateSchema
                                }
                                enableReinitialize>
                                {({
                                    errors,
                                    touched,
                                    isValid,
                                    values,
                                    setFieldTouched,
                                    setFieldValue,
                                    setFieldError,
                                    dirty,
                                    isSubmitting,
                                    handleBlur,
                                }) => {
                                    return (
                                        <Form className="profile-edit-main-wrap flexWrap dFlex">
                                            {tm2uAdminProfileForm.map(
                                                (
                                                    item,
                                                    i,
                                                ) => {
                                                    return (
                                                        <>
                                                            <Grid
                                                                item
                                                                xs={
                                                                    6
                                                                }>
                                                                {item.inputType ===
                                                                    'textField' && (
                                                                    <Grid
                                                                        item
                                                                        xs={
                                                                            12
                                                                        }
                                                                        className="dFlexVc mb1">
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                11
                                                                            }>
                                                                            <FormLabel className="tm-form-title">
                                                                                {
                                                                                    item.label
                                                                                }
                                                                                {item.required && (
                                                                                    <span className="astrik">
                                                                                        *
                                                                                    </span>
                                                                                )}
                                                                            </FormLabel>
                                                                            <TextField
                                                                                name={
                                                                                    item.name
                                                                                }
                                                                                onChange={async (
                                                                                    e,
                                                                                ) => {
                                                                                    if (
                                                                                        item.name ===
                                                                                        'mob'
                                                                                    ) {
                                                                                        // add value masking
                                                                                        const maskedValue =
                                                                                            await formatPhone(
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            )

                                                                                        setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            maskedValue,
                                                                                        )
                                                                                    } else {
                                                                                        await setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        )
                                                                                    }
                                                                                }}
                                                                                fullWidth
                                                                                id={
                                                                                    item.id
                                                                                }
                                                                                variant="outlined"
                                                                                placeholder={
                                                                                    item.placeHolder
                                                                                }
                                                                                inputProps={{
                                                                                    maxLength:
                                                                                        item.name ===
                                                                                        'mob'
                                                                                            ? 14
                                                                                            : maxCharLength,
                                                                                }}
                                                                                value={
                                                                                    values[
                                                                                        item
                                                                                            .name
                                                                                    ]
                                                                                }
                                                                                onBlur={
                                                                                    handleBlur
                                                                                }
                                                                            />
                                                                            {errors[
                                                                                item
                                                                                    .name
                                                                            ] &&
                                                                                touched[
                                                                                    item
                                                                                        .name
                                                                                ] && (
                                                                                    <FormHelperText
                                                                                        error>
                                                                                        {
                                                                                            errors[
                                                                                                item
                                                                                                    .name
                                                                                            ]
                                                                                        }
                                                                                    </FormHelperText>
                                                                                )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {item.inputType ===
                                                                    'dropDown' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                11
                                                                            }
                                                                            className="dFlexVc mb1">
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="tm-form-title">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </FormLabel>
                                                                                <Select
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        )
                                                                                    }}
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    className="ba-input-fields"
                                                                                    variant="outlined"
                                                                                    disableUnderline
                                                                                    placeholder={
                                                                                        item.placeHolder
                                                                                    }
                                                                                    defaultValue="none"
                                                                                    value={
                                                                                        values[
                                                                                            item
                                                                                                .name
                                                                                        ]
                                                                                    }
                                                                                    IconComponent={
                                                                                        ExpandMoreIcon
                                                                                    }
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }>
                                                                                    <MenuItem
                                                                                        disabled
                                                                                        value="none">
                                                                                        {
                                                                                            <span className="opacity05">
                                                                                                {
                                                                                                    item.placeHolder
                                                                                                }
                                                                                            </span>
                                                                                        }
                                                                                    </MenuItem>

                                                                                    {item.options.map(
                                                                                        (
                                                                                            el,
                                                                                        ) => (
                                                                                            <MenuItem
                                                                                                className="pl-select-option"
                                                                                                value={
                                                                                                    el.value
                                                                                                }
                                                                                                key={
                                                                                                    el.value
                                                                                                }>
                                                                                                {
                                                                                                    el.name
                                                                                                }
                                                                                            </MenuItem>
                                                                                        ),
                                                                                    )}
                                                                                </Select>
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            {userRole ===
                                                                UserRoles.TM2U_SUPER_ADMIN &&
                                                                item.sectionTitle ===
                                                                    'Notifications' && (
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }>
                                                                            <FormLabel className="tm-form-title">
                                                                                {
                                                                                    item.sectionTitle
                                                                                }
                                                                            </FormLabel>
                                                                        </Grid>
                                                                        <Grid
                                                                            container
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            md={
                                                                                4
                                                                            }
                                                                            lg={
                                                                                6
                                                                            }>
                                                                            {item.fieldItems.map(
                                                                                (
                                                                                    item,
                                                                                    index,
                                                                                ) => {
                                                                                    return (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                item.gridSize
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }>
                                                                                            <NotificationPreferences
                                                                                                label={
                                                                                                    item.label
                                                                                                }
                                                                                                name={
                                                                                                    item.name
                                                                                                }
                                                                                                value={
                                                                                                    values[
                                                                                                        item
                                                                                                            .name
                                                                                                    ]
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) => {
                                                                                                    setFieldValue(
                                                                                                        e
                                                                                                            .target
                                                                                                            .name,
                                                                                                        !values[
                                                                                                            item
                                                                                                                .name
                                                                                                        ],
                                                                                                    )
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                    )
                                                                                },
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                        </>
                                                    )
                                                },
                                            )}
                                            <Grid
                                                item
                                                xs={11}
                                                justifyContent="flex-end"
                                                spacing={2}>
                                                <ButtonEl
                                                    variant="contained"
                                                    disabled={
                                                        isSubmitting
                                                    }
                                                    type="submit"
                                                    className="space-10">
                                                    {isCreate
                                                        ? 'Save'
                                                        : 'Update'}
                                                </ButtonEl>
                                            </Grid>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {loading && <LoadingPage />}
        </>
    )
}
export default TM2UAdminProfileEdit
