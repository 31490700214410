import React from 'react'
import { TextField, InputBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ReactComponent as SearchIcon } from '../../../assests/images/icon_search.svg'
import './index.scss'

const styles = (theme) => ({
    input: {
        height: 51,
    },
})

const SearchField = withStyles(styles)(
    ({
        classes,
        placeHolder = 'Search by Patient Name',
        handleChange,
    }) => {
        return (
            <InputBase
                className="search-field-box"
                placeholder={placeHolder}
                label={placeHolder}
                inputProps={{ 'aria-label': placeHolder }}
                onChange={handleChange}
                startAdornment={<SearchIcon />}
            />
        )
    },
)

export default SearchField
