const IconClock = ({ width = 21, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 18.9583C6.13333 18.9583 2.58333 15.4083 2.58333 11.0417C2.58333 6.675 6.13333 3.125 10.5 3.125C14.8667 3.125 18.4167 6.675 18.4167 11.0417C18.4167 15.4083 14.8667 18.9583 10.5 18.9583ZM10.5 4.375C6.82499 4.375 3.83333 7.36667 3.83333 11.0417C3.83333 14.7167 6.82499 17.7083 10.5 17.7083C14.175 17.7083 17.1667 14.7167 17.1667 11.0417C17.1667 7.36667 14.175 4.375 10.5 4.375Z"
                fill="#14AE5C"
            />
            <path
                d="M10.5 11.4584C10.1583 11.4584 9.875 11.1751 9.875 10.8334V6.66675C9.875 6.32508 10.1583 6.04175 10.5 6.04175C10.8417 6.04175 11.125 6.32508 11.125 6.66675V10.8334C11.125 11.1751 10.8417 11.4584 10.5 11.4584Z"
                fill="#14AE5C"
            />
            <path
                d="M13 2.29175H8C7.65833 2.29175 7.375 2.00841 7.375 1.66675C7.375 1.32508 7.65833 1.04175 8 1.04175H13C13.3417 1.04175 13.625 1.32508 13.625 1.66675C13.625 2.00841 13.3417 2.29175 13 2.29175Z"
                fill="#14AE5C"
            />
        </svg>
    )
}

export default IconClock
