import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import HeaderNew from '../Header/HeaderNew'
import HealthReport from '../HealthReport'
import AppointmentDetails from './AppointmentDetails'
import DashboardHome from './DashboardHome'
import AppointmentNew from '../AppointmentNew'

const PatientDashboardNew = () => {
    return (
        <>
            <Grid conatiner style={{ background: '#F5F6F7' }}>
                <DashboardHome />
            </Grid>
        </>
    )
}

export default PatientDashboardNew
