import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import HeaderIcon from '../../../assests/sidebar/icon_appointments_pressed.svg'
import {
    APPOINTMENT_STATUS_CODE,
    APPOINTMENT_TYPE,
} from '../../../constants/appointment'
import Routes from '../../../constants/routes'
import { UserRoles } from '../../../constants/user-roles'
import { getAppointments } from '../../../services/Appointments/appointments'
import {
    CardHeaderEl,
    NoRowsOverlayEl,
} from '../../../shared/elements'
import { getTimeframe } from '../../../shared/utils'
import { generateCardColumns } from '../utils'

function AppointmentListCard(props) {
    const type = 'upcoming'
    const interval = 'yearly'
    const history = useHistory()

    const userInfo = React.useMemo(
        () => ({
            userId: localStorage.getItem('userId'),
            role: localStorage.getItem('role'),
        }),
        [],
    )

    const columns = [
        ...generateCardColumns(
            props.role,
            userInfo.role,
            onRowClick,
        ),
        {
            headerName: (
                <Typography variant="body2">
                    Action
                </Typography>
            ),
            field: 'action',
            flex: 1.5,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        className="content-align-center">
                        {renderStartConsultation(
                            params.row,
                        )}
                    </Grid>
                )
            },
        },
    ]

    const [filters] = React.useState({
        page: 1,
        limit: 5,
        type,
        ...getTimeframe(interval, type),
    })

    const query = useQuery(
        [
            'appointments',
            props.role === UserRoles.TM2U_ADMIN ||
            props.role === UserRoles.TM2U_SUPER_ADMIN
                ? ''
                : props.userId,
            props.role,
            filters,
        ],
        getAppointments,
    )

    function renderStartConsultation(row) {
        if (
            props.role === UserRoles.PATIENT ||
            props.role === UserRoles.CLINIC_CORDINATION
        ) {
            if (
                [
                    APPOINTMENT_STATUS_CODE.NOSHOW,
                    APPOINTMENT_STATUS_CODE.PENDING,
                    APPOINTMENT_STATUS_CODE.OPEN,
                ].includes(row.appointment_status)
            ) {
                const diff = calculateTimeDiff(row)
                if (
                    row.appointment_type ===
                        APPOINTMENT_TYPE.IN_CLINIC &&
                    props.role !==
                        UserRoles.CLINIC_CORDINATION
                ) {
                    return <></>
                } else if (
                    diff.start > 30 ||
                    diff.end >= 30 ||
                    row.appointment_status ===
                        APPOINTMENT_STATUS_CODE.NOSHOW
                ) {
                    return (
                        <Grid
                            item
                            className="content-align-center dash-appt-table">
                            <Tooltip
                                title="Button will be enable 30 min prior
                        to appointment"
                                placement="bottom">
                                <div>
                                    <Button
                                        disabled
                                        variant="outlined">
                                        Start Consultation
                                    </Button>
                                </div>
                            </Tooltip>
                        </Grid>
                    )
                }
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <Button
                            href={row.consultation_link}
                            variant="outlined">
                            Start Consultation
                        </Button>
                    </Grid>
                )
            } else if (
                row.appointment_status ===
                APPOINTMENT_STATUS_CODE.CANCELLED
            ) {
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <Typography
                            variant="body1"
                            color="error">
                            <strong>Cancelled</strong>
                        </Typography>
                    </Grid>
                )
            } else if (
                row.appointment_status ===
                APPOINTMENT_STATUS_CODE.FULFILLED
            ) {
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <Typography
                            variant="body1"
                            style={{ color: '#4C8080' }}>
                            <strong>Completed</strong>
                        </Typography>
                    </Grid>
                )
            }
        } else if (
            row.appointment_status ===
            APPOINTMENT_STATUS_CODE.CANCELLED
        ) {
            return (
                <Grid item className="content-align-center">
                    <Typography
                        variant="body1"
                        color="error">
                        <strong>Cancelled</strong>
                    </Typography>
                </Grid>
            )
        } else if (
            row.appointment_status ===
            APPOINTMENT_STATUS_CODE.FULFILLED
        ) {
            return (
                <Grid item className="content-align-center">
                    <Typography
                        variant="body1"
                        style={{ color: '#4C8080' }}>
                        <strong>Completed</strong>
                    </Typography>
                </Grid>
            )
        }
    }

    function calculateTimeDiff(params) {
        const currentTime = moment.utc().local()
        const startTime = moment
            .utc(`${params.date} ${params.start}`)
            .local()
        const endTime = moment
            .utc(`${params.date} ${params.end}`)
            .local()
        const start = startTime.diff(currentTime, 'minutes')
        const end = currentTime.diff(endTime, 'minutes')

        return { start, end }
    }

    function onRowClick(userId, role) {
        history.push(Routes.PROFILE, {
            userId,
            role,
        })
    }

    return (
        <div className="MuiDataGrid-table-card-wrapper">
            <Card
                style={{
                    height: 386,
                    width: '100%',
                }}>
                <CardHeaderEl
                    title="Appointments"
                    avatar={
                        <Box
                            style={{
                                height: 28,
                                width: 28,
                            }}>
                            <img src={HeaderIcon} />
                        </Box>
                    }
                    action={
                        <Grid container alignItems="center">
                            <Grid item>
                                <IconButton
                                    disableFocusRipple={
                                        true
                                    }
                                    disableRipple={true}
                                    size={'small'}
                                    onClick={() =>
                                        history.push(
                                            Routes.APPOINTMENT,
                                        )
                                    }>
                                    <Typography
                                        color="primary"
                                        variant="body2">
                                        View All
                                    </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent style={{ padding: 0 }}>
                    <div
                        style={{
                            height: 350,
                            width: '100%',
                        }}>
                        <DataGrid
                            getRowId={(row) => row.id}
                            rows={query.data?.data ?? []}
                            columns={columns}
                            disableColumnSelector
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <NoRowsOverlayEl title="No Appointments Found." />
                                ),
                            }}
                            rowHeight={52}
                            hideFooter
                            loading={query.isLoading}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default AppointmentListCard
