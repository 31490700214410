import React, { useState } from 'react'
import { Grid, MenuItem, Select } from '@material-ui/core'
import { Field, getIn } from 'formik'

const ProviderInNetworkInsuranceView = ({
    inNwInsurance,
    index,
    fieldArrayProps,
    setFieldValue,
    initialInNetworkInsurance,
    allValues,
    optionsList,
    setFieldTouched,
}) => {
    const options = optionsList.fieldItems
    // optionsList.fieldItems
    const { insurance } = inNwInsurance
    const [isDuplicateError, setIsDuplicateError] =
        useState(false)
    const ErrorMessage = ({ name }) => (
        <Field
            name={name}
            render={({ form }) => {
                if (
                    form.errors &&
                    form.errors.in_network_insurances &&
                    !Array.isArray(
                        form.errors.in_network_insurances,
                    ) &&
                    form.errors.in_network_insurances.includes(
                        'Duplicate',
                    )
                ) {
                    setIsDuplicateError(true)

                    const error = getIn(
                        form.errors,
                        'in_network_insurances',
                    )
                    const touch = getIn(form.touched, name)

                    return error ? (
                        <div className="errormsg">
                            {error}
                        </div>
                    ) : null
                }
                setIsDuplicateError(false)

                const error = getIn(
                    form.errors,
                    'in_network_insurances',
                )
                const touch = getIn(form.touched, name)

                return touch && error ? (
                    <div className="errormsg">{error}</div>
                ) : null
            }}
        />
    )

    const { push, remove } = fieldArrayProps
    return (
        <Grid container spacing={2} key={index}>
            <Grid item xs={5} className={'mb1'}>
                <Field
                    name={`in_network_insurances[${index}].insurance`}
                    component={Select}
                    fullWidth
                    variant="outlined"
                    placeholder="Reporing Provider"
                    onChange={(e) => {
                        setFieldValue(
                            `in_network_insurances[${index}].insurance`,
                            e.target.value,
                        )
                    }}
                    onBlur={(e) =>
                        setFieldTouched(
                            `in_network_insurances[${index}].insurance`,
                            true,
                        )
                    }
                    value={insurance}>
                    <MenuItem disabled value="none">
                        <span className="opacity05">
                            {'Select Insurance'}
                        </span>
                    </MenuItem>
                    {options.map((item) => (
                        <MenuItem
                            value={item.insurance}
                            key={item.insurance}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Field>
                {/* <ErrorMessage
                    name={`in_network_insurances[${index}].insurance`}
                /> */}
                {/* <ErrorMessage
                    name={`in_network_insurances`}
                /> */}
                <span>
                    {index !== 0 &&
                        index === allValues.length - 1 &&
                        isDuplicateError && (
                            <ErrorMessage
                                name={`in_network_insurances[${index}].insurance`}
                            />
                        )}
                    {!isDuplicateError && (
                        <ErrorMessage
                            name={`in_network_insurances[${index}].insurance`}
                        />
                    )}
                </span>
            </Grid>

            <Grid item xs={2} className="flex">
                {index !== 0 &&
                    index === allValues.length - 1 && (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(
                                    initialInNetworkInsurance,
                                )
                            }>
                            +
                        </button>
                    )}

                {index === 0 &&
                    (allValues.length === 1 ? (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(
                                    initialInNetworkInsurance,
                                )
                            }>
                            +
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() => remove(index)}>
                            -
                        </button>
                    ))}
                {index !== 0 && (
                    <button
                        type="button"
                        className="plus-wrap "
                        onClick={() => remove(index)}>
                        -
                    </button>
                )}
            </Grid>
        </Grid>
    )
}

export default ProviderInNetworkInsuranceView
