import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

function BookingButtons(props) {
    const disabledConditions = props.stepperContinueConditions(props.steps)
    const [width, setWidth]= useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [])
    return (
        <div className="booking_buttons">
            {width < 768 && props.steps === 0 ? " ":
            props.steps !== 6 &&(
                <Button
                    variant="contained"
                    color="success"
                    onClick={props.handlePreviousStep}
                    style={{ background: '#5571C6' }}>
                    {props.steps !== 0 ? 'Back' : 'Back to home'}
                </Button>
            )}
            <>
            {!(width < 768 && props.steps === 6) &&(<Button
                    variant="contained"
                    disabled={disabledConditions}
                    onClick={props.handleNextStep}
                    style={{
                        background: '#15a452',
                        opacity: disabledConditions ? 0.5 : 1,
                        color: disabledConditions ? 'rgba(255, 255, 255, 0.70)' : '#fff',
                        width: props.steps === 6 ? '100%' : ''
                    }}
                >
                    {props.steps !== 6 ? 'Continue' : 'Complete'}
                </Button>
            )}
            </>
        </div>
    )
}

export default BookingButtons
