const Routes = Object.freeze({
    //guest routes
    HOME: '/',
    LOGIN: {
        BASIC: '/login',
        ALTERNATE: '/loginAnotherWay',
        STAFF: '/login/staff',
    },

    SIGNUP: '/signup',
    REGISTER: '/register',
    VERIFY_EMAIL: '/verify-email',
    SIGNUP_VERIFY_EMAIL: '/signup-verify-email',
    PATAIENTBOOKAPPOINTMENT: '/patient-book-appointment',
    SIGNIN: '/signin',
    MAGIC_LINK: '/verify-magic-link',
    FORGOT_PASSWORD: '/forgot-password',
    CONTACTS: '/contacts',
    CONSULTATION: {
        URL: `/video/:data`,
        // getURLById: (iv, data) => `/video/${data}`,
        VIDEO: '/video',
        JOIN: '/join',
        ROOM: '/room',
        SHORT_LINK: '/encounter-link/:shortLink',
    },

    //auth routes

    APPOINTMENT: '/appointments',
    WORKLIST: '/worklist',
    // APPOINTMENTS: {
    //     HOME: '/appointments',
    // BOOK: '/appointments/book',
    // SEARCH_PROVIDER: '/appointments/search-provider',
    // PROVIDER_DETAIL: {
    //     URL: '/appointments/provider-details/:providerId',
    //     getURLById: (providerId) => `/appointments/provider-details/${providerId}`,
    // },
    // PROVIDER_LIST: '/appointments/provider-list',
    // },
    CONSULTATION_TRACKING: '/consultation/tracking',
    CONSULTATION_STATUS: '/consultation/status',
    //Sidebar menu urls
    DASHBOARD: '/dashboard',
    SCHEDULER: '/scheduler',
    USERS: '/users',
    CLINICAL_NOTES: {
        LISTING: '/clinical-notes',
        NEW_NOTE: '/clinical-note',
    },
    CLINICAL_NOTES_VIEW: {
        VIEW_LIST: '/clinical-notes-view',
    },
    // THERAPHY: '/theraphy',
    MESSSAGES: '/messages',
    DOCUMENTS: '/documents',
    BILLING: '/billing',
    LABS: '/labs',
    ERX: '/erx',
    IMAGING: '/imaging',
    REPORTS: '/reports',
    SCALES: '/scales',
    SETTINGS: {
        MAIN: '/settings',
        NEW_FORM_BUILDER: '/settings/form-builder',
    },
    SUPPORTS: '/supports',
    FAX: {
        LISTING: '/fax',
        DETAIL: '/fax/detail',
    },
    FORMS: '/forms',
    PATIENT: '/patient',
    PATIENT_DETAIL: '/patient-detail',
    PATIENT_SURVEY: '/patient/survey-form',
    PATIENT_INTAKE: '/patient/intake',
    PATIENT_PROFILE_EDIT: '/patient/profile/edit',
    PROVIDER: '/provider',

    TM2UAdmin: '/tm2uadmin/profile',
    //profile
    PATIENT_PROFILE: '/patient/profile',
    PROFILE: '/profile',
    PAYMENTS: {
        RECIEPT: '/patient/payment',
    },
    TELE_HEALTH: {
        EMPTY_NOTE: '/empty-note',
    },

    //health record
    MY_HEALTH_RECORD: '/my-health-record',
    PATIENT_INSURANCE: '/insurance',
    HEALTH_CONDITIONS: '/my-health-conditions',
    MEDICATION_ALLERRGIES: '/my-medication-allergies',
    LAB_RESULTS: '/my-lab-results',
    MEDICATIONS: '/medications',

    MESSAGE_CARE_TEAM: '/message-care-team',
})

export default Routes
