import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@material-ui/core'
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'
import { materialTheme as theme } from '../../../utilities/calendar/style'
import DateTimePickerIcon from '../../../assests/icons/datetimepicker.svg'

function DateTimePicker(props) {
    return (
        <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}>
            <ThemeProvider theme={theme}>
                <KeyboardDateTimePicker
                    id={props.name}
                    aria-label={props.name}
                    name={props.name}
                    variant="inline"
                    placeholder={'Date'}
                    disableToolbar
                    inputVariant="outlined"
                    {...props}
                    autoOk
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}
DateTimePicker.defaultProps = {
    keyboardIcon: DateTimePickerIcon,
}
export default DateTimePicker
