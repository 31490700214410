import React from 'react'
import ConsulationLink from './consulation-link'
import { useHistory } from 'react-router-dom'
import './contaer.css'

const ProviderDashboard = (props) => {
    const history = useHistory()
    return (
        <React.Fragment>
            <div className="con-dashboard">
                <div className="apt-container">
                    <div className="apt-heading-box">
                        <span className="apt-heading-txt">
                            Dashboard
                        </span>
                    </div>
                    <div className="apt-btn">
                        <ConsulationLink />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProviderDashboard
