import React from 'react'
import TeleMed_Logo from '../../../assests/images/dashboard/TM2U Logo - 500px Width 2.png'
import './PatientAppointment.scss'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import AdjustIcon from '@mui/icons-material/Adjust'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Booking = (props) => {
    const defaultSteps = [
        'First Time Booking?',
        'Find your care',
        'Select day/time',
        'Select a provider',
        'Reason for visit',
        'Review appointment',
    ]
    const defaultStepsIsFirst = [
        'First Time Booking?',
        'Intake form',
        'Find your care',
        'Select day/time',
        'Select a provider',
        'Reason for visit',
        'Review appointment',
    ]
    const isFirstTime = props.isFirstTime
    const currentStep = props.steps

    const steps = isFirstTime ? defaultStepsIsFirst : defaultSteps

    function calculateActiveStep(prop) {
        if (!isFirstTime) {
            if (currentStep === 2 || currentStep === 2.5) return 1
            if (currentStep > 2) return currentStep - 1
            return currentStep
        }
        if (currentStep === 2.5) return 2
        return currentStep
    }

    const activeStep = calculateActiveStep(props)

    const getStepIcon = (index) => {
        if (index === activeStep) return <AdjustIcon style={{ color: '#5571C6' }} />
        if (index < activeStep) return <CheckCircleIcon style={{ color: '#5571C6' }} />
        return <CircleOutlinedIcon style={{ color: '#C6C6C6' }} />
    }

    return (
        <div className="booking_appointment">
            <div style={{ display: 'flex' }} className='wizard_outer_holder'>
                <div className='image_holder'>
                    <img src={TeleMed_Logo} alt="teleMed-Logo" className="teleMed_Logo" />
                </div>
                <div style={{ width: '100%' }}>
                    <Box>
                        <Stepper className='cstm_stepper_holder' activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step className='each_step_holder' key={index}>
                                    <StepLabel className='each_step_label' StepIconComponent={() => getStepIcon(index)}>
                                        <div className='steps_info_holder'>
                                            {label} 
                                            <span className='d-lg-none'>Step {index + 1}/{steps.length}</span>
                                        </div>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Booking
