import { Box, Grid } from '@material-ui/core'
import './patientDetailsStyle.scss'
import moment from 'moment'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { patientTimeZone } from '../../../containers/AppointmentList/utils'
import { getAppointmentListUpcomingPrevious } from '../../../services/Appointments/appointments'
import { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) =>
    createStyles({
        textFooter: {
            color: '#667689',
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            paddingBottom: '10px',
        },
    }),
)

const PatientDetails = ({ patient, previousAppointmentStartTime }) => {
    const classes = useStyles()
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const [confirmappointmentStartValue, setConfirmappointmentStartValue] = useState("")
    const nextAppointment = async () =>{
        const payload = {
            page: 1,
            size: 1,
            status: 'booked,waitlist',
            start: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            _sort: 'date',
        }
        const res = await getAppointmentListUpcomingPrevious(payload)
        const value = res?.data?.data?.data.length ? res?.data?.data?.data[0].start : ''
        setConfirmappointmentStartValue(value)
    }
    useEffect(()=>{
        nextAppointment()
    },[])
    return (
        <Grid container className="patientdetails-main-container">
            <Box>
                <Box className="header-txt">Patient Details</Box>
                <Box className="content-main">
                    <Box className="content-main-1">
                        <Box className="box-1">
                            <box className="label">Date of Birth</box>
                            <box className="value">{moment(patient?.dob).format('MM/DD/YYYY')}</box>
                        </Box>
                        <Box className="box-2">
                            <box className="label">Sex</box>
                            <box className="value">{patient?.gender}</box>
                        </Box>
                        <Box className="box-3">
                            <box className="label">Previous Appointment</box>
                            <box className="value">
                                {/* {patient?.last_appointment?.start
                                    ? patientTimeZone(
                                          patient?.last_appointment?.start,
                                          loggedinPatiendata?.timezone,
                                      ).format('MM/DD/YYYY')
                                    : ''} */}
                                {previousAppointmentStartTime
                                    ? moment(previousAppointmentStartTime).format('MM/DD/YYYY')
                                    : ''}
                            </box>
                        </Box>
                        <Box className="box-4">
                            <box className="label">Next Appointment</box>
                            <box className="value">
                                {/* {patient?.next_appointment?.start
                                    ? patientTimeZone(
                                          patient?.next_appointment?.start,
                                          loggedinPatiendata?.timezone,
                                      ).format('MM/DD/YYYY')
                                    : ''} */}
                                {confirmappointmentStartValue
                                    ? moment(
                                        confirmappointmentStartValue
                                      ).format('MM/DD/YYYY')
                                    : ''}
                            </box>
                        </Box>
                    </Box>
                    <Box className="box-1">
                        <box className="label">Age</box>
                        <box className="value">
                            {moment().diff(moment(patient?.dob, 'YYYY-MM-DD'), 'years')} Years Old
                        </box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.textFooter}>If you see any inaccuracies please contact us.</Box>
        </Grid>
    )
}

export default PatientDetails
