import React from 'react';

export default function TeleInvalidLinkBg() {
  return (
    <svg width="724" height="414" viewBox="0 0 724 414" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2960_49572)">
<path d="M230.616 412.397C230.616 412.397 281.058 411.832 296.626 411.832C312.194 411.832 345.515 409.761 321.07 409.116C296.277 408.462 300.826 407.898 310.328 407.223C312.067 407.097 291.682 406.48 279.765 406.48C275.066 406.48 254.696 405.85 240.808 407.468C229.991 408.737 223.338 411.469 230.616 412.397Z" fill="#C8DEEC"/>
<path d="M83.7639 372.06C83.7639 372.06 86.4477 368.059 97.458 364.882C108.468 361.705 116.572 367.881 121.865 371.036C126.266 373.656 124.452 379.498 125.055 380.738C125.924 382.542 127.939 382.824 129.671 382.542C134.169 381.822 135.738 376.685 135.991 374.837C136.318 372.461 135.292 369.529 134.191 366.991C132.236 362.485 126.608 354.527 113.152 353.8C99.6884 353.073 82.3959 363.264 83.7639 372.06Z" fill="#DDE0E4"/>
<path d="M62.0096 380.923C62.0096 380.923 51.2892 400.572 50.0625 405.464C48.8358 410.348 46.0479 413.978 39.8996 414C33.2087 414.022 33.6399 408.217 34.755 407.134C38.5986 404.224 38.5986 408.707 43.4905 396.497C48.3897 384.279 57.601 377.501 62.0096 380.923Z" fill="#DDE0E4"/>
<path d="M626.519 101.435C634.251 107.061 638.868 109.54 641.455 111.218C644.771 113.371 648.815 113.772 650.391 115.085C655.722 119.509 653.06 125.477 653.454 127.823C651.915 132.24 648.785 132.477 644.302 133.138C642.414 132.292 644.146 127.029 641.581 125.515C639.239 124.134 639.403 124.371 639.403 124.371C639.403 124.371 640.949 126.034 641.879 128.083C642.607 129.686 643.328 130.659 643.589 131.215C644.012 132.128 643.745 133.16 643.254 133.524C642.369 134.185 640.89 133.769 636.883 129.53C635.545 128.113 632.504 121.165 630.348 119.391C627.344 116.919 625.739 115.286 618.141 112.064L626.519 101.435Z" fill="#FAC5A3"/>
<path d="M591.578 192.321C591.578 192.321 587.028 197.896 572.658 202.349C558.287 206.803 543.418 135.914 543.418 135.914L591.578 192.321Z" fill="#BECCF5"/>
<path d="M498.663 254.555C498.663 254.555 501.094 234.758 512.089 219.912L478.018 141.667L498.663 254.555Z" fill="#BECCF5"/>
<path d="M255.416 390.788C255.416 390.788 281.05 389.527 294.528 402.828C294.528 402.828 275.4 407.973 255.416 396.4V390.788Z" fill="#819D2E"/>
<path d="M240.854 402.109C240.854 402.109 271.231 394.366 290.605 407.015C290.605 407.015 268.963 417.823 242.214 408.819L240.854 402.109Z" fill="#819D2E"/>
<path d="M233.135 390.202C233.135 390.202 267.541 386.112 284.113 404.981C284.113 404.981 261.423 415.388 233.529 400.349L233.135 390.202Z" fill="#AABD54"/>
<path d="M245.826 385.607C245.826 385.607 278.567 374.28 298.789 389.178C298.789 389.178 278.842 404.179 248.384 395.435L245.826 385.607Z" fill="#AABD54"/>
<path d="M242.852 374.458C242.852 374.458 275.593 363.131 295.814 378.028C295.814 378.028 275.868 393.03 245.409 384.286L242.852 374.458Z" fill="#AABD54"/>
<path d="M234.801 378.147C234.801 378.147 263.014 358.061 286.626 366.671C286.626 366.671 271.727 386.676 240.027 386.862L234.801 378.147Z" fill="#AABD54"/>
<path d="M232.98 379.35C232.98 379.35 255.551 357.623 278.65 359.041C278.65 359.041 265.194 379.12 237.493 384.509L232.98 379.35Z" fill="#819D2E"/>
<path d="M255.832 384.909C255.832 384.909 283.83 375.92 302.699 386.646C302.699 386.646 283.057 398.003 257.497 391.115L255.832 384.909Z" fill="#819D2E"/>
<path d="M253.922 385.236C253.922 385.236 282.901 386.661 298.134 371.63C298.134 371.63 276.515 365.81 253.922 378.897V385.236Z" fill="#819D2E"/>
<path d="M207.293 393.676V358.959C207.293 348.544 215.753 340.097 226.184 340.097H259.408V412.538H226.184C215.753 412.538 207.293 404.09 207.293 393.676Z" fill="#5571C6"/>
<path d="M380.857 178.752C375.563 182.567 370.478 185.551 366.285 186.665C359.914 182.248 352.755 185.069 349.454 187.986C348.026 189.241 346.383 190.213 345.142 191.252C344.309 191.943 330.065 198.022 332.325 201.199C333.566 202.943 342.056 197.888 344.041 197.235C346.071 201.451 346.071 204.985 349.342 205.727C352.613 206.469 358.427 207.137 362.07 206.707C365.713 206.269 371.355 199.143 375.147 198.319C376.507 198.022 382.232 195.045 389.651 190.911L380.857 178.752Z" fill="#FAC5A3"/>
<path d="M451.878 389.979L450.756 399.28L462.643 400.03L464.703 388.673L451.878 389.979Z" fill="#E6E6E6"/>
<path d="M462.592 400.089C462.592 400.089 451.448 398.085 433.724 399.176C421.361 399.941 420.602 407.549 420.922 411.068C421.011 412.07 421.859 412.835 422.87 412.835L457.395 412.783C459.893 412.783 461.945 410.801 462.042 408.307L462.592 400.089Z" fill="#303E4E"/>
<path d="M687.935 224.789L695.139 218.784L688.188 209.32L681.43 214.954L687.935 224.789Z" fill="#E6E6E6"/>
<path d="M688.285 209.379C688.285 209.379 693.883 219.207 706.083 232.093C714.596 241.082 720.93 236.784 723.428 234.283C724.141 233.57 724.186 232.435 723.539 231.655L701.377 205.23C699.771 203.314 696.939 203.01 694.961 204.539L688.285 209.379Z" fill="#303E4E"/>
<path d="M485.808 71.0302L498.663 93.7815C498.663 93.7815 495.02 104.263 478.359 104.634L468.062 84.9111L485.808 71.0302Z" fill="#FEB78B"/>
<path d="M468.992 92.0594C455.09 92.2153 443.589 80.977 443.44 67.1035L443.158 41.7021C443.002 27.8212 454.257 16.3379 468.152 16.1895C482.055 16.0336 493.556 27.2719 493.704 41.1454L493.987 66.5467C494.143 80.4277 482.895 91.9035 468.992 92.0594Z" fill="#FEB78B"/>
<path d="M453.901 47.3956C453.886 47.3956 453.871 47.3956 453.848 47.3956L449.641 47.21C449.024 47.1803 448.548 46.6607 448.577 46.052C448.607 45.4359 449.113 44.9534 449.737 44.9905L453.945 45.1761C454.562 45.2058 455.038 45.7254 455.008 46.3341C454.986 46.9279 454.488 47.3956 453.901 47.3956Z" fill="#58595B"/>
<path d="M443.945 52.7549C443.752 52.7549 443.566 52.7475 443.373 52.7326C441.544 52.5767 439.893 51.7305 438.704 50.3276C437.522 48.9321 436.949 47.158 437.105 45.3319C437.418 41.5685 440.733 38.7701 444.51 39.0744C448.279 39.3862 451.089 42.7042 450.777 46.4677C450.48 50.0455 447.476 52.7549 443.945 52.7549ZM443.938 41.2864C441.559 41.2864 439.529 43.1125 439.328 45.5249C439.224 46.7572 439.611 47.9523 440.406 48.895C441.202 49.8377 442.324 50.4167 443.558 50.5132C446.094 50.7136 448.346 48.8356 448.554 46.2895C448.77 43.7509 446.867 41.5091 444.324 41.3013C444.198 41.2939 444.071 41.2864 443.938 41.2864Z" fill="#58595B"/>
<path d="M460.368 54.1281C460.175 54.1281 459.982 54.1207 459.789 54.1058C457.96 53.9499 456.309 53.1037 455.12 51.7008C453.938 50.3053 453.365 48.5312 453.521 46.7052C453.834 42.9417 457.149 40.1358 460.926 40.4476C464.695 40.7594 467.505 44.0774 467.193 47.8409C467.037 49.6669 466.19 51.3148 464.784 52.5025C463.535 53.5565 461.989 54.1281 460.368 54.1281ZM460.354 42.6522C457.975 42.6522 455.945 44.4783 455.744 46.8907C455.64 48.1229 456.027 49.318 456.822 50.2607C457.618 51.2035 458.74 51.775 459.974 51.8789C461.209 51.9829 462.405 51.5969 463.35 50.8026C464.294 50.0084 464.866 48.8875 464.97 47.6553C465.186 45.1166 463.283 42.8749 460.74 42.6671C460.614 42.6596 460.48 42.6522 460.354 42.6522Z" fill="#58595B"/>
<path d="M484.984 11.6912C487.72 6.9999 502.396 5.36685 502.388 24.6888C502.381 44.0181 498.611 69.2784 489.296 81.5411C484.181 88.2812 469.127 97.0402 454.451 88.4742C448.496 85.0002 445.359 79.5889 444.481 73.2868C443.604 66.9847 453.276 67.6751 460.763 67.1258C468.242 66.5765 484.88 66.866 488.24 61.1132C491.593 55.3604 492.701 40.8188 490.82 33.626C488.924 26.4406 478.821 22.2615 484.984 11.6912Z" fill="#303E4E"/>
<path d="M490.656 79.5963C501.28 68.447 502.372 44.0107 502.38 24.6887C502.387 5.35938 487.712 6.99986 484.976 11.6912C478.813 22.2614 488.924 26.4406 490.804 33.6334C492.105 38.5993 491.979 47.0615 490.671 53.7199C489.615 59.1015 489.429 64.6242 490.225 70.0504C490.708 73.2571 490.849 75.5285 490.656 79.5963Z" fill="#303E4E"/>
<path d="M495.459 51.8345C494.738 54.6255 492.374 56.4367 490.166 55.8725C487.958 55.3084 486.754 52.5842 487.475 49.7931C488.196 47.0021 490.56 45.1909 492.768 45.7551C494.969 46.3118 496.18 49.036 495.459 51.8345Z" fill="#FEB78B"/>
<path d="M496.523 93.7964C496.523 93.7964 514.529 82.892 520.855 80.4647C527.182 78.0374 566.599 69.2487 578.279 71.1861C589.958 73.1309 637.085 108.271 637.085 108.271L627.606 119.591L575.357 89.7508L533.501 108.605L579.669 195.29L513.547 223.282L480.457 147.093L441.724 169.956L373.907 202.565L367.045 185.915L426.937 151.205L463.908 114.44L475.937 104.649L496.523 93.7964Z" fill="#BECCF5"/>
<path d="M579.676 195.283L619.941 262.557L683.326 213.246L690.738 224.782L626.907 284.871C623.435 288.137 618.149 288.537 614.216 285.836L552.704 243.547L486.411 283.015L465.282 389.979H451.789L457.015 279.585C457.216 275.273 459.06 271.197 462.167 268.191L512.09 219.912L579.676 195.283Z" fill="#5571C6"/>
<path d="M459.492 76.6716C459.492 78.698 457.849 80.3385 455.819 80.3385C453.789 80.3385 452.146 78.698 452.146 76.6716C452.146 74.6451 453.789 73.0046 455.819 73.0046C457.849 73.0046 459.492 74.6525 459.492 76.6716Z" fill="#9CA9B8"/>
<path d="M496.523 89.647C496.523 89.647 502.351 91.3023 505.035 92.9576C508.009 94.7911 507.942 93.93 505.756 99.6234C503.57 105.317 492.701 116.295 492.701 116.295C492.701 116.295 468.064 116.295 476.167 100.685C485.051 99.9203 489.512 96.2089 496.523 89.647Z" fill="#E4ECFF"/>
<path d="M704.166 174.907L702.679 174.818C702.686 174.669 703.497 160.21 698.709 152.631L699.965 151.836C705.013 159.824 704.203 174.291 704.166 174.907Z" fill="#D5D4D9"/>
<path d="M685.513 180.912C685.208 180.482 677.952 170.282 676.012 161.731L677.461 161.405C679.335 169.666 686.65 179.947 686.725 180.051L685.513 180.912Z" fill="#D5D4D9"/>
<path d="M684.621 181.498C684.881 183.176 686.449 184.327 688.13 184.067C689.81 183.807 690.962 182.241 690.702 180.563C690.442 178.886 688.873 177.735 687.193 177.995C685.513 178.255 684.36 179.828 684.621 181.498Z" fill="#524C4E"/>
<path d="M698.841 177.386C699.102 179.064 700.67 180.214 702.35 179.955C704.031 179.695 705.183 178.128 704.923 176.451C704.662 174.773 703.094 173.623 701.414 173.883C699.733 174.142 698.581 175.716 698.841 177.386Z" fill="#524C4E"/>
<path d="M640.654 176.837L642.341 176.006C642.163 175.642 622.38 140.116 642.438 127.044L642.007 125.552C630.067 133.064 630.364 145.022 632.506 156.209C634.111 164.619 640.602 176.748 640.654 176.837Z" fill="black"/>
<path d="M681.446 140.145L683.148 139.344C671.744 119.866 652.645 123.035 652.117 122.931L652.147 124.216C663.388 124.691 675.602 127.756 681.446 140.145Z" fill="black"/>
<path d="M677.341 164.403C676.895 164.403 676.471 164.136 676.3 163.691C676.092 163.156 671.222 150.441 674.984 144.562C677.088 141.281 681.4 138.156 686.292 138.571C690.321 138.913 696.195 141.83 701.265 153.811C701.503 154.375 701.235 155.028 700.67 155.273C700.105 155.511 699.451 155.243 699.206 154.679C695.533 146.009 691.005 141.207 686.099 140.791C682.144 140.457 678.545 143.137 676.858 145.764C674.241 149.854 677.066 159.512 678.374 162.889C678.597 163.461 678.315 164.106 677.735 164.329C677.609 164.381 677.475 164.403 677.341 164.403Z" fill="black"/>
<path d="M637.754 175.798C638.185 178.566 640.78 180.474 643.553 180.044C646.326 179.613 648.237 177.022 647.806 174.254C647.374 171.485 644.78 169.577 642.007 170.008C639.226 170.431 637.323 173.021 637.754 175.798Z" fill="#E3E3E3"/>
<path d="M68.6278 325.867C79.0062 327.686 91.8604 320.092 108.744 321.562C108.744 321.562 124.155 290.779 140.31 284.915C156.458 279.051 161.595 293.711 151.321 299.575C141.046 305.439 119.754 315.698 118.29 328.888C118.29 328.888 121.226 338.419 135.909 337.685C150.592 336.95 174.605 353.585 171.297 360.429C169.022 365.127 164.346 364.511 162.212 363.457C159.818 362.277 161.528 357.088 156.711 356.487C152.339 355.945 143.745 354.312 132.244 362.485C120.743 370.657 109.978 361.861 104.841 360.636C99.7037 359.412 86.2474 373.827 76.4562 375.542C66.6651 377.249 56.3908 391.174 46.1164 397.283C35.8421 403.393 27.575 407.935 18.7132 403.393C12.5947 400.26 16.5126 391.664 21.8951 392.644C27.2776 393.624 40.9792 375.052 44.1612 370.412C47.1201 366.092 40.1763 339.05 60.8291 322.876C62.487 323.997 66.4793 325.489 68.6278 325.867Z" fill="#E9ECF1"/>
<path d="M43.4067 360.136C59.4227 353.512 67.0281 335.179 60.3939 319.187C53.7598 303.196 35.3982 295.602 19.3823 302.226C3.36631 308.85 -4.23913 327.183 2.39503 343.175C9.0292 359.166 27.3907 366.76 43.4067 360.136Z" fill="#E9ECF1"/>
<path d="M60.5227 319.922L47.3266 288.114L41.959 301.654C41.9664 301.661 44.2934 308.008 60.5227 319.922Z" fill="#303E4E"/>
<path d="M31.3885 299.835L8.3418 288.122L11.7468 306.738L31.3885 299.835Z" fill="#303E4E"/>
<path d="M21.3294 345.39C21.322 345.39 21.3071 345.39 21.2997 345.39C18.1995 345.375 15.6198 342.636 15.5529 339.288C15.5454 338.88 15.8725 338.538 16.2814 338.531C16.2889 338.531 16.2889 338.531 16.2963 338.531C16.6978 338.531 17.0323 338.85 17.0397 339.258C17.0918 341.805 19.0024 343.89 21.3071 343.905C21.3145 343.905 21.322 343.905 21.3294 343.905C22.3479 343.905 23.3144 343.49 24.0578 342.74C24.9128 341.879 25.3886 340.639 25.3588 339.34C25.3514 338.932 25.6785 338.59 26.0874 338.583C26.0949 338.583 26.0949 338.583 26.1023 338.583C26.5037 338.583 26.8383 338.902 26.8457 339.31C26.8829 341.01 26.251 342.636 25.1135 343.786C24.0876 344.818 22.7494 345.39 21.3294 345.39Z" fill="#303E4E"/>
<path d="M11.5228 345.338C11.5153 345.338 11.5005 345.338 11.493 345.338C8.39287 345.323 5.81313 342.584 5.74622 339.236C5.73878 338.828 6.0659 338.486 6.47479 338.479C6.48222 338.479 6.48222 338.479 6.48966 338.479C6.89112 338.479 7.22567 338.798 7.2331 339.206C7.28514 341.753 9.19578 343.838 11.5005 343.853C11.5079 343.853 11.5153 343.853 11.5228 343.853C12.5413 343.853 13.5077 343.438 14.2512 342.688C15.1061 341.827 15.5819 340.587 15.5522 339.288C15.5448 338.88 15.8719 338.538 16.2808 338.531C16.2882 338.531 16.2882 338.531 16.2956 338.531C16.6971 338.531 17.0317 338.85 17.0391 339.258C17.0688 340.951 16.4443 342.584 15.3069 343.734C14.2884 344.766 12.9427 345.338 11.5228 345.338Z" fill="#303E4E"/>
<path d="M15.5451 338.946L13.8203 336.979C13.1958 336.267 13.6344 335.109 14.5265 335.116L17.9018 335.131C18.7939 335.139 19.2771 336.304 18.6824 337.009L17.0319 338.954C16.6379 339.422 15.9614 339.414 15.5451 338.946Z" fill="#303E4E"/>
<path d="M31.3874 325.355C31.3874 327.382 29.7444 329.03 27.7074 329.03C25.6703 329.03 24.0273 327.389 24.0273 325.355C24.0273 323.321 25.6703 321.681 27.7074 321.681C29.7444 321.681 31.3874 323.329 31.3874 325.355Z" fill="#5571C6"/>
<path d="M29.6785 325.355C29.6785 326.439 28.7938 327.322 27.7084 327.322C26.623 327.322 25.7383 326.439 25.7383 325.355C25.7383 324.272 26.623 323.388 27.7084 323.388C28.8013 323.388 29.6785 324.272 29.6785 325.355Z" fill="#303E4E"/>
<path d="M10.6237 327.322C10.6237 329.349 8.98073 330.997 6.94371 330.997C4.90668 330.997 3.26367 329.356 3.26367 327.322C3.26367 325.296 4.90668 323.648 6.94371 323.648C8.98073 323.648 10.6237 325.296 10.6237 327.322Z" fill="#5571C6"/>
<path d="M8.91289 327.322C8.91289 328.406 8.0282 329.289 6.94277 329.289C5.85735 329.289 4.97266 328.406 4.97266 327.322C4.97266 326.239 5.85735 325.355 6.94277 325.355C8.0282 325.355 8.91289 326.239 8.91289 327.322Z" fill="#303E4E"/>
<path d="M37.8477 300.503C37.8477 300.503 30.2943 322.668 61.6972 339.34C61.6972 339.34 66.3809 329.208 58.4707 315.958C47.3786 301.238 37.8477 300.503 37.8477 300.503Z" fill="#303E4E"/>
<path d="M37.8479 300.503C37.8479 300.503 13.3292 321.458 5.74609 313.107C5.74609 313.107 13.4853 298.328 34.2348 299.961C35.1492 300.035 37.8479 300.503 37.8479 300.503Z" fill="#303E4E"/>
<path d="M66.6343 325.355C67.6454 327.983 70.7827 335.888 70.4036 340.78C69.4594 353.11 54.1743 355.626 56.6202 361.683C58.3598 365.974 64.917 366.983 67.4298 367.369C76.4849 368.765 83.7037 363.509 88.6476 359.916C94.268 355.826 98.3644 352.85 99.2565 347.758C100.401 341.203 94.8851 328.577 87.1013 323.655C87.1013 323.648 76.3437 327.203 66.6343 325.355Z" fill="#303E4E"/>
<path d="M121.403 333.609C121.403 333.609 111.344 333.498 105.679 340.209C96.0741 351.58 109.33 356.242 115.82 353.058C121.998 350.029 121.812 337.343 133.172 337.7C133.172 337.7 130.652 337.841 127.247 336.452C125.143 335.599 122.935 334.805 121.403 333.609Z" fill="#303E4E"/>
<path d="M152.836 297.801C156.442 296.792 164.694 293.384 165.943 287.884C167.66 280.32 161.936 271.606 147.513 278.494C139.499 282.918 136.837 285.167 133.098 289.072C133.098 289.072 137.484 302.099 152.836 297.801Z" fill="#303E4E"/>
<path d="M113.174 313.634C113.174 313.634 123.575 296.391 129.27 292.42C130.169 291.796 130.824 298.417 130.824 298.417C130.824 298.417 131.017 304.667 130.868 306.738C130.712 308.891 130.006 312.499 127.463 314.659C119.828 321.154 120.884 309.277 109.918 319.313C110.542 317.665 112.029 315.26 113.174 313.634Z" fill="#303E4E"/>
<path opacity="0.3" d="M476.137 135.922L512.09 219.912L522.542 216.104C522.542 216.104 519.784 183.696 492.693 140.145C485.393 128.402 476.137 110.906 476.137 110.906V135.922Z" fill="#EAEFFF"/>
<path opacity="0.3" d="M507.34 101.331C507.34 101.331 534.654 156.105 543.419 169.236C547.419 175.234 558.414 190.77 562.429 196.382C570.793 208.073 615.146 254.555 615.146 254.555L579.677 195.29L536.223 108.984L507.34 101.331Z" fill="#EAEFFF"/>
<path opacity="0.3" d="M522.55 216.104C522.55 216.104 515.561 233.029 504.536 241.936C493.511 250.844 471.066 259.855 471.066 259.855L512.104 219.912L522.55 216.104Z" fill="#8FC4CD"/>
<path d="M457.87 120.437L426.943 151.198L366.285 186.665L373.905 202.557L444.421 171.025L466.1 155.555L457.87 120.437Z" fill="#BECCF5"/>
<path d="M476.16 100.685C476.16 100.685 492.619 177.134 495.058 200.278C498.121 229.414 498.664 254.555 498.664 254.555C498.664 254.555 477.081 261.028 469.751 256.084L457.566 125.24C456.466 121.58 459.492 116.006 462.123 113.274C464.123 111.203 473.907 102.748 476.16 100.685Z" fill="#E4ECFF"/>
<path d="M518.156 81.0363C518.156 81.0363 522.595 79.4404 538.698 75.6621C554.057 72.0546 566.762 71.2603 566.762 71.2603L577.297 70.3844C577.297 70.3844 591.675 76.6271 597.742 80.1604C603.808 83.6863 637.092 108.279 637.092 108.279L627.613 119.599L575.364 89.7583L550.161 101.197L518.156 81.0363Z" fill="#E4EBFF"/>
<path d="M496.521 89.647C496.521 89.647 536.615 140.197 552.138 156.958C565.386 171.27 591.578 192.314 591.578 192.314C591.578 192.314 603.636 185.433 613.725 174.848L543.009 91.2726C543.009 91.2726 530.935 80.398 526.564 79.5889C520.497 78.468 496.521 89.647 496.521 89.647Z" fill="#E4ECFF"/>
<path d="M481.765 22.2911C485.111 20.6209 490.07 15.2096 490.129 11.3645C490.203 6.82164 487.899 2.95428 483.669 1.18762C480.16 -0.282124 474.331 -0.259855 470.755 0.556669C470.755 0.556669 442.103 9.10048 443.701 36.2017C443.701 36.2017 445.374 25.5646 457.596 23.0556C470.026 20.5096 471.811 27.2644 481.765 22.2911Z" fill="#303E4E"/>
</g>
<defs>
<clipPath id="clip0_2960_49572">
<rect width="724" height="414" fill="white"/>
</clipPath>
</defs>
</svg>


  );
}
