export const INIT_VALUES = {
    person_filling: '',
    first_name: '',
    last_name: '',
    preferred_name: '',
    dob: '',
    mobile: '',
    email: '',
    gender: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
}

export const errorMessages = {
    firstName: 'Please enter valid First Name',
    lastName: 'Please enter valid Last Name',
    dob: 'Please select Date of Birth',
    gender: 'Please choose Gender.',
    phoneNumber: 'Please enter Cellphone Number',
    validPhoneNumber: 'Please enter valid Cellphone Number.',
    emailId: 'Please enter email address.',
    validEmailId: 'Please enter valid Email Address',
    address_line_1: 'Please enter Address Line 1',
    validPreferredName: 'Please enter a valid preferred name.',
    city: 'Please enter valid city.',
    state: 'Please enter valid state.',
    zip_code: 'Please enter valid zip code.',
}
