import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import { createDecipheriv } from 'crypto'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useHistory, useLocation } from 'react-router-dom'
import IconClock from '../../assests/icons/IconClock.js'
import Tms2uLogo from '../../assests/icons/Tms2uLogo.js'
import AvatarDefault from '../../assests/images/avatar.svg'
import bgImageMobile from '../../assests/images/Bg_waiting_room_mobile.png'
import bgImage from '../../assests/images/waiting_room_bg.jpg'
import AvatarJoinRoomIcon from './icons/AvatarJoinRoomIcon'
import { config } from '../../constants'
import LoadingPage from '../../utilities/loading-page'
import ServicesSlider from '../ServicesSlider/index.js'
import ServicesSliderMobile from '../ServicesSliderMobile/index.js'
import ToggleAudioButton from './components/Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from './components/Buttons/ToggleVideoButton/ToggleVideoButton'
import useChatContext from './hooks/useChatContext/useChatContext'
import useProviderContext from './hooks/useProviderContext/useProviderContext'
import useRoomState from './hooks/useRoomState/useRoomState'
import useVideoContext from './hooks/useVideoContext/useVideoContext'
import { StateContext, useAppState } from './state'
import { GridCheckCircleIcon } from '@mui/x-data-grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import IconArrowRight from './icons/arrow-right.js'
import AlertConfirmHomeStateDialog from '../shared/Elements/AlertConfirmHomeStateDialog.js'
import AlertConfirmJoin from './AlertConfirmJoin.jsx'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',

        '& .MuiTypography-subtitle1': {
            fontSize: '16px',
            lineHeight: '22.4px',
            fontWeight: '500',
            color: '#132044',
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '13px',
            lineHeight: '18.2px',
            fontWeight: '400',
            color: '#667689',
        },

        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${bgImageMobile})`,
        },
    },
    logo: {
        width: '111.94px',
        height: '50px',
        position: 'absolute',
        top: 40,
        left: 50,
    },
    avartar: {
        width: '273px',
        height: '265px',
        marginTop: '60px',
        borderRadius: 150,
        overflow: 'hidden',
        borderWidth: 3,
        [theme.breakpoints.down('sm')]: {
            width: '176px',
            marginTop: '40px',
            height: '171px',
        },
    },
    info_column: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 20px',
    },
    name: {
        marginTop: '16px',
        fontSize: '32px',
        lineHeight: '44.8px',
        color: '#132044',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        textAlign: 'center',
    },
    user_info: {
        width: 'fit-content',
        marginTop: '16px',
        marginBottom: '16px',
        paddingBottom: '16px',
        display: 'block',
        // paddingLeft: '50px',
        minWidth: '500px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
        },
    },
    waiting_time: {
        width: 'fit-content',
        margin: '0 auto',
    },
    call_duration: {
        margin: '0 auto',
    },
    time: {
        color: '#14AE5C',
        marginLeft: 4,
    },
    buttons: {
        margin: '0px 6px',
        padding: '0px',
        background: 'rgba(255, 255, 255, 0.6)',

        display: 'flex',
        alignItems: 'center',
        width: '47px',
        height: '47px',
        minWidth: '47px',
        borderRadius: '12px',
        '&:hover': {
            background: 'rgba(34,43,55,0.5) !important',
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '24px',
            width: '65px',
            height: '65px',
            minWidth: '64px',
        },
    },
    btn_group: {
        display: 'flex',
        justifyContent: 'center',
        // marginTop: '32px',
        width: 'fit-content',
        background: 'rgba(255, 255, 255, 0.3)',
        borderRadius: 30,
        padding: 10,
        margin: '32px auto',
        marginBottom: 0,
    },
    icon_clock: {
        width: 30,
    },
    info_table_header: {
        fontSize: '13px',
        lineHeight: '18.2px',
        fontWeight: 400,
        color: '#667689',
        marginBottom: 5,
    },
    info_table_content: {
        fontSize: '16px',
        lineHeight: '22.4px',
        fontWeight: 500,
        color: '#132044',
    },

    des_time: {
        margin: '0 8px',
        color: '#303E4E',
        [theme.breakpoints.down('sm')]: {
            width: 225,
        },
    },
    border: {
        borderRight: '1px solid #ffffff4d',
    },
    boxService: {
        position: 'relative',
    },
    avatar_crop: {
        position: 'relative',
        // marginTop: 70,
    },
    avatar_crop_icon: {
        position: 'absolute',
        left: 32,
        top: 39,
        width: 200,
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            left: 22,
            top: 30,
            width: 150,
            height: 150,
        },
    },
    radio: {
        '&$checked': {
            color: '#546FC9',
        },
        '&$disabled': {
            color: '#ccc',
        },
    },
    checked: {},
    statusRoomMobile: {
        // width: '111.94px',
        height: '50px',
        position: 'absolute',
        top: 40,
        right: 0,
        left: 0,
        justifyContent: 'center',
    },
    statusRoom: {
        // width: '111.94px',
        height: '50px',
        position: 'absolute',
        top: 40,
        right: 50,
    },
    IconArrowRight: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 5,
    },
    formControlLabel: {
        marginRight: 5,
    },
    formControlText: {
        fontSize: 13,
    },
    wrapContent: {
        alignItems: 'center',
    },
}))

function JoinMainRoom() {
    const classes = useStyles()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [openHomeStateConfirm, setOpenHomeStateConfirm] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [modalMessage, setModalMessage] = useState<string>('')
    const [waitingTime, setWaitingTime] = useState<string>('')
    const [roomName, setRoomName] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [socketUrl, setSocketUrl] = useState<string>('')
    const location = useLocation()
    const roomState = useRoomState()
    const isReconnecting = roomState === 'reconnecting'
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const { keywordPopulus } = useContext(StateContext)

    function useQuery() {
        const { search } = useLocation()

        return React.useMemo(() => new URLSearchParams(search), [search])
    }
    let query = useQuery()
    const data = localStorage.getItem('urlLink')

    const [isTenMinPrior, setIsTenMinPrior] = useState(true)
    const [alertConfirmJoinOpen, setAlertConfirmJoinOpen] = useState(true)

    const [userInfor, setUserInfor] = useState<{
        avartar: string
        name: string
        startTime: string
        endTime: string
        duaration: string
        date: string
    }>({
        avartar: '',
        name: '',
        startTime: '',
        endTime: '',
        duaration: '',
        date: '',
    })
    // const [statusPreExam, setStatusPreExam] = useState<boolean>(false);
    // const [statusExam, setStatusExam] = useState<boolean>(false);
    // const [statusPostExam, setStatusPostExam] = useState<boolean>(false);
    const { user, getToken, isFetching } = useAppState()
    const [foceUpdate, setFoceUpdate] = useState(0)
    const {
        connect: videoConnect,
        isAcquiringLocalTracks,
        isConnecting,
        localTracks,
    } = useVideoContext()
    const { connect: chatConnect, conversation } = useChatContext()
    const {
        socket,
        setAccessAdmit,
        setCurrentUser,
        setListWaiting,
        setCountWaitingUser,
        setAppointmentInfo,
        setupcoming,
        setcurrentAppointment,
    } = useProviderContext()
    let history = useHistory()

    useEffect(() => {
        if (location?.state?.infoRoom) {
            converDataForDisplayDisplay(location.state.infoRoom)
            setAppointmentInfo(location?.state?.infoRoom)

            const targetDatetime = new Date(location?.state?.infoRoom?.start)
            targetDatetime.setMinutes(targetDatetime.getMinutes() - 10)

            const currentTime = new Date()

            console.log(targetDatetime)
            console.log(currentTime)

            setIsTenMinPrior(currentTime >= targetDatetime)
        }
    }, [location])

    useEffect(() => {
        setOpenHomeStateConfirm(true)
    }, [])

    // useEffect(() => {
    //     if (window.performance) {
    //         console.log('performance', performance)
    //         if (performance.navigation.type == 1 && foceUpdate === 0) {
    //             setFoceUpdate(foceUpdate + 1)

    //             history.push(`/video`)
    //         }
    //     }
    //     return () => {
    //         setFoceUpdate(0)
    //     }
    // }, [foceUpdate])

    const modalClose = () => {
        setIsModalOpen(false)
        window.location.href = '/'
    }

    const AlertModal = () => {
        return (
            <Dialog open={isModalOpen} onClose={() => modalClose()}>
                <Alert variant="outlined" severity="info">
                    <DialogTitle id="alert-modal-title">
                        Appointment information is incorrect. Check it again.
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => modalClose()} color="primary">
                            Okay
                        </Button>
                    </DialogActions>
                </Alert>
            </Dialog>
        )
    }

    const decrypt = (encryptediv: any, encryptedData: any) => {
        try {
            if (config.KEY) {
                let iv = Buffer.from(encryptediv, 'hex')
                let encryptedText = Buffer.from(encryptedData, 'hex')
                let decipher = createDecipheriv('aes-256-cbc', Buffer.from(config.KEY), iv)
                let decrypted = decipher.update(encryptedText)
                decrypted = Buffer.concat([decrypted, decipher.final()])
                return decrypted.toString()
            }
        } catch (e) {
            console.log('error', e)
            return ''
        }
    }

    // console.log(location,'log Location in Join')

    const converDataForDisplayDisplay = (infoRoom: any) => {
        const name = infoRoom?.provider_first_name
            ? `${infoRoom.provider_first_name} ${infoRoom.provider_last_name}`
            : `${infoRoom.patient_first_name} ${infoRoom.patient_last_name}`
        const startTime = moment(infoRoom.start).format('h:mm A')
        const endTime = moment(infoRoom.end).format('h:mm A')
        const duaration = moment
            .duration(moment(infoRoom.end).diff(moment(infoRoom.start)))
            .asMinutes()
            .toFixed()

        setTitle(infoRoom.type)
        setUserInfor((pre) => {
            return {
                ...pre,
                name,
                startTime: startTime,
                endTime: endTime,
                duaration,
                date: infoRoom.start,
            }
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.root}>
            {!isMobile && (
                <Box className={classes.logo}>
                    <Tms2uLogo></Tms2uLogo>
                </Box>
            )}
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    className={classes.wrapContent}>
                    <div className={classes.avatar_crop}>
                        <AvatarJoinRoomIcon
                            width={isMobile ? 200 : 273}
                            height={isMobile ? 200 : 265}
                        />
                        <img
                            className={classes.avatar_crop_icon}
                            src={AvatarDefault}
                            alt="TeleMed2U"
                        />
                        <Typography className={classes.name}>{userInfor.name}</Typography>
                    </div>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.user_info}>
                        <p>You're here early!</p>
                        Your encounter will begin in 10 minutes prior to your appointment.
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            className={classes.call_duration}>
                            <Box className={clsx(classes.info_column, classes.border)}>
                                <Typography className={classes.info_table_header}>
                                    Call duration
                                </Typography>
                                <Typography className={classes.info_table_content}>
                                    {`${userInfor.duaration} Minutes`}
                                </Typography>
                            </Box>
                            <Box className={classes.info_column}>
                                <Typography className={classes.info_table_header}>
                                    Start time - End time
                                </Typography>
                                <Typography className={classes.info_table_content}>
                                    {`${userInfor.startTime} - ${userInfor.endTime}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            className={classes.waiting_time}>
                            {location?.state?.infoRoom?.type === 'patient' ? (
                                <>
                                    <IconClock
                                        width={isMobile ? 30 : 21}
                                        height={isMobile ? 30 : 21}
                                    />
                                    <Typography className={classes.des_time}>
                                        {`Waiting for your physician to start this
                  meeting -`}
                                        {!!userInfor.startTime && (
                                            <Countdown
                                                className={classes.time}
                                                date={moment(userInfor.date).valueOf()}
                                                daysInHours={true}
                                            />
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <Typography className={classes.des_time}>
                                    Please wait the meeting host will let you in soon
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            className={classes.btn_group}>
                            <ToggleAudioButton
                                className={classes.buttons}
                                disabled={isReconnecting}
                                hideLabel
                            />
                            <ToggleVideoButton
                                className={classes.buttons}
                                disabled={isReconnecting}
                                hideLabel
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/* {isMobile ? <ServicesSliderMobile /> : <ServicesSlider keywords={keywordPopulus} />} */}
            </Grid>
            <AlertModal />
            {loading && <LoadingPage />}
            {isTenMinPrior ? (
                <AlertConfirmJoin
                    open={alertConfirmJoinOpen}
                    isMobile={isMobile}
                    userInfor={userInfor}
                    handleClose={setAlertConfirmJoinOpen}
                />
            ) : null}
        </Grid>
    )
}

export default JoinMainRoom
