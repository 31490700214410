import React, { useEffect, useMemo, useState } from 'react'
import './DashboardHome.scss'
import { Card } from '@material-ui/core'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { nameAvatar } from '../utils'
import { getProfilePic } from '../../services/Login/login'

const MemberProfile = () => {
    const loggedinPatientdata = useSelector((state) => state?.login?.userData)

    const memorizing = useMemo(() => loggedinPatientdata, [loggedinPatientdata])
    const [profilePic, setProfilePic] = useState(null)

    useEffect(async () => {
        if (loggedinPatientdata?.avatar) {
            const res = await getProfilePic({ image_name: loggedinPatientdata?.avatar })
            setProfilePic(res?.data?.data?.url)
        }
    }, [loggedinPatientdata?.avatar])

    return (
        <div className="member_profile">
            <Card className="member_profileCard">
                <span className="member_Text">Member Profile</span>
                <div className="members_data">
                    <div className="profile_img">
                        {loggedinPatientdata?.avatar ? (
                            <img
                                style={{
                                    borderRadius: '80px',
                                    border: '2px solid #D8D8D8',
                                }}
                                src={profilePic}
                                height={63}
                                width={60}
                            />
                        ) : (
                            nameAvatar(
                                `${loggedinPatientdata?.first_name} ${loggedinPatientdata?.last_name}`,
                                '63px',
                                '60px',
                            )
                        )}
                        {/* <svg
                            width="60"
                            height="63"
                            viewBox="0 0 70 73"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="34.9064" cy="36.5" rx="34.763" ry="36.5" fill="#D9D9D9" />
                        </svg> */}
                    </div>
                    <div className="patient_detail">
                        <div className="patient_data">
                            <span id="p_Head">
                                {memorizing?.first_name} {memorizing?.last_name}
                            </span>
                            <span id="p_value" style={{ marginTop: '15px' }}>
                                Medical Record Number: {memorizing?.id}
                            </span>
                        </div>
                        <div className="patient_data" style={{ marginLeft: '50px' }}>
                            <span id="p_value">
                                {' '}
                                {moment(memorizing?.dob).format('MM/DD/YYYY')}
                            </span>
                            <span id="p_value" style={{ marginTop: '15px' }}>
                                {memorizing?.phone_number}
                            </span>
                        </div>
                        <div className="patient_data" style={{ marginLeft: '50px' }}>
                            <span id="p_value">
                                Insurance -{' '}
                                {memorizing?.insurance?.insurance?.plan?.display || 'N/A'}{' '}
                            </span>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default MemberProfile
