import { Button, Grid, makeStyles, styled, Typography } from '@material-ui/core'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import urlLoginVideoScreen from '../../assests/images/loginVideoScreen_bg.png'
import appLogo from '../../assests/Logos/TM2U_Logo_250px_Width.svg'
import { getVideoChatToken } from '../../services/Provider/provider'
import validateLink from './apis/validateLink'
import useProviderContext from './hooks/useProviderContext/useProviderContext'
import useVideoContext from './hooks/useVideoContext/useVideoContext'
const { runPreflight } = require('twilio-video')
import { StateContext, useAppState } from './state'
import { CameraTest } from './components/CameraTest/CameraTest'
interface DecryptedData {
    firstName: string
    lastName: string
    isProvider: boolean
    roomId: string
    patient: string
}

function useQuery() {
    return new URLSearchParams(useLocation().search)
}
const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: `#889BD7`,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: '30px',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: 'auto !important',
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto !important',
        },
    },
    appLogo: {
        width: 250,
        [theme.breakpoints.between('xs', 'sm')]: {
            width: 150,
        },
    },
    button: {
        background: '#5571c6',
        color: 'white',
        fontSize: 15,
        fontWeight: 400,
    },
    logoContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'center',
        },
    },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    width: 350,
    marginTop: 32,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'gray',
        borderRadius: 5,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}))

export default function LoginVideoScreen(props: any) {
    let query = useQuery()

    let iv = query.get('iv')
    let linkJoin = query.get('data')
    let linkJoinLocalStorage = localStorage.getItem('urlLink')
    const dataEncode = encodeURIComponent(linkJoin)
    // let {iv, data}:{iv:any, data:any} = useParams();

    const { keywordPopulus, setKeywordPopulus } = useContext(StateContext)

    const { user, getToken, isFetching } = useAppState()
    const {
        room,
        getAudioAndVideoTracks,
        connect: videoConnect,
        isAcquiringLocalTracks,
        isConnecting,
        localTracks,
    } = useVideoContext()
    const { socket, setAccessAdmit, setCurrentUser, setListWaiting, setCountWaitingUser } =
        useProviderContext()
    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(true)
    const [isProvider, setIsProvider] = useState<boolean>(false)
    const [name, setName] = useState<string>(user?.displayName || '')
    const [roomName, setRoomName] = useState<string>('')
    const [userId, setUserId] = useState<string>('')
    const [roomId, setRoomId] = useState<string>('')
    const [mediaError, setMediaError] = useState<boolean>(false)

    const [openLoadingTypo, setopenLoadingTypo] = useState<boolean>(false)

    let history = useHistory()
    const [progress, setProgress] = React.useState(10)
    const handleClick = async (data: any) => {
        try {
            // get audio and video permissions then stop the tracks
            await navigator.mediaDevices
                .getUserMedia({ audio: true, video: true })
                .then(async (mediaStream) => {
                    mediaStream.getTracks().forEach((track) => {
                        track.stop()
                    })
                })
            // The devicechange event is not fired after permissions are granted, so we fire it
            // ourselves to update the useDevices hook. The 100 ms delay is needed so that device labels are available
            // when the useDevices hook updates.
            getValidateLink(data)
        } catch (error) {
            if (error instanceof Error) setMediaError(true)
        }
    }
    const openLoading = () => {
        let data = ''
        if (linkJoin !== linkJoinLocalStorage) {
            data = linkJoin
        } else {
            data = linkJoinLocalStorage
        }
        handleClick(data)
        // getValidateLink(data)
    }
    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5))
        }, 500)
        return () => {
            clearInterval(timer)
        }
    }, [])

    // useEffect(() => {
    //     if (progress === 100) {
    //         history.push(`/join-wait?data=${linkJoin}`)
    //     }
    // }, [progress])

    useEffect(() => {
        if (!mediaError) {
            getAudioAndVideoTracks().catch((error) => {
                console.log('Error acquiring local media:')
                console.dir(error)
                setMediaError(true)
            })
        }
    }, [getAudioAndVideoTracks, mediaError])

    const getValidateLink = async (data) => {
        try {
            setopenLoadingTypo(true)
            const res = await validateLink({ data })
            const newToken = await getVideoChatToken(res?.data?.validateLink?.encounter_id)
            const newData = res.data?.validateLink?.twillio_token
                ? res.data.validateLink
                : {
                      ...res.data.validateLink,
                      twillio_token: newToken?.data?.twilio_token,
                  }
            if (res?.data && Object.keys(res.data).length === 0) {
                history.push('/invalid-link')
            } else {
                const keywordPopulusSt = []
                const ifSpecialty = res.data?.validateLink?.specialty
                if (ifSpecialty) {
                    if (ifSpecialty.specialty) {
                        keywordPopulusSt.push(res.data.validateLink?.specialty?.specialty)
                    }
                    if (!!ifSpecialty?.conditions_treated.length) {
                        ifSpecialty.conditions_treated.forEach((element) => {
                            keywordPopulusSt.push(element.code)
                        })
                    }
                }

                if (keywordPopulusSt.length > 0) {
                    setKeywordPopulus(keywordPopulusSt.join(','))
                }

                const targetDatetime = new Date(res.data.validateLink.start)
                // targetDatetime.setMinutes(targetDatetime.getMinutes() - 10)

                const currentTime = new Date()

                console.log(targetDatetime)
                console.log(currentTime)

                console.log('Is time allowed?')
                console.log(currentTime >= targetDatetime)

                // Compare the current time to the start time
                if (currentTime >= targetDatetime) {
                    // Allow the user to access the link
                    console.log('going to main room as 10 mins done')
                    history.push(`/join?data=${encodeURIComponent(data)}`, {
                        infoRoom: newData,
                    })
                } else {
                    // Deny the user access to the link
                    console.log('in wait room before 10 mins')

                    history.push(`/join-wait?data=${encodeURIComponent(data)}`, {
                        infoRoom: newData,
                    })
                }
            }
        } catch (error) {
            history.push('/invalid-link', { message: error?.response?.data?.message })
        } finally {
            setopenLoadingTypo(false)
        }
    }
    // useEffect(() => {
    //     let data = ''
    //     if (linkJoin !== linkJoinLocalStorage) {
    //         data = linkJoin
    //     } else {
    //         data = linkJoinLocalStorage
    //     }
    //     // run a preflight test, passing in an Access Token with
    //     // a VideoGrant
    //     const preflightTest = runPreflight(data)
    //     console.log('preflightTest')
    //     console.log(preflightTest)

    //     // preflightTest.on('completed', (report) => {
    //     //     etValidateLink(data)
    //     // });
    //     getValidateLink(data)

    //     // validateLink({ data })
    //     //     .then((res) => {
    //     //         if (res?.data && Object.keys(res.data).length === 0) {
    //     //             history.push('/invalid-link')
    //     //         } else {
    //     //             history.push(`/join?data=${encodeURIComponent(data)}`, {
    //     //                 infoRoom: res.data.validateLink,
    //     //             })
    //     //             const keywordPopulusSt = []
    //     //             const ifSpecialty = res.data?.validateLink?.specialty
    //     //             if (ifSpecialty) {
    //     //                 if (ifSpecialty.specialty) {
    //     //                     keywordPopulusSt.push(res.data.validateLink?.specialty?.specialty)
    //     //                 }
    //     //                 if (!!ifSpecialty?.conditions_treated.length) {
    //     //                     ifSpecialty.conditions_treated.forEach((element) => {
    //     //                         keywordPopulusSt.push(element.code)
    //     //                     })
    //     //                 }
    //     //             }
    //     //             if (keywordPopulusSt.length > 0) {
    //     //                 // setKeywordPopulus(keywordPopulusSt.join(','))
    //     //                 setKeywordPopulus('zip12222222')
    //     //                 console.log(keywordPopulusSt, 'log keywordPopulus')
    //     //             }
    //     //         }
    //     //     })
    //     //     .catch((err) => {
    //     //         history.push('/invalid-link', { message: err?.response?.data?.message })
    //     //     })
    // }, [])

    return (
        <>
            <Grid
                container
                item
                alignItems="center"
                direction="column"
                className={classes.container}>
                <div className={classes.logoContainer}>
                    <img className={classes.appLogo} src={appLogo} alt="TeleMed2U" />
                </div>
                <>
                    {!openLoadingTypo && (
                        <CameraTest openLoading={openLoading} mediaError={mediaError} />
                    )}
                    {openLoadingTypo && (
                        <div style={{ height: '100vh' }}>
                            <Typography
                                variant="body2"
                                style={{ color: 'white', fontSize: '19px', marginTop: 80 }}>
                                Connecting with your care provider ...
                            </Typography>
                            <BorderLinearProgress variant="determinate" value={progress} />
                            <Typography style={{ fontSize: 14, color: 'white', marginTop: 32 }}>
                                {progress} %
                            </Typography>
                        </div>
                    )}
                </>
            </Grid>
        </>
    )
}
