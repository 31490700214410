import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Typography } from '@material-ui/core'
import doctor_img from '../../../../assests/images/left-img.svg'

const AppointmentLoadingPage = ({ name, value, setValue }) => {
    return (
        <div className="left-side-portion" style={{ minHeight: '620px' }}>
            <div className="doctor_care">
                <img src={doctor_img} alt="doc-img" />
            </div>
            <div className="middle_portion_loader">
                <div>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                        <CircularProgress />
                    </Box>
                    <Typography
                        className="text_holder"
                        style={{
                            color: '#303E4E',
                            fontFamily: 'Rubik',
                            fontSize: '30px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '24px',
                        }}>
                        Hang tight, confirming your selection is available.
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default AppointmentLoadingPage
