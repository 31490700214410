import {
    FormHelperText,
    makeStyles,
    Theme,
} from '@material-ui/core'
import bgImage from '../../../assets/bgImage.jpg'

export const deviceSelectionScreenStyles = makeStyles(
    (theme: Theme) => ({
        clickHereBtn: {
            cursor: 'pointer',
            color: '#1675e0',
        },
        background: {
            flex: 1,
            alignItems: 'stretch',
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100% !important',
            backgroundPosition: 'center',
            [theme.breakpoints.down('sm')]: {
                minHeight: '780px !important',
                position: 'relative',
            },
        },
        gutterBottom: {
            marginBottom: '1em',
        },
        marginTop: {
            marginTop: '1em',
        },
        deviceButton: {
            width: '100%',
            border: '2px solid #aaa',
            margin: '1em 0',
        },
        localPreviewContainer: {
            paddingRight: '2em',
            [theme.breakpoints.down('sm')]: {
                padding: '0 1.5em',
                marginTop: '6em',
            },
        },
        bodyContainer: {
            margin: 'auto',
            alignItems: 'center',
            fontFamily: 'Rubik',
        },
        meetingContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0% 25%',
            [theme.breakpoints.down('sm')]: {
                margin: '0 18%',
            },
        },
        startTime: {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '2% 5% 0% 5%',
        },
        contentStyling: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        doctorNameStyling: {
            fontSize: '1.6rem',
            color: 'white',
            margin: '0px 5px',
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
            },
            '&:hover': {
                color: 'white',
            },
        },
        content: {
            fontSize: '28px',
            color: 'white',
            marginBottom: 0,
            margin: '10px 15%',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                margin: '10px 0',
                fontSize: '24px',
            },
            '&:hover': {
                color: 'white',
            },
        },
        captionStyling: {
            fontSize: '1rem',
            color: '#fff',
            margin: '0px 5px',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
            },
            '&:hover': {
                color: 'white',
            },
        },
        buttonsContainer: {
            position: 'absolute',
            width: '100%',
            bottom: '3rem',
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '2rem',

            [theme.breakpoints.down('sm')]: {
                // position: "static",
                // marginBottom: "3rem",
                bottom: '0',
                background: '#13191f',
                padding: '1em 0',
                borderRadius: '15px 15px 0 0',
            },
        },
        // waitButtons:{
        //   minWidth: '50px',
        //   height: '40px',
        //   background: "rgba(0, 0, 0, 0.2)",
        //   width: '50px',
        //   borderRadius: '50%',
        //   margin: '0px 10px'
        // },
        startIcon: {
            margin: '0 !important',
        },
        audioVideoTestBtnContainer: {
            position: 'absolute',
            bottom: '3rem',
            right: '3rem',
            zIndex: 110,
            display: 'flex',
            positionLeft: '0.5rem',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                // margin: "auto",
                // marginBottom: "3rem",
                position: 'relative',
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'flex-end',
                width: '100%',
                top: '55%',
                right: 0,
                // marginTop:'14em'
            },
        },

        iconContainer: {
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '1rem',
            cursor: 'pointer',
        },

        joinButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                width: '100%',
                '& button': {
                    margin: '0.5em 0',
                },
            },
        },
        mobileButtonBar: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '1.5em 0 1em',
            },
        },
        buttons: {
            margin: '0 1rem',
            padding: '0.8em 0',
            background: 'rgba(0, 0, 0, 0.2)',
            width: '40px',
            height: '40px',
            minWidth: '50px',
            borderRadius: '2rem',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.7rem',
            [theme.breakpoints.down('sm')]: {
                background:
                    'rgba(255, 255, 255, 0.2) !important',
            },
        },
        testAudiVideoBtn: {
            borderColor: '#fff',
            color: '#fff',
            borderRadius: '1.4rem',
            padding: '0.4rem 2rem',
            [theme.breakpoints.down('sm')]: {
                padding: '0',
                width: 'auto',
                border: 'none',
            },
        },
        settingIconSize: {
            fontSize: '2rem',
        },
        disconnectButton: {
            margin: '0 1rem',
            padding: '0.8em 0',
            background: '#da3026',
            width: '40px',
            height: '40px',
            minWidth: '50px',
            borderRadius: '2rem',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.7rem',
        },
        timer: {
            fontSize: '48px',
            fontWeight: 'bold',
            marginTop: 0,
            color: 'white',
            marginBottom: 0,
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
            },
            '&:hover': {
                color: 'white',
            },
        },
        textOrange: {
            color: 'orange',

            '&:hover': {
                color: 'orange',
            },
        },
        durationInfoContainer: {
            color: 'white',
            display: 'flex',
            padding: '20px',
            background: 'rgba(19,25,31,0.2)',
            flexDirection: 'column',
            borderRadius: '0.4rem',

            [theme.breakpoints.down('sm')]: {
                // margin: "auto",
                padding: '12px',
                marginBottom: '3rem',
                position: 'static',
                display: 'flex',
                alignSelf: 'center',
            },
        },

        logoContainer: {
            height: '65px',
            padding: '20px',
            boxSizing: 'content-box',
            background: 'rgba(19,25,31,0.2)',
            marginRight: '4em',
            borderRadius: '0.4rem',
        },
        textStyling: {
            color: 'white',
            margin: 0,
            fontFamily: 'Rubik',
            fontSize: '22px',
            lineHeight: '29px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
            '&:hover': {
                color: 'white',
            },
        },
        miniText: {
            fontSize: '0.8rem',
            color: 'white',
            textAlign: 'left',
            fontWeight: 300,
            margin: '0',

            '&:hover': {
                color: 'white',
            },
        },
        timerMiniText: {
            margin: '0',
            display: 'flex',
            justifyContent: 'space-around',
        },
        icons: {
            display: 'inline',
            bottom: '3.5rem',
            position: 'absolute',
            left: '8rem',
        },
        close: {
            marginLeft: '95%',
        },
        renderaction: {
            marginTop: '1%',
            display: 'flex',
            marginBottom: '4%',
            width: '100%',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            //alignItems: "baseline",
        },
        iconswifi: {
            position: 'absolute',
            left: 70,
            [theme.breakpoints.down('sm')]: {
                bottom: '-14%',
                left: '8%',
            },
        },
        helpPopper: {
            // marginLeft: "2%",
        },
        popperArrow: {
            position: 'absolute',
            width: 0,
            height: 0,
            bottom: '-5%',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid #fff',
        },
        profileImgWrap: {
            borderRadius: '50%',
            width: 130,
            height: 130,
            [theme.breakpoints.down('sm')]: {
                width: 90,
                height: 90,
            },
        },
    }),
)
