import React from 'react';

export default function TeleMicIcon() {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0002 20.8714C12.6888 20.8714 9.9967 18.1793 9.9967 14.8679V7.91656C9.9967 4.60517 12.6888 1.91309 16.0002 1.91309C19.3116 1.91309 22.0036 4.60517 22.0036 7.91656V14.8679C22.0036 18.1793 19.3116 20.8714 16.0002 20.8714ZM16.0002 3.80892C13.7378 3.80892 11.8925 5.6542 11.8925 7.91656V14.8679C11.8925 17.1303 13.7378 18.9756 16.0002 18.9756C18.2625 18.9756 20.1078 17.1303 20.1078 14.8679V7.91656C20.1078 5.6542 18.2625 3.80892 16.0002 3.80892Z" fill="#303E4E"/>
<path d="M16.0002 25.295C10.1484 25.295 5.38354 20.5301 5.38354 14.6783V12.5297C5.38354 12.0115 5.81327 11.5818 6.33146 11.5818C6.84966 11.5818 7.27938 12.0115 7.27938 12.5297V14.6783C7.27938 19.4811 11.1974 23.3991 16.0002 23.3991C20.803 23.3991 24.721 19.4811 24.721 14.6783V12.5297C24.721 12.0115 25.1508 11.5818 25.669 11.5818C26.1872 11.5818 26.6169 12.0115 26.6169 12.5297V14.6783C26.6169 20.5301 21.852 25.295 16.0002 25.295Z" fill="#292D32"/>
<path d="M17.757 9.40793C17.6559 9.40793 17.5421 9.3953 17.4284 9.35738C16.5058 9.01613 15.4946 9.01613 14.572 9.35738C14.0791 9.53432 13.5356 9.28155 13.3587 8.78863C13.1817 8.29571 13.4345 7.75224 13.9274 7.5753C15.2671 7.09502 16.7459 7.09502 18.0856 7.5753C18.5785 7.75224 18.8313 8.29571 18.6544 8.78863C18.5027 9.1678 18.1362 9.40793 17.757 9.40793Z" fill="#292D32"/>
<path d="M17.0112 12.0874C16.9227 12.0874 16.8469 12.0747 16.7584 12.0495C16.2529 11.9104 15.7347 11.9104 15.2291 12.0495C14.7236 12.1885 14.2054 11.8852 14.0664 11.3796C13.9273 10.8867 14.2307 10.3685 14.7362 10.2295C15.5577 10.002 16.4425 10.002 17.264 10.2295C17.7696 10.3685 18.0729 10.8867 17.9339 11.3922C17.8201 11.8093 17.4283 12.0874 17.0112 12.0874Z" fill="#292D32"/>
<path d="M16.0002 29.0867C15.482 29.0867 15.0522 28.6569 15.0522 28.1388V24.3471C15.0522 23.8289 15.482 23.3992 16.0002 23.3992C16.5184 23.3992 16.9481 23.8289 16.9481 24.3471V28.1388C16.9481 28.6569 16.5184 29.0867 16.0002 29.0867Z" fill="#292D32"/>
</svg>

  );
}
