import React, { useEffect, useState } from 'react'
let { config } = require('../../../constants')
import axios from 'axios'
import { Button } from '@material-ui/core'

const consulationApp = (props) => {
    return (
        <React.Fragment>
            <a href="https://telesonora.page.link/v4aVM7DwVXvxJrF66">
                {' '}
                <Button
                    id="auto-book-apt-btn"
                    className="new-apt-btn"
                    style={{ marginTop: '10px' }}>
                    Start Consulatation
                </Button>
            </a>
        </React.Fragment>
    )
}

export default consulationApp
