import {
    sortAscending,
    sortDescending,
} from '../../shared/utils'
import * as actionTypes from './actionType'

export const initialState = {
    clinicAdmins: {
        data: [],
        isLoading: false,
        isLoaded: false,
        isError: false,
        totalCount: 0,
        sort: {
            full_name: 'asc',
            mob: 'asc',
            email: 'asc',
        },
    },
    searchString: '',
    pagination: {
        page: 1,
        limit: 10,
    },
    isLoadingAdminDetails: false,
    clinicAdminDetails: {},
}

export function reducer(state, action) {
    switch (action.type) {
        case actionTypes.CLINIC_ADMIN_LOADING:
            return {
                ...state,
                clinicAdmins: {
                    ...state.clinicAdmins,
                    isLoading: true,
                },
            }

        case actionTypes.CLINIC_ADMIN_SUCCESS:
            return {
                ...state,
                clinicAdmins: {
                    ...state.clinicAdmins,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data.clinicAdmins.map(
                        (clinicAdmin) => {
                            return {
                                full_name: `${clinicAdmin.first_name} ${clinicAdmin.last_name}`,
                                mob: clinicAdmin.user.mob,
                                email: clinicAdmin.user
                                    .email,
                                is_active:
                                    clinicAdmin.user
                                        .is_active,
                                user_id:
                                    clinicAdmin.user_id,
                            }
                        },
                    ),
                    totalCount: action.data.totalCount,
                },
            }

        case actionTypes.CLINIC_ADMIN_FAILURE:
            return {
                ...state,
                clinicAdmins: {
                    ...state.clinicAdmins,
                    isError: true,
                    isLoaded: true,
                },
            }

        case actionTypes.CLINIC_ADMIN_PAGE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            }

        case actionTypes.SEARCH_CLINIC_ADMIN:
            return {
                ...state,
                searchString: action.value,
                pagination: {
                    ...state.pagination,
                    page: 1,
                },
            }

        case actionTypes.CLINIC_ADMIN_SORT_DATA:
            return {
                ...state,
                clinicAdmins: {
                    ...state.clinicAdmins,
                    sort: {
                        ...state.clinicAdmins.sort,
                        [action.value.field]:
                            state.clinicAdmins.sort[
                                action.value.field
                            ] === 'asc'
                                ? 'desc'
                                : 'asc',
                    },
                    data:
                        state.clinicAdmins.sort[
                            action.value.field
                        ] === 'asc'
                            ? sortAscending(
                                  state.clinicAdmins.data,
                                  action.value.field,
                              )
                            : sortDescending(
                                  state.clinicAdmins.data,
                                  action.value.field,
                              ),
                },
            }

        case actionTypes.CLINIC_ADMIN_DETAILS_LOADING:
            return {
                ...state,
                isLoadingAdminDetails: true,
                clinicAdminDetails: {},
            }

        case actionTypes.CLINIC_ADMIN_DETAILS_SUCCESS:
            return {
                ...state,
                isLoadingAdminDetails: false,
                clinicAdminDetails: action.data,
            }
        case actionTypes.CLINIC_ADMIN_UPDATE_STATUS:
            const { user_id: userId, status } = action.data
            return {
                ...state,
                clinicAdmins: {
                    ...state.clinicAdmins,
                    data: state.clinicAdmins.data.map(
                        (clinicAdmin) => {
                            const { user_id, is_active } =
                                clinicAdmin
                            return {
                                ...clinicAdmin,
                                is_active:
                                    user_id === userId
                                        ? status
                                        : is_active,
                            }
                        },
                    ),
                },
            }

        default:
            return state
    }
}
