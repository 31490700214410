import { makeStyles } from '@material-ui/core'

export default makeStyles({
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `$secondaryGray !important`,
        },
        height: 48,
    },
    cssFocused: {
        borderWidth: '1px !important',
        borderColor: '$secondaryGray !important',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
            boxShadow:
                '0px 0px 2px 0px rgb(85 113 198 / 50%);',
        },
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#889db6 !important',
    },
})
