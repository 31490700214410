export default (theme) => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 10%)',
        border: 'none',
        outline: 'none',
        borderRadius: 4,
        padding: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        maxHeight: '70%',
        overflowY: 'auto',
    },
    timeoff: {
        position: 'absolute',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 10%)',
        border: 'none',
        outline: 'none',
        borderRadius: 4,
        padding: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        maxHeight: '40%',
        overflowY: 'auto',
    },
})
