export const INIT_VALUES = {
    termsAgree: false,
    releaseOfInfo: false,
    termsSign: '',
    regularConsultation: '',
    regularConsultationPlace: '',
    insurance: '',
    legalIssues: '',
    harmingOthers: {
        past: false,
        present: false,
        never: false,
    },
    alcoholAbuse: {
        past: false,
        present: false,
        never: false,
    },
    suicidal: {
        past: false,
        present: false,
        never: false,
    },
    substanceAbuse: {
        past: false,
        present: false,
        never: false,
    },
}

export const defaultTouchedFields = {
    regularConsultation: false,
    regularConsultationPlace: false,
    insurance: false,
    legalIssues: false,
    harmingOthers: false,
    suicidal: false,
    alcoholAbuse: false,
    substanceAbuse: false,
}
