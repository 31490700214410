import { Grid, Typography, Button, useMediaQuery } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import bgImage from './icons/bg_end_meeting.png'
import appLogo from '../../assests/Logos/tm2u_logo_white.png'
import AvatarDefault from '../../assests/images/avatar.svg'
import TimerIcon from './icons/TimerIcon'
import AvatarEndmeetingIcon from './icons/AvatarJoinRoomIcon'
import { useLocation } from 'react-router-dom'
import { StateContext } from './state'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        height: '100%',
    },
    box: {
        margin: 'auto',
        marginTop: 100,
        width: 'fit-content',
    },
    name: {
        lineHeight: 'unset',
        fontSize: 32,
        fontWeight: 500,
        color: 'white',
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        },
    },
    end_text: {
        marginLeft: 8,
        lineHeight: 'unset',

        fontSize: 19,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    appLogo: {
        width: 112,
        marginBottom: 62,
    },
    button: {
        background: '#5571c6',
        color: 'white',
        fontSize: 15,
        fontWeight: 400,
    },
    avatar_crop: {
        position: 'relative',
    },
    avatar_crop_icon: {
        position: 'absolute',
        left: 32,
        top: 39,
        width: 200,
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            left: 22,
            top: 30,
            width: 150,
            height: 150,
        },
    },
}))

export default function EndMeeting() {
    const classes = useStyles()
    const location = useLocation()
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const { currentNameLocal } = useContext(StateContext)

    // useEffect(() => {
    //     localStorage.removeItem('valid_twillio_token')
    // }, [])

    return (
        <Grid container className={classes.container}>
            <Grid className={classes.box} container alignItems="center" direction="column">
                {!isMobile && <img className={classes.appLogo} src={appLogo} alt="TeleMed2U" />}
                <div className={classes.avatar_crop}>
                    <AvatarEndmeetingIcon
                        width={isMobile ? 200 : 273}
                        height={isMobile ? 200 : 265}
                    />
                    <img className={classes.avatar_crop_icon} src={AvatarDefault} alt="TeleMed2U" />
                </div>
                <Typography className={classes.name}>
                    {currentNameLocal ? currentNameLocal : location?.state?.providerName}
                </Typography>
                <Grid
                    container
                    style={{ marginTop: 16, marginBottom: 24 }}
                    justify="center"
                    alignItems="center">
                    <TimerIcon />
                    <Typography className={classes.end_text}>
                        {location?.state?.message?.message || 'Your call has been ended'}
                    </Typography>
                </Grid>

                <Button href={'/'} variant="contained" className={classes.button}>
                    Go to Home page
                </Button>
            </Grid>
        </Grid>
    )
}
