import React from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core'
import ChatWindow from '../ChatWindow/ChatWindow'
import ParticipantList from '../ParticipantList/ParticipantList'
import MainParticipant from '../MainParticipant/MainParticipant'
import useChatContext from '../../hooks/useChatContext/useChatContext'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import ScheduleWindow from '../ScheduleWindow/ScheduleWindow'
import { getPatientCount, submitConsulationTracking } from '../../apis/closingConsultation'
import useProviderContext from '../../hooks/useProviderContext/useProviderContext'
import { ProviderParticipant } from './ProviderParticipant'

const useStyles = makeStyles((theme: Theme) => {
    const totalMobileSidebarHeight = `${
        theme.sidebarMobileHeight + theme.sidebarMobilePadding * 2 + theme.participantBorderWidth
    }px`

    return {
        container: {
            position: 'relative',
            height: '100%',
            background: '#252c3f',
            gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
            gridTemplateRows: '100%',
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: `100%`,
                gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) 160px`,
            },
        },
        chatWindowOpen: {
            gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.chatWindowWidth}px`,
        },
        scheduleWindowOpen: {
            gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.chatWindowWidth}px`,
        },
    }
})

export default function Room({ openAddParticipant, handleOpenAddParticipant }) {
    const classes = useStyles()
    const { isChatWindowOpen } = useChatContext()
    const { isScheduleWindowOpen } = useChatContext()
    const { currentUser } = useProviderContext()

    const { room, localTracks } = useVideoContext()
    if (!room?.name) return 'Loading'

    window.addEventListener('beforeunload', async (ev) => {
        ev.preventDefault()
        let currentAppointment = localStorage.getItem('currentAppointment')
        if (currentAppointment) {
            let data = localStorage.getItem('roomName')
            let countPayload = { roomName: data }
            let res = await getPatientCount(countPayload)
            let participantCount = res?.data.length
            let payload = {
                is_consultation_end: true,
                provider_id: currentUser?.provider_id,
                participants_count: participantCount,
                appointment_id: currentAppointment,
            }
            await submitConsulationTracking(payload)

            return
        }
    })
    return (
        <>
            <div
                className={
                    isScheduleWindowOpen
                        ? clsx(classes.container, {
                              [classes.scheduleWindowOpen]: isScheduleWindowOpen,
                          })
                        : clsx(classes.container)
                }>
                <MainParticipant
                    openAddParticipant={openAddParticipant}
                    handleOpenAddParticipant={handleOpenAddParticipant}
                />
                <ParticipantList openAddParticipant={openAddParticipant} />

                {/* Support for tranlations service */}
                {/* <ProviderParticipant /> */}

                {isScheduleWindowOpen ? <ScheduleWindow /> : null}
                {/* {isChatWindowOpen ? <ChatWindow /> : null} */}
            </div>
        </>
    )
}
