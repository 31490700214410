import { Box, Divider, Grid, TextField, Dialog } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import cross_icon from '../../../assests/images/cross_icon.svg'
import { SelectEl } from '../../../shared/elements'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { useFormik } from 'formik'
import { formatPhoneNumber } from '../../../utilities'
import { states } from '../../../constants/states'
import { useEffect, useMemo, useState } from 'react'
import { stateList } from '../../../containers/AppointmentBooking/constant'
import * as Yup from 'yup'
import { updateMe, updateProfilePic } from '../../../services/Login/login'
import healthCircle from '../../../assests/images/health_circle.svg'
import cloudIcon from '../../../assests/images/upload_cloud_image.svg'
import { useNotification } from '../../Appointments/MaterialUi/Notification'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { getLoggedInUserDetails } from '../../../store/actions/login'
import { getCharOfName } from '../../../utilities/profile'
import { nameAvatar } from '../../utils'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiPaper-root': {
                // height: '448px;',
                position: 'relative',
            },
            '& .MuiDialogContent-root': {
                overflow: 'auto',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '623px',
            },
        },
        modalHeader: {
            paddingLeft: '26px',
        },
        modalBody: {
            // width: '623px',
            // height: '448px',
            border: '2px solid red',
            background: 'white',
            borderColor: '#FFF',
            borderRadius: '8px',
            overflowY: 'auto',
            '&:focus': {
                outline: 'none', // Remove the focus outline
            },
        },
        label2: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        labelStyle: {
            color: ' #303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%' /* 22.4px */,
            marginBottom: '10px',
        },
        typoGraphy: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        formContainerclass: {
            display: 'flex',
        },
        dropzoneContainer: {
            width: '585px',
            height: '139px',
            flexShrink: 0,
            borderRadius: '5px',
            border: '1px solid #BFBFBF',
            background: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        Dropzonetxt: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
        },
        chooseFile: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '140%',
            textDecorationLine: 'underline',
            cursor: 'pointer',
        },
        buttonContainer: {
            width: '100%',
            border: '1px solid red',
        },
        saveButton: {
            width: '145px',
            height: '43px',
            flexShrink: 0,
            borderRadius: '5px',
            background: '#5571C6',
        },
        cancelButton: {},
        cloudIcon: {
            width: '32px',
            height: '40px',
            flexShrink: 0,
        },
        profilePictxtContainer: {
            display: 'flex',
            width: '68.329px',
            height: '57.205px',
            flexDirection: 'column',
            justifyContent: 'center',
            flexShrink: 0,
            color: '#667689',
            fontFamily: 'Rubik',
            fontSize: '26px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            left: '61px',
            top: '162px',
            position: 'absolute',
        },
    }),
)

const PatientPhotoUpdate = ({
    handleClose,
    isOpenModal,
    patientInfo,
    afterComplete,
    profilePic,
}) => {
    const classes = useStyles()
    const [isLoading, setisLoading] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [file, setFile] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const notification = useNotification()
    const dispatch = useDispatch()
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const [image, setImage] = useState(null)
    const [imageView, setImageView] = useState(null)

    const funcCloseModal = () => {
        setFile(null)
        handleClose()
        setImage('')
    }

    const readImageFileAsBinary = (fileData) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(fileData)
        })
    }
    const formSubmit = async () => {
        setisLoading(true)
        if (!file) {
            setisLoading(false)
            notification('Please Select Photo Before Submit ', 'error')
            return
        }

        try {
            const reader = new FileReader()
            const payload = {
                file_name: file.name.split('.')[0],
                ext: file.type.split('/')[1],
            }
            const config = {
                headers: {
                    'Content-Type': file.type, // Set the Content-Type header to the MIME type of the file
                },
            }
            const binaryData = await readImageFileAsBinary(file)

            const getUrl = await updateProfilePic(payload)
            await axios.put(getUrl.data.data.url, binaryData, config)
            dispatch(getLoggedInUserDetails())
            afterComplete()
            funcCloseModal()
            setisLoading(false)
        } catch (error) {
            notification('Something went wrong', 'error')
            setisLoading(false)
            setImage(image)
            console.log(error)
            dispatch(getLoggedInUserDetails())
        }
    }

    const handleDragEnter = (e) => {
        e.preventDefault()
        setDragging(true)
    }

    const handleDragLeave = () => {
        setDragging(false)
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    }

    const handleDrop = (e) => {
        e.preventDefault()
        setDragging(false)

        const droppedFile = e.dataTransfer.files[0]
        if (droppedFile && isImageFile(droppedFile)) {
            setImage(URL.createObjectURL(droppedFile))
            setImageView(photoValidator())
            setFile(droppedFile)
            setErrorMessage('')
        } else {
            notification('Unsupported File format', 'error')
        }
    }

    const isImageFile = (file) => {
        // const allowfileType = ['image/jpeg', 'image/png', 'image/webp', 'image/svg', 'image/gif']
        // return allowfileType.includes(file.type)
        const fileType = file.type
        if (fileType.startsWith('image/')) {
            return true
        }
        return false
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0]
        if (selectedFile && isImageFile(selectedFile)) {
            setImage(URL.createObjectURL(selectedFile))
            setImageView(photoValidator())
            setFile(selectedFile)
            setErrorMessage('')
        } else {
            notification('Unsupported File format', 'error')
        }
    }

    const photoValidator = () => {
        if (loggedinPatiendata?.photo && !image) {
            return profilePic
        }

        // if (!loggedinPatiendata?.photo || !image) {
        //     // alert('1')
        //     return healthCircle
        // }

        if (image) {
            return image
        }
        return healthCircle
    }

    return (
        <Dialog
            open={isOpenModal}
            fullWidth
            className={classes.root}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '780px',
                },
            }}>
            <Box className={classes.modalBody}>
                {' '}
                {/* Set width */}
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '26px',
                        paddingRight: '26px',
                    }}>
                    <Box
                        id="modal-title"
                        style={{ paddingTop: '20px' }}
                        className={[classes.typoGraphy]}>
                        Upload Profile Photo
                    </Box>
                    <Box style={{ marginTop: '20px' }}>
                        <img
                            src={cross_icon}
                            style={{ cursor: 'pointer' }}
                            onClick={funcCloseModal}
                        />
                    </Box>
                </Box>
                <Box style={{ paddingLeft: '19px', paddingRight: '19px' }}>
                    <Divider style={{ marginTop: '7px', color: '#EAECF0' }} />
                </Box>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    className={classes.formContainerclass}
                    style={{ marginTop: '28px' }}>
                    <Box>
                        {!loggedinPatiendata?.avatar && !image ? (
                            nameAvatar(
                                `${loggedinPatiendata?.first_name} ${loggedinPatiendata?.last_name}`,
                                '116px',
                                '116px',
                            )
                        ) : (
                            <img
                                style={{
                                    cursor: 'pointer',
                                    borderRadius: '80px',
                                    border: '2px solid #D8D8D8',
                                }}
                                src={photoValidator()}
                                height={116}
                                width={116}
                            />
                        )}
                    </Box>
                    <Grid style={{ marginTop: '34px' }}>
                        <Box
                            className={[
                                classes.dropzoneContainer,
                                `dropzone ${dragging ? 'dragging' : ''}`,
                            ]}
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}>
                            <Box className={classes.cloudIcon}>
                                <img src={cloudIcon} />
                            </Box>
                            <Box className={classes.Dropzonetxt}>
                                <label htmlFor="fileInput" className={classes.chooseFile}>
                                    Choose a file
                                </label>{' '}
                                or drag and drop it here.
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*" // Accept only image files
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }} // Hide the input element
                                />
                            </Box>
                            {/* <Box> {file && <p>Selected image file: {file.name}</p>}</Box> */}
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        marginTop: '20px',
                        paddingBottom: '21px',
                        paddingRight: '24px',
                    }}>
                    <Button
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            width: '145px',
                            height: '43px',
                            background: '#FFF',
                            borderRadius: '5px',
                            color: '#5571C6',
                            textTransform: 'none',
                        }}
                        onClick={funcCloseModal}>
                        {'Cancel'}
                    </Button>
                    <LoadingButton
                        onClick={() => formSubmit()}
                        loading={isLoading}
                        type="submit"
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            width: '145px',
                            height: '43px',
                            background: '#5571C6',
                            borderRadius: '5px',
                            color: '#FFF',
                            textTransform: 'none',
                            marginLeft: '2px',
                        }}>
                        {'Save'}
                    </LoadingButton>
                </Grid>
            </Box>
        </Dialog>
    )
}

export default PatientPhotoUpdate
