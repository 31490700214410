import { useEffect, useState } from 'react'
import { commonMasterData } from '../services/Provider/provider'
import { getLoggedInUserRole } from '../utilities'
import { UserRoles } from './user-roles'

export const providerProfileFieldsUpdated = (isCreate) => {
    const userRole = getLoggedInUserRole()

    // useEffect(() => {
    //     fetchCommonMasterData()
    // }, [])
    // const getEmploymentType = () => {
    //     let masterEmp = []
    //     masterData && masterData.employment_type && masterData.employment_type.map((item) => {
    //         masterEmp.push({ name: item.label, value: item.value })
    //     })
    //     return masterEmp
    // }
    return [
        {
            maintitle: 'Bio',
            section: [
                {
                    sectionTitle: '',
                    isSpacing: false,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-bio',
                            label: 'Bio',
                            name: 'desc',
                            placeHolder: 'Bio',
                            inputType: 'textArea',
                            value: '',
                            required: true,
                            gridSize: 12,
                        },
                    ],
                },
            ],
        },
        {
            maintitle: 'Provider Details',
            showContent:
                userRole === UserRoles.TM2U_SUPER_ADMIN
                    ? true
                    : false,
            section: [
                {
                    sectionTitle: '',
                    isSpacing: true,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-first-name',
                            label: 'First name',
                            name: 'first_name',
                            placeHolder: 'First Name',
                            inputType: 'textField',
                            value: '',
                            required: true,
                            gridSize: 5,
                            readOnly:
                                userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? false
                                    : true,
                        },
                        {
                            id: 'provider-last-name',
                            label: 'Last name',
                            name: 'last_name',
                            placeHolder: 'Last Name',
                            inputType: 'textField',
                            value: '',
                            required: true,
                            gridSize: 5,
                            readOnly:
                                userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? false
                                    : true,
                        },
                        {
                            id: 'provider-email',
                            label: 'email',
                            name: 'email',
                            placeHolder: 'Email',
                            inputType: 'textField',
                            value: '',
                            required: true,
                            gridSize: 5,
                            readOnly:
                                userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? false
                                    : true,
                        },
                        {
                            id: 'provider-dob',
                            label: 'Date of birth',
                            name: 'dob',
                            placeHolder: 'Date of Birth',
                            inputType: 'dateField',
                            value: '',
                            gridSize: 5,
                            required: true,
                            readOnly:
                                userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? false
                                    : true,
                        },
                        {
                            id: 'provider-gender',
                            label: 'Gender',
                            name: 'gender',
                            placeHolder: 'Gender',
                            inputType: 'dropDown',
                            options: [
                                {
                                    name: 'Male',
                                    value: 'male',
                                },
                                {
                                    name: 'Female',
                                    value: 'female',
                                },
                            ],
                            value: '',
                            gridSize: 5,
                            required: true,
                            readOnly:
                                userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? false
                                    : true,
                        },
                        {
                            id: 'provider-cellphone',
                            label: 'Cellphone',
                            name: 'mob',
                            placeHolder: 'Cellphone',
                            inputType: 'textField',
                            value: '',
                            gridSize: 5,
                            required: true,
                            readOnly:
                                userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                    ? false
                                    : true,
                        },
                    ],
                },
            ],
        },
        {
            maintitle: 'Education and Background',

            section: [
                {
                    sectionTitle: 'Specialty',
                    mainGrid: 12,
                    isFloat: true,
                    isWidth41: true,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-specialty',
                            label: 'Speciality',
                            name: 'speciality',
                            placeHolder:
                                'Select Speciality',
                            inputType: 'dropDown',
                            options: [],
                            value: '',
                            required: true,
                            gridSize: 12,
                            readOnly:
                                userRole ===
                                'tm2u_super_admin'
                                    ? false
                                    : true,
                        },
                    ],
                },
                {
                    sectionTitle: 'License',
                    isAdd: true,
                    isMinus: false,
                    isSpacing: false,
                    required: true,
                    fieldItems: [],
                },
                {
                    sectionTitle: '',
                    isSpacing: false,
                    fieldItems: [
                        {
                            id: 'provider-npi-number',
                            label: 'NPI number',
                            name: 'npi_number',
                            placeHolder: 'NPA Number',
                            inputType: 'textField',
                            value: '',
                            required: false,
                            gridSize: 2,
                            readOnly: false,
                        },
                        {
                            id: 'provider-mpn-number',
                            label: 'MPN Number',
                            name: 'mpn_number',
                            placeHolder: 'MPN Number',
                            inputType: 'textField',
                            value: '',
                            required: false,
                            gridSize: 3,
                            readOnly: false,
                        },
                    ],
                },
                {
                    sectionTitle: 'Taxonomy Code',
                    isSpacing: true,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-taxonomy-code',
                            label: 'Taxonomy code',
                            name: 'taxonomy_code',
                            placeHolder: 'Taxonomy Code',
                            inputType: 'textField',
                            value: '',
                            required: true,
                            gridSize: 2,
                            readOnly: false,
                        },
                        {
                            id: 'provider-group-tax-id',
                            label: 'Group tax id',
                            name: 'group_tax_id',
                            placeHolder: 'Group Tax id',
                            inputType: 'textField',
                            value: '',
                            required: true,
                            gridSize: 3,
                            readOnly: false,
                        },
                        {
                            id: 'provider-group-npi-number',
                            label: 'Group NPI number',
                            name: 'group_npi_number',
                            placeHolder: 'Group NPI Number',
                            inputType: 'textField',
                            value: '',
                            required: true,
                            gridSize: 3,
                            readOnly: false,
                        },
                    ],
                },
                {
                    sectionTitle: 'Education',
                    isAdd: true,
                    isSpacing: false,
                    fieldItems: [],
                    required: true,
                },
                {
                    sectionTitle: 'Training',
                    isAdd: true,
                    isSpacing: false,
                    fieldItems: [],
                    required: true,
                },
                {
                    sectionTitle: 'Board Certifications',
                    isAdd: true,
                    mainGrid: 12,
                    isSpacing: false,
                    fieldItems: [],
                },
                {
                    sectionTitle: 'Professional Membership',
                    mainGrid: 5,
                    isSpacing: false,
                    fieldItems: [
                        {
                            id: 'provider-professional-membership',
                            label: 'Institute/Organization name',
                            name: 'professional_membership',
                            placeHolder:
                                'Institute/Organization Name',
                            inputType: 'textField',
                            value: '',
                            required: false,
                            gridSize: 12,
                            readOnly: false,
                        },
                    ],
                },
                {
                    sectionTitle: 'Employment Type',
                    mainGrid: 12,
                    isSpacing: false,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-employment-type',
                            label: 'Employment type',
                            name: 'employment_type',
                            placeHolder:
                                'Select Employment Type',
                            inputType: 'dropDown',
                            options: [],
                            value: '',
                            required: true,
                            gridSize: 5,
                            readOnly: false,
                        },
                    ],
                },
                {
                    sectionTitle: 'Languages Spoken',
                    isSpacing: false,
                    fieldItems: [],
                    required: true,
                },
                {
                    sectionTitle: 'Timezone',
                    mainGrid: 12,
                    isFloat: true,
                    isWidth41: true,
                    isSpacing: false,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-timezone',
                            label: 'Timezone',
                            name: 'time_zone',
                            placeHolder: 'Select timezone',
                            inputType: 'dropDown',
                            options: [],
                            value: '',
                            required: true,
                            gridSize: 12,
                            readOnly: false,
                        },
                    ],
                },

                {
                    sectionTitle: 'In Network Insurance',
                    mainGrid: 12,
                    isSpacing: false,
                    fieldItems: [],
                    required: true,
                },
                {
                    sectionTitle:
                        'Initial Appointment Duration',
                    mainGrid: 12,
                    isFloat: false,
                    isWidth41: true,
                    isSpacing: false,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-initialAppointmentDuartion',
                            label: 'Initial Appointment Duration',
                            name: 'appointment_duration',
                            placeHolder: 'Select Duration',
                            inputType: 'dropDown',
                            options: [
                                { value: 5, name: '5 Min' },
                                {
                                    value: 10,
                                    name: '10 Min',
                                },
                                {
                                    value: 15,
                                    name: '15 Min',
                                },
                                {
                                    value: 20,
                                    name: '20 Min',
                                },
                                {
                                    value: 30,
                                    name: '30 Min',
                                },
                                {
                                    value: 45,
                                    name: '45 Min',
                                },
                                {
                                    value: 60,
                                    name: '1 Hour',
                                },
                                {
                                    value: 120,
                                    name: '2 Hour',
                                },
                            ],
                            value: '',
                            required: true,
                            gridSize: 12,
                            readOnly: false,
                        },
                    ],
                },
                {
                    sectionTitle:
                        'Follow-up Appointment Duration',
                    mainGrid: 12,
                    isFloat: false,
                    isWidth41: true,
                    isSpacing: false,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-followupAppointmentDuartion',
                            label: 'Follow-up Appointment Duration',
                            name: 'followup_appointment_duration',
                            placeHolder: 'Select Duration',
                            inputType: 'dropDown',
                            options: [
                                { value: 5, name: '5 Min' },
                                {
                                    value: 10,
                                    name: '10 Min',
                                },
                                {
                                    value: 15,
                                    name: '15 Min',
                                },
                                {
                                    value: 20,
                                    name: '20 Min',
                                },
                                {
                                    value: 30,
                                    name: '30 Min',
                                },
                                {
                                    value: 45,
                                    name: '45 Min',
                                },
                                {
                                    value: 60,
                                    name: '1 Hour',
                                },
                                {
                                    value: 120,
                                    name: '2 Hour',
                                },
                            ],
                            value: '',
                            required: true,
                            gridSize: 12,
                            readOnly: false,
                        },
                    ],
                },

                {
                    sectionTitle: 'Associated Clinics',
                    isAdd: true,
                    mainGrid: 12,
                    isSpacing: false,
                    fieldItems: [],
                },
                {
                    sectionTitle: 'Client Clinics',
                    isAdd: true,
                    mainGrid: 12,
                    fieldItems: [],
                    isSpacing: false,
                },
                {
                    sectionTitle: 'In Person Consults',
                    oneCol: true,
                    mainGrid: 3,
                    extraMar: true,
                    isSpacing: false,
                    required: true,
                    fieldItems: [
                        {
                            id: 'provider-in-person-consulta',
                            label: 'In person consults',
                            name: 'in_person_consult',
                            placeHolder:
                                'In Person Consults',
                            inputType: 'checkbox',
                            options: [
                                {
                                    label: 'Yes',
                                    name: 'yes',
                                    value: true,
                                },
                                {
                                    label: 'No',
                                    name: 'no',
                                    value: false,
                                },
                            ],
                            value: '',
                            required: true,
                            gridSize: 6,
                            readOnly: false,
                        },
                    ],
                },
                {
                    sectionTitle: 'Reporting To',
                    mainGrid: 12,
                    oneCol: true,
                    hideMainTitle: true,
                    hideContent:
                        localStorage.getItem('role') ===
                        UserRoles.TM2U_SUPER_ADMIN,
                    fieldItems: [],
                },
                {
                    sectionTitle: 'Reporting Providers',
                    mainGrid: 8,
                    oneCol: true,
                    hideMainTitle: true,
                    hideContent:
                        localStorage.getItem('role') ===
                        UserRoles.TM2U_SUPER_ADMIN,
                    fieldItems: [],
                },
                {
                    sectionTitle: 'Notifications',
                    mainGrid: 12,
                    isSpacing: false,
                    hideMainTitle:
                        userRole !==
                        UserRoles.TM2U_SUPER_ADMIN,
                    fieldItems: [
                        {
                            id: 'provider-sms-notification',
                            label: 'SMS',
                            name: 'sms_notification',
                            placeHolder: 'SMS',
                            inputType: 'toggle',
                            value: false,
                            required: false,
                            gridSize: 4,
                            readOnly: false,
                        },
                        {
                            id: 'provider-email-notification',
                            label: 'Email',
                            name: 'email_notification',
                            placeHolder: 'Email',
                            inputType: 'toggle',
                            value: false,
                            required: false,
                            gridSize: 4,
                            readOnly: false,
                        },
                        {
                            id: 'provider-in-app-notification',
                            label: 'In-App',
                            name: 'app_notification',
                            placeHolder: 'In-App',
                            inputType: 'toggle',
                            value: false,
                            required: false,
                            gridSize: 4,
                            readOnly: false,
                        },
                    ],
                },
            ],
        },
    ]
}
