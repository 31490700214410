import React from 'react'
import './HealthConditionsStyles.scss'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import hand_icon from '../../assests/images/hand_icon.svg'
import { CircularProgress, Tab, Tabs, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as SortIcon } from '../../assests/icons/Sorting.svg'
import { getMedicationAllergies } from '../../services/PatientHealth'
import { useNotification } from '../Appointments/MaterialUi/Notification'

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: '0 8px',
        background: 'none',
        color: 'black',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        width: '100px',
        height: '35px',
        minHeight: '35px !important',
        '&.Mui-selected': {
            background: '#5571C6',
            borderRadius: '5px',
            color: 'white',
            border: '1px solid #3F62C9',
        },
    },
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))((props) => <Tab {...props} />)

const HealthRecordIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.75 3.95768C3.75 2.86462 4.68275 1.97852 5.83333 1.97852H15.8333C16.0635 1.97852 16.25 2.15574 16.25 2.37435V14.2493C16.25 14.468 16.0635 14.6452 15.8333 14.6452H5.83333C5.14296 14.6452 4.58333 15.1768 4.58333 15.8327V16.2285H15.8333V17.0202H4.16667C3.93655 17.0202 3.75 16.843 3.75 16.6243V3.95768ZM9.58333 10.291V8.31185H7.5V7.52018H9.58333V5.54102H10.4167V7.52018H12.5V8.31185H10.4167V10.291H9.58333Z"
            fill="#5571C6"
        />
    </svg>
)

function Main() {
    const history = useHistory()
    const notification = useNotification()

    const [currentTab, setCurrentTab] = React.useState('active') // values: 'active', 'inactive'
    const [medicationAllergy, setMedicationAllergy] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [sort, setSort] = React.useState({
        active: false,
        inactive: false,
    })

    const flattendata = (data) => {
        if (!data || data.length === 0) return []

        return data.map((item) => {
            return {
                title: `${item?.allergy?.brandName}, ${item?.allergy?.text}, ${item?.allergy?.strength}`,
                reaction: item?.reaction.display,
                type: item?.status?.code,
                id: item?.id,
            }
        })
    }

    const getMedicationAllergyData = (type) => {
        setLoading(true)
        const payload = {
            page: 1,
            limit: 100,
            type,
        }
        getMedicationAllergies(payload)
            ?.then((res) => {
                const responseData = res?.data?.data?.data
                setMedicationAllergy(flattendata(responseData))
            })
            .catch(() => {
                notification('Something went wrong!', 'error')
            })
            .finally(() => setLoading(false))
    }

    const goToPage = () => history.goBack()

    const handleSort = () => {
        setSort((state) => {
            return {
                ...state,
                [currentTab]: !state[currentTab],
            }
        })
        medicationAllergy?.reverse()
    }

    React.useEffect(() => {
        getMedicationAllergyData(currentTab)
    }, [currentTab])

    console.log('medication allergy', medicationAllergy)
    return (
        <>
            <div className="health_condition_main_container">
                <div className="health_condition_sub_container">
                    <div className="health_condition_back_container" onClick={goToPage}>
                        <BackIcon />
                        <Typography>Back to health record</Typography>
                    </div>
                    <div className="health_condition_body">
                        <div className="health_condition_body_header">
                            <img
                                src={hand_icon}
                                alt="medication_allergies"
                                height={20}
                                width={20}
                            />
                            <Typography>Medication Allergies</Typography>
                        </div>
                        <div className="health_conditions_body_contents">
                            <Tabs
                                style={{
                                    minHeight: 'auto',
                                    background: '#EEF6FE',
                                    width: 'max-content',
                                    borderRadius: 5,
                                }}
                                TabIndicatorProps={{ style: { display: 'none' } }}
                                value={currentTab}
                                onChange={(_, value) => setCurrentTab(value)}>
                                <CustomTab value="active" label="Active" size="small" />
                                <CustomTab value="inactive" label="Inactive" size="small" />
                            </Tabs>

                            <div className="health_conditions_tab_contents">
                                <div className="health_conditions_tab_content_headers">
                                    <Typography className="health_conditions_tab_content_header">
                                        {currentTab} Medication Allergies
                                    </Typography>
                                </div>

                                <div className="health_conditions_tab_content_third_row">
                                    <span className="health_conditions_tab_content_count">
                                        {loading ? (
                                            <CircularProgress
                                                style={{
                                                    color: '#667689',
                                                    height: 15,
                                                    width: 15,
                                                    marginRight: 3,
                                                }}
                                            />
                                        ) : (
                                            <Typography>{medicationAllergy?.length}</Typography>
                                        )}
                                        <Typography>{currentTab} medications allergies</Typography>
                                    </span>
                                    <span
                                        onClick={handleSort}
                                        style={{
                                            pointerEvents:
                                                loading || medicationAllergy?.length === 0
                                                    ? 'none'
                                                    : 'auto',
                                        }}
                                        className="health_condition_tab_content_sorting">
                                        <SortIcon />
                                        <Typography>
                                            {sort[currentTab]
                                                ? 'Oldest to newest'
                                                : 'Newest to oldest'}
                                        </Typography>
                                    </span>
                                </div>

                                {loading ? (
                                    <div className="health_condition_cards_loader">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="health_conditions_card_container">
                                        {medicationAllergy?.length > 0 &&
                                            medicationAllergy?.map((allergyData) => {
                                                return (
                                                    <div
                                                        key={allergyData?.id}
                                                        className="health_conditions_cards">
                                                        <div className="health_conditions_cards_contents">
                                                            <Typography
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 17,
                                                                    fontWeight: 500,
                                                                }}>
                                                                {allergyData?.title}
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: '#667689',
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                }}>
                                                                Reaction
                                                            </Typography>
                                                            <Typography
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                }}>
                                                                {allergyData?.reaction}
                                                            </Typography>
                                                        </div>
                                                        <div className="health_conditions_card_type">
                                                            <span
                                                                style={{
                                                                    color:
                                                                        allergyData.type ===
                                                                        'active'
                                                                            ? '#14AE5C'
                                                                            : '#FFAC0C',
                                                                    background:
                                                                        allergyData.type ===
                                                                        'active'
                                                                            ? '#EBFFF3'
                                                                            : '#FEEDC6',
                                                                }}>
                                                                {allergyData?.type}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main
