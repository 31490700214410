import { Grid, Paper, makeStyles } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import AppointmentListCard from '../../containers/AppointmentList/Card'
import { MarkConsultationStatusCard } from '../../containers/ConsultationStatus'
import { searchPatientForProvider } from '../../services/Patient/patient'
import {
    AutoCompleteBoxEl,
    ButtonEl,
} from '../../shared/elements'
import { useDebounce } from '../../shared/hooks'
import { Button } from '../custom'

const useStyles = makeStyles(() => ({
    dasboard_container: {
        padding: '16px 20px',
    },
}))

function ProviderDashboard() {
    const history = useHistory()
    const classes = useStyles()
    const [searchData, setSearchData] = React.useState([])
    const [searchText, setSearchText] = React.useState('')
    const debouncedSearchText = useDebounce(searchText, 500)

    // React.useEffect(() => {
    //     onSearch(debouncedSearchText)
    // }, [debouncedSearchText])

    // const userInfo = React.useMemo(() => {
    //     return {
    //         role: localStorage.getItem('role'),
    //         userId: localStorage.getItem('userId'),
    //         consultation_link: localStorage.getItem(
    //             'consultation_link',
    //         ),
    //     }
    // }, [])

    async function onSearch(filterString) {
        try {
            const result = await searchPatientForProvider({
                page: 1,
                limit: 10,
                filterString,
            })
            if (result.data.status !== 404) {
                setSearchData(
                    result.data.patient.map((p) => ({
                        ...p,
                        title: `${p.first_name} ${p.last_name}`,
                        subtitle: moment(p.dob).format(
                            'MM/DD/YYYY',
                        ),
                    })),
                )
            }
        } catch (err) {
            console.log({ err })
            setSearchData([])
        }
    }

    function onOptionSelect(_, selected) {
        if (selected?.user_id) {
            if (!selected?.user?.is_active) {
                Swal.fire({
                    text: 'This account is inactive.',
                    confirmButtonText: 'Ok',
                }).then((result) => {})
                return
            }
            history.push(Routes.PROFILE, {
                userId: selected.user_id,
                role: UserRoles.PATIENT,
            })
        }
    }

    function onTextChange(e) {
        setSearchText(e.target.value)
    }

    return (
        <Grid
            container
            className={classes.dasboard_container}
            xs={12}>
            Coming soon!
            {/* <Grid container item>
                <Grid
                    item
                    style={{ marginRight: 16, flex: 1 }}>
                    <AutoCompleteBoxEl
                        options={searchData}
                        placeholder={'Search Patient'}
                        onInputChange={onTextChange}
                        onChange={onOptionSelect}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <ButtonEl
                        href={userInfo.consultation_link}
                        target="_blank"
                        fullWidth>
                        <strong>Start Consultation</strong>
                    </ButtonEl>
                </Grid>
            </Grid>
            <Grid container item style={{ marginTop: 16 }}>
                <Grid
                    item
                    style={{ marginRight: 16, flex: 1 }}>
                    <AppointmentListCard
                        role={userInfo.role}
                        userId={userInfo.userId}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MarkConsultationStatusCard
                        role={userInfo.role}
                        userId={userInfo.userId}
                    />
                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default ProviderDashboard
