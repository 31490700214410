import MomentUtils from '@date-io/moment'
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    ThemeProvider,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import {
    getProviderTimeoffs,
    updateProviderTimeoffsStatus,
} from '../../../store/actions/provider'
import { materialTheme as theme } from '../../../utilities/calendar/style'
import styles from '../styles'

function RequestTimeoffModal(props) {
    const dispatch = useDispatch()

    let row_id = props.selectedRow.id

    let start_date = moment
        .utc(
            `${props.selectedRow.start_date} ${props.selectedRow.start}`,
        )
        .local()
    let end_date = moment
        .utc(`${props.selectedRow.end_date}`)
        .local()
    let start_at = moment(start_date).format('hh:mm A')

    // let start_at = moment(
    //     props.selectedRow.start_date +
    //         ' ' +
    //         props.selectedRow.start,
    // ).format('hh:mm A');
    let end_at = moment(
        props.selectedRow.end_date +
            ' ' +
            props.selectedRow.end,
    ).format('hh:mm A')
    let operation = props.operation
    let userid = props.userid
    const [comments, SetComments] = useState('')

    function handleSubmit() {
        dispatch(
            updateProviderTimeoffsStatus(
                row_id,
                operation,
                comments,
                () => {
                    dispatch(getProviderTimeoffs(userid))
                },
                () => {
                    Swal.fire({
                        text: 'Appointments are scheduled in time-off period.',
                        confirmButtonText: 'Ok',
                    })
                },
            ),
        )
        props.onClose()
    }

    return (
        <Modal
            aria-labelledby="Request-Time-Off"
            aria-describedby="Request Time-Off Details"
            open={props.open}
            onClose={props.onClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendarModal">
            <div className={props.classes.paper}>
                <header>
                    <span>Request Time-Off</span>
                    <CloseIcon
                        style={{
                            color: '#FFF',
                            cursor: 'pointer',
                        }}
                        onClick={props.onClose}
                    />
                </header>
                <Grid
                    container
                    direction="column"
                    style={{ padding: 16 }}>
                    <form>
                        <Grid
                            container
                            direction="row"
                            alignItems={'flex-start'}
                            spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}>
                                    <ThemeProvider
                                        theme={theme}>
                                        <KeyboardDatePicker
                                            id="from"
                                            aria-label="from"
                                            name="from"
                                            variant="inline"
                                            format={
                                                'MM/DD/YY'
                                            }
                                            placeholder={
                                                'From'
                                            }
                                            inputVariant="outlined"
                                            value={
                                                start_date
                                            }
                                            disabled
                                            autoOk
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="start_at"
                                        id="start_at"
                                        select
                                        SelectProps={{
                                            style: {
                                                height: 54,
                                            },
                                            disableUnderline: true,
                                        }}
                                        className="timeoff-dropdown"
                                        value={start_at}
                                        fullWidth
                                        disabled>
                                        <MenuItem
                                            key={start_at}
                                            value={
                                                start_at
                                            }>
                                            {start_at}
                                        </MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            alignItems={'flex-start'}
                            spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}>
                                    <ThemeProvider
                                        theme={theme}>
                                        <KeyboardDatePicker
                                            id="to"
                                            aria-label="to"
                                            name="to"
                                            variant="inline"
                                            format={
                                                'MM/DD/YY'
                                            }
                                            placeholder={
                                                'To'
                                            }
                                            inputVariant="outlined"
                                            value={end_date}
                                            disabled
                                            autoOk
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="end_at"
                                        id="end_at"
                                        select
                                        value={end_at}
                                        SelectProps={{
                                            style: {
                                                height: 54,
                                            },
                                            disableUnderline: true,
                                        }}
                                        className="timeoff-dropdown"
                                        fullWidth
                                        disabled>
                                        <MenuItem
                                            key={end_at}
                                            value={end_at}>
                                            {end_at}
                                        </MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {operation == 'reject' ? (
                            <Grid>
                                <TextField
                                    name="comments"
                                    id="comment"
                                    fullWidth
                                    placeholder="Type the reason for Rejecting Time-off"
                                    style={{
                                        marginTop: 20,
                                    }}
                                    variant="outlined"
                                    value={comments}
                                    onChange={(e) =>
                                        SetComments(
                                            e.target.value,
                                        )
                                    }></TextField>
                            </Grid>
                        ) : null}

                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            style={{ marginTop: 20 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </div>
        </Modal>
    )
}

export default withStyles(styles)(RequestTimeoffModal)
