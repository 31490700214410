import {
    Container,
    FormLabel,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { errorMessages } from '../../constants/errorMessages'
import { ButtonSpinner } from '../custom'
import EmailIcon from '@mui/icons-material/Email'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import { useHistory } from 'react-router-dom'
import './signin.scss'
import check from '../../assests/icons/check.svg'
import leftImg from '../../assests/images/left-img.svg'
import rightImg from '../../assests/images/right-img.svg'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch } from 'react-redux'
import { getLoggedInUserDetails } from '../../store/actions/login'
import { makeAuthenticatedAPICall } from '../../services/Login/login'
import bg1 from '../../assests/images/bg1.png'
import bg2 from '../../assests/images/bg2.png'
import bg3 from '../../assests/images/bg3.png'
import bg4 from '../../assests/images/bg4.png'
import bg5 from '../../assests/images/bg5.png'

const bgImages = [bg1, bg2, bg3, bg4, bg5]
// Computation
const randomBgImage = bgImages[Math.floor(Math.random() * bgImages.length)]

const MagicLink = ({ decryptedData, handleResendLink }) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [verify, setVerify] = React.useState(false)
    const formikRef = useRef()
    const notification = useNotification()
    const validateSchema = Yup.object().shape({
        email: Yup.string().required().email(errorMessages.validEmailId),
    })
    const dispatch = useDispatch()
    const history = useHistory()
    React.useEffect(async () => {
        if (decryptedData?.isSSO || (decryptedData?.token && decryptedData?.email)) {
            setLoading(true)
            try {
                const res = await makeAuthenticatedAPICall({ token: decryptedData?.token })
                if (res?.status) {
                    setVerify(true)
                    setLoading(false)
                } else {
                    setVerify(false)
                    setLoading(false)
                    notification(res?.message, 'error')
                }

                if (decryptedData?.isSSO) {
                    localStorage.clear();
                    localStorage.setItem('access_token', decryptedData?.token)
                    dispatch(getLoggedInUserDetails())
                    history.replace('/patient-book-appointment')
                }
            } catch (err) {
                notification('Somting is wrong', 'error')
                setVerify(false)
                setLoading(false)
                localStorage.clear()
            }
        } else {
            setVerify(false)
            setLoading(false)
            localStorage.clear()
        }
    }, [decryptedData])
    const handleSubmitMagicLink = () => {
        setIsSubmitting(true)
        localStorage.setItem('access_token', decryptedData?.token)
        setTimeout(() => {
            dispatch(getLoggedInUserDetails())
            setIsSubmitting(false)
            history.push('/dashboard')
        }, 3000)
    }
    if (loading) {
        return (
            <Container
                className="sign-up-wrapper"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Container>
        )
    }

    if (decryptedData?.token && verify) {
        return (
            <Container className="sign-in-wrapper">
                <Grid container className="form-wrapper" style={{ gap: '10px', height: '100vh' }}>
                    <Grid item xs={12} sm={12} span={12} ml={2}>
                        <Grid className="logo-wrapper">
                            <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            span={12}
                            ml={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    border: '1px solid #CFCFCF',
                                    background: '#FAFAFA',
                                    flexDirection: 'column',
                                    width: '637px',
                                    height: '342px',
                                    gap: '5px',
                                }}>
                                <img src={check} width={68} />
                                <span className="text-verification">Verification Successful!</span>
                                <ButtonSpinner
                                    disabled={Boolean(isSubmitting)}
                                    isLoading={Boolean(isSubmitting)}
                                    onclick={handleSubmitMagicLink}
                                    label={'Enter your portal'}
                                    className="btn-portal"
                                    sx={{
                                        width: '50%',
                                        textTransform: 'none',
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            span={12}
                            ml={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <img src={leftImg} />
                                <span
                                    style={{ alignSelf: 'center', fontSize: 10, color: '#FFFFFF' }}>
                                    Image by &nbsp;
                                    <a
                                        target="_blank"
                                        style={{ cursor: 'pointer', color: '#FFFFFF' }}
                                        href="https://www.freepik.com/free-vector/health-professional-team_7474278.htm#&position=0&from_view=search&track=ais&uuid=222009ed-9c4c-445a-88ef-64dc5d4813c8"
                                        rel="noopener noreferrer">
                                        Freepik
                                    </a>
                                </span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <img src={rightImg} />
                                <span
                                    style={{ alignSelf: 'center', fontSize: 10, color: '#FFFFFF' }}>
                                    Image by &nbsp;
                                    <a
                                        target="_blank"
                                        style={{ cursor: 'pointer', color: '#FFFFFF' }}
                                        href="https://www.freepik.com/free-vector/health-professional-team_7474278.htm#&position=0&from_view=search&track=ais&uuid=222009ed-9c4c-445a-88ef-64dc5d4813c8"
                                        rel="noopener noreferrer">
                                        Freepik
                                    </a>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    } else {
        if (!decryptedData && !decryptedData?.email) {
            return (
                <Container className="sign-in-wrapper">
                    <Grid
                        container
                        className="form-wrapper"
                        style={{ gap: '10px', height: '100vh' }}>
                        <Grid item xs={12} sm={12} span={12} ml={2}>
                            <Grid className="logo-wrapper">
                                <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                span={12}
                                ml={2}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                        border: '1px solid #CFCFCF',
                                        background: '#FAFAFA',
                                        flexDirection: 'column',
                                        width: '637px',
                                        height: '342px',
                                        gap: '5px',
                                    }}>
                                    <span className="text-verification">Invalid Link</span>
                                    <ButtonSpinner
                                        // disabled={Boolean(isSubmitting)}
                                        // isLoading={Boolean(isSubmitting)}
                                        onclick={() => history.push('/signin')}
                                        label={'Go back to home'}
                                        className="btn-portal"
                                        sx={{
                                            width: '50%',
                                            textTransform: 'none',
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )
        } else {
            return (
                <Container className="sign-in-wrapper">
                    <Grid container className="form-wrapper">
                        <Grid item xs={12} sm={6} span={12}>
                            <div
                                className="form-img"
                                style={{
                                    // background:
                                    // 'url(http://source.unsplash.com/620x700/?medical)',
                                    background: `url(${randomBgImage})`,
                                    borderRadius: '10px',
                                    backgroundRepeat: 'none',
                                    backgroundSize: 'cover',
                                }}></div>
                        </Grid>
                        <Grid item xs={12} sm={6} span={12} ml={2} className="right-panel">
                            <Grid className="logo-wrapper">
                                <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                            </Grid>
                            <Formik
                                innerRef={formikRef}
                                initialValues={{
                                    email: decryptedData?.email || '',
                                }}
                                validationSchema={validateSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    try {
                                        const res = await handleResendLink(values)
                                        if (res && res?.data?.data?.status) {
                                            notification(
                                                'Link resend in the mail successfull!',
                                                'success',
                                            )
                                        } else {
                                            notification(
                                                res?.data?.data.message || 'Somthing is wrong',
                                                'error',
                                            )
                                        }

                                        setSubmitting(false)
                                    } catch (error) {
                                        notification('Somthing is wrong', 'error')
                                        setSubmitting(false)
                                    }
                                }}>
                                {({
                                    errors,
                                    touched,
                                    isValid,
                                    values,
                                    isSubmitting,
                                    dirty,
                                    handleChange,
                                    handleSubmit,
                                }) => {
                                    return (
                                        <form
                                            className="form registration"
                                            onSubmit={handleSubmit}
                                            noValidate>
                                            <div className="inner-wrapper-logo">
                                                <img
                                                    className="mobile-logo"
                                                    src={logo}
                                                    alt="TeleMed2U"></img>
                                            </div>
                                            <h1 className="align-item-center">
                                                {
                                                    'Magic link sent! Check your email to continue signing in.'
                                                }
                                            </h1>

                                            <p className="resend-email-paragraph">
                                                {`We have sent you a link to ${decryptedData?.email}. Please
                                            follow the instructions to access your patient portal.`}
                                            </p>

                                            <FormLabel
                                                className="input-label"
                                                component="legend"
                                                style={{
                                                    marginTop: 25,
                                                    color: '#303E4E',
                                                    fontSize: '16px',
                                                    fontWeight: '500',
                                                    textTransform: 'none',
                                                }}>
                                                Didn’t get an email?
                                            </FormLabel>
                                            <div className="submit">
                                                <ButtonSpinner
                                                    disabled={Boolean(isSubmitting)}
                                                    isLoading={Boolean(isSubmitting)}
                                                    label={'Resend magic link'}
                                                    className="btn-resend"
                                                    sx={{
                                                        width: '100%',
                                                        textTransform: 'none',
                                                    }}
                                                />
                                            </div>
                                        </form>
                                    )
                                }}
                            </Formik>
                            <Grid className="form-footer">
                                <p className="footer-tag">
                                    If you have any questions or need assistance, please call us at{' '}
                                    <a>(562) 268-0955</a>.{' '}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            )
        }
    }
}

export default MagicLink
