import React from 'react';

export default function TeleWaveInvalidLinkBg() {
  return (
    <svg width="1920" height="726" viewBox="0 0 1920 726" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1919.99 731H0V25.0384C188.498 -11.6145 343.401 -12.2825 445.537 51.779C506.487 90.0097 522.699 144.21 608.331 162.17C745.624 190.966 808.621 73.5697 990.355 67.551C1194.61 60.7879 1289.69 203.296 1426.95 162.17C1512.03 136.677 1498.7 74.9821 1581.58 43.8961C1669.93 10.7679 1811.37 33.5066 1920 81.0071V731H1919.99Z" fill="url(#paint0_linear_2960_49536)"/>
    <defs>
    <linearGradient id="paint0_linear_2960_49536" x1="959.997" y1="49" x2="959.997" y2="731" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FEFEFD"/>
    <stop offset="1" stop-color="#EBF3FF"/>
    </linearGradient>
    </defs>
    </svg>
    
  );
}
