/* eslint-disable react/react-in-jsx-scope */
import {
    Grid,
    Typography,
    FormLabel,
    TextField,
    Select,
    MenuItem,
    Button,
    FormHelperText,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as Yup from 'yup'
import { clinicProfileForm } from '../../../constants/clinicProfileForm'

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined'
import { useDispatch, useSelector } from 'react-redux'
import {
    handleEditClinicDetails,
    handleAddClinicDetails,
} from '../../../store/actions/clinic'
import { useEffect, useState } from 'react'

import LoadingPage from '../../../utilities/loading-page'

import Swal from 'sweetalert2'
import {
    maxCharLength,
    maxCharLengthZip,
} from '../../../constants'
import { errorMessages } from '../../../constants/errorMessages'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserRole,
} from '../../../utilities'
import { states } from '../../../constants/states'
import { Autocomplete } from '@material-ui/lab'
import { ButtonEl } from '../../../shared/elements'

const ClinicProfileEdit = (props) => {
    const {
        isCreate,
        onEditCallback,
        onSaveCallback,
        clinicDetails,
    } = props

    const dispatch = useDispatch()
    const editSuccessMsg = useSelector(
        (state) => state.clinic.clinicEditSuccessMsg,
    )
    const loading = useSelector(
        (state) => state.loader.loading,
    )
    const errMsg = useSelector(
        (state) => state.loader.errorMsg,
    )

    const initialValues = {
        clinic_name:
            capitalizeFirstLetter(
                clinicDetails?.clinic_name,
            ) || '',
        add1: clinicDetails?.add1,
        add2: clinicDetails?.add2,
        city: clinicDetails?.city,
        state: clinicDetails?.state,
        zip: clinicDetails?.zip,
        id: clinicDetails?.id,
        clinic_type: clinicDetails?.clinic_type,
    }

    const validateSchema = Yup.object().shape({
        clinic_name: Yup.string()
            .required(errorMessages.clinic_name)
            .trim()
            .matches(
                /^[aA-zZ\s]+$/,
                errorMessages.clinic_name,
            ),
        add1: Yup.string().required(
            errorMessages.address_line_1,
        ),
        city: Yup.string()
            .required(errorMessages.city)
            .matches(/^[aA-zZ\s]+$/, errorMessages.city),
        clinic_type: Yup.string().required(
            'Please select type',
        ),
        state: Yup.string()
            .required(errorMessages.state)
            .nullable(),
        zip: Yup.string()
            .required(errorMessages.zip)
            .test(
                'len',
                errorMessages.validZip,
                (val) => val && val.length === 5,
            ),
    })

    const getChangedValues = (values, initial) => {
        return Object.entries(values).reduce(
            (acc, [key, value]) => {
                const hasChanged = initial[key] !== value
                if (hasChanged) {
                    acc[key] = value
                }
                return acc
            },
            {},
        )
    }

    const saveProfile = async (val, touched) => {
        const changedValues = getChangedValues(
            val,
            initialValues,
        )
        changedValues['id'] = val?.id
        const createPayload = {
            clinic_name: val.clinic_name,
            add1: val.add1,
            add2: val.add2,
            city: val.city,
            state: val.state,
            zip: val.zip,
            clinic_type: val.clinic_type,
        }

        const actionMessage = isCreate
            ? 'Clinic Created Successfully'
            : 'Clinic Updated Successfully'
        if (isCreate) {
            await dispatch(
                handleAddClinicDetails(
                    createPayload,
                    (res) => {
                        const { isClinicExist } = res.data

                        const clinicCreationResponse =
                            isClinicExist
                                ? 'Clinic Already Existed'
                                : actionMessage

                        Swal.fire(
                            '',
                            clinicCreationResponse,
                        ).then((result) => {
                            onSaveCallback()
                        })
                    },
                ),
            )
        } else {
            await dispatch(
                handleEditClinicDetails(
                    changedValues,
                    () => {
                        Swal.fire('', actionMessage).then(
                            (result) => {
                                onEditCallback()
                            },
                        )
                    },
                ),
            )
        }
    }

    useEffect(() => {
        if (errMsg) {
            Swal.fire('', 'Clinic Already Existed', 'error')
        }
    }, [errMsg])

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    variant="h2"
                    className="edit-main-title ">
                    {isCreate ? (
                        <div className="editStaff-strip">
                            Add Clinic
                        </div>
                    ) : (
                        <div className="editStaff-strip">
                            Edit Clinic
                        </div>
                    )}
                </Typography>
                <Grid item xs={12} className="mt2">
                    <Grid container>
                        {/* <Grid item xs={2}>
                            {/* <div className="upload-wrap">
                <div className="empty-bg">
                  Logo
                  <div className="icon-wrap">
                    <CameraAltOutlinedIcon />
                  </div>
                </div>
              </div> * /}
                        </Grid> */}
                        <Grid item xs={8}>
                            <Formik
                                initialValues={
                                    initialValues
                                }
                                onSubmit={(values) =>
                                    saveProfile(values)
                                }
                                validationSchema={
                                    validateSchema
                                }
                                enableReinitialize>
                                {({
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    isSubmitting,
                                    handleBlur,
                                }) => {
                                    return (
                                        <Form className="profile-edit-main-wrap flexWrap dFlex">
                                            {clinicProfileForm.map(
                                                (
                                                    item,
                                                    i,
                                                ) => {
                                                    return (
                                                        <>
                                                            <Grid
                                                                item
                                                                xs={
                                                                    6
                                                                }>
                                                                {item.inputType ===
                                                                    'textField' && (
                                                                    <Grid
                                                                        item
                                                                        xs={
                                                                            12
                                                                        }
                                                                        className="dFlexVc mb1">
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                11
                                                                            }>
                                                                            <FormLabel className="tm-form-title">
                                                                                {
                                                                                    item.label
                                                                                }
                                                                                {item.required && (
                                                                                    <span className="astrik">
                                                                                        *
                                                                                    </span>
                                                                                )}
                                                                            </FormLabel>
                                                                            <TextField
                                                                                name={
                                                                                    item.name
                                                                                }
                                                                                onChange={async (
                                                                                    e,
                                                                                ) => {
                                                                                    if (
                                                                                        item.name ===
                                                                                        'zip'
                                                                                    ) {
                                                                                        e.target.value =
                                                                                            e.target.value.replace(
                                                                                                /\D/g,
                                                                                                '',
                                                                                            )
                                                                                        setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        )
                                                                                    } else {
                                                                                        await setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        )
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    marginTop:
                                                                                        '1em',
                                                                                }}
                                                                                fullWidth
                                                                                id={
                                                                                    item.id
                                                                                }
                                                                                variant="outlined"
                                                                                placeholder={
                                                                                    item.placeHolder
                                                                                }
                                                                                inputProps={{
                                                                                    maxLength:
                                                                                        item.label ===
                                                                                        'Zip code'
                                                                                            ? maxCharLengthZip
                                                                                            : item.name ===
                                                                                                  'mob' ||
                                                                                              item.name ===
                                                                                                  'referring_provider_cellphone'
                                                                                            ? 14
                                                                                            : item.name ===
                                                                                              'referring_provider_fax'
                                                                                            ? 10
                                                                                            : maxCharLength,
                                                                                    min: 0,
                                                                                }}
                                                                                value={
                                                                                    values[
                                                                                        item
                                                                                            .name
                                                                                    ]
                                                                                }
                                                                                onBlur={
                                                                                    handleBlur
                                                                                }
                                                                            />
                                                                            {errors[
                                                                                item
                                                                                    .name
                                                                            ] &&
                                                                                touched[
                                                                                    item
                                                                                        .name
                                                                                ] && (
                                                                                    <FormHelperText
                                                                                        error>
                                                                                        {
                                                                                            errors[
                                                                                                item
                                                                                                    .name
                                                                                            ]
                                                                                        }
                                                                                    </FormHelperText>
                                                                                )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}

                                                                {item.inputType ===
                                                                    'dropDown' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                11
                                                                            }
                                                                            className="dFlexVc mb1">
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="tm-form-title">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </FormLabel>
                                                                                <Select
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '1em',
                                                                                    }}
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        )
                                                                                    }}
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    className="ba-input-fields"
                                                                                    variant="outlined"
                                                                                    disableUnderline
                                                                                    placeholder={
                                                                                        item.placeHolder
                                                                                    }
                                                                                    defaultValue="none"
                                                                                    value={
                                                                                        values[
                                                                                            item
                                                                                                .name
                                                                                        ]
                                                                                    }
                                                                                    IconComponent={
                                                                                        ExpandMoreIcon
                                                                                    }
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }>
                                                                                    <MenuItem
                                                                                        disabled
                                                                                        value="none">
                                                                                        {
                                                                                            <span className="opacity05">
                                                                                                {
                                                                                                    item.placeHolder
                                                                                                }
                                                                                            </span>
                                                                                        }
                                                                                    </MenuItem>

                                                                                    {item.options.map(
                                                                                        (
                                                                                            el,
                                                                                        ) => (
                                                                                            <MenuItem
                                                                                                className="pl-select-option"
                                                                                                value={
                                                                                                    el.value
                                                                                                }
                                                                                                key={
                                                                                                    el.value
                                                                                                }>
                                                                                                {
                                                                                                    el.name
                                                                                                }
                                                                                            </MenuItem>
                                                                                        ),
                                                                                    )}
                                                                                </Select>
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}

                                                                {item.inputType ===
                                                                    'autoComplete' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                11
                                                                            }
                                                                            className="dFlexVc mb1">
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="tm-form-title">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </FormLabel>
                                                                                <Autocomplete
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '1em',
                                                                                    }}
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    className="ba-input-fields"
                                                                                    variant="outlined"
                                                                                    value={
                                                                                        values[
                                                                                            item
                                                                                                .name
                                                                                        ]
                                                                                    }
                                                                                    options={
                                                                                        states
                                                                                    }
                                                                                    getOptionLabel={(
                                                                                        states,
                                                                                    ) =>
                                                                                        states
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                        value,
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            item.name,
                                                                                            value,
                                                                                        )
                                                                                    }}
                                                                                    popupIcon={
                                                                                        <ExpandMoreIcon />
                                                                                    }
                                                                                    renderInput={(
                                                                                        params,
                                                                                    ) => (
                                                                                        <TextField
                                                                                            name={
                                                                                                item.name
                                                                                            }
                                                                                            {...params}
                                                                                            placeholder="Enter your state"
                                                                                            variant="outlined"
                                                                                        />
                                                                                    )}
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }
                                                                                />
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </>
                                                    )
                                                },
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                className="dEnd">
                                                <ButtonEl
                                                    className="primaryBtn mb2"
                                                    disabled={
                                                        isSubmitting
                                                    }
                                                    type="submit">
                                                    Save
                                                </ButtonEl>
                                            </Grid>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading && <LoadingPage />}
        </>
    )
}
export default ClinicProfileEdit
