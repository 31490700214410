import { getLoggedInUserRole } from '../utilities'
import { patientProfileSecQues } from './patientProfileSecQues'
import { UserRoles } from './user-roles'

export const patientProfileFields = (isCreate) => {
    const userRole = getLoggedInUserRole()
    return [
        {
            sectionTitle: '',
            sView: true,
            fieldItems: [
                {
                    id: 'patient-first-name',
                    label: 'First Name',
                    name: 'first_name',
                    placeHolder: 'First Name',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    type: 'text',
                    view: true,
                },
                {
                    id: 'patient-address_line_1',
                    label: 'Address Line 1',
                    name: 'address_line_1',
                    placeHolder: 'Address Line 1',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-last-name',
                    label: 'Last Name',
                    name: 'last_name',
                    placeHolder: 'Last Name',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-address_line_2',
                    label: 'Address Line 2',
                    name: 'address_line_2',
                    placeHolder: 'Address Line 2',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-dob',
                    label: 'Date of Birth',
                    name: 'dob',
                    placeHolder: 'Date of Birth',
                    inputType: 'dateField',
                    value: '',
                    required: true,
                },
                {
                    id: 'patient-city',
                    label: 'City',
                    name: 'city',
                    placeHolder: 'City',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-gender',
                    label: 'Gender',
                    name: 'gender',
                    placeHolder: 'Gender',
                    inputType: 'dropDown',
                    options: [
                        { name: 'Male', value: 'male' },
                        { name: 'Female', value: 'female' },
                    ],
                    value: '',
                    required: true,
                },
                {
                    id: 'patient-state',
                    label: 'State',
                    name: 'state',
                    placeHolder: 'State',
                    inputType: 'autoComplete',
                    value: '',
                    required: true,
                },
                {
                    id: 'patient-email',
                    label: 'Email',
                    name: 'email',
                    placeHolder: 'Email',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? true
                            : false,
                },
                {
                    id: 'patient-zip',
                    label: 'Zip Code',
                    name: 'zip',
                    placeHolder: 'Zip Code',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    type: 'number',
                    view: true,
                },
                {
                    id: 'patient-cellphone',
                    label: 'Cellphone',
                    name: 'mob',
                    placeHolder: 'Cellphone',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? true
                            : false,
                },
                {
                    id: 'patient-language',
                    label: 'Preferred Language',
                    name: 'language',
                    placeHolder: 'Language',
                    inputType: 'dropDown',
                    options: [],
                    value: 'none',
                    required: false,
                },
                {
                    id: 'patient-race',
                    label: 'Race',
                    name: 'race',
                    placeHolder: 'Race',
                    inputType: 'dropDown',
                    options: [],
                    value: '',
                    required: false,
                },

                {
                    id: 'patient-ethnicity',
                    label: 'Ethinicity',
                    name: 'ethinicity',
                    placeHolder: 'Ethinicity',
                    inputType: 'dropDown',
                    options: [],
                    value: '',
                    required: false,
                },

                {
                    id: 'patient-other',
                    label: 'Other Race',
                    name: 'other_race',
                    placeHolder: 'Other Race',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: false,
                },
                {
                    id: 'patient-other',
                    label: 'Other Ethinicity',
                    name: 'other_ethinicity',
                    placeHolder: 'Other Ethinicity',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: false,
                },
            ],
        },
        {
            sectionTitle: 'Security Questions',
            sView: false,

            fieldItems: [
                {
                    id: 'patient-sec-que1',
                    label: 'Security Question 1',
                    name: 'sec_ques_1',
                    placeHolder:
                        'Please select a Security Question',
                    inputType: 'dropDown',
                    options: patientProfileSecQues,
                    value: '',
                    required: false,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? false
                            : true,
                },
                {
                    id: 'patient-ans1',
                    label: 'Answer',
                    name: 'sec_ans_1',
                    placeHolder: 'Answer',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? false
                            : true,
                },
                {
                    id: 'patient-sec-que2',
                    label: 'Security Question 2',
                    name: 'sec_ques_2',
                    placeHolder:
                        'Please select a Security Question',
                    inputType: 'dropDown',
                    options: patientProfileSecQues,
                    value: '',
                    required: false,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? false
                            : true,
                },
                {
                    id: 'patient-ans2',
                    label: 'Answer',
                    name: 'sec_ans_2',
                    placeHolder: 'Answer',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? false
                            : true,
                },
                {
                    id: 'patient-sec-que3',
                    label: 'Security Question 3',
                    name: 'sec_ques_3',
                    placeHolder:
                        'Please select a Security Question',
                    inputType: 'dropDown',
                    options: patientProfileSecQues,
                    value: '',
                    required: false,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? false
                            : true,
                },

                {
                    id: 'patient-ans3',
                    label: 'Answer',
                    name: 'sec_ans_3',
                    placeHolder: 'Answer',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                    readOnly:
                        userRole === UserRoles.PATIENT
                            ? false
                            : true,
                },
            ],
        },
        {
            sectionTitle: 'Dependants',
            addButton: false,
            fieldItems: [
                [
                    {
                        id: 'patient-dependent-first-name',
                        label: 'First Name',
                        name: 'first_name',
                        placeHolder: 'First Name',
                        inputType: 'textField',
                        value: '',
                        required: false,
                        view: true,
                    },

                    {
                        id: 'patient-dependent-last-name',
                        label: 'Last Name',
                        name: 'last_name',
                        placeHolder: 'Last Name',
                        inputType: 'textField',
                        value: '',
                        required: false,
                        view: true,
                    },
                    {
                        id: 'patient-dependent-dob',
                        label: 'Date of Birth',
                        name: 'dob',
                        placeHolder: 'Date of Birth',
                        inputType: 'dateField',
                        value: '',
                        required: false,
                    },
                    {
                        id: 'patient-dependent-gender',
                        label: 'Gender',
                        name: 'gender',
                        placeHolder: 'Gender',
                        inputType: 'dropDown',
                        options: [
                            { name: 'Male', value: 'male' },
                            {
                                name: 'Female',
                                value: 'female',
                            },
                        ],
                        value: '',
                        required: false,
                    },
                    {
                        id: 'patient-dependent-relation',
                        label: 'Relation',
                        name: 'relation',
                        placeHolder: 'Relation',
                        inputType: 'dropDown',
                        options: [
                            { name: 'Son', value: 'son' },
                            {
                                name: 'Daughter',
                                value: 'daughter',
                            },
                        ],
                        view: true,
                        value: '',
                        required: false,
                    },
                ],
            ],
        },
        {
            sectionTitle: 'Primary Insurer',
            sView: false,
            readOnly: true,
            fieldItems: [
                {
                    id: 'patient-first-name',
                    label: 'First Name',
                    name: 'primary_insurer_first_name',
                    placeHolder: 'First Name',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-last-name',
                    label: 'Last Name',
                    name: 'primary_insurer_last_name',
                    placeHolder: 'Last Name',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-dob',
                    label: 'Date of Birth',
                    name: 'primary_insurer_dob',
                    placeHolder: 'Date of Birth',
                    inputType: 'dateField',
                    value: '',
                    required: false,
                },
                {
                    id: 'patient-gender',
                    label: 'Gender',
                    name: 'primary_insurer_gender',
                    placeHolder: 'Gender',
                    inputType: 'dropDown',
                    options: [
                        { name: 'Male', value: 'male' },
                        { name: 'Female', value: 'female' },
                    ],
                    value: '',
                    required: false,
                },

                {
                    id: 'patient-primary-insurer-relation',
                    label: 'Patient Relation to Insured',
                    name: 'primary_insurer_relation',
                    placeHolder:
                        'Patient Relation to Insured',
                    inputType: 'dropDown',
                    options: [],
                    value: undefined,
                    required: false,
                },
            ],
        },
        {
            sectionTitle: 'Primary Insurance',
            sView: false,
            fieldItems: [
                {
                    id: 'patient-primary_insurance_plan_name',
                    label: 'Plan Name',
                    name: 'primary_insurance_plan_name',
                    placeHolder: 'Plan Name',
                    inputType: 'dropDown',
                    options: [
                        { name: 'lic', value: 'lic' },
                        {
                            name: 'reliance',
                            value: 'reliance',
                        },
                    ],
                    value: '',
                    required: true,
                },
                {
                    id: 'patient-primary_insurance_group_number',
                    label: 'Insurance Group Number',
                    name: 'primary_insurance_group_number',
                    placeHolder: 'Insurance Group Number',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-primary_insurance_payer',
                    label: 'Insurance Payer',
                    name: 'primary_insurance_payer',
                    placeHolder: 'Insurance Payer',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-primary_insurance_payer_id',
                    label: 'Payer ID',
                    name: 'primary_insurance_payer_id',
                    placeHolder: 'Payer ID',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-primary_insurance_primary_member_id',
                    label: 'Primary Member ID',
                    name: 'primary_insurance_primary_member_id',
                    placeHolder: 'Primary Member ID',
                    inputType: 'textField',
                    value: '',
                    required: true,
                    view: true,
                },
                {
                    id: 'patient-primary_insurance_image_front',
                    label: 'Choose Images',
                    subLabel:
                        'PNG or JPEG format, not larger than 3MB',
                    name: 'primary_insurance_image_front',
                    placeHolder: 'Choose Images',
                    inputType: 'file_upload',
                    images: [],
                    required: false,
                },
            ],
        },
        {
            sectionTitle: 'Secondary Insurance',
            sView: false,
            fieldItems: [
                {
                    id: 'patient-secondary_insurance_plan_name',
                    label: 'Plan Name',
                    name: 'secondary_insurance_plan_name',
                    placeHolder: 'Plan Name',
                    inputType: 'dropDown',
                    options: [
                        { name: 'lic', value: 'lic' },
                        {
                            name: 'reliance',
                            value: 'reliance',
                        },
                    ],
                    value: '',
                    required: false,
                },
                {
                    id: 'patient-secondary_insurance_group_number',
                    label: 'Insurance Group Number',
                    name: 'secondary_insurance_group_number',
                    placeHolder: 'Insurance Group Number',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-secondary_insurance_payer',
                    label: 'Insurance Payer',
                    name: 'secondary_insurance_payer',
                    placeHolder: 'Insurance Payer',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-secondary_insurance_payer_id',
                    label: 'Payer ID',
                    name: 'secondary_insurance_payer_id',
                    placeHolder: 'Payer ID',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-secondary_insurance_secondary_member_id',
                    label: 'Secondary Member ID',
                    name: 'secondary_insurance_primary_member_id',
                    placeHolder: 'Secondary Member ID',
                    inputType: 'textField',
                    view: true,
                    value: '',
                    required: false,
                },
                {
                    id: 'patient-secondary_insurance_image_front',
                    label: 'Choose Images',
                    subLabel:
                        'PNG or JPEG format, not larger than 3MB',
                    name: 'secondary_insurance_image_front',
                    placeHolder: 'Choose Images',
                    inputType: 'file_upload',
                    images: [],
                    required: false,
                },
            ],
        },
        {
            sectionTitle: 'Referring Provider',
            sView: false,
            fieldItems: [
                {
                    id: 'patient-dependent-first-name',
                    label: 'First Name',
                    name: 'referring_provider_first_name',
                    placeHolder: 'First Name',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },

                {
                    id: 'patient-referring_provider_last_name',
                    label: 'Last Name',
                    name: 'referring_provider_last_name',
                    placeHolder: 'Last Name',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-referring_provider_email',
                    label: 'Email',
                    name: 'referring_provider_email',
                    placeHolder: 'Email',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-referring_provider_cellphone',
                    label: 'Cellphone',
                    name: 'referring_provider_cellphone',
                    placeHolder: 'Cellphone',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-referring_provider_fax',
                    label: 'Fax',
                    name: 'referring_provider_fax',
                    placeHolder: 'Fax',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-referring_provider_referral_code',
                    label: 'Referral code',
                    name: 'referring_provider_referral_code',
                    placeHolder: 'Referral Code',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
            ],
        },
        {
            sectionTitle: 'Pharmacy',
            sView: false,

            fieldItems: [
                {
                    id: 'patient-pharmacy_name',
                    label: 'Pharmacy Name',
                    name: 'pharmacy_name',
                    placeHolder: 'Pharmacy Name',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },
                {
                    id: 'patient-pharmacy_address_line_1',
                    label: 'Address Line 1',
                    name: 'pharmacy_address_line_1',
                    placeHolder: 'Address Line 1',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },

                {
                    id: 'patient-pharmacy_address_line_2',
                    label: 'Address Line 2',
                    name: 'pharmacy_address_line_2',
                    placeHolder: 'Address Line 2',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },

                {
                    id: 'patient-pharmacy_city',
                    label: 'City',
                    name: 'pharmacy_city',
                    placeHolder: 'City',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    view: true,
                },

                {
                    id: 'patient-pharmacy_state',
                    label: 'State',
                    name: 'pharmacy_state',
                    placeHolder: 'State',
                    inputType: 'autoComplete',

                    value: '',
                    required: false,
                },

                {
                    id: 'patient-pharmacy_zip',
                    label: 'Zip Code',
                    name: 'pharmacy_zip',
                    placeHolder: 'Zip Code',
                    inputType: 'textField',
                    value: '',
                    required: false,
                    type: 'number',
                    view: true,
                },
            ],
        },
        {
            sectionTitle: 'Notifications',
            mainGrid: 12,
            isSpacing: false,
            hide:
                userRole === UserRoles.TM2U_SUPER_ADMIN ||
                userRole === UserRoles.PATIENT
                    ? false
                    : true,
            fieldItems: [
                {
                    id: 'provider-sms-notification',
                    label: 'SMS',
                    name: 'sms_notification',
                    placeHolder: 'SMS',
                    inputType: 'toggle',
                    value: false,
                    required: false,
                    gridSize: 4,
                    readOnly: false,
                    key: 'sms',
                },
                {
                    id: 'provider-email-notification',
                    label: 'Email',
                    name: 'email_notification',
                    placeHolder: 'Email',
                    inputType: 'toggle',
                    value: false,
                    required: false,
                    gridSize: 4,
                    readOnly: false,
                    key: 'email',
                },
                {
                    id: 'provider-in-app-notification',
                    label: 'In-App',
                    name: 'app_notification',
                    placeHolder: 'In-App',
                    inputType: 'toggle',
                    value: false,
                    required: false,
                    gridSize: 4,
                    readOnly: false,
                    key: 'app_notification',
                },
            ],
        },
    ]
}
