export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'California',
    'Colorado',
    'Delaware',
    'Georgia',
    'Hawaii',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kentucky',
    'Maryland',
    'Michigan',
    'Missouri',
    'New Jersey',
    'New York',
    'Pennsylvania',
    'Tennessee',
    'Virginia',
    'West Virginia',
    'Maharashtra',
    'Kerala',
    'Karnataka',
    'Gujarat',
    'Haryana',
]
