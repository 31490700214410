import React, { useEffect, useState } from 'react'

import {
    Grid,
    Typography,
    Button,
    FormLabel,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserRole,
} from '../../../../utilities'
import { UserRoles } from '../../../../constants/user-roles'
import { commonMasterData } from '../../../../services/Provider/provider'

const PatientInformation = (props) => {
    const { patientProfile, handleEdit } = props
    const userRole = getLoggedInUserRole()
    const [raceArr, setRaceArr] = useState([])
    const [ethinicityArr, setEthinicityArr] = useState([])
    const [languageOptions, setLanguageOptions] = useState(
        [],
    )

    const fetchCommonMasterData = async () => {
        try {
            const res = await commonMasterData()
            const ethinicityOptions = []
            const languageOptions = []
            const raceOptions = []
            res.data &&
                res.data.ethinicity &&
                res.data.ethinicity.map((item) => {
                    ethinicityOptions.push({
                        name: item.label,
                        value: item.value,
                    })
                })
            res.data.language.map((item) => {
                languageOptions.push({
                    name: item.label,
                    code: item.value,
                })
            })
            res.data.race.map((item) => {
                raceOptions.push({
                    name: item.label,
                    value: item.value,
                })
            })
            setRaceArr(raceOptions)
            setEthinicityArr(ethinicityOptions)
            setLanguageOptions(languageOptions)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchCommonMasterData()
    }, [])
    const getRaceName = (race) => {
        if (race !== '') {
            const obj =
                raceArr &&
                raceArr.filter(
                    (item) => item.value === race,
                )
            return obj[0]?.name
        }
    }
    const getEthinicityName = (name) => {
        if (name !== '') {
            const obj = ethinicityArr.filter(
                (item) => item.value === name,
            )
            return obj[0]?.name
        }
    }
    const getLanguageLabel = (code) => {
        if (code !== '' && code !== null) {
            const obj = languageOptions.filter(
                (item) => item.code === code,
            )
            return obj[0]?.name
        }
        return code
    }
    return (
        <Grid container className="borderBottom">
            <Grid
                item
                xs={12}
                md={5}
                lg={5}
                className="pTB3">
                <Grid item xs={12} className="flex">
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                First Name
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile.first_name,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Last Name
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile.last_name,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="flex">
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Gender
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile.gender,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Date of Birth
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {moment(
                                    patientProfile?.dob,
                                ).format('MM/DD/YYYY')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="flex">
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Cellphone
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {formatPhone(
                                    patientProfile?.user?.mob
                                        .replace(/\D/g, '')
                                        .slice(-10),
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Email
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {
                                    patientProfile?.user
                                        ?.email
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Preferred Language
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {getLanguageLabel(
                                    patientProfile?.user
                                        ?.language,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={5}
                lg={5}
                className="pTB3">
                <Grid item xs={12} className="flex">
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Address Line 1
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile?.address_line_1,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Address Line 2
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile?.address_line_2 ||
                                        '',
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="flex">
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                City
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile?.city,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                State
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {capitalizeFirstLetter(
                                    patientProfile?.state,
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="flex">
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Zip Code
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark">
                                {patientProfile?.zip}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className="mb1">
                        <Grid item xs={12}>
                            <FormLabel
                                variant="subtitle2"
                                className="detail-item-title ">
                                Race
                            </FormLabel>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className="textOverflow">
                            <Typography
                                variant="subtitle2"
                                className="detail-item-title colorDark capitalize ">
                                {patientProfile?.other_race
                                    ? patientProfile?.other_race
                                    : getRaceName(
                                          patientProfile?.race,
                                      )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {patientProfile?.other_race &&
                    <Grid item xs={12} className="dFlex p5">
                        <Grid item xs={6} >
                            <Typography className="detail-item-title ">
                                Other race
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className="ml1 textOverflow"
                        >
                            <Typography className="detail-item-title colorDark p6" >
                                {patientProfile?.other_race}
                            </Typography>
                        </Grid>
                    </Grid>
                } */}
                <Grid item xs={6} className="mb1">
                    <Grid item xs={12}>
                        <FormLabel
                            variant="subtitle2"
                            className="detail-item-title ">
                            Ethnicity
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            className="detail-item-title colorDark capitalize">
                            {patientProfile?.other_ethinicity
                                ? patientProfile?.other_ethinicity
                                : getEthinicityName(
                                      patientProfile?.ethinicity,
                                  )}
                        </Typography>
                    </Grid>
                </Grid>
                {/* {patientProfile?.other_ethinicity &&
                    <Grid item xs={12} className="dFlex p5">
                        <Grid item xs={6} >
                            <Typography className="detail-item-title ">
                                Other ethinicity
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className="ml1 textOverflow"
                        >
                            <Typography className="detail-item-title colorDark p6" >
                                {patientProfile?.other_ethinicity}
                            </Typography>
                        </Grid>
                    </Grid>
                } */}
            </Grid>
            <Grid
                item
                xs={2}
                md={2}
                lg={2}
                className="pTB3">
                {userRole !== UserRoles.PROVIDER && (
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        className="btn-primary"
                        onClick={handleEdit}>
                        Edit
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

export default PatientInformation
