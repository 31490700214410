import { makeStyles, Theme } from '@material-ui/core'

export const chatWindowStyle = makeStyles(
    (theme: Theme) => ({
        chatBox: {
            // position: 'absolute',
            // zIndex: 400,
            // top: '40vh',
            // left: '3%',
            wordBreak: 'break-all',
            width: '100%',
            // [theme.breakpoints.down('sm')]: {
            //     top: '33vh',
                //    left:'2%',
                //    width:'96%'
            // },
        },
        chatWindowContainer: {
            borderRadius: '5px',
            flex: 1,
            display: 'flex',
            height: "100%",
            flexDirection: 'column',
        },
        chatWindowContainerTop: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 'fit-content',
            marginBottom: '4px',
        },
        chatWindowContainerTopName: {
            fontSize: '19px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 1)'
        },
        chatWindowContainerTopToggle: {
            border: 'none',
            backgroundColor: '#F5F6F7',
            cursor: 'pointer',
            borderRadius: 10,
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        chatWindowBottomChats: {
            overflowY: 'scroll',
            // padding: '0.5rem',
            flex:1,
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        chatWindowBottomChatbox: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0.5rem',
            paddingBottom: '0.8rem',
        },
        chatWindowBottomInput: {
            width: '83%',
            height: '35px',
            border: 'none',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            padding: '0 0.8rem',
            fontSize: '0.8rem',
            fontWeight: 500,
            '&::placeholder': {
                opacity: 0.6,
                fontSize: '0.7rem',
            },
        },
        chatWindowBottomSend: {
            position: 'absolute',
            borderRadius: '5px',
            top: '8.5px',
            right: '8px',
            padding: '0.3rem 0.7rem',
            paddingTop: '0.5rem',
            border: 'none',
            backgroundColor: '#5571c6',
            cursor: 'pointer',
        },
        chatWindowBottomIcon: {
            width: '20px',
            height: '20px',
            filter: 'invert(100%)',
            transform: 'rotate(15deg)',
        },
        chatMobContainer: {
            '.MuiDialogContent-root': {
                padding: '0px',
            },
        },
        chatDialogContent: {
            width: '94% !important',
            position: 'fixed',
            left: '3%',
            top: '28%',
        },
    }),
)
