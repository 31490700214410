export const tm2uAdminProfileForm = [
    {
        id: 'name',
        label: 'Staff Admin Name',
        name: 'name',
        placeHolder: 'Staff Admin Name',
        inputType: 'textField',
        value: '',
        required: true,
    },
    {
        id: 'staff_id',
        label: 'Admin Role',
        name: 'staff_id',
        placeHolder: 'Admin Role',
        inputType: 'dropDown',
        options: [
            { name: 'Admin', value: 2 },
            { name: 'Super Admin', value: 1 },
            {
                name: 'Billing Admin',
                value: 6,
            },
        ],
        value: '',
        required: true,
    },
    {
        id: 'email',
        label: 'Email',
        name: 'email',
        placeHolder: 'Email',
        inputType: 'textField',
        value: '',
        required: true,
    },
    {
        id: 'mob',
        label: 'Cellphone',
        name: 'mob',
        placeHolder: 'Cellphone',
        inputType: 'textField',
        value: '',
        required: true,
    },
    {
        sectionTitle: 'Notifications',
        mainGrid: 12,
        isSpacing: false,
        fieldItems: [
            {
                id: 'provider-sms-notification',
                label: 'SMS',
                name: 'sms_notification',
                placeHolder: 'SMS',
                inputType: 'toggle',
                value: false,
                required: false,
                gridSize: 4,
                readOnly: false,
            },
            {
                id: 'provider-email-notification',
                label: 'Email',
                name: 'email_notification',
                placeHolder: 'Email',
                inputType: 'toggle',
                value: false,
                required: false,
                gridSize: 4,
                readOnly: false,
            },
            {
                id: 'provider-in-app-notification',
                label: 'In-App',
                name: 'app_notification',
                placeHolder: 'In-App',
                inputType: 'toggle',
                value: false,
                required: false,
                gridSize: 4,
                readOnly: false,
            },
        ],
    },
]
