import React from 'react'

export default function IconDisableVideo() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.51405 19.6999C4.34877 19.6999 4.1835 19.6416 4.04738 19.5346C3.06544 18.7277 2.521 17.4346 2.521 15.8888V8.11104C2.521 4.78604 3.81405 3.49298 7.13905 3.49298H12.9724C14.9557 3.49298 17.0363 3.86243 17.4835 6.63326C17.5516 7.03187 17.2793 7.40131 16.8807 7.46937C16.4821 7.53743 16.1127 7.2652 16.0446 6.86659C15.8404 5.6027 15.3057 4.95131 12.9724 4.95131H7.13905C4.63072 4.95131 3.97933 5.6027 3.97933 8.11104V15.8888C3.97933 16.5208 4.10572 17.6971 4.971 18.4069C5.28211 18.6596 5.33072 19.1263 5.06822 19.4374C4.93211 19.6124 4.71822 19.6999 4.51405 19.6999Z"
                fill="#303E4E"
            />
            <path
                d="M12.9724 20.5069H8.11125C7.71264 20.5069 7.38208 20.1763 7.38208 19.7777C7.38208 19.3791 7.71264 19.0485 8.11125 19.0485H12.9724C15.4807 19.0485 16.1321 18.3971 16.1321 15.8888V11.0277C16.1321 10.6291 16.4626 10.2985 16.8612 10.2985C17.2599 10.2985 17.5904 10.6291 17.5904 11.0277V15.8888C17.5904 19.2138 16.2974 20.5069 12.9724 20.5069Z"
                fill="#303E4E"
            />
            <path
                d="M20.5558 17.8722C20.1377 17.8722 19.6225 17.7361 19.01 17.3083L16.4433 15.5097C16.1127 15.2763 16.035 14.8194 16.2683 14.4986C16.5016 14.168 16.9488 14.0902 17.2794 14.3236L19.8461 16.1222C20.2738 16.4236 20.585 16.4527 20.7113 16.3847C20.8377 16.3166 20.9933 16.0541 20.9933 15.5291V7.13883C20.9933 6.74022 21.3238 6.40967 21.7225 6.40967C22.1211 6.40967 22.4516 6.74022 22.4516 7.13883V15.5194C22.4516 16.9097 21.7808 17.4638 21.3822 17.668C21.1975 17.7652 20.9155 17.8722 20.5558 17.8722Z"
                fill="#303E4E"
            />
            <path
                d="M2.29729 22.636C2.11257 22.636 1.92785 22.5679 1.78201 22.4221C1.50007 22.1402 1.50007 21.6735 1.78201 21.3916L21.2265 1.94712C21.5084 1.66517 21.9751 1.66517 22.257 1.94712C22.539 2.22906 22.539 2.69573 22.257 2.97767L2.81257 22.4221C2.67646 22.5679 2.48201 22.636 2.29729 22.636Z"
                fill="#303E4E"
            />
        </svg>
    )
}
