import React from 'react'
import SignIn from '../../components/SignIn/SignIn'
import {
    completeSignIn,
    verfyEmailForgotPassword,
    verifyMagicLink,
} from '../../services/Login/login'
const SignInContainer = () => {
    const handleSubmit = async (values) => {
        try {
            const res = await completeSignIn(values)
            return res
        } catch (error) {
            return error
        }
    }
    const handleSendLink = async (values) => {
        try {
            const res = await verfyEmailForgotPassword(values)
            console.log('rree', res, values)
            return res
        } catch (error) {
            return error
        }
    }
    const handleMagicLink = async (values) => {
        try {
            const res = await verifyMagicLink(values)
            console.log('rree', res, values)
            return res
        } catch (error) {
            return error
        }
    }
    return (
        <div>
            {/* <Header /> */}
            <SignIn
                handleSubmit={handleSubmit}
                handleSendLink={handleSendLink}
                handleMagicLink={handleMagicLink}
            />
            {/* <Footer /> */}
        </div>
    )
}

export default SignInContainer
