import React from 'react'

export default function IconMicSvg() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0004 16.1319C9.45316 16.1319 7.38232 14.061 7.38232 11.5138V6.16658C7.38232 3.61936 9.45316 1.54852 12.0004 1.54852C14.5476 1.54852 16.6184 3.61936 16.6184 6.16658V11.5138C16.6184 14.061 14.5476 16.1319 12.0004 16.1319ZM12.0004 3.00686C10.2601 3.00686 8.84066 4.4263 8.84066 6.16658V11.5138C8.84066 13.2541 10.2601 14.6735 12.0004 14.6735C13.7407 14.6735 15.1601 13.2541 15.1601 11.5138V6.16658C15.1601 4.4263 13.7407 3.00686 12.0004 3.00686Z"
                fill="#303E4E"
            />
            <path
                d="M12.0002 19.5346C7.49877 19.5346 3.8335 15.8694 3.8335 11.368V9.71519C3.8335 9.31658 4.16405 8.98602 4.56266 8.98602C4.96127 8.98602 5.29183 9.31658 5.29183 9.71519V11.368C5.29183 15.0624 8.30572 18.0763 12.0002 18.0763C15.6946 18.0763 18.7085 15.0624 18.7085 11.368V9.71519C18.7085 9.31658 19.0391 8.98602 19.4377 8.98602C19.8363 8.98602 20.1668 9.31658 20.1668 9.71519V11.368C20.1668 15.8694 16.5016 19.5346 12.0002 19.5346Z"
                fill="#292D32"
            />
            <path
                d="M13.3515 7.31382C13.2738 7.31382 13.1863 7.3041 13.0988 7.27493C12.389 7.01243 11.6113 7.01243 10.9015 7.27493C10.5224 7.41104 10.1043 7.2166 9.96821 6.83743C9.8321 6.45826 10.0265 6.04021 10.4057 5.9041C11.4363 5.53465 12.5738 5.53465 13.6043 5.9041C13.9835 6.04021 14.1779 6.45826 14.0418 6.83743C13.9252 7.1291 13.6432 7.31382 13.3515 7.31382Z"
                fill="#292D32"
            />
            <path
                d="M12.7781 9.37493C12.71 9.37493 12.6517 9.36521 12.5836 9.34577C12.1947 9.23882 11.7961 9.23882 11.4072 9.34577C11.0183 9.45271 10.6197 9.21938 10.5128 8.83049C10.4058 8.45132 10.6392 8.05271 11.0281 7.94576C11.66 7.77076 12.3406 7.77076 12.9725 7.94576C13.3614 8.05271 13.5947 8.45132 13.4878 8.84021C13.4003 9.16104 13.0989 9.37493 12.7781 9.37493Z"
                fill="#292D32"
            />
            <path
                d="M12.0002 22.4513C11.6016 22.4513 11.271 22.1207 11.271 21.7221V18.8055C11.271 18.4068 11.6016 18.0763 12.0002 18.0763C12.3988 18.0763 12.7293 18.4068 12.7293 18.8055V21.7221C12.7293 22.1207 12.3988 22.4513 12.0002 22.4513Z"
                fill="#292D32"
            />
        </svg>
    )
}
