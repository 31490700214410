import React from 'react'
import './Login.scss'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import {
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormHelperText,
    FormControlLabel,
    FormLabel,
    TextField,
    Grid,
    Container,
    CircularProgress,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import backIcon from '../../assests/images/icon_back.png'
import { Formik } from 'formik'
import { Auth } from 'aws-amplify'

const StaffLoginForm = (props) => {
    const { onError } = props

    return (
        <Container className="container">
            <Grid container className="form-wrapper">
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className=""
                    span={12}>
                    <div
                        className="form-img"
                        style={{
                            background:
                                'url(http://source.unsplash.com/645x480/?medical)',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className=""
                    span={12}
                    ml={2}>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validate={(values) => {
                            const errors = {}
                            if (!values.email) {
                                errors.email = 'Required'
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email,
                                )
                            ) {
                                errors.email =
                                    'Invalid email address'
                            }
                            return errors
                        }}
                        onSubmit={(
                            values,
                            { setSubmitting },
                        ) => {
                            Auth.signIn(
                                values.email,
                                values.password,
                            )
                                .then((user) => {
                                    console.log(
                                        user,
                                        'sesstion',
                                    )
                                })
                                .catch((e) => {
                                    onError(e)
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form
                                className="form"
                                noValidate
                                onSubmit={handleSubmit}>
                                <div className="inner-wrapper-logo">
                                    <img
                                        className="mobile-logo"
                                        alt="TeleMed2U"
                                        src={logo}></img>
                                </div>
                                <h1 className="align-item-center">
                                    <Link
                                        id="auto-law-back"
                                        to="/">
                                        <img
                                            className="ba-back-icon"
                                            src={backIcon}
                                            alt=""
                                        />
                                    </Link>
                                    <span className="main-header">
                                        Login to Sonora EHR
                                    </span>
                                </h1>
                                <FormLabel
                                    className="input-label"
                                    component="legend">
                                    Email
                                    <span className="astrik">
                                        *
                                    </span>
                                </FormLabel>
                                <TextField
                                    id="auto-law-phone"
                                    required
                                    error={Boolean(
                                        errors.email,
                                    )}
                                    helperText={
                                        Boolean(
                                            errors.email,
                                        )
                                            ? errors.email
                                            : null
                                    }
                                    variant="outlined"
                                    size="small"
                                    className="text-input"
                                    type="email"
                                    name="email"
                                    placeholder="Enter Your Email"
                                    inputProps={{
                                        maxLength: 225,
                                    }}
                                    onChange={handleChange}
                                    disabled={false}
                                />
                                <FormLabel
                                    className="input-label"
                                    component="legend">
                                    Password
                                    <span className="astrik">
                                        *
                                    </span>
                                </FormLabel>
                                <TextField
                                    id="auto-law-phone"
                                    required
                                    error={errors.password}
                                    variant="outlined"
                                    size="small"
                                    className="text-input"
                                    type="password"
                                    name="password"
                                    placeholder="Enter Your Password"
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                    onChange={handleChange}
                                    disabled={false}
                                />
                                <div className="center-align">
                                    <Button
                                        id="auto-law-submit-btn"
                                        type="submit"
                                        variant="contained"
                                        disabled={
                                            isSubmitting
                                        }
                                        className={
                                            'btn-primary'
                                        }>
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Container>
    )
}

export default StaffLoginForm
