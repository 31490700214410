import React, {
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import Button from '@material-ui/core/Button'
import ChatIconWhite from '../../../icons/ChatIconWhite'
import clsx from 'clsx'
import { Badge, makeStyles } from '@material-ui/core'
import useChatContext from '../../../hooks/useChatContext/useChatContext'
import TeleChattingIcon from "../../../icons/TeleChattingIcon";
import { withStyles } from '@material-ui/styles'

export const ANIMATION_DURATION = 700

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 20,
        top: 13,
        padding: '0 4px',
    },
}))(Badge)

const useStyles = makeStyles({
    iconContainer: {
        position: 'relative',
        display: 'flex',
    },
    circle: {
        width: '10px',
        height: '10px',
        backgroundColor: '#5BB75B',
        borderRadius: '50%',
        position: 'absolute',
        top: '-3px',
        left: '13px',
        opacity: 0,
        transition: `opacity ${
            ANIMATION_DURATION * 0.5
        }ms ease-in`,
    },
    hasUnreadMessages: {
        opacity: 1,
    },
    ring: {
        border: '3px solid #5BB75B',
        borderRadius: '30px',
        height: '14px',
        width: '14px',
        position: 'absolute',
        left: '11px',
        top: '-5px',
        opacity: 0,
    },
    animateRing: {
        animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
        animationIterationCount: 1,
    },
    '@keyframes expand': {
        '0%': {
            transform: 'scale(0.1, 0.1)',
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            transform: 'scale(1.4, 1.4)',
            opacity: 0,
        },
    },
})

export default function ToggleChatButton(props: {
    className?: string
    isMobile?: boolean;
}) {
    const [shouldAnimate, setShouldAnimate] =
        useState(false)
    const [unReadCount, setUnreadCount] = useState(0)
    const [chat, setChat] = useState<any>([])
    const {
        isChatWindowOpen,
        setIsChatWindowOpen,
        conversation,
        hasUnreadMessages,
        messages,
    } = useChatContext()

    const toggleChatWindow = () => {
        setIsChatWindowOpen(!isChatWindowOpen)
        setUnreadCount(0)
    }
    const classes = useStyles()
    // useEffect(() => {
    //     setTimeout(() => {
    //         setChat((prevChat: any) => {
    //             if (prevChat.length > 0) {
    //                 if (messages.length > 0) {
    //                     setUnreadCount(unReadCount + 1);
    //                 }
    //             } else {
    //             }
    //             return messages;
    //         });
    //     }, 1000);
    // }, [messages]);

    useEffect(() => {
        if (shouldAnimate) {
            setUnreadCount(unReadCount + 1)
            setTimeout(
                () => setShouldAnimate(false),
                ANIMATION_DURATION,
            )
        }
    }, [shouldAnimate])

    useEffect(() => {
        if (conversation && !isChatWindowOpen) {
            const handleNewMessage = () =>
                setShouldAnimate(true)
            conversation.on(
                'messageAdded',
                handleNewMessage,
            )
            return () => {
                conversation.off(
                    'messageAdded',
                    handleNewMessage,
                )
            }
        }
    }, [conversation, isChatWindowOpen])
    useEffect(() => {
        setUnreadCount(0)
    }, [isChatWindowOpen])

    return (
        <StyledBadge
            badgeContent={unReadCount}
            color="primary"
            invisible={
                !hasUnreadMessages || unReadCount === 0
            }>
            <Button
                data-cy-chat-button
                className={props.className}
                onClick={toggleChatWindow}
                // startIcon={<ChatIconWhite />}
            >

      <TeleChattingIcon stroke={props.isMobile && 'white'} width={props.isMobile? 22:32} height={props.isMobile? 22:32}/>
            </Button>
        </StyledBadge>
    )
}
