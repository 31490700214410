import {
    CircularProgress,
    FormLabel,
    Grid,
    Typography,
    Box,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getUserListAccess,
    UserRoles,
} from '../../../constants/user-roles'
import { getNotificationPreferences } from '../../../services/Notification'
import {
    getTM2UAdminProfile,
    resetTm2uProfile,
} from '../../../store/actions/tm2uAdmin'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserId,
    getLoggedInUserRole,
} from '../../../utilities'
import { getCharOfName } from '../../../utilities/profile'
import '../MainProfile/MainProfile.scss'
import TM2UAdminProfileEdit from './TM2UAdminProfileEdit'
import { ButtonEl } from '../../../shared/elements'
class TM2UAdminProfile extends Component {
    state = {
        isEdit: false,
        isCreate: false,
        isView: false,
        isProfile: false,
        isDialog: false,
        roleOptions: {
            tm2u_super_admin: 'Super Admin',
            tm2u_admin: 'Admin',
            billing_admin: 'Billing Admin',
        },
        preferences: {
            email: false,
            sms: false,
            app_notification: false,
        },
        userRole: getLoggedInUserRole(),
    }

    componentDidMount() {
        const {
            isEditMode,
            isCreateMode,
            isViewMode,
            isUserProfile,
            isProfile,
        } = this.props

        this.setState({
            isEdit: !!isEditMode,
            isCreate: !!isCreateMode,
            isView: !!isViewMode,
            isUserProfile,
            isProfile,
        })

        // get profile data
        this.fetchTm2uAdminProfile()
        if (
            this.state.userRole ===
            UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
    }
    componentWillUnmount() {
        const {
            actions: { resetTm2uProfile },
        } = this.props
        resetTm2uProfile()
    }

    fetchNotificationPreferences = async () => {
        try {
            const userId = this.props.isUserProfile
                ? this.props.userId
                : getLoggedInUserId()
            const preferences =
                await getNotificationPreferences({
                    userId,
                })
            this.setState({
                ...this.state,
                preferences:
                    preferences.data?.data ??
                    this.state.preferences,
            })
        } catch (error) {
            console.log(
                'Failed to retrieve notification preferences',
            )
        }
    }

    fetchTm2uAdminProfile = () => {
        const {
            isCreateMode,
            isUserProfile,
            userId,
            profile,
            actions: { getTM2UAdminProfile }, // actions: { staffProfile }
            isProfile,
        } = this.props

        const profileId = isUserProfile
            ? userId
            : getLoggedInUserId()

        if (
            (isUserProfile &&
                !isCreateMode &&
                !Object.keys(profile).length) ||
            isProfile
        ) {
            getTM2UAdminProfile(profileId)
        }
    }

    handleEdit = () => {
        const { isEdit, isView } = this.state
        this.setState({ isEdit: !isEdit, isView: !isView })
    }

    handleCloseEdit = (
        edit = !this.state.isEdit,
        view = !this.state.isView,
    ) => {
        this.fetchTm2uAdminProfile()
        if (
            this.state.userRole ===
            UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
        this.setState({ isEdit: edit, isView: view })
    }
    render() {
        const {
            isCreate,
            isView,
            roleOptions,
            preferences,
        } = this.state
        const {
            profile,
            toggleToUserList,
            isStaff,
            selectedUserType,
        } = this.props
        const currentLoggedInUserRole =
            getLoggedInUserRole()
        const access = selectedUserType
            ? getUserListAccess(
                  currentLoggedInUserRole,
                  selectedUserType,
              )
            : { edit: true }

        return !isCreate && !Object.keys(profile).length ? (
            <CircularProgress className="loader" />
        ) : (
            <>
                <Grid
                    container
                    className="profile-main-wrap">
                    {' '}
                    {isView ? (
                        <>
                            <Grid
                                item
                                xs={12}
                                className="bg-tab">
                                <Typography
                                    variant="h3"
                                    className="main-name">{`${capitalizeFirstLetter(
                                    profile?.name,
                                )}`}</Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                className="tabs-main dFlex"
                                style={{
                                    padding: '1em 2em',
                                    marginBottom: '65vh',
                                }}>
                                <Grid
                                    item
                                    justifyContent="center">
                                    <div className="upload-wrap">
                                        <div className="empty-bg">
                                            {profile?.photo ? (
                                                <img
                                                    src={
                                                        profile?.photo
                                                    }
                                                    alt="Profile"
                                                    className="profile-img"
                                                />
                                            ) : (
                                                <div class="placeholder-text">
                                                    {getCharOfName(
                                                        profile?.name,
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>

                                <Box
                                    sx={{
                                        width: '70%',
                                        padding: '0 25px',
                                    }}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="tm-form-title mb15">
                                                        {profile?.role && (
                                                            <>
                                                                {
                                                                    roleOptions[
                                                                        profile
                                                                            ?.role
                                                                            ?.role_name
                                                                    ]
                                                                }
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <FormLabel
                                                        variant="subtitle2"
                                                        color="lightGray">
                                                        Email:
                                                    </FormLabel>
                                                    <Typography variant="subtitle2">
                                                        {
                                                            profile
                                                                ?.user
                                                                ?.email
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <FormLabel variant="subtitle2">
                                                        Cellphone:
                                                    </FormLabel>
                                                    <Typography variant="subtitle2">
                                                        {formatPhone(
                                                            profile?.user?.mob
                                                                .replace(
                                                                    /\D/g,
                                                                    '',
                                                                )
                                                                .slice(
                                                                    -10,
                                                                ),
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* <Grid item xs={6}>
                                            {this.state
                                                .userRole ===
                                                UserRoles.TM2U_SUPER_ADMIN && (
                                                <Grid
                                                    container>
                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }>
                                                        <Typography
                                                            variant="subtitle1"
                                                            className="tm-form-title mb15">
                                                            Notifications
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }>
                                                        <FormLabel>
                                                            SMS:{' '}
                                                        </FormLabel>
                                                        <Typography variant="subtitle2">
                                                            {preferences.sms
                                                                ? 'On'
                                                                : 'Off'}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }>
                                                        <FormLabel>
                                                            Email
                                                            :{' '}
                                                        </FormLabel>
                                                        <Typography variant="subtitle2">
                                                            {preferences.email
                                                                ? 'On'
                                                                : 'Off'}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }>
                                                        <FormLabel>
                                                            In-App:{' '}
                                                        </FormLabel>
                                                        <Typography variant="subtitle2">
                                                            {preferences.app_notification
                                                                ? 'On'
                                                                : 'Off'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid> */}
                                    </Grid>
                                </Box>
                                <Grid item xs={1}>
                                    {access &&
                                    access?.edit ? (
                                        <>
                                            <ButtonEl
                                                variant="contained"
                                                startIcon={
                                                    <EditIcon />
                                                }
                                                onClick={
                                                    this
                                                        .handleEdit
                                                }>
                                                Edit
                                            </ButtonEl>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <TM2UAdminProfileEdit
                            profileDetails={profile}
                            isCreate={isCreate}
                            onSaveCallback={
                                toggleToUserList
                            }
                            onEditCallback={
                                this.handleCloseEdit
                            }
                            isStaff={isStaff}
                            preferences={preferences}
                        />
                    )}
                </Grid>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        tm2uAdmin: { profile },
    } = state

    return { profile }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    getTM2UAdminProfile,
                    resetTm2uProfile,
                },
                dispatch,
            ),
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TM2UAdminProfile)
