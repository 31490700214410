import React, { useState, PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import ReactCrop from 'react-image-crop'
/* eslint-disable react/react-in-jsx-scope */
import {
    Button,
    Modal,
    Link,
    CircularProgress,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined'
import Swal from 'sweetalert2'
const { v4: uuidv4 } = require('uuid')
import { getLoggedInUserId } from '../../../utilities'
import { uploadFormDocuments } from '../../../utilities/profile'
import { handleEditTM2UAdminProfile } from '../../../store/actions/tm2uAdmin'
import './TM2UAdmin.scss'
import { handleEditPatientProfile } from '../../../store/actions/patient'
import { handleEditProviderProfile } from '../../../store/actions/provider'
import { handleClinicAdminProfileEdit } from '../../../store/actions/clinicAdmin'
import { setPatientData } from '../../../store/actions/login'
import LoadingPage from '../../../utilities/loading-page'
import 'react-image-crop/dist/ReactCrop.css'

class StyledProfilePhoto extends PureComponent {
    initialState = {
        src: null,
        file: null,
        crop: {
            unit: '%',
            width: 100,
            height: 100,
        },
        imageRef: null,
        isSaveBtnDisable: true,
    }
    state = {
        ...this.initialState,
        src: this.props.profilePhoto,
        isSourceHavingOldImage: !!this.props.profilePhoto,
        isOldImageLoaded: false,
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    file: e.target.files[0],
                    isSaveBtnDisable: false,
                    crop: { ...this.initialState.crop },
                    isSourceHavingOldImage: false,
                }),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    // If you setState the crop in here you should return false.
    createImage = async (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
            image.addEventListener('load', () =>
                resolve(image),
            )
            image.addEventListener('error', (error) =>
                reject(error),
            )
        })

    onImageLoaded = async (image) => {
        const imageRef = image
        this.setState({ imageRef })
    }

    onCropComplete = async (crop) => {
        this.makeClientCrop(crop)
    }

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop })
    }

    async makeClientCrop(crop) {
        if (
            this.state.imageRef &&
            crop.width &&
            crop.height
        ) {
            let image = this.state.imageRef
            const croppedImageUrl =
                await this.getCroppedImg(
                    image,
                    crop,
                    'newFile.jpeg',
                )
            this.setState({ croppedImageUrl })
        }
    }

    async getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const pixelRatio = window.devicePixelRatio
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')

        canvas.width = crop.width * pixelRatio * scaleX
        canvas.height = crop.height * pixelRatio * scaleY

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        )
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'))
                        return
                    }
                    blob.name = fileName
                    window.URL.revokeObjectURL(this.fileUrl)
                    this.fileUrl =
                        window.URL.createObjectURL(blob)
                    resolve(this.fileUrl)
                },
                'image/jpeg',
                1,
            )
        })
    }

    savePhotoSwalCallback = (photoLocation) => {
        const loggedInUser = getLoggedInUserId()
        const {
            userId,
            actions: { setPatientData },
            userData,
            onEditCallback,
        } = this.props
        Swal.fire('', 'Photo saved successfully').then(
            (result) => {
                onEditCallback()
                if (loggedInUser == userId) {
                    localStorage.setItem(
                        'photo',
                        photoLocation,
                    )
                    if (userData.isValidSecurityQus) {
                        setPatientData({
                            isValidSecurityQus:
                                userData.isValidSecurityQus,
                            response: {
                                ...userData.response,
                                photo: photoLocation,
                            },
                        })
                    } else {
                        setPatientData({
                            ...userData,
                            photo: photoLocation,
                        })
                    }
                }
            },
        )
    }
    savePhoto = async () => {
        const { croppedImageUrl, file } = this.state
        const { profileDetails } = this.props
        let imageFile
        const fileName = `${uuidv4()}_${file?.name}`
        const lastModified = new Date().getTime()

        try {
            let uploadedFiles = []
            if (croppedImageUrl) {
                let response = await fetch(croppedImageUrl)
                let data = await response.blob()
                imageFile = new File([data], fileName, {
                    type: file.type,
                    lastModified,
                })
                if (imageFile.size / 1024 / 1024 > 3) {
                    Swal.fire(
                        '',
                        'Please upload image less then size 3Mb.',
                        'error',
                    )
                    return
                }
                uploadedFiles = await uploadFormDocuments(
                    imageFile,
                    this.props.userId,
                )
            } else if (file) {
                const blob = file.slice(
                    0,
                    file.size,
                    file.type,
                )
                imageFile = new File([blob], fileName, {
                    type: file.type,
                    lastModified,
                })
                uploadedFiles = await uploadFormDocuments(
                    imageFile,
                    this.props.userId,
                )
            }

            let photoLocation = uploadedFiles[0]?.Location
            const providerEditpayload = {
                user_id: profileDetails?.user_id,
                licenses: profileDetails?.licenses,
                user: {
                    language:
                        profileDetails?.user?.language,
                },
                provider: {
                    speciality:
                        profileDetails?.speciality?.id,
                    first_name: profileDetails?.first_name,
                    last_name: profileDetails?.last_name,
                    dob: profileDetails?.dob,
                    gender: profileDetails?.gender,
                    photo: photoLocation
                        ? photoLocation
                        : '',
                    education: profileDetails?.education,
                    training: profileDetails?.training,
                    certification:
                        profileDetails?.certification,
                    in_network_insurances:
                        profileDetails?.in_network_insurances,
                    practice_name:
                        profileDetails?.practice_name,
                    group_tax_id:
                        profileDetails?.group_tax_id,
                    npi_number: profileDetails?.npi_number,
                    mpn_number: profileDetails?.mpn_number,
                    taxonomy_code:
                        profileDetails?.taxonomy_code,
                    professional_membership:
                        profileDetails?.professional_membership,
                    group_npi_number:
                        profileDetails?.group_npi_number,
                    employment_type:
                        profileDetails?.employment_type,
                    in_person_consult:
                        profileDetails?.in_person_consult,
                    associated_clinic_id:
                        profileDetails?.associated_clinic_id,
                    client_clinic_id:
                        profileDetails?.client_clinic_id,
                    supervisor_id:
                        profileDetails?.supervisor_id,
                    desc: profileDetails?.desc,
                    time_zone: profileDetails?.time_zone,
                    appointment_type:
                        profileDetails?.appointment_type,
                    assitant_provider:
                        profileDetails?.assitant_provider,
                    appointment_duration:
                        profileDetails?.appointment_duration,
                    followup_appointment_duration:
                        profileDetails?.followup_appointment_duration,
                },
            }
            const {
                actions: {
                    handleEditTM2UAdminProfile,
                    handleEditPatientProfile,
                    handleEditProviderProfile,
                    handleClinicAdminProfileEdit,
                },
                whois,
            } = this.props
            switch (whois) {
                case 'admin-self':
                    handleEditTM2UAdminProfile(
                        {
                            user_id: this.props.userId,
                            photo: photoLocation
                                ? photoLocation
                                : '',
                        },
                        this.savePhotoSwalCallback(
                            photoLocation,
                        ),
                    )
                    break
                case 'admin-user':
                    handleEditPatientProfile(
                        {
                            user_id: this.props.userId,
                            patient: {
                                photo: photoLocation
                                    ? photoLocation
                                    : '',
                            },
                        },
                        this.savePhotoSwalCallback(
                            photoLocation,
                        ),
                    )
                    break
                case 'provider':
                    handleEditProviderProfile(
                        {
                            user_id: this.props.userId,
                            licenses:
                                profileDetails.licenses,
                            user: {
                                language:
                                    profileDetails.user
                                        .language,
                            },
                            provider:
                                providerEditpayload.provider,
                        },
                        this.savePhotoSwalCallback(
                            photoLocation,
                        ),
                    )
                    break
                case 'clinical-admin':
                    handleClinicAdminProfileEdit(
                        {
                            user_id: this.props.userId,
                            photo: photoLocation
                                ? photoLocation
                                : '',
                        },
                        this.savePhotoSwalCallback(
                            photoLocation,
                        ),
                    )
                    break
                default:
                    break
            }
        } catch (err) {
            console.log(err)
            Swal.fire(
                '',
                'Files could not be uploaded!',
                'error',
            )
        }
    }

    deletePhoto = () => {
        this.setState({
            ...this.initialState,
            isSaveBtnDisable: false,
        })
    }

    oldImageLoaded = () => {
        this.setState({ isOldImageLoaded: true })
    }

    render() {
        const {
            crop,
            src,
            isSaveBtnDisable,
            isSourceHavingOldImage,
            isOldImageLoaded,
            croppedImageUrl,
        } = this.state
        return (
            <>
                <div class="placeholder">
                    {src ? (
                        isSourceHavingOldImage ? (
                            <>
                                {!isOldImageLoaded && (
                                    <CircularProgress />
                                )}
                                <img
                                    src={src}
                                    onLoad={
                                        this.oldImageLoaded
                                    }
                                    style={{
                                        maxHeight: '100%',
                                        objectFit:
                                            'contain',
                                        height: '360px',
                                        marginBottom: '0px',
                                        display:
                                            isOldImageLoaded
                                                ? 'block'
                                                : 'none',
                                        maxWidth: '100%',
                                    }}
                                />
                            </>
                        ) : (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                disabled={
                                    !src &&
                                    !!this.props
                                        .profilePhoto
                                }
                                onImageLoaded={
                                    this.onImageLoaded
                                }
                                onComplete={
                                    this.onCropComplete
                                }
                                onChange={this.onCropChange}
                                style={{
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                    height: '360px',
                                    marginBottom: '0px',
                                }}
                                imageStyle={{
                                    maxHeight: '360px',
                                    maxWidth: '100%',
                                }}
                                circularCrop={false}
                            />
                        )
                    ) : (
                        <div class="placeholder-text">
                            {this.props.nameInitials}{' '}
                        </div>
                    )}
                </div>
                {src && !isSourceHavingOldImage && (
                    <div class="placeholder-title">
                        Drag to reposition the photo
                    </div>
                )}
                {src || this.props.profilePhoto ? (
                    <div className="choose-new-delete-wrapper">
                        <label className="choose-new-link">
                            <input
                                type="file"
                                onChange={this.onSelectFile}
                                accept="image/*"
                            />
                            <EditIcon /> Change Photo
                        </label>
                        <label
                            className="delete-link"
                            onClick={this.deletePhoto}>
                            <DeleteOutlineIcon /> Delete
                        </label>
                    </div>
                ) : (
                    <label className="MuiButtonBase-root MuiButton-root MuiButton-text upload-btn">
                        <input
                            type="file"
                            onChange={this.onSelectFile}
                            accept="image/*"
                        />
                        <EditIcon /> Add profile photo
                    </label>
                )}
                {(src || this.props.profilePhoto) && (
                    <Button
                        className="upload-btn"
                        onClick={this.savePhoto}
                        disabled={isSaveBtnDisable}>
                        Save
                    </Button>
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        patient: { patientProfile },
        login: { userData },
    } = state

    return { patientProfile, userData }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    handleEditTM2UAdminProfile,
                    handleEditPatientProfile,
                    handleEditProviderProfile,
                    handleClinicAdminProfileEdit,
                    setPatientData,
                },
                dispatch,
            ),
        },
    }
}

const Demo = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledProfilePhoto)

export const ProfilePhotoModal = ({
    profilePhoto,
    nameInitials,
    userId,
    onEditCallback,
    whois,
    profileDetails,
}) => {
    const loading = useSelector(
        (state) => state.loader.loading,
    )
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <div class="icon-wrap" onClick={handleOpen}>
                <CameraAltOutlinedIcon />
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                className="profile-pic-modal">
                <div className="profile-pic-upload">
                    <header>
                        <CloseIcon onClick={handleClose} />
                    </header>
                    <main>
                        <Demo
                            profilePhoto={profilePhoto}
                            nameInitials={nameInitials}
                            userId={userId}
                            onEditCallback={() => {
                                onEditCallback(false, true)
                                handleClose()
                            }}
                            whois={whois}
                            profileDetails={profileDetails}
                        />
                    </main>
                </div>
            </Modal>
            {loading && <LoadingPage />}
        </>
    )
}
