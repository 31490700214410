import * as actionType from '../actionType'

export const setLoading = (value) => {
    return {
        type: actionType.SET_LOADER,
        value: value,
    }
}

export const setError = (value) => {
    return {
        type: actionType.SET_ERROR,
        value: value,
    }
}

export const setErrorMessage = (value) => {
    return {
        type: actionType.SET_ERROR_MSG,
        value: value,
    }
}

export const setLoadingAdmit = (value) => {
    return {
        type: actionType.SET_LOADING_ADMIT,
        value: value,
    }
}

export const setLoadingEndRoom = (value) => {
    return {
        type: actionType.SET_LOADING_END_ROOM,
        value: value,
    }
}
