import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Switch, useHistory } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import AppointmentDetails from '../../components/Dashboard/AppointmentDetails'
import HeaderNew from '../../components/Header/HeaderNew'
import Routes from '../../constants/routes'
import { authRoutes } from '../../routes'
import Loader from '../../utilities/loading-page'
import './AppLayout.scss'

const AppLayout = (props) => {
    const { isAuthenticated, logout } = props
    const [isActive, setIsActive] = useState(true)
    const [redirect, setRedirect] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const onLogout = () => {
        localStorage.clear()
        logout()
        history.push(Routes.SIGNIN)
    }
    const onProfileClick = () => {
        // let getRole = localStorage.getItem('role')
        // history.push(Routes.PROFILE, {
        //     userId: localStorage.getItem('userId'),
        //     role: localStorage.getItem('role'),
        // })
    }
    React.useEffect(() => {
        let timeoutId
        const resetTimer = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(logout, 900000)
        }
        const logout = () => {
            setIsActive(false)
            setRedirect(true)
        }
        const handleUserActivity = () => {
            resetTimer()
            setIsActive(true)
        }

        resetTimer()

        // Event listeners for user activity
        document.addEventListener('mousemove', handleUserActivity)
        document.addEventListener('keydown', handleUserActivity)

        return () => {
            // Cleanup: remove event listeners
            document.removeEventListener('mousemove', handleUserActivity)
            document.removeEventListener('keydown', handleUserActivity)
            clearTimeout(timeoutId) // Clear timeout
        }
    }, [])

    const queryParams = new URLSearchParams(window.location.search)

    const videoConsultationRoutes = [
        Routes.CONSULTATION.URL,
        Routes.CONSULTATION.VIDEO,
        Routes.CONSULTATION.JOIN,
        Routes.CONSULTATION.ROOM,
        Routes.CONSULTATION.SHORT_LINK,
    ]

    const shouldHeaderPresent = () => !videoConsultationRoutes.includes(window.location.pathname)

    if (!isActive || redirect) {
        localStorage.clear()
        return <Redirect to="/signin" />
    }
    if (!isAuthenticated) {
        // show loader
        return <Loader />
    } else if (isAuthenticated) {

        if (queryParams.redirect === 'ok') {
            return <Redirect to='/patient-book-apppointment' />
        }

        return (
            <Grid container className="app-layout" wrap="nowrap">
                {/* <Sidebar isShow={isShow} handeOpenSideBar={toggleShowSideBar} /> */}

                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{
                        flex: 1,
                        width: '1000px',
                        position: 'relative',
                        height: '100%',
                        overflow: 'hidden',
                    }}>
                    { shouldHeaderPresent() &&
                        <>
                            <HeaderNew onLogout={onLogout} />
                            <AppointmentDetails />
                        </>
                    }
                    {/* <Header
                        isShow={isShow}
                        pathName={location.pathname}
                        onLogout={onLogout}
                        onProfile={onProfileClick}
                        profilePhotoUrl={profilePhotoUrl}
                        handleToggleShowSideBar={toggleShowSideBar}
                    /> */}
                    <div
                        style={{
                            // position: shouldHeaderPresent() ? 'absolute' : 'initial',
                            width: '100%',
                            overflowY: 'auto',
                            // height: shouldHeaderPresent() ? 'calc(100vh - 160px)' : '100%',
                            height: '100%',
                            // maxHeight: '85%',
                        }}>
                        <GuardProvider>
                            <Switch>
                                {authRoutes.map((route, index) => {
                                    const { path, component, meta, roles } = route

                                    return (
                                        <GuardedRoute
                                            path={path}
                                            exact
                                            strict
                                            component={component}
                                            key={index}
                                            meta={{
                                                ...meta,
                                                allowedUsers: roles,
                                                dispatch,
                                            }}
                                            guards={meta.guards}
                                        />
                                    )
                                })}
                            </Switch>
                        </GuardProvider>
                    </div>
                </Grid>
            </Grid>
        )
    }
    onLogout()
}

export default AppLayout
