import axios from 'axios'
import { config } from '../../constants'

export const getNotificationPreferences = (payload) => {
    const token = localStorage.getItem('token')
    // return axios.get(
    //     `${config.dev.notificationURL}/v1.0/preference?user_id=${payload.userId}`,
    //     { headers: { Authorization: `Bearer ${token}` } },
    // );
    return Promise.resolve({})
}

export const saveNotificationPreferences = (payload) => {
    const token = localStorage.getItem('token')
    return Promise.resolve({})
    // return axios.put(
    //     `${config.dev.notificationURL}/v1.0/preference`,
    //     payload,
    //     { headers: { Authorization: `Bearer ${token}` } },
    // );
}

export const getNotificationList = (payload) => {
    const token = localStorage.getItem('token')
    return axios.post(
        `${config.dev.notificationURL}/v1.0/app_notification/list`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
    )
}

export const getUnreadCount = (payload) => {
    const token = localStorage.getItem('token')
    return axios.post(
        `${config.dev.notificationURL}/v1.0/app_notification/unread_count`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
    )
}

export const markAsRead = (payload) => {
    const token = localStorage.getItem('token')
    return axios.post(
        `${config.dev.notificationURL}/v1.0/app_notification/mark_read`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
    )
}
