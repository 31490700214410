import { Grid, Tabs, Tab, Typography, IconButton, Menu, MenuItem } from '@material-ui/core'
import React, { useState, useEffect, useMemo } from 'react'
import './HeaderNew.scss'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import patientImage from '../../assests/images/patientImage.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeaderTab } from '../../store/actions/header'
import { useHistory } from 'react-router-dom'
import { getLoggedInUserName, capitalizeFirstLetter } from '../../utilities'
import { userMenus } from '../../constants/menu'
const useStyles = makeStyles((theme) => ({
    menuItem: {
        // Add your custom styles here
        fontSize: '16px',
        paddingLeft: '8px',
    },
}))
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '& .MuiTab-root': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                // fontWeight: 500,
                fontFamily: 'Rubik',
                borderBottom: `4px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                color: '#242424 !important',
            },
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                fontWeight: 600,
                fontFamily: 'Rubik',
                borderBottom: `4px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                color: '#242424',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)

const headerTabConstantValues = {
    0: 'dashboard',
    1: 'patient-book-appointment',
    2: 'message-care-team',
    3: 'my-health-record',
    4: 'appointments',
}

const HeaderNew = ({ onLogout }) => {
    const history = useHistory()
    const classes = useStyles()
    const currentHeaderTabState = useSelector((state) => state.header.headerTab)
    // console.log('currentHeaderTabState', currentHeaderTabState)
    // const [currentTab, setCurrentTab] = React.useState(currentHeaderTabState)
    const [anchorEl, setAnchorEl] = useState(null)

    const dispatch = useDispatch()
    const loggedinPatientdata = useSelector((state) => state?.login?.userData)

    const memorizing = useMemo(() => loggedinPatientdata, [loggedinPatientdata])
    const userName = capitalizeFirstLetter(getLoggedInUserName())

    const handleChange = (tabName) => {
        // setCurrentTab(tabName)
        dispatch(changeHeaderTab(tabName))
        history.push(`/${headerTabConstantValues[tabName]}`)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onLogoutClick: () => {
            onLogout()
            handleClose()
        },
        onSettingsClick: () => {
            dispatch(changeHeaderTab(3))
            history.push('/my-health-record', { isMenu: true })
            handleClose()
        },
    }
    return (
        <>
            <div className="app_header">
                <div>
                    <Grid container>
                        <Tabs
                            value={currentHeaderTabState}
                            onChange={(_, value) => handleChange(value)}
                            TabIndicatorProps={{ style: { display: 'none' } }}>
                            <CustomTab
                                className="tabText"
                                style={{
                                    background: 'none',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Rubik',
                                    color: '#242424',
                                    fontWeight: 400,
                                }}
                                label="Home"
                            />
                            <CustomTab
                                className="tabText"
                                style={{
                                    background: 'none',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Rubik',
                                    color: '#242424',
                                    fontWeight: 400,
                                }}
                                label="Book an Appointment"
                            />
                            <CustomTab
                                className="tabText"
                                style={{
                                    background: 'none',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Rubik',
                                    color: '#242424',
                                    fontWeight: 400,
                                }}
                                label="Message Care Team"
                            />
                            <CustomTab
                                className="tabText"
                                style={{
                                    background: 'none',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Rubik',
                                    color: '#242424',
                                    fontWeight: 400,
                                }}
                                label="My Health Record"
                            />
                            <CustomTab
                                className="tabText"
                                style={{
                                    background: 'none',
                                    textTransform: 'capitalize',
                                    fontFamily: 'Rubik',
                                    color: '#242424',
                                    fontWeight: 400,
                                }}
                                label="Appointments "
                            />
                        </Tabs>
                    </Grid>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
                    <Typography className="hello">Hello,</Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            textAlign: 'center',
                        }}>
                        <span className="patientNameText">{userName}</span>
                    </div>
                    {/* <img className="user_image" src={loggedinPatientdata?.photo} /> */}
                    {/* <div className="user_image">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 70 73"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="34.9064" cy="36.5" rx="34.763" ry="36.5" fill="#D9D9D9" />
                        </svg>
                    </div> */}
                    <IconButton>
                        {Boolean(anchorEl) ? (
                            <KeyboardArrowUpIcon
                                style={{
                                    fontSize: 20,
                                    color: '#060D21',
                                    marginLeft: 8,
                                }}
                            />
                        ) : (
                            <KeyboardArrowDownIcon
                                style={{
                                    fontSize: 20,
                                    color: '#060D21',
                                    marginLeft: 8,
                                }}
                            />
                        )}
                    </IconButton>
                </div>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 130,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {userMenus.map((menu, index) => (
                    <MenuItem
                        id={`auto-header-${menu.name}`}
                        style={{ color: `${menu.name === 'Log out' ? '#E81A1A' : '#516983'}` }}
                        key={index}
                        onClick={handleFunc[menu.action]}
                        className={classes.menuItem}>
                        <img
                            className="user-menu-icon"
                            src={menu.icon}
                            alt="user-profile-icon"
                            style={{ marginRight: '8px' }}
                        />
                        {menu.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default HeaderNew
