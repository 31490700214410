import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import MenuBar from './components/MenuBar/MenuBar'
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar'
// import PreJoinScreens from "./components/PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification'
import ProviderReconnectingNotification from './components/ProviderReconnectingNotification/ProviderReconnectingNotification'
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import Room from './components/Room/Room'
// import GTranslateIcon from '@mui/icons-material/GTranslate';
// import VideoChat from "../TwilioVideoPatient/VideoChat";

import useHeight from './hooks/useHeight/useHeight'
import { useHistory } from 'react-router-dom'
import { pushMessageSocket } from '../../containers/ClinicalNotes/api/services'
import { useLocation } from 'react-router-dom'
import { config } from '../../constants'
import { TranslateServie } from './components/MenuBar/Menu/TranslateServie'
import { Box } from '@mui/material'
import { StateContext } from './state'

const Container = styled('div')({
    // display: "grid",
    // gridTemplateRows: "1fr auto",
    height: '100vh !important',
})

// const ButtonTranslate = styled("div")(({ theme }: { theme: Theme }) => ({
//     right: '65px',
//     bottom: '64px',
//     position: 'absolute',
//     background: '#10172a',
//     display: 'flex',
//     width: '50px',
//     height: '50px',
//     justifyContent: 'center',
//     alignItems: 'center',
//     borderRadius: '16px',
//     color: '#fff',
//     cursor: 'pointer',
// }));

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
    overflow: 'hidden',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
        // paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
    },
}))

export default function Appointment() {
    let location = useLocation()
    const { triggerTranslate, setTriggerTranslate, stopTranslate, setStopTranslate } =
        useContext(StateContext)
    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.
    // console.log(location,'log location')
    const height = useHeight()
    const history = useHistory()
    const [openAddParticipant, setOpenAddParticipant] = useState(false)

    const handleOpenAddParticipant = () => {
        setOpenAddParticipant(!openAddParticipant)
    }

    const [connectionId, setConnectionId] = useState('')
    const [isReconnect, setIsReconnect] = useState(false)
    const [messageHistory, setMessageHistory] = useState([])
    console.log('location?.state', location?.state)
    const token = location?.state?.token
    const type = location?.state?.title === 'patient' ? 'patient' : 'participant'
    const typeWithPId = `${type}????${location?.state?.encounterId}`
    console.log('enc :: ', typeWithPId)
    const { sendMessage, lastMessage, readyState } = useWebSocket(
        `${config.dev.socketExamRoom}?authorization=${token}&type=${typeWithPId}`,
    )

    // console.log(lastMessage, 'log lastMessage')

    useEffect(() => {
        let dataUrlLink = localStorage.getItem('urlLink')
        const linkWaitingRoom = localStorage.getItem('consultation_link')
        if (window.performance) {
            if (performance.navigation.type == 1) {
                history.push(`/video?data=${encodeURIComponent(dataUrlLink)}`)
            }
        }
        if (lastMessage !== null) {
            const message = JSON.parse(lastMessage?.data)
            setConnectionId(message.connectionId)
            const action = message?.action
            const status = message?.data?.status
            const stop = message?.data?.action
            switch (action) {
                case 'encounter_status':
                    switch (status) {
                        case 'COMPLETED':
                            history.push(`/end-meeting`, {
                                message: message.data,
                                providerName: location?.state?.providerName,
                            })
                            break

                        default:
                            history.push(`/video/:data`, {
                                message: message.data,
                                providerName: location?.state?.providerName,
                            })
                            break
                    }
                    break
                case 'end_success':
                    history.push(`/end-meeting`, {
                        message: message.data,
                        providerName: location?.state?.providerName,
                    })
                    break
                case 'get_out_pre_exam_room':
                    setIsReconnect(true)
                    break
                case 'exam_room_disconnected':
                    setIsReconnect(false)
                    break
                case 'exam_room_disconnected':
                    setIsReconnect(false)
                    break
                case 'exam_room_member':
                    setIsReconnect(false)
                    break
                case 'start_translation':
                    if (stop === 'stop') {
                        setStopTranslate(stopTranslate + 1)
                    } else {
                        setTriggerTranslate({
                            token: message?.data.token,
                            target_lang: message?.data.from_lang,
                            from_lang: message?.data.target_lang,
                            region: message?.data.region,
                        })
                    }

                    break
                default:
                    console.log('action', action)
                    break
            }
            //   switch (action) {
            //     case 'end_success':
            //         history.push(`/end-meeting` ,{message: message.data, providerName: location?.state?.providerName});
            //         break;
            //     case 'back_to_waiting_room':
            //         history.push(`/${linkWaitingRoom}`);
            //         break;
            //     case 'end_failed':
            //         history.push(`/end-meeting`, {message: message.data, providerName: location?.state?.providerName});
            //         break;
            //     case 'assign_to_provider':
            //         history.push(`/${linkWaitingRoom}`);
            //         break;
            //     case 'kick':
            //         history.push(`/end-meeting`, {message: message.data, providerName: location?.state?.providerName});
            //         break;
            //     case 'exam_room_disconnected':
            //         setIsReconnect(true)
            //         break;
            //     case 'member':
            //         setIsReconnect(false)
            //         break;
            //     default:
            //       console.log('action', action)
            //         break;
            // }
        }
    }, [lastMessage, setMessageHistory])

    return (
        <Container style={{ height }}>
            <Main>
                <ReconnectingNotification />
                <ProviderReconnectingNotification open={isReconnect} />
                {/* <MobileTopMenuBar /> */}
                <Room
                    openAddParticipant={openAddParticipant}
                    handleOpenAddParticipant={() => {
                        setOpenAddParticipant(!openAddParticipant)
                    }}
                />
                <MenuBar
                    openAddParticipant={openAddParticipant}
                    handleOpenAddParticipant={() => {
                        setOpenAddParticipant(!openAddParticipant)
                    }}
                />
            </Main>
        </Container>
    )
}
