//     Mobile no	OTP		Role
//     1111111111 - 111111	tm2u_super_admin
//     2222222222 - 222222	tm2u_admin
//     3333333333 - 333333	clinic_coordination
//     4444444444 - 444444	provider
//     5555555555 - 555555	Patient
//     6666666666 - 666666 	billing_admin

export const users = Object.freeze({
    1111111111: 111111,
    2222222222: 222222,
    3333333333: 333333,
    4444444444: 444444,
    5555555555: 555555,
    6666666666: 666666,
})

export const getTestOTP = Object.values(users)
export const getTestUserPhone = Object.keys(users)
