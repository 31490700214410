import React from 'react'

import { Box } from '@material-ui/core'
import Mic from '@material-ui/icons/Mic'
import Switch from '@material-ui/core/Switch'
import MicIcon from '../../../assests/icons/mic.svg'
import { IconMuteMic } from '../../TwilioVideo/icon'

const AudioSettings = ({ hasAudio, onAudioChange, className }) => {
    return (
        // <div className={className}>
        //   <Mic />
        //   <div>Microphone</div>
        //   <Switch checked={hasAudio} onChange={onAudioChange} name="AudioToggle" />
        // </div>
        <Box className={className} onClick={onAudioChange}>
            {hasAudio ? <img src={MicIcon} alt="TeleMed2U" /> : <IconMuteMic />}
        </Box>
    )
}

export default AudioSettings
