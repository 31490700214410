/* eslint-disable react/jsx-indent */
import {
    Button,
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    getUserListAccess,
    UserRoles,
} from '../../../constants/user-roles'
import { getNotificationPreferences } from '../../../services/Notification'
import {
    getActiveProvidersList,
    getClientList,
    getSpecialityList,
    handleGetProviderProfile,
    resetProviderProfile,
} from '../../../store/actions/provider'
import {
    getLoggedInUserId,
    getLoggedInUserRole,
} from '../../../utilities'
import ProfileTitle from '../Common/ProfileTitle'
import '../MainProfile/MainProfile.scss'
import ProfileEdit from './ProfileEdit'
import Notification from './ProviderSection/Notification'
import ProviderBackground from './ProviderSection/ProviderBackground'
import ProviderEducation from './ProviderSection/ProviderEducation'
import { ButtonEl } from '../../../shared/elements'
class ProviderProfileDetails extends Component {
    state = {
        isEdit: false,
        isCreate: false,
        isView: false,
        isProfile: false,
        preferences: {
            email: false,
            sms: false,
            app_notification: false,
        },
        userRole: getLoggedInUserRole(),
    }

    componentDidMount() {
        const {
            isEditMode,
            isCreateMode,
            isViewMode,
            isUserProfile,
            isProfile,
        } = this.props

        this.setState({
            isEdit: !!isEditMode,
            isCreate: !!isCreateMode,
            isView: !!isViewMode,
            isUserProfile,
            isProfile,
        })

        // get profile data
        this.fetchProviderProfile()
        if (
            this.state.userRole ===
            UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
    }

    fetchNotificationPreferences = async () => {
        try {
            const userId = this.props.isUserProfile
                ? this.props.userId
                : getLoggedInUserId()
            const preferences =
                await getNotificationPreferences({
                    userId,
                })
            this.setState({
                ...this.state,
                preferences:
                    preferences.data?.data ??
                    this.state.preferences,
            })
        } catch (error) {
            console.log(
                'Failed to retrieve notification preferences',
            )
        }
    }

    fetchProviderProfile = () => {
        const {
            isCreateMode,
            isUserProfile,
            userId,
            providerProfile,
            actions: {
                handleGetProviderProfile,
                getSpecialityList,
                getClientList,
                getActiveProvidersList,
            },
            isProfile,
        } = this.props

        const profileId = isUserProfile
            ? userId
            : getLoggedInUserId()

        if (
            (isUserProfile &&
                !isCreateMode &&
                !Object.keys(providerProfile).length) ||
            isProfile
        ) {
            handleGetProviderProfile(profileId)
            getSpecialityList()
            getClientList()
            getActiveProvidersList()
        }
    }

    handleEdit = () => {
        const { isEdit, isView } = this.state
        this.setState({ isEdit: !isEdit, isView: !isView })
    }
    handleCloseEdit = () => {
        const { isEdit, isView } = this.state
        if (
            this.state.userRole ===
            UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
        this.setState({ isEdit: !isEdit, isView: !isView })
    }
    componentWillUnmount() {
        const {
            actions: { resetProviderProfile },
        } = this.props
        resetProviderProfile()
    }

    render() {
        const { isCreate, isView, preferences } = this.state

        const {
            providerProfile,
            toggleToUserList,
            isProvider,
            selectedUserType,
        } = this.props

        const currentLoggedInUserRole =
            getLoggedInUserRole()
        const access = selectedUserType
            ? getUserListAccess(
                  currentLoggedInUserRole,
                  selectedUserType,
              )
            : { edit: true }

        return !isCreate &&
            !Object.keys(providerProfile).length ? (
            <CircularProgress className="loader" />
        ) : (
            <>
                <ProfileTitle
                    profileDetails={providerProfile[0]}
                    isCreate={isCreate}
                    isProvider={true}
                    onEditCallback={this.handleCloseEdit}
                    photoEditAccess={!isView}
                />
                {isView ? (
                    <>
                        <Grid
                            container
                            className="bio-main-wrap">
                            <Grid
                                container
                                xs={12}
                                justifyContent="flex-end">
                                {access && access?.edit ? (
                                    <ButtonEl
                                        variant="contained"
                                        startIcon={
                                            <EditIcon />
                                        }
                                        onClick={
                                            this.handleEdit
                                        }>
                                        Edit
                                    </ButtonEl>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    className="bio-title"
                                    variant="h3">
                                    Bio
                                </Typography>
                                <Typography
                                    className="bio-desc"
                                    variant="subtitle2">
                                    {
                                        providerProfile[0]
                                            ?.desc
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className="bio-main-wrap">
                            <Grid item xs={12}>
                                <Typography
                                    className="bio-title"
                                    variant="h3">
                                    Education and Background
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className=" mt15">
                                <ProviderEducation
                                    providerProfile={
                                        providerProfile[0]
                                    }
                                />
                                <ProviderBackground
                                    providerProfile={
                                        providerProfile[0]
                                    }
                                />
                                {/** Notification */}
                                {/* {this.state.userRole ===
                                    UserRoles.TM2U_SUPER_ADMIN && (
                                    <Notification
                                        preferences={
                                            preferences
                                        }
                                    />
                                )} */}
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <ProfileEdit
                        profileDetails={providerProfile[0]}
                        toggleToUserList={isCreate}
                        onSaveCallback={toggleToUserList}
                        isCreate={isCreate}
                        onEditCallback={
                            this.handleCloseEdit
                        }
                        preferences={preferences}
                    />
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        provider: { providerProfile },
    } = state

    return { providerProfile }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    handleGetProviderProfile,
                    resetProviderProfile,
                    getSpecialityList,
                    getClientList,
                    getActiveProvidersList,
                },
                dispatch,
            ),
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProviderProfileDetails)
