import {
    Grid,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'
import { APPOINTMENT_TYPE, EVENT_TYPE } from '../constant'

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 200,
        width: 200,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        shadow: 'rgba(0,0,0,0.25) 10px 0 0',
        padding: '20px',
        borderRadius: '0',
    },
}))(Tooltip)

function MonthCalendarEvent(props) {
    if (props.event.type === EVENT_TYPE.TIMEOFF) {
        return (
            <HtmlTooltip
                title={<ToolTipContent {...props.event} />}
                placement="top">
                <Grid container className="event-wrap">
                    <Typography noWrap variant="body1">
                        <strong>{props.title}</strong>
                    </Typography>
                </Grid>
            </HtmlTooltip>
        )
    }

    if (props.event.type === EVENT_TYPE.BUSY_SLOT) {
        return (
            <Grid container className="event-wrap">
                <Typography noWrap variant="body1">
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>
        )
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="event-wrap">
            <Grid item xs={12} className="time">
                <Typography noWrap>
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>
        </Grid>
    )
}

function SlotToolTipContent(props) {
    return (
        <div className="tooltip-wrapper">
            <div style={{ marginBottom: '10px' }}>
                <span className="date">
                    {props.slot.start_date.format(
                        'ddd DD MMM, YYYY',
                    )}
                </span>
                <span className="time">
                    {props.slot.start_date.format(
                        'hh:mm A',
                    )}
                </span>
            </div>

            {props.appointment?.patient?.name && (
                <Grid
                    container
                    style={{ marginBottom: '5px' }}>
                    <Grid item xs={4}>
                        <span className="gray">
                            Patient:
                        </span>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            noWrap
                            className="black capitalize">
                            {props.appointment?.patient
                                ?.name ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container style={{ marginBottom: '5px' }}>
                <Grid item xs={4}>
                    <span className="gray">With:</span>
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        noWrap
                        className="black capitalize">
                        {props.appointment.provider.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '5px' }}>
                <Grid item xs={4}>
                    <span className="gray">
                        Speciality:
                    </span>
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        noWrap
                        className="black capitalize">
                        {
                            props.appointment.provider
                                .speciality?.name
                        }
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '5px' }}>
                <Grid item xs={4}>
                    <span className="gray">Type:</span>
                </Grid>
                <Grid item xs={8}>
                    <Typography noWrap className="black">
                        {props.appointment.type ===
                        APPOINTMENT_TYPE.TELE_HEALTH
                            ? 'Tele-health'
                            : 'In-clinic'}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

function ToolTipContent(props) {
    return (
        <div className="tooltip-wrapper">
            <div style={{ marginBottom: '10px' }}>
                <Typography variant="subtitle1">
                    <strong>
                        {moment(props.start).format(
                            'ddd DD MMM, YYYY',
                        )}{' '}
                    </strong>
                </Typography>
                <Typography noWrap variant="caption">
                    {moment(props.start).format('hh:mm A')}{' '}
                    - {moment(props.end).format('hh:mm A')}
                </Typography>
            </div>
        </div>
    )
}

export default MonthCalendarEvent
