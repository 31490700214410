import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Typography } from '@material-ui/core'
import { ReactComponent as RadioInvertedCheck } from '../../../../../assests/icons/RadioInvertedCheck.svg'
// import { Router } from 'express'

const BookingWith = ({ name, value, setValue }) => {
    return (
        <div className="booking_boxes">
            <div>
                <Typography className="step-headers">
                    Is this your first time booking with Telemed2U?
                </Typography>
            </div>
            <FormControl className="card-raw">
                <RadioGroup
                    value={value}
                    onChange={(event) => setValue(event.target.value, name)}
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="booking_radio_group">
                    <FormControlLabel
                        className="booking_box"
                        value="yes"
                        control={
                            <Radio
                                checkedIcon={<RadioInvertedCheck />}
                                className="first_time_booking_radio"
                            />
                        }
                        label="Yes, this is my first time"
                    />
                    <FormControlLabel
                        className="booking_box"
                        value="no"
                        control={
                            <Radio
                                checkedIcon={<RadioInvertedCheck />}
                                className="first_time_booking_radio"
                            />
                        }
                        label="No"
                    />
                </RadioGroup>
            </FormControl>
            {/* <div><input type='radio'/><span>Yes, this is my first time</span></div> */}
        </div>
    )
}

export default BookingWith
