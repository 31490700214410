import React, { PureComponent } from 'react'
import { ReactComponent as ViewIcon } from '../../../assests/images/icons_view_profile.svg'
import { ReactComponent as EditIcon } from '../../../assests/images/icons_edit_profile.svg'
import { ReactComponent as UserActiveIcon } from '../../../assests/images/icon_user_active.svg'
import { ReactComponent as UserInActiveIcon } from '../../../assests/images/icon_user_inactive.svg'
import { STATUS_CODE } from '../../../constants/appointments'

export default class TableRows extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            timestamp: new Date().getTime(),
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableData !== this.props.tableData) {
            this.setState({
                timestamp: new Date().getTime(),
            })
        }
    }

    handleActionViewClick = (userId) => {
        const { onActionViewClick } = this.props
        onActionViewClick(userId)
    }

    handleActionEditClick = (userId) => {
        const { onActionEditClick } = this.props
        onActionEditClick(userId)
    }

    handleActionStatusClick = (userId, isActive) => {
        const { onActionStatusClick } = this.props
        onActionStatusClick(userId, isActive)
    }
    handleActionFollowup = (appointmentId) => {
        const { onActionFollowup } = this.props
        onActionFollowup(appointmentId)
    }
    handleActionReschduled = (appointmentId) => {
        const { onActionReschduled } = this.props
        onActionReschduled(appointmentId)
    }
    handleActionCancel = (userId) => {
        const { onActionCancel } = this.props
        onActionCancel(userId)
    }

    render() {
        const {
            tableData,
            headerRefKeys,
            isAppointment,
            access,
            selectedUserType,
        } = this.props

        return (
            <tbody>
                {tableData.map((datum, index) => {
                    const {
                        user_id: userId,
                        is_active: isActive,
                        isfollowupRequired,
                        status,
                        appointmentId,
                    } = datum
                    const isDisabled = [
                        STATUS_CODE.CANCELLED,
                        STATUS_CODE.FULFILLED,
                        STATUS_CODE.PROPOSED,
                    ].includes(status)
                    return (
                        <tr key={index}>
                            {headerRefKeys.map(
                                (
                                    { refKey, type },
                                    index,
                                ) => {
                                    if (refKey === '-1') {
                                        return null
                                    }

                                    return (
                                        <td
                                            key={`key-${refKey}`}
                                            className={`custom_table__body__cell 
                                    custom_table__cell
                                    custom_table__body__cell-${refKey}`}>
                                            {type ===
                                            'custom'
                                                ? datum[
                                                      refKey
                                                  ]
                                                      .childrenComponent
                                                : datum[
                                                      refKey
                                                  ] ===
                                                      '-1' ||
                                                  datum[
                                                      refKey
                                                  ]
                                                      .length ===
                                                      0
                                                ? '--'
                                                : datum[
                                                      refKey
                                                  ]}
                                        </td>
                                    )
                                },
                            )}
                            <td
                                key="key-actions"
                                className="custom_table__cell custom_table__body__cell-actions">
                                {isAppointment ? (
                                    <div className="div_container_appointment">
                                        {isfollowupRequired &&
                                            status ===
                                                STATUS_CODE.FULFILLED && (
                                                <span
                                                    className="listing_action_link"
                                                    onClick={(
                                                        e,
                                                    ) =>
                                                        this.handleActionFollowup(
                                                            appointmentId,
                                                        )
                                                    }
                                                    role="presentation">
                                                    Follow-up
                                                </span>
                                            )}
                                        {status ===
                                            STATUS_CODE.CANCELLED && (
                                            <span
                                                className="cancelled_cell"
                                                role="presentation">
                                                Cancelled
                                            </span>
                                        )}
                                        {!isDisabled && (
                                            <>
                                                <span
                                                    className="listing_action_link"
                                                    onClick={(
                                                        e,
                                                    ) => {
                                                        this.handleActionReschduled(
                                                            appointmentId,
                                                        )
                                                    }}
                                                    role="presentation">
                                                    Reschedule
                                                </span>
                                                <span
                                                    className="listing_action_link"
                                                    onClick={(
                                                        e,
                                                    ) =>
                                                        this.handleActionCancel(
                                                            userId,
                                                        )
                                                    }
                                                    role="presentation">
                                                    Cancel
                                                </span>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        {access &&
                                        access.view ? (
                                            <span
                                                key={`${this.state.timestamp}-view`}
                                                title="View"
                                                onClick={() =>
                                                    this.handleActionViewClick(
                                                        userId,
                                                    )
                                                }
                                                className={
                                                    !isActive &&
                                                    'custom_table__body__cell-disabled'
                                                }
                                                role="presentation">
                                                <ViewIcon />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                        {access &&
                                        access.edit ? (
                                            <span
                                                key={`${this.state.timestamp}-edit`}
                                                title="Edit"
                                                onClick={(
                                                    e,
                                                ) =>
                                                    this.handleActionEditClick(
                                                        userId,
                                                    )
                                                }
                                                role="presentation"
                                                className={
                                                    !isActive &&
                                                    'custom_table__body__cell-disabled'
                                                }>
                                                <EditIcon />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                        <span
                                            title={
                                                isActive
                                                    ? 'Active'
                                                    : 'Inactive'
                                            }
                                            onClick={() =>
                                                access &&
                                                access.status &&
                                                (selectedUserType ===
                                                'Patients'
                                                    ? isActive
                                                    : true) &&
                                                this.handleActionStatusClick(
                                                    userId,
                                                    isActive,
                                                )
                                            }
                                            role="presentation"
                                            style={
                                                access &&
                                                access.status
                                                    ? selectedUserType ===
                                                          'Patients' &&
                                                      !isActive
                                                        ? {
                                                              cursor: 'not-allowed',
                                                          }
                                                        : {
                                                              cursor: 'pointer',
                                                          }
                                                    : {
                                                          cursor: 'not-allowed',
                                                      }
                                            }>
                                            {isActive ? (
                                                <UserActiveIcon
                                                    key={`${this.state.timestamp}-active`}
                                                />
                                            ) : (
                                                <UserInActiveIcon
                                                    key={`${this.state.timestamp}-inactive`}
                                                />
                                            )}
                                        </span>
                                    </>
                                )}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        )
    }
}
