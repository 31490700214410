export const TIMEZONE_OPTONS = [
    {
        utc: '-05:00',
        key: 'US/Eastern',
        value: '(UTC-05:00) US/Eastern',
        dst: 'EDT',
        label: 'Eastern Time - EST',
        abbr: 'EST',
    },
    {
        utc: '-06:00',
        key: 'US/Central',
        value: '(UTC-06:00) US/Central',
        dst: 'CDT',
        label: 'Central Time - CST',
        abbr: 'CST',
    },
    {
        utc: '-07:00',
        key: 'US/Mountain',
        value: '(UTC-07:00) US/Mountain',
        dst: 'MDT',
        label: 'Mountain Time - MST',
        abbr: 'MST',
    },
    {
        utc: '-08:00',
        key: 'US/Pacific',
        value: '(UTC-08:00) US/Pacific',
        dst: 'PDT',
        label: 'Pacific Time - PST',
        abbr: 'PST',
    },
    {
        utc: '-09:00',
        key: 'US/Alaska',
        value: '(UTC-09:00) US/Alaska',
        dst: 'AKDT',
        label: 'Alaskan Time - AKST',
        abbr: 'AKST',
    },
    {
        utc: '-10:00',
        key: 'US/Hawaii',
        value: '(UTC-10:00) US/Hawaii',
        dst: 'HDT',
        label: 'Hawaiian Time - HAST',
        abbr: 'HAST',
    },
]
