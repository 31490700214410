import { useRef, useEffect } from 'react'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles({
    container: {
        position: 'relative',
        margin: '0 0.2em 0',
        height: '4px',
        '& div': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
        },
    },
    progress: {
        background: '#14B053',
        right: '100%',
    },
    background: {
        right: 0,
        background: alpha('#E1E3EA', 0.2),
    },
    progressContainer: {
        width: '100%',
        display: 'flex',
    },
    progressSegment: {
        width: '2%',
        height: '14px',
        borderRadius: '2px',
        marginRight: '4px',
        border: '0.3px solid #118944',
    },
    green: {
        background: '#15A452',
        top: '100%',
    },
    white: {
        background: '#FFF',
        border: '0.3px solid #BFBFBF',
    },
})

interface ProgressBarProps {
    duration: number
    position: number
    style?: { [key: string]: string }
}
function firstDigit(num: number) {
    // 1: convert absolute form to string and get length of integer
    const len = String(Math.abs(num)).length
    const divisor = 10 ** (len - 1)
    // 2: get integer part from result of division
    return Math.trunc(num / divisor) * 3
}
export default function ProgressBar({ duration, position, style }: ProgressBarProps) {
    const classes = useStyles()
    const progressBarRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const el = progressBarRef.current
        if (el) {
            window.requestAnimationFrame(() => {
                // We set these values asynchronously so that the browser can recognize the change in the 'right' value.
                // Without this, the progress bar would instantly snap to the designated position.
                el.style.transition = `right ${duration}s linear`
                el.style.top = `${Math.max(0, 100 - position)}%`
            })
        }
    }, [duration, position])

    return (
        // <div className={classes.container} style={{ ...style }} data-testid="progressBarContainer">
        //   <div className={classes.progress} ref={progressBarRef} data-testid="progressBar"></div>
        //   <div className={classes.background}></div>
        // </div>
        <div className={classes.progressContainer}>
            {/* {[1,2,3,4,5,6].map((item,i)=>(
            <div className={`${classes.progressSegment} ${classes.green}`} key={i} ref={progressBarRef}/>
        ))} */}
            {Array.from({ length: 31 }, (_, i) => i + 1).map((item, i) => (
                <div
                    className={`${classes.progressSegment} ${
                        item <= firstDigit(Math.round(position)) ? classes.green : classes.white
                    }`}
                    key={i}
                />
            ))}
        </div>
    )
}
