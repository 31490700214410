import * as dates from 'date-arithmetic'
import PropTypes from 'prop-types'
import React from 'react'
import { Navigate } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import moment from 'moment'
class CustomWeekView extends React.Component {
    render() {
        let {
            date,
            localizer,
            min = localizer.startOf(new Date(), 'day'),
            max = localizer.endOf(new Date(), 'day'),
            scrollToTime = localizer.startOf(
                new Date(),
                'day',
            ),
        } = this.props
        let range = CustomWeekView.range(date, {
            localizer,
        })

        return (
            <TimeGrid
                {...this.props}
                range={range}
                eventOffset={15}
                localizer={localizer}
                min={min}
                max={max}
                scrollToTime={scrollToTime}
            />
        )
    }
}

CustomWeekView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.any,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
}

CustomWeekView.range = (date, { localizer }) => {
    let start = moment(date).startOf('week').toDate()
    let end = moment(date).endOf('week').toDate()

    let current = start
    let range = []

    while (localizer.lte(current, end, 'day')) {
        range.push(current)
        current = localizer.add(current, 1, 'day')
    }

    return range
}

CustomWeekView.navigate = (date, action, { localizer }) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -7, 'day')

        case Navigate.NEXT:
            return localizer.add(date, 7, 'day')

        default:
            return date
    }
}

CustomWeekView.title = (date) => {
    return `My awesome week: ${date.toLocaleDateString()}`
}

export default CustomWeekView
