import React from 'react'

import { Box } from '@material-ui/core'
import Videocam from '@material-ui/icons/Videocam'
import Switch from '@material-ui/core/Switch'
import VideoIcon from '../../../assests/icons/video.svg'
import { IconDisableVideo } from '../../TwilioVideo/icon'

const VideoSettings = React.memo(({ hasVideo, onVideoChange, className }) => {
    return (
        // <div className={className}>
        //   <Videocam />
        //   <div>Video</div>
        //   <Switch checked={hasVideo} onChange={onVideoChange} name="VideoToggle" />
        // </div>
        <Box className={className} onClick={onVideoChange}>
            {hasVideo ? <img src={VideoIcon} alt="TeleMed2U" /> : <IconDisableVideo />}
        </Box>
    )
})

export default VideoSettings
