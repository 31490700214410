import { makeStyles, Theme } from '@material-ui/core'

export const ChatsStyle = makeStyles((theme: Theme) => ({
    chats: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 8
    },
    chatMessage: {
        maxWidth: '80%',
        display: 'flex',
        flexDirection: 'column',
    },
    chatText: {
        gap: 8,
        overflowX: 'hidden',
        borderRadius: '12px',
        flex: 1,
        flexDirection: 'column',
        padding: '8px 16px',
        display: 'flex',
        fontSize: '13px',
        color: '#667689',
        fontWeight: 400,
    },
    chatValue: {
        margin: 0,
        opacity: 1,
        whiteSpace: 'break-spaces',
        width: "100%",
        wordBreak: 'break-all'
    },
    chatTime: {
        fontSize: '12px',
        marginTop: 10,
        color: '#9CA9B8',
    },
    chatAvatar: {
        width: 'auto',
        fontSize: '1rem',
        fontWeight: 500,
    },
    profileImageContainer: {
        display: 'flex',
        gap:8 
    },
    marginRight: {
        marginRight: '0.5rem',
    },
    marginLeft: {
        marginLeft: '0.5rem',
    },
    chatInitial: {
        height: '40px',
        width: '40px',
        borderRadius: '25px',
        textAlign: 'center',
    },
    nameInitial: {
        color: 'white',
        fontSize: '18px',
        lineHeight: '40px',
    },
}))
