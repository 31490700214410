import React from 'react'
import { UserRoles } from '../../../constants/user-roles'
import CalendarScheduler from '../../../containers/CalendarScheduler'
import Documents from '../../../containers/Documents'
import TimeOffs from '../../Timeoffs/Timeoffs'
import MainProfile from '../MainProfile/MainProfile'
import ProviderProfileDetails from './ProviderProfileDetails'

const ProviderProfile = (props) => {
    const [selectedTab, setSelectedTab] =
        React.useState(null)

    const userInfo = React.useMemo(
        () => ({
            id: props.userId,
        }),
        [props.userId],
    )

    const handleSelectedTab = (tab) => {
        setSelectedTab(tab)
    }

    const providerTabs = [
        {
            label: 'profile',
            components: (
                <ProviderProfileDetails {...props} />
            ),
        },
        // {
        //     label: 'Schedule',
        //     components: (
        //         <CalendarScheduler
        //             providerId={userInfo.id}
        //             role={UserRoles.PROVIDER}
        //             date={selectedTab?.date}
        //         />
        //     ),
        // },
        // {
        //     label: 'Documents',
        //     components: <Documents userid={props.userId} />,
        // },
        // {
        //     label: 'Time-offs',
        //     components: (
        //         <TimeOffs
        //             userid={props.userId}
        //             handleSelectedTab={handleSelectedTab}
        //         />
        //     ),
        // },
    ]

    return (
        <MainProfile
            tabsList={providerTabs}
            isCreateMode={props.isCreateMode}
            selectedTab={selectedTab}
        />
    )
}

export default ProviderProfile
