import {
    Box,
    Card,
    CardContent,
    IconButton,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import HeaderIcon from '../../assests/sidebar/cstmtu.svg'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import { getPatientByUserId } from '../../services/Patient/patient'
import {
    CardHeaderEl,
    NoRowsOverlayEl,
} from '../../shared/elements'
import * as actionTypes from './actionTypes'
import { initialState, reducer } from './reducer'

function MinorsCard(props) {
    const history = useHistory()
    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    const columns = [
        {
            headerName: (
                <Typography variant="body2">
                    Name
                </Typography>
            ),
            field: 'name',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography className="capitalize">
                        {`${params.row.patient?.first_name} ${params.row.patient?.last_name}`}
                    </Typography>
                )
            },
        },
        {
            headerName: <Typography>DOB</Typography>,
            field: 'dob',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography className="capitalize">
                        {moment(
                            params.row.patient?.dob,
                        ).format('MM/DD/YYYY')}
                    </Typography>
                )
            },
        },
    ]

    React.useEffect(() => {
        fetchMinors()
    }, [])

    async function fetchMinors() {
        try {
            dispatch({ type: actionTypes.MINORS_LOADING })
            const response = await getPatientByUserId(
                props.userId,
            )

            dispatch({
                type: actionTypes.MINORS_SUCCESS,
                data: response.data.minors,
            })
        } catch (error) {
            if (error?.status === 404) {
                return dispatch({
                    type: actionTypes.MINORS_SUCCESS,
                    data: [],
                })
            }

            dispatch({
                type: actionTypes.MINORS_FAILURE,
            })
        }
    }

    return (
        <Card
            className="MuiDataGrid-table-card-wrapper minor-wrap"
            style={{ height: 386, width: '100%' }}>
            <CardHeaderEl
                title="Minor"
                avatar={
                    <Box style={{ height: 28, width: 28 }}>
                        <img src={HeaderIcon} />
                    </Box>
                }
                action={
                    <IconButton
                        disableFocusRipple={true}
                        disableRipple={true}
                        size={'small'}
                        onClick={() =>
                            history.push(Routes.PROFILE, {
                                userId: props.userId,
                                role: props.role,
                            })
                        }>
                        <Typography
                            color="primary"
                            variant="body2">
                            Add
                        </Typography>
                    </IconButton>
                }
            />
            <CardContent style={{ padding: 0 }}>
                <div
                    style={{
                        height: 350,
                        width: '100%',
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={state.minors.data.slice(0, 5)}
                        columns={columns}
                        disableColumnSelector
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnMenu
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl
                                    title={
                                        !state.minors
                                            .isFailure
                                            ? 'No Minors Found.'
                                            : 'Failed to retrieve minors'
                                    }
                                />
                            ),
                        }}
                        rowHeight={52}
                        hideFooter
                        loading={state.minors.isLoading}
                    />
                </div>
            </CardContent>
        </Card>
    )
}

export default MinorsCard
