import React from 'react'
import './MedicationsStyles.scss'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import { CircularProgress, Tab, Tabs, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as SortIcon } from '../../assests/icons/Sorting.svg'
import { getHealthConditions, getMedications } from '../../services/PatientHealth'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import moment from 'moment'
import { dateFormat } from '../utils'
import { useSelector } from 'react-redux'

/*
    {
    "id": "102174",
    "status": "active",
    "drug": {
        "id": "55513084301",
        "code": "55513084301",
        "display": "erenumab-aooe",
        "rCopiaID": "12100000099140",
        "nDCID": "55513084301",
        "rxnormID": "",
        "firstDataBankMedID": "599175",
        "brandName": "Aimovig Autoinjector",
        "genericName": "erenumab-aooe",
        "strength": "140 mg/mL",
        "form": "auto-injector"
    },
    "patient_id": "102082",
    "practitioner_id": "94344",
    "strength": "140 mg/mL",
    "form": "auto-injector",
    "start_date": "",
    "stop_date": "",
    "external_id": "",
    "rcopia_id": "27383739323",
    "encounter_id": "",
    "how_long": "",
    "therapeutic_effect": "",
    "side_effects": "",
    "reason_for_stopping": "",
    "frequency": {},
    "dose": "",
    "dose_unit": "",
    "day_supply": {},
    "action": {
        "code": "Hold",
        "display": "Hold"
    },
    "route": {},
    "other": {},
    "action_status": "New Order",
    "provider_first_name": "cxtestnew",
    "provider_last_name": "BHprovider"
}
*/

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: '0 8px',
        background: 'none',
        color: 'black',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        width: '100px',
        height: '35px',
        minHeight: '35px !important',
        '&.Mui-selected': {
            background: '#5571C6',
            borderRadius: '5px',
            color: 'white',
            border: '1px solid #3F62C9',
        },
    },
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))((props) => <Tab {...props} />)

const MedicationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.33333 11.9167C9.86462 11.9167 11.9167 9.86462 11.9167 7.33333C11.9167 4.80205 9.86462 2.75 7.33333 2.75C4.80205 2.75 2.75 4.80205 2.75 7.33333C2.75 9.86462 4.80205 11.9167 7.33333 11.9167ZM9.30586 7.0163C9.53989 6.91983 9.6514 6.65193 9.55497 6.4179C9.45853 6.18383 9.19059 6.07232 8.95657 6.16875L5.36085 7.65059C5.12682 7.74703 5.01527 8.01497 5.11175 8.24899C5.20818 8.48302 5.47607 8.59457 5.71015 8.4981L9.30586 7.0163Z"
            fill="#5571C6"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.6667 19.2507C17.198 19.2507 19.25 17.1986 19.25 14.6673C19.25 12.136 17.198 10.084 14.6667 10.084C12.1354 10.084 10.0834 12.136 10.0834 14.6673C10.0834 17.1986 12.1354 19.2507 14.6667 19.2507ZM16.4591 15.5497C16.7058 15.6064 16.9518 15.4524 17.0085 15.2057C17.0652 14.959 16.9112 14.7131 16.6645 14.6563L12.8743 13.785C12.6276 13.7282 12.3816 13.8822 12.3249 14.129C12.2682 14.3756 12.4222 14.6216 12.6689 14.6783L16.4591 15.5497Z"
            fill="#5571C6"
        />
    </svg>
)

function Main() {
    const history = useHistory()
    const notification = useNotification()

    const [currentTab, setCurrentTab] = React.useState('active') // values: 'active', 'inactive'
    const [medications, setMedications] = React.useState([])
    const [activeMedications, setActiveMedications] = React.useState([])
    const [inactiveMedications, setInactiveMedications] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [sort, setSort] = React.useState({
        active: false,
        inactive: false,
    })
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)

    const flattendata = (data) => {
        if (!data || data.length === 0) return []

        return data.map((item) => {
            return {
                title: `${item?.drug?.brandName}, ${item?.drug?.genericName}`,
                // type: item?.status,
                id: item?.id,
                strength: item?.drug?.strength,
                form: item?.drug?.form,
                date_prescribed: item?.start_date
                    ? moment(item?.start_date)
                          .tz(loggedinPatiendata?.timezone || 'US/Pacific')
                          .format('MM/DD/YYYY')
                    : 'N/A',
                prescribed_by: `${item?.provider_first_name} ${item?.provider_last_name}`,
            }
        })
    }

    const medicationList = () => {
        currentTab === 'active'
            ? setMedications(flattendata(activeMedications))
            : setMedications(flattendata(inactiveMedications))
    }

    const getAllMedications = (type) => {
        setLoading(true)
        const payload = {
            page: 1,
            limit: 100,
            type,
        }
        getMedications(payload)
            ?.then((res) => {
                const responseData = res?.data?.data?.data
                const active = []
                const inactive = []
                responseData.forEach((medication) => {
                    if (
                        medication?.stop_date &&
                        dateFormat(medication?.stop_date).isSameOrBefore(
                            moment().tz(loggedinPatiendata?.timezone || 'US/Pacific'),
                            'day',
                        )
                    )
                        return inactive.push(medication)

                    active.push(medication)
                })
                setActiveMedications(active)
                setInactiveMedications(inactive)
                // setMedications(flattendata(responseData))
            })
            .catch(() => {
                notification('Something went wrong!', 'error')
            })
            .finally(() => setLoading(false))
    }

    const goToPage = () => history.push('/my-health-record')

    const handleSort = () => {
        setSort((state) => {
            return {
                ...state,
                [currentTab]: !state[currentTab],
            }
        })
        medications?.reverse()
    }

    React.useEffect(() => {
        getAllMedications(currentTab)
    }, [])

    React.useEffect(() => {
        medicationList()
    }, [currentTab, inactiveMedications, activeMedications])

    return (
        <div className="medication_main_container">
            <div className="medication_sub_container">
                <div className="medication_back_container" onClick={goToPage}>
                    <BackIcon />
                    <Typography>Back to health record</Typography>
                </div>
                <div className="medication_body">
                    <div className="medication_body_header">
                        <MedicationIcon />
                        <Typography>Medications</Typography>
                    </div>
                    <div className="medications_body_contents">
                        <Tabs
                            style={{
                                minHeight: 'auto',
                                background: '#EEF6FE',
                                width: 'max-content',
                                borderRadius: 5,
                            }}
                            TabIndicatorProps={{ style: { display: 'none' } }}
                            value={currentTab}
                            onChange={(_, value) => setCurrentTab(value)}>
                            <CustomTab value="active" label="Active" size="small" />
                            <CustomTab value="inactive" label="Inactive" size="small" />
                        </Tabs>

                        <div className="medications_tab_contents">
                            <div className="medications_tab_content_headers">
                                <Typography className="medications_tab_content_header">
                                    {currentTab} Medications
                                </Typography>
                            </div>

                            <div className="medications_tab_content_third_row">
                                <span className="medications_tab_content_count">
                                    {loading ? (
                                        <CircularProgress
                                            style={{
                                                color: '#667689',
                                                height: 15,
                                                width: 15,
                                                marginRight: 3,
                                            }}
                                        />
                                    ) : (
                                        <Typography>{medications?.length}</Typography>
                                    )}
                                    <Typography>{currentTab} medications</Typography>
                                </span>
                                <span
                                    onClick={handleSort}
                                    style={{
                                        pointerEvents:
                                            loading || medications?.length === 0 ? 'none' : 'auto',
                                    }}
                                    className="medication_tab_content_sorting">
                                    <SortIcon />
                                    <Typography>
                                        {sort[currentTab] ? 'Oldest to newest' : 'Newest to oldest'}
                                    </Typography>
                                </span>
                            </div>

                            {loading ? (
                                <div className="medication_cards_loader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="medications_card_container">
                                    {medications?.length > 0 &&
                                        medications?.map((medicationItem) => {
                                            return (
                                                <div
                                                    key={medicationItem?.id}
                                                    className="medications_cards">
                                                    <div className="medications_cards_contents">
                                                        <Typography
                                                            style={{
                                                                color: '#303E4E',
                                                                fontSize: 17,
                                                                fontWeight: 500,
                                                            }}>
                                                            {medicationItem?.title}
                                                        </Typography>
                                                        <div className="medications_body_fields">
                                                            <div className="medications_body_fields_part">
                                                                <span>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#667689',
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        Strength
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#303E4E',
                                                                            fontSize: 16,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        {medicationItem?.strength}
                                                                    </Typography>
                                                                </span>
                                                                <span>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#667689',
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        Form
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#303E4E',
                                                                            fontSize: 16,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        {medicationItem?.form}
                                                                    </Typography>
                                                                </span>
                                                            </div>
                                                            <div className="medications_body_fields_part">
                                                                <span>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#667689',
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        Date Prescribed
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#303E4E',
                                                                            fontSize: 16,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        {
                                                                            medicationItem?.date_prescribed
                                                                        }
                                                                    </Typography>
                                                                </span>
                                                                <span>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#667689',
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        Prescribed By
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: '#303E4E',
                                                                            fontSize: 16,
                                                                            fontWeight: 400,
                                                                        }}>
                                                                        {
                                                                            medicationItem?.prescribed_by
                                                                        }
                                                                    </Typography>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="medications_card_type">
                                                        <span
                                                            style={{
                                                                color:
                                                                    currentTab === 'active'
                                                                        ? '#14AE5C'
                                                                        : '#FFAC0C',
                                                                background:
                                                                    currentTab === 'active'
                                                                        ? '#EBFFF3'
                                                                        : '#FEEDC6',
                                                            }}>
                                                            {currentTab === 'active'
                                                                ? 'Active'
                                                                : 'Inactive'}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
