import React from 'react'
import {
    Grid,
    makeStyles,
    Switch,
    Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        width: '50px',
        height: '24px',
        padding: '0px',
    },
    switchBase: {
        color: '#303E4E',
        padding: '1px',
        '&$checked': {
            '& + $track': {
                backgroundColor: '#5571C6',
            },
        },
    },
    thumb: {
        color: 'white',
        width: '20px',
        height: '20px',
        margin: '1px 0',
    },
    track: {
        borderRadius: '20px',
        backgroundColor: '#303E4E',
        opacity: '1 !important',
        '&:after, &:before': {
            color: 'white',
            fontSize: '11px',
            position: 'absolute',
            top: '5px',
        },
        '&:after': {
            content: "'On'",
            left: '6px',
        },
        '&:before': {
            content: "'Off'",
            right: '7px',
        },
    },
    checked: {
        color: '#23bf58 !important',
        transform: 'translateX(26px) !important',
    },
})

function NotificationPreferences(props) {
    const classes = useStyles()
    return (
        <Grid container>
            {/* lg={4} xs={6} */}
            <Grid item lg={3}>
                <Typography
                    variant="subtitle2"
                    className="notification-label">
                    {props.label}
                </Typography>
            </Grid>
            <Grid item lg={3}>
                <Switch
                    classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked,
                    }}
                    checked={props.value}
                    inputProps={{
                        'aria-label': props.label,
                    }}
                    {...props}
                />
            </Grid>
        </Grid>
    )
}

export default NotificationPreferences
