import React from 'react';

export default function TeleAddParticipantIcon({width =32, height =32, stroke = "#303E4E"}) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0001 15.5C19.4902 15.5 22.3196 12.6707 22.3196 9.18053C22.3196 5.69039 19.4902 2.86108 16.0001 2.86108C12.51 2.86108 9.68066 5.69039 9.68066 9.18053C9.68066 12.6707 12.51 15.5 16.0001 15.5Z" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.14331 28.1387C5.14331 23.2475 10.0093 19.2915 16.0001 19.2915C17.2134 19.2915 18.3889 19.4558 19.4885 19.7591" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.6389 23.0831C28.6389 23.4876 28.5884 23.8794 28.4873 24.2586C28.3735 24.7641 28.1713 25.257 27.9059 25.6868C27.0338 27.1529 25.4287 28.1387 23.5834 28.1387C22.2816 28.1387 21.1062 27.6457 20.2214 26.8369C19.8423 26.5082 19.5136 26.1165 19.2609 25.6868C18.7932 24.9284 18.5278 24.0311 18.5278 23.0831C18.5278 21.7181 19.0713 20.4669 19.956 19.5569C20.8787 18.609 22.1678 18.0276 23.5834 18.0276C25.0748 18.0276 26.4271 18.6722 27.3371 19.7086C28.146 20.6059 28.6389 21.794 28.6389 23.0831Z" stroke={stroke} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.4666 23.0579H21.7002" stroke={stroke} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.5835 21.2126V24.9917" stroke={stroke} stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
}
