import { Button, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useHistory, useLocation } from 'react-router'
import * as React from 'react'
import ProviderLate from '../../assests/icons/ProviderLate.svg'
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-root': {
            height: '253px !important',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'auto',
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '461px !important',
        },
    },
    btn_delete: {
        // fontSize: 15,
        // width: 'calc((100% - 10px) / 2)',
        // borderRadius: 12,
        // fontWeight: 'normal',
        borderRadius: '5px',
        background: '#5571C6',
        color: '#fff',
        display: 'flex',
        width: '184px',
        height: '28px',
        padding: '12px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: '0',
    },
    footer_btn: {
        marginTop: '10px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '15px ',
        justifyContent: 'center',
    },
    providerImg: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
    headText: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',

        color: '#303E4E',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
    },
    subHeadText: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',

        color: '#303E4E',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
    },
}))

export default function AlertProviderLate({ open, handleClose }) {
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '461px',
                },
            }}
            fullWidth={true}>
            <div className={classes.providerImg}>
                <img src={ProviderLate} alt="" />
            </div>
            <Typography className={classes.headText}>Your provider is running late!</Typography>
            <Typography className={classes.subHeadText}>
                Thank you for your patience, your session will begin soon.
            </Typography>
            <Box className={classes.footer_btn}>
                <Button
                    onClick={() => {
                        handleClose()
                    }}
                    autoFocus
                    variant="contained"
                    color="primary"
                    className={classes.btn_delete}>
                    Dismiss
                </Button>
            </Box>
        </Dialog>
    )
}
