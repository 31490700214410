import React from 'react'

import {
    Grid,
    Typography,
    FormLabel,
} from '@material-ui/core'
import { capitalizeFirstLetter } from '../../../../utilities'
const PatientPharmacy = (props) => {
    const { patientProfile } = props
    return (
        <>
            <Grid container className="borderBottom mb2">
                <Grid item xs={6} lg={6} className="pTB3">
                    <Grid
                        item
                        xs={12}
                        className="card-title-align">
                        <Typography
                            variant="subtitle1"
                            className="card-title">
                            Pharmacy
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Pharmacy Name
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .pharmacy
                                            ?.pharmacy_name ||
                                            '',
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={5}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Address
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .pharmacy
                                            ?.address_line_1 ||
                                            '',
                                    )}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .pharmacy
                                            ?.address_line_2 ||
                                            '',
                                    )}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .pharmacy
                                            ?.city || '',
                                    )}
                                </Typography>
                                <Typography className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            .pharmacy
                                            ?.state || '',
                                    )}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {
                                        patientProfile
                                            .pharmacy?.zip
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default PatientPharmacy
