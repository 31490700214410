const Tms2uLogo = () => {
    return (
        <svg
            width="112"
            height="50"
            viewBox="0 0 112 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2957_49279)">
                <path
                    d="M63.3405 39.0886L26.7574 46.1154V36.9745H24.1573V49.2625L65.9404 41.2363V36.9001H63.3403V39.0886H63.3405ZM24.1573 0V12.1569H26.7574V3.14712L63.3405 10.1764V17.0765H65.9406V8.02605L24.1573 0Z"
                    fill="#1C2A54"
                />
                <path
                    d="M6.75108 16.8087V34.7848H4.23611V16.8087H0V14.4788H10.9869V16.8087H6.75108Z"
                    fill="#1C2A54"
                />
                <path
                    d="M20.9164 30.2839C20.9164 33.2758 19.2743 34.9439 15.25 34.9439C11.1203 34.9439 9.53137 33.4347 9.53137 29.2778V24.7509C9.53137 20.7003 11.7292 19.2177 15.25 19.2177C18.7182 19.2177 20.9685 20.7268 20.9685 24.7509V28.0608H12.0204V29.2252C12.0204 31.9788 13.0262 32.6143 15.25 32.6143C17.4741 32.6143 18.4803 31.8724 18.4803 30.2839H20.9164ZM12.0204 25.8628H18.5597V24.7509C18.5597 22.0238 17.2359 21.4415 15.25 21.4415C13.2649 21.4415 12.0204 21.9977 12.0204 24.7509V25.8628Z"
                    fill="#1C2A54"
                />
                <path
                    d="M24.2256 14.347H26.6883V34.9441H24.2256V14.347Z"
                    fill="#1C2A54"
                />
                <path
                    d="M41.8587 30.2839C41.8587 33.2758 40.2165 34.9439 36.1927 34.9439C32.063 34.9439 30.4741 33.4347 30.4741 29.2778V24.7509C30.4741 20.7003 32.6717 19.2177 36.1927 19.2177C39.6611 19.2177 41.9114 20.7268 41.9114 24.7509V28.0608H32.9631V29.2252C32.9631 31.9788 33.9688 32.6143 36.1927 32.6143C38.4167 32.6143 39.4227 31.8724 39.4227 30.2839H41.8587ZM32.9631 25.8628H39.502V24.7509C39.502 22.0238 38.1784 21.4415 36.1927 21.4415C34.2073 21.4415 32.9631 21.9977 32.9631 24.7509V25.8628Z"
                    fill="#1C2A54"
                />
                <path
                    d="M46.36 17.6031L46.5456 22.9777V34.7851H45.1686V14.347H46.6244L51.02 27.2132L55.4412 14.347H56.8967V34.7851H55.5467V22.9777L55.705 17.6031L51.2586 30.1253H50.8079L46.36 17.6031Z"
                    fill="#5571C6"
                />
                <path
                    d="M69.288 30.5754C69.288 33.3814 67.8586 34.8645 64.708 34.8645C61.3718 34.8645 59.8101 33.3289 59.8101 29.1194V24.9627C59.8101 20.7269 61.3719 19.1118 64.708 19.1118C68.2821 19.1118 69.473 20.8858 69.473 24.9627V27.2665H61.0808V29.0133C61.0808 32.1638 61.8227 33.673 64.708 33.673C67.5406 33.673 68.0966 32.4023 68.0966 30.5755H69.288V30.5754ZM68.3086 26.0479V24.9887C68.3086 22.0238 67.7787 20.2238 64.708 20.2238C61.8488 20.2238 61.0808 21.6532 61.0808 24.9097V26.0479H68.3086Z"
                    fill="#5571C6"
                />
                <path
                    d="M72.3068 29.2778V24.2743C72.3068 20.6207 74.1336 19.1118 77.2045 19.1118C78.8194 19.1118 79.9306 19.509 80.7783 20.4884V13.2875H82.1022V34.785H80.938L80.8052 33.0904C80.0642 34.1757 78.8716 34.8645 76.9656 34.8645C73.7097 34.8645 72.3068 33.3289 72.3068 29.2778ZM77.0985 33.6199C79.7992 33.5672 80.7783 32.1108 80.7783 29.596V24.4866C80.8052 21.5476 80.0642 20.3298 77.2045 20.3298C74.3452 20.3298 73.6303 21.7329 73.6303 24.3276V29.2515C73.6302 32.7199 74.7694 33.6199 77.0985 33.6199Z"
                    fill="#5571C6"
                />
                <path
                    d="M85.4656 30.761C85.4656 22.1039 94.0962 24.3011 94.0962 19.4032C94.0962 17.3912 93.144 16.4117 90.9992 16.4117C89.0403 16.4117 87.9537 17.4706 87.9537 19.8267H85.4388C85.4388 15.8557 87.6895 14.1086 91.1052 14.1086C94.4933 14.1086 96.6373 15.7495 96.6373 19.3771C96.6373 26.7634 88.0343 23.5066 88.0343 30.7344V32.2964H96.4791V34.7852H85.4656V30.761Z"
                    fill="#4C8080"
                />
                <path
                    d="M109.399 14.347H111.94V28.0609C111.94 32.5344 110.511 34.9439 105.666 34.9439C100.821 34.9439 99.3388 32.5344 99.3388 28.0609V14.3729H101.88V28.087C101.88 31.3168 102.516 32.588 105.666 32.588C108.817 32.588 109.399 31.3168 109.399 28.087V14.347Z"
                    fill="#1C2A54"
                />
            </g>
            <defs>
                <clipPath id="clip0_2957_49279">
                    <rect
                        width="111.94"
                        height="50"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default Tms2uLogo
