import axios from "axios";
import { config } from "../../../constants";

const methods = {
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Headers":
        //     "Origin, X-Requested-With, Content-Type, Accept",
    },
};


async function validateLink(data: any) {
    try {
        return await axios.post(
            `${config.dev.baseURLV2}/telehealth/validateLink`,
            data,
            methods,
        );
    } catch (err) {
        throw err;
    }
}

export default validateLink;
