import React, { useState } from 'react'
import { Grid, MenuItem, Select } from '@material-ui/core'
import { Field, getIn } from 'formik'

const ProviderLangageSpokenView = ({
    language,
    index,
    fieldArrayProps,
    setFieldValue,
    initialLanguageSpoken,
    allValues,
    handleBlur,
    onBlur,
    setFieldTouched,
}) => {
    const options = [
        { label: 'English', value: 'en' },
        { label: 'Spanish', value: 'es' },
        { label: 'German', value: 'de' },
    ]
    const { push, remove } = fieldArrayProps
    const [isDuplicateError, setIsDuplicateError] =
        useState(false)
    const ErrorMessage = ({ name }) => (
        <Field
            name={name}
            render={({ form }) => {
                if (
                    form.errors &&
                    form.errors.user?.language &&
                    !Array.isArray(
                        form.errors.user?.language,
                    ) &&
                    form.errors.user?.language.includes(
                        'Duplicate',
                    )
                ) {
                    setIsDuplicateError(true)
                    const error = getIn(
                        form.errors,
                        'user.language',
                    )
                    const touch = getIn(form.touched, name)
                    return error ? (
                        <div className="errormsg">
                            {error}
                        </div>
                    ) : null
                }
                setIsDuplicateError(false)

                const error = getIn(
                    form.errors,
                    'user.language',
                )
                const touch = getIn(form.touched, name)
                return touch && error ? (
                    <div className="errormsg">{error}</div>
                ) : null
            }}
        />
    )
    return (
        <Grid container spacing={2} key={index}>
            <Grid item xs={5} className={'mb1'}>
                <Field
                    name={`user.language[${index}]`}
                    component={Select}
                    fullWidth
                    variant="outlined"
                    placeholder="Language Spoken"
                    onChange={(e) => {
                        setFieldValue(
                            `user.language[${index}]`,
                            e.target.value,
                        )
                    }}
                    value={language}
                    onBlur={(e) =>
                        setFieldTouched(
                            `user.language[${index}]`,
                            true,
                        )
                    }>
                    <MenuItem disabled value="none">
                        <span className="opacity05">
                            {'Select Language'}
                        </span>
                    </MenuItem>
                    {options.map((item) => (
                        <MenuItem
                            value={item.value}
                            key={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Field>

                <span>
                    {index !== 0 &&
                        index === allValues.length - 1 &&
                        isDuplicateError && (
                            <ErrorMessage
                                name={`user.language[${index}]`}
                            />
                        )}
                    {!isDuplicateError && (
                        <ErrorMessage
                            name={`user.language[${index}]`}
                        />
                    )}
                </span>
            </Grid>

            <Grid item xs={2} className="flex">
                {index !== 0 &&
                    index === allValues.length - 1 && (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(initialLanguageSpoken)
                            }>
                            +
                        </button>
                    )}

                {index === 0 &&
                    (allValues.length === 1 ? (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(initialLanguageSpoken)
                            }>
                            +
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() => remove(index)}>
                            -
                        </button>
                    ))}
                {index !== 0 && (
                    <button
                        type="button"
                        className="plus-wrap "
                        onClick={() => remove(index)}>
                        -
                    </button>
                )}
            </Grid>
        </Grid>
    )
}

export default ProviderLangageSpokenView
