import React from 'react'
import { ReactComponent as SortIcon } from '../../../assests/images/icon_sort.svg'
import {
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    dFlex: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

export default function GridHeader(props) {
    const classes = useStyles()
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between">
            <Grid item xs={10} md={10}>
                <Typography noWrap variant="body1">
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>
            <Grid
                item
                md={2}
                xs={
                    props?.isSortIconEnd ? 2 : 2
                } /* Check point */
                className={
                    props?.isSortIconEnd && classes.dFlex
                }>
                <IconButton
                    onClick={() =>
                        props.onSort({ field: props.field })
                    }>
                    <SortIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}
