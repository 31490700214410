import axios from "axios";
import { config } from "../../../constants";
const axiosInstance = axios.create({
  baseURL: config.dev.baseURLV2,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  }
})

axiosInstance.interceptors.request.use(
  (requestConfig) => {
      let token = localStorage.getItem('token')
      requestConfig.headers[
          'Authorization'
      ] = `Bearer ${token}`
      requestConfig.headers[
          'Content-Type'
      ] = `application/json`
      if (requestConfig.data?.source) {
          requestConfig['cancelToken'] =
              requestConfig.data.source.token
          delete requestConfig.data.source
      }
      return requestConfig
  },
  (error) => {
      console.log(error)
      return Promise.reject(error)
  },
)


async function getProvidersDetails(id: any) {
  try {
    //dev.telemed2u.com/login-service/providers/findById/1
    https: return await axiosInstance.get(
      `${config.dev.baseURL}/providers/findById/${id}`
    );
  } catch (err) {
    throw err;
  }
}
async function getAppointmentDetails(id: any) {
  try {
    //dev.telemed2u.com/login-service/providers/findById/1
    console.log("config",config.dev)
    https: return await axios.get(
      `${config.dev.baseURL}/appointment/details/${id}`
    );
  } catch (err) {
    throw err;
  }
}
async function getPatientDetails(id: any) {
  console.log("toucccch");
  
  try {
    https: return await axios.get(
      `/patients/${id}`
    );
  } catch (err) {
    throw err;
  }
}
async function getParticipantDetails(id: any) {
  try {
    //dev.telemed2u.com/login-service/providers/findById/1
    https: return await axios.get(
      `${config.dev.baseURL}/participants/${id}`
    );
  } catch (err) {
    throw err;
  }
}

export {
  getPatientDetails,
  getProvidersDetails,
  getParticipantDetails,
  getAppointmentDetails,
};
