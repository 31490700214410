import React from 'react'

export default function IconMuteMic() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.11125 11.7569C7.71264 11.7569 7.38208 11.4263 7.38208 11.0277V6.16658C7.38208 3.61936 9.45291 1.54852 12.0001 1.54852C14.5474 1.54852 16.6182 3.61936 16.6182 6.16658V6.45825C16.6182 6.85686 16.2876 7.18741 15.889 7.18741C15.4904 7.18741 15.1599 6.85686 15.1599 6.45825V6.16658C15.1599 4.4263 13.7404 3.00686 12.0001 3.00686C10.2599 3.00686 8.84041 4.4263 8.84041 6.16658V11.0277C8.84041 11.4263 8.50986 11.7569 8.11125 11.7569Z"
                fill="#303E4E"
            />
            <path
                d="M12.0003 16.1319C10.6975 16.1319 9.45305 15.5777 8.57805 14.6152C8.30583 14.3138 8.33499 13.8569 8.62666 13.5846C8.92805 13.3124 9.38499 13.3416 9.65722 13.6332C10.2503 14.2944 11.1058 14.6735 12.0003 14.6735C13.7406 14.6735 15.16 13.2541 15.16 11.5138V11.0277C15.16 10.6291 15.4906 10.2985 15.8892 10.2985C16.2878 10.2985 16.6183 10.6291 16.6183 11.0277V11.5138C16.6183 14.061 14.5475 16.1319 12.0003 16.1319Z"
                fill="#292D32"
            />
            <path
                d="M12.0001 19.5346C9.92922 19.5346 7.94589 18.7569 6.42922 17.3471C6.13756 17.0749 6.11811 16.6082 6.39033 16.3166C6.67228 16.0152 7.13894 16.0055 7.43061 16.2777C8.67506 17.4346 10.2987 18.0763 12.0001 18.0763C15.6945 18.0763 18.7084 15.0624 18.7084 11.368V9.71519C18.7084 9.31658 19.0389 8.98602 19.4376 8.98602C19.8362 8.98602 20.1667 9.31658 20.1667 9.71519V11.368C20.1667 15.8694 16.5014 19.5346 12.0001 19.5346Z"
                fill="#292D32"
            />
            <path
                d="M5.146 14.9944C4.86405 14.9944 4.59183 14.8291 4.47516 14.5471C4.04739 13.536 3.8335 12.4763 3.8335 11.368V9.71519C3.8335 9.31658 4.16405 8.98602 4.56266 8.98602C4.96127 8.98602 5.29183 9.31658 5.29183 9.71519V11.368C5.29183 12.2721 5.46683 13.1471 5.81683 13.9735C5.97239 14.343 5.79739 14.7707 5.42794 14.9263C5.34044 14.9749 5.24322 14.9944 5.146 14.9944Z"
                fill="#292D32"
            />
            <path
                d="M4.1447 19.5249C3.95998 19.5249 3.77526 19.4569 3.62943 19.311C3.34748 19.0291 3.34748 18.5624 3.62943 18.2805L19.3308 2.57907C19.6128 2.29713 20.0794 2.29713 20.3614 2.57907C20.6433 2.86102 20.6433 3.32768 20.3614 3.60963L4.6697 19.311C4.52387 19.4569 4.33915 19.5249 4.1447 19.5249Z"
                fill="#292D32"
            />
            <path
                d="M11.028 6.89575C10.6294 6.89575 10.2988 6.5652 10.2988 6.16659V3.24992C10.2988 2.85131 10.6294 2.52075 11.028 2.52075C11.4266 2.52075 11.7572 2.85131 11.7572 3.24992V6.16659C11.7572 6.5652 11.4266 6.89575 11.028 6.89575Z"
                fill="#292D32"
            />
            <path
                d="M12.0002 22.4513C11.6016 22.4513 11.271 22.1207 11.271 21.7221V18.8055C11.271 18.4068 11.6016 18.0763 12.0002 18.0763C12.3988 18.0763 12.7293 18.4068 12.7293 18.8055V21.7221C12.7293 22.1207 12.3988 22.4513 12.0002 22.4513Z"
                fill="#292D32"
            />
        </svg>
    )
}
