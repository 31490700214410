import { Grid, Typography } from '@material-ui/core'
import userIcon from '../../../../assests/images/icon_user.svg'
import provider_license from '../../../../assests/images/Provider_license.svg'
import provider_taxo from '../../../../assests/images/Provider_taxonamy.svg'
import provider_education from '../../../../assests/images/Provider_education.svg'
import provider_cer from '../../../../assests/images/Provider_certification.svg'
import provider_mem from '../../../../assests/images/Provider_p_membership.svg'
import moment from 'moment'

const ProviderEducation = (props) => {
    const { providerProfile } = props
    return (
        <Grid
            item
            xs={12}
            md={6}
            className="provider-education-info">
            <Grid item xs={12} className="dFlex">
                <Grid item xs={1}>
                    <img
                        src={provider_license}
                        height={35}
                        width={35}
                        alt="camera"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        License
                    </Typography>
                    {providerProfile?.licenses &&
                        Array.isArray(
                            providerProfile?.licenses,
                        ) &&
                        providerProfile?.licenses.map(
                            (item, i) => (
                                <>
                                    <Grid key={item}>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            {`${item.state_name} ${item.license_number}`}
                                        </Typography>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            Expires On -{' '}
                                            {moment(
                                                item.expiry_date,
                                            ).format(
                                                'MM/DD/YYYY',
                                            )}
                                        </Typography>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            DEA No.{' '}
                                            {
                                                item.dea_number
                                            }
                                        </Typography>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            NPI No.{' '}
                                            {
                                                providerProfile?.npi_number
                                            }
                                        </Typography>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            MPN No.{' '}
                                            {
                                                providerProfile?.mpn_number
                                            }
                                        </Typography>
                                    </Grid>
                                </>
                            ),
                        )}
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={provider_taxo}
                        height={35}
                        width={35}
                        alt="camera"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Taxonomy Code
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {providerProfile?.taxonomy_code}
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        Group Tax id{' '}
                        {providerProfile?.group_tax_id}
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        Group NPI No.{' '}
                        {providerProfile?.group_npi_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={provider_education}
                        height={35}
                        width={35}
                        alt="camera"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Education and Training
                    </Typography>
                    {providerProfile?.education &&
                        Array.isArray(
                            providerProfile?.education,
                        ) &&
                        providerProfile?.education.map(
                            (item, i) => {
                                if (item !== null) {
                                    return (
                                        <>
                                            <Grid
                                                key={item}>
                                                <Typography
                                                    className="detail-item-title"
                                                    variant="subtitle2">
                                                    {`${item.university}, ${item.education}`}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )
                                }
                            },
                        )}
                    {providerProfile?.training &&
                        Array.isArray(
                            providerProfile?.training,
                        ) &&
                        providerProfile?.training.map(
                            (item, i) => {
                                if (item !== null) {
                                    return (
                                        <>
                                            <Grid
                                                key={item}>
                                                <Typography
                                                    className="detail-item-title"
                                                    variant="subtitle2">
                                                    {`${item.university}, ${item.training}`}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )
                                }
                            },
                        )}
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={provider_cer}
                        height={35}
                        width={35}
                        alt="camera"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Board Certifications
                    </Typography>
                    {providerProfile?.certification &&
                        Array.isArray(
                            providerProfile?.certification,
                        ) &&
                        providerProfile?.certification.map(
                            (item) => (
                                <>
                                    <Typography
                                        className="detail-item-title"
                                        variant="subtitle2">
                                        {item.certified}
                                    </Typography>
                                </>
                            ),
                        )}
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={provider_mem}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Professional Membership
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {
                            providerProfile?.professional_membership
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default ProviderEducation
