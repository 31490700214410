import { Theme, useMediaQuery, Grid } from '@material-ui/core'
import {
    createStyles,
    makeStyles
} from '@material-ui/styles'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import logo_white from '../../../../assests/Logos/tm2u_logo_150.png'
import {
    getPatientCount,
    submitConsulationTracking
} from '../../apis/closingConsultation'
import { getAppointmentDetails } from '../../apis/fetchDetails'
import useChatContext from '../../hooks/useChatContext/useChatContext'
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle/useFlipCameraToggle'
import useProviderContext from '../../hooks/useProviderContext/useProviderContext'
import useRoomState from '../../hooks/useRoomState/useRoomState'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import AboutDialog from '../AboutDialog/AboutDialog'
import AddParticipantDialog from '../AddParticipantDialog/AddParticipantDialog'
import AddPersonButton from '../Buttons/AddPersonButton/AddPersonButton'
import DisconnectButton from '../Buttons/DisconnectButton/DisconnectButton'
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton'
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton'
import DeviceSelectionDialog from '../DeviceSelectionDialog/DeviceSelectionDialog'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: `white`,
            bottom: 0,
            left: 0,
            right: 0,
            height: `${theme.footerHeight}px`,
            position: 'fixed',
            display: 'flex',
            padding: '0 1.43em',
            zIndex: 10,
            [theme.breakpoints.down('sm')]: {
                height: `${theme.mobileFooterHeight}px`,
                padding: 0,
            },
        },
        iconContainer: {
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '1rem',
            cursor: 'pointer',
        },
        footer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: '3rem',
            zIndex: 100,
            margin: 'auto',
            width: 'fit-content',
            background: "#ffffff99",
            backdropFilter: 'brightness(0.5)',
            borderRadius: 30,
            padding: 10,

            [theme.breakpoints.down('sm')]: {
                bottom: '2rem',
                backdropFilter: 'unset'
                // position: "static",
                // marginBottom: "3rem",
                // bottom: '0',
                // position: 'fixed',
                // zIndex: 100,
                // // background: 'rgba(13,17,21,0.8)',
                // padding: '1em',
                // borderRadius: '10px 10px 0px 0px',
                // justifyContent: 'space-between',
            },
        },
        screenShareBanner: {
            position: 'fixed',
            zIndex: 10,
            bottom: `${theme.footerHeight}px`,
            left: 0,
            right: 0,
            height: '104px',
            background: 'rgba(0, 0, 0, 0.5)',
            '& h6': {
                color: 'white',
            },
            '& button': {
                background: 'white',
                color: theme.brand,
                border: `2px solid ${theme.brand}`,
                margin: '0 2em',
                '&:hover': {
                    color: '#600101',
                    border: `2px solid #600101`,
                    background: '#FFE9E7',
                },
            },
        },
        audioVideoTestBtnContainer: {
            position: 'absolute',
            bottom: '3rem',
            right: '3rem',
            zIndex: 110,
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                margin: 'auto',
                marginBottom: '3rem',
                position: 'static',
                display: 'flex',
                alignSelf: 'center',
            },
        },
        flipButton: {
            margin: '0px',
            padding: '0px',
            background: 'rgba(255, 255, 255, 0.6)',
            width: '65px',
            height: '65px',
            borderRadius: '24px',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                background: 'rgba(34,43,55,0.5) !important',
            },
        },
        buttons: {
            margin: '0px 6px',
            padding: '0px',
            background: 'rgba(255, 255, 255, 0.6)',
            width: '65px',
            height: '65px',
            borderRadius: '24px',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                background: 'rgba(34,43,55,0.5) !important',
            },
            [theme.breakpoints.down('sm')]: {
                '&:hover': {
                    background: 'rgba(255, 255, 255, 0.6)!important',
                },
            }

        },
        buttons_mobile: {
            margin: '0px 6px',
            padding: '0px',
            background: 'rgba(61, 70, 98, 0.3)',
            width: '40px',
            minWidth: '40px',
            height: '40px',
            borderRadius: '24px',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                '&:hover': {
                    background: 'rgba(61, 70, 98, 0.3)',
                },
            }
        },
        btn_mobiles_active: {
            background: '#5571C6 !important',
        },

        blueIconButton: {
            margin: '0 1rem',
            padding: '0.8em 0',
            background: '#5571C6',
            width: '50px',
            height: '40px',
            borderRadius: '2rem',
            minWidth: '50px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.7rem',
            '&:hover': {
                background: '#5571C6 !important',
            },
        },

        durationInfoContainer: {
            color: 'white',
            display: 'flex',
            padding: '1rem 2rem',
            background: 'rgba(19,25,31,0.2)',
            flexDirection: 'column',
            margin: '1rem',
            borderRadius: '0.4rem',
            position: 'absolute',
            left: '1.5rem',
            top: '1.5rem',
            zIndex: 100,

            [theme.breakpoints.down('sm')]: {
                // margin: "auto",
                // marginBottom: "3rem",
                // position: "static",
                // display: "flex",
                // alignSelf: "center",
                top: '4em',
                left: '1em',
                padding: '1em',
                margin: 0,
            },
        },
        disconnectButton: {
            background: '#E81212',
            margin: '0px 6px',
            padding: '0px',
            width: '65px',
            height: '65px',
            borderRadius: '24px',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                background: 'rgba(34,43,55,0.5) !important',
            },

        },
        roomNameContainer: {
            zIndex: 100,
            padding: '0 2rem',
            borderRadius: '0.2rem',
            position: 'absolute',
            bottom: '0',
            left: '0',
            color: '#fff',
            background: 'rgba(177, 175, 175 ,0.5)',
        },

        textStyling: {
            fontSize: '1.2rem',
            color: 'white',
            margin: 0,

            '&:hover': {
                color: 'white',
            },
        },
        textWhite: {
            color: '#fff',
        },
        testAudiVideoBtn: {
            borderColor: '#fff',
            color: '#fff',
            borderRadius: '1.4rem',
            padding: '0.4rem 2rem',
        },
        hideMobile: {
            display: 'initial',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        imageContainer: {
            bottom: '51px',
            left: '4rem',
            zIndex: 9,
            position: 'absolute',
            background: '#ffffff',
            padding: '9px',
            width: '143px',
            height: 'auto',
            borderRadius: '10px',
        },
        top_btn_bar: {
            position: 'absolute',
            top: 30,
            right: 14,
            width: 'fit-content',
            zIndex: 9,
        },
        imageContainerMobile: {
            left: '2rem',
            width: 80
        },
        left14: {
            left: '14rem',
        },
    }),
)

export default function MenuBar({ handleOpenAddParticipant, openAddParticipant }) {
    const classes = useStyles()
    const { isSharingScreen, toggleScreenShare, room } =
        useVideoContext()
    const { isScheduleWindowOpen, isChatWindowOpen } =
        useChatContext()
    const {
        isAccessAdmit,
        waitingCount,
        socket,
        currentUser,
        setListWaiting,
        setCountWaitingUser,
        upcoming,
        setupcoming,
        appointmentInfo,
        currentAppointment,
        listWaiting,
        setcurrentAppointment,
        waitingList,
        setWaitingGroup,
    } = useProviderContext()
    const roomState = useRoomState()
    const history = useHistory()
    const location = useLocation()
    const isReconnecting = roomState === 'reconnecting'
    const [
        addParticipantsDialogOpen,
        setAddParticipantsDialogOpen,
    ] = React.useState(false)
    const [deviceSettingsOpen, setDeviceSettingsOpen] =
        React.useState(false)
    const [aboutOpen, setAboutOpen] = React.useState(false)
    const [participantCount, setParticipantCount] =
        React.useState(0)

    const {
        flipCameraDisabled,
        toggleFacingMode,
        flipCameraSupported,
    } = useFlipCameraToggle()
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    )

    const handleGetPatientCount = async () => {
        let data = localStorage.getItem('roomName')
        let payload = { roomName: data }
        let res = await getPatientCount(payload)
        setParticipantCount(res.data.length)
        if (res?.data) {
            handleConsulationTracking(
                currentAppointment?.appointment_id,
                res.data.length,
            )
        }
    }

    const handleConsulationTracking = async (
        appointment_id: any,
        count: any,
    ) => {
        let payload = {
            is_consultation_end: true,
            provider_id: currentUser?.provider_id,
            participants_count: count,
            appointment_id: appointment_id,
        }
        let res = await submitConsulationTracking(payload)
        if (res) {
            // window.location.href = '/'
            window.location.href = '/end-meeting'
        }
    }

    const handleDisconnect = async () => {
        room?.disconnect()
        history.push('/end-meeting', { providerName: location?.state?.providerName })
    }

    const renderDurationInfo = (): React.ReactElement => {
        return (
            <>
                {(upcoming &&
                    upcoming.Upcoming !== 'undefined') ||
                    currentAppointment ? (
                    <div
                        className={clsx(
                            classes.durationInfoContainer,
                            classes.left14,
                        )}>
                        {currentAppointment ? (
                            <p
                                className={
                                    classes.textStyling
                                }>
                                Current appointment: <br />{' '}
                                <strong>
                                    {moment
                                        .utc(
                                            currentAppointment.start,
                                            'HH:mm',
                                        )
                                        .local()
                                        .format(
                                            'hh:mm A',
                                        )}{' '}
                                    to{' '}
                                    {moment
                                        .utc(
                                            currentAppointment.end,
                                            'HH:mm',
                                        )
                                        .local()
                                        .format('hh:mm A')}
                                </strong>
                            </p>
                        ) : null}
                        {upcoming ? (
                            <p
                                className={
                                    classes.textStyling
                                }>
                                Upcoming event:{' '}
                                {upcoming &&
                                    upcoming.Upcoming !=
                                    'undefined' ? (
                                    <strong>
                                        {moment
                                            .utc(
                                                `${upcoming.Upcoming.date} ${upcoming.Upcoming.start}`,
                                            )
                                            .local()
                                            .format(
                                                'hh:mm A',
                                            )}{' '}
                                        to{' '}
                                        {moment
                                            .utc(
                                                `${upcoming.Upcoming.date} ${upcoming.Upcoming.end}`,
                                            )
                                            .local()
                                            .format(
                                                'hh:mm A',
                                            )}
                                    </strong>
                                ) : (
                                    'NA'
                                )}
                            </p>
                        ) : null}
                    </div>
                ) : null}
                {/* {!(!upcoming || (upcoming.Current == "undefined" && upcoming.Upcoming == "undefined")) ?
          <div className={classes.durationInfoContainer}>
            {upcoming.Current != "undefined" ? <p className={classes.textStyling}>
              Current appointment: <strong>{`${upcoming.Current.currentStartTime} to ${upcoming.Current.currentEndTime}`}</strong>
            </p> : null}
            {upcoming.Upcoming != "undefined" ? <p className={classes.textStyling}>
              Upcoming event: <strong>{moment.utc(`${upcoming.Upcoming.date} ${upcoming.Upcoming.start}`).local().format('hh:mm A')} to {moment.utc(`${upcoming.Upcoming.date} ${upcoming.Upcoming.end}`).local().format('hh:mm A')}</strong>
            </p> : null}
          </div>
          : null}
        {Object.keys(appointmentInfo).length > 0 ? <div className={classes.durationInfoContainer}>
          <p className={classes.textStyling}>
            Current appointment: <strong>{moment.utc(`${appointmentInfo.date} ${appointmentInfo.start}`).local().format('hh:mm A')} to {moment.utc(`${appointmentInfo.date} ${appointmentInfo.end}`).local().format('hh:mm A')}</strong>
          </p>
        </div>
          : null} */}
            </>
        )
    }

    useEffect(() => {
        if (currentUser?.is_provider) {
            if (listWaiting.length > 0) {
                let updatedlistWaiting = listWaiting.map(
                    (el: any) => {
                        el.isLoading = false
                        return el
                    },
                )
                let list = _.groupBy(
                    updatedlistWaiting,
                    'appointment_id',
                )
                if (
                    upcoming &&
                    upcoming?.Current &&
                    upcoming?.Current?.id &&
                    !list[upcoming.Current.id]
                ) {
                    let upcoming = {
                        Current: 'undefined',
                        Upcoming: 'undefined',
                    }
                    setupcoming(upcoming)
                    setcurrentAppointment(undefined)
                }

                getupdatedList(list).then((res) => {
                    setWaitingGroup(res)
                })
            } else {
                let upcoming = {
                    Current: 'undefined',
                    Upcoming: 'undefined',
                }
                setupcoming(upcoming)
                setcurrentAppointment(undefined)
                setWaitingGroup([])
            }
        }
    }, [listWaiting])

    const getupdatedList = async (list: any) => {
        return await Promise.all(
            Object.keys(list).map(
                async (el): Promise<any> => {
                    let appointmentData = {}
                    let status = ''
                    if (
                        !(upcoming && upcoming.Current) ||
                        el != upcoming.Current.id
                    ) {
                        let appointmentDetails: any =
                            await getAppointmentDetails(el)
                        if (appointmentDetails) {
                            appointmentData =
                                appointmentDetails.data
                            let startTime = moment
                                .utc(
                                    `${appointmentDetails.data.date} ${appointmentDetails.data.start}`,
                                )
                                .local()
                                .format('hh:mm A')
                            let endTime = moment
                                .utc(
                                    `${appointmentDetails.data.date} ${appointmentDetails.data.end}`,
                                )
                                .local()
                                .format('hh:mm A')
                            let currentTime = moment()
                                .utc()
                                .local()
                                .format('hh:mm A')
                            let beginningTime = moment(
                                startTime,
                                'h:mm a',
                            )
                            let liveTime = moment(
                                currentTime,
                                'h:mm a',
                            )
                            let endingTime = moment(
                                endTime,
                                'h:mm a',
                            )
                            if (
                                liveTime.isBefore(
                                    beginningTime,
                                )
                            ) {
                                status = 'Upcoming Event:'
                            } else if (
                                liveTime.isAfter(endingTime)
                            ) {
                                status = 'Late Event:'
                            } else {
                                status = 'Current Event:'
                            }
                        }
                    } else {
                        appointmentData =
                            upcoming &&
                                upcoming.Current !== 'undefined'
                                ? upcoming.Current
                                : 'undefined'
                        status = 'Ongoing Event'
                    }
                    return {
                        appointment_id: el,
                        ...appointmentData,
                        status: status,
                        list: list[el],
                    }
                },
            ),
        )
    }

    // useEffect(() => {
    //     //Check need to added for provider
    //     let ss = currentAppointment

    //     socket.on('get_waiting_list_user', (data: any) => {
    //         if (
    //             data['client-Message'].room_id ==
    //             currentUser?.room_id
    //         ) {
    //             fetchWaitingList()
    //         }
    //     })
    // }, [])

    return (
        <>
            {!isMobile && <img
                src={logo_white}
                className={clsx({ [classes.imageContainer]: true, [classes.imageContainerMobile]: isMobile })}
                alt="logo_white"
            />}
            {isMobile && location?.state?.title === "patient" && (<Grid container className={classes.top_btn_bar}>
                {/* <AddPersonButton
                    isMobile={true}
                    className={clsx({ [classes.buttons_mobile]: true, [classes.btn_mobiles_active]: openAddParticipant })}
                    onClick={() => {
                        handleOpenAddParticipant();

                    }}
                /> */}
                <ToggleChatButton
                    isMobile={true}
                    className={clsx({ [classes.buttons_mobile]: true, [classes.btn_mobiles_active]: isChatWindowOpen })}
                />
            </Grid>)}

            {renderDurationInfo()}
            <div className={classes.footer}>
                <ToggleAudioButton
                    className={classes.buttons}
                    disabled={isReconnecting}
                    hideLabel
                />

                {isMobile ?
                    <>
                        <DisconnectButton
                            className={classes.disconnectButton}
                            onClick={() => handleDisconnect()}
                        />
                        <ToggleVideoButton
                            className={classes.buttons}
                            disabled={isReconnecting}
                            hideLabel
                        />
                    </> :
                    <>
                        <ToggleVideoButton
                            className={classes.buttons}
                            disabled={isReconnecting}
                            hideLabel
                        />
                        <DisconnectButton
                            className={classes.disconnectButton}
                            onClick={() => handleDisconnect()}
                        />
                    </>}
                {/* {isAccessAdmit ? (
                    <ToggleScheduleButton
                        className={
                            isScheduleWindowOpen
                                ? classes.blueIconButton
                                : classes.buttons
                        }
                        count={waitingCount}
                    />
                ) : (
                    <></>
                )} */}

                {/* {(upcoming &&
                    upcoming.Current &&
                    upcoming.Current.id) ||
                (Object.keys(appointmentInfo).length !==
                    0 &&
                    !currentUser?.participant_id) ? (
                    <AddPersonButton
                        className={classes.buttons}
                        onClick={() =>
                            setAddParticipantsDialogOpen(
                                true,
                            )
                        }
                    />
                ) : null} */}
                {/* {location?.state?.title === "patient" && !isMobile && location?.state?.status !== "NOT_START" && location?.state?.status !== "PRE_EXAM" && location?.state?.status !== "PRE_EXAM_WAITING" && (
                <AddPersonButton
                    className={classes.buttons}
                    onClick={() => {
                        // setAddParticipantsDialogOpen(
                        //     true,
                        // );
                        handleOpenAddParticipant();

                    }}
                />)} */}
                {/* {isMobile && (
                    <FlipCameraButton
                        className={classes.flipButton}
                        disabled={flipCameraDisabled}
                        onClick={toggleFacingMode}
                    />
                )} */}
                {location?.state?.title === "patient" && !isMobile && (
                    <ToggleChatButton
                        className={
                            // isChatWindowOpen
                            //     ? classes.blueIconButton
                            //     : classes.buttons
                            classes.buttons
                        }
                    />
                )}

            </div>

            <AddParticipantDialog
                open={addParticipantsDialogOpen}
                onClose={() => {
                    setAddParticipantsDialogOpen(false)
                }}
            />

            <AboutDialog
                open={aboutOpen}
                onClose={() => {
                    setAboutOpen(false)
                }}
            />

            <DeviceSelectionDialog
                open={deviceSettingsOpen}
                onClose={() => {
                    setDeviceSettingsOpen(false)
                }}
            />
        </>
    )
}
