import VideoCam from '@material-ui/icons/Videocam'
import VideocamOff from '@material-ui/icons/VideocamOff'
import { IconButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import useDevices from '../hooks/useDevices'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import React from 'react'
import styles from './styles.js'
import { UserContext } from '../context/UserContext'

export default function MuteVideoButton({
    classes,
    hasVideo,
    toggleVideo,
    getVideoSource,
    cameraPublishing,
    changeVideoSource,
}) {
    const title = hasVideo ? 'Disable Camera' : 'Enable Camera'
    const { deviceInfo } = useDevices()
    const [devicesAvailable, setDevicesAvailable] = React.useState(null)
    const [options, setOptions] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const localClasses = styles()
    const { user } = React.useContext(UserContext)

    React.useEffect(() => {
        setDevicesAvailable(deviceInfo.videoInputDevices)
        if (cameraPublishing) {
            const currentDeviceId = getVideoSource()?.deviceId

            const IndexOfSelectedElement = devicesAvailable.indexOf(
                devicesAvailable.find((e) => e.deviceId === currentDeviceId),
            )
            setSelectedIndex(IndexOfSelectedElement)
        }
    }, [cameraPublishing, getVideoSource, deviceInfo, devicesAvailable])

    React.useEffect(() => {
        if (devicesAvailable) {
            const videoDevicesAvailable = devicesAvailable.map((e) => {
                return e.label
            })
            setOptions(videoDevicesAvailable)
        }
        // if (user.videoEffects.backgroundBlur)
        //   setOptions(['Not available with Background Blurring']);
    }, [devicesAvailable])

    const handleChangeVideoSource = (event, index) => {
        setSelectedIndex(index)
        setOpen(false)
        const videoDeviceId = devicesAvailable.find(
            (device) => device.label === event.target.textContent,
        ).deviceId
        changeVideoSource(videoDeviceId)
    }

    const handleToggle = (e) => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    return (
        <>
            <ButtonGroup
                className={classes.groupButton}
                disableElevation
                variant="contained"
                ref={anchorRef}
                aria-label="split button">
                <Tooltip title={title} aria-label="add">
                    <IconButton
                        onClick={toggleVideo}
                        edge="start"
                        aria-label="videoCamera"
                        size="small"
                        className={`${classes.arrowButton} ${
                            !hasVideo ? classes.disabledButton : ''
                        }`}>
                        {!hasVideo ? <VideocamOff /> : <VideoCam />}
                    </IconButton>
                </Tooltip>
                <IconButton
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    className={classes.arrowButton}>
                    <ArrowDropDownIcon />
                </IconButton>
            </ButtonGroup>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 101 }} // todo temporary fix for a bug in MP 0.1.5
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={(event) =>
                                                handleChangeVideoSource(event, index)
                                            }
                                            classes={{
                                                selected: localClasses.selected,
                                                root: localClasses.root,
                                            }}
                                            // disabled={user.videoEffects.backgroundBlur}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
