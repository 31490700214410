/* eslint-disable no-use-before-define */
import * as actionType from '../actionType'

import { formatPhone, getNameFormat } from '../../utilities'
import {
    sortAscending,
    sortDescending,
} from '../../shared/utils'

const initialState = {
    providerDetails: null,
    providerList: [],
    calendarDates: [],
    calendarSlots: [],
    providerSearchList: [],
    searchMeta: {
        currentPage: 1,
        totalCount: 0,
    },
    providerProfile: [],
    specialityList: [],
    clinicList: [],
    providerList: [],
    timeoffList: [],
    timeoffsListsort: {
        start_date: 'asc',
        start: 'asc',
        appointments: 'asc',
        status: 'asc',
    },
    encounterQueueList: [],
    openListConsultation: false,
    tokenVideoProvider: '',
    statusAdmit: '',
    statusEndRoom: '',
    statusKickParticipant: '',
    encounterMeetingId: '',
}

const provider = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PROVIDER_DETAILS: {
            return {
                ...state,
                providerDetails: action.value,
            }
        }
        case actionType.SET_PROVIDER_LIST:
            return {
                ...state,
                providerList: action.value,
            }
        case actionType.SET_CALENDAR_DATES:
            return {
                ...state,
                calendarDates: action.value,
            }
        case actionType.SET_CALENDAR_SLOTS:
            return {
                ...state,
                calendarSlots: action.value,
            }
        case actionType.SET_SPECIALITY_LIST:
            return {
                ...state,
                specialityList: action.value,
            }
        case actionType.SET_CLIENT_LIST:
            return {
                ...state,
                clinicList: action.value,
            }
        case actionType.SET_ACTIVE_PROVIDER_LIST:
            return {
                ...state,
                providerList: action.value,
            }
        case actionType.SET_SEARCH_PROVIDER_LIST: {
            const { page, totalCount, provider } =
                action.value

            const providerSearchList = provider?.length
                ? formatProviderSearchResult(provider)
                : []
            // const totalCount =
            //     Math.ceil(totalRecord / 10) ?? 0;

            return {
                ...state,
                providerSearchList,
                searchMeta: {
                    currentPage: page ?? 1,
                    totalCount,
                },
            }
        }
        case actionType.RESET_PROVIDER_PROFILE: {
            return {
                ...state,
                providerProfile: [],
            }
        }
        case actionType.UPDATE_SEARCH_PROVIDER_STATUS: {
            const { userId, status } = action.value

            return {
                ...state,
                providerSearchList:
                    state.providerSearchList.map(
                        (provider) => {
                            const { user_id, is_active } =
                                provider

                            return {
                                ...provider,
                                is_active:
                                    user_id === userId
                                        ? status
                                        : is_active,
                            }
                        },
                    ),
            }
        }
        case actionType.SET_PROVIDER_PROFILE: {
            return {
                ...state,
                providerProfile: action.value,
            }
        }
        case actionType.SET_TIMEOFFS_LIST: {
            return {
                ...state,
                timeoffList: action.value,
            }
        }
        case actionType.GET_LIST_ENCOUNTER_QUEUE: {
            return {
                ...state,
                encounterQueueList: action.value,
            }
        }
        case actionType.SHOW_CONSULTATION_LIST: {
            const { open } = action.value
            return {
                ...state,
                openListConsultation: open,
            }
        }
        case actionType.GET_TOKEN_VIDEO_PROVIDER: {
            return {
                ...state,
                tokenVideoProvider: action.value,
            }
        }
        case actionType.ACTION_ADMIT: {
            return {
                ...state,
                statusAdmit: action.value,
            }
        }
        case actionType.ACTION_END_ROOM: {
            return {
                ...state,
                statusEndRoom: action.value,
            }
        }
        case actionType.ACTION_KICK_PARTICIPANT: {
            return {
                ...state,
                statusKickParticipant: action.value,
            }
        }
        case actionType.SET_ENCOUNTER_MEETING_ID: {
            return {
                ...state,
                encounterMeetingId: action.value,
            }
        }
        case actionType.TIMEOFFS_SORT_DATA:
            return {
                ...state,
                timeoffsListsort: {
                    ...state.timeoffsListsort,
                    [action.value]:
                        state.timeoffsListsort[
                            action.value
                        ] === 'asc'
                            ? 'desc'
                            : 'asc',
                },
                timeoffList:
                    state.timeoffsListsort[action.value] ===
                    'asc'
                        ? sortAscending(
                              state.timeoffList,
                              action.value,
                          )
                        : sortDescending(
                              state.timeoffList,
                              action.value,
                          ),
            }

        case actionType.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

const formatProviderSearchResult = (providerList) =>
    providerList.map((provider) => {
        const {
            first_name: firstName,
            last_name: lastName,
            npi_number: npiNumber,
            user_id,
            associated_clinic: clinics,
            user: { mob, email, is_active },
            speciality,
        } = provider

        const associatedClinics = (clinics || [])
            .map((item) => item?.clinic_name)
            .join(',')

        return {
            providerName: getNameFormat(
                firstName,
                lastName,
            ),
            speciality: speciality?.name ?? '',
            phoneNumber: mob,
            // phoneNumber: formatPhone(
            //     mob?.replace(/\D/g, '')?.slice(-10),
            // ),
            email: email.toLowerCase(),
            npiNumber,
            associatedClinics,
            is_active,
            user_id,
        }
    }) ?? []

export default provider
