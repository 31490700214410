import moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
export const dateFormat = (date) => {
    const parts = date?.split(/[T-]/)
    const year = parts[0]
    const month = parts[1]
    const day = parts[2]
    let temp = moment()
    temp.set('year', year)
    temp.set('month', month - 1)
    temp.set('date', day)
    temp.set('hour', 0)
    temp.set('minute', 0)
    temp.set('second', 0)
    temp.set('millisecond', 0)
    return temp
}

export const mapperFunction = (masterData = [], checkerData) => {
    for (let i = 0; i < checkerData.length; i++) {
        const dataheader = checkerData[i]
        const elementHeader = checkerData[i].header
        for (let j = 0; j < masterData.length; j++) {
            const masterDataReview = masterData[j].reviews_options
            // console.log(masterDataReview, "I am master dataReview options")
            const data = masterData[j]._id
            if (data === elementHeader) {
                dataheader.header = { _id: data, header_name: masterData[j].header_name }
                if (dataheader.reviews_options.length) {
                    for (let ik = 0; ik < dataheader.reviews_options.length; ik++) {
                        const elementUser = dataheader.reviews_options[ik]
                        for (let jk = 0; jk < masterDataReview.length; jk++) {
                            const elementMaster = masterDataReview[jk]
                            if (elementUser === elementMaster._id) {
                                dataheader.reviews_options[ik] = elementMaster
                            }
                        }
                    }
                }
            }
        }
    }
    return checkerData
}

export const devEduHistroyMapper = (orgData, chekerData) => {
    const keys = [
        'additional_schooling',
        'childhood_atmos',
        'childhood_challenges',
        'difficulties_in_reaching',
        'during_preg_prob',
        'have_prob_in_school',
        'highest_edu_level',
    ]
    for (const singleKey of keys) {
        let optionsArray = []
        if (singleKey !== 'highest_edu_level') {
            const optionArrayPull = chekerData[singleKey]?.options
            for (let cpx = 0; cpx < optionArrayPull?.length; cpx++) {
                const holdData = optionArrayPull[cpx]
                for (const singOriginal of orgData) {
                    if (singOriginal._id === holdData) {
                        optionsArray.push(singOriginal)
                    }
                }
                chekerData[singleKey].options = optionsArray
            }
        }

        if (singleKey === 'highest_edu_level') {
            const highest_edu_level_id = chekerData[singleKey]
            for (let idx = 0; idx < orgData?.length; idx++) {
                if (orgData[idx]._id === highest_edu_level_id) {
                    chekerData[singleKey] = orgData[idx]
                }
            }
        }
    }

    return chekerData
}

export const interVationMapper = (masterData = [], checkerData = []) => {
    for (let ix = 0; ix < checkerData.length; ix++) {
        const ele = checkerData[ix]?.interventions_utilized
        for (let index = 0; index < masterData.length; index++) {
            if (masterData[index]._id === ele) {
                checkerData[ix].interventions_utilized = masterData[index]
            }
        }
    }

    return checkerData
}

export const sessionMapper = (masterData = [], checkerData = []) => {
    for (let idx = 0; idx < checkerData.length; idx++) {
        const ele = checkerData[idx]
        for (let index = 0; index < masterData.length; index++) {
            if (masterData[index]._id === ele) {
                checkerData[idx] = masterData[index]
            }
        }
    }

    return checkerData
}

export const cgiMapper = (masterdata = [], checker) => {
    for (let idx = 0; idx < masterdata.length; idx++) {
        if (masterdata[idx]._id === checker?.client_condition) {
            checker.client_condition = masterdata[idx]
        }
    }
    return checker
}
export const familyEnvMapper = (masterData = [], checkerData = []) => {
    for (let ix = 0; ix < checkerData.length; ix++) {
        const ele = checkerData[ix]
        for (let index = 0; index < masterData.length; index++) {
            if (masterData[index]._id === ele) {
                checkerData[ix] = masterData[index]
            }
        }
    }

    return checkerData
}

export const clinical_note_master = {
    systemReviewMasters: [
        {
            _id: '61db24056566413440d9ae75',
            header_name: 'Constitutional',
            is_horizontal: false,
            seq: 1,
            reviews_options: [
                {
                    name: 'Chronic pain',
                    _id: '61db24486566413440d9ae83',
                    seq: 1,
                },
                {
                    name: 'Loss of appetite',
                    _id: '61db24486566413440d9ae84',
                    seq: 2,
                },
                {
                    name: 'Increase in appetite',
                    _id: '61db24486566413440d9ae85',
                    seq: 3,
                },
                {
                    name: 'Unexplained weight loss',
                    _id: '61db24486566413440d9ae86',
                    seq: 4,
                },
                {
                    name: 'Weight gain',
                    _id: '61db24486566413440d9ae87',
                    seq: 5,
                },
                {
                    name: 'Fatigue/Lethargy',
                    _id: '61db24486566413440d9ae88',
                    seq: 6,
                },
                {
                    name: 'Unexplained fever',
                    _id: '61db24486566413440d9ae89',
                    seq: 7,
                },
                {
                    name: 'Hot or Cold spells',
                    _id: '61db24486566413440d9ae8a',
                    seq: 8,
                },
                {
                    name: 'Night sweats',
                    _id: '61db24486566413440d9ae8b',
                    seq: 9,
                },
                {
                    name: 'Sleeping pattern disruption',
                    _id: '61db24486566413440d9ae8c',
                    seq: 10,
                },
                {
                    name: 'Malaise (Flu-like or Vague sick feeling)',
                    _id: '61db24486566413440d9ae8d',
                    seq: 11,
                },
                {
                    name: 'None of the above constitutional issues',
                    _id: '61db24486566413440d9ae8e',
                    seq: 12,
                    is_none: true,
                },
            ],
        },
        {
            _id: '61db24876566413440d9ae90',
            header_name: 'Eyes',
            header_label: 'Eye',
            is_horizontal: false,
            seq: 2,
            reviews_options: [
                {
                    name: 'Eye pain',
                    _id: '61db24cc6566413440d9ae91',
                    seq: 1,
                },
                {
                    name: 'Eye discharge',
                    _id: '61db24cc6566413440d9ae92',
                    seq: 2,
                },
                {
                    name: 'Eye redness',
                    _id: '61db24cc6566413440d9ae93',
                    seq: 3,
                },
                {
                    name: 'Blurred or double vision',
                    _id: '61db24cc6566413440d9ae94',
                    seq: 4,
                },
                {
                    name: 'Visual change',
                    _id: '61db24cc6566413440d9ae95',
                    seq: 5,
                },
                {
                    name: 'History of eye surgery',
                    _id: '61db24cc6566413440d9ae96',
                    seq: 6,
                },
                {
                    name: 'Sensitivity to light',
                    _id: '61db24cc6566413440d9ae97',
                    seq: 7,
                },
                {
                    name: 'Scotomas (Blind spots)',
                    _id: '61db24cc6566413440d9ae98',
                    seq: 8,
                },
                {
                    name: 'Retinal hemorrhage (Floaters in vision)',
                    _id: '61db24cc6566413440d9ae99',
                    seq: 9,
                },
                {
                    name: ' Amaurosis fugax (Feeling like a curtain is pulled over vision)',
                    _id: '61db24cc6566413440d9ae100',
                    seq: 10,
                },
                {
                    name: 'None of the above eye issues',
                    _id: '61db24cd6566413440d9ae9a',
                    seq: 11,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ad4ae9797ddc2f84fd',
            header_name: 'Ears, Nose, Mouth, and Throat',
            header_label: 'Ear/Nose/Mouth/Throat',
            is_horizontal: false,
            seq: 3,
            reviews_options: [
                {
                    name: 'Earache',
                    _id: '6204174e4ae9797ddc2f850b',
                    seq: 1,
                },
                {
                    name: 'Tinnitus (Ringing in ears)',
                    _id: '6204174e4ae9797ddc2f850c',
                    seq: 2,
                },
                {
                    name: 'Decreased hearing or hearing loss',
                    _id: '6204174e4ae9797ddc2f850d',
                    seq: 3,
                },
                {
                    name: 'Frequent ear infections',
                    _id: '6204174e4ae9797ddc2f850e',
                    seq: 4,
                },
                {
                    name: 'Frequent nose bleeds',
                    _id: '6204174e4ae9797ddc2f850f',
                    seq: 5,
                },
                {
                    name: 'Sinus congestion',
                    _id: '6204174e4ae9797ddc2f8510',
                    seq: 6,
                },
                {
                    name: 'Runny nose/Post-nasal drip',
                    _id: '6204174e4ae9797ddc2f8511',
                    seq: 7,
                },
                {
                    name: 'Difficulty swallowing',
                    _id: '6204174e4ae9797ddc2f8512',
                    seq: 8,
                },
                {
                    name: 'Frequent sore throat',
                    _id: '6204174e4ae9797ddc2f8513',
                    seq: 9,
                },
                {
                    name: 'Prolonged hoarseness',
                    _id: '6204174f4ae9797ddc2f8514',
                    seq: 10,
                },
                {
                    name: 'Pain in jaw or tooth',
                    _id: '6204174f4ae9797ddc2f8515',
                    seq: 11,
                },
                {
                    name: 'Dry mouth',
                    _id: '6204174f4ae9797ddc2f8516',
                    seq: 12,
                },
                {
                    name: 'None of the above ear, nose, mouth or throat issues',
                    _id: '6204174f4ae9797ddc2f8517',
                    seq: 13,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ad4ae9797ddc2f84fe',
            header_name: 'Cardiovascular',
            is_horizontal: false,
            seq: 4,
            reviews_options: [
                {
                    name: 'Chest pain',
                    _id: '620417834ae9797ddc2f8518',
                    seq: 1,
                },
                {
                    name: 'Pacemaker',
                    _id: '620417834ae9797ddc2f8519',
                    seq: 2,
                },
                {
                    name: 'Palpitations (fast or irregular heartbeat)',
                    _id: '620417834ae9797ddc2f851a',
                    seq: 3,
                },
                {
                    name: 'Swollen feet or hands',
                    _id: '620417834ae9797ddc2f851b',
                    seq: 4,
                },
                {
                    name: 'Fainting spells',
                    _id: '620417834ae9797ddc2f851c',
                    seq: 5,
                },
                {
                    name: 'Shortness of breath with exercise',
                    _id: '620417834ae9797ddc2f851d',
                    seq: 6,
                },
                {
                    name: 'None of the above cardiovascular issues',
                    _id: '620417834ae9797ddc2f851e',
                    seq: 7,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f84ff',
            header_name: 'Respiratory',
            is_horizontal: false,
            seq: 5,
            reviews_options: [
                {
                    name: 'Pain with breathing',
                    _id: '620417d54ae9797ddc2f851f',
                    seq: 1,
                },
                {
                    name: 'Chronic cough',
                    _id: '620417d54ae9797ddc2f8520',
                    seq: 2,
                },
                {
                    name: 'Chronic shortness of breath',
                    _id: '620417d64ae9797ddc2f8521',
                    seq: 3,
                },
                {
                    name: 'Chronic wheezing/Asthma',
                    _id: '620417d64ae9797ddc2f8522',
                    seq: 4,
                },
                {
                    name: 'Excessive phlegm',
                    _id: '620417d64ae9797ddc2f8523',
                    seq: 5,
                },
                {
                    name: 'Coughing blood',
                    _id: '620417d64ae9797ddc2f8524',
                    seq: 6,
                },
                {
                    name: 'Nocturnal Dyspnea (Shortness of breath at night)',
                    _id: '620417d64ae9797ddc2f8525',
                    seq: 7,
                },
                {
                    name: 'None of the above respiratory issues',
                    _id: '620417d64ae9797ddc2f8526',
                    seq: 8,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8500',
            header_name: 'Musculoskeletal',
            is_horizontal: false,
            seq: 6,
            reviews_options: [
                {
                    name: 'Swelling in joints',
                    _id: '620418094ae9797ddc2f8527',
                    seq: 1,
                },
                {
                    name: 'Redness of joints',
                    _id: '6204180a4ae9797ddc2f8528',
                    seq: 2,
                },
                {
                    name: 'Other joint pains or stiffness',
                    _id: '6204180a4ae9797ddc2f8529',
                    seq: 3,
                },
                {
                    name: 'Muscle pain or cramping',
                    _id: '6204180a4ae9797ddc2f852a',
                    seq: 4,
                },
                {
                    name: 'Muscle weakness',
                    _id: '6204180a4ae9797ddc2f852b',
                    seq: 5,
                },
                {
                    name: 'Muscle stiffness',
                    _id: '6204180a4ae9797ddc2f852c',
                    seq: 6,
                },
                {
                    name: 'Decreased range of motion',
                    _id: '6204180a4ae9797ddc2f852d',
                    seq: 7,
                },
                {
                    name: 'Back pain or stiffness',
                    _id: '6204180a4ae9797ddc2f852e',
                    seq: 8,
                },
                {
                    name: 'History of fractures',
                    _id: '6204180a4ae9797ddc2f852f',
                    seq: 9,
                },
                {
                    name: 'Past injury to spine or joints',
                    _id: '6204180a4ae9797ddc2f8530',
                    seq: 10,
                },
                {
                    name: 'None of the above musculoskeletal issues',
                    _id: '6204180a4ae9797ddc2f8531',
                    seq: 11,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8501',
            header_name: 'Gastrointestinal',
            is_horizontal: true,
            seq: 7,
            reviews_options: [
                {
                    name: 'Excessive flatulence or belching',
                    _id: '620418324ae9797ddc2f8532',
                    seq: 1,
                },
                {
                    name: 'Diarrhea',
                    _id: '620418324ae9797ddc2f8533',
                    seq: 2,
                },
                {
                    name: 'Constipation',
                    _id: '620418324ae9797ddc2f8534',
                    seq: 3,
                },
                {
                    name: 'Persistent nausea/vomiting',
                    _id: '620418324ae9797ddc2f8535',
                    seq: 4,
                },
                {
                    name: 'Abdominal Pain',
                    _id: '620418324ae9797ddc2f8536',
                    seq: 5,
                },
                {
                    name: 'Heartburn',
                    _id: '620418324ae9797ddc2f8537',
                    seq: 6,
                },
                {
                    name: 'Difficulty swallowing solids or liquids',
                    _id: '620418324ae9797ddc2f8538',
                    seq: 7,
                },
                {
                    name: 'Recent loss in appetite',
                    _id: '620418324ae9797ddc2f8539',
                    seq: 8,
                },
                {
                    name: 'Sensitivity to milk products',
                    _id: '620418334ae9797ddc2f853a',
                    seq: 9,
                },
                {
                    name: 'Jaundice (yellow skin)',
                    _id: '620418334ae9797ddc2f853b',
                    seq: 10,
                },
                {
                    name: 'Change in appearance of stool',
                    _id: '620418334ae9797ddc2f853c',
                    seq: 11,
                },
                {
                    name: 'Blood in stool',
                    _id: '620418334ae9797ddc2f853d',
                    seq: 12,
                },
                {
                    name: 'Dark/Tarry stool',
                    _id: '620418334ae9797ddc2f853e',
                    seq: 13,
                },
                {
                    name: 'Loss of bowel control/soiling',
                    _id: '620418334ae9797ddc2f853f',
                    seq: 14,
                },
                {
                    name: 'None of the above gastrointestinal issues',
                    _id: '620418334ae9797ddc2f8540',
                    seq: 15,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8502',
            header_name: 'Allergic/Immunologic',
            is_horizontal: false,
            seq: 8,
            reviews_options: [
                {
                    name: 'Frequent infections',
                    _id: '620418554ae9797ddc2f8541',
                    seq: 1,
                },
                {
                    name: 'Hives',
                    _id: '620418554ae9797ddc2f8542',
                    seq: 2,
                },
                {
                    name: 'Anaphylaxic reaction',
                    _id: '620418564ae9797ddc2f8543',
                    seq: 3,
                },
                {
                    name: 'None of the above allergic or immunologic issues',
                    _id: '620418564ae9797ddc2f8544',
                    seq: 4,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8503',
            header_name: 'Endocrine',
            is_horizontal: false,
            seq: 9,
            reviews_options: [
                {
                    name: 'Severe menopausal symptoms',
                    label: 'Severe menopausal',
                    _id: '620418784ae9797ddc2f8545',
                    seq: 1,
                },
                {
                    name: 'Cold or heat intolerance',
                    _id: '620418784ae9797ddc2f8546',
                    seq: 2,
                },
                {
                    name: 'Excessive appetite',
                    _id: '620418784ae9797ddc2f8547',
                    seq: 3,
                },
                {
                    name: 'Excessive thirst or urination',
                    _id: '620418784ae9797ddc2f8548',
                    seq: 4,
                },
                {
                    name: 'Excessive sweating',
                    _id: '620418784ae9797ddc2f8549',
                    seq: 5,
                },
                {
                    name: 'None of the above endocrine issues',
                    _id: '620418794ae9797ddc2f854a',
                    seq: 6,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8504',
            header_name: 'Hematologic/Lymphatic',
            is_horizontal: false,
            seq: 10,
            reviews_options: [
                {
                    name: 'Blood Clots',
                    _id: '620418b44ae9797ddc2f854b',
                    seq: 1,
                },
                {
                    name: 'Excess/easy bleeding (surgery, dental work, brushing teeth, scrapes)',
                    label: 'easy bleeding after surgery or dental work',
                    _id: '620418b44ae9797ddc2f854c',
                    seq: 2,
                },
                {
                    name: 'History of blood transfusion',
                    _id: '620418b44ae9797ddc2f854d',
                    seq: 3,
                },
                {
                    name: 'Excessive bruising',
                    label: 'Excessive bruising or bleeding',
                    _id: '620418b44ae9797ddc2f854e',
                    seq: 4,
                },
                {
                    name: 'Swollen glands (neck, armpits, groin)',
                    label: 'Swollen glands (neck, armpits, groin)',
                    _id: '620418b44ae9797ddc2f854f',
                    seq: 5,
                },
                {
                    name: 'None of the above hematologic or lymphatic issues',
                    _id: '620418b54ae9797ddc2f8550',
                    seq: 6,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8505',
            header_name: 'Genitourinary (General)',
            header_label: 'general Genitourinary',
            is_horizontal: false,
            seq: 11,
            reviews_options: [
                {
                    name: 'Loss of urine control (including bed-wetting)',
                    label: 'Loss of urine control/bed-wetting',
                    _id: '620418d64ae9797ddc2f8551',
                    seq: 1,
                },
                {
                    name: 'Painful/Burning urination',
                    _id: '620418d64ae9797ddc2f8552',
                    seq: 2,
                },
                {
                    name: 'Blood in urine',
                    _id: '620418d74ae9797ddc2f8553',
                    seq: 3,
                },
                {
                    name: 'Increased frequency of urination',
                    _id: '620418d74ae9797ddc2f8554',
                    seq: 4,
                },
                {
                    name: 'Up more than twice/night to urinate',
                    label: 'Up more than twice a night to urinate',
                    _id: '620418d74ae9797ddc2f8555',
                    seq: 5,
                },
                {
                    name: 'Urine retention',
                    _id: '620418d74ae9797ddc2f8556',
                    seq: 6,
                },
                {
                    name: 'Frequent urine infections',
                    _id: '620418d74ae9797ddc2f8557',
                    seq: 7,
                },
                {
                    name: 'None of the above general genitourinary issues',
                    _id: '620418d74ae9797ddc2f8558',
                    seq: 8,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8506',
            header_name: 'Genitourinary (Women)',
            header_label: 'sex-specific Genitourinary',
            is_horizontal: false,
            seq: 12,
            reviews_options: [
                {
                    name: 'Unusual vaginal discharge',
                    _id: '620419004ae9797ddc2f8559',
                    seq: 1,
                },
                {
                    name: 'Vaginal pain, bleeding, soreness, or dryness',
                    _id: '620419004ae9797ddc2f855a',
                    seq: 2,
                },
                {
                    name: 'Genital sores',
                    _id: '620419004ae9797ddc2f855b',
                    seq: 3,
                },
                {
                    name: 'Heavy or irregular periods',
                    _id: '620419004ae9797ddc2f855c',
                    seq: 4,
                },
                {
                    name: 'No menses (Periods stopped)',
                    _id: '620419004ae9797ddc2f855d',
                    seq: 5,
                },
                {
                    name: 'Currently pregnant',
                    _id: '620419004ae9797ddc2f855e',
                    seq: 6,
                },
                {
                    name: 'Sterility/Infertility',
                    _id: '620419014ae9797ddc2f855f',
                    seq: 7,
                },
                {
                    name: 'Any other sexual/sex organ concerns',
                    _id: '620419014ae9797ddc2f8560',
                    seq: 8,
                },
                {
                    name: 'None of the above sex-specific genitourinary issues',
                    _id: '620419014ae9797ddc2f8561',
                    seq: 9,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8507',
            header_name: 'Genitourinary (Men)',
            header_label: 'sex-specific Genitourinary',
            is_horizontal: false,
            seq: 13,
            reviews_options: [
                {
                    name: 'Slow urine stream',
                    _id: '620419274ae9797ddc2f8562',
                    seq: 1,
                },
                {
                    name: 'Scrotal pain',
                    _id: '620419274ae9797ddc2f8563',
                    seq: 2,
                },
                {
                    name: 'Lump or mass in the testicles',
                    _id: '620419274ae9797ddc2f8564',
                    seq: 3,
                },
                {
                    name: 'Abnormal penis discharge',
                    _id: '620419274ae9797ddc2f8565',
                    seq: 4,
                },
                {
                    name: 'Trouble getting/maintaining erections',
                    _id: '620419274ae9797ddc2f8566',
                    seq: 5,
                },
                {
                    name: 'Inability to ejaculate/orgasm',
                    _id: '620419274ae9797ddc2f8567',
                    seq: 6,
                },
                {
                    name: 'Any other sexual or sex organ concerns',
                    _id: '620419284ae9797ddc2f8568',
                    seq: 7,
                },
                {
                    name: 'None of the above sex-specific genitourinary issues',
                    _id: '620419284ae9797ddc2f8569',
                    seq: 8,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8508',
            header_name: 'Neurological',
            is_horizontal: false,
            seq: 14,
            reviews_options: [
                {
                    name: 'Paralysis',
                    _id: '620419884ae9797ddc2f856a',
                    seq: 1,
                },
                {
                    name: 'Fainting spells or blackouts',
                    _id: '620419884ae9797ddc2f856b',
                    seq: 2,
                },
                {
                    name: 'Dizziness/Vertigo',
                    _id: '620419894ae9797ddc2f856c',
                    seq: 3,
                },
                {
                    name: 'Drowsiness',
                    _id: '620419894ae9797ddc2f856d',
                    seq: 4,
                },
                {
                    name: 'Slurred speech',
                    _id: '620419894ae9797ddc2f856e',
                    seq: 5,
                },
                {
                    name: 'Speech problems (other)',
                    _id: '620419894ae9797ddc2f856f',
                    seq: 6,
                },
                {
                    name: 'Short term memory trouble',
                    _id: '620419894ae9797ddc2f8570',
                    seq: 7,
                },
                {
                    name: 'Memory difficulties (loss)',
                    _id: '620419894ae9797ddc2f8571',
                    seq: 8,
                },
                {
                    name: 'Frequent headaches',
                    _id: '620419894ae9797ddc2f8572',
                    seq: 9,
                },
                {
                    name: 'Muscle weakness',
                    _id: '620419894ae9797ddc2f8573',
                    seq: 10,
                },
                {
                    name: 'Numbness/Tingling sensations',
                    _id: '620419894ae9797ddc2f8574',
                    seq: 11,
                },
                {
                    name: 'Neuropathy (numbness in feet)',
                    _id: '6204198a4ae9797ddc2f8575',
                    seq: 12,
                },
                {
                    name: 'Tremor in hands/shaking',
                    _id: '6204198a4ae9797ddc2f8576',
                    seq: 13,
                },
                {
                    name: 'Muscle spasms or tremors',
                    _id: '6204198a4ae9797ddc2f8577',
                    seq: 14,
                },
                {
                    name: 'None of the above neurological issues',
                    _id: '6204198a4ae9797ddc2f8578',
                    seq: 15,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415af4ae9797ddc2f8509',
            header_name: 'Integumentary (Skin/Breast and Hair)',
            is_horizontal: false,
            seq: 15,
            reviews_options: [
                {
                    name: 'Lesions',
                    _id: '620419ce4ae9797ddc2f8579',
                    seq: 1,
                },
                {
                    name: 'Unusual mole',
                    _id: '620419ce4ae9797ddc2f857a',
                    seq: 2,
                },
                {
                    name: 'Easy bruising',
                    _id: '620419ce4ae9797ddc2f857b',
                    seq: 3,
                },
                {
                    name: 'Increased perspiration',
                    _id: '620419cf4ae9797ddc2f857c',
                    seq: 4,
                },
                {
                    name: 'Rashes',
                    _id: '620419cf4ae9797ddc2f857d',
                    seq: 5,
                },
                {
                    name: 'Chronic dry skin',
                    _id: '620419cf4ae9797ddc2f857e',
                    seq: 6,
                },
                {
                    name: 'Itchy skin or scalp',
                    _id: '620419cf4ae9797ddc2f857f',
                    seq: 7,
                },
                {
                    name: 'Hair or nail changes',
                    _id: '620419cf4ae9797ddc2f8580',
                    seq: 8,
                },
                {
                    name: 'Hair loss',
                    _id: '620419cf4ae9797ddc2f8581',
                    seq: 9,
                },
                {
                    name: 'Breast tenderness',
                    _id: '620419cf4ae9797ddc2f8582',
                    seq: 10,
                },
                {
                    name: 'Breast discharge',
                    _id: '620419cf4ae9797ddc2f8583',
                    seq: 11,
                },
                {
                    name: 'Breast lump or mass',
                    _id: '620419cf4ae9797ddc2f8584',
                    seq: 12,
                },
                {
                    name: 'None of the above integumentary issues',
                    _id: '620419cf4ae9797ddc2f8585',
                    seq: 13,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415af4ae9797ddc2f850a',
            header_name: 'Psychiatric',
            is_horizontal: false,
            seq: 16,
            reviews_options: [
                {
                    name: 'In-depth review of psychiatric system appears earlier in document (to be checked by clinician only)',
                    _id: '62041af84ae9797ddc2f8586',
                    seq: 1,
                },
                {
                    name: 'Feeling depressed',
                    _id: '62041af94ae9797ddc2f8587',
                    seq: 2,
                },
                {
                    name: 'Difficulty concentrating',
                    _id: '62041af94ae9797ddc2f8588',
                    seq: 3,
                },
                {
                    name: 'Phobias/Unexplained fears',
                    _id: '62041af94ae9797ddc2f8589',
                    seq: 4,
                },
                {
                    name: 'No pleasure from life anymore',
                    _id: '62041af94ae9797ddc2f858a',
                    seq: 5,
                },
                {
                    name: 'Anxiety',
                    _id: '62041af94ae9797ddc2f858b',
                    seq: 6,
                },
                {
                    name: 'Insomnia',
                    _id: '62041af94ae9797ddc2f858c',
                    seq: 7,
                },
                {
                    name: 'Excessive moodiness',
                    _id: '62041af94ae9797ddc2f858d',
                    seq: 8,
                },
                {
                    name: 'Stress',
                    _id: '62041af94ae9797ddc2f858e',
                    seq: 9,
                },
                {
                    name: 'Disturbing thoughts',
                    _id: '62041af94ae9797ddc2f858f',
                    seq: 10,
                },
                {
                    name: 'Manic episodes',
                    _id: '62041af94ae9797ddc2f8590',
                    seq: 11,
                },
                {
                    name: 'Confusion',
                    _id: '62041af94ae9797ddc2f8591',
                    seq: 12,
                },
                {
                    name: 'Memory loss',
                    _id: '62041afa4ae9797ddc2f8592',
                    seq: 13,
                },
                {
                    name: 'Nightmares',
                    _id: '62041afa4ae9797ddc2f8593',
                    seq: 14,
                },
                {
                    name: 'None of the above psychiatric issues',
                    _id: '62041afa4ae9797ddc2f8594',
                    seq: 15,
                    is_none: true,
                },
            ],
        },
    ],
    devEduHistoryMasters: [
        {
            _id: '61ef858127a89df5082861c8',
            name: 'None of these',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 1,
            schema_field: 'during_preg_prob',
            is_none: true,
        },
        {
            _id: '61ef858127a89df5082861c9',
            name: 'Exposure to drugs or alcohol during pregnancy',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 2,
            schema_field: 'during_preg_prob',
        },
        {
            _id: '61ef858127a89df5082861ca',
            name: 'A difficult pregnancy',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 3,
            schema_field: 'during_preg_prob',
        },
        {
            _id: '61ef858127a89df5082861cb',
            name: 'Problems with delivery',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 4,
            schema_field: 'during_preg_prob',
        },
        {
            _id: '61ef881427a89df5082861cc',
            name: 'None of these',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 1,
            schema_field: 'difficulties_in_reaching',
            is_none: true,
        },
        {
            _id: '61ef881427a89df5082861cd',
            name: 'Walking',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 2,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861ce',
            name: 'Talking',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 3,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861cf',
            name: 'Toilet training',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 4,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861d0',
            name: 'Sleeping alone',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 5,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861d1',
            name: 'Being away from parents',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 6,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861d2',
            name: 'Making friends',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 7,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef887627a89df5082861d3',
            name: 'Normal',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 1,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d4',
            name: 'Supportive',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 2,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d5',
            name: 'Parental fighting',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 3,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d6',
            name: 'Parental violence',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 4,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d7',
            name: 'Financial difficulties',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 5,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d8',
            name: 'Frequent moving',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 6,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef899527a89df5082861d9',
            name: 'None of these',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 1,
            schema_field: 'childhood_challenges',
            is_none: true,
        },
        {
            _id: '61ef899527a89df5082861da',
            name: 'Tantrums',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 2,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861db',
            name: 'Enuresis(bed wetting)',
            label: 'Enuresis',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 3,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861dc',
            name: 'Encopresis (fecal incontinence)',
            label: 'Encopresis',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 4,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861dd',
            name: 'Running away from home',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 5,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861de',
            name: 'Fighting',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 6,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861df',
            name: 'Stealing',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 7,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e0',
            name: 'Property damage',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 8,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e1',
            name: 'Fire setting',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 9,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e2',
            name: 'Animal cruelty',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 10,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e3',
            name: 'Separation anxiety',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 11,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e4',
            name: 'Victim of bullying',
            label: 'having been the victim of bullying',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 12,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e5',
            name: 'Depression',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 13,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899627a89df5082861e6',
            name: 'Death of a parent/caregiver',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 14,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899627a89df5082861e7',
            name: 'Parental divorce',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 15,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef89e527a89df5082861e8',
            name: 'None of these',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 1,
            schema_field: 'have_prob_in_school',
            is_none: true,
        },
        {
            _id: '61ef89e627a89df5082861e9',
            name: 'Fighting',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 2,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ea',
            name: 'School phobia',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 3,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861eb',
            name: 'Truancy',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 4,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ec',
            name: 'Detentions',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 5,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ed',
            name: 'Suspensions',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 6,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ee',
            name: 'Expulsions',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 7,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ef',
            name: 'School refusal',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 8,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f0',
            name: 'Class failures',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 9,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f1',
            name: 'Repetition of grades',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 10,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f2',
            name: 'Special education',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 11,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f3',
            name: 'Remedial classes',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 12,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef8acc27a89df5082861f4',
            name: 'None of these',
            section_name: 'additional_schooling',
            section: 6,
            seq: 1,
            schema_field: 'additional_schooling',
            is_none: true,
        },
        {
            _id: '61ef8acc27a89df5082861f5',
            name: 'Speech classes',
            section_name: 'additional_schooling',
            section: 6,
            seq: 2,
            schema_field: 'additional_schooling',
        },
        {
            _id: '61ef8acd27a89df5082861f6',
            name: 'Tutoring',
            section_name: 'additional_schooling',
            section: 6,
            seq: 3,
            schema_field: 'additional_schooling',
        },
        {
            _id: '61ef8acd27a89df5082861f7',
            name: 'Accommodations',
            section_name: 'additional_schooling',
            section: 6,
            seq: 4,
            schema_field: 'additional_schooling',
        },
        {
            _id: '61ef8b0d27a89df5082861f8',
            name: 'Less than high school',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 1,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861f9',
            name: 'High school/GED',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 2,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861f349',
            name: ' Some college',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 3,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861fa',
            name: 'Two-year degree',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 4,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861fb',
            name: 'Four-year degree',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 5,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861fc',
            name: 'Graduate/Professional degree',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 6,
            schema_field: 'highest_edu_level',
        },
    ],
    interventionMasters: [
        {
            _id: '61de5565e7b983cd1ef1dada',
            seq: 1,
            name: 'Psychoeducation',
            label: 'Psychoeducation',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadb',
            seq: 2,
            name: 'Hierarchy',
            label: 'Hierarchy',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadc',
            seq: 3,
            name: 'Breathing/Relaxation/Other physiological management',
            label: 'Breathing/Relaxation/Other physiological management',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadd',
            seq: 4,
            name: 'Cognitive modification strategies (direct work on thinking, behavioral experiments to modify thinking)',
            label: 'Cognitive modification strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dade',
            seq: 5,
            name: 'Mindfulness-based strategy',
            label: 'Mindfulness-based strategy',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadf',
            seq: 6,
            name: 'Acceptance-based strategy',
            label: 'Acceptance-based strategy',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae0',
            seq: 7,
            name: 'Self monitoring',
            label: 'Self monitoring',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae1',
            seq: 8,
            name: 'Exposure - In vivo (t. assisted)',
            label: 'Exposure - In vivo (t. assisted)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae2',
            seq: 9,
            name: 'Exposure - In vivo (c. independent)',
            label: 'Exposure - In vivo (c. independent)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae3',
            seq: 10,
            name: 'Exposure - Imaginal (t. assisted)',
            label: 'Exposure - Imaginal (t. assisted)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae4',
            seq: 11,
            name: 'Exposure - Imaginal (c. independent)',
            label: 'Exposure - Imaginal (c. independent)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae5',
            seq: 12,
            name: 'Exposure - Interoceptive (t. assisted)',
            label: 'Exposure - Interoceptive (t. assisted)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae6',
            seq: 13,
            name: 'Exposure - Interoceptive (c. independent)',
            label: 'Exposure - Interoceptive (c. independent)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae7',
            seq: 14,
            name: 'Response prevention / Safety behaviors',
            label: 'Response prevention / Safety behaviors',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae8',
            seq: 15,
            name: 'Stimulus control strategies',
            label: 'Stimulus control strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae9',
            seq: 16,
            name: 'Habit reversal strategies',
            label: 'Habit reversal strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daea',
            seq: 17,
            name: 'Contingency management (out of session or in session)',
            label: 'Contingency management (out of session or in session)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daeb',
            seq: 18,
            name: 'Problem solving',
            label: 'Problem solving',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daec',
            seq: 19,
            name: 'Validation',
            label: 'Validation',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daed',
            seq: 20,
            name: 'Skill training (specific skill)',
            label: 'Skill training',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daee',
            seq: 21,
            name: 'Behavioral activation strategies',
            label: 'Behavioral activation strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daef',
            seq: 22,
            name: 'Emotion identification/regulation strategies',
            label: 'Emotion identification/regulation strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf0',
            seq: 23,
            name: 'Functional analysis',
            label: 'Functional analysis',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf1',
            seq: 24,
            name: 'Relapse preventions',
            label: 'Relapse preventions',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf2',
            seq: 25,
            name: 'Motivation enhancement',
            label: 'Motivation enhancement',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf3',
            seq: 26,
            name: 'FAP-based strategies',
            label: 'FAP-based strategies',
            additional: false,
            description: '',
            main: false,
        },
    ],
    sessionFocusMasters: [
        {
            _id: '61de5565e7b983cd1ef1dad2',
            seq: 1,
            name: 'Assessment',
            label: 'assessment session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad3',
            seq: 2,
            name: 'Treatment planning',
            label: 'treatment planning session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad4',
            seq: 3,
            name: 'Intervention targeting specific problem(s) (also complete "Treatment Approach" section)',
            label: 'intervention targeting specific problem(s)',
        },
        {
            _id: '61de5565e7b983cd1ef1dad5',
            seq: 4,
            name: 'Maintenance/Support (targeting improvement or maintenance of overall functioning)',
            label: 'maintenance or supportive session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad6',
            seq: 5,
            name: 'Booster session',
            label: 'Booster session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad7',
            seq: 6,
            name: 'Termination session',
            label: 'Termination session',
        },
    ],
    clinicalImpMasters: [
        {
            _id: '61de7c73f6291371eda0d7c7',
            seq: 1,
            name: 'Very much improved since the initiation of treatment',
            label: 'Very much improved since',
        },
        {
            _id: '61de7c73f6291371eda0d7c8',
            seq: 2,
            name: 'Much improved',
            label: 'Much improved since',
        },
        {
            _id: '61de7c73f6291371eda0d7c9',
            seq: 3,
            name: 'Minimally improved',
            label: 'Minimally improved since',
        },
        {
            _id: '61de7c73f6291371eda0d7ca',
            seq: 4,
            name: 'No change from initiation of treatment',
            label: 'unchanged from',
        },
        {
            _id: '61de7c73f6291371eda0d7cb',
            seq: 5,
            name: 'Minimally worse',
            label: 'Minimally worse since',
        },
        {
            _id: '61de7c73f6291371eda0d7cc',
            seq: 6,
            name: 'Much worse',
            label: 'Much worse since',
        },
        {
            _id: '61de7c73f6291371eda0d7cd',
            seq: 7,
            name: 'Very much worse since the initiation of treatment',
            label: 'Very much worse since',
        },
    ],
    envStrategyMasters: [
        {
            _id: '61de7c73f6291371eda0d7b7',
            seq: 1,
            section: 1,
            name: 'School/Teacher intervention',
        },
        {
            _id: '61de7c73f6291371eda0d7b8',
            seq: 2,
            section: 1,
            name: 'Brief family intervention',
        },
        {
            _id: '61de7c73f6291371eda0d7b9',
            seq: 3,
            section: 1,
            name: 'Behavioral Parent Training',
        },
        {
            _id: '61de7c73f6291371eda0d7ba',
            seq: 1,
            section: 2,
            name: 'Attending',
        },
        {
            _id: '61de7c73f6291371eda0d7bb',
            seq: 2,
            section: 2,
            name: 'Praise',
        },

        {
            _id: '61de7c73f6291371eda0d7bc',
            seq: 3,
            section: 2,
            name: 'Ignoring',
        },

        {
            _id: '61de7c73f6291371eda0d7bd',
            seq: 4,
            section: 2,
            name: 'Tangible rewards',
        },
        {
            _id: '61de7c73f6291371eda0d7be',
            seq: 5,
            section: 2,
            name: 'Time out',
        },
        {
            _id: '61de7c73f6291371eda0d7bf',
            seq: 6,
            section: 2,
            name: 'Parent emotion regulation',
        },
        {
            _id: '61de7c73f6291371eda0d7c0',
            seq: 7,
            section: 2,
            name: 'Consequences',
        },
        {
            _id: '61de7c73f6291371eda0d7c1',
            seq: 8,
            section: 2,
            name: 'Effective Directions',
        },
        {
            _id: '61de7c73f6291371eda0d7c2',
            seq: 9,
            section: 2,
            name: 'Family rules and routines',
        },
        {
            _id: '61de7c73f6291371eda0d7c3',
            seq: 10,
            section: 2,
            name: 'Coordinating parenting',
        },
        {
            _id: '61de7c73f6291371eda0d7c4',
            seq: 11,
            section: 2,
            name: 'Problem solving',
        },
    ],
}
export const nameAvatar = (Avatarname, height = '66px', width = '66px') => {
    function stringToColor(string) {
        let hash = 0
        let i

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash)
        }

        let color = '#'

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff
            color += `00${value.toString(16)}`.slice(-2)
        }
        /* eslint-enable no-bitwise */

        return color
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                height: height,
                width: width,
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        }
    }
    return (
        <Stack direction="row" spacing={2}>
            <Avatar {...stringAvatar(Avatarname)} />
        </Stack>
    )
}
