export const CLINIC_ADMIN_LOADING = 'CLINIC_ADMIN_LOADING'
export const CLINIC_ADMIN_SUCCESS = 'CLINIC_ADMIN_SUCCESS'
export const CLINIC_ADMIN_FAILURE = 'CLINIC_ADMIN_FAILURE'
export const CLINIC_ADMIN_PAGE_CHANGE =
    'CLINIC_ADMIN_PAGE_CHANGE'
export const CLINIC_ADMIN_SORT_DATA =
    'CLINIC_ADMIN_SORT_DATA'
export const SEARCH_CLINIC_ADMIN = 'CLINIC_ADMIN_SEARCH'
export const CLINIC_ADMIN_DETAILS_LOADING =
    'CLINIC_ADMIN_DETAILS_LOADING'
export const CLINIC_ADMIN_DETAILS_SUCCESS =
    'CLINIC_ADMIN_DETAILS_SUCCESS'
export const CLINIC_ADMIN_UPDATE_STATUS =
    'CLINIC_ADMIN_UPDATE_STATUS'
