import {
    Box,
    Dialog,
    DialogContent,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
    Button,
} from '@material-ui/core'
import './HealthAccountStyle.scss'
import { useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AntSwitch from '../../shared/Elements/AntSwitch'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { resetPassWord } from '../../../services/Login/login'
import { useNotification } from '../../Appointments/MaterialUi/Notification'
import { useHistory } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            height: '475px !important',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'auto',
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '559px !important',
        },
    },
    titleText: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        paddingLeft: '6px',
    },
    receiveNotificationText: {
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
        marginTop: '12px',
    },
    headingText: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
    },
    subText: {
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
        marginTop: '8px',
    },
    currentPassword: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
    },
    currentPasswordLink: {
        color: '#5571C6',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '140%',
        cursor: 'pointer',
    },
    divider: {
        background: '#EAECF0',
        height: 1,
        // marginBottom: 14,
        marginTop: 8,
    },
    restButton: {
        width: '518px !important',
        height: '43px !important',
        borderRadius: '5px !important',
        background: '#5571C6 !important',
        color: 'white !important',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
        textTransform: 'none !important',
    },
    checkText: {
        display: 'flex',
        alignItems: 'center',
        textAlignLast: 'center',
        gap: '5px',
    },
}))
const textHeadWithSubText = [
    {
        checked: true,
        headText: 'Appointment confirmations',
        subText: 'Get a notification when your appointment has been confirmed',
        disabled: false
    },
    {
        checked: true,
        headText: 'Changes to your appointment',
        subText: 'Get a notification if there are any changes your appointment',
        disabled: true
    },
    {
        checked: true,
        headText: 'Appointment reminders',
        subText: 'Get a multiple appointment reminders prior to your appointment',
        disabled: true
    },
]
const AccountPreferences = () => {
    const notification = useNotification()
    const classes = useStyles()
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [loadingButton, setLoadingButton] = useState(false)
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    })
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        formik.resetForm()
        setOpen(false)
    }
    const handleClickShowPassword = (value) => {
        setShowPassword((prev) => ({
            ...prev,
            [value]: !prev[value],
        }))
    }
    const handleSubmit = async (payload) => {
        try {
            setLoadingButton(true)
            const newPayload = {
                current_password: payload?.currentPassword,
                password: payload?.newPassword,
                password_confirmation: payload?.confirmPassword,
            }
            const res = await resetPassWord(newPayload)
            if (res?.data?.status) {
                notification('Password changed successfully', 'success')
                handleClose()
                // localStorage.clear()
                // history.push('/signin')
            } else {
                notification(`${res?.data?.message || 'Something went wrong!'}`, 'error')
            }
            setLoadingButton(false)
        } catch (err) {
            console.log('err', err)
            setLoadingButton(false)
            notification('Something went wrong!', 'error')
        }
    }
    const initData = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    }
    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Current password is required'),
        newPassword: Yup.string()
            .required('New password is required')
            .min(8, 'New password must be at least 8 characters')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
                'New password must include at least one alphanumeric character and one special character',
            )
            .test(
                'password-match',
                'Your new password must be different from your current password',
                function (value) {
                    const { currentPassword } = this.parent
                    return value !== currentPassword
                },
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Confirm password must match with New password')
            .required('Confirm password is required'),
    })
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })
    return (
        <Grid container style={{ paddingRight: '10px', marginTop: '10px' }}>
            <Grid
                container
                alignContent="center"
                style={{ borderBottom: '1px solid #EAECF0', paddingBottom: '7px' }}>
                <NotificationsIcon style={{ color: '#667689', fontSize: '17px' }} />
                <Typography className={classes.titleText}>Notifications</Typography>
            </Grid>
            <Typography className={classes.receiveNotificationText}>
                Receive SMS texts and email notifications for:
            </Typography>
            {textHeadWithSubText.map((item, index) => (
                <Grid container style={{ marginTop: '20px', opacity: item.disabled ? '0.5' : 1}} key={index}>
                    <AntSwitch
                        // onChange={() => onChange(id)}
                        defaultChecked
                        // checked={is_allow}
                        disabled={item.disabled}
                        inputProps={{
                            'aria-label': 'ant design',
                        }}
                    />
                    <Grid item style={{ paddingLeft: '15px', marginTop: '-2px' }}>
                        <Typography className={classes.headingText}>{item?.headText}</Typography>
                        <Typography className={classes.subText}>{item?.subText}</Typography>
                    </Grid>
                </Grid>
            ))}
            <Grid
                container
                alignContent="center"
                style={{
                    borderBottom: '1px solid #EAECF0',
                    paddingBottom: '7px',
                    marginTop: '17px',
                }}>
                <NotificationsIcon style={{ color: '#667689', fontSize: '17px' }} />
                <Typography className={classes.titleText}>Reset Password</Typography>
            </Grid>
            <Typography className={classes.receiveNotificationText}>
                Reset your password below. Do not share your password.
            </Typography>
            <Grid
                container
                style={{
                    display: 'flex',
                    allignItem: 'center',
                    justifyContent: 'space-between',
                    marginTop: '30px',
                }}>
                <Grid item>
                    <Typography className={classes.currentPassword}>Current password </Typography>
                    <Typography
                        className={classes.receiveNotificationText}
                        style={{ fontSize: '14px', fontWeight: '500' }}>
                        ****************{' '}
                    </Typography>
                </Grid>
                <Typography className={classes.currentPasswordLink} onClick={handleOpen}>
                    Change password{' '}
                </Typography>
            </Grid>
            <Dialog
                open={open}
                sx={{
                    '& .MuiDialog-paperFullWidth': {
                        maxWidth: '580px',
                    },
                }}
                fullWidth={true}
                onClose={handleClose}
                className={classes.root}>
                <DialogContent>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #EAECF0',
                        }}>
                        <Typography
                            className={classes.currentPassword}
                            style={{ fontSize: '18px', fontWeight: '500' }}>
                            Change your password
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon style={{ color: '#303E4E' }} />
                        </IconButton>
                    </div>

                    <FormLabel
                        className={classes.currentPassword}
                        component="legend"
                        style={{
                            fontSize: '16px',
                            padding: '9px 0px 7px 0px',
                            textTransform: 'none',
                        }}>
                        Current password
                    </FormLabel>
                    <TextField
                        id="auto-reg-password"
                        required
                        placeholder="Enter current password"
                        type={showPassword.currentPassword ? 'text' : 'password'}
                        error={formik.errors.currentPassword && formik.touched.currentPassword}
                        helperText={
                            formik.errors.currentPassword && formik.touched.currentPassword
                                ? formik.errors.currentPassword
                                : ''
                        }
                        variant="outlined"
                        size="small"
                        className="text-input"
                        name="Current"
                        value={formik.values?.currentPassword}
                        onChange={(e) => {
                            formik.setFieldValue('currentPassword', e.target.value)
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            handleClickShowPassword('currentPassword')
                                        }}
                                        edge="end">
                                        {showPassword.currentPassword ? (
                                            <VisibilityOffOutlinedIcon
                                                style={{
                                                    color: '#667689',
                                                    marginRight: '10px',
                                                    fontSize: '18px',
                                                }}
                                            />
                                        ) : (
                                            <VisibilityOutlinedIcon
                                                style={{
                                                    color: '#667689',
                                                    marginRight: '10px',
                                                    fontSize: '18px',
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Divider
                        classes={{
                            root: classes.divider,
                        }}
                        style={{margin:"20px 0px 4px 0px"}}
                    />
                    <FormLabel
                        className={classes.currentPassword}
                        component="legend"
                        style={{
                            fontSize: '16px',
                            padding: '5px 0px 7px 0px',
                            textTransform: 'none',
                        }}>
                        New password
                    </FormLabel>
                    <TextField
                        id="auto-reg-password"
                        required
                        placeholder="Enter new password"
                        type={showPassword.newPassword ? 'text' : 'password'}
                        error={formik.errors.newPassword && formik.touched.newPassword}
                        helperText={
                            formik.errors.newPassword && formik.touched.newPassword
                                ? formik.errors.newPassword
                                : ''
                        }
                        variant="outlined"
                        size="small"
                        className="text-input"
                        name="newPassword"
                        value={formik.values?.newPassword}
                        onChange={(e) => {
                            formik.setFieldValue('newPassword', e.target.value)
                        }}
                        disabled={!formik.values?.currentPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            handleClickShowPassword('newPassword')
                                        }}
                                        edge="end">
                                        {showPassword.newPassword ? (
                                            <VisibilityOffOutlinedIcon
                                                style={{
                                                    color: '#667689',
                                                    marginRight: '10px',
                                                    fontSize: '18px',
                                                }}
                                            />
                                        ) : (
                                            <VisibilityOutlinedIcon
                                                style={{
                                                    color: '#667689',
                                                    marginRight: '10px',
                                                    fontSize: '18px',
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className={classes.checkText}>
                        <CheckCircleOutlineIcon
                            style={{
                                width: '13px',
                                color:
                                    formik.values?.newPassword &&
                                    formik.values?.newPassword.length >= 8
                                        ? 'green'
                                        : '#CDD0D4',
                            }}
                        />
                        <span
                            className={classes.subText}
                            style={{ fontSize: '13px', marginTop: '0px' }}>
                            Your password must be at least 8 characters long.
                        </span>
                    </div>
                    <Grid container className={classes.checkText}>
                        <CheckCircleOutlineIcon
                            style={{
                                width: '13px',
                                color:
                                    formik.values?.newPassword &&
                                    /[^a-zA-Z0-9]/.test(formik.values?.newPassword)
                                        ? 'green'
                                        : '#CDD0D4',
                            }}
                        />
                        <span
                            className={classes.subText}
                            style={{ fontSize: '13px', marginTop: '0px' }}>
                            Your password must include at least one special character.
                        </span>
                    </Grid>
                    <Grid container className={classes.checkText}>
                        <CheckCircleOutlineIcon
                            style={{
                                width: '13px',
                                color:
                                    formik.values?.newPassword &&
                                    /\d/.test(formik.values?.newPassword) &&
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/.test(
                                        formik.values?.newPassword,
                                    )
                                        ? 'green'
                                        : '#CDD0D4',
                            }}
                        />
                        <span
                            className={classes.subText}
                            style={{ fontSize: '13px', marginTop: '0px' }}>
                            Your password must include at least one alphanumeric character (a-z,
                            A-Z, 0-9).
                        </span>
                    </Grid>
                    <FormLabel
                        className={classes.currentPassword}
                        component="legend"
                        style={{
                            fontSize: '16px',
                            padding: '5px 0px 7px 0px',
                            textTransform: 'none',
                        }}>
                        Confirm new password
                    </FormLabel>
                    <TextField
                        id="auto-reg-password"
                        required
                        placeholder="Confirm new password"
                        type={showPassword.confirmPassword ? 'text' : 'password'}
                        error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                        helperText={
                            formik.errors.confirmPassword && formik.touched.confirmPassword
                                ? formik.errors.confirmPassword
                                : ''
                        }
                        variant="outlined"
                        size="small"
                        className="text-input"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={(e) => {
                            formik.setFieldValue('confirmPassword', e.target.value)
                        }}
                        disabled={!formik.values?.newPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            handleClickShowPassword('confirmPassword')
                                        }}
                                        edge="end">
                                        {showPassword.confirmPassword ? (
                                            <VisibilityOffOutlinedIcon
                                                style={{
                                                    color: '#667689',
                                                    marginRight: '10px',
                                                    fontSize: '18px',
                                                }}
                                            />
                                        ) : (
                                            <VisibilityOutlinedIcon
                                                style={{
                                                    color: '#667689',
                                                    marginRight: '10px',
                                                    fontSize: '18px',
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className={classes.checkText}>
                        <CheckCircleOutlineIcon
                            style={{
                                width: '13px',
                                color:
                                    formik.values?.newPassword &&
                                    formik.values?.newPassword === formik.values?.confirmPassword &&
                                    formik.values?.confirmPassword
                                        ? 'green'
                                        : '#CDD0D4',
                            }}
                        />
                        <span
                            className={classes.subText}
                            style={{ fontSize: '13px', marginTop: '0px' }}>
                            Passwords match
                        </span>
                    </div>
                    <Divider
                        classes={{
                            root: classes.divider,
                        }}
                        style={{ margin: "5px 0px 10px 0px"}}
                    />
                    <Grid item style={{ marginTop: '5px' }}>
                        <LoadingButton
                            className={classes.restButton}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                            loading={loadingButton}>
                            Reset my password
                        </LoadingButton>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}

export default AccountPreferences
