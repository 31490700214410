import {
    Container,
    FormLabel,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { errorMessages } from '../../constants/errorMessages'
import { ButtonSpinner } from '../custom'
import EmailIcon from '@mui/icons-material/Email'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import './signin.scss'
import { useHistory } from 'react-router-dom'

const ForgotPassword = ({ handleSubmit, decryptedData, handleResendLink }) => {
    const formikRef = useRef()
    const history = useHistory()
    const [showPassword, setShowPassword] = React.useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const notification = useNotification()
    const validateSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]+$/,
                'Password must contain at least one letter, one number, and one special character',
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords do not match, please try again')
            .required('Confirm Password is required'),
    })
    // if (!decryptedData && !decryptedData?.email) {
    //     return (
    //         <Container className="sign-in-wrapper">
    //             <span>In valid link!</span>
    //         </Container>
    //     )
    // }
    return (
        <Container className="sign-in-wrapper">
            <Grid container className="form-wrapper">
                <Grid item xs={12} sm={6} span={12}>
                    <div
                        className="form-img"
                        style={{
                            // background:
                            // 'url(http://source.unsplash.com/620x700/?medical)',
                            background: '#E8E8E9',
                            borderRadius: '10px',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} span={12} ml={2} className="right-panel">
                    <Grid className="logo-wrapper">
                        <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                    </Grid>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            password: '',
                            confirm_password: '',
                        }}
                        validationSchema={validateSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                const payload = {
                                    password: values.password,
                                    email: decryptedData.email,
                                    otp: decryptedData.otp,
                                    name: `${decryptedData.first_name} ${decryptedData.last_name}`,
                                }
                                const res = await handleSubmit(payload)
                                if (res && res?.data?.status) {
                                    notification('Password reset successfully done', 'success')
                                    history.push('/dashboard')
                                } else {
                                    notification(res?.data?.message || 'Somthing is wrong', 'error')
                                }

                                setSubmitting(false)
                            } catch (error) {
                                notification('Somthing is wrong', 'error')
                                setSubmitting(false)
                            }
                        }}>
                        {({
                            errors,
                            touched,
                            isValid,
                            values,
                            isSubmitting,
                            setFieldValue,
                            dirty,
                            handleChange,
                            handleSubmit,
                        }) => {
                            return (
                                <form
                                    className="form registration"
                                    onSubmit={handleSubmit}
                                    noValidate>
                                    <div className="inner-wrapper-logo">
                                        <img
                                            className="mobile-logo"
                                            src={logo}
                                            alt="TeleMed2U"></img>
                                    </div>
                                    <h1>Reset your patient portal password</h1>
                                    <p className="para-tag">Enter a new password.</p>
                                    <FormLabel className="input-label" component="legend">
                                        Password
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-password"
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        error={errors.password && touched.password}
                                        helperText={
                                            errors.password && touched.password
                                                ? errors.password
                                                : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="text-input"
                                        name="password"
                                        placeholder="Enter new password"
                                        value={values.password}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end">
                                                        {showPassword ? (
                                                            <VisibilityOffOutlinedIcon
                                                                style={{
                                                                    color: '#667689',
                                                                    marginRight: '10px',
                                                                    fontSize: '18px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <VisibilityOutlinedIcon
                                                                style={{
                                                                    color: '#667689',
                                                                    marginRight: '10px',
                                                                    fontSize: '18px',
                                                                }}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />{' '}
                                    <FormLabel className="input-label" component="legend">
                                        Confirm Password
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-password"
                                        required
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        error={errors.confirm_password && touched.confirm_password}
                                        helperText={
                                            errors.confirm_password && touched.confirm_password
                                                ? errors.confirm_password
                                                : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="text-input"
                                        name="password"
                                        value={values.confirm_password}
                                        onChange={(e) =>
                                            setFieldValue('confirm_password', e.target.value)
                                        }
                                        placeholder="Confirm new password"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        edge="end">
                                                        {showConfirmPassword ? (
                                                            <VisibilityOffOutlinedIcon
                                                                style={{
                                                                    color: '#667689',
                                                                    marginRight: '10px',
                                                                    fontSize: '18px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <VisibilityOutlinedIcon
                                                                style={{
                                                                    color: '#667689',
                                                                    marginRight: '10px',
                                                                    fontSize: '18px',
                                                                }}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />{' '}
                                    <div className="submit">
                                        <ButtonSpinner
                                            disabled={Boolean(isSubmitting)}
                                            isLoading={Boolean(isSubmitting)}
                                            label={'Sign in with new password'}
                                            className="btn-submit-password"
                                            sx={{
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                    <Grid className="form-footer">
                        <p className="footer-tag">
                            If you have any questions or need assistance, please call us at{' '}
                            <a>(562) 268-0955</a>.{' '}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ForgotPassword
