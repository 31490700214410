import axios from "axios";
import { config } from "../../../constants";

const methods = {
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
    },
};


async function updateWaitingQueue(id: any, data: any) {
    try {
        return await axios.post(
            `${config.dev.baseURL}/waiting-queue/update/${id}`,
            data,
            methods,
        );
    } catch (err) {
        throw err;
    }
}

export default updateWaitingQueue;
