import React from 'react'

import {
    Grid,
    Typography,
    FormLabel,
} from '@material-ui/core'
import {
    capitalizeFirstLetter,
    formatPhone,
} from '../../../../utilities'
const PatientReferingProvider = (props) => {
    const { patientProfile } = props
    return (
        <>
            <Grid container className="borderBottom">
                <Grid item xs={12} lg={12} className="pTB3">
                    <Grid
                        item
                        xs={12}
                        className="card-title-align">
                        <Typography
                            variant="subtitle1"
                            className="card-title">
                            Referring Provider
                        </Typography>
                    </Grid>
                    <Grid container lg={5} xs={12}>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    First Name
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {capitalizeFirstLetter(
                                        patientProfile
                                            ?.referringProvider
                                            ?.first_name,
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Last Name
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {
                                        patientProfile
                                            ?.referringProvider
                                            ?.last_name
                                    }
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Email
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {
                                        patientProfile
                                            ?.referringProvider
                                            ?.email
                                    }
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Cellphone
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {formatPhone(
                                        patientProfile?.referringProvider?.mob
                                            .replace(
                                                /\D/g,
                                                '',
                                            )
                                            .slice(-10),
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Fax
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {
                                        patientProfile
                                            ?.referringProvider
                                            ?.fax
                                    }
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className="mb1">
                            <Grid item xs={12}>
                                <FormLabel className="detail-item-title ">
                                    Referral Code
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className="detail-item-title colorDark">
                                    {
                                        patientProfile
                                            ?.referringProvider
                                            ?.referral_code
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default PatientReferingProvider
