import React from 'react'

export default function IconSound() {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask
                id="path-1-inside-1_2468_1410"
                fill="white">
                <path d="M2.14993 7.9251C1.97455 7.9251 1.8291 7.77966 1.8291 7.60427V4.39594C1.8291 4.22055 1.97455 4.0751 2.14993 4.0751C2.32532 4.0751 2.47077 4.22055 2.47077 4.39594V7.60427C2.47077 7.77966 2.32532 7.9251 2.14993 7.9251Z" />
            </mask>
            <path
                d="M2.14993 7.9251C1.97455 7.9251 1.8291 7.77966 1.8291 7.60427V4.39594C1.8291 4.22055 1.97455 4.0751 2.14993 4.0751C2.32532 4.0751 2.47077 4.22055 2.47077 4.39594V7.60427C2.47077 7.77966 2.32532 7.9251 2.14993 7.9251Z"
                fill="white"
            />
            <path
                d="M2.14993 6.9251C2.52683 6.9251 2.8291 7.22737 2.8291 7.60427H0.829102C0.829102 8.33194 1.42226 8.9251 2.14993 8.9251V6.9251ZM2.8291 7.60427V4.39594H0.829102V7.60427H2.8291ZM2.8291 4.39594C2.8291 4.77283 2.52683 5.0751 2.14993 5.0751V3.0751C1.42226 3.0751 0.829102 3.66826 0.829102 4.39594H2.8291ZM2.14993 5.0751C1.77304 5.0751 1.47077 4.77283 1.47077 4.39594H3.47077C3.47077 3.66826 2.87761 3.0751 2.14993 3.0751V5.0751ZM1.47077 4.39594V7.60427H3.47077V4.39594H1.47077ZM1.47077 7.60427C1.47077 7.22737 1.77304 6.9251 2.14993 6.9251V8.9251C2.87761 8.9251 3.47077 8.33194 3.47077 7.60427H1.47077Z"
                fill="white"
                mask="url(#path-1-inside-1_2468_1410)"
            />
            <mask
                id="path-3-inside-2_2468_1410"
                fill="white">
                <path d="M4.07523 8.99453C3.89984 8.99453 3.75439 8.84909 3.75439 8.6737V3.32648C3.75439 3.15109 3.89984 3.00565 4.07523 3.00565C4.25062 3.00565 4.39606 3.15109 4.39606 3.32648V8.6737C4.39606 8.84909 4.25062 8.99453 4.07523 8.99453Z" />
            </mask>
            <path
                d="M4.07523 8.99453C3.89984 8.99453 3.75439 8.84909 3.75439 8.6737V3.32648C3.75439 3.15109 3.89984 3.00565 4.07523 3.00565C4.25062 3.00565 4.39606 3.15109 4.39606 3.32648V8.6737C4.39606 8.84909 4.25062 8.99453 4.07523 8.99453Z"
                fill="white"
            />
            <path
                d="M4.07523 7.99453C4.45212 7.99453 4.75439 8.29681 4.75439 8.6737H2.75439C2.75439 9.40138 3.34755 9.99453 4.07523 9.99453V7.99453ZM4.75439 8.6737V3.32648H2.75439V8.6737H4.75439ZM4.75439 3.32648C4.75439 3.70338 4.45212 4.00565 4.07523 4.00565V2.00565C3.34755 2.00565 2.75439 2.59881 2.75439 3.32648H4.75439ZM4.07523 4.00565C3.69833 4.00565 3.39606 3.70338 3.39606 3.32648H5.39606C5.39606 2.59881 4.8029 2.00565 4.07523 2.00565V4.00565ZM3.39606 3.32648V8.6737H5.39606V3.32648H3.39606ZM3.39606 8.6737C3.39606 8.29681 3.69833 7.99453 4.07523 7.99453V9.99453C4.8029 9.99453 5.39606 9.40138 5.39606 8.6737H3.39606Z"
                fill="white"
                mask="url(#path-3-inside-2_2468_1410)"
            />
            <mask
                id="path-5-inside-3_2468_1410"
                fill="white">
                <path d="M6.00003 10.064C5.82464 10.064 5.6792 9.91855 5.6792 9.74316V2.25705C5.6792 2.08166 5.82464 1.93622 6.00003 1.93622C6.17542 1.93622 6.32087 2.08166 6.32087 2.25705V9.74316C6.32087 9.91855 6.17542 10.064 6.00003 10.064Z" />
            </mask>
            <path
                d="M6.00003 10.064C5.82464 10.064 5.6792 9.91855 5.6792 9.74316V2.25705C5.6792 2.08166 5.82464 1.93622 6.00003 1.93622C6.17542 1.93622 6.32087 2.08166 6.32087 2.25705V9.74316C6.32087 9.91855 6.17542 10.064 6.00003 10.064Z"
                fill="white"
            />
            <path
                d="M6.00003 9.064C6.37693 9.064 6.6792 9.36627 6.6792 9.74316H4.6792C4.6792 10.4708 5.27236 11.064 6.00003 11.064V9.064ZM6.6792 9.74316V2.25705H4.6792V9.74316H6.6792ZM6.6792 2.25705C6.6792 2.63395 6.37693 2.93622 6.00003 2.93622V0.936218C5.27236 0.936218 4.6792 1.52938 4.6792 2.25705H6.6792ZM6.00003 2.93622C5.62314 2.93622 5.32087 2.63395 5.32087 2.25705H7.32087C7.32087 1.52938 6.72771 0.936218 6.00003 0.936218V2.93622ZM5.32087 2.25705V9.74316H7.32087V2.25705H5.32087ZM5.32087 9.74316C5.32087 9.36627 5.62314 9.064 6.00003 9.064V11.064C6.72771 11.064 7.32087 10.4708 7.32087 9.74316H5.32087Z"
                fill="white"
                mask="url(#path-5-inside-3_2468_1410)"
            />
            <mask
                id="path-7-inside-4_2468_1410"
                fill="white">
                <path d="M7.92484 8.99453C7.74945 8.99453 7.604 8.84909 7.604 8.6737V3.32648C7.604 3.15109 7.74945 3.00565 7.92484 3.00565C8.10023 3.00565 8.24567 3.15109 8.24567 3.32648V8.6737C8.24567 8.84909 8.10023 8.99453 7.92484 8.99453Z" />
            </mask>
            <path
                d="M7.92484 8.99453C7.74945 8.99453 7.604 8.84909 7.604 8.6737V3.32648C7.604 3.15109 7.74945 3.00565 7.92484 3.00565C8.10023 3.00565 8.24567 3.15109 8.24567 3.32648V8.6737C8.24567 8.84909 8.10023 8.99453 7.92484 8.99453Z"
                fill="white"
            />
            <path
                d="M7.92484 7.99453C8.30173 7.99453 8.604 8.29681 8.604 8.6737H6.604C6.604 9.40138 7.19716 9.99453 7.92484 9.99453V7.99453ZM8.604 8.6737V3.32648H6.604V8.6737H8.604ZM8.604 3.32648C8.604 3.70338 8.30173 4.00565 7.92484 4.00565V2.00565C7.19716 2.00565 6.604 2.59881 6.604 3.32648H8.604ZM7.92484 4.00565C7.54794 4.00565 7.24567 3.70338 7.24567 3.32648H9.24567C9.24567 2.59881 8.65251 2.00565 7.92484 2.00565V4.00565ZM7.24567 3.32648V8.6737H9.24567V3.32648H7.24567ZM7.24567 8.6737C7.24567 8.29681 7.54794 7.99453 7.92484 7.99453V9.99453C8.65251 9.99453 9.24567 9.40138 9.24567 8.6737H7.24567Z"
                fill="white"
                mask="url(#path-7-inside-4_2468_1410)"
            />
            <mask
                id="path-9-inside-5_2468_1410"
                fill="white">
                <path d="M9.85013 7.9251C9.67474 7.9251 9.5293 7.77966 9.5293 7.60427V4.39594C9.5293 4.22055 9.67474 4.0751 9.85013 4.0751C10.0255 4.0751 10.171 4.22055 10.171 4.39594V7.60427C10.171 7.77966 10.0255 7.9251 9.85013 7.9251Z" />
            </mask>
            <path
                d="M9.85013 7.9251C9.67474 7.9251 9.5293 7.77966 9.5293 7.60427V4.39594C9.5293 4.22055 9.67474 4.0751 9.85013 4.0751C10.0255 4.0751 10.171 4.22055 10.171 4.39594V7.60427C10.171 7.77966 10.0255 7.9251 9.85013 7.9251Z"
                fill="white"
            />
            <path
                d="M9.85013 6.9251C10.227 6.9251 10.5293 7.22737 10.5293 7.60427H8.5293C8.5293 8.33195 9.12246 8.9251 9.85013 8.9251V6.9251ZM10.5293 7.60427V4.39594H8.5293V7.60427H10.5293ZM10.5293 4.39594C10.5293 4.77283 10.227 5.0751 9.85013 5.0751V3.0751C9.12246 3.0751 8.5293 3.66826 8.5293 4.39594H10.5293ZM9.85013 5.0751C9.47324 5.0751 9.17096 4.77283 9.17096 4.39594H11.171C11.171 3.66826 10.5778 3.0751 9.85013 3.0751V5.0751ZM9.17096 4.39594V7.60427H11.171V4.39594H9.17096ZM9.17096 7.60427C9.17096 7.22737 9.47324 6.9251 9.85013 6.9251V8.9251C10.5778 8.9251 11.171 8.33195 11.171 7.60427H9.17096Z"
                fill="white"
                mask="url(#path-9-inside-5_2468_1410)"
            />
        </svg>
    )
}
