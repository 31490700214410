import {
    Container,
    FormLabel,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { errorMessages } from '../../constants/errorMessages'
import { ButtonSpinner } from '../custom'
import EmailIcon from '@mui/icons-material/Email'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import './signup.scss'
import bg1 from '../../assests/images/bg1.png'
import bg2 from '../../assests/images/bg2.png'
import bg3 from '../../assests/images/bg3.png'
import bg4 from '../../assests/images/bg4.png'
import bg5 from '../../assests/images/bg5.png'

const bgImages = [bg1, bg2, bg3, bg4, bg5]
// Computation
const randomBgImage = bgImages[Math.floor(Math.random() * bgImages.length)]

const SignUpLinkForm = ({ handleSubmit, decryptedData, handleResendLink }) => {
    const formikRef = useRef()
    const [step, setStep] = useState(1)
    const notification = useNotification()
    const validateSchema = Yup.object().shape({
        email: Yup.string().email(errorMessages.validEmailId),
    })
    if (!decryptedData && !decryptedData?.email) {
        return (
            <Container className="sign-up-wrapper">
                <Grid container className="form-wrapper" style={{ gap: '10px', height: '100vh' }}>
                    <Grid item xs={12} sm={12} span={12} ml={2}>
                        <Grid className="logo-wrapper">
                            <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            span={12}
                            ml={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    border: '1px solid #CFCFCF',
                                    background: '#FAFAFA',
                                    flexDirection: 'column',
                                    width: '637px',
                                    height: '342px',
                                    gap: '5px',
                                }}>
                                <span className="text-verification">Invalid Link</span>
                                <ButtonSpinner
                                    // disabled={Boolean(isSubmitting)}
                                    // isLoading={Boolean(isSubmitting)}
                                    onclick={() => history.push('/signup')}
                                    label={'Go back to home'}
                                    className="btn-portal"
                                    sx={{
                                        width: '50%',
                                        textTransform: 'none',
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    }
    return (
        <Container className="sign-up-wrapper">
            <Grid container className="form-wrapper">
                <Grid item xs={12} sm={6} span={12}>
                    <div
                        className="form-img"
                        style={{
                            // background:
                            // 'url(http://source.unsplash.com/620x700/?medical)',
                            background: `url(${randomBgImage})`,
                            borderRadius: '10px',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} span={12} ml={2} className="right-panel">
                    <Grid className="logo-wrapper">
                        <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                    </Grid>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            email: decryptedData?.email || '',
                        }}
                        validationSchema={validateSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                if (step === 1) {
                                    const res = await handleSubmit(values)
                                    if (res && res?.data?.status) {
                                        setStep(2)
                                    } else {
                                        notification(
                                            res?.data?.message || 'Somthing is wrong',
                                            'error',
                                        )
                                    }
                                }
                                if (step === 2) {
                                    const res = await handleResendLink(values)
                                    if (res && res?.data?.status) {
                                        setStep(2)
                                    } else {
                                        notification(
                                            res?.data?.message || 'Somthing is wrong',
                                            'error',
                                        )
                                    }
                                }
                                setSubmitting(false)
                            } catch (error) {
                                notification('Somthing is wrong', 'error')
                                setSubmitting(false)
                            }
                        }}>
                        {({
                            errors,
                            touched,
                            isValid,
                            values,
                            isSubmitting,
                            dirty,
                            handleChange,
                            handleSubmit,
                        }) => {
                            return (
                                <form
                                    className="form registration"
                                    onSubmit={handleSubmit}
                                    noValidate>
                                    <div className="inner-wrapper-logo">
                                        <img
                                            className="mobile-logo"
                                            src={logo}
                                            alt="TeleMed2U"></img>
                                    </div>
                                    <h1 className="align-item-center">
                                        {step === 1
                                            ? 'Welcome to the patient portal!'
                                            : step === 2
                                            ? 'Check your email to finish signing up!'
                                            : ''}
                                    </h1>
                                    {step === 1 && (
                                        <p className="para-tag">
                                            Enter your email information to set up your patient
                                            portal account.
                                        </p>
                                    )}
                                    {step === 2 && (
                                        <p className="resend-email-paragraph">
                                            {`We have sent you a link to ${values.email}. Please
                                            follow the instructions to access your patient portal.`}
                                        </p>
                                    )}

                                    {step === 1 && (
                                        <>
                                            <h2>Sign Up</h2>
                                            <FormLabel className="input-label" component="legend">
                                                Email Address
                                            </FormLabel>
                                            <TextField
                                                id="auto-reg-email"
                                                required
                                                error={errors.email && touched.email}
                                                helperText={
                                                    errors.email && touched.email
                                                        ? errors.email
                                                        : ''
                                                }
                                                variant="outlined"
                                                size="small"
                                                className="signup-text-input"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={values.email}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton
                                                                aria-label="toggle  visibility"
                                                                edge="start">
                                                                <EmailIcon
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: '12px',
                                                                        marginLeft: '5px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </>
                                    )}
                                    {step === 2 && (
                                        <FormLabel
                                            className="input-label"
                                            component="legend"
                                            style={{
                                                marginTop: 25,
                                                color: '#303E4E',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                            }}>
                                            Didn’t get an email?
                                        </FormLabel>
                                    )}
                                    <div className="submit">
                                        {step === 1 && (
                                            <ButtonSpinner
                                                disabled={Boolean(isSubmitting)}
                                                isLoading={Boolean(isSubmitting)}
                                                label={'Sign up with password'}
                                                className="btn-submit"
                                                sx={{
                                                    width: '100%',
                                                }}
                                                // onclick={() => setStep(2)}
                                            />
                                        )}
                                        {step === 2 && (
                                            <ButtonSpinner
                                                disabled={Boolean(isSubmitting)}
                                                isLoading={Boolean(isSubmitting)}
                                                label={'Resend sign up link'}
                                                className="btn-resend"
                                                sx={{
                                                    width: '100%',
                                                }}
                                            />
                                        )}
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                    <Grid className="form-footer">
                        <p className="footer-tag">
                            If you have any questions or need assistance, please call us at{' '}
                            <a>(562) 268-0955</a>.{' '}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SignUpLinkForm
