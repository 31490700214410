import React from 'react'
import ForgotPassword from '../../components/SignIn/ForgotPassword'
import {
    completeSignUp,
    verifyEmailSignUp,
    verifyResendEmailSignUp,
} from '../../services/Login/login'
import { decryptData } from '../../utilities'
const ForgotPasswordContainer = () => {
    const [decryptedData, setDecryptedData] = React.useState(null)

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const encryptedData = queryParams.get('data')
        try {
            const parsedData = decryptData(encryptedData)
            setDecryptedData(parsedData)
        } catch (error) {
            console.error('Error parsing decrypted data:', error)
        }
    }, [])
    const handleSubmit = async (values) => {
        try {
            const res = await completeSignUp(values)
            return res
        } catch (error) {
            return error
        }
    }
    const handleResendLink = async (values) => {
        try {
            const res = await verifyResendEmailSignUp(values)
            return res
        } catch (error) {
            return error
        }
    }
    return (
        <div>
            <ForgotPassword
                handleSubmit={handleSubmit}
                decryptedData={decryptedData}
                handleResendLink={handleResendLink}
            />
        </div>
    )
}

export default ForgotPasswordContainer
