export const SCREEN = {
    PATIENT_DETAILS: 'PATIENT_DETAILS',
    ADD_PERSON: 'ADD_PERSON',
    INSURANCE: 'INSURANCE',
    BEHAVIRIOAL_QUESTIONS: 'BEHAVIRIOAL_QUESTIONS',
    ADD_PATIENT: 'ADD_PATIENT',
}

export const TABS = [
    {
        key: SCREEN.PATIENT_DETAILS,
        value: 'Patient Details',
    },
    { key: SCREEN.ADD_PERSON, value: 'Add Person' },
    { key: SCREEN.INSURANCE, value: 'Insurance' },
]

export const PATIENT_TYPE = {
    CHILD: 'child',
    ADULT: 'adult',
}

export const ANSWER_TYPE = {
    YES: 'Yes',
    NO: 'No',
}

export const QUESTION1 = 'Do you currently have suicidal thoughts or thoughts of harming others?'
export const QUESTION2 =
    'Do you have any current or pending legal issues or are you being advised by a court judge, parole officer, or CPS to seek  mental health care or assessment?'
export const QUESTION3 =
    'Please select if you have ever experienced or suffered from any of the following in the past or present?'
export const QUESTION4 = 'The specialty you have chosen for this appointment'
export const QUESTION5 = 'Select your preferred location for this appointment'

export const SUICIDAL_WARNING =
    'If you are experiencing a crisis or emergency, please dial 911. You may also access the free 24-hour National Suicide Prevention Lifeline at 1-800-273-8255 (1-800-273-TALK).'

export const stateList = [
    { state: 'AK', stateLabel: 'Alaska' },
    { state: 'AL', stateLabel: 'Alabama' },
    { state: 'AR', stateLabel: 'Arkansas' },
    { state: 'AZ', stateLabel: 'Arizona' },
    { state: 'CA', stateLabel: 'California' },
    { state: 'CO', stateLabel: 'Colorado' },
    { state: 'CT', stateLabel: 'Connecticut' },
    { state: 'DC', stateLabel: 'Districtof Columbia' },
    { state: 'DE', stateLabel: 'Delaware' },
    { state: 'FL', stateLabel: 'Florida' },
    { state: 'GA', stateLabel: 'Georgia' },
    { state: 'HI', stateLabel: 'Hawaii' },
    { state: 'IA', stateLabel: 'Iowa' },
    { state: 'ID', stateLabel: 'Idaho' },
    { state: 'IL', stateLabel: 'Illinois' },
    { state: 'IN', stateLabel: 'Indiana' },
    { state: 'KS', stateLabel: 'Kansas' },
    { state: 'KY', stateLabel: 'Kentucky' },
    { state: 'LA', stateLabel: 'Louisiana' },
    { state: 'MA', stateLabel: 'Massachusetts' },
    { state: 'MD', stateLabel: 'Maryland' },
    { state: 'ME', stateLabel: 'Maine' },
    { state: 'MI', stateLabel: 'Michigan' },
    { state: 'MN', stateLabel: 'Minnesota' },
    { state: 'MO', stateLabel: 'Missouri' },
    { state: 'MS', stateLabel: 'Mississippi' },
    { state: 'MT', stateLabel: 'Montana' },
    { state: 'NC', stateLabel: 'North Carolina' },
    { state: 'ND', stateLabel: 'North Dakota' },
    { state: 'NE', stateLabel: 'Nebraska' },
    { state: 'NH', stateLabel: 'New Hampshire' },
    { state: 'NJ', stateLabel: 'New Jersey' },
    { state: 'NM', stateLabel: 'New Mexico' },
    { state: 'NV', stateLabel: 'Nevada' },
    { state: 'NY', stateLabel: 'New York' },
    { state: 'OH', stateLabel: 'Ohio' },
    { state: 'OK', stateLabel: 'Oklahoma' },
    { state: 'OR', stateLabel: 'Oregon' },
    { state: 'PA', stateLabel: 'Pennsylvania' },
    { state: 'RI', stateLabel: 'Rhode Island' },
    { state: 'SC', stateLabel: 'South Carolina' },
    { state: 'SD', stateLabel: 'South Dakota' },
    { state: 'TN', stateLabel: 'Tennessee' },
    { state: 'TX', stateLabel: 'Texas' },
    { state: 'UT', stateLabel: 'Utah' },
    { state: 'VA', stateLabel: 'Virginia' },
    { state: 'VT', stateLabel: 'Vermont' },
    { state: 'WA', stateLabel: 'Washington' },
    { state: 'WI', stateLabel: 'Wisconsin' },
    { state: 'WV', stateLabel: 'West Virginia' },
    { state: 'WY', stateLabel: 'Wyoming' },
].sort((p, q) => p.stateLabel.localeCompare(q.stateLabel))
