/* eslint-disable react/jsx-indent */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { CircularProgress, Grid } from '@material-ui/core'

import {
    getPatientProfile,
    resetPatientProfile,
} from '../../../store/actions/patient'
import {
    getLoggedInUserId,
    getLoggedInUserRole,
} from '../../../utilities'

// patient profile sections
import ProfileTitle from '../Common/ProfileTitle'
import PatientInformation from './PatientSections/PatientInformation'
import PatientSecurityQuestions from './PatientSections/PatientSecurityQuestions'
import PatientDepents from './PatientSections/PatientDependents'
import PatientPrimaryInsurer from './PatientSections/PatientPrimaryInsurer'
import PatientPrimaryInsurance from './PatientSections/PatientPrimaryInsurance'
import PatientReferingProvider from './PatientSections/PatientReferingProvider'
import PatientPharmacy from './PatientSections/PatientPharmacy'
import Notification from './PatientSections/Notification'
import PatientProfileEdit from './PatientProfileEdit'

import '../MainProfile/MainProfile.scss'
import { getNotificationPreferences } from '../../../services/Notification'
import { UserRoles } from '../../../constants/user-roles'

class PatientProfileDetails extends Component {
    state = {
        isEdit: false,
        isCreate: false,
        isView: false,
        isProfile: false,
        preferences: {
            email: false,
            sms: false,
            app_notification: false,
        },
        userRole: getLoggedInUserRole(),
    }

    componentDidMount() {
        const {
            isEditMode,
            isCreateMode,
            isViewMode,
            isUserProfile,
            isProfile,
        } = this.props

        this.setState({
            isEdit: !!isEditMode,
            isCreate: !!isCreateMode,
            isView: !!isViewMode,
            isUserProfile,
            isProfile,
        })

        // get profile data
        this.fetchPatientProfile()
        if (
            this.state.userRole === UserRoles.PATIENT ||
            this.state.userRole ===
                UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
    }

    componentWillUnmount() {
        const {
            actions: { resetPatientProfile },
        } = this.props
        resetPatientProfile()
    }

    fetchNotificationPreferences = async () => {
        try {
            const userId = this.props.isUserProfile
                ? this.props.userId
                : getLoggedInUserId()
            const preferences =
                await getNotificationPreferences({
                    userId,
                })
            this.setState({
                ...this.state,
                preferences:
                    preferences.data?.data ??
                    this.state.preferences,
            })
        } catch (error) {
            console.log(
                'Failed to retrieve notification preferences',
            )
        }
    }

    fetchPatientProfile = () => {
        const {
            isCreateMode,
            isUserProfile,
            userId,
            actions: { getPatientProfile },
            patientProfile,
            isProfile,
        } = this.props

        const profileId = isUserProfile
            ? userId
            : getLoggedInUserId()

        if (
            (isUserProfile &&
                !isCreateMode &&
                !Object.keys(patientProfile).length) ||
            isProfile
        ) {
            getPatientProfile(profileId)
        }
    }

    handleEdit = () => {
        const { isEdit, isView } = this.state
        this.setState({ isEdit: !isEdit, isView: !isView })
    }

    handleCloseEdit = (
        isEdit = !this.state.isEdit,
        isView = !this.state.isView,
    ) => {
        this.fetchPatientProfile()
        this.setState({ isEdit, isView })
        if (
            this.state.userRole === UserRoles.PATIENT ||
            this.state.userRole ===
                UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
    }

    render() {
        const { isCreate, isView, preferences } = this.state

        const { patientProfile, toggleToUserList } =
            this.props

        return !isCreate &&
            !Object.keys(patientProfile).length ? (
            <CircularProgress className="loader" />
        ) : (
            <>
                <ProfileTitle
                    profileDetails={patientProfile}
                    isCreate={isCreate}
                    onEditCallback={this.handleCloseEdit}
                    isProvider={false}
                    photoEditAccess={!isView}
                />
                {isView ? (
                    <>
                        {/* PatientInfo */}
                        <PatientInformation
                            patientProfile={patientProfile}
                            handleEdit={this.handleEdit}
                        />

                        {/* SecurityQues */}
                        <PatientSecurityQuestions
                            patientProfile={patientProfile}
                        />

                        {/* //dependents */}
                        <PatientDepents
                            patientProfile={patientProfile}
                        />

                        {/* //Primary insurer */}
                        <PatientPrimaryInsurer
                            patientProfile={patientProfile}
                        />

                        {/* //primary insurance */}
                        <PatientPrimaryInsurance
                            patientProfile={patientProfile}
                        />
                        {/* referring provider */}
                        <PatientReferingProvider
                            patientProfile={patientProfile}
                        />

                        {/* Pharmacy */}
                        <PatientPharmacy
                            patientProfile={patientProfile}
                        />

                        {/** Notification */}
                        {/* {(this.state.userRole ===
                            UserRoles.PATIENT ||
                            this.state.userRole ===
                                UserRoles.TM2U_SUPER_ADMIN ||
                            this.state.userRole ===
                                UserRoles.TM2U_ADMIN) && (
                            <Notification
                                preferences={preferences}
                            />
                        )} */}
                    </>
                ) : (
                    <PatientProfileEdit
                        profileDetails={patientProfile}
                        onEditCallback={
                            this.handleCloseEdit
                        }
                        isCreate={isCreate}
                        onSaveCallback={toggleToUserList}
                        preferences={preferences}
                    />
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        patient: { patientProfile },
    } = state

    return { patientProfile }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    getPatientProfile,
                    resetPatientProfile,
                },
                dispatch,
            ),
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PatientProfileDetails)
