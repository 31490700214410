import { Redirect } from 'react-router-dom'

//Constants
import { authRequired } from '../utilities/guards'
import Routes from '../constants/routes'
import { getAllUserRoles, UserRoles } from '../constants/user-roles'
import userRoutes from './users-routes'

//Dashboard
import Dashboard from '../containers/Dashboard'

//Patient Health
import HealthReport from '../components/HealthReport'
import HealthConditions from '../components/HeathConditions'
import LabResults from '../components/LabResults'
import ClinicalNotes from '../components/ClinicalNotesGuest'
import MedicationAllergies from '../components/MedicationAllergies'
import Medications from '../components/Medications'
import PatientBookAppointment from '../containers/Appointments/PatientBookAppointment'
//Appointments
import AppointmentNew from '../components/AppointmentNew'

//Contact
import MessageCareTeam from '../components/MessageCareTeam'
import Contacts from '../components/Contacts'
import PatientInsurance from '../components/PatientInsurance'
import MainIndex from '../components/Consultation/MainIndex'

const meta = {
    auth: true,
    guards: [authRequired],
}

const authRoutes = [
    {
        path: [Routes.HOME, Routes.DASHBOARD],
        roles: getAllUserRoles(),
        component: Dashboard,
        meta,
    },
    {
        path: [
            Routes.CONSULTATION.URL,
            Routes.CONSULTATION.VIDEO,
            Routes.CONSULTATION.JOIN,
            Routes.CONSULTATION.ROOM,
            Routes.CONSULTATION.SHORT_LINK,
        ],
        roles: getAllUserRoles(),
        component: MainIndex,
        meta: { ...meta, isVideo: true },
    },
    {
        path: [Routes.MY_HEALTH_RECORD],
        roles: getAllUserRoles(),
        component: HealthReport,
        meta,
    },
    {
        path: [Routes.HEALTH_CONDITIONS],
        roles: getAllUserRoles(),
        component: HealthConditions,
        meta,
    },
    {
        path: [Routes.LAB_RESULTS],
        roles: getAllUserRoles(),
        component: LabResults,
        meta,
    },
    {
        path: [Routes.PATIENT_INSURANCE],
        roles: getAllUserRoles(),
        component: PatientInsurance,
        meta,
    },
    {
        path: [Routes.MEDICATIONS],
        roles: getAllUserRoles(),
        component: Medications,
        meta,
    },
    {
        path: [Routes.CLINICAL_NOTES_VIEW.VIEW_LIST],
        roles: getAllUserRoles(),
        component: ClinicalNotes,
        meta,
    },
    {
        path: [Routes.MEDICATION_ALLERRGIES],
        roles: getAllUserRoles(),
        component: MedicationAllergies,
        meta,
    },
    {
        path: [Routes.APPOINTMENT],
        roles: getAllUserRoles(),
        component: AppointmentNew,
        meta,
    },
    {
        path: [Routes.MESSAGE_CARE_TEAM],
        roles: getAllUserRoles(),
        component: MessageCareTeam,
        meta,
    },
    {
        path: [Routes.PATAIENTBOOKAPPOINTMENT],
        component: PatientBookAppointment,
        meta,
    },
    {
        path: [Routes.CONTACTS],
        component: Contacts,
        meta,
    },
    {
        path: '*',
        roles: getAllUserRoles(),
        component: () => (
            // eslint-disable-next-line react/react-in-jsx-scope
            <Redirect to={Routes.DASHBOARD}></Redirect>
        ),
        meta,
    },
]

export default authRoutes
