import * as actionType from '../actionType'

const initialState = {
    faxList: [],
    notesList: [],
    totalCount: 0,
    isLoading: false,
    numbers: [],
    search: {
        archive: false,
        search: false,
        filterString: '',
        archiveSearch: false,
    },
    pagination: {
        page: 1,
        limit: 10,
    },
    notePagination: {
        page: 1,
        limit: 10,
    },
    succesMsg: '',
}

const fax = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_FAX_NUMBER: {
            return { ...state, number: action.value }
        }

        case actionType.SET_FAX_NUMBERS: {
            return { ...state, numbers: action.value }
        }

        case actionType.SET_FAX_LIST: {
            return {
                ...state,
                faxList: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        case actionType.SET_FAX_COUNT: {
            return {
                ...state,
                totalCount: action.value,
            }
        }
        case actionType.SET_FAX_PAGE_CHANGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            }
        }
        case actionType.SET_FAX_SEARCH_CRITERIA: {
            return {
                ...state,
                search: action.value,
                pagination: {
                    page: 1,
                    limit: 10,
                },
            }
        }
        case actionType.SET_FAX_LOADING: {
            return {
                ...state,
                isLoading: action.value,
            }
        }
        case actionType.SET_NOTES_LIST: {
            return {
                ...state,
                notesList: action.value,
                succesMsg: '',
            }
        }
        case actionType.SET_ADD_NOTES: {
            return {
                ...state,
                succesMsg: action.value,
            }
        }
        case actionType.SET_NOTES_PAGE_CHANGE: {
            return {
                ...state,
                notePagination: {
                    ...state.notePagination,
                    page: action.page,
                },
            }
        }
        default:
            return state
    }
}

export default fax
