import { Button, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useHistory, useLocation } from 'react-router'
import Countdown from 'react-countdown'
import IconClock from '../../assests/icons/IconClock.js'
import * as React from 'react'
import provider1 from '../../assests/images/provider1.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
        '& .MuiDialogTitle-root': {
            padding: '16px 8px',
            [theme.breakpoints.up('sm')]: {
                padding: '16px 24px',
            },
        },
        '& .MuiButtonBase-root': {
            padding: '4px 16px',
            [theme.breakpoints.up('sm')]: {
                padding: '6px 16px',
            },
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    text_ct: {
        color: '#303E4E',
        lineHeight: 'normal',
        fontSize: '16px',
        padding: 8,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '17px',
            padding: '15px 15px 45px',
            lineHeight: '26px',
        },
    },
    btn_cancel: {
        background: '#303E4E',
        width: 'calc((100% - 10px) / 2)',
        fontSize: 15,
        borderRadius: 12,
        color: 'white',
        fontWeight: 'normal',
        marginRight: '10px',
        '&:hover': {
            background: '#143254',
        },
    },
    btn_delete: {
        fontSize: 15,
        width: 'calc((100% - 10px) / 2)',
        borderRadius: 12,
        fontWeight: 500,
    },
    footer_btn: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '15px ',
        justifyContent: 'center',
    },
    title_box: {
        // color: '#3bbad7',
        fontSize: '25px',
        padding: '15px 15px',
        fontWeight: 600,
        textAlign: 'center',
    },
}))

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function AlertConfirmEncounterJoin({
    open,
    isMobile,
    userInfor,
    handleClose,
    enableSession,
}) {
    const query = useQuery()
    const history = useHistory()
    const location = useLocation()
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)

    const handleJoinMainEncounter = () => {
        // console.log('Redirect to the main encounter.')
        // const data = query.get('data')
        // history.push(`/join?data=${encodeURIComponent(data)}`, {
        //     infoRoom: location?.state?.infoRoom,
        // })
        enableSession(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <img src={provider1} />
            <Typography className={classes.title_box}>Your appointment has started</Typography>
            {/* <Typography className={classes.text_ct}>
                <p>
                    <IconClock width={isMobile ? 30 : 21} height={isMobile ? 30 : 21} />{' '}
                    {!!userInfor.startTime && (
                        <Countdown
                            className={classes.time}
                            date={moment(userInfor.actStart).valueOf()}
                            daysInHours={true}
                        />
                    )}
                </p>
                <p>
                    Would you like to join the encounter? A staff member will be with you shortly.
                </p>
            </Typography> */}
            <Box className={classes.footer_btn}>
                {/* <Button
                    disabled={isLoading}
                    onClick={() => {
                        handleClose(false)
                    }}
                    variant="contained"
                    className={classes.btn_cancel}>
                    Cancel
                </Button> */}
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        handleJoinMainEncounter(true)
                    }}
                    // autoFocus
                    variant="contained"
                    color="primary"
                    className={classes.btn_delete}>
                    Join Encounter
                </Button>
            </Box>
        </Dialog>
    )
}
