import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Typography,
} from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import CloseIcon from '../../components/Consultation/icons/CloseIcon'
import { SCREEN } from './constant'
import BookingHeader from './Header'
import { INITIAL_VALUES } from './schema'
import './styles.scss'
import BookingTab from './Tab'
import BehaviouralDetails from './Views/BehaviouralDetails'
import PatientDetails from './Views/PatientDetails'
import AddPerson from './Views/AddPerson'
import Insurance from './Views/Insurance'
import { ModalEl } from '../../shared/elements'

function AppointmentBooking(props) {
    return (
        <ModalEl
            title="Booking Appointment"
            description="Booking Appointment Details"
            open={props.open}
            onClose={props.onModalClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid
                container
                className="appointment-booking-container">
                <Formik initialValues={INITIAL_VALUES}>
                    {({ values, setFieldValue }) => {
                        console.log(values)
                        return (
                            <Grid container item>
                                <Grid
                                    item
                                    xs={12}
                                    className="booking-header">
                                    <BookingHeader
                                        provider={
                                            values.provider
                                        }
                                        start={values.start}
                                        end={values.end}
                                        category={
                                            values.category
                                        }
                                    />
                                </Grid>
                                {/* Tabs */}
                                <Grid
                                    container
                                    item
                                    justifyContent="space-between"
                                    direction="row"
                                    className="booking-tabs">
                                    <Grid
                                        item
                                        className="modal-spacer">
                                        <BookingTab
                                            value={
                                                values.selectedTab
                                            }
                                            onChange={(
                                                value,
                                            ) => {
                                                setFieldValue(
                                                    'selectedTab',
                                                    value,
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        className="modal-spacer">
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setFieldValue(
                                                    'isNewPatient',
                                                    !values.isNewPatient,
                                                )
                                                setFieldValue(
                                                    'selectedTab',
                                                    SCREEN.PATIENT_DETAILS,
                                                )

                                                setFieldValue(
                                                    'selectedScreen',
                                                    !values.isNewPatient
                                                        ? SCREEN.BEHAVIRIOAL_QUESTIONS
                                                        : SCREEN.PATIENT_DETAILS,
                                                )
                                            }}>
                                            <Typography>
                                                {!values.isNewPatient
                                                    ? 'Add New Patient'
                                                    : 'Search Existing Patient'}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                                {/* Tabs Panel */}
                                {values.selectedTab ===
                                    SCREEN.PATIENT_DETAILS && (
                                    <PatientDetails
                                        values={values}
                                        setFieldValue={
                                            setFieldValue
                                        }
                                    />
                                )}
                                {values.selectedScreen ===
                                    SCREEN.BEHAVIRIOAL_QUESTIONS &&
                                    values.selectedTab ===
                                        SCREEN.PATIENT_DETAILS && (
                                        <BehaviouralDetails
                                            {...props}
                                            values={values}
                                            setFieldValue={
                                                setFieldValue
                                            }
                                        />
                                    )}
                                {values.selectedTab ===
                                    SCREEN.ADD_PERSON && (
                                    <AddPerson
                                        values={values}
                                        setFieldValue={
                                            setFieldValue
                                        }
                                    />
                                )}
                                {values.selectedTab ===
                                    SCREEN.INSURANCE && (
                                    <Insurance
                                        values={values}
                                        setFieldValue={
                                            setFieldValue
                                        }
                                    />
                                )}
                                <Grid
                                    container
                                    item
                                    justifyContent="space-between"
                                    direction="row"
                                    className="border-top">
                                    <Grid
                                        item
                                        className="modal-spacer">
                                        <FormControlLabel
                                            label="I agree to the TeleMed2U Terms and Conditions and the Privacy Policy."
                                            control={
                                                <Checkbox color="primary" />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    justifyContent="flex-end"
                                    direction="row">
                                    <Grid item>
                                        <Button variant="contained">
                                            <Typography>
                                                Book
                                                Appointment
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }}
                </Formik>
            </Grid>
        </ModalEl>
    )
}

export default AppointmentBooking
