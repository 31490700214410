import { Button, Grid, Theme, useMediaQuery } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import AddParticipantForm from './../AddParticipantForm/AddParticipantForm'
import {
    LocalAudioTrack,
    LocalVideoTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack,
} from 'twilio-video'
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting'
import useChatContext from './../../hooks/useChatContext/useChatContext'
import useParticipants from '../../hooks/useParticipants/useParticipants'
import useProviderContext from '../../hooks/useProviderContext/useProviderContext'
import usePublications from '../../hooks/usePublications/usePublications'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant'
import useTrack from '../../hooks/useTrack/useTrack'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import image4 from '../../icons/image_28.png'
import image1 from '../../icons/image_29.png'
import image2 from '../../icons/image_30.png'
import VerticalVolumn from '../VerticalVolumn/VerticalVolumn'
import ChatWindow from '../ChatWindow/ChatWindow'
// import VideoChat from '../../../TwilioVideoPatient/VideoChat'
import { useLocation } from 'react-router-dom'
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator'
import TeleDisableVideoUserIcon from '../../icons/TeleDisableVideoUserIcon'
import { TranslateServie } from '../MenuBar/Menu/TranslateServie'
import { Box, ClassNameMap } from '@mui/material'
import GTranslateIcon from '@mui/icons-material/GTranslate'
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk'
import './styles.scss'
import { StateContext } from '../../state'

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            container_main: {
                position: 'relative',
                display: 'flex',
                flex: 1,
                height: '100%',
                alignItems: 'center',
                background: '#000',
                borderRadius: '10px',
                overflow: 'hidden',
                [theme.breakpoints.down('sm')]: {
                    borderRadius: '0px',
                },
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    left: '0',
                    bottom: 0,
                    background: '#ffffff75',
                    width: '100%',
                    height: '101px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    [theme.breakpoints.down('sm')]: {
                        display: 'none',
                    },
                },
            },
            identity: {
                background: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                padding: '0.1em 0.3em 0.1em 0',
                display: 'inline-flex',
                '& svg': {
                    marginLeft: '0.3em',
                },
                marginRight: '0.4em',
                alignItems: 'center',
            },
            infoContainer: {
                position: 'absolute',
                zIndex: 2,
                height: '100%',
                width: '100%',
            },
            collapse__participants: {
                background: 'rgba(3, 12, 37, 0.75)',
                borderRadius: '18px',
                padding: 16,
                width: 280,
                position: 'relative',
            },
            buttons: {
                margin: '0px 6px',
                padding: '0px',
                background: 'rgba(3, 12, 37, 0.75)',
                width: '50px',
                height: '50px',
                borderRadius: '18px',
                display: 'flex',
                alignItems: 'center',
                minWidth: 'fit-content',
                '&:hover': {
                    background: 'rgba(34,43,55,0.5) !important',
                },
            },
            text__overlay: {
                position: 'absolute',
                background: 'rgba(3, 12, 37, 0.5)',
                top: 0,
                width: '100%',
                height: '100%',
                left: 0,
                zIndex: 1,
            },
            right_side_container: {
                background: 'white',
                boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
                borderRadius: '16px',
                marginLeft: '24px',
                width: '350px',
                overflow: 'hidden',
                transition: 'all 0.5s',
                // position: 'absolute',
                // top: 0,
                // right: 0,
                [theme.breakpoints.down('sm')]: {
                    zIndex: 1000,
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    height: 'calc(100% - 80px)',
                    marginLeft: 0,
                    borderRadius: '16px 16px 0 0',
                },
            },
            right_side_container_closed: {
                width: 0,
                marginLeft: 0,
            },
            image_participant: {
                width: '280px',
                position: 'relative',
                height: '170px',
                boxShadow: '0px 20px 80px rgba(166, 177, 190, 0.2)',
                borderRadius: '16px',
                margin: '12px 0',

                '&:first-child': {
                    marginTop: '0px',
                },
                '&:last-child': {
                    marginBottom: '0px',
                },
            },
            reconnectingContainer: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(40, 42, 43, 0.75)',
                zIndex: 1,
            },
            fullWidth: {
                gridArea: '1 / 1 / 2 / 3',
                [theme.breakpoints.down('sm')]: {
                    gridArea: '1 / 1 / 3 / 3',
                },
            },
            avatarContainer: {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#303E4E',
                overflow: 'hidden',
                borderRadius: 16,
                margin: 'auto',
                // backgroundImage: `url(${image1})`,
                backgroundSize: 'cover',
                zIndex: 1,
                '& svg': {
                    transform: 'scale(2)',
                },
            },
            cover_participant: {
                position: 'absolute',
                right: 24,
                top: 0,
                flexDirection: 'column',
                width: 'fit-content',
                height: 'calc(100vh - 80px)',
                zIndex: 2,
            },
            list_container: {
                flexDirection: 'column',
                // width: 'fit-content',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
            },

            avatar: {
                width: '40px',
                height: '40px',
                marginRight: 8,
                background: '#FFFFFF',
                border: '2px solid rgba(255, 255, 255, 0.5)',
                borderRadius: '80px',
                overflow: 'hidden',
                position: 'relative',
            },
            avatar__backward: {
                width: '40px',
                height: '40px',
                marginRight: 8,
                background: '#FFFFFF',
                border: '2px solid #00000080',
                borderRadius: '80px',
                overflow: 'hidden',
                position: 'relative',
            },
            avatar__forward: {
                position: 'absolute',
                top: 16,
                left: 48,
                width: '40px',
                height: '40px',
                marginRight: 8,
                background: '#FFFFFF',
                border: '2px solid rgba(255, 255, 255, 0.5)',
                borderRadius: '80px',
                overflow: 'hidden',
                zIndex: 2,
            },
            BtnContainer: {
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                margin: '1rem',
                position: 'absolute',
                right: '9px',
                bottom: '4px',
                zIndex: 100,
                alignItems: 'flex-end',
                // [theme.breakpoints.down('sm')]: {
                //     // margin: "auto",
                //     // marginBottom: "3rem",
                //     // position: "static",
                //     // display: "flex",
                //     // alignSelf: "center",
                //     // left:0
                //     display: 'none',
                // },
            },
            nameContainer: {
                padding: '10px',
                height: 60,
                background: '#030c25bf',
                borderRadius: 18,
                marginRight: 24,
                minWidth: 'fit-content',
                maxWidth: '300px',
                display: 'flex',
                alignItems: 'center',
            },
            name_participatn_item: {
                position: 'absolute',
                bottom: 10,
                left: 10,
                padding: '10px',
                background: '#030c25bf',
                borderRadius: 10,
                marginRight: 24,
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
            },
            textStyling: {
                fontSize: '19px',
                color: 'white',
                margin: 0,
                '&:hover': {
                    color: 'white',
                },
            },
            circularProgressWrap: {
                position: 'absolute',
                right: '20%',
                top: '5%',
                zIndex: 999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#fff',
                padding: '1em',
                borderRadius: '10px',
                boxShadow: '1px 1px 1px #ccc',

                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    right: '0',
                },
            },
            btn_translate: {
                right: '26px',
                bottom: '24px',
                position: 'absolute',
                background: '#10172a',
                display: 'flex',
                width: '50px',
                height: '50px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '16px',
                color: '#fff',
                cursor: 'pointer',
                zIndex: 99,
                [theme.breakpoints.down('sm')]: {
                    bottom: '44px',
                    right: '10px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'rgba(61, 70, 98, 0.3)',
                },
            },
            boxTranslate: {
                position: 'absolute',
                bottom: '111px',
                right: '24px',
                zIndex: '999',
                width: '350px',
                [theme.breakpoints.down('sm')]: {
                    right: 0,
                    // width: '300px',
                    maxWidth: 'calc(100% - 20px)',
                    margin: '0 10px',
                    bottom: '141px',
                },
            },
        } as any),
)

interface MainParticipantInfoProps {
    participant: Participant
    children: React.ReactNode
    openAddParticipant: Boolean
    handleOpenAddParticipant: () => void
}

export default function MainParticipantInfo({
    participant,
    children,
    openAddParticipant,
    handleOpenAddParticipant,
}: MainParticipantInfoProps) {
    const classes = useStyles()
    const { room } = useVideoContext()
    let location = useLocation()

    const { isChatWindowOpen } = useChatContext()

    const localParticipant = room!.localParticipant
    const isLocal = localParticipant === participant
    console.log({ participant })
    const screenShareParticipant = useScreenShareParticipant()
    // const isRemoteParticipantScreenSharing =
    //     screenShareParticipant &&
    //     screenShareParticipant !== localParticipant

    const publications = usePublications(participant)
    const videoPublication = publications.find((p) => p.kind.includes('video'))
    const screenSharePublication = publications.find((p) => p.trackName.includes('screen'))

    const videoTrack = useTrack(screenSharePublication || videoPublication)
    const isVideoEnabled = Boolean(videoTrack)

    const audioPublication = publications.find((p) => p.kind === 'audio')
    const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined

    const isVideoSwitchedOff = useIsTrackSwitchedOff(
        videoTrack as LocalVideoTrack | RemoteVideoTrack,
    )
    const isParticipantReconnecting = useParticipantIsReconnecting(participant)

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    // removing the random numbers from the name
    const participantIdentityWithoutNumbers = `${participant?.identity?.split('_')[0]} ${
        participant?.identity?.split('_')[1]
    }`

    //console.log(location, 'log location')

    //---------------------------------------Translation service-------------------------------
    const { triggerTranslate, setTriggerTranslate, stopTranslate } = useContext(StateContext)
    const [showTranslate, setShowTranslate] = useState(false)
    const [fromLanguage, setFromLanguage] = useState('')
    const [executedIdentifying, setExecutedIdentifying] = useState('')
    const [isExecutingTranslation, setIsExecutingTranslation] = useState(false)
    const [recognition, setRecognition] = useState<any>()
    const [isTranslating, setIsTranslating] = useState(false)
    const [isTranslation, setIsTranslation] = useState(false)

    // const identifyLanguage = () => {
    //     console.log('access identifying language')
    //     const speechConfig = SpeechSDK.SpeechTranslationConfig.fromSubscription(
    //         'token',
    //         'eastus',
    //     )
    //     speechConfig.speechRecognitionLanguage = 'en-US'
    //     const autoDetectSourceLanguageConfig =
    //         SpeechSDK.AutoDetectSourceLanguageConfig.fromLanguages([
    //             'hi-IN',
    //             'ar-BH',
    //             'es-ES',
    //             'zh-CN',
    //         ])
    //     let stream
    //     const videoEl = document.getElementById('provider_audio')
    //     if (videoEl) {
    //         stream = videoEl.captureStream()
    //         const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(stream)

    //         const recognizer = SpeechSDK.SpeechRecognizer.FromConfig(
    //             speechConfig,
    //             autoDetectSourceLanguageConfig,
    //             audioConfig,
    //         )

    //         recognizer.recognizeOnceAsync(
    //             function (result) {
    //                 console.log(result.privLanguage)
    //                 setFromLanguage(result.privLanguage)
    //                 recognizer.close()
    //                 setExecutedIdentifying(Math.random())
    //             },
    //             function (err) {
    //                 recognizer.close()
    //                 setExecutedIdentifying(Math.random())
    //             },
    //         )
    //     } else {
    //         setExecutedIdentifying(Math.random())
    //     }
    // }

    const translateLanguage = (fromLang: string, targetLang: string) => {
        setIsExecutingTranslation(true)
        if (recognition) {
            recognition.stopContinuousRecognitionAsync()
        }
        const statusDiv: any = document.getElementById('show_sub')

        function applyCommonConfigurationTo(recognizer: SpeechSDK.TranslationRecognizer) {
            recognizer.recognizing = onRecognizing
            recognizer.recognized = onRecognized
            recognizer.canceled = onCanceled
            recognizer.sessionStarted = onSessionStarted
            recognizer.sessionStopped = onSessionStopped
        }

        function onSessionStarted(sender: any, sessionEventArgs: any) {
            setIsTranslating(true)
            setIsExecutingTranslation(false)
        }

        function onSessionStopped(sender: any, sessionEventArgs: any) {
            setIsTranslating(false)
            setIsExecutingTranslation(false)
        }

        function onCanceled(sender: any, cancellationEventArgs: any) {
            setIsTranslating(false)
            setIsExecutingTranslation(false)
        }

        function onRecognizing(sender: any, recognitionEventArgs: any) {
            //   statusDiv[0].innerHTML = recognitionEventArgs.privResult.privTranslations.privMap.privValues[0];
        }

        function onRecognized(sender: any, recognitionEventArgs: { result: any }) {
            const result = recognitionEventArgs.result
            onRecognizedResult(recognitionEventArgs.result)
        }

        function onRecognizedResult(
            result: SpeechSDK.RecognitionResult | SpeechSDK.SpeechSynthesisResult | any,
        ) {
            switch (result.privReason) {
                case SpeechSDK.ResultReason.NoMatch:
                    console.log('NoMatch')
                    const noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(result)
                    console.log(noMatchDetail)
                    if (statusDiv) {
                        statusDiv.innerHTML = ''
                    }

                    break
                case SpeechSDK.ResultReason.Canceled:
                    console.log('Canceled')
                    const cancelDetails = SpeechSDK.CancellationDetails.fromResult(result)
                    console.log(cancelDetails)
                    break
                case SpeechSDK.ResultReason.RecognizedSpeech:
                case SpeechSDK.ResultReason.TranslatedSpeech:
                case SpeechSDK.ResultReason.RecognizedIntent:
                    console.log('RecognizedSpeech')
                    if (result.privTranslations) {
                        if (statusDiv) {
                            if (result.privTranslations.privMap.privValues[0] !== undefined) {
                                statusDiv.innerHTML = `<span class="text-sub">${result.privTranslations.privMap.privValues[0]}</span>`
                            }
                        }
                        console.log(result)
                    }
                    break
                default:
                    console.log('Nothing')
                    break
            }
        }

        let stream
        const videoEl: any = document.getElementById('provider_audio')
        if (videoEl) {
            stream = videoEl.captureStream()
            if (statusDiv) statusDiv.style.visibility = 'visible'
            statusDiv.innerHTML = ''

            const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(stream)
            const speechConfig = SpeechSDK.SpeechTranslationConfig.fromAuthorizationToken(
                triggerTranslate?.token ? triggerTranslate?.token : 'token',
                triggerTranslate?.region ? triggerTranslate?.region : 'region',
            )
            speechConfig.speechRecognitionLanguage = fromLang
            speechConfig.addTargetLanguage([targetLang ? targetLang : 'en-US'])

            const reco = new SpeechSDK.TranslationRecognizer(speechConfig, audioConfig)
            applyCommonConfigurationTo(reco)
            reco.synthesizing = function (s, e) {
                const audioSize = e.result.audio === undefined ? 0 : e.result.audio.byteLength
                if (e.result.audio && soundContext) {
                    const source = soundContext.createBufferSource()
                    soundContext.decodeAudioData(e.result.audio, function (newBuffer: any) {
                        source.buffer = newBuffer
                        source.connect(soundContext.destination)
                        source.start(0)
                    })
                }
            }

            reco.startContinuousRecognitionAsync()
            setRecognition(reco)
            setIsExecutingTranslation(false)
        } else {
            setIsExecutingTranslation(false)
        }
    }

    useEffect(() => {
        if (triggerTranslate?.from_lang && triggerTranslate?.target_lang) {
            translateLanguage(triggerTranslate.from_lang, triggerTranslate.target_lang)
        }
    }, [triggerTranslate])

    const stopTranslation = () => {
        if (document.getElementById('show_sub') !== undefined)
            document.getElementById('show_sub').style.visibility = 'hidden'

        recognition?.stopContinuousRecognitionAsync()
        recognition?.close()

        setIsTranslating(false)
        setIsExecutingTranslation(false)
        console.log('stop----->Translation service')
    }
    useEffect(() => {
        if (stopTranslate >= 0) {
            stopTranslation()
        }
    }, [stopTranslate])

    const openTranslation = () => {
        console.log('openTranslation')
        setIsTranslation(true)

        // identifyLanguage()
        // setOpenMenu(false)
        // setIsDisplayChatProvider(false)
        // setIsDisplayAddParticipant(false)
        // setDisplayChangeBackground(false)
    }

    const toggleTranslation = () => {
        openTranslation()
        // if (!isTranslation) {
        // } else {
        //     closeTranslation()
        // }
    }

    useEffect(() => {
        toggleTranslation()
    }, [])
    return (
        <Grid container style={{ height: '100%', padding: !isMobile && 40 }} wrap="nowrap">
            <div
                data-cy-main-participant
                // data-cy-participant={participant.identity}
                className={clsx(classes.container_main)}>
                {!isChatWindowOpen && (
                    <div className={classes.BtnContainer}>
                        {/* <NetworkQualityLevel
                        participant={localParticipant}
                    /> */}
                        {!isMobile && (
                            <div className={classes.nameContainer}>
                                {/* <div className={classes.avatar}>
                            <img src={image4} alt="alternative_video" />
                        </div> */}
                                <Typography className={classes.textStyling}>
                                    {participantIdentityWithoutNumbers}
                                    {isLocal}{' '}
                                    {location?.state?.roomMaster ===
                                    participantIdentityWithoutNumbers
                                        ? `( ${location?.state?.suffix} )`
                                        : ''}
                                    {/* Christopher */}
                                </Typography>

                                {/* <AudioLevelIndicator audioTrack={audioTrack} />  */}
                                {/* {!(
                            !upcoming ||
                            upcoming.Current == 'undefined'
                        ) ? (
                            <div>
                                {!isParticpantExist &&
                                    `Call duration ${formatTime()}`}
                            </div>
                        ) : null}
                        {Object.keys(appointmentInfo).length !==
                        0 ? (
                            <div>
                                {`Call duration ${getAppDurationInMinutes()}:00`}
                                {!isParticpantExist &&
                                    `Call duration ${formatTime()}`}
                            </div>
                        ) : null} */}
                            </div>
                        )}
                        {!isMobile && <VerticalVolumn />}
                    </div>
                )}

                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div className={classes.avatarContainer}>
                        <TeleDisableVideoUserIcon />
                    </div>
                )}
                {isParticipantReconnecting && (
                    <div className={classes.reconnectingContainer}>
                        <Typography variant="body1" style={{ color: 'white' }}>
                            Reconnecting...
                        </Typography>
                    </div>
                )}
                <div id="show_sub"></div>
                {/* {!isChatWindowOpen && (
                    <Box>
                        <Box
                            className={classes.btn_translate}
                            onClick={() => setShowTranslate(!showTranslate)}>
                            <GTranslateIcon fontSize="medium" />
                        </Box>

                        <Box
                            sx={{ display: showTranslate ? 'block' : 'none' }}
                            className={classes.boxTranslate}>
                            <TranslateServie
                                tg_fromLanguage={triggerTranslate?.from_lang}
                                tg_targetLanguage={triggerTranslate?.target_lang}
                                onClose={() => setShowTranslate(false)}
                                handleIdentifyLanguage={identifyLanguage}
                                handleStartTranslation={translateLanguage}
                                identifiedLanguage={fromLanguage}
                                executed={executedIdentifying}
                                isExecutingTranslation={isExecutingTranslation}
                                isTranslating={isTranslating}
                                stopTranslation={stopTranslation}
                            />
                        </Box>
                    </Box>
                )} */}
                {children}
            </div>

            <Grid
                container
                className={clsx({
                    [classes.right_side_container]: true,
                    [classes.right_side_container_closed]: !isChatWindowOpen,
                })}>
                <ChatWindow />
            </Grid>
            <Grid
                container
                className={clsx({
                    [classes.right_side_container]: true,
                    [classes.right_side_container_closed]: !openAddParticipant,
                })}>
                <AddParticipantForm
                    handleOpenAddParticipant={handleOpenAddParticipant}
                    onClose={handleOpenAddParticipant}
                    open={false}
                />
            </Grid>
        </Grid>
    )
}
