import { Grid } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Routes from '../../../constants/routes'
import { UserRoles } from '../../../constants/user-roles'
import LoadingPage from '../../../utilities/loading-page'
import { APPOINTMENT_TYPE } from '../constant'
import styles from '../styles'
import { getSlotTypeColor } from '../utils'

function AppointmentsModal(props) {
    const history = useHistory()

    function handleClose() {
        props.onModalClose()
    }

    function handlePatientNameClick(id) {
        history.push(Routes.PROFILE, {
            userId: id,
            role: UserRoles.PATIENT,
        })
    }

    return (
        <Modal
            aria-labelledby="Appointments"
            aria-describedby="appointment slot details"
            open={props.open}
            onClose={handleClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendarModal">
            <div className={props.classes.paper}>
                <Grid
                    container
                    direction="column"
                    className="appointmentModal">
                    <header>
                        <span>Appointments</span>
                        <CloseIcon
                            style={{
                                color: '#FFF',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        />
                    </header>
                    <main>
                        <Grid item xs={12}>
                            {props.data.isLoading ? (
                                <LoadingPage />
                            ) : props.data.data.length ===
                              0 ? (
                                <>No Appointments Found.</>
                            ) : (
                                props.data.data.map(
                                    (slot) => {
                                        return (
                                            <Grid
                                                container
                                                key={
                                                    slot
                                                        .appointment
                                                        .id
                                                }
                                                direction="row"
                                                className="details-wrap"
                                                style={{
                                                    backgroundColor:
                                                        getSlotTypeColor(
                                                            slot
                                                                .slot
                                                                .type,
                                                        ),
                                                }}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className="bar">
                                                    <Typography
                                                        noWrap
                                                        className="time">
                                                        {slot.slot.start_date?.format(
                                                            'hh:mm A',
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        noWrap
                                                        className="field1 capitalize">
                                                        <a
                                                            href="#"
                                                            onClick={(
                                                                e,
                                                            ) => {
                                                                e.preventDefault()
                                                                handlePatientNameClick(
                                                                    slot
                                                                        .appointment
                                                                        .patient
                                                                        .id,
                                                                )
                                                            }}
                                                            style={{
                                                                color: 'black',
                                                            }}>
                                                            {
                                                                slot
                                                                    .appointment
                                                                    .patient
                                                                    .name
                                                            }
                                                        </a>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {slot
                                                        .appointment
                                                        .type ===
                                                    APPOINTMENT_TYPE.TELE_HEALTH ? (
                                                        <Typography
                                                            noWrap
                                                            className="clinic-field capitalize">
                                                            Tele-health
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            noWrap
                                                            className="clinic-field capitalize">
                                                            In-clinic
                                                            -{' '}
                                                            {
                                                                slot
                                                                    .appointment
                                                                    .clinic
                                                                    .name
                                                            }
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )
                                    },
                                )
                            )}
                        </Grid>
                    </main>
                </Grid>
            </div>
        </Modal>
    )
}

AppointmentsModal.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    data: PropTypes.object,
}

export default withStyles(styles)(AppointmentsModal)
