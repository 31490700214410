import React from 'react'
import ReactDOM from 'react-dom'
const Model = ({ isShowing, hide }) =>
    isShowing
        ? ReactDOM.createPortal(
              <React.Fragment>Hi</React.Fragment>,
              document.body,
          )
        : null
export default Model
