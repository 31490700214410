import React from 'react'
import './MCTStyles.scss'
import { Typography } from '@material-ui/core'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import { useHistory } from 'react-router-dom'
import Routes from '../../constants/routes'

function Main() {
    const history = useHistory()

    return (
        <div className="message_care_team_container">
            <Typography>Coming Soon...</Typography>
            <div className="mct_back_container" onClick={() => history.push(Routes.DASHBOARD)}>
                <BackIcon />
                <Typography>Back to home</Typography>
            </div>
        </div>
    )
}

export default Main
