/* eslint-disable default-case */
import { postService } from '../../utilities/request/restClient'
import { getLoggedInUserRole } from '../../utilities'
import { config } from '../../constants'
import { UserRoles } from '../../constants/user-roles'
export const getAdminProviderList = (payload) =>
    postService(`/admin/admin-provider-search`, payload)

// get list of admin / staff
export const getStaffList = (payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    return postService(`/${userType}/staff/admin-list`, {
        limit: 10,
        ...payload,
    })
}
// add new staff
export const addStaffProfile = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    // eslint-disable-next-line default-case
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    return postService(
        `${config.dev.baseURL}/${userType}/staff/admin-add`,
        payload,
    )
}

// update staff status
export const updateStaffStatus = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    // eslint-disable-next-line default-case
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    const { userId, status } = payload
    return postService(`/${userType}/staff/admin-status`, {
        user_id: userId,
        status,
    })
}
// search staff
export const getStaffResultList = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    // eslint-disable-next-line default-case
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }

    return postService(`/${userType}/staff/admin-search`, {
        limit: 10,
        ...payload,
    })
}
