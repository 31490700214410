import React from 'react'
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'

export const useNotification = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    return (message, type, hideDuration, className) => {
        enqueueSnackbar(message, {
            variant: type || 'info',
            action: (sackbarId) => (
                <CloseIcon
                    fontSize="small"
                    style={{ cursor: 'pointer', width: 18 }}
                    onClick={() => closeSnackbar(sackbarId)}
                />
            ),
            className,
            autoHideDuration: hideDuration || 5000,
        })
    }
}
