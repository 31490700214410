import MomentUtils from '@date-io/moment'
import {
    Button,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import { ButtonSpinner } from '../../custom'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { FieldArray, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {
    maxCharLength,
    maxCharLengthZip,
} from '../../../constants'
import { errorMessages } from '../../../constants/errorMessages'
import { patientProfileFields } from '../../../constants/patientProfileForm'
import { states } from '../../../constants/states'
import { UserRoles } from '../../../constants/user-roles'
import NotificationPreferences from '../../../containers/NotificationPreferences'
import { saveNotificationPreferences } from '../../../services/Notification'
import { commonMasterData } from '../../../services/Provider/provider'
import {
    setError,
    setErrorMessage,
} from '../../../store/actions/loader'
import {
    handleEditPatientProfile,
    handleSavePatientProfile,
} from '../../../store/actions/patient'
import {
    formatPhone,
    getLoggedInUserRole,
} from '../../../utilities'
import LoadingPage from '../../../utilities/loading-page'
// import FormikOnError from './Components/FormikOnError';
import {
    charRegex,
    usNumberRegex,
} from '../../../utilities/regex'
import DependentsView from './Components/DependentsView'

const PatientProfileEdit = (props) => {
    const {
        profileDetails,
        onEditCallback,
        isCreate,
        onSaveCallback,
        preferences,
    } = props
    const dispatch = useDispatch()
    const loggedInUser = getLoggedInUserRole()
    const customPatientProfileFields =
        patientProfileFields(isCreate)

    const initialDepent = {
        patient: {
            first_name: '',
            last_name: '',
            dob: null,
            gender: null,
            relation: null,
            newEntry: 'yes',
        },
    }
    const [formFields, setFormFields] = useState(
        customPatientProfileFields,
    )
    const [secInsuranceHeight, setSecInsuranceHeight] =
        useState('')

    const loading = useSelector(
        (state) => state.loader.loading,
    )

    const errMsg = useSelector(
        (state) => state.loader.errorMsg,
    )

    const [uploadedPImages, setUploadedPImages] = useState(
        [],
    )

    const [uploadedSImages, setUploadedSImages] = useState(
        [],
    )
    const [imgArrCount, setImgArrCount] = useState('')
    const [minDateMajor, setMinDateMajor] = useState('')

    const fetchCommonMasterData = async () => {
        try {
            const res = await commonMasterData()
            const ethinicityOptions = []
            const languageOptions = []
            const raceOptions = []
            const relationOptions = []
            res.data &&
                res.data.ethinicity &&
                res.data.ethinicity.map((item) => {
                    ethinicityOptions.push({
                        name: item.label,
                        value: item.value,
                    })
                })
            res.data.language.map((item) => {
                languageOptions.push({
                    name: item.label,
                    value: item.value,
                })
            })
            res.data.race.map((item) => {
                raceOptions.push({
                    name: item.label,
                    value: item.value,
                })
            })
            res.data.relation.map((item) => {
                relationOptions.push({
                    name: item.label,
                    value: item.value,
                })
            })
            customPatientProfileFields[0].fieldItems[13].options =
                ethinicityOptions
            customPatientProfileFields[0].fieldItems[11].options =
                languageOptions
            customPatientProfileFields[0].fieldItems[12].options =
                raceOptions
            customPatientProfileFields[3].fieldItems[4].options =
                relationOptions
            setFormFields(customPatientProfileFields)
            console.log(
                'customProviderProfileFields',
                customPatientProfileFields,
            )
        } catch (err) {
            console.log(err)
        }
    }
    const initialValues = {
        loggedInUser: getLoggedInUserRole(),
        user_id: profileDetails?.user_id,
        id: profileDetails?.id,
        address_line_1: profileDetails?.address_line_1,
        address_line_2: profileDetails?.address_line_2,
        city: profileDetails?.city,
        clinic_id: profileDetails?.clinic_id,
        dob: profileDetails?.dob,
        gender: profileDetails?.gender,
        first_name: profileDetails?.first_name,
        last_name: profileDetails?.last_name,
        other_race: profileDetails?.other_race,
        other_ethinicity: profileDetails?.other_ethinicity,
        // other_race: profileDetails?.other_race === null ? undefined : profileDetails.other_race,
        // other_ethinicity: profileDetails.other_ethinicity === null ? undefined : profileDetails.other_ethinicity,
        email: profileDetails?.user?.email ?? '',
        mob: profileDetails?.user?.mob
            ? formatPhone(
                  profileDetails?.user?.mob
                      .replace(/\D/g, '')
                      .slice(-10),
              )
            : '',
        language: profileDetails?.user?.language,
        /* language:
            profileDetails?.user?.language === null
                ? undefined
                : profileDetails?.user?.language, */
        state: profileDetails?.state,
        zip: profileDetails?.zip,
        race: profileDetails?.race,
        ethinicity: profileDetails?.ethinicity,
        sec_ques_1:
            profileDetails?.security_qus_ans?.question1,
        sec_ques_2:
            profileDetails?.security_qus_ans?.question2,
        sec_ques_3:
            profileDetails?.security_qus_ans?.question3,
        sec_ans_1:
            profileDetails?.security_qus_ans?.answer1,
        sec_ans_2:
            profileDetails?.security_qus_ans?.answer2,
        sec_ans_3:
            profileDetails?.security_qus_ans?.answer3,
        dependent_first_name: '',
        dependent_last_name: '',
        dependent_dob: '',
        dependent_gender: '',
        dependent_relation: '',
        primary_insurer_first_name:
            profileDetails?.primaryInsurer?.first_name,
        primary_insurer_last_name:
            profileDetails?.primaryInsurer?.last_name,
        primary_insurer_dob:
            profileDetails?.primaryInsurer?.dob || null,
        primary_insurer_gender:
            profileDetails?.primaryInsurer?.gender,
        primary_insurer_relation:
            profileDetails?.primaryInsurer
                ?.patient_relation_to_insured,
        primary_insurance_plan_name:
            profileDetails?.insuranceDetail?.[0]
                ?.plan_name === ''
                ? undefined
                : profileDetails?.insuranceDetail?.[0]
                      ?.plan_name,
        primary_insurance_group_number:
            profileDetails?.insuranceDetail?.[0]?.group_no,
        primary_insurance_payer:
            profileDetails?.insuranceDetail?.[0]?.payer,
        primary_insurance_payer_id:
            profileDetails?.insuranceDetail?.[0]?.payer_id,
        primary_insurance_primary_member_id:
            profileDetails?.insuranceDetail?.[0]?.member_id,
        primary_insurance_image_front: '',
        primary_insurance_image_back: '',
        primary_insurance_id:
            profileDetails?.insuranceDetail?.[0]?.id,
        secondary_insurance_id:
            profileDetails?.insuranceDetail?.[1]?.id,
        secondary_insurance_plan_name:
            profileDetails?.insuranceDetail?.[1]
                ?.plan_name === ''
                ? undefined
                : profileDetails?.insuranceDetail?.[1]
                      ?.plan_name,
        secondary_insurance_group_number:
            profileDetails?.insuranceDetail?.[1]?.group_no,
        secondary_insurance_payer:
            profileDetails?.insuranceDetail?.[1]?.payer,
        secondary_insurance_payer_id:
            profileDetails?.insuranceDetail?.[1]?.payer_id,
        secondary_insurance_primary_member_id:
            profileDetails?.insuranceDetail?.[1]?.member_id,
        secondary_insurance_image_front: '',
        secondary_insurance_image_back: '',
        referring_provider_first_name:
            profileDetails?.referringProvider?.first_name,
        referring_provider_last_name:
            profileDetails?.referringProvider?.last_name,
        referring_provider_email:
            profileDetails?.referringProvider?.email,
        referring_provider_cellphone:
            profileDetails?.referringProvider?.mob &&
            formatPhone(
                profileDetails?.referringProvider?.mob
                    ?.replace(/\D/g, '')
                    .slice(-10),
            ),
        referring_provider_fax:
            profileDetails?.referringProvider?.fax &&
            formatPhone(
                profileDetails?.referringProvider?.fax
                    ?.replace(/\D/g, '')
                    .slice(-10),
            ),
        referring_provider_referral_code:
            profileDetails?.referringProvider
                ?.referral_code,
        referring_provider_id:
            profileDetails?.referringProvider?.id,
        pharmacy_name:
            profileDetails?.pharmacy?.pharmacy_name,
        pharmacy_address_line_1:
            profileDetails?.pharmacy?.address_line_1,
        pharmacy_address_line_2:
            profileDetails?.pharmacy?.address_line_2,
        pharmacy_city: profileDetails?.pharmacy?.city,
        pharmacy_state: profileDetails?.pharmacy?.state,
        pharmacy_zip: profileDetails?.pharmacy?.zip,
        pharmacy_id: profileDetails?.pharmacy?.id,
        // mob2: formatPhone(profileDetails?.mob2?.replace(/\D/g, '')
        //     .slice(-10)),
        // mob3: formatPhone(profileDetails?.mob3?.replace(/\D/g, '')
        //     .slice(-10)),
        minors: profileDetails?.minors,
        email_notification: preferences?.email ?? false,
        sms_notification: preferences?.sms ?? false,
        app_notification:
            preferences?.app_notification ?? false,
        photo: profileDetails?.photo,
    }
    if (isCreate) {
        initialValues.minors = []
        initialValues.dob = null
    }

    Yup.addMethod(
        Yup.array,
        'unique',
        function (message, mapper = (a) => a) {
            return this.test(
                'unique',
                message,
                function (list) {
                    if (list !== undefined) {
                        return (
                            list.length ===
                            new Set(list.map(mapper)).size
                        )
                    }
                },
            )
        },
    )

    const validateSchema = Yup.object().shape({
        dob: Yup.date()
            .required(errorMessages.dob)
            .nullable()
            .typeError(''),
        gender: Yup.string().required(errorMessages.gender),
        first_name: Yup.string()
            .trim()
            .required(errorMessages.firstName)
            .matches(charRegex, errorMessages.firstName),
        last_name: Yup.string()
            .trim()
            .required(errorMessages.lastName)
            .matches(charRegex, errorMessages.lastName),
        mob:
            isCreate &&
            Yup.string().required(
                errorMessages.phoneNumber,
            ),
        // .matches(
        //     usNumberRegex,
        //     errorMessages.validPhoneNumber,
        // ),
        email: Yup.string().email(
            'Please enter valid Email',
        ),
        address_line_1: Yup.string().required(
            errorMessages.address_line_1,
        ),
        city: Yup.string()
            .required(errorMessages.city)
            .matches(/^[aA-zZ\s]+$/, errorMessages.city),

        state: Yup.string()
            .required(errorMessages.state)
            .nullable(),
        zip: Yup.string().required(errorMessages.zip),
        sec_ans_1: Yup.string().when('loggedInUser', {
            is: (loggedInUser) =>
                loggedInUser === 'patient',
            then: Yup.string().required(
                errorMessages.securityAnwer,
            ),
            otherwise: Yup.string(),
        }),
        sec_ans_2: Yup.string().when('loggedInUser', {
            is: (loggedInUser) =>
                loggedInUser === 'patient',
            then: Yup.string().required(
                errorMessages.securityAnwer,
            ),
            otherwise: Yup.string(),
        }),
        sec_ans_3: Yup.string().when('loggedInUser', {
            is: (loggedInUser) =>
                loggedInUser === 'patient',
            then: Yup.string().required(
                errorMessages.securityAnwer,
            ),
            otherwise: Yup.string(),
        }),
        primary_insurance_plan_name: Yup.string().required(
            errorMessages.planName,
        ),
        primary_insurance_group_number:
            Yup.string().required(
                errorMessages.groupNumber,
            ),
        primary_insurance_payer: Yup.string().required(
            errorMessages.payer,
        ),
        primary_insurance_payer_id: Yup.string().required(
            errorMessages.payerId,
        ),
        primary_insurance_primary_member_id:
            Yup.string().required(errorMessages.memberId),

        // secondary_insurance_plan_name:
        //     Yup.string().required(errorMessages.planName),
        // secondary_insurance_group_number:
        //     Yup.string().required(errorMessages.groupNumber),
        // secondary_insurance_payer:
        //     Yup.string().required(errorMessages.payer),
        // secondary_insurance_payer_id:
        //     Yup.string().required(errorMessages.payerId),
        // secondary_insurance_primary_member_id:
        //     Yup.string().required(errorMessages.memberId),

        referring_provider_first_name: Yup.string().matches(
            /^[aA-zZ\s]+$/,
            errorMessages.firstName,
        ),
        referring_provider_last_name: Yup.string().matches(
            /^[aA-zZ\s]+$/,
            errorMessages.lastName,
        ),
        referring_provider_fax: Yup.string().matches(
            usNumberRegex,
            'Please enter valid Fax Number.',
        ),
        referring_provider_referral_code: Yup.string()
            .min('6', 'Please enter valid Referral Code.')
            .max('6', 'Please enter valid Referral Code.')
            .matches(
                /^[a-z0-9]+$/i,
                'Please enter valid Referral Code.',
            ),
        referring_provider_cellphone: Yup.string().matches(
            usNumberRegex,
            errorMessages.validPhoneNumber,
        ),
        referring_provider_email: Yup.string().email(
            'Please enter valid Email',
        ),
        primary_insurer_first_name: Yup.string()
            .trim()
            .matches(charRegex, errorMessages.firstName),
        primary_insurer_last_name: Yup.string()
            .trim()
            .matches(charRegex, errorMessages.lastName),
        // minors: Yup.array()
        //     .of(
        //         Yup.object().shape({
        //             patient: Yup.object().shape({
        //                 first_name: Yup.string()
        //                     .required(
        //                         errorMessages.firstName,
        //                     )
        //                     .matches(
        //                         /^[aA-zZ\s]+$/,
        //                         errorMessages.firstName,
        //                     ),
        //                 // .test('alphabets', 'Please enter valid first name', (value = "") => {
        //                 //     return /^[A-Za-z]+$/.test(value);
        //                 // })
        //                 last_name: Yup.string()
        //                     .required(
        //                         errorMessages.lastName,
        //                     )
        //                     .matches(
        //                         /^[aA-zZ\s]+$/,
        //                         errorMessages.lastName,
        //                     ),
        //                 // .test('alphabets', 'Please enter valid last name', (value = "") => {
        //                 //     return /^[A-Za-z]+$/.test(value);
        //                 // })
        //                 dob: Yup.date()
        //                     .required(errorMessages.dob)
        //                     .nullable()
        //                     .typeError(''),
        //                 // dob: Yup.date().required(
        //                 //     errorMessages.dob,
        //                 // ),
        //                 gender: Yup.string()
        //                     .required(errorMessages.gender)
        //                     .nullable(),
        //                 relation: Yup.string()
        //                     .required(
        //                         errorMessages.relation,
        //                     )
        //                     .nullable(),
        //             }),
        //         }),
        //     )
        //     .unique(
        //         'Duplicate First Name',
        //         (a) => a.patient.first_name,
        //     ),
    })

    async function savePreferences(values) {
        await saveNotificationPreferences({
            user_id: profileDetails?.user?.id,
            email: values.email_notification,
            sms: values.sms_notification,
            app_notification: values.app_notification,
        })
    }

    const saveProfile = async (val) => {
        const elmnt = document.getElementById(
            'primaryInsurance',
        ).offsetHeight
        setSecInsuranceHeight(elmnt)
        const formatMinorArr = []
        val?.minors?.map((item) => {
            formatMinorArr.push({
                first_name: item.patient.first_name,
                last_name: item.patient.last_name,
                dob: item.patient.dob,
                gender: item.patient.gender,
                relation: item.patient.relation,
                newEntry: item.patient.newEntry,
                id: item.patient.id,
            })
        })
        formatMinorArr.map((item) => {
            if (item.newEntry === 'yes') {
                delete item['id']
                delete item['newEntry']
                return item
            }
            delete item['newEntry']
            return item
        })
        const createPayload = {
            first_name: val.first_name,
            last_name: val.last_name,
            dob: val.dob,
            phone_number: val.mob,
            // ? process.env.REACT_APP_COUNTRY_CODE +
            //   val.mob.replace(/\D/g, '').slice(-10)
            // : '',
            email: val.email,
            gender: val.gender,
            photo: val.photo,
            zip: val.zip,
            address1: val.address_line_1,
            address2: val.address_line_2,
            city: val.city,
            state: val.state,
            marital_status: '',
            language: val.language,
            race: val.race,
            ethinicity: val.ethinicity,
            security_qus_ans: {},
            other_race: val.other_race
                ? val.other_race
                : '',
            other_ethinicity: val.other_ethinicity
                ? val.other_ethinicity
                : '',
            insuranceDetail: [
                {
                    plan_name:
                        val?.primary_insurance_plan_name,
                    group_no:
                        val?.primary_insurance_group_number,
                    payer: val?.primary_insurance_payer,
                    payer_id:
                        val?.primary_insurance_payer_id,
                    member_id:
                        val?.primary_insurance_primary_member_id,
                    type: 'primary',
                    photo_front: '4238',
                    photo_back: '231',
                    id: val?.primary_insurance_id,
                },
                {
                    plan_name:
                        val?.secondary_insurance_plan_name,
                    group_no:
                        val?.secondary_insurance_group_number,
                    payer: val?.secondary_insurance_payer,
                    payer_id:
                        val?.secondary_insurance_payer_id,
                    member_id:
                        val?.secondary_insurance_primary_member_id,
                    type: 'secondary',
                    photo_front: '4238',
                    photo_back: '231',
                    id: val?.secondary_insurance_id,
                },
            ],
            primaryInsurer: {
                // dob: val.primary_insurer_dob !== null && val.primary_insurer_dob,
                first_name: val.primary_insurer_first_name,
                gender: val.primary_insurer_gender,
                last_name: val.primary_insurer_last_name,
                patient_relation_to_insured:
                    val.primary_insurer_relation,
                id: profileDetails?.primaryInsurer?.id,
            },
            referringProvider: {
                first_name:
                    val?.referring_provider_first_name,
                last_name:
                    val?.referring_provider_last_name,
                referral_code:
                    val?.referring_provider_referral_code,
                email: val?.referring_provider_email,
                mob:
                    val?.referring_provider_cellphone &&
                    process.env.REACT_APP_COUNTRY_CODE +
                        val?.referring_provider_cellphone
                            ?.replace(/\D/g, '')
                            .slice(-10),
                fax:
                    val?.referring_provider_fax &&
                    process.env.REACT_APP_COUNTRY_CODE +
                        val?.referring_provider_fax
                            ?.replace(/\D/g, '')
                            .slice(-10),
                id: val?.referring_provider_id,
            },
            pharmacy: {
                pharmacy_name: val?.pharmacy_name,
                address_line_1:
                    val?.pharmacy_address_line_1,
                address_line_2:
                    val?.pharmacy_address_line_2,
                city: val?.pharmacy_city,
                state: val?.pharmacy_state,
                zip: val?.pharmacy_zip,
                id: val?.pharmacy_id,
            },

            minors: formatMinorArr,
        }
        console.log(val, 'val')
        const payload = {
            user_id: val.user_id,
            id: val.id,
            language: val.language,
            patient: {
                first_name: val.first_name,
                last_name: val.last_name,
                dob: val.dob,
                gender: val.gender,
                photo: val.photo,
                zip: val.zip,
                address_line_1: val.address_line_1,
                address_line_2: val.address_line_2,
                city: val.city,
                state: val.state,
                marital_status: '',
                security_qus_ans: {
                    answer1: val.sec_ans_1,
                    answer2: val.sec_ans_2,
                    answer3: val.sec_ans_3,
                    question1: val.sec_ques_1,
                    question2: val.sec_ques_2,
                    question3: val.sec_ques_3,
                },
                clinic_id: val.clinic_id,
                race: val.race,
                ethinicity: val.ethinicity,
                other_race: val.other_race
                    ? val.other_race
                    : '',
                other_ethinicity: val.other_ethinicity
                    ? val.other_ethinicity
                    : '',
            },
            insuranceDetail: [
                {
                    plan_name:
                        val?.primary_insurance_plan_name,
                    group_no:
                        val?.primary_insurance_group_number,
                    payer: val?.primary_insurance_payer,
                    payer_id:
                        val?.primary_insurance_payer_id,
                    member_id:
                        val?.primary_insurance_primary_member_id,
                    type: 'primary',
                    photo_front: '4238',
                    photo_back: '231',
                    id: val?.primary_insurance_id,
                },
                {
                    plan_name:
                        val?.secondary_insurance_plan_name,
                    group_no:
                        val?.secondary_insurance_group_number,
                    payer: val?.secondary_insurance_payer,
                    payer_id:
                        val?.secondary_insurance_payer_id,
                    member_id:
                        val?.secondary_insurance_primary_member_id,
                    type: 'secondary',
                    photo_front: '4238',
                    photo_back: '231',
                    id: val?.secondary_insurance_id,
                },
            ],
            primaryInsurer: {
                // dob: val.primary_insurer_dob !== null && val.primary_insurer_dob,
                first_name: val.primary_insurer_first_name,
                gender: val.primary_insurer_gender,
                last_name: val.primary_insurer_last_name,
                patient_relation_to_insured:
                    val.primary_insurer_relation,
                id: profileDetails?.primaryInsurer?.id,
            },
            referringProvider: {
                first_name:
                    val?.referring_provider_first_name,
                last_name:
                    val?.referring_provider_last_name,
                referral_code:
                    val?.referring_provider_referral_code,
                email: val?.referring_provider_email,
                mob:
                    val?.referring_provider_cellphone &&
                    process.env.REACT_APP_COUNTRY_CODE +
                        val?.referring_provider_cellphone
                            ?.replace(/\D/g, '')
                            .slice(-10),
                fax:
                    val?.referring_provider_fax &&
                    process.env.REACT_APP_COUNTRY_CODE +
                        val?.referring_provider_fax
                            ?.replace(/\D/g, '')
                            .slice(-10),
                id: val?.referring_provider_id,
            },
            pharmacy: {
                pharmacy_name: val?.pharmacy_name,
                address_line_1:
                    val?.pharmacy_address_line_1,
                address_line_2:
                    val?.pharmacy_address_line_2,
                city: val?.pharmacy_city,
                state: val?.pharmacy_state,
                zip: val?.pharmacy_zip,
                id: val?.pharmacy_id,
            },

            minors: formatMinorArr,
        }
        if (
            initialValues.primary_insurer_dob !==
            val.primary_insurer_dob
        ) {
            createPayload.primaryInsurer = {
                ...createPayload.primaryInsurer,
                dob: val.primary_insurer_dob,
            }
            payload.primaryInsurer = {
                ...payload.primaryInsurer,
                dob: val.primary_insurer_dob,
            }
        }

        if (initialValues.email !== val.email) {
            payload.user = {
                email: val.email,
            }
        }
        if (initialValues.mob !== val.mob) {
            payload.user = {
                ...payload.user,
                phone_number:
                    process.env.REACT_APP_COUNTRY_CODE +
                    val?.mob?.replace(/\D/g, '').slice(-10),
            }
        }

        const actionMessage = isCreate
            ? 'Profile Created Successfully'
            : 'Profile Updated Successfully'
        if (isCreate) {
            await dispatch(
                handleSavePatientProfile(
                    createPayload,
                    (res) => {
                        const { isUserExist } = res.data

                        const patientCreationResponse =
                            isUserExist
                                ? 'User Already Existed'
                                : actionMessage

                        Swal.fire(
                            '',
                            patientCreationResponse,
                        ).then((result) => {
                            onSaveCallback()
                        })
                    },
                ),
            )
        } else {
            await dispatch(
                handleEditPatientProfile(payload, () => {
                    Swal.fire('', actionMessage).then(
                        (result) => {
                            onEditCallback()
                        },
                    )
                }),
            )
        }
    }

    const handlePhotoUpload = (e, name) => {
        const { files } = e.target
        if (
            uploadedPImages.length < 3 ||
            uploadedSImages.length < 3
        ) {
            if (name === 'primary_insurance_image_front') {
                setImgArrCount(4)
                setUploadedPImages([
                    ...uploadedPImages,
                    files[0].name,
                ])
            } else {
                setImgArrCount(5)
                setUploadedSImages([
                    ...uploadedSImages,
                    files[0].name,
                ])
            }
        }
    }
    const getUploadedImageArray = (i) => {
        const imageArr =
            i === 4 ? uploadedPImages : uploadedSImages
        const list = [...formFields]
        list[i]['fieldItems'][5]['images'] = imageArr
        setFormFields(list)
    }
    useEffect(() => {
        if (
            uploadedSImages.length > 0 ||
            uploadedPImages.length > 0
        ) {
            getUploadedImageArray(imgArrCount)
        }
    }, [uploadedSImages, uploadedPImages])

    useEffect(() => {
        const min_date = moment().subtract(18, 'years')
        const formattedDate = new Date(min_date.toDate())
        setMinDateMajor(formattedDate)
        fetchCommonMasterData()
        const list = [...formFields]
        const ethinicityIndex = list[0][
            'fieldItems'
        ].findIndex((x) => x.name === 'other_ethinicity')
        const raceIndex = list[0]['fieldItems'].findIndex(
            (x) => x.name === 'other_race',
        )
        if (profileDetails.other_race) {
            list[0]['fieldItems'][raceIndex]['view'] = true
        } else {
            list[0]['fieldItems'][raceIndex]['view'] = false
        }
        if (profileDetails.other_ethinicity) {
            list[0]['fieldItems'][ethinicityIndex][
                'view'
            ] = true
        } else {
            list[0]['fieldItems'][ethinicityIndex][
                'view'
            ] = false
        }
        setFormFields(list)

        /*  let elmnt = document.getElementById(
            'primaryInsurance',
        ).offsetHeight;
        setSecInsuranceHeight(elmnt); */
    }, [])

    useEffect(() => {
        if (errMsg) {
            Swal.fire('', 'User Already Existed', 'error')
        }
        dispatch(setError(false))
        dispatch(setErrorMessage(''))
    }, [errMsg])

    return (
        <>
            <Grid container className="mt1">
                <Formik
                    validateOnMount={true}
                    initialValues={initialValues}
                    onSubmit={async (
                        values,
                        { setSubmitting },
                    ) => {
                        await saveProfile({
                            ...values,
                            id: initialValues.id,
                        })
                        if (
                            loggedInUser ===
                                UserRoles.PATIENT ||
                            loggedInUser ===
                                UserRoles.TM2U_SUPER_ADMIN
                        ) {
                            await savePreferences(values)
                        }
                        setSubmitting(false)
                    }}
                    validationSchema={validateSchema}>
                    {({
                        isSubmitting,
                        dirty,
                        errors,
                        touched,
                        isValid,
                        values,
                        setTouched,
                        setFieldValue,
                        setFieldError,
                        handleBlur,
                    }) => {
                        const elmnt =
                            document.getElementById(
                                'primaryInsurance',
                            ) &&
                            document.getElementById(
                                'primaryInsurance',
                            ).offsetHeight
                        setSecInsuranceHeight(elmnt)
                        return (
                            <Form className="profile-edit-main-wrap patientEditWrapper">
                                {formFields.map(
                                    (val, index) => {
                                        return !val.hide ? (
                                            <React.Fragment
                                                key={index}>
                                                <Grid
                                                    item
                                                    xs={
                                                        val.sectionTitle ===
                                                            'Primary Insurance' ||
                                                        val.sectionTitle ===
                                                            'Secondary Insurance'
                                                            ? 6
                                                            : 12
                                                    }
                                                    className={
                                                        val.sView
                                                            ? 'dFlexWrap borderBottom pb3'
                                                            : 'borderBottom pb3'
                                                    }
                                                    id={
                                                        val.sectionTitle ===
                                                            'Primary Insurance' &&
                                                        'primaryInsurance'
                                                    }
                                                    style={{
                                                        float:
                                                            val.sectionTitle ===
                                                                'Primary Insurance' ||
                                                            val.sectionTitle ===
                                                                'Dependants' ||
                                                            val.sectionTitle ===
                                                                'Primary Insurer'
                                                                ? 'left'
                                                                : val.sectionTitle ===
                                                                      'Secondary Insurance' &&
                                                                  'right',
                                                        position:
                                                            val.sectionTitle ===
                                                                'Dependants' &&
                                                            'relative',
                                                        width:
                                                            (val.sectionTitle ===
                                                                'Primary Insurance' &&
                                                                '50%') ||
                                                            (val.sectionTitle ===
                                                                'Secondary Insurance' &&
                                                                '50%') ||
                                                            (val.sectionTitle ===
                                                                'Dependants' &&
                                                                '100%') ||
                                                            (val.sectionTitle ===
                                                                'Primary Insurer' &&
                                                                '100%'),
                                                        marginBottom:
                                                            val.sectionTitle ===
                                                                'Primary insurance' &&
                                                            '3em',
                                                        paddingLeft:
                                                            val.sectionTitle ===
                                                                'Secondary Insurance' &&
                                                            '5%',
                                                        height:
                                                            val.sectionTitle ===
                                                                'Secondary Insurance' &&
                                                            secInsuranceHeight,
                                                    }}>
                                                    {val.sectionTitle && (
                                                        <Grid
                                                            item
                                                            xs={
                                                                12
                                                            }>
                                                            <Grid
                                                                item
                                                                xs={
                                                                    val.sectionTitle ===
                                                                        'Primary Insurance' ||
                                                                    val.sectionTitle ===
                                                                        'Secondary Insurance'
                                                                        ? 12
                                                                        : 6
                                                                }>
                                                                <Typography
                                                                    className="card-title "
                                                                    style={{
                                                                        width: '50%',
                                                                        marginLeft:
                                                                            '-8px',
                                                                        margin: '3em 0 1em 0',
                                                                    }}>
                                                                    {
                                                                        val.sectionTitle
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {val.fieldItems.map(
                                                        (
                                                            item,
                                                            index,
                                                        ) => (
                                                            <>
                                                                {/* for dependents */}
                                                                {val.sectionTitle ===
                                                                    'Dependants' && (
                                                                    <>
                                                                        <FieldArray name="minors">
                                                                            {(
                                                                                fieldArrayProps,
                                                                            ) => {
                                                                                const {
                                                                                    push,
                                                                                    form,
                                                                                } =
                                                                                    fieldArrayProps
                                                                                const {
                                                                                    values: {
                                                                                        minors,
                                                                                    },
                                                                                } =
                                                                                    form

                                                                                return (
                                                                                    <>
                                                                                        {minors !==
                                                                                            null &&
                                                                                            minors !==
                                                                                                undefined &&
                                                                                            minors.length ===
                                                                                                0 && (
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    className="primaryBtn"
                                                                                                    onClick={() =>
                                                                                                        push(
                                                                                                            initialDepent,
                                                                                                        )
                                                                                                    }>
                                                                                                    Add
                                                                                                    Dependent
                                                                                                </Button>
                                                                                            )}
                                                                                        {minors !==
                                                                                            null &&
                                                                                            minors !==
                                                                                                undefined &&
                                                                                            minors.map(
                                                                                                (
                                                                                                    val,
                                                                                                    i,
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <DependentsView
                                                                                                                allValues={
                                                                                                                    minors
                                                                                                                }
                                                                                                                minor={
                                                                                                                    val
                                                                                                                }
                                                                                                                index={
                                                                                                                    i
                                                                                                                }
                                                                                                                fieldArrayProps={
                                                                                                                    fieldArrayProps
                                                                                                                }
                                                                                                                setFieldValue={
                                                                                                                    setFieldValue
                                                                                                                }
                                                                                                                initialDepent={
                                                                                                                    initialDepent
                                                                                                                }
                                                                                                            />
                                                                                                        </>
                                                                                                    )
                                                                                                },
                                                                                            )}
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </FieldArray>
                                                                    </>
                                                                )}
                                                                {!Array.isArray(
                                                                    item,
                                                                ) &&
                                                                    item.inputType ===
                                                                        'textField' &&
                                                                    item.view && (
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                val.sectionTitle ===
                                                                                    'Primary Insurance' ||
                                                                                val.sectionTitle ===
                                                                                    'Secondary Insurance'
                                                                                    ? 12
                                                                                    : 6
                                                                            }>
                                                                            <Grid
                                                                                item
                                                                                lg={
                                                                                    5
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="ba-input-label-profile">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required &&
                                                                                        (val.sectionTitle ===
                                                                                        'Security Questions'
                                                                                            ? !item.readOnly
                                                                                            : true) && (
                                                                                            <span className="astrik">
                                                                                                *
                                                                                            </span>
                                                                                        )}
                                                                                </FormLabel>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                md={
                                                                                    7
                                                                                }
                                                                                xs={
                                                                                    7
                                                                                }>
                                                                                <TextField
                                                                                    autoComplete="off"
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    type={
                                                                                        item.type
                                                                                    }
                                                                                    onChange={async (
                                                                                        e,
                                                                                    ) => {
                                                                                        if (
                                                                                            item.name ===
                                                                                                'mob' ||
                                                                                            item.name ===
                                                                                                'referring_provider_cellphone' ||
                                                                                            item.name ===
                                                                                                'referring_provider_fax'
                                                                                        ) {
                                                                                            // add value masking
                                                                                            const maskedValue =
                                                                                                await formatPhone(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                                )

                                                                                            setFieldValue(
                                                                                                e
                                                                                                    .target
                                                                                                    .name,
                                                                                                maskedValue,
                                                                                            )
                                                                                        } else {
                                                                                            if (
                                                                                                item.label ===
                                                                                                'Zip Code'
                                                                                            ) {
                                                                                                e.target.value =
                                                                                                    Math.max(
                                                                                                        0,
                                                                                                        parseInt(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                        ),
                                                                                                    )
                                                                                                        .toString()
                                                                                                        .slice(
                                                                                                            0,
                                                                                                            5,
                                                                                                        )
                                                                                            }
                                                                                            await setFieldValue(
                                                                                                e
                                                                                                    .target
                                                                                                    .name,
                                                                                                e
                                                                                                    .target
                                                                                                    .value,
                                                                                            )

                                                                                            const elmnt =
                                                                                                document.getElementById(
                                                                                                    'primaryInsurance',
                                                                                                ).offsetHeight
                                                                                            setSecInsuranceHeight(
                                                                                                elmnt,
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                    style={{
                                                                                        marginTop:
                                                                                            '0.5em',
                                                                                        marginBottom:
                                                                                            '0.5em',
                                                                                    }}
                                                                                    fullWidth
                                                                                    disabled={
                                                                                        item.readOnly
                                                                                    }
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    variant="outlined"
                                                                                    placeholder={
                                                                                        item.placeHolder
                                                                                    }
                                                                                    value={
                                                                                        values[
                                                                                            item
                                                                                                .name
                                                                                        ]
                                                                                    }
                                                                                    inputProps={{
                                                                                        maxLength:
                                                                                            item.label ===
                                                                                            'Zip Code'
                                                                                                ? maxCharLengthZip
                                                                                                : item.name ===
                                                                                                      'mob' ||
                                                                                                  item.name ===
                                                                                                      'referring_provider_cellphone' ||
                                                                                                  item.name ===
                                                                                                      'referring_provider_fax'
                                                                                                ? 14
                                                                                                : item.name ===
                                                                                                  'referring_provider_referral_code'
                                                                                                ? 6
                                                                                                : maxCharLength,
                                                                                        min: 0,
                                                                                    }}
                                                                                    onBlur={
                                                                                        handleBlur
                                                                                    }
                                                                                />
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                {item.inputType ===
                                                                    'dateField' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                val.sectionTitle ===
                                                                                    'Primary Insurance' ||
                                                                                val.sectionTitle ===
                                                                                    'Secondary Insurance'
                                                                                    ? 12
                                                                                    : 6
                                                                            }>
                                                                            <Grid
                                                                                item
                                                                                lg={
                                                                                    5
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="ba-input-label-profile">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </FormLabel>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    7
                                                                                }
                                                                                xs={
                                                                                    7
                                                                                }>
                                                                                <MuiPickersUtilsProvider
                                                                                    utils={
                                                                                        MomentUtils
                                                                                    }>
                                                                                    <KeyboardDatePicker
                                                                                        id={
                                                                                            item.id
                                                                                        }
                                                                                        // disableToolbar
                                                                                        className="ba-input-fields ba-date-input"
                                                                                        disabled={
                                                                                            item.readOnly
                                                                                        }
                                                                                        inputVariant="outlined"
                                                                                        variant="inline"
                                                                                        format="MM/DD/YYYY"
                                                                                        margin="normal"
                                                                                        maxDate={
                                                                                            val.sectionTitle ===
                                                                                            'Primary Insurer'
                                                                                                ? minDateMajor
                                                                                                : item.required &&
                                                                                                  minDateMajor
                                                                                        }
                                                                                        name={
                                                                                            item.name
                                                                                        }
                                                                                        autoOk={
                                                                                            true
                                                                                        }
                                                                                        placeholder={
                                                                                            'MM/DD/YYYY'
                                                                                        }
                                                                                        value={
                                                                                            values[
                                                                                                item
                                                                                                    .name
                                                                                            ]
                                                                                        }
                                                                                        onChange={(
                                                                                            date,
                                                                                        ) => {
                                                                                            if (
                                                                                                date
                                                                                            ) {
                                                                                                setFieldValue(
                                                                                                    item.name,
                                                                                                    moment(
                                                                                                        date,
                                                                                                    )
                                                                                                        .format()
                                                                                                        .split(
                                                                                                            'T',
                                                                                                        )[0],
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                        onBlur={
                                                                                            handleBlur
                                                                                        }
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}

                                                                {item.inputType ===
                                                                    'dropDown' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                val.sectionTitle ===
                                                                                    'Primary Insurance' ||
                                                                                val.sectionTitle ===
                                                                                    'Secondary Insurance'
                                                                                    ? 12
                                                                                    : 6
                                                                            }>
                                                                            <Grid
                                                                                item
                                                                                lg={
                                                                                    5
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="ba-input-label-profile">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </FormLabel>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                md={
                                                                                    7
                                                                                }
                                                                                xs={
                                                                                    7
                                                                                }
                                                                                style={{
                                                                                    marginTop:
                                                                                        '0.5em',
                                                                                    marginBottom:
                                                                                        '0.5em',
                                                                                }}>
                                                                                <Select
                                                                                    displayEmpty
                                                                                    disabled={
                                                                                        item.readOnly
                                                                                    }
                                                                                    name={
                                                                                        item.name
                                                                                    }
                                                                                    onChange={async (
                                                                                        e,
                                                                                    ) => {
                                                                                        if (
                                                                                            e
                                                                                                .target
                                                                                                .value ===
                                                                                                'other_race' &&
                                                                                            item.label ===
                                                                                                'Race'
                                                                                        ) {
                                                                                            const list =
                                                                                                [
                                                                                                    ...formFields,
                                                                                                ]
                                                                                            const raceIndex =
                                                                                                list[0][
                                                                                                    'fieldItems'
                                                                                                ].findIndex(
                                                                                                    (
                                                                                                        x,
                                                                                                    ) =>
                                                                                                        x.name ===
                                                                                                        'other_race',
                                                                                                )
                                                                                            list[0][
                                                                                                'fieldItems'
                                                                                            ][
                                                                                                raceIndex
                                                                                            ][
                                                                                                'view'
                                                                                            ] = true
                                                                                            setFormFields(
                                                                                                list,
                                                                                            )
                                                                                        }
                                                                                        if (
                                                                                            e
                                                                                                .target
                                                                                                .value !==
                                                                                                'other_race' &&
                                                                                            item.label ===
                                                                                                'Race'
                                                                                        ) {
                                                                                            const list =
                                                                                                [
                                                                                                    ...formFields,
                                                                                                ]
                                                                                            const raceIndex =
                                                                                                list[0][
                                                                                                    'fieldItems'
                                                                                                ].findIndex(
                                                                                                    (
                                                                                                        x,
                                                                                                    ) =>
                                                                                                        x.name ===
                                                                                                        'other_race',
                                                                                                )
                                                                                            list[0][
                                                                                                'fieldItems'
                                                                                            ][
                                                                                                raceIndex
                                                                                            ][
                                                                                                'view'
                                                                                            ] = false
                                                                                            values[
                                                                                                'other_race'
                                                                                            ] =
                                                                                                ''
                                                                                            setFormFields(
                                                                                                list,
                                                                                            )
                                                                                        }
                                                                                        if (
                                                                                            e
                                                                                                .target
                                                                                                .value ===
                                                                                                'other_ethinicity' &&
                                                                                            item.label ===
                                                                                                'Ethinicity'
                                                                                        ) {
                                                                                            const list =
                                                                                                [
                                                                                                    ...formFields,
                                                                                                ]
                                                                                            const raceIndex =
                                                                                                list[0][
                                                                                                    'fieldItems'
                                                                                                ].findIndex(
                                                                                                    (
                                                                                                        x,
                                                                                                    ) =>
                                                                                                        x.name ===
                                                                                                        'other_ethinicity',
                                                                                                )
                                                                                            list[0][
                                                                                                'fieldItems'
                                                                                            ][
                                                                                                raceIndex
                                                                                            ][
                                                                                                'view'
                                                                                            ] = true
                                                                                            setFormFields(
                                                                                                list,
                                                                                            )
                                                                                        }
                                                                                        if (
                                                                                            e
                                                                                                .target
                                                                                                .value !==
                                                                                                'other_ethinicity' &&
                                                                                            item.label ===
                                                                                                'Ethinicity'
                                                                                        ) {
                                                                                            const list =
                                                                                                [
                                                                                                    ...formFields,
                                                                                                ]
                                                                                            const ethinicityIndex =
                                                                                                list[0][
                                                                                                    'fieldItems'
                                                                                                ].findIndex(
                                                                                                    (
                                                                                                        x,
                                                                                                    ) =>
                                                                                                        x.name ===
                                                                                                        'other_ethinicity',
                                                                                                )
                                                                                            list[0][
                                                                                                'fieldItems'
                                                                                            ][
                                                                                                ethinicityIndex
                                                                                            ][
                                                                                                'view'
                                                                                            ] = false
                                                                                            values[
                                                                                                'other_ethinicity'
                                                                                            ] =
                                                                                                ''

                                                                                            setFormFields(
                                                                                                list,
                                                                                            )
                                                                                        }

                                                                                        await setFieldValue(
                                                                                            e
                                                                                                .target
                                                                                                .name,
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                        )

                                                                                        const elmnt =
                                                                                            document.getElementById(
                                                                                                'primaryInsurance',
                                                                                            ).offsetHeight
                                                                                        setSecInsuranceHeight(
                                                                                            elmnt,
                                                                                        )
                                                                                    }}
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    className="ba-input-fields"
                                                                                    variant="outlined"
                                                                                    disableUnderline
                                                                                    placeholder={
                                                                                        item.placeHolder
                                                                                    }
                                                                                    defaultValue="none"
                                                                                    value={
                                                                                        values[
                                                                                            item
                                                                                                .name
                                                                                        ]
                                                                                            ? values[
                                                                                                  item
                                                                                                      .name
                                                                                              ]
                                                                                            : 'none'
                                                                                    }
                                                                                    IconComponent={
                                                                                        ExpandMoreIcon
                                                                                    }>
                                                                                    <MenuItem
                                                                                        className="pl-select-option"
                                                                                        disabled
                                                                                        value="none">
                                                                                        <span className="opacity05">
                                                                                            {
                                                                                                item.placeHolder
                                                                                            }
                                                                                        </span>
                                                                                    </MenuItem>
                                                                                    {item.options.map(
                                                                                        (
                                                                                            el,
                                                                                        ) => {
                                                                                            if (
                                                                                                item.name ===
                                                                                                'sec_ques_1'
                                                                                            ) {
                                                                                                if (
                                                                                                    el.value ===
                                                                                                        values.sec_ques_2 ||
                                                                                                    el.value ===
                                                                                                        values.sec_ques_3
                                                                                                )
                                                                                                    return
                                                                                            }
                                                                                            if (
                                                                                                item.name ===
                                                                                                'sec_ques_2'
                                                                                            ) {
                                                                                                if (
                                                                                                    el.value ===
                                                                                                        values.sec_ques_1 ||
                                                                                                    el.value ===
                                                                                                        values.sec_ques_3
                                                                                                )
                                                                                                    return
                                                                                            }
                                                                                            if (
                                                                                                item.name ===
                                                                                                'sec_ques_3'
                                                                                            ) {
                                                                                                if (
                                                                                                    el.value ===
                                                                                                        values.sec_ques_1 ||
                                                                                                    el.value ===
                                                                                                        values.sec_ques_2
                                                                                                )
                                                                                                    return
                                                                                            }
                                                                                            return (
                                                                                                <MenuItem
                                                                                                    className="pl-select-option"
                                                                                                    value={
                                                                                                        el.value
                                                                                                    }
                                                                                                    key={
                                                                                                        el.value
                                                                                                    }>
                                                                                                    {
                                                                                                        el.name
                                                                                                    }
                                                                                                </MenuItem>
                                                                                            )
                                                                                        },
                                                                                    )}
                                                                                </Select>
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                                {item.inputType ===
                                                                    'autoComplete' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                val.sectionTitle ===
                                                                                    'Primary Insurance' ||
                                                                                val.sectionTitle ===
                                                                                    'Secondary Insurance'
                                                                                    ? 12
                                                                                    : 6
                                                                            }>
                                                                            <Grid
                                                                                item
                                                                                lg={
                                                                                    5
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <FormLabel className="ba-input-label-profile">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                    {item.required && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                </FormLabel>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    7
                                                                                }
                                                                                style={{
                                                                                    marginTop:
                                                                                        '0.5em',
                                                                                    marginBottom:
                                                                                        '0.5em',
                                                                                }}>
                                                                                <Autocomplete
                                                                                    ListboxProps={{
                                                                                        style: {
                                                                                            maxHeight:
                                                                                                '15rem',
                                                                                        },
                                                                                        position:
                                                                                            'bottom-start',
                                                                                    }}
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    value={
                                                                                        values[
                                                                                            item
                                                                                                .name
                                                                                        ]
                                                                                    }
                                                                                    options={
                                                                                        states
                                                                                    }
                                                                                    getOptionLabel={(
                                                                                        states,
                                                                                    ) =>
                                                                                        states
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                        value,
                                                                                    ) => {
                                                                                        setFieldValue(
                                                                                            item.name,
                                                                                            value,
                                                                                        )
                                                                                    }}
                                                                                    popupIcon={
                                                                                        <ExpandMoreIcon />
                                                                                    }
                                                                                    renderInput={(
                                                                                        params,
                                                                                    ) => (
                                                                                        <TextField
                                                                                            name="Textname"
                                                                                            {...params}
                                                                                            placeholder="Enter your State"
                                                                                            variant="outlined"
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {errors[
                                                                                    item
                                                                                        .name
                                                                                ] &&
                                                                                    touched[
                                                                                        item
                                                                                            .name
                                                                                    ] && (
                                                                                        <FormHelperText
                                                                                            error>
                                                                                            {
                                                                                                errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        </FormHelperText>
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                                {item.inputType ===
                                                                    'file_upload' && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    4
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                lg={
                                                                                    5
                                                                                }></Grid>
                                                                            <Grid
                                                                                item
                                                                                md={
                                                                                    7
                                                                                }
                                                                                xs={
                                                                                    7
                                                                                }>
                                                                                <div className="ba-choose-img-btn_upload">
                                                                                    <label
                                                                                        htmlFor={
                                                                                            item.id
                                                                                        }>
                                                                                        {
                                                                                            item.placeHolder
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                                <input
                                                                                    hidden
                                                                                    type="file"
                                                                                    multiple
                                                                                    id={
                                                                                        item.id
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) => {
                                                                                        handlePhotoUpload(
                                                                                            e,
                                                                                            item.name,
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            style={{
                                                                                height: '30px',
                                                                                display:
                                                                                    'flex',
                                                                            }}>
                                                                            <Grid
                                                                                item
                                                                                lg={
                                                                                    5
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }></Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    7
                                                                                }
                                                                                md={
                                                                                    7
                                                                                }>
                                                                                {Array.isArray(
                                                                                    item?.images,
                                                                                ) &&
                                                                                    item?.images.map(
                                                                                        (
                                                                                            v,
                                                                                            i,
                                                                                        ) => (
                                                                                            <Grid
                                                                                                key={`${i}-image-title`}
                                                                                                item
                                                                                                xs={
                                                                                                    12
                                                                                                }>
                                                                                                <AttachFileIcon className="attachfile-icon" />
                                                                                                <span className="img-title">
                                                                                                    {
                                                                                                        v
                                                                                                    }
                                                                                                </span>
                                                                                                <DeleteOutlineIcon className="delete-icon" />
                                                                                            </Grid>
                                                                                        ),
                                                                                    )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </>
                                                        ),
                                                    )}

                                                    {/*{(loggedInUser ===*/}
                                                    {/*    UserRoles.PATIENT ||*/}
                                                    {/*    loggedInUser ===*/}
                                                    {/*        UserRoles.TM2U_SUPER_ADMIN) &&*/}
                                                    {/*    val.sectionTitle ===*/}
                                                    {/*        'Notifications' && (*/}
                                                    {/*        <Grid*/}
                                                    {/*            container*/}
                                                    {/*            spacing={*/}
                                                    {/*                2*/}
                                                    {/*            }>*/}
                                                    {/*            <Grid*/}
                                                    {/*                container*/}
                                                    {/*                item>*/}
                                                    {/*                {val.fieldItems.map(*/}
                                                    {/*                    (*/}
                                                    {/*                        item,*/}
                                                    {/*                        index,*/}
                                                    {/*                    ) => {*/}
                                                    {/*                        return (*/}
                                                    {/*                            <Grid*/}
                                                    {/*                                item*/}
                                                    {/*                                xs={*/}
                                                    {/*                                    item.gridSize*/}
                                                    {/*                                }*/}
                                                    {/*                                md={*/}
                                                    {/*                                    2*/}
                                                    {/*                                }*/}
                                                    {/*                                key={*/}
                                                    {/*                                    index*/}
                                                    {/*                                }>*/}
                                                    {/*                                <NotificationPreferences*/}
                                                    {/*                                    label={*/}
                                                    {/*                                        item.label*/}
                                                    {/*                                    }*/}
                                                    {/*                                    name={*/}
                                                    {/*                                        item.name*/}
                                                    {/*                                    }*/}
                                                    {/*                                    value={*/}
                                                    {/*                                        values[*/}
                                                    {/*                                            item*/}
                                                    {/*                                                .name*/}
                                                    {/*                                        ]*/}
                                                    {/*                                    }*/}
                                                    {/*                                    onChange={(*/}
                                                    {/*                                        e,*/}
                                                    {/*                                    ) => {*/}
                                                    {/*                                        setFieldValue(*/}
                                                    {/*                                            e*/}
                                                    {/*                                                .target*/}
                                                    {/*                                                .name,*/}
                                                    {/*                                            !values[*/}
                                                    {/*                                                item*/}
                                                    {/*                                                    .name*/}
                                                    {/*                                            ],*/}
                                                    {/*                                        )*/}
                                                    {/*                                    }}*/}
                                                    {/*                                />*/}
                                                    {/*                            </Grid>*/}
                                                    {/*                        )*/}
                                                    {/*                    },*/}
                                                    {/*                )}*/}
                                                    {/*            </Grid>*/}
                                                    {/*        </Grid>*/}
                                                    {/*    )}*/}

                                                    {val.addButton && (
                                                        <Grid
                                                            className="dFlex"
                                                            item
                                                            xs={
                                                                12
                                                            }>
                                                            <Grid
                                                                item
                                                                xs={
                                                                    2
                                                                }></Grid>
                                                            <Grid
                                                                item
                                                                xs={
                                                                    8
                                                                }>
                                                                <Button
                                                                    className="primaryBtn"
                                                                    onClick={
                                                                        () => {} // handleAddDependant
                                                                    }>
                                                                    Add
                                                                    Dependant
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment
                                                key={
                                                    index
                                                }></React.Fragment>
                                        )
                                    },
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    className="dEnd">
                                    <ButtonSpinner
                                        disabled={
                                            !(
                                                isValid &&
                                                dirty
                                            ) ||
                                            isSubmitting
                                        }
                                        isLoading={Boolean(
                                            isSubmitting,
                                        )}
                                        label={'Save'}
                                    />
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>
            {loading && <LoadingPage />}
        </>
    )
}
export default PatientProfileEdit
