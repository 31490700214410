import { getService, postService } from '../../utilities/request/restClient'
import {
    getService as getPatientPortalService,
    authGetService,
    postService as postPatientPortalService,
    putService as putPatientPortalService,
} from '../../utilities/request/restPatientPortalClient'
import { config } from '../../constants'
const otpServiceUrl = `${config.dev.baseURLV2}/account`

export const sendOtpService = (data) => {
    return postService(`${otpServiceUrl}/get-otp`, data)
}
export const verifyOtpService = (data) => {
    return postService(`${otpServiceUrl}/login`, data)
}

export const getAccountInfo = () => {
    return getService(`${otpServiceUrl}/me`)
}

export const verifyTokenService = (data) => {
    return getService(`/verifytoken`)
}

export const providerDetailById = (id) => {
    return getService(`/providers/findById/${id}`)
}

export const registerService = (data) => {
    return postService(`${otpServiceUrl}/signup`, data)
}
export const completeProfileService = (data) => {
    return postService(`/completeprofile`, data)
}
export const getDetailsService = (data) => {
    return postService(`/get-details`, data)
}
export const sendOtpToEmailService = (data) => {
    return postService(`/validate-Email`, data)
}
export const verifyQuestionsService = (data) => {
    return postService(`/validate-security-qes`, data)
}
export const getPatientProfileById = (userId) => {
    return getService(`${config.dev.baseURLV2}/patients/${userId}`)
}

export const verifyEmail = (id) => {
    return getService(`/verify-email?id=${id}`)
}

export const verifyEmailSignUp = (data) => {
    return postPatientPortalService(`/patient_portal/sign_up`, data)
}
export const verifyResendEmailSignUp = (data) => {
    return postPatientPortalService(`/patient_portal/resend_signup_link`, data)
}
export const completeSignUp = (data) => {
    return postPatientPortalService(`/patient_portal/complete_sign_up`, data)
}
export const completeSignIn = (data) => {
    return postPatientPortalService(`/patient_portal/login`, data)
}
export const verfyEmailForgotPassword = (data) => {
    return postPatientPortalService(`/patient_portal/forget_password`, data)
}
export const me = () => {
    return getPatientPortalService(`/patient_portal/me`)
}
export const updateMe = (data) => {
    return postPatientPortalService(`/patient_portal/me`, data)
}
export const verifyMagicLink = (data) => {
    return postPatientPortalService(`/patient_portal/send-magic-link`, data)
}
export const resetPassWord = (data) => {
    return postPatientPortalService(`/patient_portal/reset_password`, data)
}
export const makeAuthenticatedAPICall = (params) => {
    console.log('params', params)
    return authGetService(`/patient_portal/me`, params)
}
export const updateProfilePic = (data) => {
    return postPatientPortalService(`patient_portal/patient/profile-pic-upload`, data)
}
export const getProfilePic = (data) => {
    return postPatientPortalService(`patient_portal/patient/profile-pic-view`, data)
}
export const uploadFile = (url, file) => {
    return putPatientPortalService(url, file)
}
