import React from 'react'

import {
    Grid,
    Typography,
    FormLabel,
} from '@material-ui/core'
const PatientPrimaryInsurance = (props) => {
    const { patientProfile } = props
    return (
        <>
            <Grid container className="borderBottom">
                {Array.isArray(
                    patientProfile.insuranceDetail,
                ) &&
                patientProfile.insuranceDetail.length !==
                    0 ? (
                    patientProfile.insuranceDetail.map(
                        (item) => (
                            <>
                                <Grid
                                    container
                                    lg={5}
                                    className="pTB3">
                                    {/* <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    > */}
                                    <Grid
                                        item
                                        xs={12}
                                        lg={5}
                                        className="card-title-align">
                                        <Typography
                                            variant="subtitle1"
                                            className="card-title">
                                            <span className="capitalize">
                                                {item.type}
                                            </span>
                                            &nbsp;Insurance
                                        </Typography>
                                    </Grid>
                                    {/* </Grid> */}
                                    <Grid
                                        container
                                        xs={12}
                                        lg={12}>
                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            className="mb1">
                                            <Grid
                                                item
                                                xs={12}>
                                                <FormLabel className="detail-item-title ">
                                                    Plan
                                                    Name
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="detail-item-title colorDark">
                                                    {
                                                        item.plan_name
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            className="mb1">
                                            <Grid
                                                item
                                                xs={12}>
                                                <FormLabel className="detail-item-title ">
                                                    Insurance
                                                    Group
                                                    Number
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="detail-item-title colorDark">
                                                    {
                                                        item.group_no
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            className="mb1">
                                            <Grid
                                                item
                                                xs={12}>
                                                <FormLabel className="detail-item-title ">
                                                    Insurance
                                                    Payer
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="detail-item-title colorDark">
                                                    {
                                                        item.payer
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            className="mb1">
                                            <Grid
                                                item
                                                xs={12}>
                                                <FormLabel className="detail-item-title ">
                                                    Payer ID
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="detail-item-title colorDark">
                                                    {
                                                        item.payer_id
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            lg={6}
                                            className="mb1">
                                            <Grid
                                                item
                                                xs={12}>
                                                <FormLabel className="detail-item-title ">
                                                    Primary
                                                    Member
                                                    ID
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="detail-item-title colorDark">
                                                    {
                                                        item.member_id
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ),
                    )
                ) : (
                    <>
                        <Grid
                            item
                            xs={6}
                            lg={6}
                            className="pTB3">
                            <Grid
                                item
                                xs={12}
                                className="card-title-align">
                                <Typography
                                    variant="subtitle1"
                                    className="card-title">
                                    <span className="capitalize">
                                        Primary
                                    </span>
                                    &nbsp;Insurance
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={5}
                                className="mb1">
                                <Grid item xs={12}>
                                    <FormLabel className="detail-item-title ">
                                        Plan Name
                                    </FormLabel>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={5}
                                className="mb1">
                                <Grid item xs={12}>
                                    <FormLabel className="detail-item-title ">
                                        Insurance Group
                                        Number
                                    </FormLabel>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={5}
                                className="mb1">
                                <Grid item xs={12}>
                                    <FormLabel className="detail-item-title ">
                                        Insurance Payer
                                    </FormLabel>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={5}
                                className="mb1">
                                <Grid item xs={12}>
                                    <FormLabel className="detail-item-title ">
                                        Payer ID
                                    </FormLabel>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={5}
                                className="mb1">
                                <Grid item xs={12}>
                                    <FormLabel className="detail-item-title ">
                                        Primary Member ID
                                    </FormLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}
export default PatientPrimaryInsurance
