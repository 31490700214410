import _ from 'lodash'
export const AUDIO_LEVEL_THRESHOLD = 200

export const isMobile = (() => {
    if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
        return false
    }
    return /Mobile/.test(navigator.userAgent)
})()

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
    if (!_.isObject(obj)) return obj

    const target: { [name: string]: any } = {}

    for (const key in obj) {
        const val = obj[key]
        if (typeof val !== 'undefined') {
            target[key] = removeUndefineds(val)
        }
    }

    return target as T
}

export async function getDeviceInfo() {
    const devices = await navigator.mediaDevices.enumerateDevices()

    return {
        audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
        videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
        audioOutputDevices: devices.filter((device) => device.kind === 'audiooutput'),
        hasAudioInputDevices: devices.some((device) => device.kind === 'audioinput'),
        hasVideoInputDevices: devices.some((device) => device.kind === 'videoinput'),
    }
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
    if (navigator.permissions) {
        try {
            const result = await navigator.permissions.query({ name })
            return result.state === 'denied'
        } catch {
            return false
        }
    } else {
        return false
    }
}

export function getAudioLevelPercentage(level: number) {
    return (level * 100) / AUDIO_LEVEL_THRESHOLD // 0 to 100
}

export function getStandardDeviation(values: number[]): number {
    // Same method used in client sdks
    // https://github.com/twilio/twilio-client.js/blob/master/lib/twilio/statsMonitor.ts#L88

    if (values.length <= 0) {
        return 0
    }

    const valueAverage: number =
        values.reduce((partialSum: number, value: number) => partialSum + value, 0) / values.length

    const diffSquared: number[] = values.map((value: number) => Math.pow(value - valueAverage, 2))

    const stdDev: number = Math.sqrt(
        diffSquared.reduce((partialSum: number, value: number) => partialSum + value, 0) /
            diffSquared.length,
    )

    return round(stdDev)
}

export const round = (num: number, decimals = 2) =>
    Math.round((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals
