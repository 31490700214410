import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { config } from '../../constants'

const methods = {
    headers: {
        'Content-Type': 'application/json',
    },
}

function ShortLink() {
    const history = useHistory()
    const { shortLink }: { shortLink: string } = useParams()

    useEffect(() => {
        ;(async () => {
            try {
                const { data } = await axios.get(
                    `${config.dev.baseURLV2}/telehealth/encounter-link/${shortLink}`,
                    methods,
                )
                window.location.href = data.getEncounterLongLink.long_link
            } catch (error) {
                history.push('/invalid-link')
            }
        })()
    }, [shortLink])

    return null
}

export default ShortLink
