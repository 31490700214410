import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import { bindActionCreators } from 'redux'
import VideoChat from '../components/TwilioVideo/VideoChat'
import Routes from '../constants/routes'
import { guestRoutes } from '../routes'
import { getLoggedInUserDetails, logout } from '../store/actions/login'

import { setRedirectURL, verifyToken } from '../store/actions/login'
import {
    getAuthToken,
    getIsLoggedIn,
    getIsPatientDetailCompleted,
    getLoggedInUserName,
} from '../utilities'
import AppLayout from './AppLayout'

class AppContainer extends Component {
    constructor(props) {
        super(props)
        this.validateToken()
    }
    // componentDidMount() {
    //     if (process.env.REACT_APP_ADD_JIRA_ISSUE_COLLECTOR) {
    //         const script = document.createElement('script')
    //         script.src =
    //             'https://team-1621955497024.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/azc3hx/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=9e4e2fc8'
    //         script.type = 'text/javascript'
    //         script.async = true
    //         document.body.appendChild(script)
    //     }

    // if (localStorage.getItem('access_token') && localStorage.getItem('refresh_token')) {
    //     this.props.getLoggedInUserDetails()
    //     //     try {
    //     //         this.props.getLoggedInUserDetails()
    //     //         if (this.props.isAuthenticated) {
    //     //             this.props.history.push('/dashboard')
    //     //         }
    //     //     } catch (error) {
    //     //         this.props.history.push('/signin')
    //     //     }
    //     // } else {
    //     //     this.props.history.push('/signin')
    //     // }
    // }
    // }

    validateToken = () => {
        //check for token validity
        const { verifyToken: checkAuthentication, location, setRedirectURL } = this.props
        const token = getAuthToken()
        if (token) {
            checkAuthentication()
        } else {
            if (location.pathname === Routes.PAYMENTS.RECIEPT) {
                setRedirectURL({
                    pathname: location.pathname,
                    search: location.search,
                })
            }
        }
    }

    render() {
        const {
            location: { pathname },
            isAuthenticated,
            logout,
            verifyToken: checkAuthentication,
        } = this.props
        const isLoggedIn = getIsLoggedIn()
        const isPatientDetailCompleted = getIsPatientDetailCompleted()

        // Check consulataion url
        const isConsulation = Routes.CONSULTATION.VIDEO === pathname

        const queryParams = new URLSearchParams(window.location.search);
        if (isLoggedIn && queryParams.get('redirect') === 'ok') {
            localStorage.clear();
            window.location.href = `/verify-magic-link?data=${queryParams.get('data')}&redirect=ok`;
        }

        return isLoggedIn ? (
            // eslint-disable-next-line react/jsx-indent
            <AppLayout
                isAuthenticated={isAuthenticated}
                validateToken={this.validateToken}
                logout={logout}
            />
        ) : (
            <GuardProvider>
                {/* {!isExcludedRoute(pathname) && <HeaderNew />}
                {!isExcludedRoute(pathname) && <AppointmentDetails />} */}
                <Switch>
                    {guestRoutes.map((route, index) => {
                        const { path, component, meta } = route
                        return (
                            <GuardedRoute
                                path={path}
                                exact
                                strict
                                component={component}
                                key={index}
                                meta={{
                                    ...meta,
                                    // verifyToken: checkAuthentication,
                                    isAuthenticated,
                                }}
                                role={meta.role}
                                guards={meta.guards}
                            />
                        )
                    })}
                </Switch>
                <VideoChat />
            </GuardProvider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.login.isAuthenticated,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            verifyToken,
            logout,
            setRedirectURL,
            getLoggedInUserDetails: getLoggedInUserDetails,
        },
        dispatch,
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer))
