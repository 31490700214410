import React, { useState } from 'react'
import {
    Typography,
    FormControl,
    Select,
    MenuItem,
    Button,
    CircularProgress,
} from '@material-ui/core'
import './documents.scss'
import { UserRoles } from '../../constants/user-roles'
import { Modal } from '@material-ui/core'
import { Button as ButtonComponent } from '../../components/custom'
import { PlaceholderEl } from '../../shared/elements'
import { useHistory } from 'react-router-dom'
import Routes from '../../constants/routes'
import { ReactComponent as SearchIcon } from '../../assests/images/icon_search.svg'
import InputBase from '@material-ui/core/InputBase'
import { ReactComponent as ViewIcon } from '../../assests/images/icons_view_profile.svg'
import { ReactComponent as EditIcon } from '../../assests/images/icons_edit_profile.svg'
import { ReactComponent as DeleteIcon } from '../../assests/images/icons_delete.svg'
import {
    getLoggedInUserId,
    getLoggedInUserRole,
    capitalizeFirstLetter,
} from '../../utilities'
import { TextField } from '@material-ui/core'
import CustomSweetAlerat from '../../components/shared/Elements/CustomSweetAlerat'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getDocumentList } from '../../services/Document/queries'
import {
    NoRowsOverlayEl,
    DocumentHeaderE1,
} from '../../shared/elements'
import { initialState, reducer } from './reducer'
import * as actionTypes from './actionTypes'
import {
    deleteDocument,
    editDocument,
    uploadDocs,
} from '../../store/actions/documents'
import { docTypes } from '../../constants/documentLabel'
import { docupload } from '../../constants/documentuploadlabel'
import LoadingPage from '../../utilities/loading-page'
import CloseIcon from '@material-ui/icons/Close'
import { bytesToMB } from '../../utilities'
import Swal from 'sweetalert2'
import attachIcon from '../../assests/images/icon_attachment.svg'
import { useDebounce } from '../../shared/hooks'
import TabView from '../../components/shared/Elements/TabView'
import { url, Document_TABS } from '../../constants/index'
import { commonMasterData } from '../../services/Provider/provider'

const useStyles = makeStyles({})

const Index = (props) => {
    const { userid, checkprovider } = props
    const dispatcher = useDispatch()
    const parentClass = 'document_container'
    const loggedInUserId = getLoggedInUserId()
    const [activeTab, handleTabChange] = useState(0)

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    const [timestamp, setTimestamp] = useState(
        new Date().getTime(),
    )
    const [selectedOption, setSelectedOption] = useState('')
    const [selectededitOption, setSelectedEditOption] =
        useState('')
    const [flag, setFlag] = useState('showDocument')
    const classes = useStyles()

    const userRole = getLoggedInUserRole()

    const [searchtext, SetSearchText] = useState('')
    const [searchactive, setSearchActive] = useState(false)
    const [checkvalue, setCheckValue] = useState(false)

    function tabchange(activeTabindex) {
        handleTabChange(activeTabindex)
        setSearchActive(false)
        SetSearchText('')
    }
    React.useEffect(() => {
        setTimestamp(new Date().getTime())
    }, [state.documents.data])

    const [open, setOpen] = useState(false)
    const [errorSize, setErrorSize] = useState(false)
    const [uploadModel, setOpenUploadModel] =
        useState(false)
    const [selecteduploadtype, setSelectedUploadType] =
        useState('')

    const [Id, setID] = useState('')
    const [doctext, setChangeText] = useState('')
    const [validate, setValidate] = useState('')
    const [file_urls, setFileUrl] = useState('')
    const [fileextension, setFileExtension] = useState('')
    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
    ].includes(userRole)
    const [isFilePicked, setIsFilePicked] = useState([])
    const loading = useSelector(
        (state) => state.loader.loading,
    )
    function handleOpen(
        id,
        file_url,
        document_name,
        document_type,
    ) {
        setOpen(true)
        setID(id)
        setFileUrl(file_url)
        setChangeText(document_name.replace(/_/g, ' '))
        setValidate(document_name.replace(/_/g, ' '))
        setSelectedEditOption(
            document_type?.replace(/_/g, ' '),
        )
        setFileExtension(document_name.split('.').pop())
    }

    const handleClose = () => {
        setOpen(false)
        setCheckValue('')
        setValidate('')
    }
    const handleuploadmodel = () => {
        setOpenUploadModel(false)
        setSelectedUploadType('')
        setIsFilePicked([])
        setErrorSize(false)
    }

    function uploaddialogue() {
        setOpenUploadModel(true)
    }
    function handleEditdocChange(value) {
        setValidate(value)
        setChangeText(value)
    }
    const submitEdit = () => {
        if (validate.trim() === '') {
            setCheckValue(true)
        } else {
            dispatcher(
                editDocument(
                    {
                        document_name: `${doctext.replace(
                            /\..+$/,
                            '',
                        )}.${fileextension}`,
                        file_url: file_urls,
                        id: Id,
                        document_type: selectededitOption,
                    },
                    () => {
                        fetchdocuments({
                            document_type:
                                selectededitOption,
                            page: state.pagination.page,
                            limit: state.pagination.limit,
                        })
                    },
                ),
            )
            handleClose()
            setCheckValue(false)
        }

        setFlag('editrecords')
    }
    function uploadFiles() {
        const formData = new FormData()
        formData.append(
            'user_id',
            isDisabled || checkprovider
                ? +userid
                : +loggedInUserId,
        )
        formData.append(
            'uploaded_by',
            isDisabled || checkprovider
                ? +loggedInUserId
                : isDisabled || checkprovider
                ? +userid
                : +loggedInUserId,
        )

        formData.append('document_type', selecteduploadtype)
        isFilePicked.forEach((f, i) => {
            formData.append(`File${i}`, f)
        })
        dispatcher(
            uploadDocs(formData, () => {
                Swal.fire('', 'Document uploaded').then(
                    () => {
                        handleTabChange(0)
                        fetchdocuments({
                            page: state.pagination.page,
                            limit: state.pagination.limit,
                        })
                    },
                )
                handleuploadmodel()
            }),
        )
    }
    function onRowClickDelete(id, document_name) {
        CustomSweetAlerat({
            text: `Are you sure you want to remove this ${
                document_name.charAt(0).toUpperCase() +
                document_name.slice(1).replace(/_/g, ' ')
            }?`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            onConfirm: () => {
                dispatcher(
                    deleteDocument({ id }, () => {
                        fetchdocuments({
                            page: state.pagination.page,
                            limit: state.pagination.limit,
                        })
                    }),
                )
            },
        })
        setFlag('deleterecords')
    }
    const columns = [
        {
            renderHeader: (values) => (
                <DocumentHeaderE1
                    title="Document Type"
                    field="document_type"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'document_type',
            flex: 1,
            renderCell: ({ value }) => {
                return (
                    <span>{value?.replace(/_/g, ' ')}</span>
                )
            },
        },
        {
            renderHeader: (values) => (
                <DocumentHeaderE1
                    title="Document Name"
                    field="document_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            flex: 1,
            sortable: false,
            field: 'document_name',
            renderCell: ({ value }) => {
                return (
                    <span className="doctexttransform">
                        {capitalizeFirstLetter(
                            value ? value : null,
                        ).replace(/_/g, ' ')}
                    </span>
                )
            },
        },
    ]

    if (activeTab === 1) {
        columns.push(
            {
                renderHeader: (values) => (
                    <DocumentHeaderE1
                        title="Uploaded by"
                        field="uploaded_name"
                        onSort={onSort}
                        {...values}
                    />
                ),
                sortable: false,
                field: 'uploaded_name',
                flex: 1,
                renderCell: ({ value }) => {
                    return (
                        <span className="description-txt">
                            {value}
                        </span>
                    )
                },
            },
            {
                renderHeader: (values) => (
                    <DocumentHeaderE1
                        title="Uploaded on"
                        field="created_at"
                        onSort={onSort}
                        {...values}
                    />
                ),
                sortable: false,
                field: 'created_at',
                flex: 1,
                renderCell: ({ value }) => {
                    return moment
                        .utc(new Date(value))
                        .local()
                        .format('MM/DD/YYYY hh:mm A')
                },
            },
            {
                headerName: (
                    <Typography variant="body2">
                        Actions
                    </Typography>
                ),
                field: 'action',
                flex: 0.4,
                sortable: false,
                renderCell: (values) => {
                    const Checkuser =
                        values.row.role.role_name
                    return (
                        <div className="Icons">
                            <Typography>
                                <span
                                    onClick={() =>
                                        window.open(
                                            `${url}${values.row.file_url}`,
                                        )
                                    }>
                                    <ViewIcon
                                        key={`${timestamp}-view`}
                                        className="Iconsizr"
                                        style={{
                                            marginTop:
                                                '4px',
                                        }}
                                    />
                                </span>
                            </Typography>
                        </div>
                    )
                },
            },
        )
    } else {
        if (
            userRole === UserRoles.TM2U_ADMIN ||
            userRole === UserRoles.TM2U_SUPER_ADMIN ||
            userRole === UserRoles.CLINIC_CORDINATION
        ) {
            columns.push(
                {
                    renderHeader: (values) => (
                        <DocumentHeaderE1
                            title="Uploaded by"
                            field="uploaded_name"
                            onSort={onSort}
                            {...values}
                        />
                    ),
                    sortable: false,
                    field: 'uploaded_name',
                    flex: 1,
                    renderCell: ({ value }) => {
                        return (
                            <span className="description-txt">
                                {value}
                            </span>
                        )
                    },
                },
                {
                    renderHeader: (values) => (
                        <DocumentHeaderE1
                            title="Uploaded on"
                            field="created_at"
                            onSort={onSort}
                            {...values}
                        />
                    ),
                    sortable: false,
                    field: 'created_at',
                    flex: 1,
                    renderCell: ({ value }) => {
                        return moment
                            .utc(new Date(value))
                            .local()
                            .format('MM/DD/YYYY hh:mm A')
                    },
                },
                {
                    headerName: (
                        <Typography variant="body2">
                            Actions
                        </Typography>
                    ),
                    field: 'action',
                    flex: 1,
                    sortable: false,
                    renderCell: (values) => {
                        const Checkuser =
                            values.row.role.role_name
                        return (
                            <div className="Icons">
                                <Typography>
                                    <span
                                        onClick={() =>
                                            window.open(
                                                `${url}${values.row.file_url}`,
                                            )
                                        }>
                                        <ViewIcon
                                            key={`${timestamp}-view-1`}
                                            className={
                                                activeTab ===
                                                1
                                                    ? 'Iconsleftassign'
                                                    : 'Iconsizr'
                                            }
                                            style={{
                                                marginTop:
                                                    '4px',
                                            }}
                                        />
                                    </span>
                                </Typography>
                                {activeTab === 1 ? (
                                    <span>
                                        {' '}
                                        <EditIcon
                                            key={`${timestamp}-edit`}
                                            className="Iconsss"
                                            fill={'grey'}
                                        />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            handleOpen(
                                                values.row
                                                    .id,
                                                values.row
                                                    .file_url,
                                                values.row
                                                    .document_name,
                                                values.row
                                                    .document_type,
                                            )
                                        }>
                                        {' '}
                                        <EditIcon
                                            key={`${timestamp}-edit-2`}
                                            className="Iconsizr"
                                        />
                                    </span>
                                )}
                                {activeTab === 1 ? (
                                    <span>
                                        {' '}
                                        <DeleteIcon
                                            key={`${timestamp}-delete`}
                                            className="Iconsss"
                                        />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            onRowClickDelete(
                                                values.row
                                                    .id,
                                                values.row
                                                    .document_name,
                                            )
                                        }>
                                        {' '}
                                        <DeleteIcon
                                            key={`${timestamp}-delete-1`}
                                            className="Iconsizr"
                                        />
                                    </span>
                                )}
                            </div>
                        )
                    },
                },
            )
        }
        if (checkprovider) {
            columns.push(
                {
                    renderHeader: (values) => (
                        <DocumentHeaderE1
                            title="Uploaded by"
                            field="uploaded_name"
                            onSort={onSort}
                            {...values}
                        />
                    ),
                    sortable: false,
                    field: 'uploaded_name',
                    flex: 1,
                    renderCell: ({ value }) => {
                        return (
                            <span className="description-txt">
                                {value}
                            </span>
                        )
                    },
                },
                {
                    renderHeader: (values) => (
                        <DocumentHeaderE1
                            title="Uploaded on"
                            field="created_at"
                            onSort={onSort}
                            {...values}
                        />
                    ),
                    sortable: false,
                    field: 'created_at',
                    flex: 1,
                    renderCell: ({ value }) => {
                        return moment
                            .utc(new Date(value))
                            .local()
                            .format('MM/DD/YYYY hh:mm A')
                    },
                },
                {
                    headerName: (
                        <Typography variant="body2">
                            Actions
                        </Typography>
                    ),
                    field: 'action',
                    flex: 0.5,
                    sortable: false,
                    renderCell: (values) => {
                        const Checkuser =
                            values.row.role.role_name
                        return (
                            <div className="Icons">
                                <Typography>
                                    <span
                                        onClick={() =>
                                            window.open(
                                                `${url}${values.row.file_url}`,
                                            )
                                        }>
                                        <ViewIcon
                                            key={`${timestamp}-view-3`}
                                            className="Iconsizr"
                                            style={{
                                                marginTop:
                                                    '4px',
                                            }}
                                        />
                                    </span>
                                </Typography>
                                {activeTab === 1 ? (
                                    <span>
                                        {' '}
                                        <EditIcon
                                            key={`${timestamp}-edit-1`}
                                            className="Iconsss"
                                            fill={'grey'}
                                        />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            handleOpen(
                                                values.row
                                                    .id,
                                                values.row
                                                    .file_url,
                                                values.row
                                                    .document_name,
                                                values.row
                                                    .document_type,
                                            )
                                        }>
                                        {' '}
                                        <EditIcon
                                            key={`${timestamp}-edit-3`}
                                            className="Iconsizr"
                                        />
                                    </span>
                                )}
                                {activeTab === 1 ? (
                                    <span>
                                        {' '}
                                        <DeleteIcon
                                            key={`${timestamp}-delete-2`}
                                            className="Iconsss"
                                        />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            onRowClickDelete(
                                                values.row
                                                    .id,
                                                values.row
                                                    .document_name,
                                            )
                                        }>
                                        {' '}
                                        <DeleteIcon
                                            key={`${timestamp}-delete-3`}
                                            className="Iconsizr"
                                        />
                                    </span>
                                )}
                            </div>
                        )
                    },
                },
            )
        } else {
            columns.push(
                {
                    renderHeader: (values) => (
                        <DocumentHeaderE1
                            title="Uploaded on"
                            field="created_at"
                            onSort={onSort}
                            {...values}
                        />
                    ),
                    sortable: false,
                    field: 'created_at',
                    flex: 1,
                    renderCell: ({ value }) => {
                        return moment
                            .utc(new Date(value))
                            .local()
                            .format('MM/DD/YYYY hh:mm A')
                    },
                },
                {
                    headerName: (
                        <Typography variant="body2">
                            Actions
                        </Typography>
                    ),
                    field: 'action',
                    flex: 0.5,
                    sortable: false,
                    renderCell: (values) => {
                        const Checkuser =
                            values.row.role.role_name
                        return (
                            <div className="Icons">
                                <Typography>
                                    <span
                                        onClick={() =>
                                            window.open(
                                                `${url}${values.row.file_url}`,
                                            )
                                        }>
                                        <ViewIcon
                                            key={`${timestamp}-view-2`}
                                            className="Iconsizr"
                                            style={{
                                                marginTop:
                                                    '4px',
                                            }}
                                        />
                                    </span>
                                </Typography>
                                {activeTab === 1 ? (
                                    <span>
                                        {' '}
                                        <EditIcon
                                            key={`${timestamp}-edit-4`}
                                            className="Iconsss"
                                            fill={'grey'}
                                        />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            handleOpen(
                                                values.row
                                                    .id,
                                                values.row
                                                    .file_url,
                                                values.row
                                                    .document_name,
                                                values.row
                                                    .document_type,
                                            )
                                        }>
                                        {' '}
                                        <EditIcon
                                            key={`${timestamp}-edit-5`}
                                            className="Iconsizr"
                                        />
                                    </span>
                                )}
                                {activeTab === 1 ? (
                                    <span>
                                        {' '}
                                        <DeleteIcon
                                            key={`${timestamp}-delete-4`}
                                            className="Iconsss"
                                        />
                                    </span>
                                ) : (
                                    <span
                                        onClick={() =>
                                            onRowClickDelete(
                                                values.row
                                                    .id,
                                                values.row
                                                    .document_name,
                                            )
                                        }>
                                        {' '}
                                        <DeleteIcon
                                            key={`${timestamp}-delete-5`}
                                            className="Iconsizr"
                                        />
                                    </span>
                                )}
                            </div>
                        )
                    },
                },
            )
        }
    }
    React.useEffect(() => {
        dispatch({
            type: actionTypes.DOCUMENTS_PAGE_CHANGE,
            page: 1,
        })
    }, [activeTab])
    const debouncedSearchText = useDebounce(searchtext, 500)
    React.useEffect(() => {
        fetchdocuments({
            page: state.pagination.page,
            limit: state.pagination.limit,
            document_type:
                selectedOption === 'All'
                    ? ''
                    : selectedOption.replace(' ', '_'),
        })
    }, [
        state.pagination,
        selectedOption,
        activeTab,
        debouncedSearchText.replace(' ', '_'),
    ])

    async function fetchdocuments({
        page,
        limit,
        document_type,
    }) {
        try {
            dispatch({
                type: actionTypes.DOCUMENTS_LOADING,
            })

            const documents = await getDocumentList({
                document_list_param: userRole,
                id:
                    isDisabled || checkprovider
                        ? +userid
                        : +loggedInUserId,
                limit,
                page,
                document_type,
                received: checkprovider
                    ? false
                    : activeTab === 1,
                self: checkprovider
                    ? false
                    : activeTab === 0,
                search: searchactive,
                filterString: debouncedSearchText.replace(
                    ' ',
                    '_',
                ),
            })
            dispatch({
                type: actionTypes.DOCUMENTS_SUCCESS,
                data: documents.data.documentList,
                totalCount: documents.data.totalCount,
            })
        } catch {
            dispatch({
                type: actionTypes.DOCUMENTS_FAILURE,
            })
        }
    }
    function onSort(value) {
        dispatch({
            type: actionTypes.DOCUMENTS_SORT_DATA,
            value,
        })
    }
    function onPageChange(page) {
        dispatch({
            type: actionTypes.DOCUMENTS_PAGE_CHANGE,
            page: page + 1,
        })
    }

    function handlesearch(value) {
        if (!selectedOption) {
            Swal.fire('Please Select Document Type')
            SetSearchText('')
        } else {
            SetSearchText(value)
            if (!value) {
                setSearchActive(false)
            } else {
                setSearchActive(true)
            }
        }
    }

    const handlePhotoUpload = async (e) => {
        const { files } = e.target
        setErrorSize(false)
        if (files) {
            const filesArr = [...isFilePicked].slice(0, 10)

            for (let i = 0; i < files.length; i++) {
                const getFileext = files[i].name
                    .split('.')
                    .pop()
                const isValidFile = [
                    'ppt',
                    'pptx',
                    'jpeg',
                    'csv',
                    'docx',
                    'doc',
                    'jpg',
                    'png',
                    'xlx',
                    'csv',
                    'pdf',
                    'txt',
                ].includes(getFileext)

                if (
                    filesArr.findIndex(
                        (f) => f.name === files[i].name,
                    ) === -1 &&
                    isValidFile
                ) {
                    filesArr.push(files[i])
                }
            }
            setIsFilePicked(filesArr.slice(0, 10))
        }
        e.target.value = ''
    }
    React.useEffect(() => {
        const totalSizeInBytes = isFilePicked.reduce(
            (acc, item) => acc + item.size,
            0,
        )
        const totalSizeInMB = bytesToMB(totalSizeInBytes)
        if (totalSizeInMB > 25) {
            setErrorSize(true)
        } else {
            setErrorSize(false)
        }
        return totalSizeInMB < 25
    }, [isFilePicked])
    const [doctype, setdocType] = React.useState([])
    const [editdocTypeList, seteditDoctype] =
        React.useState([])
    const fetchCommonMasterData = async () => {
        try {
            const res = await commonMasterData()
            seteditDoctype(res.data.document_types)

            const docList = [...res.data.document_types]
            docList.unshift({
                value: 'All',
                label: 'All',
            })

            setdocType(docList)
        } catch (err) {
            console.log(err)
        }
    }
    React.useEffect(() => {
        fetchCommonMasterData()
    }, [])
    return (
        <div className="MuiDataGrid-table-wrapper">
            <div
                className={
                    isDisabled || checkprovider
                        ? 'admin_header'
                        : 'header_ad'
                }>
                {isDisabled ? (
                    <div></div>
                ) : checkprovider === true ? (
                    <div></div>
                ) : (
                    <Typography
                        variant="h2"
                        className={`${parentClass}__header-label`}>
                        Documents
                    </Typography>
                )}

                <div
                    className={`${parentClass}__header__actions`}>
                    <FormControl className="documentheader">
                        <Select
                            id="docdropdown"
                            className="documentSelect"
                            disableUnderline
                            value={selectedOption}
                            displayEmpty
                            renderValue={
                                !selectedOption
                                    ? () => (
                                          <PlaceholderEl>
                                              Document Type
                                          </PlaceholderEl>
                                      )
                                    : null
                            }
                            onChange={(e) => {
                                setSelectedOption(
                                    e.target.value,
                                )
                            }}>
                            {doctype.map((el) => (
                                <MenuItem
                                    value={el.value}
                                    key={el.value}
                                    className="pl-select-option">
                                    {el.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="search_input">
                        <SearchIcon
                            key={`${timestamp}-search`}
                        />
                        <InputBase
                            placeholder="Search by Document Name"
                            inputProps={{
                                'aria-label': 'id no.',
                                className:
                                    classes.inputField,
                            }}
                            value={searchtext}
                            onChange={(e) =>
                                handlesearch(e.target.value)
                            }
                        />
                    </div>

                    <ButtonComponent
                        onClick={uploaddialogue}
                        style={{
                            width: '160px',
                            fontSize: '14px',
                        }}>
                        Upload
                    </ButtonComponent>
                </div>
            </div>
            <div className={`${parentClass}__body  mt2`}>
                {isDisabled ? null : checkprovider ===
                  true ? null : (
                    <TabView
                        activeTab={activeTab}
                        handleTabChange={tabchange}
                        tabs={Document_TABS}
                    />
                )}
            </div>

            <div
                className={
                    isDisabled || checkprovider
                        ? 'doctableview'
                        : 'doctableviewpersonawise'
                }>
                <DataGrid
                    className={classes.root}
                    getRowId={(row) => row.id}
                    rows={state.documents.data}
                    columns={columns}
                    pagination
                    page={state.pagination.page - 1}
                    pageSize={state.pagination.limit}
                    rowsPerPageOptions={[
                        state.pagination.limit,
                    ]}
                    rowCount={state.documents.totalCount}
                    paginationMode="server"
                    onPageChange={onPageChange}
                    disableColumnSelector
                    disableSelectionOnClick
                    autoHeight
                    rowHeight={60}
                    components={{
                        NoRowsOverlay: () => (
                            <NoRowsOverlayEl title="No Documents Found." />
                        ),
                    }}
                    loading={state.documents.isLoading}
                />
            </div>
            <>
                <Modal open={open} onClose={handleClose}>
                    <div className="document_Model_EditContainer">
                        <div className="documentHeader">
                            <p className="modellabel">
                                Edit Document
                            </p>
                            <span
                                className="modelclose"
                                onClick={handleClose}>
                                <CloseIcon
                                    style={{
                                        fill: 'white',
                                        cursor: 'pointer',
                                    }}
                                />
                            </span>
                        </div>

                        <div>
                            <FormControl
                                className={`${parentClass}__header-user_dropdown`}>
                                <span className="uploaddoclabel">
                                    Document Type
                                </span>
                                <Select
                                    id="docdropdown"
                                    className="editselectmodelforedit"
                                    disableUnderline
                                    placeholder={
                                        'Select Document Type'
                                    }
                                    value={
                                        selectededitOption
                                    }
                                    displayEmpty
                                    renderValue={
                                        !selectededitOption
                                            ? () => (
                                                  <PlaceholderEl>
                                                      Document
                                                      Type
                                                  </PlaceholderEl>
                                              )
                                            : null
                                    }
                                    onChange={(e) => {
                                        setSelectedEditOption(
                                            e.target.value,
                                        )
                                    }}>
                                    {editdocTypeList.map(
                                        (el) => (
                                            <MenuItem
                                                value={
                                                    el.value
                                                }
                                                key={
                                                    el.value
                                                }
                                                className="pl-select-option">
                                                {el.label.replace(
                                                    '_',
                                                    ' ',
                                                )}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                marginTop: '20px',
                                marginLeft: '3px',
                            }}>
                            <span className="documentName">
                                Document Name
                            </span>

                            <TextField
                                variant="outlined"
                                InputProps={{
                                    className:
                                        'textfilemodel',
                                }}
                                inputProps={{
                                    maxLength: 40,
                                }}
                                value={doctext.replace(
                                    /\..+$/,
                                    '',
                                )}
                                onChange={(e) =>
                                    handleEditdocChange(
                                        e.target.value,
                                    )
                                }
                            />
                            {checkvalue === true ? (
                                <span className="alertmessage">
                                    Field cannot be blank *
                                </span>
                            ) : null}
                        </div>

                        <ButtonComponent
                            onClick={submitEdit}
                            disabled={!selectededitOption}
                            style={{
                                width: '160px',
                                marginLeft: '145px',
                                marginTop: '45px',
                            }}>
                            Update
                        </ButtonComponent>
                    </div>
                </Modal>
            </>

            {/* Upload Document */}

            <>
                <Modal open={uploadModel}>
                    <div
                        className={
                            isFilePicked.length > 1
                                ? isFilePicked.length > 9
                                    ? 'document_container_selected'
                                    : 'document_container_upload'
                                : 'document_Model_Container'
                        }>
                        <div className="documentHeader">
                            <p className="uploaddocmodellabel">
                                Upload Documents
                            </p>
                            {loading ? null : (
                                <span
                                    className="modelclose"
                                    onClick={
                                        handleuploadmodel
                                    }>
                                    <CloseIcon
                                        style={{
                                            fill: 'white',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </span>
                            )}
                        </div>

                        <div>
                            <FormControl
                                className={`${parentClass}__header-user_dropdown`}>
                                <span className="uploaddoclabel">
                                    Document Type
                                </span>

                                <Select
                                    id="docdropdown"
                                    className="selectmodelupload"
                                    disableUnderline
                                    placeholder={
                                        'Select Document Type'
                                    }
                                    value={
                                        selecteduploadtype
                                    }
                                    displayEmpty
                                    renderValue={
                                        !selecteduploadtype
                                            ? () => (
                                                  <PlaceholderEl>
                                                      Document
                                                      Type
                                                  </PlaceholderEl>
                                              )
                                            : null
                                    }
                                    onChange={(e) =>
                                        setSelectedUploadType(
                                            e.target.value,
                                        )
                                    }>
                                    {doctype.map((el) => (
                                        <MenuItem
                                            value={el.value}
                                            key={el.value}
                                            className="pl-select-option">
                                            {el.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {isFilePicked.length >= 1 ? (
                                <label htmlFor="select-image">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        className="uploadbuttondoc"
                                        disabled={
                                            !selecteduploadtype ||
                                            errorSize ||
                                            isFilePicked.length >=
                                                10
                                        }
                                        style={{
                                            marginTop:
                                                '49px',
                                            marginLeft:
                                                '10px',
                                        }}>
                                        Upload More
                                    </Button>
                                </label>
                            ) : (
                                <label htmlFor="select-image">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        className="uploadbuttondoconly"
                                        disabled={
                                            !selecteduploadtype ||
                                            errorSize ||
                                            isFilePicked.length >=
                                                10
                                        }
                                        style={{
                                            marginTop:
                                                '49px',
                                            marginLeft:
                                                '10px',
                                        }}>
                                        Upload
                                    </Button>
                                </label>
                            )}

                            <span className="uploaddocbuttonlabel">
                                Document(s) format with a
                                maximum file size of 25MB
                            </span>
                        </div>
                        <div
                            // className={
                            //     isFilePicked.length > 3
                            //         ? 'filepickerscroll'
                            //         : 'filepickerscrollforthree'
                            // }
                            className="filepickerscroll">
                            {isFilePicked.map((item, i) => {
                                console.log(
                                    isFilePicked,
                                    'files',
                                )
                                return (
                                    <div
                                        key={i}
                                        className="filepicked">
                                        <img
                                            className="ba-attach-icn"
                                            src={attachIcon}
                                            alt=""
                                        />

                                        <span className="attachfilename">
                                            {item.name}
                                        </span>
                                        <span
                                            className="attachremove"
                                            onClick={() => {
                                                setIsFilePicked(
                                                    isFilePicked.filter(
                                                        (
                                                            ele,
                                                            index,
                                                        ) =>
                                                            i !==
                                                            index,
                                                    ),
                                                )
                                            }}>
                                            <CloseIcon
                                                style={{
                                                    fill: 'black',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ marginTop: '35px' }}>
                            <div
                                style={{
                                    marginLeft: '20px',
                                }}>
                                <input
                                    type="file"
                                    multiple
                                    id="select-image"
                                    style={{
                                        display: 'none',
                                    }}
                                    disabled={
                                        !selecteduploadtype ||
                                        errorSize
                                    }
                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv"
                                    onChange={(e) => {
                                        handlePhotoUpload(e)
                                    }}
                                />
                                {isFilePicked.length >=
                                1 ? (
                                    loading ? (
                                        <ButtonComponent className="continuebutton button">
                                            <CircularProgress className="cirrcularpredoc" />
                                        </ButtonComponent>
                                    ) : (
                                        <ButtonComponent
                                            onClick={
                                                uploadFiles
                                            }
                                            className="continuebutton button"
                                            disabled={
                                                errorSize
                                            }>
                                            Continue
                                        </ButtonComponent>
                                    )
                                ) : null}
                            </div>
                            {isFilePicked.length >= 10 ? (
                                loading ? null : (
                                    <span className="errormoreupload">
                                        Limit of maximum 10
                                        files that can be
                                        uploaded.
                                    </span>
                                )
                            ) : null}
                            {errorSize ? (
                                loading ? null : (
                                    <div
                                        style={{
                                            marginLeft:
                                                '20px',
                                        }}>
                                        <span className="errormoreuploadmb">
                                            Files more than
                                            25 MB cannot be
                                            uploaded.
                                        </span>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                </Modal>
            </>
        </div>
    )
}
export default Index
