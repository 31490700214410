import React from 'react'
import { config } from '../../../constants'
import TextInput from '../TextInput'
import * as Yup from 'yup'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'

const Text = React.forwardRef((props, ref) => (
    <TextInput inputRef={ref} {...props} />
))

function PhoneInputEl(props) {
    return (
        <PhoneInput
            country={config.country.name}
            inputComponent={Text}
            {...props}
        />
    )
}

Yup.addMethod(
    Yup.string,
    'phoneNumber',
    function (errorMessage) {
        return this.test(
            `test-phone-number`,
            errorMessage,
            function (value = '') {
                const { path, createError } = this

                return (
                    isPossiblePhoneNumber(value) ||
                    createError({
                        path,
                        message: errorMessage,
                    })
                )
            },
        )
    },
)

export default React.memo(PhoneInputEl)
