export const securityQuestions = [
    'What is title of your favorite book?',
    'What is the name of the street you grew up on?',
    'What is your mother’s maiden name?',
    'What was the name of your first pet?',
    'What was the name of the first company that you worked for?',
    'Where did you meet your spouse?',
    'What was the name of your high school?',
    'What city were you born in?',
    'What was the color of your first car?',
]
