import { useParams } from 'react-router'
import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import { getCredentials } from "../../api/fetchCreds";
import styles from './styles.js'
import useRoom from '../hooks/useRoom'
import { UserContext } from '../context/UserContext'

import ToolBar from '../ToolBar'

import NetworkToast from '../NetworkToast'
import useScreenSharing from '../hooks/useScreenSharing'
import axios from 'axios'
import EarlyArrival from '../EarlyArrival'

export default function VideoRoom() {
    const { user } = useContext(UserContext)
    const { push } = useHistory()
    const location = useLocation()
    const [credentials, setCredentials] = useState(null)
    const [error, setError] = useState(null)
    const {
        createCall,
        room,
        participants,
        connected,
        networkStatus,
        cameraPublishing,
        localParticipant,
    } = useRoom()
    const { isScreenSharing, startScreenSharing, stopScreenSharing } = useScreenSharing({ room })
    const roomContainer = useRef()
    const classes = styles()
    let { roomName } = useParams()
    const [isEarlyArrival, setIsEarlyArrival] = useState(true)

    console.log('participants :: ', JSON.stringify(participants))

    useEffect(() => {
        if (
            location?.state?.infoRoom &&
            location?.state?.infoRoom?.session_key &&
            location?.state?.infoRoom?.session_id &&
            location?.state?.infoRoom?.session_token &&
            location?.state?.infoRoom?.status
        ) {
            // const targetDatetime = new Date(location?.state?.infoRoom?.start)
            // targetDatetime.setMinutes(targetDatetime.getMinutes())

            // const currentTime = new Date()

            // console.log(targetDatetime)
            // console.log(currentTime)

            if (location?.state?.infoRoom?.status !== 'EXAM') {
                setIsEarlyArrival(true)
            } else {
                setIsEarlyArrival(false)
            }
        }
    }, [location?.state?.infoRoom])

    useEffect(() => {
        if (!isEarlyArrival) {
            setCredentials({
                apikey: location?.state?.infoRoom?.session_key,
                sessionId: location?.state?.infoRoom?.session_id,
                token: location?.state?.infoRoom?.session_token,
                userName: `${location?.state?.infoRoom?.patient_first_name} ${location?.state?.infoRoom?.patient_last_name}`,
            })
            // getCredentials(roomName)
            //   .then(({ data }) => {
            //     setCredentials({
            //       apikey: data.session_key,
            //       sessionId: data.session_id,
            //       token: data.session_token,
            //     });
            //   })
            //   .catch((err) => {
            //     setError(err);
            //     console.log(err);
            //   });
        }
    }, [isEarlyArrival])

    useEffect(() => {
        if (credentials) {
            createCall(credentials, roomContainer.current, credentials.userName, user.videoFilter, {
                ...user.defaultSettings,
            })
        }
    }, [createCall, credentials, user])

    if (error)
        return (
            <div className={classes.errorContainer}>
                There was an error fetching the data from the server
            </div>
        )

    return (
        <>
            {isEarlyArrival && <EarlyArrival enableSession={setIsEarlyArrival} />}

            {!isEarlyArrival && (
                <div id="callContainer" className={classes.callContainer}>
                    <div id="roomContainer" className={classes.roomContainer} ref={roomContainer}>
                        <NetworkToast networkStatus={networkStatus} />
                        <div id="screenSharingContainer" className={classes.screenSharingContainer}>
                            {isScreenSharing && (
                                <div className={classes.screenSharingOverlay}>
                                    You Are Screensharing
                                </div>
                            )}
                        </div>
                    </div>
                    <ToolBar
                        room={room}
                        participants={participants}
                        localParticipant={localParticipant}
                        connected={connected}
                        cameraPublishing={cameraPublishing}
                        isScreenSharing={isScreenSharing}
                        startScreenSharing={startScreenSharing}
                        stopScreenSharing={stopScreenSharing}></ToolBar>
                </div>
            )}
        </>
    )
}
