import * as actionType from '../actionType'

const initialState = {
    userData: null,
    otpError: false,
    isNewUser: false,
    isExistingUser: false,
    errorMsg: '',
}

const register = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PATIENT_DATA: {
            return {
                ...state,
                userData: action.value,
            }
        }
        case actionType.SET_OTP_ERROR: {
            return {
                ...state,
                otpError: action.value,
            }
        }
        case actionType.SET_NEW_USER: {
            return {
                ...state,
                isNewUser: !action.value,
                isExistingUser: action.value,
            }
        }
        case actionType.HANDLE_BACK: {
            return {
                ...state,
                isNewUser: action.value,
                isExistingUser: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}
export default register
