import React from 'react'
import { Button as ButtonComponent } from '@material-ui/core'
import './Button.scss'
export default function Button(props) {
    const { children } = props
    return (
        <ButtonComponent
            className="button"
            variant="contained"
            {...props}>
            {children}
        </ButtonComponent>
    )
}
