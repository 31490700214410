import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Divider, Typography } from '@material-ui/core'
import CheckboxOption from '../../../ClinicalNotes/reusable/CheckboxOptions'
import SingleCalenderComp from '../../../../shared/elements/DatePickerCustom/SingleCalenderComp'
import moment from 'moment'

const TimeofDay = ({ value, setValue }) => {
    const [openCalender, setOpenCalender] = React.useState(true)

    const onSelectDate = (payloadDate) => {
        setValue(moment(payloadDate), 'date')
    }

    return (
        <div className="booking_boxes">
            <div>
                <Typography className="step-headers">
                    What date and time works best for you?{' '}
                </Typography>
            </div>

            <div>
                <div className="select-a-time-body-header d-md-none">
                    <Typography style={{ marginLeft: 10 }}>Date</Typography>
                    <Typography>Time</Typography>
                </div>

                <div
                    className="select-a-time-body"
                    style={{ display: 'flex', alignItems: 'center', gap: 50 }}>
                    <Typography className='heading_label d-lg-none'>Choose date <span className='required'>*</span></Typography>
                    <SingleCalenderComp
                        selectedDate={onSelectDate}
                        displayDate={value?.date}
                        openCalender={openCalender}
                        closeTheCalender={() => setOpenCalender(true)}
                        alwaysOpen={true}
                        minDate={moment().add(1, 'days')}
                    />

                    <Divider
                        variant="middle"
                        orientation="vertical"
                        style={{ height: '380px', backgroundColor: '#C0C1C5' }}
                    />

                    <FormControl className='time_form_holder'>
                        <Typography className='heading_label d-lg-none'>Choose time <span className='required'>*</span></Typography>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group">
                            <FormControlLabel
                                style={{ marginBottom: 50 }}
                                className="booking_box"
                                control={
                                    <CheckboxOption
                                        checked={value.morning}
                                        onChange={(event) =>
                                            setValue(event?.target?.checked, 'morning')
                                        }
                                        option={{
                                            label: `Morning: 8 AM - 12 PM (${
                                                value.timezone || 'Loading...'
                                            })`,
                                            value: value.morning,
                                        }}
                                        customStyle={{
                                            marginLeft: 18,
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                style={{ marginBottom: 50 }}
                                className="booking_box"
                                control={
                                    <CheckboxOption
                                        checked={value.afternoon}
                                        onChange={(event) =>
                                            setValue(event?.target?.checked, 'afternoon')
                                        }
                                        option={{
                                            label: `Afternoon: 12 PM - 5 PM (${
                                                value.timezone || 'Loading...'
                                            })`,
                                            value: value.afternoon,
                                        }}
                                        customStyle={{
                                            marginLeft: 18,
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                style={{ marginBottom: 50 }}
                                className="booking_box"
                                control={
                                    <CheckboxOption
                                        checked={value.evening}
                                        onChange={(event) =>
                                            setValue(event?.target?.checked, 'evening')
                                        }
                                        option={{
                                            label: `Evening: 5 PM - 10 PM (${
                                                value.timezone || 'Loading...'
                                            })`,
                                            value: value.evening,
                                        }}
                                        customStyle={{
                                            marginLeft: 18,
                                        }}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>

            {/* <div><input type='radio'/><span>Yes, this is my first time</span></div> */}
        </div>
    )
}

export default TimeofDay
