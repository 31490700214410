import React, { useContext, useEffect, useRef, useState } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import moment from 'moment'
import clsx from 'clsx'
import styles from './style.js'
import Countdown from 'react-countdown'
import { useHistory, useLocation } from 'react-router-dom'
import IconClock from '../../../assests/icons/IconClock.js'
import Tms2uLogo from '../../../assests/icons/Tms2uLogo.js'
import AvatarDefault from '../../../assests/images/avatar.svg'
import bgImageMobile from '../../../assests/images/Bg_waiting_room_mobile.png'
import bgImage from '../../../assests/images/waiting_room_bg.jpg'
import AvatarJoinRoomIcon from '../../Consultation/icons/AvatarJoinRoomIcon'
import { config } from '../../../constants/index.js'
import LoadingPage from '../../../utilities/loading-page'
import AlertConfirmJoin from '../../Consultation/AlertConfirmJoin.jsx'
import axios from 'axios'
import { usePageVisibility } from '../hooks/usePageVisibility'
import AlertConfirmEncounterJoin from '../../Consultation/AlertConfirmEncounterJoin.jsx'
import AlertProviderLate from '../../Consultation/AlertProviderLate.jsx'

export default function EarlyArrival({ enableSession }) {
    const classes = styles()
    const location = useLocation()
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
    const [isTenMinPrior, setIsTenMinPrior] = useState(true)
    const [userInfor, setUserInfor] = useState({
        avartar: '',
        name: '',
        startTime: '',
        endTime: '',
        duaration: '',
        date: '',
    })
    const [alertConfirmJoinOpen, setAlertConfirmJoinOpen] = useState(true)
    const [loading, setLoading] = useState(false)
    const isPageVisible = usePageVisibility()
    const timerIdRef = useRef(null)
    const [triggerEncounter, setTriggerEncounter] = useState(false)
    const [modalHandle, setModalHandle] = useState(false)
    const [onClickOnModal, setOnClickOnModal] = useState(false)
    const [isPostStartTime, setIsPostStartTime] = useState(false)

    const converDataForDisplayDisplay = (infoRoom) => {
        const name = infoRoom?.provider_first_name
            ? `${infoRoom.provider_first_name} ${infoRoom.provider_last_name}`
            : `${infoRoom.patient_first_name} ${infoRoom.patient_last_name}`
        const startTime = moment(infoRoom.start).format('h:mm A')
        const endTime = moment(infoRoom.end).format('h:mm A')
        const duaration = moment
            .duration(moment(infoRoom.end).diff(moment(infoRoom.start)))
            .asMinutes()
            .toFixed()

        // setTitle(infoRoom.type)
        setUserInfor((pre) => {
            return {
                ...pre,
                name,
                startTime: startTime,
                endTime: endTime,
                duaration,
                date: moment(infoRoom.start).subtract(10, 'minute'),
                actStart: moment(infoRoom.start),
            }
        })
    }

    useEffect(() => {
        const pollingCallback = async () => {
            console.log('Checking status...')
            const res = await axios.get(
                `${config.dev.baseURLV2}/patient_portal/telehealth/sync/ptenc?encounter_id=${location?.state?.infoRoom?.encounter_id}`,
            )
            const newData = res.data
            if (newData && newData.status) {
                const enc_status = newData.status
                if (enc_status === 'EXAM') {
                    // setTriggerEncounter(true)
                    enableSession(false)
                }
            }
        }

        const startPolling = () => {
            timerIdRef.current = setInterval(pollingCallback, 15000)
        }

        const stopPolling = () => {
            console.log('Polling Stopped...')
            clearInterval(timerIdRef.current)
        }

        if (isPageVisible && location?.state?.infoRoom?.encounter_id) {
            console.log('isEmptyPageVisible, isPollingEmptyEnabled :: ', isPageVisible)
            pollingCallback()
            startPolling()
        }

        return () => {
            stopPolling()
        }
    }, [isPageVisible])

    const checkProviderLate = async () => {
        const res = await axios.get(
            `${config.dev.baseURLV2}/appointment/${location?.state?.infoRoom?.appointment_id}/is-provider-delay`,
        )
        if (res?.data?.isProviderDelay) {
            setModalHandle(true)
        }
    }

    const intervalRef = useRef(null)

    // Auto polling every 15seconds
    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

        if (!onClickOnModal) {
            intervalRef.current = setInterval(async () => {
                checkProviderLate()
            }, 15000) // 15 seconds
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [onClickOnModal])

    useEffect(() => {
        checkProviderLate()
    }, [])

    useEffect(() => {
        if (location?.state?.infoRoom) {
            converDataForDisplayDisplay(location.state.infoRoom)

            const targetDatetime = new Date(location?.state?.infoRoom?.start)
            const targetStartTime = new Date(location?.state?.infoRoom?.start)
            targetDatetime.setMinutes(targetDatetime.getMinutes() - 10)

            const currentTime = new Date()

            console.log(targetDatetime)
            console.log(currentTime)
            const targetDate = new Date(targetDatetime);
            const currentDate = new Date(currentTime)

            // setIsTenMinPrior(currentTime >= targetDatetime)
            setIsPostStartTime(currentDate >= targetDate)
        }
    }, [location])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.root}>
            {!isMobile && (
                <Box className={classes.logo}>
                    <Tms2uLogo></Tms2uLogo>
                </Box>
            )}
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    className={classes.wrapContent}>
                    <div className={classes.avatar_crop}>
                        <AvatarJoinRoomIcon
                            width={isMobile ? 200 : 273}
                            height={isMobile ? 200 : 265}
                        />
                        <img
                            className={classes.avatar_crop_icon}
                            src={AvatarDefault}
                            alt="TeleMed2U"
                        />
                        <Typography className={classes.name}>{userInfor.name}</Typography>
                    </div>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        className={classes.user_info}>
                        {!isPostStartTime && (
                            <>
                                <p>You're here early!</p>
                                Your encounter will begin in -
                                {!!userInfor.startTime && (
                                    <Countdown
                                        className={classes.time}
                                        date={moment(userInfor.date).valueOf()}
                                        daysInHours={true}
                                        onComplete={() => {
                                            setIsTenMinPrior(true)
                                        }}
                                    />
                                )}
                            </>
                        )}
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            className={classes.call_duration}>
                            <Box className={clsx(classes.info_column, classes.border)}>
                                <Typography className={classes.info_table_header}>
                                    Call duration
                                </Typography>
                                <Typography className={classes.info_table_content}>
                                    {`${userInfor.duaration} Minutes`}
                                </Typography>
                            </Box>
                            <Box className={classes.info_column}>
                                <Typography className={classes.info_table_header}>
                                    Start time - End time
                                </Typography>
                                <Typography className={classes.info_table_content}>
                                    {`${userInfor.startTime} - ${userInfor.endTime}`}
                                </Typography>
                            </Box>
                        </Grid>
                        {/* <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            className={classes.waiting_time}>
                            {location?.state?.infoRoom?.type === 'patient' ? (
                                <>
                                    <IconClock
                                        width={isMobile ? 30 : 21}
                                        height={isMobile ? 30 : 21}
                                    />
                                    <Typography className={classes.des_time}>
                                        {`Waiting for your physician to start this
                  meeting -`}
                                        {!!userInfor.startTime && (
                                            <Countdown
                                                className={classes.time}
                                                date={moment(userInfor.date).valueOf()}
                                                daysInHours={true}
                                                onComplete={() => {setIsTenMinPrior(true)}}
                                            />
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <Typography className={classes.des_time}>
                                    Please wait the meeting host will let you in soon
                                </Typography>
                            )}
                        </Grid> */}
                        {/* <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            className={classes.btn_group}>
                            <ToggleAudioButton
                                className={classes.buttons}
                                disabled={isReconnecting}
                                hideLabel
                            />
                            <ToggleVideoButton
                                className={classes.buttons}
                                disabled={isReconnecting}
                                hideLabel
                            />
                        </Grid> */}
                    </Grid>
                </Grid>

                {/* {isMobile ? <ServicesSliderMobile /> : <ServicesSlider keywords={keywordPopulus} />} */}
            </Grid>
            {/* <AlertModal /> */}
            {loading && <LoadingPage />}
            {/* {isTenMinPrior ? (
                <AlertConfirmJoin
                    open={alertConfirmJoinOpen}
                    isMobile={isMobile}
                    userInfor={userInfor}
                    handleClose={setAlertConfirmJoinOpen}
                    enableSession={enableSession}
                />
            ) : null} */}
            {triggerEncounter ? (
                <AlertConfirmEncounterJoin
                    open={alertConfirmJoinOpen}
                    isMobile={isMobile}
                    userInfor={userInfor}
                    handleClose={setAlertConfirmJoinOpen}
                    enableSession={enableSession}
                />
            ) : null}
            {modalHandle && (
                <AlertProviderLate
                    open={modalHandle}
                    handleClose={() => {
                        setModalHandle(false), setOnClickOnModal(true)
                    }}
                />
            )}
        </Grid>
    )
}
