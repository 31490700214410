import React from 'react'
import './LabResultsStyles.scss'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import { CircularProgress, Tab, Tabs, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as SortIcon } from '../../assests/icons/Sorting.svg'
import { getLabResults as labResultsApi } from '../../services/PatientHealth'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import moment from 'moment'

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: '0 8px',
        background: 'none',
        color: 'black',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        width: '100px',
        height: '35px',
        minHeight: '35px !important',
        '&.Mui-selected': {
            background: '#5571C6',
            borderRadius: '5px',
            color: 'white',
            border: '1px solid #3F62C9',
        },
    },
    wrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))((props) => <Tab {...props} />)

const dummyLabOrders = [
    {
        id: '97418',
        sponsor_name: 'LABCORP BIRMINGHAM',
        creation_datetime: '2024-01-24T01:12:00',
        provider_first_name: 'William',
        provider_middle_name: 'J',
        provider_last_name: 'Mayfield',
        provider_suffix: '',
        sponsor: '1502182',
        report_service_date: '1/29/2024 7:55 AM',
        result: ['97419', '97420'],
        requisitionUrl:
            'https://s3.amazonaws.com/s3_bucket/PatientDirectory/84879/LabOrder/141521/3008064004/1_759489170196347.html?AWSAccessKeyId=AKIA3HY7EMFCMVLWBWUH&Expires=1709283195&Signature=W%2BkZxD04GOR6GnM2KbAt0SiInqU%3D',
    },
    {
        id: '97320',
        sponsor_name: 'LABCORP BIRMINGHAM',
        creation_datetime: '2024-01-19T06:40:00',
        provider_first_name: 'William',
        provider_middle_name: 'J',
        provider_last_name: 'Mayfield',
        provider_suffix: '',
        sponsor: '1502182',
        report_service_date: '1/29/2024 7:55 AM',
        result: ['97322', '97321'],
        requisitionUrl:
            'https://s3.amazonaws.com/s3_bucket/PatientDirectory/84879/LabOrder/141376/3008063455/1_759076850194510.html?AWSAccessKeyId=AKIA3HY7EMFCMVLWBWUH&Expires=1709283195&Signature=TTakfRuBxmRfVaGYzSOGM%2BasxUE%3D',
    },
]

const LabResultsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.723 3.23125L4.13093 8.6538L7.11331 12.8294L14.7054 7.40688L11.723 3.23125ZM10.778 5.13116L10.0188 5.67341L11.9167 8.33064L12.6759 7.7884L10.778 5.13116ZM8.87995 6.48686L8.12075 7.0291L10.0187 9.68631L10.7779 9.14406L8.87995 6.48686ZM6.98174 7.84239L6.22253 8.38464L8.12044 11.0418L8.87964 10.4996L6.98174 7.84239Z"
            fill="#5571C6"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.36143 11.5847L13.4351 7.24672L20.2133 16.7368C21.4111 18.414 21.0226 20.7447 19.3454 21.9427C17.6683 23.1406 15.3375 22.752 14.1396 21.0748L7.36143 11.5847ZM8.66288 11.8017L14.8988 20.5326C15.7972 21.7905 17.5453 22.0819 18.8032 21.1834C20.0611 20.285 20.3525 18.537 19.454 17.2791L13.2181 8.54821L8.66288 11.8017Z"
            fill="#5571C6"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8863 12.5067L15.0619 9.52436L19.6711 15.9776L15.4955 18.96L10.8863 12.5067ZM12.1878 12.7237L15.7124 17.6585L18.3696 15.7607L14.845 10.8258L12.1878 12.7237Z"
            fill="#5571C6"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.8411 15.8422C13.841 15.8422 13.8412 15.8422 13.8411 15.8422C13.1361 16.0591 12.1871 16.7369 11.9431 17.1978L14.5188 20.8041C15.5669 22.2716 17.6064 22.6116 19.0739 21.5634C20.5414 20.5152 20.8814 18.4758 19.8332 17.0083L19.02 15.8697L15.6039 18.3096L13.8411 15.8422Z"
            fill="#5571C6"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.5211 17.2042L14.8988 20.5326C15.7972 21.7905 17.5452 22.0819 18.8031 21.1835C20.061 20.285 20.3524 18.537 19.454 17.2791L18.9115 16.5201L15.4955 18.96L13.672 16.4073C13.451 16.5093 13.2119 16.6495 12.9903 16.8077C12.7913 16.9499 12.6327 17.0879 12.5211 17.2042ZM12.4481 16.0485C12.8303 15.7755 13.2891 15.5236 13.7039 15.396L13.7635 15.3776L14.0758 15.3674L15.7124 17.6585L19.1285 15.2187L20.2132 16.7368C21.4111 18.414 21.0226 20.7447 19.3454 21.9427C17.6682 23.1406 15.3374 22.752 14.1395 21.0748L11.4279 17.2788L11.5111 17.0163L11.5308 16.9792C11.7058 16.6486 12.0793 16.3119 12.4481 16.0485Z"
            fill="#5571C6"
        />
    </svg>
)

const DownloadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.4629 6.12335V9.03202C10.4629 9.2681 10.361 9.48244 10.1969 9.63751L10.1961 9.63678C10.0311 9.79236 9.80463 9.88898 9.55647 9.88898H1.44375C0.944463 9.88898 0.537354 9.50408 0.537354 9.03202V6.12335H1.34761V9.03202C1.34761 9.08132 1.39183 9.12302 1.44375 9.12302H9.55636C9.58342 9.12302 9.60729 9.11324 9.6239 9.09744V9.09598C9.6415 9.07934 9.6525 9.05667 9.6525 9.03202V6.12335H10.4629ZM5.78853 7.48044L7.48572 5.87583V4.79256L5.90524 6.28684V0.887988C5.90524 0.383692 5.09498 0.383692 5.09498 0.887988V6.28684L3.5145 4.79267V5.87583L5.21169 7.48044C5.37207 7.63312 5.63013 7.63197 5.78853 7.48044Z"
            fill="#5571C6"
        />
    </svg>
)

function Main() {
    const history = useHistory()
    const notification = useNotification()

    const [labResults, setLabResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [sort, setSort] = React.useState(false)

    const flattendata = (data) => {
        if (!data || data.length === 0) return []

        return data.map((item) => {
            return {
                title: item?.sponsor_name,
                order_date: item?.creation_datetime
                    ? moment(item?.creation_datetime).format('MM/DD/YYYY')
                    : 'N/A',
                result_date: item?.report_service_date
                    ? moment(item?.report_service_date).format('MM/DD/YYYY')
                    : 'N/A',
                id: item?.id,
                download_url: item?.requisitionUrl,
            }
        })
    }

    const getLabResults = (type) => {
        setLoading(true)
        const payload = {
            page: 1,
            limit: 100,
        }
        labResultsApi(payload)
            ?.then((res) => {
                const responseData = res?.data?.data
                setLabResults(flattendata(responseData))
            })
            .catch(() => {
                notification('Something went wrong!', 'error')
            })
            .finally(() => setLoading(false))
    }

    const goToPage = () => history.push('/my-health-record')

    const handleSort = () => {
        setSort(!sort)
        labResults?.reverse()
    }

    React.useEffect(() => {
        getLabResults()
    }, [])

    return (
        <div className="lab_result_main_container">
            <div className="lab_result_sub_container">
                <div className="lab_result_back_container" onClick={goToPage}>
                    <BackIcon />
                    <Typography>Back to health record</Typography>
                </div>
                <div className="lab_result_body">
                    <div className="lab_result_body_header">
                        <LabResultsIcon />
                        <Typography>Lab Results</Typography>
                    </div>
                    <div className="lab_results_body_contents">
                        <div className="lab_results_tab_contents">
                            <div className="lab_results_tab_content_third_row">
                                <span className="lab_results_tab_content_count">
                                    {loading ? (
                                        <CircularProgress
                                            style={{
                                                color: '#667689',
                                                height: 15,
                                                width: 15,
                                                marginRight: 3,
                                            }}
                                        />
                                    ) : (
                                        <Typography>{labResults?.length}</Typography>
                                    )}
                                    <Typography>Lab results</Typography>
                                </span>
                                <span
                                    onClick={handleSort}
                                    style={{
                                        pointerEvents:
                                            loading || labResults?.length === 0 ? 'none' : 'auto',
                                    }}
                                    className="lab_result_tab_content_sorting">
                                    <SortIcon />
                                    <Typography>
                                        {sort ? 'Oldest to newest' : 'Newest to oldest'}
                                    </Typography>
                                </span>
                            </div>

                            {loading ? (
                                <div className="lab_result_cards_loader">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="lab_results_card_container">
                                    {labResults?.length > 0 &&
                                        labResults?.map((medicalProblemItem) => {
                                            return (
                                                <div
                                                    key={medicalProblemItem?.id}
                                                    className="lab_results_cards">
                                                    <div className="lab_results_cards_contents">
                                                        <Typography
                                                            style={{
                                                                color: '#303E4E',
                                                                fontSize: 17,
                                                                fontWeight: 500,
                                                            }}>
                                                            {medicalProblemItem?.title}
                                                        </Typography>
                                                        <div className="lab_results_date_fields">
                                                            <span>
                                                                <Typography
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: 14,
                                                                        fontWeight: 400,
                                                                    }}>
                                                                    Results Received
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        color: '#303E4E',
                                                                        fontSize: 16,
                                                                        fontWeight: 400,
                                                                    }}>
                                                                    {
                                                                        medicalProblemItem?.result_date
                                                                    }
                                                                </Typography>
                                                            </span>
                                                            <span>
                                                                <Typography
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: 14,
                                                                        fontWeight: 400,
                                                                    }}>
                                                                    Order Date
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        color: '#303E4E',
                                                                        fontSize: 16,
                                                                        fontWeight: 400,
                                                                    }}>
                                                                    {medicalProblemItem?.order_date}
                                                                </Typography>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="lab_results_card_type">
                                                        <DownloadIcon />
                                                        <Typography>Download results</Typography>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
