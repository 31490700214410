import React from 'react'
import { GridOverlay } from '@mui/x-data-grid'
import { Typography } from '@material-ui/core'

export default function NoRowsOverlayEl(props) {
    return (
        <GridOverlay>
            <Typography>{props.title}</Typography>
        </GridOverlay>
    )
}
