import { Grid } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useFormik } from 'formik'
import React from 'react'
import closeIcon from '../../../assests/images/icon_close.svg'
import { WorkListStatus } from '../utils'
import './styles.scss'

const CustomTextField = withStyles({
    root: {},
})(TextField)

const PrimaryButton = withStyles((theme) => ({
    root: {
        color: '#FFF',
        backgroundColor: theme.palette.primary,
        width: '160px',
    },
}))(Button)

export function MarkStatusModal(props) {
    const parentClass = 'worklist'

    const formik = useFormik({
        initialValues: {
            status: props.status,
            comments: '',
        },
        onSubmit: props.onSubmit,
    })

    React.useEffect(() => {
        if (props.status)
            formik.setFieldValue('status', props.status)
    }, [props.status])

    return (
        <Modal open={props.opened} onClose={props.onClose}>
            <div
                className={`${parentClass}__modal__container`}>
                <div
                    className={`${parentClass}__modal__header`}>
                    <Typography
                        id="modal-modal-title"
                        className="worklist__modal__title"
                        variant="inherit"
                        component="h3">
                        <strong>Mark Worklist</strong>
                    </Typography>

                    <img
                        className={`${parentClass}__modal__cancel-btn`}
                        src={closeIcon}
                        role="presentation"
                        onClick={props.onClose}
                        alt="close-icon"
                    />
                </div>
                <div
                    className={`${parentClass}__modal__body`}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            spacing={4}
                            alignItems="center"
                            justifyContent="center">
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        color: '#516983',
                                        paddingBottom:
                                            '8px',
                                    }}>
                                    <strong>Status</strong>
                                </Typography>
                                <FormControl
                                    style={{
                                        width: '100%',
                                        border: '1px solid $secondaryGray',
                                        paddingTop: '8px',
                                        paddingBottom:
                                            '8px',
                                        borderRadius: '4px',
                                    }}>
                                    <Select
                                        id="status"
                                        aria-label="status"
                                        name="status"
                                        className="ba-input-fields"
                                        disableUnderline
                                        displayEmpty
                                        disableUnderline
                                        onChange={
                                            formik.handleChange
                                        }
                                        value={
                                            formik.values
                                                .status
                                        }>
                                        {WorkListStatus.map(
                                            (el) => (
                                                <MenuItem
                                                    value={
                                                        el.key
                                                    }
                                                    key={
                                                        el.value
                                                    }
                                                    className="pl-select-option">
                                                    {
                                                        el.value
                                                    }
                                                </MenuItem>
                                            ),
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    style={{
                                        width: '100%',
                                    }}>
                                    <Typography
                                        style={{
                                            color: '#516983',
                                            paddingBottom:
                                                '8px',
                                        }}>
                                        <strong>
                                            Comments
                                        </strong>
                                    </Typography>
                                    <CustomTextField
                                        id="comments"
                                        aria-label="comments"
                                        name="comments"
                                        hiddenLabel
                                        size="medium"
                                        variant="outlined"
                                        onChange={
                                            formik.handleChange
                                        }
                                        inputProps={{
                                            maxLength: 500,
                                        }}
                                        helperText="Max 500 characters only"
                                        autoFocus
                                        multiline
                                        rows={6}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div
                            className={`${parentClass}__modal__footer`}>
                            <PrimaryButton
                                variant="contained"
                                color="primary"
                                style={{
                                    justifyContent:
                                        'center',
                                }}
                                type="submit">
                                Submit
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
