import { Box, Grid, Tab, Tabs } from '@material-ui/core'
import './HealthAccountStyle.scss'
import { withStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import AccountPreferences from './AccountPreferences'
import record_book from '../../../assests/images/record_book.svg'
import insurance_icon from '../../../assests/images/isnurance.svg'
import medication_icon from '../../../assests/images/medication_icon.svg'
import hand_icon from '../../../assests/images/hand_icon.svg'
import vital_sign from '../../../assests/images/vital_sign.svg'
import labresult_icon from '../../../assests/images/labresult_icon.svg'
import clinical_notes from '../../../assests/images/clinical_notes.svg'
import contacts from '../../../assests/images/contacts.svg'
import { useHistory } from 'react-router-dom'
import Routes from '../../../constants/routes'
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '& .MuiTab-root': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                // fontWeight: 500,
                fontFamily: 'Rubik',
                borderBottom: `4px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                color: '#242424 !important',
            },
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                fontWeight: 600,
                fontFamily: 'Rubik',
                borderBottom: `4px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                color: '#242424',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)

// const TabPanel = (props) => {
//     const { children, value, index, ...other } = props

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}>
//             {value === index && (
//                 <Box sx={{ p: 3, padding: '10px 0 0 0', marginBottom: 10 }}>{children}</Box>
//             )}
//         </div>
//     )
// }
const DataCardObject = [
    {
        icon: record_book,
        headerlabel: 'Health conditions',
        lable2: 'View your medical problems ',
        redirect: Routes.HEALTH_CONDITIONS,
    },
    {
        icon: medication_icon,
        headerlabel: 'Medications',
        lable2: 'View your medications list',
        redirect: Routes.MEDICATIONS,
    },
    {
        icon: hand_icon,
        headerlabel: 'Medication Allergies',
        lable2: 'View your medication allergies',
        redirect: Routes.MEDICATION_ALLERRGIES,
    },
    {
        icon: vital_sign,
        headerlabel: 'Vital Signs',
        lable2: 'View your vital signs ',
    },
    {
        icon: labresult_icon,
        headerlabel: 'Lab Results',
        lable2: 'View your lab results',
        redirect: Routes.LAB_RESULTS,
    },
    {
        icon: clinical_notes,
        headerlabel: 'Clinical Notes',
        lable2: 'View your appointment notes',
        redirect: Routes.CLINICAL_NOTES_VIEW.VIEW_LIST,
    },
    {
        icon: contacts,
        headerlabel: 'Contacts',
        lable2: 'View your contacts list',
        redirect: Routes.CONTACTS,
    },
    {
        icon: insurance_icon,
        headerlabel: 'Insurance',
        lable2: 'View your insurance list',
        redirect: Routes.PATIENT_INSURANCE,
    },
]

const AdditionalDataCard = ({ cardContent }) => {
    const history = useHistory()
    return (
        <div onClick={() => (cardContent.redirect ? history.push(cardContent.redirect) : '')}>
            <Grid className="main-data-card-container">
                <Grid className="main-content">
                    <img src={cardContent.icon} height={16} width={16} />{' '}
                    <span className="label-container">
                        <span className="big-label">{cardContent.headerlabel}</span>
                        <span className="small-label">{cardContent.lable2}</span>
                    </span>
                </Grid>
            </Grid>
        </div>
    )
}

const HealthAccount = ({ isMenu }) => {
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box sx={{ p: 3, padding: '10px 0 0 0', marginBottom: 10 }}>{children}</Box>
                )}
            </div>
        )
    }
    const [value, setValue] = useState(isMenu ? 1 : 0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    return (
        <Grid className="main-heath-account-container">
            <Grid item xs={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                    <CustomTab
                        className="tabText"
                        style={{
                            background: 'none',
                            textTransform: 'capitalize',
                            fontFamily: 'Rubik',
                            fontWeight: 400,
                            color: '#242424',
                        }}
                        label="Health Record"
                    />
                    <CustomTab
                        className="tabText"
                        style={{
                            background: 'none',
                            textTransform: 'capitalize',
                            fontFamily: 'Rubik',
                            fontWeight: 400,
                            color: '#242424',
                        }}
                        label="Account Preferences"
                    />
                </Tabs>
                <TabPanel value={value} index={1}>
                    <AccountPreferences />
                </TabPanel>
                <TabPanel value={value} index={0}>
                    <Grid className="card-wrapper" xs={12}>
                        {DataCardObject.map((cardcontent, idx) => (
                            <AdditionalDataCard key={idx} cardContent={cardcontent} />
                        ))}
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    )
}

export default HealthAccount
