import React, { useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Typography, CircularProgress, Box } from '@material-ui/core'
import { ReactComponent as BackIcon } from '../../assests/icons/Back.svg'
import { useHistory } from 'react-router-dom'
import './ClinicalNotesStyles.scss'
import contactsIcon from '../../assests/images/contacts.svg'
import { useSelector } from 'react-redux'

function Main() {
    const history = useHistory()
    const EmergencyContacts = useSelector((state) => state?.login?.userData)
    const [emergencyContacts, setEmergencyContact] = useState([])
    useEffect(() => {
        EmergencyContacts?.data
            ? setEmergencyContact(EmergencyContacts?.data?.emergency_contacts)
            : setEmergencyContact(EmergencyContacts?.emergency_contacts)
    }, [EmergencyContacts])

    const BellIcon = () => (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="18"
                viewBox="0 0 10 9"
                fill="none">
                <path
                    d="M7.00694 5.73512L6.9632 5.7755C6.78823 5.94547 6.68543 6.17068 6.6723 6.41077C6.6541 6.54467 6.53474 6.58364 6.42953 6.54675C5.2067 6.09793 3.60954 4.54501 3.14883 3.35971C3.1127 3.25656 3.15043 3.14227 3.28877 3.1239C3.5581 3.11231 3.80273 2.98959 3.98647 2.80095L1.59372 0.476562L1.55216 0.519052C-0.934867 3.25932 3.96609 8.849 7.55594 8.74358C8.30056 8.74436 8.93606 8.52135 9.39752 8.05738C9.39752 8.05739 7.00694 5.73512 7.00694 5.73512Z"
                    fill="#D22121"
                />
            </svg>
        </>
    )

    return (
        <div>
            <div className="medication_main_container">
                <div className="medication_sub_container">
                    <div
                        className="medication_back_container"
                        onClick={() => history.push('/my-health-record')}>
                        <BackIcon />
                        <Typography>Back to health record</Typography>
                    </div>
                    <div className="medication_body">
                        <div className="medication_body_header">
                            <img src={contactsIcon} alt="clinical_report" height={21} width={17} />
                            <Typography>Contacts</Typography>
                        </div>
                        <div className="medications_body_contents">
                            <div className="medications_tab_contents">
                                <div className="medications_tab_content_third_row">
                                    <span className="medications_tab_content_count">
                                        <Typography>
                                            {emergencyContacts?.filter(
                                                (con) => con.related_person_name,
                                            )?.length || 0}
                                        </Typography>

                                        <Typography> Contact(s)</Typography>
                                    </span>
                                </div>

                                <div className="medications_card_container">
                                    {emergencyContacts?.length > 0 &&
                                        emergencyContacts.map((contact) => {
                                            return (
                                                contact?.related_person_name && (
                                                    <div
                                                        key={contact?.id}
                                                        className="medications_cards">
                                                        <div className="medications_cards_contents">
                                                            <Typography
                                                                style={{
                                                                    color: '#303E4E',
                                                                    fontSize: 17,
                                                                    fontWeight: 500,
                                                                }}>
                                                                {contact?.related_person_name ||
                                                                    'N/A'}
                                                                -
                                                                <span
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: 15,
                                                                        fontWeight: 400,
                                                                    }}>
                                                                    &nbsp;
                                                                    {contact?.relation || 'N/A'}
                                                                </span>
                                                            </Typography>
                                                            <div className="medications_body_fields">
                                                                <div className="medications_body_fields_part">
                                                                    <span>
                                                                        <Typography
                                                                            style={{
                                                                                color: '#667689',
                                                                                fontSize: 14,
                                                                                fontWeight: 400,
                                                                            }}>
                                                                            Phone Number
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{
                                                                                color: '#303E4E',
                                                                                fontSize: 16,
                                                                                fontWeight: 400,
                                                                            }}>
                                                                            {
                                                                                contact?.[
                                                                                    'related_person_phone'
                                                                                ]
                                                                            }
                                                                        </Typography>
                                                                    </span>
                                                                    {contact?.related_person_email && (
                                                                        <span>
                                                                            <Typography
                                                                                style={{
                                                                                    color: '#667689',
                                                                                    fontSize: 14,
                                                                                    fontWeight: 400,
                                                                                }}>
                                                                                Email
                                                                            </Typography>

                                                                            <Typography
                                                                                style={{
                                                                                    color: '#303E4E',
                                                                                    fontSize: 16,
                                                                                    fontWeight: 400,
                                                                                    textTransform:
                                                                                        'lowercase',
                                                                                }}>
                                                                                {
                                                                                    contact?.related_person_email
                                                                                }
                                                                            </Typography>
                                                                        </span>
                                                                    )}
                                                                </div>

                                                                {['1', 'true', true]?.includes(
                                                                    contact?.is_emergency_contact,
                                                                ) ? (
                                                                    <div
                                                                        className="medications_body_fields_part"
                                                                        style={{
                                                                            justifyContent:
                                                                                'center',
                                                                            alignItems: 'baseline',
                                                                        }}>
                                                                        <div className="lab_results_card_type">
                                                                            <BellIcon />
                                                                            <Typography
                                                                                style={{
                                                                                    color: '#D22121',
                                                                                    fontFamily:
                                                                                        'Rubik',
                                                                                    fontSize: 16,
                                                                                    fontWeight: 400,
                                                                                }}>
                                                                                Emergency Contact
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main
