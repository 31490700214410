import React, { Component } from 'react'
import {
    CircularProgress,
    LinearProgress,
} from '@material-ui/core'

import './loader.scss'

export const LoaderType = Object.freeze({
    LINEAR: 'linear',
    CIRCULAR: 'circular',
})
class LoadingPage extends Component {
    render() {
        const { type } = this.props

        if (type === LoaderType.LINEAR) {
            return (
                <div className="linear-loader">
                    <LinearProgress
                        style={{ width: '100%' }}
                    />
                </div>
            )
        }

        return (
            <div className="loaderWrapper">
                <div className="loader">
                    <CircularProgress />
                    {/* <small> Loading </small> */}
                </div>
            </div>
        )
    }
}

export default LoadingPage
