import { LocalAudioTrack } from 'twilio-video'
import { useCallback } from 'react'
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled'
import useVideoContext from '../useVideoContext/useVideoContext'

export default function useLocalAudioToggle() {
    const { localTracks, getLocalAudioTrack, onError } = useVideoContext()
    const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack
    const isEnabled = useIsTrackEnabled(audioTrack)

    const toggleAudioEnabled = useCallback(() => {
        if (audioTrack) {
            audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable()
        } else {
            //create an audio track if unavailable
            getLocalAudioTrack()
                .then((track: LocalAudioTrack) => {
                    console.log('LocalAudioTrack :: ', track)
                })
                .catch(onError)
        }
    }, [audioTrack])

    return [isEnabled, toggleAudioEnabled] as const
}
