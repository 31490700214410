import {
    Grid,
    IconButton,
    InputAdornment,
    Popper,
    TextField,
    Typography,
} from '@material-ui/core'
import moment from 'moment'
import {
    createStyles,
    makeStyles,
} from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import SearchIcon from '../../assests/icons/search_icon.svg'
import CalendarIcon from '../../assests/icons/CalendarIcon'
import { UserRoles } from '../../constants/user-roles'
import {
    DateTimePickerEl,
    DropdownEl,
} from '../../shared/elements'
import { getLoggedInUserRole } from '../../utilities'

const useStyles = makeStyles({
    notchedOutline: {
        borderColor: `#E9ECF1 !important`,
    },
    search_input_root: {
        width: 320,
        background: '#FBFCFC',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '&:focus': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '& .MuiOutlinedInput-inputAdornedEnd': {
            fontSize: 16,
            color: '#303E4E !important',
        },
    },
    date_input: {
        background: '#FBFCFC',
        height: 40,
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    heading: {
        fontSize: '24px',
        color: '#303E4E',
    },
})

const useAutoCompleteStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiAutocomplete-listbox': {
                '& li': {
                    marginLeft: '8px',
                    marginRight: '8px',
                    marginTop: '10px',
                    marginBottom: '10px',
                },
            },
        },
    }),
)

const CustomPopper = function (props) {
    const classes = useAutoCompleteStyles()
    return (
        <Popper
            {...props}
            className={classes.root}
            placement="bottom"
        />
    )
}

const clinicalNoteStatus = [
    { key: 'all', name: 'All', value: 'All', amount: 5 },
    {
        key: 'Pending',
        name: 'Pending Approval',
        value: 'Pending Approval',
        amount: 5,
    },
    {
        key: 'draft',
        name: 'Draft',
        value: 'Draft',
        amount: 5,
    },
    {
        key: 'locked',
        name: 'Locked',
        value: 'Lock',
        amount: 5,
    },
    {
        key: 'resent_to_edit',
        name: 'Resent to Edit',
        value: 'resent_to_edit',
        amount: 5,
    },
    {
        key: 'approved_lock',
        name: 'Approved & Locked',
        value: 'approved_lock',
        amount: 5,
    },
]

const ClinicalNotesFilter = ({
    userId,
    searchData,
    onSearch,
}) => {
    const classes = useStyles()

    const [isLoading, setLoading] = useState(false)
    const [filterData, setFilterData] = useState({
        status: 'All',
    })

    const userRole = getLoggedInUserRole()

    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
    ].includes(userRole)

    const onChangeFilterData = (key, value) => {
        setFilterData((prev) => ({
            ...prev,
            [key]: value,
        }))
        onSearch({
            ...filterData,
            [key]: value,
        })
    }
    useEffect(() => {
        setFilterData(searchData)
    }, [searchData])
    return (
        <Grid
            container
            // className={classes.clinical_notes_container}
            alignItems="center">
            <Grid item style={{ flex: 1 }}>
                <Typography className={classes.heading}>
                    Clinical Notes List
                </Typography>
            </Grid>
            <Grid
                container
                spacing={2}
                style={{
                    marginLeft: 8,
                    width: 'fit-content',
                }}>
                <Grid item>
                    {' '}
                    <TextField
                        placeholder="Search..."
                        onChange={(e) =>
                            onChangeFilterData(
                                'searchText',
                                e.target.value,
                            )
                        }
                        value={filterData.searchText}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton size="small">
                                        <img
                                            src={SearchIcon}
                                            alt=""></img>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    {filterData.searchText && (
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                onChangeFilterData(
                                                    'searchText',
                                                    '',
                                                )
                                            }>
                                            <CloseIcon
                                                style={{
                                                    fontSize: 16,
                                                    color: '#303E4E',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                            classes: {
                                notchedOutline:
                                    classes.notchedOutline,
                                root: classes.search_input_root,
                            },
                        }}
                    />
                </Grid>
                <Grid item style={{ minWidth: 240 }}>
                    <DropdownEl
                        value={filterData.status}
                        options={clinicalNoteStatus}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        renderValue={(selected) =>
                            `Status: ${
                                clinicalNoteStatus.find(
                                    (item) =>
                                        item.key ===
                                        (selected ||
                                            filterData.status),
                                )?.value || 'All'
                            }`
                        }
                        onChange={(e) => {
                            onChangeFilterData(
                                'status',
                                e.value,
                            )
                        }}
                    />
                </Grid>
                <Grid item style={{ width: 240 }}>
                    <DateTimePickerEl
                        fullWidth
                        // emptyLabel="Date"
                        name="end_date"
                        format="MMM YYYY"
                        placeholder="End"
                        value={moment(filterData.date, 'X')}
                        onChange={(e) => {
                            onChangeFilterData(
                                'date',
                                e.unix(),
                            )
                        }}
                        labelFunc={(e) =>
                            `Date: ${e.format('MMM YYYY')}`
                        }
                        views={['year', 'month']}
                        InputProps={{
                            readOnly: true,
                            classes: {
                                notchedOutline:
                                    classes.notchedOutline,
                                root: classes.date_input,
                            },
                        }}
                        keyboardIcon={<CalendarIcon />}
                        // InputProps={{ }}
                    />
                </Grid>

                {/* <TextInput clinicalNoteStatus={clinicalNoteStatus} /> */}

                {/* <Autocomplete
                    freeSolo
                    id="search"
                    options={searchData}
                    getOptionLabel={(option) =>
                        option?.first_name
                            ? `${option.first_name} ${option.last_name}`
                            : ''
                    }
                    disableClearable
                    onInputChange={onTextChange}
                    onChange={onOptionSelect}
                    PopperComponent={CustomPopper}
                    renderOption={(option) => {
                        return (
                            <Grid
                                container
                                direction="column">
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        className={
                                            classes.capitalize
                                        }>
                                        <strong>{`${option.first_name} ${option.last_name}`}</strong>
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={
                                            classes.capitalize
                                        }>
                                        {moment(
                                            option.dob,
                                        ).format(
                                            'MM/DD/YYYY',
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={
                                'Search by Patient Name'
                            }
                            onChange={(e) =>
                                onSearch(e.target.value)
                            }
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: (
                                    <InputAdornment>
                                        <IconButton size="small">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused:
                                        classes.cssFocused,
                                    notchedOutline:
                                        classes.notchedOutline,
                                },
                            }}
                            variant="outlined"
                            autoFocus
                            autoComplete={false}
                        />
                    )}
                /> */}
            </Grid>
        </Grid>
    )
}

export default ClinicalNotesFilter
