/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react'
import Draggable from 'react-draggable'
import BtnIconTop from './compoment/BtnIconTop'
import {
    IconAddParticipant,
    IconChat,
    IconEndCall,
    IconListParticipant,
    IconMicSvg,
    IconMinisize,
    IconVideoSvg,
    IconSound,
    IconMuteMic,
    IconDisableVideo,
} from './icon'
import imageParticipant from './icon/img_participant.png'
import { connect, useDispatch, useSelector } from 'react-redux'
import { handleOpenListConsultation, postEndRoom } from '../../store/actions/provider'
import VideoParticipant from './videoParticipant'
import ChatWindowProvider from '../Consultation/components/ChatWindowProvider/ChatWindowProvider'
import AddParticipant from './AddParticipant'
import { ListItem, makeStyles } from '@material-ui/core'
import { render } from 'react-dom'
import { first } from 'lodash'

const Participant = ({
    // participant,
    // lisParticipants,
    // toggleVideo,
    // toggleMute,
    room,
}) => {
    // const [videoTracks, setVideoTracks] = useState([])
    // const [audioTracks, setAudioTracks] = useState([])
    // const [videoLocal, setVideoLocal] =
    //     useState(participant)
    // const [
    //     lisParticipantsNotPatient,
    //     setlisParticipantsNotPatient,
    // ] = useState([])
    const [styles, setStyles] = useState({})
    const [isActive, setActive] = useState(null)
    const [disabledVideo, setDisabledVideo] = useState(false)
    const [isMute, setMute] = useState(false)
    const [isDisplayAddParticipant, setIsDisplayAddParticipant] = useState(false)

    const dispatch = useDispatch()
    const videoRef = useRef()
    const audioRef = useRef()

    const useStyles = makeStyles({
        avatar: {
            maxWidth: 550,
            width: 533,
            height: 400,
            borderRadius: 16,
            background: 'gray',
        },
        avatarMiniSize: {
            width: 120,
            height: 120,
            borderRadius: 16,
            background: 'gray',
        },
        listItem: {
            width: 120,
            height: 120,
        },
    })

    // const trackpubsToTracks = (trackMap) =>
    //     Array.from(trackMap.values())
    //         .map((publication) => publication.track)
    //         .filter((track) => track !== null)

    // useEffect(() => {
    //     setVideoTracks(
    //         trackpubsToTracks(videoLocal.videoTracks),
    //     )
    //     setAudioTracks(
    //         trackpubsToTracks(videoLocal.audioTracks),
    //     )

    //     const trackSubscribed = (track) => {
    //         if (track.kind === 'video') {
    //             setVideoTracks((videoTracks) => [
    //                 ...videoTracks,
    //                 track,
    //             ])
    //         } else if (track.kind === 'audio') {
    //             setAudioTracks((audioTracks) => [
    //                 ...audioTracks,
    //                 track,
    //             ])
    //         }
    //     }

    //     const trackUnsubscribed = (track) => {
    //         if (track.kind === 'video') {
    //             setVideoTracks((videoTracks) =>
    //                 videoTracks.filter((v) => v !== track),
    //             )
    //         } else if (track.kind === 'audio') {
    //             setAudioTracks((audioTracks) =>
    //                 audioTracks.filter((a) => a !== track),
    //             )
    //         }
    //     }

    //     videoLocal.on('trackSubscribed', trackSubscribed)
    //     videoLocal.on(
    //         'trackUnsubscribed',
    //         trackUnsubscribed,
    //     )

    //     return () => {
    //         setVideoTracks([])
    //         setAudioTracks([])
    //         videoLocal.removeAllListeners()
    //     }
    // }, [videoLocal])

    // useEffect(() => {
    //     const videoTrack = videoTracks[0]
    //     if (videoTrack) {
    //         videoTrack.attach(videoRef.current)
    //         return () => {
    //             videoTrack.detach()
    //         }
    //     }
    // }, [videoTracks, disabledVideo])

    // useEffect(() => {
    //     const audioTrack = audioTracks[0]
    //     if (audioTrack) {
    //         audioTrack.attach(audioRef.current)
    //         return () => {
    //             audioTrack.detach()
    //         }
    //     }
    // }, [audioTracks])

    // useEffect(() => {
    //     console.log(666, lisParticipants)
    //     if (lisParticipants.length > 1) {
    //         const firstElement = lisParticipants.shift()
    //         console.log(123, firstElement, lisParticipants)
    //         setlisParticipantsNotPatient(lisParticipants)
    //     } else {
    //         setlisParticipantsNotPatient([])
    //     }
    // }, [lisParticipants])

    const [waitingParticipants, setWaitingParticipants] = useState([])
    const [participants, setParticipants] = useState([])

    useEffect(() => {
        const participantConnected = (participant) => {
            console.log('participantConnected', participant)
            setParticipants((prevParticipants) =>
                [...prevParticipants, participant].filter((item) => item.sid !== participant.sid),
            )
        }

        const participantDisconnected = (participant) => {
            setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant))
        }

        room.on('participantConnected', participantConnected)
        room.on('participantDisconnected', participantDisconnected)
    }, [room])

    const participantConnected = (participant) => {
        console.log('participant', participant)
        setParticipants((prevParticipants) => [...prevParticipants, participant])
    }

    const participantDisconnected = (participant) => {
        setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant))
    }

    const fetchingWaitingList = () => {
        setWaitingParticipants(
            waitingParticipants.filter((item) => !item.isAdmitted && !item.isRejected),
        )
    }

    useEffect(() => {
        room.on('participantConnected', participantConnected)
        room.on('participantDisconnected', participantDisconnected)
    }, [room])

    const toggleVideo = (isActive) => {
        // Disabled local video track
        if (!isActive) {
            room.localParticipant.videoTracks.forEach((publication) => {
                publication.track.disable()
            })
        } else {
            room.localParticipant.videoTracks.forEach((publication) => {
                publication.track.enable()
            })
        }
    }

    const toggleMute = (isActive) => {
        // Disabled local video track
        if (!isActive) {
            room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.disable()
            })
        } else {
            room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.enable()
            })
        }
    }

    const _addClass = (e) => {
        setActive(e.type === 'click' ? true : false)
    }

    const _removeClass = (e) => {
        setActive(e.type !== 'click' ? true : false)
    }

    const _openListConsultation = (item) => {
        dispatch(handleOpenListConsultation(item))
    }

    const openListConsultation = useSelector((state) => state.provider.openListConsultation)

    const localParticipant = (
        <VideoParticipant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            isMute={isMute}
            isActive={isActive}
            disabledVideo={disabledVideo}
        />
    )

    const localParticipantPin = (
        <VideoParticipant
            isCheck={true}
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            isMute={isMute}
            isActive={isActive}
            disabledVideo={disabledVideo}
        />
    )

    const toggleAddParticipantPanel = () => {
        setIsDisplayAddParticipant(!isDisplayAddParticipant)
    }

    const toggleDisableVideo = () => {
        setDisabledVideo(!disabledVideo)
        toggleVideo(disabledVideo)
    }

    const toggDisableleMute = () => {
        setMute(!isMute)
        toggleMute(isMute)
    }

    const endRoom = () => {
        let encounterIdProvider = localStorage.getItem('encounterIdProvider')
        dispatch(postEndRoom(encounterIdProvider))
    }

    const classes = useStyles()

    const encounterQueueList = useSelector((state) => state.provider.encounterQueueList?.arrPatien)

    const identity = participants.map((x) => console.log(33, x.identity))
    const phoneNunber = encounterQueueList?.map((x) => console.log(4444, x.phone_number))

    const isPatient = identity === phoneNunber

    console.log(2, participants)
    console.log(55, [...participants.slice(0, participants.length - 1)])
    // console.log(666, participants.filter(data, index) => )
    // console.log(
    //     'lisParticipantsNotPatient',
    //     lisParticipantsNotPatient,
    // )

    const renderRemoteParticipant = (participantRemote, index) => {
        return (
            <button
                onClick={(e) => _removeClass(e)}
                class={`remote-participants-list ${isActive ? 'active' : ''}`}>
                <ListItem key={index} className={`${classes.listItem} `}>
                    <VideoParticipant
                        key={participantRemote.sid}
                        participant={participantRemote}
                        isCheck={true}
                        isMute={isMute}
                        isActive={isActive}
                        disabledVideo={disabledVideo}
                    />
                    <div className={`mini-size-icon ${isActive ? 'active' : ''}`}>
                        <IconSound />
                    </div>
                </ListItem>
            </button>
        )
    }

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <Draggable className="drag-react" bounds="parent">
                <div className={`participant ${isActive ? 'active' : ''}`}>
                    <div className="listParticipant-minisize">
                        {participants.length > 0 && (
                            <button
                                onClick={(e) => _removeClass(e)}
                                class={`remote-participants ${isActive ? 'active' : ''}`}>
                                {localParticipant}
                                <div className={`mini-size-icon ${isActive ? 'active' : ''}`}>
                                    <IconSound />
                                </div>
                            </button>
                        )}
                    </div>

                    {participants
                        .filter((_, index) => index !== 0)
                        .map((participantRemote, index) => (
                            <button
                                onClick={(e) => _removeClass(e)}
                                class={`remote-participants-list ${isActive ? 'active' : ''}`}>
                                <ListItem key={index} className={`${classes.listItem} `}>
                                    <VideoParticipant
                                        key={participantRemote.sid}
                                        participant={participantRemote}
                                        isCheck={true}
                                        isMute={isMute}
                                        isActive={isActive}
                                        disabledVideo={disabledVideo}
                                    />
                                    <div className={`mini-size-icon ${isActive ? 'active' : ''}`}>
                                        <IconSound />
                                    </div>
                                </ListItem>
                            </button>
                        ))}

                    <button
                        onClick={(e) => _removeClass(e)}
                        class={`localParticipant-minisize remote-participants ${
                            isActive ? 'active' : ''
                        }`}>
                        {!disabledVideo ? (
                            localParticipant
                        ) : (
                            <div className="pictures-avatar">
                                <img
                                    src={
                                        'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                                    }
                                    alt="Profile"
                                    className={classes.avatarMiniSize}
                                />
                            </div>
                        )}
                        <div className={`mini-size-icon ${isActive ? 'active' : ''}`}>
                            <IconSound />
                        </div>
                    </button>
                    <div className={`view-mini-size ${isActive ? 'active' : ''}`}>
                        <IconSound />
                    </div>
                    <div className={`view-top-bar ${isActive ? 'active' : ''}`}>
                        <div className="list-participant view-background-top-bar">
                            <BtnIconTop
                                background={openListConsultation && 'rgba(85, 113, 198, 0.9)'}
                                Icon={<IconListParticipant />}
                                onPress={() =>
                                    _openListConsultation({
                                        open: true,
                                    })
                                }
                            />
                        </div>
                        <div className="minimize view-background-top-bar">
                            <BtnIconTop Icon={<IconMinisize />} onPress={(e) => _addClass(e)} />
                        </div>
                    </div>
                    <div className={`video-participant ${isActive ? 'active' : ''}`}>
                        {participants.length > 0 ? (
                            <>
                                {participants
                                    .filter((_, index) => index === 0)
                                    .map((participantRemote, index) => (
                                        <VideoParticipant
                                            key={participantRemote.sid}
                                            participant={participantRemote}
                                            isCheck={true}
                                            isMute={isMute}
                                            isActive={isActive}
                                            disabledVideo={disabledVideo}
                                        />
                                    ))}
                            </>
                        ) : (
                            <>
                                {!disabledVideo ? (
                                    localParticipantPin
                                ) : (
                                    <div className="pictures-avatar">
                                        <img
                                            src={
                                                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                                            }
                                            alt="Profile"
                                            className={classes.avatar}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {isDisplayAddParticipant && <AddParticipant />}
                    <div className={`view-button-bar ${isActive ? 'active' : null}`}>
                        <div className="micro view-background-button-bar">
                            <button className="button-bar" onClick={() => toggDisableleMute()}>
                                {!isMute ? <IconMicSvg /> : <IconMuteMic />}
                            </button>
                        </div>
                        <div className="video view-background-button-bar">
                            <button className="button-bar" onClick={() => toggleDisableVideo()}>
                                {!disabledVideo ? <IconVideoSvg /> : <IconDisableVideo />}
                            </button>
                        </div>
                        <div className="endCall view-background-button-bar">
                            <button className="button-bar btn-end-call" onClick={() => endRoom()}>
                                <IconEndCall />
                            </button>
                        </div>
                        <div className="addParticipant view-background-button-bar">
                            <button
                                className="button-bar"
                                onClick={() => toggleAddParticipantPanel()}>
                                <IconAddParticipant />
                            </button>
                        </div>
                        <div className="chat view-background-button-bar">
                            <button className="button-bar">
                                <IconChat />
                            </button>
                        </div>
                    </div>
                    <audio
                        ref={audioRef}
                        autoPlay={true}
                        // muted={isMute}
                    />
                </div>
            </Draggable>
        </div>
    )
}

export default connect()(Participant)
