import React, { useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import moment from 'moment'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Field, getIn } from 'formik'

import MomentUtils from '@date-io/moment'
import { maxCharLength } from '../../../../constants'

const ProviderLicenseView = ({
    license,
    index,
    fieldArrayProps,
    setFieldValue,
    initialLicense,
    allValues,
    onBlur,
    setFieldTouched,
}) => {
    const {
        state_name,
        license_number,
        expiry_date,
        dea_number,
    } = license

    const { push, remove } = fieldArrayProps
    const [isDuplicateError, setIsDuplicateError] =
        useState(false)
    const ErrorMessage = ({ name }) => (
        <Field
            name={name}
            render={({ form }) => {
                if (
                    form.errors &&
                    form.errors.licenses &&
                    !Array.isArray(form.errors.licenses) &&
                    form.errors.licenses.includes(
                        'Duplicate',
                    )
                ) {
                    setIsDuplicateError(true)
                } else {
                    setIsDuplicateError(false)
                }

                const error = getIn(form.errors, name)
                const touch = getIn(form.touched, name)
                return touch && error ? (
                    <div className="errormsg">{error}</div>
                ) : null
            }}
        />
    )
    return (
        <Grid
            container
            key={index}
            spacing={1}
            style={{
                alignItems: 'flex-start',
            }}>
            <Grid item xs={2}>
                <Field
                    name={`licenses[${index}].state_name`}
                    component={TextField}
                    fullWidth
                    inputProps={{
                        maxLength: maxCharLength,
                    }}
                    variant="outlined"
                    placeholder="State Name"
                    onChange={(e) => {
                        setFieldValue(
                            `licenses[${index}].state_name`,
                            e.target.value,
                        )
                    }}
                    value={state_name}
                    onBlur={(e) =>
                        setFieldTouched(
                            `licenses[${index}].state_name`,
                            true,
                        )
                    }
                />
                <ErrorMessage
                    name={`licenses[${index}].state_name`}
                />
            </Grid>
            <Grid item xs={3}>
                <Field
                    name={`licenses[${index}].license_number`}
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    placeholder="License Number"
                    onChange={(e) => {
                        setFieldValue(
                            `licenses[${index}].license_number`,
                            e.target.value,
                        )
                    }}
                    value={license_number}
                    onBlur={(e) =>
                        setFieldTouched(
                            `licenses[${index}].license_number`,
                            true,
                        )
                    }
                />
                <ErrorMessage
                    name={`licenses[${index}].license_number`}
                />
            </Grid>

            <Grid item xs={3}>
                <MuiPickersUtilsProvider
                    utils={MomentUtils}>
                    <Field
                        fullWidth
                        value={expiry_date}
                        name={`licenses[${index}].expiry_date`}
                        variant="outlined"
                        component={KeyboardDatePicker}
                        className="ba-input-fields ba-date-input"
                        inputVariant="outlined"
                        variant="inline"
                        format="MM/DD/YYYY"
                        autoOk={true}
                        placeholder={'MM/DD/YYYY'}
                        onChange={(e) => {
                            setFieldValue(
                                `licenses[${index}].expiry_date`,
                                moment(e)
                                    .format()
                                    .split('T')[0],
                            )
                        }}
                        onBlur={(e) =>
                            setFieldTouched(
                                `licenses[${index}].expiry_date`,
                                true,
                            )
                        }
                    />
                    <ErrorMessage
                        name={`licenses[${index}].expiry_date`}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
                <Field
                    name={`licenses[${index}].dea_number`}
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    placeholder="DEA Number"
                    onChange={(e) => {
                        setFieldValue(
                            `licenses[${index}].dea_number`,
                            e.target.value,
                        )
                    }}
                    value={dea_number}
                />
                <ErrorMessage
                    name={`licenses[${index}].dea_number`}
                />
            </Grid>

            <Grid item xs={2} className="flex">
                {index !== 0 &&
                    index === allValues.length - 1 && (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(initialLicense)
                            }>
                            +
                        </button>
                    )}

                {index === 0 &&
                    (allValues.length === 1 ? (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(initialLicense)
                            }>
                            +
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() => remove(index)}>
                            -
                        </button>
                    ))}

                {index !== 0 && (
                    <button
                        type="button"
                        className="plus-wrap "
                        onClick={() => remove(index)}>
                        -
                    </button>
                )}
            </Grid>
            <Grid item xs={12}>
                {isDuplicateError && (
                    <ErrorMessage name={'licenses'} />
                )}
            </Grid>
        </Grid>
    )
}

export default ProviderLicenseView
