import { Divider, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const DUMMY_GENDERS = [
    {
        key: 'male',
        label: 'Male',
    },
    {
        key: 'female',
        label: 'Female',
    },
    {
        key: 'other',
        label: 'Other',
    },
]

const DUMMY_PERSON_FILLING = [
    {
        key: 'option1',
        label: 'Option 1',
    },
    {
        key: 'option2',
        label: 'Option 2',
    },
    {
        key: 'option3',
        label: 'Option 3',
    },
]

function NewPatientForm(props) {
    const formik = props.formik

    const [genderOptions, setGenderOptions] = React.useState(DUMMY_GENDERS)
    const [personFillingOptions, setPersonFillingOptions] = React.useState(DUMMY_PERSON_FILLING)

    const getGenderOptions = () => {}
    const getPersonFillingOptions = () => {}

    return (
        <div className="booking_boxes">
            <div>
                <Typography className="step-headers">
                    Create a profile to book your appointment!
                </Typography>
            </div>

            <div className="body">
                <Typography className="appo_details_title">Personal Profile</Typography>
                <div className="provider_card form_card">
                    <div className="card_content">
                        <div className="provider_card_content appo_details_card_content">
                            <div className="patient_info">
                                <div className="patient_info_child_single">
                                    <Typography className="field_label">
                                        Person filling out form
                                    </Typography>
                                    <Select
                                        onChange={(e) =>
                                            formik.setFieldValue('person_filling', e.target.value)
                                        }
                                        name="person_filling"
                                        disableUnderline
                                        value={formik.values.person_filling}
                                        style={{
                                            width: '414px',
                                            borderRadius: '5px',
                                            border: '1px solid #BFBFBF',
                                            background: ' #FFF',
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        displayEmpty
                                        renderValue={
                                            formik.values.person_filling === ''
                                                ? () => {
                                                      return (
                                                          <span className="dropdown-placeholder">
                                                              Select
                                                          </span>
                                                      )
                                                  }
                                                : undefined
                                        }>
                                        {personFillingOptions.map((el) => (
                                            <MenuItem
                                                className="pl-select-option"
                                                value={el.key}
                                                key={el.key}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                <div className="patient_info_child">
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            First Name<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="first_name"
                                            placeholder="Enter first name"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.first_name}
                                            onChange={(e) =>
                                                formik.setFieldValue('first_name', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            Last Name<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="last_name"
                                            placeholder="Enter last name"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.last_name}
                                            onChange={(e) =>
                                                formik.setFieldValue('last_name', e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="patient_info_child">
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            Preferred Name (Optional)
                                        </Typography>
                                        <TextField
                                            name="preferred_name"
                                            placeholder="Enter preferred name"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.preferred_name}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'preferred_name',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            Date of Birth<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="dob"
                                            placeholder="MM/DD/YYYY"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.dob}
                                            onChange={(e) =>
                                                formik.setFieldValue('dob', e.target.value)
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none">
                                                        <path
                                                            d="M5.33398 1.33301V3.33301"
                                                            stroke="#667689"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M10.666 1.33301V3.33301"
                                                            stroke="#667689"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M2.33398 6.05957H13.6673"
                                                            stroke="#667689"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M14.6673 12.6667C14.6673 13.1667 14.5273 13.64 14.2807 14.04C13.8207 14.8133 12.974 15.3333 12.0007 15.3333C11.3273 15.3333 10.714 15.0867 10.2473 14.6667C10.0407 14.4933 9.86065 14.28 9.72065 14.04C9.47399 13.64 9.33398 13.1667 9.33398 12.6667C9.33398 11.1933 10.5273 10 12.0007 10C12.8007 10 13.514 10.3533 14.0007 10.9067C14.414 11.38 14.6673 11.9933 14.6673 12.6667Z"
                                                            stroke="#667689"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M10.9609 12.667L11.6209 13.327L13.0409 12.0137"
                                                            stroke="#667689"
                                                            stroke-width="0.7"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M14 5.66634V10.9063C13.5133 10.353 12.8 9.99967 12 9.99967C10.5267 9.99967 9.33333 11.193 9.33333 12.6663C9.33333 13.1663 9.47333 13.6397 9.72 14.0397C9.86 14.2797 10.04 14.493 10.2467 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V5.66634C2 3.66634 3 2.33301 5.33333 2.33301H10.6667C13 2.33301 14 3.66634 14 5.66634Z"
                                                            stroke="#667689"
                                                            stroke-miterlimit="10"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M7.99764 9.13314H8.00363"
                                                            stroke="#667689"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M5.52889 9.13314H5.53488"
                                                            stroke="#667689"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M5.52889 11.1331H5.53488"
                                                            stroke="#667689"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="patient_info_child">
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            Mobile<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="mobile"
                                            placeholder="(000) 000-0000"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.mobile}
                                            onChange={(e) =>
                                                formik.setFieldValue('mobile', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            Email<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="email"
                                            placeholder="Enter email"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.email}
                                            onChange={(e) =>
                                                formik.setFieldValue('email', e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="patient_info_child_single">
                                    <Typography className="field_label">
                                        Gender at Birth<p>*</p>
                                    </Typography>
                                    <Select
                                        onChange={(e) =>
                                            formik.setFieldValue('gender', e.target.value)
                                        }
                                        name="gender"
                                        disableUnderline
                                        value={formik.values.gender}
                                        style={{
                                            width: '414px',
                                            borderRadius: '5px',
                                            border: '1px solid #BFBFBF',
                                            background: ' #FFF',
                                        }}
                                        IconComponent={ExpandMoreIcon}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        displayEmpty
                                        renderValue={
                                            formik.values.gender === ''
                                                ? () => {
                                                      return (
                                                          <span className="dropdown-placeholder">
                                                              Select
                                                          </span>
                                                      )
                                                  }
                                                : undefined
                                        }>
                                        {genderOptions.map((el) => (
                                            <MenuItem
                                                className="pl-select-option"
                                                value={el.key}
                                                key={el.key}>
                                                {el.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Divider />
                            <div className="address_line">
                                <div className="patient_info_grandchild">
                                    <Typography className="field_label">
                                        Address Line 1<p>*</p>
                                    </Typography>
                                    <TextField
                                        name="address1"
                                        placeholder="Enter address"
                                        inputProps={{
                                            maxLength: 40,
                                        }}
                                        variant="outlined"
                                        value={formik.values.address1}
                                        onChange={(e) =>
                                            formik.setFieldValue('address1', e.target.value)
                                        }
                                    />
                                </div>

                                <div className="patient_info_grandchild">
                                    <Typography className="field_label">Address Line 2</Typography>
                                    <TextField
                                        name="address2"
                                        placeholder="Enter address"
                                        inputProps={{
                                            maxLength: 40,
                                        }}
                                        variant="outlined"
                                        value={formik.values.address2}
                                        onChange={(e) =>
                                            formik.setFieldValue('address2', e.target.value)
                                        }
                                    />
                                </div>

                                <div className="address_line_last_child">
                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            City<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="city"
                                            placeholder="Enter"
                                            style={{
                                                width: '253px !important',
                                            }}
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.city}
                                            onChange={(e) =>
                                                formik.setFieldValue('city', e.target.value)
                                            }
                                        />
                                    </div>

                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            State<p>*</p>
                                        </Typography>
                                        <Select
                                            onChange={(e) =>
                                                formik.setFieldValue('state', e.target.value)
                                            }
                                            name="state"
                                            disableUnderline
                                            value={formik.values.state}
                                            style={{
                                                width: '267px',
                                                borderRadius: '5px',
                                                border: '1px solid #BFBFBF',
                                                background: ' #FFF',
                                                height: 45,
                                            }}
                                            IconComponent={ExpandMoreIcon}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            displayEmpty
                                            renderValue={
                                                formik.values.state === ''
                                                    ? () => {
                                                          return (
                                                              <span className="dropdown-placeholder">
                                                                  Select
                                                              </span>
                                                          )
                                                      }
                                                    : undefined
                                            }>
                                            {personFillingOptions.map((el) => (
                                                <MenuItem
                                                    className="pl-select-option"
                                                    value={el.key}
                                                    key={el.key}>
                                                    {el.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>

                                    <div className="patient_info_grandchild">
                                        <Typography className="field_label">
                                            Zip Code<p>*</p>
                                        </Typography>
                                        <TextField
                                            name="zip_code"
                                            placeholder="Enter"
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                            variant="outlined"
                                            value={formik.values.zip_code}
                                            onChange={(e) =>
                                                formik.setFieldValue('zip_code', e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewPatientForm
