import { styled } from '@mui/material/styles'
import {
    Grid,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core'
import {
    createStyles,
    makeStyles,
} from '@material-ui/core/styles'
import { GridOverlay } from '@mui/x-data-grid'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Stack from '@mui/material/Stack'
import NoDataIcon from '../../assests/icons/NoDataIcon'
import Tooltip, {
    tooltipClasses,
} from '@mui/material/Tooltip'
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationRowRangeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import EditIcon from '../../assests/icons/edit_icon.svg'
import HoverEditIcon from '../../assests/icons/hover_edit_icon.svg'
import ForwardIcon from '../../assests/icons/ForwardIcon'
import NextIcon from '../../assests/icons/NextIcon'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import { _getClinicalNotes } from '../../containers/ClinicalNotes/api/services'
import { searchPatientForProvider } from '../../services/Patient/patient'
import { ButtonIconEl } from '../../shared/elements'
import { getLoggedInUserRole } from '../../utilities'
import ClinicalNotesFilter from './ClinicalNotesFilter'

const LIMIT = 10
const useStyles = makeStyles({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})

const useAutoCompleteStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiAutocomplete-listbox': {
                '& li': {
                    marginLeft: '8px',
                    marginRight: '8px',
                    marginTop: '10px',
                    marginBottom: '10px',
                },
            },
        },
    }),
)

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        arrow
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#FFFFFF',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 16px rgba(225, 229, 235, 0.8)',
        backgroundColor: '#FFFFFF',
        color: '#303E4E',
        padding: '8px 15px',
        //   maxWidth: 220,
        fontSize: 14,
        // border: '1px solid #dadde9',
    },
}))

const NoRowsOverlayEl = (props) => {
    const classes = useStyles()
    return (
        <GridOverlay
            containter
            className={classes.cover_no_row}>
            <NoDataIcon />
            <Typography
                variant="h4"
                className={classes.header_no_row}>
                No Results
            </Typography>
            <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. In placerat sollicitudin
                erat, ac dignissim nibh gravida ut. Fusce
                sit amet metus eu lectus ultricies lacinia.
            </Typography>
            <Button
                variant="contained"
                classes={{ root: classes.btn__go_to_main }}>
                Go to main page
            </Button>
        </GridOverlay>
    )
}

const renderEditAction = (params, handleEditClick) => {
    const classes = useStyles()
    return (
        <Grid item>
            <HtmlTooltip
                title={'Edit'}
                arrow
                placement="top"
                style={{
                    background: 'white !important',
                    color: '#303E4E',
                }}>
                <div>
                    <ButtonIconEl
                        size="small"
                        onClick={() => {
                            handleEditClick(params)
                        }}
                        className={classes.act_iconbtn}
                        icon={
                            <img
                                src={EditIcon}
                                alt=""
                                style={{
                                    color: 'white',
                                }}></img>
                        }
                        iconHover={
                            <img
                                src={HoverEditIcon}
                                alt=""
                                style={{
                                    color: 'white',
                                }}></img>
                        }></ButtonIconEl>
                </div>
            </HtmlTooltip>
        </Grid>
    )
}

const renderActions = (params, actions) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            {renderEditAction(
                params,
                actions.handleEditClick,
            )}
        </Grid>
    )
}

const CustomPagination = ({
    onPageChange,
    total,
    numberRecord,
}) => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(
        apiRef,
        gridPageCountSelector,
    )
    const rowIndex = gridPaginationRowRangeSelector(apiRef)

    return (
        <Stack
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <Typography>
                Show{' '}
                <strong>
                    {rowIndex?.lastRowIndex + 1 || 0}{' '}
                    records
                </strong>{' '}
                of <strong>{total}</strong>
            </Typography>
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                variant="outlined"
                shape="rounded"
                onChange={(event, value) => {
                    apiRef.current.setPage(value - 1)
                    onPageChange(value)
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ForwardIcon,
                            next: NextIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}

const ClinicalNotesList = ({ userId }) => {
    const history = useHistory()
    const [page, setPage] = useState(1)
    const classes = useStyles()

    const location = useLocation()

    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })

    const onPageChange = (value) => {
        setPage(value)
    }

    const handleChangePage = (value) => {
        setPage(value - 1)
    }
    const userRole = getLoggedInUserRole()
    const [searchData, setSearchData] = React.useState({
        searchText: '',
        status: 'All',
        date: moment().unix(),
    })
    const [selectedUser, setSelectedUser] =
        React.useState('')

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'type',
            sort: 'desc',
        },
    ])
    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
    ].includes(userRole)
    useEffect(() => {
        if (location?.state?.searchData)
            setSearchData(location?.state?.searchData)
    }, [location])
    useEffect(async () => {
        setLoading(true)
        try {
            const res = await _getClinicalNotes({
                limit: 10,
                page,
                status: searchData.status,
            })
            setLoading(false)
            setPatientList(
                res.data?.data.map((item) => {
                    item.clinicalNoteName = `${item.patient_first_name} ${item.patient_lase_name}`
                    return item
                }),
            )
            setPaginationData(res.data?.paging)
        } catch (e) {
            setLoading(false)
        }
    }, [page, selectedUser, searchData])

    function onOptionSelect(_, selected) {
        if (selected?.user_id) {
            setSelectedUser(selected.user.id)
        } else {
            setSelectedUser(0)
        }
    }

    const handleEditClick = (params) => {
        const { row } = params
        const payload = {
            ...row,
        }
        history.push(
            Routes.CLINICAL_NOTES.NEW_NOTE,
            payload,
        )
    }

    const columns = [
        {
            headerName: (
                <Typography variant="body1">
                    Patient Name <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'clinicalNoteName',
            flex: 1.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                //return `${row.patient_first_name} ${row.patient_lase_name}`
                return value
                // return (
                //     value.charAt(0).toUpperCase() +
                //     value.slice(1)
                // )
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Date <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'date',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value
                    ? moment
                          .utc(`${value} ${row.time}`)
                          .local()
                          .format('MM/DD/YYYY')
                    : '01 Jan 2022'
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Time <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'time',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value
                    ? moment
                          .utc(`${row.date} ${value}`)
                          .local()
                          .format('hh:mm A')
                    : '9:30 AM'
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Type <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'templateName',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value
            },
        },

        {
            headerName: (
                <Typography variant="body1">
                    Status <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'status',
            flex: 0.75,
            sortable: false,
            renderCell: ({ value }) => {
                const valueLowCase = value.toLowerCase()
                return (
                    <span
                        className={clsx({
                            [classes.status_box]: true,
                            [classes.bgColor_draft]:
                                valueLowCase === 'draft',
                            [classes.bgColor_pending_approval]:
                                valueLowCase === 'pending',
                            [classes.bgColor_approved_lock]:
                                valueLowCase ===
                                    'approved_lock' ||
                                valueLowCase === 'locked',
                            [classes.bgColor_resent_to_edit]:
                                valueLowCase ===
                                'resent_to_edit',
                        })}>
                        {statusMapper(value) || value}
                    </span>
                )
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Actions
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'id',
            width: 100,
            // flex: 0.25,
            sortable: false,
            renderCell: (params) =>
                renderActions(params, { handleEditClick }),
        },
    ]

    function statusMapper(status) {
        switch (status) {
            case 'pending':
                return 'Pending Approval'
            case 'draft':
                return 'Draft'
            case 'locked':
                return 'Locked'
            case 'resent_to_edit':
                return 'Resent to Edit'
            case 'approved_lock':
                return 'Approved & Locked'
            default:
                return ''
        }
    }

    return (
        <Grid
            container
            className={classes.clinical_notes_container}
            xs={12}>
            <ClinicalNotesFilter
                searchData={searchData}
                onSearch={(searchVal) => {
                    setSearchData(searchVal)
                }}
            />
            <Grid
                item
                xs={12}
                className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator':
                                {
                                    visibility: 'hidden',
                                },
                            '& .MuiDataGrid-columnHeaderTitle':
                                {
                                    '& .MuiTypography-body1':
                                        {
                                            display: 'flex',
                                            alignItems:
                                                'center',
                                            color: '#303E4E',
                                            fontWeight: 500,
                                            fontSize:
                                                '14px',
                                        },
                                },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext':
                            {
                                padding: '0 22px',
                            },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                            {
                                outline: 'none !important',
                            },
                        '& .MuiPaginationItem-root.Mui-selected':
                            {
                                borderRadius: '5px',
                                background: '#667689',
                                color: 'white',
                                border: 'none',
                            },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 160px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader':
                            {
                                border: 'none',
                                outline: 'none !important',
                            },
                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) =>
                            setSortModel(newSortModel)
                        }
                        rows={patientList}
                        columns={columns}
                        pageSize={10}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={paginationData.total}
                        pagination
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl />
                            ),
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                onPageChange,
                                total: paginationData.total,
                            },
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            pagination: {
                                total: paginationData.total,
                                numberRecord:
                                    patientList.length,
                            },
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default ClinicalNotesList
