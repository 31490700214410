import React, { useState, useEffect } from 'react'
import {
    Grid,
    TextField,
    Select,
    MenuItem,
    FormLabel,
    Button,
} from '@material-ui/core'
import moment from 'moment'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Field, getIn } from 'formik'
import MomentUtils from '@date-io/moment'
import { maxCharLength } from '../../../../constants'
import trash_icon from '../../../../assests/images/icon_delete.svg'

const DependentsView = ({
    minor,
    index,
    fieldArrayProps,
    setFieldValue,
    allValues,
    initialDepent,
}) => {
    const { push, remove } = fieldArrayProps
    const [isDuplicateError, setIsDuplicateError] =
        useState(false)
    const options = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
    ]
    const relationOptions = [
        { label: 'Son', value: 'son' },
        { label: 'Daughter', value: 'daughter' },
    ]
    const [minDateMajor, setMinDateMajor] = useState('')
    const ErrorMessage = ({ name }) => (
        <Field
            name={name}
            render={({ form }) => {
                if (
                    form.errors &&
                    form.errors.minors &&
                    !Array.isArray(form.errors.minors) &&
                    form.errors.minors.includes('Duplicate')
                ) {
                    setIsDuplicateError(true)
                } else {
                    setIsDuplicateError(false)
                }

                const error = getIn(form.errors, name)
                const touch = getIn(form.touched, name)
                return touch && error ? (
                    <div className="errormsg">{error}</div>
                ) : null
            }}
        />
    )
    useEffect(() => {
        const min_date = moment().subtract(18, 'years')
        const formattedDate = new Date(min_date.toDate())
        setMinDateMajor(formattedDate)
    }, [])
    return (
        <Grid
            container
            style={{ width: '50%' }}
            key={index}
            className="dependent-form-wrap">
            <Grid
                item
                xs={12}
                className="invidual-dependent-wrap">
                <Grid item xs={12} className="flex">
                    <button
                        type="button"
                        className="plus-wrap-img"
                        onClick={() => remove(index)}>
                        <img
                            src={trash_icon}
                            alt="delete"
                            height={24}
                            width={20}
                        />
                    </button>
                </Grid>
                <Grid item xs={4} lg={5}>
                    <FormLabel
                        className="ba-input-label-profile"
                        component="legend">
                        First Name
                    </FormLabel>
                </Grid>
                <Grid item xs={8} lg={6}>
                    <Field
                        name={`minors[${index}][patient].first_name`}
                        component={TextField}
                        fullWidth
                        inputProps={{
                            maxLength: maxCharLength,
                        }}
                        variant="outlined"
                        placeholder="First name"
                        onChange={(e) => {
                            setFieldValue(
                                `minors[${index}][patient].first_name`,
                                e.target.value,
                            )
                        }}
                        value={minor['patient']?.first_name}
                    />
                    <ErrorMessage
                        name={`minors[${index}][patient].first_name`}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                className="invidual-dependent-wrap">
                <Grid item xs={4} lg={5}>
                    <FormLabel
                        className="ba-input-label-profile"
                        component="legend">
                        Last Name
                    </FormLabel>
                </Grid>
                <Grid item xs={8} lg={6}>
                    <Field
                        name={`minors[${index}][patient].last_name`}
                        component={TextField}
                        fullWidth
                        inputProps={{
                            maxLength: maxCharLength,
                        }}
                        variant="outlined"
                        placeholder="Last name"
                        onChange={(e) => {
                            setFieldValue(
                                `minors[${index}][patient].last_name`,
                                e.target.value,
                            )
                        }}
                        value={minor['patient']?.last_name}
                    />
                    <ErrorMessage
                        name={`minors[${index}][patient].last_name`}
                    />
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
            <Grid
                item
                xs={12}
                className="invidual-dependent-wrap">
                <Grid item xs={4} lg={5}>
                    <FormLabel
                        className="ba-input-label-profile"
                        component="legend">
                        Date of Birth
                    </FormLabel>
                </Grid>
                <Grid item xs={8} lg={6}>
                    <MuiPickersUtilsProvider
                        utils={MomentUtils}>
                        <Field
                            fullWidth
                            value={minor['patient']?.dob}
                            name={`minors[${index}][patient].dob`}
                            variant="outlined"
                            component={KeyboardDatePicker}
                            className="ba-input-fields ba-date-input"
                            inputVariant="outlined"
                            minDate={minDateMajor}
                            maxDate={new Date()}
                            variant="inline"
                            format="MM/DD/YYYY"
                            autoOk={true}
                            placeholder={'MM/DD/YYYY'}
                            onChange={(date) => {
                                if (date) {
                                    setFieldValue(
                                        `minors[${index}][patient].dob`,
                                        moment(date).format(
                                            'YYYY-MM-DD',
                                        ),
                                    )
                                }
                            }}
                        />
                        <ErrorMessage
                            name={`minors[${index}][patient].dob`}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                className="invidual-dependent-wrap">
                <Grid item xs={4} lg={5}>
                    <FormLabel
                        className="ba-input-label-profile"
                        component="legend">
                        Gender
                    </FormLabel>
                </Grid>
                <Grid item xs={8} lg={6}>
                    <Field
                        name={`minors[${index}][patient].gender`}
                        component={Select}
                        fullWidth
                        displayEmpty
                        variant="outlined"
                        placeholder="Gender"
                        onChange={(e) => {
                            if (e.target.value === 'male') {
                                setFieldValue(
                                    `minors[${index}][patient].relation`,
                                    'Son',
                                )
                            } else {
                                setFieldValue(
                                    `minors[${index}][patient].relation`,
                                    'Daughter',
                                )
                            }
                            setFieldValue(
                                `minors[${index}][patient].gender`,
                                e.target.value,
                            )
                        }}
                        value={
                            minor?.patient?.gender || ''
                        }>
                        <MenuItem disabled value="">
                            <span className="opacity05">
                                {'Select Gender'}
                            </span>
                        </MenuItem>
                        {options.map((item) => (
                            <MenuItem
                                value={item?.value}
                                key={item?.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Field>
                    <ErrorMessage
                        name={`minors[${index}][patient].gender`}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                className="invidual-dependent-wrap">
                <Grid item xs={4} lg={5}>
                    <FormLabel
                        className="ba-input-label-profile"
                        component="legend">
                        Relation
                    </FormLabel>
                </Grid>
                <Grid item xs={8} lg={6}>
                    <Field
                        name={`minors[${index}][patient].relation`}
                        component={TextField}
                        fullWidth
                        inputProps={{ readOnly: true }}
                        variant="outlined"
                        placeholder="Relation"
                        onChange={(e) => {
                            setFieldValue(
                                `minors[${index}][patient].relation`,
                                e.target.value,
                            )
                        }}
                        value={minor?.patient?.relation}>
                        {/* <MenuItem
                            disabled
                            value="none"
                        >
                            <span className="opacity05">{'Select relation'}</span>
                        </MenuItem>
                        {relationOptions.map((item) => (
                            <MenuItem
                                value={item?.value}
                                key={item?.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))} */}
                    </Field>

                    <ErrorMessage
                        name={`minors[${index}][patient].relation`}
                    />
                </Grid>
            </Grid>
            {/* <Grid item xs={12} className="invidual-dependent-wrap">
                <Grid item xs={4}>
                    <FormLabel
                        className="ba-input-label-profile"
                        component="legend"
                    >
                        Relation
                    </FormLabel>
                </Grid>
                <Grid item xs={7}>
                    <Field
                        name={`minors[${index}][patient].relation`}
                        component={Select}
                        fullWidth
                        inputProps={{
                            maxLength: maxCharLength,
                        }}
                        variant="outlined"
                        placeholder="Relation"
                        onChange={(e) => {
                            setFieldValue(
                                `minors[${index}][patient].relation`,
                                e.target.value,
                            );
                        }}
                        value={minor?.patient?.gender}
                    >  <MenuItem
                        disabled
                        value="none"
                    >
                            <span className="opacity05">{'Select relation'}</span>
                        </MenuItem>
                        {relationOptions.map((item) => (
                            <MenuItem
                                value={item.value}
                                key={item.value}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Field>
                    <ErrorMessage
                        name={`minors[${index}][patient]?.relation`}
                    />
                </Grid>
            </Grid> */}

            {index === allValues.length - 1 && (
                <Button
                    style={{
                        position: 'absolute',
                        left: '0px',
                        bottom: '30px',
                        left: '21%',
                    }}
                    type="button"
                    className="primaryBtn"
                    onClick={() => push(initialDepent)}>
                    Add Dependent
                </Button>
            )}

            <Grid item xs={12}>
                {isDuplicateError && (
                    <ErrorMessage name={'minors'} />
                )}
            </Grid>
        </Grid>
    )
}

export default DependentsView
