import * as actionType from '../actionType'

const initialState = {
    formListData: null,
    formTypes: [],
    forSubTypes: [],
    selectedFormId: null,
    selectedFormData: null,
    formType: '',
}

const formBuilder = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_FORM_LIST:
            return {
                ...state,
                formListData: action.value,
            }
        case actionType.SET_FORM_TYPES:
            return {
                ...state,
                formTypes: action.value,
            }
        case actionType.SET_SUB_FORM_TYPES:
            return {
                ...state,
                forSubTypes: action.value,
            }
        case actionType.SET_SELECTED_FORM_ID:
            return {
                ...state,
                selectedFormId: action.value,
            }
        case actionType.SET_FORM_DATA:
            return {
                ...state,
                selectedFormData: action.value,
            }
        case actionType.SET_FORM_TYPE:
            return {
                ...state,
                formType: action.value,
            }
        case actionType.RESET_FORM_BUILDER:
            return {
                ...initialState,
            }
        case actionType.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

export default formBuilder
