import React from 'react'
import Card from '@mui/material/Card'
import avatar from '../../../../assests/images/PatientApoointment/avatar.png'
import CardContent from '@mui/material/CardContent'
import { Box, Button, Grid, Typography, TextField } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { patientTimeZone, specialityOfPrivider } from '../../../../containers/AppointmentList/utils'
import { nameAvatar } from '../../../utils'
import Pills from '../../../../assests/icons/pills.svg'
import Divider from '@mui/material/Divider'
import TrashIcon from '../../../../assests/icons/trash.svg'
import CustomAlertPopOver from '../../../shared/Elements/AlertPopover'
import { DemoGraphModal } from './DemographModal'
import CheckboxOption from '../../../ClinicalNotes/reusable/CheckboxOptions'
const isAnyKeyNotEmpty = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key]) {
            return true // If any key has a non-empty value, return true
        }
    }
    return false // If all keys are empty, return false
}
const ReasonForVisit = ({
    value,
    setValue,
    selctedProviderDetails,
    selectedTime,
    medications,
    setMedications,
    bookingWith,
    steps,
    setSteps,
    handleNextStep,
    requireTranslator,
    setRequireTranslator
}) => {
    const loggedinPatiendata = useSelector((state) => state?.login?.userData)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(null)

    // const [medArr, setMedArray] = React.useState([1])
    const isAtLeastOneKeyFilled = () => {
        for (const obj of medications) {
            let hasNonEmptyKey = false
            for (const key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] !== '') {
                    hasNonEmptyKey = true // If any key is not empty, set hasNonEmptyKey to true
                    break // No need to continue checking other keys once a non-empty key is found
                }
            }
            if (!hasNonEmptyKey) {
                return false // If all keys are empty in the current object, return false
            }
        }
        return true // If at least one object has at least one non-empty key, return true
    }
    // const isAtLeastOneKeyFilled = () => {
    //     for (const obj of medications) {
    //         for (const key in obj) {
    //             if (obj.hasOwnProperty(key) && obj[key]) {
    //                 return true
    //             }
    //         }
    //     }
    //     return false
    // }
    // Function to handle medication submission
    const handleAddMedication = (event) => {
        event.preventDefault()
        // Check if all keys in all objects of the array are filled before submitting
        if (isAtLeastOneKeyFilled()) {
            setMedications((prev) => [
                ...prev,
                {
                    name: '',
                    strength: '',
                    directions: '',
                },
            ])
        }
    }

    // Function to handle input changes
    const handleInputChange = (event, index) => {
        const { name, value } = event.target
        const updatedMedicationsData = [...medications]
        updatedMedicationsData[index] = {
            ...updatedMedicationsData[index],
            [name]: value,
        }
        setMedications(updatedMedicationsData)
    }
    const handleClickDeleteIcon = (event, index) => {
        setAnchorEl(event.currentTarget)
        setSelectedItemIndex(index)
    }
    const handleDeleteMedication = () => {
        if (selectedItemIndex !== null) {
            if (medications?.length > 3 && !(selectedItemIndex >= 0 && selectedItemIndex <= 2)) {
                const updatedItems = medications.filter((_, i) => i !== selectedItemIndex)
                setMedications(updatedItems)
            } else {
                const updatedMedicationsData = [...medications]
                updatedMedicationsData[selectedItemIndex] = {
                    name: '',
                    strength: '',
                    directions: '',
                }
                setMedications(updatedMedicationsData)
            }
        }
        setSelectedItemIndex(null)
        setAnchorEl(null)
    }
    console.log('reasinforvisirtttttt', steps)
    return (
        <div className="booking_boxes">
            <div>
                <Typography className="step-headers">Tell us why you are seeking care?</Typography>
            </div>

            <div className="body w-md-100 reason_visit_body" style={{ minHeight: '600px' }}>
                <div className="body">
                    <Typography className="appo_details_title d-md-none">Appointment Details</Typography>
                    <div className="provider_card">
                        <div className="card_content">
                            <div className="provider_card_content">
                                <div className="left_section">
                                    <div>
                                        {/* <img src={avatar} alt="avatar" /> */}
                                        {selctedProviderDetails?.avatar ? (
                                            <img
                                                style={{
                                                    borderRadius: '80px',
                                                    border: '2px solid #D8D8D8',
                                                }}
                                                alt="avatar"
                                                src={selctedProviderDetails?.avatar}
                                                height={63}
                                                width={60}
                                            />
                                        ) : (
                                            nameAvatar(
                                                `${selctedProviderDetails?.first_name} ${selctedProviderDetails?.last_name}`,
                                            )
                                        )}
                                    </div>
                                    <div className="about_doctor">
                                        <Typography className="name">
                                            {`${selctedProviderDetails?.first_name} ${selctedProviderDetails?.last_name}`}
                                            {/* Dr. Divyansu Patel */}
                                        </Typography>
                                        <Typography className="practice">
                                            {specialityOfPrivider(selctedProviderDetails?.specialities)}
                                        </Typography>
                                        <span
                                            className='d-md-none'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 8,
                                            }}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none">
                                                <path
                                                    d="M4.66602 1.16699V2.91699"
                                                    stroke="#667689"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M9.33398 1.16699V2.91699"
                                                    stroke="#667689"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M2.04102 5.30273H11.9577"
                                                    stroke="#667689"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.8327 11.0833C12.8327 11.5208 12.7102 11.935 12.4943 12.285C12.0918 12.9617 11.351 13.4167 10.4993 13.4167C9.91018 13.4167 9.37352 13.2008 8.96518 12.8333C8.78435 12.6817 8.62685 12.495 8.50435 12.285C8.28852 11.935 8.16602 11.5208 8.16602 11.0833C8.16602 9.79417 9.21018 8.75 10.4993 8.75C11.1993 8.75 11.8235 9.05916 12.2493 9.54332C12.611 9.95749 12.8327 10.4942 12.8327 11.0833Z"
                                                    stroke="#667689"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M9.58984 11.0834L10.1673 11.6609L11.4098 10.5117"
                                                    stroke="#667689"
                                                    stroke-width="0.7"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M12.25 4.95866V9.54365C11.8242 9.05948 11.2 8.75033 10.5 8.75033C9.21083 8.75033 8.16667 9.79449 8.16667 11.0837C8.16667 11.5212 8.28917 11.9353 8.505 12.2853C8.6275 12.4953 8.785 12.682 8.96583 12.8337H4.66667C2.625 12.8337 1.75 11.667 1.75 9.91699V4.95866C1.75 3.20866 2.625 2.04199 4.66667 2.04199H9.33333C11.375 2.04199 12.25 3.20866 12.25 4.95866Z"
                                                    stroke="#667689"
                                                    stroke-miterlimit="10"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M6.99745 7.99186H7.00269"
                                                    stroke="#667689"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M4.83924 7.99186H4.84448"
                                                    stroke="#667689"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M4.83924 9.74186H4.84448"
                                                    stroke="#667689"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            <Typography className="practice">
                                                {/* February 7th, 2024 */}
                                                {patientTimeZone(
                                                    selectedTime?.start_time,
                                                    loggedinPatiendata?.timezone,
                                                ).format('MMMM Do, YYYY')}
                                            </Typography>
                                        </span>
                                        <span
                                            className='d-md-none'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 8,
                                            }}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="19"
                                                viewBox="0 0 15 19"
                                                fill="none">
                                                <path
                                                    d="M7.80039 5.01557C7.80039 4.76372 7.59893 4.55957 7.35039 4.55957C7.10186 4.55957 6.90039 4.76372 6.90039 5.01557V8.1579L9.66392 9.88753C9.87537 10.0199 10.1526 9.95346 10.2833 9.7392C10.4139 9.52492 10.3483 9.24395 10.1369 9.11161L7.80039 7.64924V5.01557Z"
                                                    fill="#667689"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M13.5 7.59953C13.5 10.9574 10.8137 13.6795 7.5 13.6795C4.18629 13.6795 1.5 10.9574 1.5 7.59953C1.5 4.24164 4.18629 1.51953 7.5 1.51953C10.8137 1.51953 13.5 4.24164 13.5 7.59953ZM12.6 7.59953C12.6 10.4537 10.3167 12.7675 7.5 12.7675C4.68335 12.7675 2.4 10.4537 2.4 7.59953C2.4 4.74532 4.68335 2.43153 7.5 2.43153C10.3167 2.43153 12.6 4.74532 12.6 7.59953Z"
                                                    fill="#667689"
                                                />
                                            </svg>
                                            <Typography className="practice">
                                                {`${patientTimeZone(
                                                    selectedTime?.start_time,
                                                    loggedinPatiendata?.timezone,
                                                ).format('hh:mm A')} - ${patientTimeZone(
                                                    selectedTime?.end_time,
                                                    loggedinPatiendata?.timezone,
                                                ).format('hh:mm A')}`}
                                                {/* 6:00 PM - 7:00 PM */}
                                            </Typography>
                                        </span>
                                    </div>
                                </div>

                                <div className='more_info_holder d-lg-none'>
                                    <span                                        
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                        }}>
                                        <Typography className="practice">
                                            {/* February 7th, 2024 */}
                                            {patientTimeZone(
                                                selectedTime?.start_time,
                                                loggedinPatiendata?.timezone,
                                            ).format('MMMM Do, YYYY')}
                                        </Typography>
                                    </span>
                                    <span
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                        }}>
                                        <Typography className="practice">
                                            {`${patientTimeZone(
                                                selectedTime?.start_time,
                                                loggedinPatiendata?.timezone,
                                            ).format('hh:mm A')} - ${patientTimeZone(
                                                selectedTime?.end_time,
                                                loggedinPatiendata?.timezone,
                                            ).format('hh:mm A')}`}
                                            {/* 6:00 PM - 7:00 PM */}
                                        </Typography>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="body" style={{ marginTop: 20 }}>
                    <Typography className="appo_details_title">Reason for Visit</Typography>
                    <textarea
                        placeholder="Enter your reason for visit."
                        className="reason_for_visit"
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                    />
                </div>

                <div className="body" style={{ marginLeft: 8 }}>
                    <CheckboxOption 
                        option={{
                            label: 'Translator needed',
                            value: false
                        }}
                        checked={requireTranslator}
                        onChange={(event) => setRequireTranslator(event?.target?.checked)}
                    />
                </div>

                {bookingWith === 'yes' && (
                    <div className="body" style={{ marginTop: 20 }}>
                        <Typography className="appo_details_title bigger_text">
                            What medications are you currently taking?
                        </Typography>
                        <Box className="medication_card">
                            <Box className="medication-container">
                                {medications?.map((med, index) => (
                                    <Grid
                                        container
                                        direction="column"
                                        style={{ gap: '10px' }}
                                        key={index}>
                                        <div className='d-md-none'>
                                            <img src={Pills} style={{ marginRight: '5px' }} />
                                            <span className="medication-ser">
                                                Medication {index + 1}
                                            </span>
                                        </div>

                                        <div className='each_box_holder'>
                                            <div className='each_holder'>
                                                <p className='cstm_label_holder d-lg-none'>Medication {index + 1}</p>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Enter medication name"
                                                    style={{ width: '100%' }}
                                                    value={med?.name}
                                                    name="name"
                                                    onChange={(event) =>
                                                        handleInputChange(event, index)
                                                    }
                                                />
                                            </div>
                                            <div className='each_holder'>
                                                <p className='cstm_label_holder d-lg-none'>Strength</p>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Enter strength"
                                                    style={{ width: '100%' }}
                                                    value={med?.strength}
                                                    name="strength"
                                                    onChange={(event) =>
                                                        handleInputChange(event, index)
                                                    }
                                                />
                                            </div>
                                            <div className='each_holder'>
                                                <p className='cstm_label_holder d-lg-none'>Directions</p>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Enter directions"
                                                    style={{ width: '100%' }}
                                                    value={med?.directions}
                                                    name="directions"
                                                    onChange={(event) =>
                                                        handleInputChange(event, index)
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="icon_position"
                                                style={{
                                                    width: '3%',
                                                    opacity: isAnyKeyNotEmpty(med) ? 1 : 0.5,
                                                }}>
                                                <Box display="flex" alignItems={'center'}>
                                                    <Divider
                                                        orientation="vertical"
                                                        variant="middle"
                                                        className="divider_height d-md-none"
                                                        flexItem
                                                    />
                                                    <div
                                                        className="delete_icon"
                                                        style={{
                                                            cursor: isAnyKeyNotEmpty(med)
                                                                ? 'pointer'
                                                                : 'none',
                                                        }}
                                                        onClick={(event) => {
                                                            isAnyKeyNotEmpty(med) &&
                                                                handleClickDeleteIcon(event, index)
                                                        }}>
                                                        {' '}
                                                        <img src={TrashIcon} />
                                                        <span className='cstm_label d-lg-none'>Remove medication {index + 1}</span>
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                                <CustomAlertPopOver
                                    anchorEl={anchorEl}
                                    handleClose={() => {
                                        setAnchorEl(null)
                                        setSelectedItemIndex(null)
                                    }}
                                    title="Delete Medication"
                                    bodyText={`Are you sure you want to delete the medication?`}
                                    confirmButtonName={'Delete'}
                                    confirmButtonColor={'red'}
                                    onSuccess={handleDeleteMedication}
                                />
                            </Box>

                            <Button
                                className="add-medication-submit"
                                onClick={handleAddMedication}
                                disabled={!isAtLeastOneKeyFilled()}>
                                + Add medication
                            </Button>
                        </Box>
                    </div>
                )}
                {steps === 5.5 && (
                    <DemoGraphModal
                        isOpenModal={steps === 5.5}
                        setSteps={setSteps}
                        steps={steps}
                        handleNextStep={handleNextStep}
                        requireTranslator={requireTranslator}
                    />
                )}
            </div>
        </div>
    )
}

export default ReasonForVisit
