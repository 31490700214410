import { useEffect, useRef, useState } from 'react'
import { Calendar } from 'react-date-range'
import format from 'date-fns/format'
import './default.scss'
import './style.scss'
import moment from 'moment'

const SingleCalenderComp = (props) => {
    const [open, setOpen] = useState(false)
    const refOne = useRef(null)
    const handleSelect = (e) => {
        const newDate = new Date(e)
        if (refOne.current) {
            const openValue = props?.alwaysOpen ? props?.alwaysOpen : false
            setOpen(openValue)
            props.closeTheCalender()
        }
        props.selectedDate(newDate)
    }

    const hideOnEscape = (e) => {
        if (e.key === 'Escape') {
            const openValue = props?.alwaysOpen ? props?.alwaysOpen : false
            setOpen(openValue)
            props.closeTheCalender()
        }
    }

    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            props.closeTheCalender()
            const openValue = props?.alwaysOpen ? props?.alwaysOpen : false
            setOpen(openValue)
        }
    }
    useEffect(() => {
        if (props.openCalender) {
            setOpen(true)
        }
    }, [props.openCalender])

    useEffect(() => {
        document.addEventListener('keydown', hideOnEscape, true)
        document.addEventListener('click', hideOnClickOutside, true)
        return () => {
            document.removeEventListener('keydown', hideOnEscape, true)
            document.removeEventListener('click', hideOnClickOutside, true)
        }
    }, [props.displayDate])

    return (
        <div className="calenderwrapper">
            <div ref={refOne}>
                {open && (
                    <Calendar
                        minDate={props?.minDate ? new Date(props?.minDate) : new Date()}
                        date={new Date(props.displayDate)}
                        onChange={handleSelect}
                        className="calendarElement"
                        // value={new Date()}
                    />
                )}
            </div>
        </div>
    )
}

export default SingleCalenderComp
