import React, { Component } from 'react'
import LoginForm from '../../components/Login/Login'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/login'
import * as loaderActions from '../../store/actions/loader'
// import LoadingPage from "../../utilities/loading-page/";
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
// import Toast from "../../utilities/toast";
// import { toast } from "react-toastify";
import { usNumberRegex } from '../../utilities/regex'
import Modal from '../../utilities/modal/modal'
import { formatPhone } from '../../utilities/formatPhone'
import Routes from './../../constants/routes'
import { getTestUserPhone } from '../../constants/test-users'
import { UserRoles } from '../../constants/user-roles'

class Login extends Component {
    state = {
        errorMsg: null,
        isError: false,
        errMsgContent:
            'Something went wrong please try after sometime!!',
        errMsgHeader: '',
        showModal: false,
        otpErrMsg: 'Please enter a valid OTP.',
        resendButtonDisabledTime: 30,
        intervalID: null,
        otp: '',
    }

    componentDidMount() {
        const {
            setIsValidUser,
            setIsUserExist,
            setMaxAttempt,
        } = this.props
        setIsValidUser(false)
        setIsUserExist(true)
        setMaxAttempt(false)
    }
    componentDidUpdate(nextProps) {
        const {
            isValidUser,
            error,
            patientData,
            isUserExist,
            maxAttempt,
            setRedirectURL,
            history,
        } = this.props

        const { intervalID } = this.state

        if (
            nextProps.isUserExist !== isUserExist &&
            !isUserExist
        ) {
            this.setState({
                buttonType: 'Register',
                errMsgContent:
                    'Sorry, we cannot find an account, please register to get started.',
                showModal: true,
            })
        }
        if (
            nextProps.isValidUser !== isValidUser &&
            isValidUser
        ) {
            if (intervalID) {
                clearInterval(intervalID)
            }
            this.setState({ timmerRunning: false }, () => {
                this.startResendOtpTimer()
            })
            // this.setResendButtonDisabledTime(30);
        }

        if (nextProps.error !== error) {
            if (error.statusCode === 500) {
                this.setState({
                    buttonType: 'Close',
                    showModal: true,
                    errMsgContent:
                        'An internal server error occurred',
                })
            } else if (error.isMaxAttempts) {
                this.setState({
                    buttonType: 'Close',
                    showModal: true,
                    errMsgContent:
                        'You have reached max attempts please try tomorrow',
                })
            } else if (
                error.hasOwnProperty('status') &&
                !error.status
            ) {
                this.setState({
                    buttonType: 'Close',
                    showModal: true,
                    errMsgContent:
                        'Your account is inactive. Please contact Admin!!',
                })
            } else {
                if (!isValidUser) {
                    this.setState({
                        buttonType: 'Close',
                        showModal: true,
                        errMsgContent:
                            'Something went wrong please try after sometime!!',
                    })
                }
            }
        }
        if (nextProps.patientData !== patientData) {
            if (patientData) {
                localStorage.setItem(
                    'token',
                    patientData.accessToken,
                )
                localStorage.setItem(
                    'role',
                    patientData.role,
                )
                localStorage.setItem(
                    'userId',
                    patientData.id,
                )
                localStorage.setItem(
                    'name',
                    patientData.name,
                )
                localStorage.setItem(
                    'photo',
                    patientData.photo ||
                        'https://decisionsystemsgroup.github.io/workshop-html/img/john-doe.jpg',
                )
                if (
                    patientData.role === UserRoles.PROVIDER
                ) {
                    localStorage.setItem(
                        'consultation_link',
                        patientData?.providerData
                            ?.consultation_link ||
                            'video?iv=7c1e9e502e6ed91c3533c88d9894ba33&data=dd378b24d37c849ce05638d08b40e9d9a2017ee9aa48bc7dd3396ca60f61cd2ae1dc23caa8a4b1db0fb424e4a61924c7dab87ae2f6fe72c7d4e95ce19d5f9333',
                    )
                }

                if (
                    !patientData.isProfileComplete &&
                    patientData.role === UserRoles.PATIENT
                ) {
                    localStorage.setItem(
                        'isProfileComplete',
                        false,
                    )
                    history.push(Routes.PATIENT_DETAIL)
                } else {
                    localStorage.setItem(
                        'isProfileComplete',
                        true,
                    )
                    // NOTE: This usecase is to redirect user to the respective route on auth if user was redirected got to the login because of auth issue
                    if (nextProps.redirectUrl !== null) {
                        history.push(nextProps.redirectUrl)
                        setRedirectURL(null)
                    } else {
                        history.push(Routes.DASHBOARD)
                    }
                }
            }
        }
        if (
            nextProps.maxAttempt !== maxAttempt &&
            maxAttempt
        ) {
            this.setState({
                buttonType: 'Close',
                showModal: true,
                errMsgContent:
                    'You have reached max attempts please try tomorrow',
            })
        }
    }

    startResendOtpTimer = () => {
        if (!this.state.timmerRunning) {
            var timesRun = 30
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.intervalID = setInterval(() => {
                if (timesRun === 0) {
                    clearInterval(this.state.intervalID)
                }
                timesRun -= 1
                this.setResendButtonDisabledTime(timesRun)
            }, 1000)
        } else {
            return
        }
    }

    setResendButtonDisabledTime = (time) => {
        this.setState({
            resendButtonDisabledTime: time,
            timmerRunning: true,
        })
        if (time === -1) {
            this.setState({ timmerRunning: false })
        }
    }

    sendOtp = (event) => {
        this.props.setOtpError(false)
        event.preventDefault()
        if (!this.state.phoneNo) {
            this.setState({
                errorMsg:
                    'Please enter valid cellphone number',
                isError: true,
            })
        } else {
            if (this.props.isValidUser) {
                this.startResendOtpTimer()
            }
            this.props.sendOtp({
                to: this.state.phoneNo,
                // `${process.env.REACT_APP_COUNTRY_CODE}${this.state.phoneNo}`.replace(
                //     /[ )(]/g,
                //     '',
                // ),
                channel: 'sms',
                type: 'l',
            })
        }
    }

    goBack = () => {
        this.props.setIsValidUser(false)
        this.props.setIsUserExist(true)
        this.props.setMaxAttempt(false)
    }
    handleClose = () => {
        this.props.setIsUserExist(true)
        this.props.setMaxAttempt(false)
        this.setState({ showModal: false })
    }
    handleModalChange = (type) => {
        switch (type) {
            case 'Close':
                this.handleClose()
                break
            case 'Register':
                this.props.history.push(Routes.REGISTER)
                break
            default:
                break
        }
    }
    verifyLogin = () => {
        this.props.verifyLogin({
            method: 'sms',
            mob: this.state.phoneNo,
            session: this.props.session,
            // cmmunicationMedium:
            //     `${process.env.REACT_APP_COUNTRY_CODE}${this.state.phoneNo}`.replace(
            //         /[ )(]/g,
            //         '',
            //     ),
            otp: this.state.otp,
        })
    }
    setOtp = (event) => {
        this.setState({ otp: event.target.value })
    }
    validateMob = (event) => {
        event.preventDefault()
        // const phone = formatPhone(event.target.value)
        // const unformattedPhone = (phone || '').replace(
        //     /[ )(]/g,
        //     '',
        // )
        // if (
        //     usNumberRegex.test(phone) &&
        //     !getTestUserPhone.includes(unformattedPhone)
        // ) {
        //     this.setState({
        //         errorMsg:
        //             'Please enter valid Mob/Phone Number',
        //         isError: true,
        //         phoneNo: phone,
        //     })
        // } else {
        //     this.setState({
        //         errorMsg: null,
        //         isError: false,
        //         phoneNo: phone,
        //     })
        // }

        this.setState({
            errorMsg: null,
            isError: false,
            phoneNo: event.target.value,
        })
    }

    handleClick = () => {
        this.props.history.push(Routes.HOME)
    }

    render() {
        const { isValidUser } = this.props
        return (
            <div>
                <Modal
                    openModal={this.state.showModal}
                    content={this.state.errMsgContent}
                    buttonType={this.state.buttonType}
                    handleModalChange={
                        this.handleModalChange
                    }
                    handleClose={this.handleClose}></Modal>
                <Header handleClick={this.handleClick} />
                <LoginForm
                    handleSubmit={this.sendOtp}
                    handleChange={this.validateMob}
                    errorMsg={this.state.errorMsg}
                    isValidUser={isValidUser}
                    handleBack={this.goBack}
                    verifyLogin={this.verifyLogin}
                    setOtp={this.setOtp}
                    otpError={this.props.otpError}
                    validationError={this.state.isError}
                    otpErrMsg={
                        this.props.otpError
                            ? this.state.otpErrMsg
                            : ''
                    }
                    resendButtonDisabledTime={
                        this.state.resendButtonDisabledTime
                    }
                    loading={this.props.loading}
                    phoneNo={this.state.phoneNo}
                    otp={this.state.otp}
                    setOtpError={this.props.setOtpError}
                />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loader.loading,
        error: state.loader.error,
        isValidUser: state.login.isValidUser,
        isUserExist: state.login.isUserExist,
        session: state.login.session,
        patientData: state.login.userData,
        otpError: state.login.otpError,
        maxAttempt: state.login.maxAttempt,
        redirectUrl: state.login.redirectUrl,
    }
}

const mapDispatchtoProp = (dispatch) => {
    return {
        sendOtp: (mob) => dispatch(actions.sendOtp(mob)),
        setIsUserExist: (val) =>
            dispatch(actions.setIsUserExist(val)),
        setIsValidUser: (val) =>
            dispatch(actions.setUser(val)),
        setError: (val) =>
            dispatch(loaderActions.setError(val)),
        verifyLogin: (data) =>
            dispatch(actions.verifyLogin(data)),
        setMaxAttempt: (data) =>
            dispatch(actions.setMaxAttempt(data)),
        setOtpError: (val) =>
            dispatch(actions.setOtpError(val)),
        setRedirectURL: (val) =>
            dispatch(actions.setRedirectURL(val)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchtoProp,
)(Login)
