import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import bgImageMobile from '../../../assests/images/Bg_waiting_room_mobile.png'
import bgImage from '../../../assests/images/waiting_room_bg.jpg'
export default makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',

        '& .MuiTypography-subtitle1': {
            fontSize: '16px',
            lineHeight: '22.4px',
            fontWeight: '500',
            color: '#132044',
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '13px',
            lineHeight: '18.2px',
            fontWeight: '400',
            color: '#667689',
        },

        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url(${bgImageMobile})`,
        },
    },
    logo: {
        width: '111.94px',
        height: '50px',
        position: 'absolute',
        top: 40,
        left: 50,
    },
    avartar: {
        width: '273px',
        height: '265px',
        marginTop: '60px',
        borderRadius: 150,
        overflow: 'hidden',
        borderWidth: 3,
        [theme.breakpoints.down('sm')]: {
            width: '176px',
            marginTop: '40px',
            height: '171px',
        },
    },
    info_column: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 20px',
    },
    name: {
        marginTop: '16px',
        fontSize: '32px',
        lineHeight: '44.8px',
        color: '#132044',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
        },
        textAlign: 'center',
    },
    user_info: {
        width: 'fit-content',
        marginTop: '16px',
        marginBottom: '16px',
        paddingBottom: '16px',
        display: 'block',
        // paddingLeft: '50px',
        minWidth: '500px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%',
        },
    },
    waiting_time: {
        width: 'fit-content',
        margin: '0 auto',
    },
    call_duration: {
        margin: '0 auto',
    },
    time: {
        color: '#14AE5C',
        marginLeft: 4,
    },
    buttons: {
        margin: '0px 6px',
        padding: '0px',
        background: 'rgba(255, 255, 255, 0.6)',

        display: 'flex',
        alignItems: 'center',
        width: '47px',
        height: '47px',
        minWidth: '47px',
        borderRadius: '12px',
        '&:hover': {
            background: 'rgba(34,43,55,0.5) !important',
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '24px',
            width: '65px',
            height: '65px',
            minWidth: '64px',
        },
    },
    btn_group: {
        display: 'flex',
        justifyContent: 'center',
        // marginTop: '32px',
        width: 'fit-content',
        background: 'rgba(255, 255, 255, 0.3)',
        borderRadius: 30,
        padding: 10,
        margin: '32px auto',
        marginBottom: 0,
    },
    icon_clock: {
        width: 30,
    },
    info_table_header: {
        fontSize: '13px',
        lineHeight: '18.2px',
        fontWeight: 400,
        color: '#667689',
        marginBottom: 5,
    },
    info_table_content: {
        fontSize: '16px',
        lineHeight: '22.4px',
        fontWeight: 500,
        color: '#132044',
    },

    des_time: {
        margin: '0 8px',
        color: '#303E4E',
        [theme.breakpoints.down('sm')]: {
            width: 225,
        },
    },
    border: {
        borderRight: '1px solid #ffffff4d',
    },
    boxService: {
        position: 'relative',
    },
    avatar_crop: {
        position: 'relative',
        // marginTop: 70,
    },
    avatar_crop_icon: {
        position: 'absolute',
        left: 32,
        top: 39,
        width: 200,
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            left: 22,
            top: 30,
            width: 150,
            height: 150,
        },
    },
    radio: {
        '&$checked': {
            color: '#546FC9',
        },
        '&$disabled': {
            color: '#ccc',
        },
    },
    checked: {},
    statusRoomMobile: {
        // width: '111.94px',
        height: '50px',
        position: 'absolute',
        top: 40,
        right: 0,
        left: 0,
        justifyContent: 'center',
    },
    statusRoom: {
        // width: '111.94px',
        height: '50px',
        position: 'absolute',
        top: 40,
        right: 50,
    },
    IconArrowRight: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 5,
    },
    formControlLabel: {
        marginRight: 5,
    },
    formControlText: {
        fontSize: 13,
    },
    wrapContent: {
        alignItems: 'center',
    },
}))
