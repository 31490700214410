import React from 'react'

export default function IconVideoSvg() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.8556 20.6138H6.95422C3.50283 20.6138 2.27783 18.193 2.27783 15.9374V8.06244C2.27783 4.69855 3.59033 3.38605 6.95422 3.38605H12.8556C16.2195 3.38605 17.532 4.69855 17.532 8.06244V15.9374C17.532 19.3013 16.2195 20.6138 12.8556 20.6138ZM6.95422 4.86382C4.41672 4.86382 3.75561 5.52494 3.75561 8.06244V15.9374C3.75561 17.1333 4.17367 19.136 6.95422 19.136H12.8556C15.3931 19.136 16.0542 18.4749 16.0542 15.9374V8.06244C16.0542 5.52494 15.3931 4.86382 12.8556 4.86382H6.95422Z"
                fill="#303E4E"
            />
            <path
                d="M20.5362 17.9402C20.1181 17.9402 19.5834 17.8041 18.9709 17.3763L16.3751 15.5582C16.1806 15.4221 16.064 15.1985 16.064 14.9555V9.04434C16.064 8.80129 16.1806 8.57768 16.3751 8.44157L18.9709 6.62351C20.1279 5.81657 20.9737 6.0499 21.3723 6.25407C21.7709 6.46796 22.4515 7.02212 22.4515 8.43184V15.5582C22.4515 16.968 21.7709 17.5318 21.3723 17.736C21.1876 17.843 20.8959 17.9402 20.5362 17.9402ZM17.532 14.5666L19.8167 16.161C20.2542 16.4624 20.5654 16.4916 20.6917 16.4235C20.8279 16.3555 20.9737 16.0832 20.9737 15.5582V8.44157C20.9737 7.90684 20.8181 7.64434 20.6917 7.57629C20.5654 7.50823 20.2542 7.5374 19.8167 7.83879L17.532 9.43323V14.5666Z"
                fill="#303E4E"
            />
            <path
                d="M11.5142 11.7568C10.3086 11.7568 9.32666 10.7749 9.32666 9.56934C9.32666 8.36378 10.3086 7.38184 11.5142 7.38184C12.7197 7.38184 13.7017 8.36378 13.7017 9.56934C13.7017 10.7749 12.7197 11.7568 11.5142 11.7568ZM11.5142 8.84017C11.1155 8.84017 10.785 9.17072 10.785 9.56934C10.785 9.96795 11.1155 10.2985 11.5142 10.2985C11.9128 10.2985 12.2433 9.96795 12.2433 9.56934C12.2433 9.17072 11.9128 8.84017 11.5142 8.84017Z"
                fill="#303E4E"
            />
        </svg>
    )
}
