const axios = require('axios')

const baseURL = process.env.REACT_APP_SONORA_V2_API_URL
//const baseURL =('https://qa.telemed2u.com/telemed2u-clinical-notes')

const axiosInstance = axios.create({
    baseURL,
})

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        const token = localStorage.getItem('token')
        requestConfig.headers[
            'Authorization'
        ] = `Bearer ${token}`
        requestConfig.headers[
            'Content-Type'
        ] = `application/json`
        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] =
                requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        console.log(error)
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error.response)
    },
)

function getService(url, config) {
    return axiosInstance.get(url, config)
}

function getServiceForPdf(url, config) {
    return axiosInstance.get(url, config)
}

function postService(url, params, options) {
    return axiosInstance.post(url, params, options)
}

function putService(url, params) {
    return axiosInstance.request({
        url,
        method: 'PUT',
        data: params,
    })
}

function deleteService(url, params) {
    return axiosInstance.request({
        url,
        method: 'DELETE',
        data: params,
    })
}

module.exports = {
    getService,
    postService,
    putService,
    deleteService,
    getServiceForPdf,
}
