import React, {
    createContext,
    useContext,
    useReducer,
    useState,
    Dispatch,
    SetStateAction,
} from 'react'
import { RoomType } from '../types'
import { TwilioError } from 'twilio-video'
import {
    initialSettings,
    Settings,
    SettingsAction,
    settingsReducer,
} from './settings/settingsReducer'
import useActiveSinkId from './useActiveSinkId/useActiveSinkId'
// import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { User } from 'firebase'
import { config } from '../../../constants'

export interface StateContextType {
    error: TwilioError | Error | null
    keywordPopulus: string
    setKeywordPopulus: Dispatch<SetStateAction<string>>
    setError(error: TwilioError | Error | null): void

    getToken(name: string, room?: string, userId?: any): Promise<string>
    currentNameLocal?: string
    setCurrentNameLocal: Dispatch<SetStateAction<string | undefined>>

    triggerTranslate?: {
        token: string
        region: string
        target_lang: string
        from_lang: string
    }

    setTriggerTranslate: Dispatch<
        SetStateAction<
            | {
                  token: string
                  region: string
                  target_lang: string
                  from_lang: string
              }
            | undefined
        >
    >

    stopTranslate: number
    setStopTranslate: Dispatch<SetStateAction<number>>

    user?:
        | User
        | null
        | {
              displayName: undefined
              photoURL: undefined
              passcode?: string
          }

    signIn?(passcode?: string): Promise<void>

    signOut?(): Promise<void>

    isAuthReady?: boolean
    isFetching: boolean
    activeSinkId: string

    setActiveSinkId(sinkId: string): void

    settings: Settings
    dispatchSetting: React.Dispatch<SettingsAction>
    roomType?: RoomType
}

export const StateContext = createContext<StateContextType>(null!)

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
    const [error, setError] = useState<TwilioError | null>(null)
    const [isFetching, setIsFetching] = useState(false)
    const [activeSinkId, setActiveSinkId] = useActiveSinkId()
    const [currentNameLocal, setCurrentNameLocal] = useState<string | undefined>()
    const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings)
    const [keywordPopulus, setKeywordPopulus] = useState('')
    const [triggerTranslate, setTriggerTranslate] = useState<{
        token: string
        region: string
        target_lang: string
        from_lang: string
    }>()

    const [stopTranslate, setStopTranslate] = useState<number>(-1)

    let contextValue = {
        error,
        setError,
        isFetching,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
    } as StateContextType

    contextValue = {
        ...contextValue,
        getToken: async (user_identity, room_name, userId) => {
            // const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/generateGrantToken';
            const endpoint = config.dev.baseURL + '/generateGrantToken'
            // to make sure every user having unique token, if user having same first name and second name,
            //so adding random number with user_identity
            // const userIdWithRandomNumber = user_identity + Math.floor(Math.random() * (100000000000000 - 1 + 1)) + 1;
            const userIdWithRandomNumber = user_identity + userId
            return fetch(endpoint, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    // user_identity,
                    // room_name,
                    create_conversation:
                        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
                    username: userIdWithRandomNumber,
                    room: room_name,
                }),
            })
                .then((res) => res.json())
                .then((res) => res.token as string)
        },
    }

    const getToken: StateContextType['getToken'] = (name, room, userId) => {
        setIsFetching(true)
        return contextValue
            .getToken(name, room, userId)
            .then((res) => {
                setIsFetching(false)
                return res
            })
            .catch((err) => {
                setError(err)
                setIsFetching(false)
                return Promise.reject(err)
            })
    }

    return (
        <StateContext.Provider
            value={{
                ...contextValue,
                getToken,
                currentNameLocal,
                setCurrentNameLocal,
                triggerTranslate,
                setTriggerTranslate,
                keywordPopulus,
                setKeywordPopulus,
                stopTranslate,
                setStopTranslate,
            }}>
            {props.children}
        </StateContext.Provider>
    )
}

export function useAppState() {
    const context = useContext(StateContext)
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider')
    }
    return context
}
