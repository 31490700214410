import { getService, postService } from '../../utilities/request/restPatientPortalClient'

export const getClinicalNotesGuest = ({ page, limit }) => {
    return getService(`/patient_portal/document?page=${page}&size=${limit}`)
}

export const getClinicalNotesPreview = (noteId) => {
    return getService(`/patient_portal/document/${noteId}/preview`)
}

export const fetchClinicalNotePdfData = (noteId, payload) => {
    return postService(`/patient_portal/document/${noteId}/preview`, payload, {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    })
}
