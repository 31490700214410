import React from "react";

export default function TimerIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 22.75C7.26 22.75 3 18.49 3 13.25C3 8.01 7.26 3.75 12.5 3.75C17.74 3.75 22 8.01 22 13.25C22 18.49 17.74 22.75 12.5 22.75ZM12.5 5.25C8.09 5.25 4.5 8.84 4.5 13.25C4.5 17.66 8.09 21.25 12.5 21.25C16.91 21.25 20.5 17.66 20.5 13.25C20.5 8.84 16.91 5.25 12.5 5.25Z" fill="white"/>
    <path d="M12.5 13.75C12.09 13.75 11.75 13.41 11.75 13V8C11.75 7.59 12.09 7.25 12.5 7.25C12.91 7.25 13.25 7.59 13.25 8V13C13.25 13.41 12.91 13.75 12.5 13.75Z" fill="white"/>
    <path d="M15.5 2.75H9.5C9.09 2.75 8.75 2.41 8.75 2C8.75 1.59 9.09 1.25 9.5 1.25H15.5C15.91 1.25 16.25 1.59 16.25 2C16.25 2.41 15.91 2.75 15.5 2.75Z" fill="white"/>
    </svg>
    
  );
}
