import {
    Box,
    Container,
    FormLabel,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Formik } from 'formik'
import React, { useRef } from 'react'
import * as Yup from 'yup'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { errorMessages } from '../../constants/errorMessages'
import { ButtonSpinner } from '../custom'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import EmailIcon from '@mui/icons-material/Email'
import { useNotification } from '../Appointments/MaterialUi/Notification'
import { useHistory } from 'react-router-dom'
import { getLoggedInUserDetails } from '../../store/actions/login'
import { useDispatch } from 'react-redux'
import './signup.scss'
import bg1 from '../../assests/images/bg1.png'
import bg2 from '../../assests/images/bg2.png'
import bg3 from '../../assests/images/bg3.png'
import bg4 from '../../assests/images/bg4.png'
import bg5 from '../../assests/images/bg5.png'

const bgImages = [bg1, bg2, bg3, bg4, bg5]
// Computation
const randomBgImage = bgImages[Math.floor(Math.random() * bgImages.length)]

const SignUpForm = ({ handleSubmit, decryptedData }) => {
    const formikRef = useRef()
    const [showPassword, setShowPassword] = React.useState(false)
    const dispatch = useDispatch()
    const notification = useNotification()
    const history = useHistory()
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Please enter your name'),
        email: Yup.string().required().email(errorMessages.validEmailId),
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/,
                'Password must include at least one alphanumeric character and one special character',
            ),
    })

    if (!decryptedData && !decryptedData?.email) {
        return (
            <Container className="sign-up-wrapper">
                <Grid container className="form-wrapper" style={{ gap: '10px', height: '100vh' }}>
                    <Grid item xs={12} sm={12} span={12} ml={2}>
                        <Grid className="logo-wrapper">
                            <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            span={12}
                            ml={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    border: '1px solid #CFCFCF',
                                    background: '#FAFAFA',
                                    flexDirection: 'column',
                                    width: '637px',
                                    height: '342px',
                                    gap: '5px',
                                }}>
                                <span className="text-verification">Invalid Link</span>
                                <ButtonSpinner
                                    // disabled={Boolean(isSubmitting)}
                                    // isLoading={Boolean(isSubmitting)}
                                    onclick={() => history.push('/')}
                                    label={'Go back to home'}
                                    className="btn-portal"
                                    sx={{
                                        width: '50%',
                                        textTransform: 'none',
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    }
    return (
        <Container className="sign-up-wrapper">
            <Grid container className="form-wrapper">
                <Grid item xs={12} sm={6} span={12}>
                    <div
                        className="form-img"
                        style={{
                            // background:
                            // 'url(http://source.unsplash.com/620x700/?medical)',
                            background: `url(${randomBgImage})`,
                            borderRadius: '10px',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} span={12} ml={2} className="right-panel">
                    <Grid className="logo-wrapper">
                        <img className="form-logo" src={logo} alt="TeleMed2U"></img>
                    </Grid>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            name: decryptedData?.first_name + '  ' + decryptedData?.last_name || '',
                            email: decryptedData?.email || '',
                            otp: decryptedData?.otp || '',
                            password: '',
                        }}
                        validationSchema={validateSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                const res = await handleSubmit(values)
                                if (res && res?.data?.status) {
                                    const { accessToken, refreshToken } = res?.data?.data
                                    localStorage.setItem('access_token', accessToken)
                                    localStorage.setItem('refresh_token', refreshToken)
                                    setTimeout(() => {
                                        dispatch(getLoggedInUserDetails())
                                        history.push('/dashboard')
                                    }, 2000)
                                } else {
                                    notification(res?.data?.message || 'Somthing is wrong', 'error')
                                }
                            } catch (error) {
                                notification('Somthing is wrong', 'error')
                            }
                            setSubmitting(false)
                        }}>
                        {({
                            errors,
                            touched,
                            values,
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                        }) => {
                            return (
                                <form
                                    className="form registration"
                                    onSubmit={handleSubmit}
                                    noValidate>
                                    <div className="inner-wrapper-logo">
                                        <img
                                            className="mobile-logo"
                                            src={logo}
                                            alt="TeleMed2U"></img>
                                    </div>

                                    <h1>Sign up to continue!</h1>
                                    <FormLabel className="input-label" component="legend">
                                        Email Address
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-email"
                                        required
                                        error={errors.email && touched.email}
                                        helperText={
                                            errors.email && touched.email ? errors.email : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="signup-text-input"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={values.email}
                                        onChange={handleChange}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle  visibility"
                                                        edge="start">
                                                        <EmailIcon
                                                            style={{
                                                                color: '#667689',
                                                                fontSize: '12px',
                                                                marginLeft: '5px',
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormLabel className="input-label" component="legend">
                                        Full Name
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-name"
                                        required
                                        error={errors.name && touched.name}
                                        helperText={errors.name && touched.name ? errors.name : ''}
                                        variant="outlined"
                                        size="small"
                                        className="signup-text-input"
                                        name="name"
                                        placeholder="Patient Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <FormLabel className="input-label" component="legend">
                                        Password
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-password"
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        error={errors.password && touched.password}
                                        helperText={
                                            errors.password && touched.password
                                                ? errors.password
                                                : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="signup-text-input"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end">
                                                        {showPassword ? (
                                                            <VisibilityOffOutlinedIcon
                                                                style={{
                                                                    color: '#667689',
                                                                    marginRight: '10px',
                                                                    fontSize: '18px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <VisibilityOutlinedIcon
                                                                style={{
                                                                    color: '#667689',
                                                                    marginRight: '10px',
                                                                    fontSize: '18px',
                                                                }}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box className="box-span-container">
                                        <Box className="box-span-text-icon">
                                            <CheckCircleOutlineIcon
                                                style={{
                                                    width: '13px',
                                                    color:
                                                        values.password &&
                                                        values.password.length >= 8
                                                            ? 'green'
                                                            : '#CDD0D4',
                                                }}
                                            />
                                            <span className="span-text">
                                                Your password must be at least 8 characters long.
                                            </span>
                                        </Box>
                                        <Box className="box-span-text-icon">
                                            <CheckCircleOutlineIcon
                                                style={{
                                                    width: '13px',
                                                    color:
                                                        values.password &&
                                                        /[^a-zA-Z0-9]/.test(values.password)
                                                            ? 'green'
                                                            : '#CDD0D4',
                                                }}
                                            />
                                            <span className="span-text">
                                                Your password must include at least one special
                                                character.
                                            </span>
                                        </Box>
                                        <Box className="box-span-text-icon">
                                            <CheckCircleOutlineIcon
                                                style={{
                                                    width: '13px',
                                                    color:
                                                        values.password &&
                                                        /\d/.test(values.password) &&
                                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/.test(
                                                            values.password,
                                                        )
                                                            ? 'green'
                                                            : '#CDD0D4',
                                                }}
                                            />
                                            <span className="span-text">
                                                Your password must include at least one alphanumeric
                                                character (a-z, A-Z, 0-9).
                                            </span>
                                        </Box>
                                    </Box>

                                    <div className="submit">
                                        <ButtonSpinner
                                            disabled={Boolean(isSubmitting)}
                                            isLoading={Boolean(isSubmitting)}
                                            label={'Sign up'}
                                            className="signup-submit"
                                            sx={{
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                    <Grid className="form-footer">
                        <p className="footer-tag">
                            If you have any questions or need assistance, please call us at{' '}
                            <a>(562) 268-0955</a>.{' '}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SignUpForm
