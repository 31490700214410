import {
    Grid,
    makeStyles,
    Select,
    TextField,
    MenuItem,
    Button,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addParticipant } from '../../store/actions/provider'
import './styles.scss'

const useStyles = makeStyles({
    addParticipantContainer: {
        width: '450px',
        height: '400px',
        maxWidth: '450px',
        maxHeight: '400px',
        borderRadius: '0px 16px 16px 0px',
        textAlign: 'left',
        fontWeight: '500',
        boxShadow: '0px 16px 24px rgba(136, 145, 156, 0.2)',
        // padding: '20px 0',
        overflowY: 'scroll',
    },
    addParticipantWrapper: {
        margin: '20px 20px auto',
    },
    sectionHeader: {
        marginBottom: '20px',
        fontSize: '16px',
    },
    inputInfo: {
        marginTop: '8px',
        height: '40px',
        width: '100%',
        background: '#FBFCFC',
        borderRadius: '5px',
    },
    firstSectionItem: {
        display: 'flex',
        marginBottom: '20px',
    },
    sectionItem: {
        marginTop: '16px',
    },
    sectionLeft: {
        width: '198px',
        marginRight: '16px',
        fontSize: '14px',
    },
    sectionRight: {
        width: '198px',
        alignContent: 'flex-end',
    },
    btnAdd: {
        width: '100%',
        backgroundColor: '#5571C6',
        marginBottom: '20px',
    },
})

const AddParticipant = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [participantInfo, setParticipantInfo] = useState({
        relationShip: 'ADM',
    })
    const relationShipList = [
        { code: 'ADM', name: 'Admitter' },
        { code: 'ATND', name: 'Attender' },
        { code: 'CALLBCK', name: 'Callback contact' },
        { code: 'CON', name: 'Consultant' },
        { code: 'DIS', name: 'Discharger' },
        { code: 'ESC', name: 'Escort' },
        { code: 'REF', name: 'Referrer' },
        { code: 'SPRF', name: 'Secondary performer' },
        { code: 'PPRF', name: 'Primary performer' },
        { code: 'PART', name: 'Participation' },
        { code: 'TRAS', name: 'Translator' },
        { code: 'EMER', name: 'Emergency' },
    ]
    const encounterMeetingId = useSelector(
        (state) => state.provider.encounterMeetingId,
    )

    const handleTextChange = (e) => {
        const { name, value } = e.target
        setParticipantInfo((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleAddParticipant = () => {
        dispatch(
            addParticipant({
                ...participantInfo,
                encounter_id: encounterMeetingId,
            }),
        )
    }

    return (
        <Grid className={classes.addParticipantContainer}>
            <Grid className={classes.addParticipantWrapper}>
                <Grid className={classes.sectionHeader}>
                    <header>Add Provider</header>
                </Grid>
                <Grid className={classes.firstSectionItem}>
                    <div className={classes.sectionLeft}>
                        <div>First name *</div>
                        <div>
                            <TextField
                                className={
                                    classes.inputInfo
                                }
                                name="first_name"
                                variant="outlined"
                                placeholder="First name"
                                onChange={(e) => {
                                    handleTextChange(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.sectionRight}>
                        <div>Last name *</div>
                        <div>
                            <TextField
                                className={
                                    classes.inputInfo
                                }
                                name="last_name"
                                variant="outlined"
                                placeholder="Last name"
                                onChange={(e) => {
                                    handleTextChange(e)
                                }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid className={classes.sectionItem}>
                    <div>Email</div>
                    <div>
                        <TextField
                            className={classes.inputInfo}
                            name="email"
                            variant="outlined"
                            placeholder="Email"
                            onChange={(e) => {
                                handleTextChange(e)
                            }}
                        />
                    </div>
                </Grid>
                <Grid className={classes.sectionItem}>
                    <div>Phone number</div>
                    <div>
                        <TextField
                            className={classes.inputInfo}
                            name="phone_number"
                            variant="outlined"
                            placeholder="Phone number"
                            onChange={(e) => {
                                handleTextChange(e)
                            }}
                        />
                    </div>
                </Grid>
                <Grid className={classes.sectionItem}>
                    <div>Relationship *</div>
                    <div className={classes.inputInfo}>
                        <Select
                            variant="outlined"
                            name="relation_ship"
                            value={
                                participantInfo.relationShip
                            }
                            placeholder="Select"
                            onChange={(e) =>
                                handleTextChange(e)
                            }
                            fullWidth>
                            {relationShipList.map(
                                (item) => (
                                    <MenuItem
                                        value={item.code}
                                        key={item.code}>
                                        {item.name}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </div>
                </Grid>
                <Grid className={classes.sectionItem}>
                    <Button
                        className={classes.btnAdd}
                        onClick={() =>
                            handleAddParticipant()
                        }>
                        Add
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddParticipant
