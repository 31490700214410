import React, { useEffect, useState, useMemo } from 'react'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import AppStateProvider, { useAppState } from './state'
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import ErrorDialog from './components/ErrorDialog/ErrorDialog'
import LoginPage from './components/LoginPage/LoginPage'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import theme from './theme'
import './types'
import { ChatProvider } from './components/ChatProvider'
import { VideoProvider } from './components/VideoProvider'
import useConnectionOptions from './utilities/useConnectionOptions/useConnectionOptions'
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning'
import Consultation from './Consultation'
import EndMeeting from './EndMeeting'
import { ProviderDetail } from './components/ProviderDetail'

import LoginVideoScreen from './LoginVideoScreen'
import PreJoinscreen from './components/PreJoinScreens/PreJoinScreen'
import JoinMainRoom from './JoinMainRoom'
import JoinWaitMainRoom from './JoinWaitMainRoom'
import InvalidLink from './InvalidLink'
import ShortLink from './ShortLink'
import WaitingRoom from '../Telehealth/WaitingRoom'
import VideoRoom from '../Telehealth/VideoRoom'
import { UserContext } from '../Telehealth/context/UserContext'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const MainIndex = () => {
    const { error, setError } = useAppState()
    const connectionOptions = useConnectionOptions()
    const [socket, setSocket] = useState(null)

    let query = useQuery()
    let data = query.get('data')
    useEffect(() => {
        localStorage.setItem('urlLink', data)
    }, [])
    const [user, setUser] = useState({
        videoFilter: {
            filterName: false,
            filterPayload: false,
        },
        defaultSettings: {
            publishAudio: true,
            publishVideo: true,
            audioSource: undefined,
            videoSource: undefined,
            audioOutput: undefined,
        },
    })
    const userValue = useMemo(() => ({ user, setUser }), [user, setUser])

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <UnsupportedBrowserWarning>
                <Router>
                    <UserContext.Provider value={userValue}>
                        <VideoProvider options={connectionOptions} onError={setError}>
                            {/* <ErrorDialog
                            dismissError={() =>
                                setError(null)
                            }
                            error={error}
                        /> */}
                            <ChatProvider>
                                <ProviderDetail socket={socket}>
                                    <AppStateProvider>
                                        <Switch>
                                            <Route path="/video/:data" exact>
                                                <LoginVideoScreen />
                                            </Route>
                                            <Route path="/join">
                                                <JoinMainRoom />
                                            </Route>
                                            <Route path="/join-wait">
                                                <JoinWaitMainRoom />
                                            </Route>
                                            <Route path="/encounter-link/:shortLink">
                                                <ShortLink />
                                            </Route>
                                            <Route path="/end-meeting">
                                                <EndMeeting />
                                            </Route>
                                            <Route path="/invalid-link">
                                                <InvalidLink />
                                            </Route>
                                            <PrivateRoute path="/video">
                                                <WaitingRoom />
                                            </PrivateRoute>
                                            <PrivateRoute path="/room">
                                                <VideoRoom />
                                            </PrivateRoute>
                                            {/* <PrivateRoute path="/video">
                                            <LoginVideoScreen />
                                        </PrivateRoute>
                                        <PrivateRoute path="/room">
                                            <Consultation />
                                        </PrivateRoute> */}
                                            <PrivateRoute path="/prejoinscreen/:name/:roomName">
                                                <PreJoinscreen />
                                            </PrivateRoute>
                                            <Route path="/login">
                                                <LoginPage />
                                            </Route>
                                            {/* <Redirect to="/"/> */}
                                        </Switch>
                                    </AppStateProvider>
                                </ProviderDetail>
                            </ChatProvider>
                        </VideoProvider>
                    </UserContext.Provider>
                </Router>
            </UnsupportedBrowserWarning>
        </MuiThemeProvider>
    )
}

export default MainIndex
