import * as yup from 'yup'

export const validation = yup.object().shape({
    termsAgree: yup.boolean().test({
        name: 'termsAgree',
        test(value, context) {
            if (!value) {
                return this.createError({
                    message: 'This field is required.',
                    path: context.path,
                })
            }
            return true
        },
    }),
    releaseOfInfo: yup.boolean().test({
        name: 'releaseOfInfo',
        test(value, context) {
            if (!value) {
                return this.createError({
                    message: 'This field is required.',
                    path: context.path,
                })
            }
            return true
        },
    }),
    regularConsultation: yup.string().required('Please select any of the options.'),
    regularConsultationPlace: yup.string().test({
        name: 'regularConsultationPlace',
        test(value, context) {
            if ((!value || value === '') && context.parent?.regularConsultation === 'yes') {
                return this.createError({
                    message: 'This field is required.',
                    path: context.path,
                })
            }
            return true
        },
    }),
    insurance: yup.string().required('Please select any of the options.'),
    legalIssues: yup.string().required('Please select any of the options.'),
    harmingOthers: yup.object().test({
        name: 'harmingOthers',
        test(value, context) {
            if (value && ![value?.past, value?.present, value?.never].includes(true)) {
                return this.createError({
                    message: 'Please select atleast one option.',
                    path: context.path,
                })
            }
            return true
        },
    }),
    alcoholAbuse: yup.object().test({
        name: 'alcoholAbuse',
        test(value, context) {
            if (value && ![value?.past, value?.present, value?.never].includes(true)) {
                return this.createError({
                    message: 'Please select atleast one option.',
                    path: context.path,
                })
            }
            return true
        },
    }),
    suicidal: yup.object().test({
        name: 'suicidal',
        test(value, context) {
            if (value && ![value?.past, value?.present, value?.never].includes(true)) {
                return this.createError({
                    message: 'Please select atleast one option.',
                    path: context.path,
                })
            }
            return true
        },
    }),
    substanceAbuse: yup.object().test({
        name: 'substanceAbuse',
        test(value, context) {
            if (value && ![value?.past, value?.present, value?.never].includes(true)) {
                return this.createError({
                    message: 'Please select atleast one option.',
                    path: context.path,
                })
            }
            return true
        },
    }),
})
