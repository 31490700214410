import { Grid } from '@material-ui/core'
import AppointmentDetails from '../Dashboard/AppointmentDetails'
import HeaderNew from '../Header/HeaderNew'
import Main from './Main'

function ClinicalNotes() {
    return (
        <>
            <Main />
        </>
    )
}

export default ClinicalNotes
