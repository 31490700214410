import { Theme, useMediaQuery } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import React, { useEffect, useRef } from 'react'
import { Track } from 'twilio-video'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions/useVideoTrackDimensions'
import { IVideoTrack } from '../../types'

const Video = styled('video')({
    width: '100%',
    height: '100%',
})

interface VideoTrackProps {
    track: IVideoTrack
    isLocal?: boolean
    priority?: Track.Priority | null
    participant?: any
}

export default function VideoTrack({ track, isLocal, priority, participant }: VideoTrackProps) {
    const ref = useRef<HTMLVideoElement>(null!)

    const mediaStreamTrack = useMediaStreamTrack(track)
    const dimensions = useVideoTrackDimensions(track)

    const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0)
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    useEffect(() => {
        const el = ref.current
        el.muted = true
        if (track.setPriority && priority) {
            track.setPriority(priority)
        }
        track.attach(el)
        return () => {
            track.detach(el)
            if (track.setPriority && priority) {
                // Passing `null` to setPriority will set the track's priority to that which it was published with.
                track.setPriority(null)
            }
        }
    }, [track, priority])

    // The local video track is mirrored if it is not facing the environment.
    const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment'
    const style = {
        transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
        objectFit: isMobile ? ('revert' as const) : ('cover' as const),
        // objectFit:isMobile?('cover' as const):'',
    }

    return (
        <>
            <Video ref={ref} style={style} />
        </>
    )
}
