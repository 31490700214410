import moment from 'moment'
import { SCREEN } from './constant'

export const INITIAL_VALUES = {
    start: moment(),
    end: moment(),
    category: [
        {
            key: 'red',
            value: 'Red',
        },
        {
            key: 'white',
            value: 'White',
        },
    ],
    selectedTab: SCREEN.PATIENT_DETAILS,
    selectedScreen: SCREEN.PATIENT_DETAILS,
    isNewPatient: false,
    patient: null,
    patient_type: null,
    minor: '',
    appointment_reason: '',
    additional_info: '',
    questions: {
        1: null,
        2: null,
        3: null,
    },
    provider: {
        name: 'Test',
        speciality: 'Speciality',
        start: new Date(),
        end: new Date(),
    },
}
