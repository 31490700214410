import {
    FormControl,
    InputLabel,
    TextField,
} from '@material-ui/core'
import React from 'react'
import useStyles from './styles'

const TextInput = (props) => {
    const classes = useStyles(props)

    const handleChange = (event) => {
        props.onSearch && props.onSearch(event.target.value)
        props.onChange && props.onChange(event.target.value)
    }

    return (
        <FormControl fullWidth variant="outlined">
            {props.label && (
                <InputLabel>{props.label}</InputLabel>
            )}
            <TextField
                onChange={handleChange}
                value={props.value}
                placeholder={props.placeholder}
                variant="outlined"
                autoComplete="new-password"
                InputProps={{
                    classes: {
                        root: classes.root,
                        input: classes.input,
                        focused: classes.cssFocused,
                        notchedOutline:
                            classes.notchedOutline,
                    },
                }}
                inputProps={{
                    ...props.inputProps,
                    autoComplete: 'new-password',
                }}
                {...props}
            />
        </FormControl>
    )
}

TextInput.defaultProps = {
    height: 40,
    border: '1px solid $secondaryGray',
}

export default TextInput
