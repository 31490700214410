import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useFormik } from 'formik'
import React from 'react'
import closeIcon from '../../../assests/images/close_icon.png'
import { MarkConsultationSchema } from '../utils'
import '../styles.scss'

const useStyles = makeStyles({
    radio: {
        '&$checked': {
            color: '#546FC9',
        },
    },
    checked: {},
})

function MarkEachConsulationModal(props) {
    const classes = useStyles()

    const formik = useFormik({
        initialValues: {
            appointmentStatus: '',
            followUp: {
                interval: 'week',
                period: 0,
            },
        },
        validationSchema: MarkConsultationSchema,
        validateOnChange: true,
        onSubmit: props.onSubmit,
    })

    React.useEffect(() => {
        if (props.appointmentStatus) {
            formik.setFieldValue(
                'appointmentStatus',
                props.appointmentStatus,
            )
        }
    }, [props.appointmentStatus])

    React.useEffect(() => {
        if (
            formik.values.followUp.interval === 'week' &&
            formik.values.followUp.period > 4
        ) {
            formik.setFieldValue('followUp.period', 4)
        }
    }, [formik.values.followUp.interval])

    return (
        <Modal open={props.opened} onClose={props.onClose}>
            <div className="mark__icc__modal__container">
                <form onSubmit={formik.handleSubmit}>
                    <Grid container direction="column">
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-end">
                            <img
                                className="mark__icc__modal__cancel-btn"
                                src={closeIcon}
                                role="presentation"
                                onClick={props.onClose}
                                alt="close-icon"
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center">
                            <Typography
                                id="modal-modal-title"
                                className="mark__icc__modal__title"
                                variant="inherit"
                                component="h3"
                                align="center">
                                <strong>
                                    Consultation Status
                                </strong>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ p: 4 }}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        marginBottom: 16,
                                    }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            Appointment
                                            Status
                                        </FormLabel>
                                        <RadioGroup
                                            id="appointmentStatus"
                                            aria-label="appointmentStatus"
                                            name="appointmentStatus"
                                            row={true}
                                            onChange={
                                                formik.handleChange
                                            }>
                                            <FormControlLabel
                                                value={
                                                    'fulfilled'
                                                }
                                                control={
                                                    <Radio
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                }
                                                label="Complete"
                                                checked={
                                                    formik
                                                        .values
                                                        .appointmentStatus ===
                                                    'fulfilled'
                                                }
                                            />
                                            <FormControlLabel
                                                value={
                                                    'noshow'
                                                }
                                                control={
                                                    <Radio
                                                        classes={{
                                                            root: classes.radio,
                                                            checked:
                                                                classes.checked,
                                                        }}
                                                    />
                                                }
                                                label="No Show"
                                                checked={
                                                    formik
                                                        .values
                                                        .appointmentStatus ===
                                                    'noshow'
                                                }
                                            />
                                        </RadioGroup>
                                        {formik.errors
                                            .appointmentStatus &&
                                        formik.touched
                                            .appointmentStatus ? (
                                            <Typography
                                                variant="caption"
                                                style={{
                                                    color: 'red',
                                                }}>
                                                {
                                                    formik
                                                        .errors
                                                        .appointmentStatus
                                                }
                                            </Typography>
                                        ) : null}
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                alignItems="center">
                                <FormLabel component="legend">
                                    Follow-up in
                                </FormLabel>
                                <Box sx={{ paddingY: 2 }}>
                                    <Grid container>
                                        <Grid item>
                                            <TextField
                                                id="follow-up-period"
                                                aria-label="follow-up-period"
                                                name="followUp.period"
                                                hiddenLabel
                                                type="number"
                                                size="small"
                                                variant="outlined"
                                                value={
                                                    formik
                                                        .values
                                                        .followUp
                                                        .period
                                                }
                                                InputProps={{
                                                    inputProps:
                                                        {
                                                            min: 0,
                                                            max:
                                                                formik
                                                                    .values
                                                                    .followUp
                                                                    .interval ===
                                                                'week'
                                                                    ? 4
                                                                    : formik
                                                                          .values
                                                                          .followUp
                                                                          .interval ===
                                                                      'month'
                                                                    ? 12
                                                                    : null,
                                                        },
                                                }}
                                                onChange={
                                                    formik.handleChange
                                                }
                                                style={{
                                                    width: '80px',
                                                    marginRight:
                                                        '28px',
                                                }}
                                                autoFocus
                                                required
                                                aria-required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <RadioGroup
                                                id="follow-up-interval"
                                                aria-label="follow-up-interval"
                                                name="followUp.interval"
                                                row={true}
                                                onChange={
                                                    formik.handleChange
                                                }>
                                                <FormControlLabel
                                                    value={
                                                        'week'
                                                    }
                                                    control={
                                                        <Radio
                                                            classes={{
                                                                root: classes.radio,
                                                                checked:
                                                                    classes.checked,
                                                            }}
                                                        />
                                                    }
                                                    label="Week(s)"
                                                    checked={
                                                        formik
                                                            .values
                                                            .followUp
                                                            .interval ===
                                                        'week'
                                                    }
                                                />
                                                <FormControlLabel
                                                    value={
                                                        'month'
                                                    }
                                                    control={
                                                        <Radio
                                                            classes={{
                                                                root: classes.radio,
                                                                checked:
                                                                    classes.checked,
                                                            }}
                                                        />
                                                    }
                                                    label="Month(s)"
                                                    checked={
                                                        formik
                                                            .values
                                                            .followUp
                                                            .interval ===
                                                        'month'
                                                    }
                                                />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid
                                container
                                item
                                spacing={2}
                                justifyContent="center">
                                <Box
                                    sx={{
                                        paddingX: 1,
                                        paddingY: 8,
                                    }}>
                                    <Button
                                        onClick={
                                            props.onClose
                                        }
                                        variant="outlined"
                                        size="large">
                                        Discard
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        paddingX: 1,
                                        paddingY: 8,
                                    }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        size="large">
                                        Submit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </div>
        </Modal>
    )
}

export default MarkEachConsulationModal
