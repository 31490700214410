import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    FormControlLabel,
    Grid,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertTitle } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
import { FieldArray, Form, Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { getClinics } from '../../../services/Calendar'
import {
    SelectEl,
    DatePickerEl,
} from '../../../shared/elements'
import {
    REPEAT_INTERVALS,
    SLOT_TYPES,
    TIMEZONES,
    VIEW,
} from '../constant'
import styles from '../styles'
import {
    generateRange,
    generateSuffix,
    getWeekdays,
    getWeekInfo,
} from '../utils'
import { INITIAL_VALUES, validationSchema } from './schema'

function AddScheduleModal(props) {
    const [initialValues, setInitialValues] =
        React.useState(INITIAL_VALUES)
    const [formValues, setFormValues] = React.useState([])
    const [isDraftSaving, setDraftSaving] =
        React.useState(false)

    const range = React.useMemo(() => {
        const output = []
        const start_date = moment().set({
            hour: 0,
            minute: 0,
        })
        const end_date = moment().set({
            hour: 23,
            minute: 59,
        })

        const dates = generateRange({
            start_date,
            end_date,
            interval: 'minutes',
            step: 15,
        })

        for (let date of dates) {
            output.push({
                key: date,
                value: date.format('hh:mm A'),
            })
        }

        return output
    }, [props.data.status])

    React.useEffect(() => {
        if (props.data.status) {
            const start_index = getInitialStartTime()
            const { weekInfo, month_types } =
                generateMonthTypes(moment())

            let start = null
            let end = null

            start = range[start_index + 1]
                ? range[start_index]['key']
                : range[0]['key']
            end = range[start_index + 1]
                ? range[start_index + 1]['key']
                : range[1]['key']

            getClinics(props.data.provider.id)
                .then((response) => {
                    console.log({
                        tz: props.data.provider.timezone,
                    })
                    setInitialValues({
                        ...initialValues,
                        clinics: response.data
                            .filter(
                                (clinic) =>
                                    clinic.is_active,
                            )
                            .map((clinic) => ({
                                key: clinic.id,
                                value: clinic.clinic_name,
                            })),
                        timezone:
                            TIMEZONES.find(
                                (tz) =>
                                    tz.abbr ===
                                    props.data.provider
                                        .timezone,
                            )?.key ?? '',
                        start,
                        end,
                        weekdays: getWeekdays().map(
                            (day) => ({
                                key: day,
                                value: day[0],
                            }),
                        ),
                        month_types,
                        weekInfo,
                    })
                })
                .catch((error) => {
                    setInitialValues({
                        ...initialValues,
                        timezone:
                            TIMEZONES.find(
                                (tz) =>
                                    tz.abbr ===
                                    props.data.provider
                                        .timezone,
                            )?.value ?? '',
                        start,
                        end,
                        weekdays: getWeekdays().map(
                            (day) => ({
                                key: day,
                                value: day[0],
                            }),
                        ),
                        month_types,
                        weekInfo,
                    })
                })
        }
    }, [props.data, props.open])

    function generateMonthTypes(date) {
        const weekInfo = getWeekInfo({
            date: moment(date),
            start_date: moment(date).startOf('month'),
            end_date: moment(date).endOf('month'),
        })

        const month_types = [
            {
                key: 0,
                value: `Monthly on day ${weekInfo.day_number}`,
            },
            {
                key: 1,
                value: `Monthly on the ${generateSuffix(
                    weekInfo.day_week_number,
                )} ${weekInfo.date.format('dddd')}`,
            },
        ]

        return { weekInfo, month_types }
    }

    function getInitialStartTime() {
        const date = initialValues.current_date
        let output = 0

        for (let d = 0; d < range.length; d++) {
            if (range[d]['key'].isAfter(date)) {
                output = d
                break
            }
        }

        return output
    }

    return (
        <Modal
            aria-labelledby="AddSchedule"
            aria-describedby="add schedule"
            open={props.data.status}
            onClose={props.onClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendarModal">
            <div className={props.classes.paper}>
                <Grid
                    container
                    direction="column"
                    className="appointmentModal"
                    justifyContent="center">
                    <Grid item>
                        <header>
                            <span>Add Schedule</span>
                            <CloseIcon
                                style={{
                                    color: '#FFF',
                                    cursor: 'pointer',
                                }}
                                onClick={props.onClose}
                            />
                        </header>
                        <Collapse
                            orientation="verticle"
                            in={isDraftSaving}>
                            <Alert severity="info">
                                <AlertTitle>
                                    <strong>Info</strong>
                                </AlertTitle>
                                The draft saved
                                successfully.
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validateOnBlur={true}
                        onSubmit={(values) => {
                            props.onSubmit([
                                ...formValues,
                                values,
                            ])
                            setFormValues([])
                        }}>
                        {({
                            values,
                            isValid,
                            dirty,
                            handleChange,
                            setFieldValue,
                            resetForm,
                        }) => {
                            return (
                                <Form>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{
                                            padding: 28,
                                        }}>
                                        <Grid item xs={12}>
                                            <SelectEl
                                                name="timezone"
                                                placeholder="Select Timezone"
                                                value={
                                                    values.timezone
                                                }
                                                onChange={handleChange(
                                                    'timezone',
                                                )}
                                                options={
                                                    TIMEZONES
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectEl
                                                name="slot_type"
                                                placeholder="Select Slot Type"
                                                value={
                                                    values.slot_type
                                                }
                                                onChange={handleChange(
                                                    'slot_type',
                                                )}
                                                options={
                                                    SLOT_TYPES
                                                }
                                            />
                                        </Grid>
                                        {values.slot_type ===
                                            'exclusive' && (
                                            <Grid
                                                item
                                                xs={12}>
                                                <SelectEl
                                                    name="clinic_id"
                                                    placeholder="Select Clinic Name"
                                                    value={
                                                        values.clinic_id
                                                    }
                                                    onChange={handleChange(
                                                        'clinic_id',
                                                    )}
                                                    options={
                                                        values.clinics
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            spacing={2}>
                                            <Grid
                                                item
                                                xs={6}>
                                                <DatePickerEl
                                                    name="start_date"
                                                    value={
                                                        values.start_date
                                                    }
                                                    minDate={
                                                        values.current_date
                                                    }
                                                    maxDate={
                                                        values.end_date ||
                                                        undefined
                                                    }
                                                    format="MM/DD/YYYY"
                                                    placeholder="From"
                                                    onChange={(
                                                        date,
                                                    ) => {
                                                        const {
                                                            weekInfo,
                                                            month_types,
                                                        } =
                                                            generateMonthTypes(
                                                                date,
                                                            )

                                                        setFieldValue(
                                                            'start_date',
                                                            date,
                                                        )
                                                        setFieldValue(
                                                            'weekInfo',
                                                            weekInfo,
                                                        )
                                                        setFieldValue(
                                                            'month_types',
                                                            month_types,
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}>
                                                <SelectEl
                                                    name="start"
                                                    placeholder="Select Start Time"
                                                    value={
                                                        values.start
                                                    }
                                                    onChange={handleChange(
                                                        'start',
                                                    )}
                                                    options={
                                                        range
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            spacing={2}>
                                            <Grid
                                                item
                                                xs={6}>
                                                <DatePickerEl
                                                    name="end_date"
                                                    value={
                                                        values.end_date
                                                    }
                                                    minDate={
                                                        values.start_date ||
                                                        values.current_date
                                                    }
                                                    maxDate={values.current_date
                                                        .clone()
                                                        .add(
                                                            1,
                                                            VIEW.YEAR,
                                                        )}
                                                    format="MM/DD/YYYY"
                                                    placeholder="To"
                                                    onChange={(
                                                        date,
                                                    ) =>
                                                        setFieldValue(
                                                            'end_date',
                                                            date,
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}>
                                                <SelectEl
                                                    name="end"
                                                    placeholder="Select End Time"
                                                    value={
                                                        values.end
                                                    }
                                                    onChange={handleChange(
                                                        'end',
                                                    )}
                                                    options={range.filter(
                                                        (
                                                            date,
                                                        ) =>
                                                            values.start
                                                                ? date[
                                                                      'key'
                                                                  ].isAfter(
                                                                      values.start,
                                                                  )
                                                                : true,
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                row
                                                defaultValue={
                                                    values.is_repeat
                                                }
                                                value={
                                                    values.is_repeat
                                                }
                                                onChange={(
                                                    event,
                                                ) =>
                                                    setFieldValue(
                                                        'is_repeat',
                                                        Number(
                                                            event
                                                                .target
                                                                .value,
                                                        ),
                                                    )
                                                }
                                                name="is_repeat"
                                                aria-label="is-repeat"
                                                name="is-repeat-group">
                                                <FormControlLabel
                                                    value={
                                                        1
                                                    }
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label="Repeat"
                                                />
                                                <FormControlLabel
                                                    value={
                                                        0
                                                    }
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label="No Repeat"
                                                />
                                            </RadioGroup>
                                        </Grid>

                                        {!!values.is_repeat && (
                                            <>
                                                <Grid
                                                    container
                                                    item
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={
                                                        2
                                                    }>
                                                    <Grid
                                                        item
                                                        xs={
                                                            3
                                                        }>
                                                        <Typography
                                                            noWrap>
                                                            Repeat
                                                            every:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            4
                                                        }>
                                                        <TextField
                                                            id="repeat_number"
                                                            name="repeat_number"
                                                            type="number"
                                                            variant="outlined"
                                                            value={
                                                                values.repeat_number
                                                            }
                                                            fullWidth
                                                            inputProps={{
                                                                style: {
                                                                    height: 12,
                                                                },
                                                                min: 1,
                                                                max:
                                                                    values.repeat_type ===
                                                                    VIEW.DAY
                                                                        ? 31
                                                                        : values.repeat_type ===
                                                                          VIEW.WEEK
                                                                        ? 4
                                                                        : values.repeat_type ===
                                                                          VIEW.MONTH
                                                                        ? 12
                                                                        : 5,
                                                            }}
                                                            onChange={handleChange(
                                                                'repeat_number',
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            5
                                                        }>
                                                        <SelectEl
                                                            name="repeat_type"
                                                            placeholder="Select Interval"
                                                            value={
                                                                values.repeat_type
                                                            }
                                                            onChange={(
                                                                e,
                                                            ) => {
                                                                setFieldValue(
                                                                    'repeat_type',
                                                                    e
                                                                        .target
                                                                        .value,
                                                                )
                                                                setFieldValue(
                                                                    'repeat_number',
                                                                    1,
                                                                )
                                                            }}
                                                            options={
                                                                REPEAT_INTERVALS
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {values.repeat_type ===
                                                    VIEW.WEEK && (
                                                    <Grid
                                                        container
                                                        item
                                                        spacing={
                                                            1
                                                        }>
                                                        <FieldArray name="days_of_week">
                                                            {({
                                                                push,
                                                                remove,
                                                            }) => {
                                                                return values.weekdays.map(
                                                                    (
                                                                        day,
                                                                    ) => {
                                                                        const itemIndex =
                                                                            values.days_of_week.indexOf(
                                                                                day.key,
                                                                            )
                                                                        const isExists =
                                                                            itemIndex >
                                                                            -1
                                                                        return (
                                                                            <Grid
                                                                                key={
                                                                                    day.key
                                                                                }
                                                                                item
                                                                                xs>
                                                                                <Box>
                                                                                    <Paper
                                                                                        style={{
                                                                                            display:
                                                                                                'flex',
                                                                                            height: 34,
                                                                                            width: '100%',
                                                                                            backgroundColor:
                                                                                                !isExists
                                                                                                    ? '#dce2fd'
                                                                                                    : '#5571c6',
                                                                                            color: !isExists
                                                                                                ? '#333'
                                                                                                : '#fff',
                                                                                            borderRadius: 4,
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                !isExists
                                                                                            ) {
                                                                                                push(
                                                                                                    day.key,
                                                                                                )
                                                                                            } else {
                                                                                                remove(
                                                                                                    itemIndex,
                                                                                                )
                                                                                            }
                                                                                        }}>
                                                                                        <Box m="auto">
                                                                                            <Typography
                                                                                                noWrap
                                                                                                variant="title">
                                                                                                {
                                                                                                    day.value
                                                                                                }
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Paper>
                                                                                </Box>
                                                                            </Grid>
                                                                        )
                                                                    },
                                                                )
                                                            }}
                                                        </FieldArray>
                                                    </Grid>
                                                )}
                                                {values.repeat_type ===
                                                    VIEW.MONTH && (
                                                    <Grid
                                                        container
                                                        item>
                                                        <Grid
                                                            item
                                                            xs={
                                                                3
                                                            }></Grid>
                                                        <Grid
                                                            item
                                                            xs={
                                                                9
                                                            }>
                                                            <SelectEl
                                                                name="month_type"
                                                                value={
                                                                    values.month_type
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    setFieldValue(
                                                                        'month_type',
                                                                        e
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }}
                                                                options={
                                                                    values.month_types
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </>
                                        )}

                                        <Grid
                                            container
                                            item
                                            justifyContent="center"
                                            style={{
                                                marginTop: 20,
                                            }}
                                            spacing={2}>
                                            {props.loading ? (
                                                <CircularProgress />
                                            ) : (
                                                <React.Fragment>
                                                    <Grid
                                                        item>
                                                        <Button
                                                            variant="outlined"
                                                            disabled={
                                                                !(
                                                                    isValid &&
                                                                    dirty
                                                                )
                                                            }
                                                            onClick={() => {
                                                                setFormValues(
                                                                    [
                                                                        ...formValues,
                                                                        values,
                                                                    ],
                                                                )
                                                                resetForm()
                                                                setDraftSaving(
                                                                    true,
                                                                )
                                                                setTimeout(
                                                                    () => {
                                                                        setDraftSaving(
                                                                            false,
                                                                        )
                                                                    },
                                                                    2000,
                                                                )
                                                            }}>
                                                            <Typography color="primary">
                                                                <strong>
                                                                    Save
                                                                    &
                                                                    Add
                                                                    More
                                                                </strong>
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid
                                                        item>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                !(
                                                                    isValid &&
                                                                    dirty
                                                                )
                                                            }>
                                                            <Typography
                                                                style={{
                                                                    color: '#FFF',
                                                                }}>
                                                                <strong>
                                                                    Save
                                                                </strong>
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
            </div>
        </Modal>
    )
}

export default withStyles(styles)(AddScheduleModal)
