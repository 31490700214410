import React from 'react'
import SignUpForm from '../../components/SignUp/SignUpForm'
import { completeSignUp } from '../../services/Login/login'
import { decryptData } from '../../utilities'
const SignUp = () => {
    const [decryptedData, setDecryptedData] = React.useState(null)
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const encryptedData = queryParams.get('data')
        try {
            const parsedData = decryptData(encryptedData)
            setDecryptedData(parsedData)
        } catch (error) {
            console.error('Error parsing decrypted data:', error)
        }
    }, [])
    const handleSubmit = async (values) => {
        try {
            const res = await completeSignUp(values)
            return res
        } catch (error) {
            return error
        }
    }
    return (
        <div>
            {/* <Header /> */}
            <SignUpForm handleSubmit={handleSubmit} decryptedData={decryptedData} />
            {/* <Footer /> */}
        </div>
    )
}

export default SignUp
