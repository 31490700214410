/* eslint-disable default-case */
import { getService, postService, putService } from '../../utilities/request/restClient'
import { config } from '../../constants'
import { getLoggedInUserRole } from '../../utilities'
import { UserRoles } from '../../constants/user-roles'
const axios = require('axios')
export const getPatientByUserId = (userId) => {
    return Promise.resolve({
        data: {},
    })
    // return getService(
    //     `${config.dev.baseURLV2}/patients/${userId}`,
    // )
}

export const getPatientListForUsers = (payload) => {
    const userRole = getLoggedInUserRole()
    let { filterString } = payload

    payload = {
        ...payload,
        name: payload.name?.replaceAll('/', '-'),
        limit: 10,
        start: 1,
    }
    delete payload.page

    const params = new URLSearchParams(payload)

    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin/tm2u-admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
    }
    return getService(
        `${config.dev.baseURLV2}/${userType}/patient?${params.toString()}`,
        payload,
    ).then((response) => {
        response.data.result = response.data.result.map((item) => {
            item.user_id = item.patientId
            return item
        })
        return response
    })
}

export const updatePatientStatus = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    const { userId, status } = payload

    return postService(`/${userType}/patient/change-status`, {
        user_id: userId,
        is_active: status,
    })
}

export const getPatientProfileById = (userId) => {
    return Promise.resolve({
        data: {},
    })
    // return getService(
    //     `${config.dev.baseURLV2}/patients/${userId}`,
    // )
}

export const getPatientList = (payload) =>
    // postService(`/patient/search`, payload)
    getService(`${config.dev.baseURLV2}/patient`)

export const searchPatientForProvider = (payload) =>
    postService('providers/patient/active-list', payload)

export const editPatientProfile = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    // switch (userRole) {
    //     case UserRoles.TM2U_ADMIN:
    //         userType = 'admin'
    //         break
    //     case UserRoles.TM2U_SUPER_ADMIN:
    //         userType = 'super-admin'
    //         break
    //     case UserRoles.CLINIC_CORDINATION:
    //         userType = 'clinic-admin'
    //         break
    // }
    // let personaUrl =
    //     userRole === UserRoles.PATIENT
    //         ? `${config.dev.baseURL}/patient/edit-profile`
    //         : `${config.dev.baseURL}/${userType}/patient/edit-profile`

    return putService(`${config.dev.baseURLV2}/patients/${payload.id}`, payload)
}

export const savePatientProfile = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
    }

    return postService(`${config.dev.baseURLV2}/${userType}/patient`, payload)
}
export const getPatientDetails = () =>
    // postService(`/patient/search`, payload)
    getService(`${config.dev.baseURLV2}patient_portal/me`)

// export async function makeAuthenticatedAPICall(apiUrl, authToken) {
//     const axiosConfig = {
//         headers: {
//             Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
//             'Content-Type': 'application/json', // Example content type, adjust as needed
//         },
//     }

//     // Make the GET request using Axios
//     const response = await axios.get(`${config.dev.baseURLV2}${apiUrl}`, axiosConfig)
//     return response.data // Return the response data
// }
