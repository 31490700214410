import { makeStyles } from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'
import { Height } from '@material-ui/icons'
export default makeStyles((theme) => ({
    waitingRoomContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translate(-50%, -50%)',
        backgroundColor: blueGrey[100],
        padding: '25px',
        borderRadius: 5,
        width: '400px',
        // height: '90%',
    },
    formControl: {
        width: '100%',
    },
    mediaSources: {
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    waitingRoomVideoPreview: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0px',
        minHeight: '200px',
        width: '100%',
    },
    deviceContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 5px',
    },
    deviceSettings: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    networkTestContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 5px',
    },
    flex: {
        display: 'flex',
    },
    root: {
        width: '20%',
    },
    container: {
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: `#889BD7`,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: '30px',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: 'auto !important',
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto !important',
        },
    },
    appLogo: {
        width: 250,
        [theme.breakpoints.between('xs', 'sm')]: {
            width: 150,
        },
    },
    button: {
        background: '#5571c6',
        color: 'white',
        fontSize: 15,
        fontWeight: 400,
    },
    logoContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'center',
        },
    },
    mainContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
        },
    },
    paper: {
        padding: '1em 0rem',
        borderRadius: '5px',
        maxWidth: '626px',
        // height: '372px',
        backgroundColor: '#303E4E',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    videoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    aspectRatioContainer: {
        position: 'relative',
        display: 'flex',
        width: '80%',
        marginTop: '44px',
        padding: '1em',
        margin: '1em 0',
        height: '230px',
        width: '100%',
        '& video': {
            position: 'absolute',
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
    header: {
        float: 'left',
        [theme.breakpoints.down('md')]: {
            float: 'initial',
        },
    },
    cameraAudioTest: {
        // float: 'right',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            float: 'initial',
        },
    },
    videoTest: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        // float: 'left',
        marginRight: '1em',
        [theme.breakpoints.down('md')]: {
            float: 'initial',
            display: 'flex',
            justifyContent: 'center',
            margin: '0 0 2.5em 0',
            flexWrap: 'wrap',
        },
    },
    confirmationButtons: {
        clear: 'left',
        [theme.breakpoints.down('md')]: {
            clear: 'initial',
            marginBottom: '2em',
        },
    },
    error: {
        display: 'flex',
        alignItems: 'center',
        margin: '0.5em 0',
        '& svg': {
            marginRight: '0.3em',
        },
    },
    audioTitle: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        fontSize: '18px',
        fontWeight: 500,
        gap: 6,
    },
    selectInput: {
        borderRadius: '5px',
        border: '1px solid #CDCDCD',
        background: '#FBFCFC',
        width: '100%',
        [theme.breakpoints.between('xs', 'sm')]: {
            maxWidth: '250px',
        },
    },
    conTitle: {
        color: '#fff',
        fontSize: '25px',
        fontWeight: 500,
    },
    conTitleContainer: {
        padding: '10px 0px',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    continueButton: {
        borderRadius: '5px',
        border: '1px solid #1B9B52',
        color: '#D8D8D8',
        background: '#15A452',
        '&.Mui-disabled': {
            border: '1px solid #60B082',
            background: '#84BB9C',
        },
    },
    cameraAudioTestContainer: {
        // float: 'right',
        display: 'flex',
        gap: 50,
        width: '100%',
        marginTop: 10,
    },
    formListContainer: {
        // float: 'right',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    boxIconContainer: {
        width: '74px',
        height: '44px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    gridIconContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        gap: 5,
    },
    instrucTitle: {
        color: '#fff',
        fontSize: '17px',
        fontWeight: 400,
    },
    instrucContainer: {
        display: 'inline-flex',
        gap: 10,
    },
    selectContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    errorContainer: {
        background: '#667689CC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        padding: '0.5em 0em',
    },
    numberSpan: {
        height: '14px',
        width: '14px',
        background: '#5571C6',
        borderRadius: '50%',
        color: '#fff',
        textAlign: 'center',
        fontSize: '10px',
    },
    refreshButton: {
        borderRadius: '5px',
        color: '#D8D8D8',
    },
    audioTest: {
        width: '100%',
        float: 'right',
        marginRight: '1em',
        [theme.breakpoints.down('md')]: {
            float: 'initial',
            display: 'flex',
            justifyContent: 'center',
            margin: '0 0 2em 0',
        },
    },
}))
