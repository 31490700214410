import React, { Component } from 'react'
import TableHeader from './TableHeader'
import TableRows from './TableRows'
import './Table.scss'

const sortOrderOptions = Object.freeze({
    ASC: 'ascending order',
    DESC: 'descending order',
})

class Table extends Component {
    componentDidMount() {
        const { tableData } = this.props
        this.setState({ tableData: tableData })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            const { tableData } = this.props
            this.setState({
                tableData,
            })
        }
    }

    toggleSort = (option) => {
        const { activeSortedHead, sortOrder } = this.state
        const { name } = option

        let currentSortOrder = sortOrderOptions.ASC

        if (activeSortedHead?.name === name) {
            //toggle sortOrder
            currentSortOrder =
                sortOrder === sortOrderOptions.ASC
                    ? sortOrderOptions.DESC
                    : sortOrderOptions.ASC
        }
        const tableData = this.applySortRule(
            option,
            currentSortOrder,
        )

        this.setState({
            activeSortedHead: option,
            sortOrder: currentSortOrder,
            tableData,
        })
    }

    applySortRule = ({ refKey, rule, type }, sortOrder) => {
        const { alphaNumeric, date } = rule
        const { tableData } = this.state
        let data = []
        if (alphaNumeric) {
            // sort based on alphaNumeric
            data = tableData.sort(
                this.compare(refKey, type, sortOrder),
            )
        }
        if (date) {
            // sort based on date
            data = tableData.sort(
                this.compare(refKey, type, sortOrder, true),
            )
        }

        return data
    }

    compare = (
        property,
        type,
        sortOrderOption,
        isDate = false, // default compare by alphanumeric
    ) => {
        let sortOrder = 1
        if (sortOrderOption === sortOrderOptions.DESC) {
            sortOrder = -1
        }
        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            let result = 0
            if (isDate) {
                result =
                    new Date(a[property]) -
                    new Date(b[property])
            } else {
                if (type === 'custom') {
                    result = a[property][
                        'value'
                    ].localeCompare(b[property]['value'])
                } else {
                    if (typeof a[property] === 'number') {
                        result = a[property]
                            .toString()
                            .localeCompare(
                                b[property].toString(),
                                undefined,
                                { numeric: true },
                            )
                    } else {
                        result = a[property].localeCompare(
                            b[property],
                        )
                    }
                }
            }

            return result * sortOrder
        }
    }

    setActiveSortedOption = (option, sortOrder) => {
        this.setState({
            activeSortedHead: option.name,
            sortOrder,
        })
    }

    getHeaderKeys = () => {
        const { headers } = this.props
        const filterKeys = headers
            .map(({ refKey, type }) => ({
                refKey,
                type,
            }))
            .filter((item) => item !== '-1')

        return filterKeys
    }

    render() {
        const {
            tableData,
            headers,
            access,
            selectedUserType,
        } = this.props
        if (!access) {
            access = {
                view: true,
                edit: true,
                status: true,
            }
        }
        const {
            onActionViewClick,
            onActionEditClick,
            onActionStatusClick,
            onActionFollowup,
            onActionReschduled,
            onActionCancel,
            isAppointment,
        } = this.props

        const headerRefKeys = this.getHeaderKeys()

        return (
            <table className="custom_table">
                <TableHeader
                    className="custom_table__header"
                    headerOption={headers}
                    toggleSort={this.toggleSort}
                />
                <TableRows
                    tableData={tableData}
                    headerRefKeys={headerRefKeys}
                    access={access}
                    selectedUserType={selectedUserType}
                    onActionViewClick={onActionViewClick}
                    onActionEditClick={onActionEditClick}
                    onActionStatusClick={
                        onActionStatusClick
                    }
                    isAppointment={isAppointment}
                    onActionFollowup={onActionFollowup}
                    onActionReschduled={onActionReschduled}
                    onActionCancel={onActionCancel}
                />
            </table>
        )
    }
}

export default Table
