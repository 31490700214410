import CameraSvg from '../assests/Provider detail/icon_camera.svg'
import Inclinic from '../assests/Provider detail/icon_inclinic.svg'

export const appointmentTypes = {
    'tele-consultant': {
        label: 'Telehealth',
        icon: CameraSvg,
    },
    'in-clinic': { label: 'In-clinic', icon: Inclinic },
}

export const appointmentFlowTypes = {
    NEW: 'new',
    FOLLOW_UP: 'follow-up',
    RESCHEDULE: 'reschedule',
}

export const specialityTypes = {
    NON_BEHAVIORAL: 'non-behavioral',
    BEHAVIORAL: 'behavioral',
    THERAPY: 'therapy',
}

export const STATUS_CODE = {
    PROPOSED: 'proposed',
    PENDING: 'pending',
    BOOKED: 'booked',
    ARRIVED: 'arrived',
    FULFILLED: 'fulfilled',
    CANCELLED: 'cancelled',
    NOSHOW: 'noshow',
    OPEN: 'open',
    ENTERED_IN_ERROR: 'entered-in-error',
    CHECKED_IN: 'checked-in',
    WAITLIST: 'waitlist',
}

export const APPOINTMENT_TYPE = {
    IN_CLINIC: 'in-clinic',
    TELE_CONSULTANT: 'tele-consultant',
}

export const INTERECT_WITH_DR = {
    CHILD: 'child',
    ADULT: 'adult',
}

export const BOOKING_TYPE = {
    CHECKUP: 'checkup',
    EMERGENCY: 'emergency',
    FOLLOWUP: 'followup',
    ROUTINE: 'routine',
    WALKIN: 'walkin',
}
