import moment from 'moment'
import { extendMoment } from 'moment-range'
import {
    APPOINTMENT_TYPE,
    DATE_FORMAT,
    SLOT_TYPE,
    TIMEOFF_STATUS,
    TIME_FORMAT,
    VIEW,
} from './constant'

const moment_extend = extendMoment(moment)

export function getDate(str) {
    return moment(str, DATE_FORMAT).toDate()
}

export function getTimeoffTitle(status) {
    switch (status) {
        case TIMEOFF_STATUS.PENDING:
            return 'Time-Off Requested'
        case TIMEOFF_STATUS.ACCEPTED:
            return 'Time-Off'
        default:
            return ''
    }
}

export const getWeekdays = () => moment.weekdaysShort()

export function convertUtcToTz(str, tz) {
    return !tz
        ? moment(
              moment
                  .utc(str, `${DATE_FORMAT} ${TIME_FORMAT}`)
                  .format(),
          )
        : moment(
              moment
                  .utc(str, `${DATE_FORMAT} ${TIME_FORMAT}`)
                  .format(),
          ).tz(tz)
}

export function getSlotTypeColor(slot_type) {
    switch (slot_type) {
        case SLOT_TYPE.EXCLUSIVE:
            return '#B39DDB7E'
        case SLOT_TYPE.SHARED:
            return '#BBDEFB9F'
        case SLOT_TYPE.OPEN:
            return '#B2DFDBA9'
        case APPOINTMENT_TYPE.IN_CLINIC:
            return '#b0bce4'
        case APPOINTMENT_TYPE.TELE_HEALTH:
            return '#d1c4e9'
    }
}

export function generateRange({
    start_date,
    end_date,
    interval = 'days',
    step = 1,
}) {
    let output = []

    const dates = moment_extend.range(start_date, end_date)

    for (let date of dates.by(interval, { step })) {
        output = [...output, date]
    }

    return output
}

export function generateSlots(range) {
    let output = []
    let { start_date, end_date } = range

    if (
        range.repeat_type === VIEW.DAY ||
        range.repeat_type === VIEW.WEEK
    ) {
        const working_days =
            range.days_of_week
                ?.split(',')
                ?.map((d) => d.trim()) ??
            moment.weekdaysShort()
        const dates = moment_extend.range(
            start_date,
            end_date,
        )
        for (let date of dates.by('days', {
            step: range?.repeat_number ?? 1,
        })) {
            if (working_days.includes(date.format('ddd'))) {
                output = [...output, date]
            }
        }

        return output
    } else if (range.repeat_type === VIEW.MONTH) {
        if (range.day_number) {
            const dates = moment_extend.range(
                start_date,
                end_date,
            )
            for (let date of dates.by('month', {
                step: range?.repeat_number ?? 1,
            })) {
                if (
                    date.isSameOrAfter(
                        start_date,
                        end_date,
                    ) ||
                    date.isSameOrBefore(
                        start_date,
                        end_date,
                    )
                ) {
                    output = [...output, date]
                }
            }

            return output
        } else if (
            Object.keys(range.day_of_month)?.length > 0
        ) {
            const start_date_tz = start_date
                .clone()
                .utcOffset(range.tz_offset)
            const end_date_tz = end_date
                .clone()
                .utcOffset(range.tz_offset)

            const months = moment_extend.range(
                start_date_tz,
                end_date_tz,
            )

            for (let month of months.by('month', {
                step: range?.repeat_number ?? 1,
            })) {
                const start_month = month
                    .clone()
                    .startOf('month')

                const end_month = month
                    .clone()
                    .endOf('month')

                const dates = moment_extend.range(
                    start_month,
                    end_month,
                )
                let index = 0
                let weekDay = moment()

                for (let date of dates.by('days')) {
                    if (
                        date.format('ddd') ===
                        range.day_of_month.day_name
                    ) {
                        index++
                        if (
                            range.day_of_month
                                .day_week_number === index
                        ) {
                            weekDay = date
                                .clone()
                                .set(
                                    'hour',
                                    start_date_tz.get(
                                        'hour',
                                    ),
                                )
                                .set(
                                    'minutes',
                                    start_date_tz.get(
                                        'minutes',
                                    ),
                                )
                                .set(
                                    'seconds',
                                    start_date_tz.get(
                                        'seconds',
                                    ),
                                )
                            break
                        }
                    }
                }

                output = [...output, weekDay.clone().utc()]
            }

            return output
        }
    } else {
        throw new Error('Invalid repeat_type')
    }

    return output
}

export function generateSuffix(i) {
    switch (i) {
        case 1:
            return 'first'
        case 2:
            return 'second'
        case 3:
            return 'third'
        case 4:
            return 'fourth'
        case 5:
            return 'fifth'
    }
}

export function getWeekInfo({
    date,
    start_date,
    end_date,
}) {
    let index = 0
    const dates = moment_extend.range(start_date, end_date)
    for (let day of dates.by('days')) {
        if (day.format('ddd') === date.format('ddd')) {
            index++
        }

        if (
            day.format('MM-DD-YYYY') ===
            date.format('MM-DD-YYYY')
        ) {
            break
        }
    }

    return {
        day_number: date.date(),
        day_week_number: index,
        day_name: date.format('ddd'),
        date,
    }
}

export function getDayOfWeek({
    day_of_month,
    start_date,
    end_date,
}) {
    const dates = moment_extend.range(start_date, end_date)

    for (let date of dates.by('days')) {
        if (
            moment(moment.utc(date).format()).format(
                'ddd',
            ) === day_of_month.day_name &&
            weekOfMonth(date) ===
                day_of_month.day_week_number
        ) {
            return date
        }
    }

    return null
}

export function weekOfMonth(date) {
    let weekInYearIndex = date.week()
    if (date.year() !== date.weekYear()) {
        weekInYearIndex =
            date.clone().subtract(1, 'week').week() + 1
    }
    const weekIndex =
        weekInYearIndex -
        moment.utc(date).startOf('month').week() +
        1

    return weekIndex
}

export function isWorkingDay(date, timeoffs) {
    if (
        timeoffs.some((timeoff) =>
            moment(timeoff.date).isSame(
                moment(date),
                'day',
            ),
        )
    ) {
        return {
            className: 'time-off-day',
            style: {
                backgroundColor: '#E3E3E3',
            },
        }
    } else {
        return {}
    }
}

export const customSlotPropGetter = (date) => {
    return {
        className: 'slot',
        style: {
            minHeight: '10vh',
        },
    }
}

export function renderDate(args) {
    switch (args.view) {
        case 'month':
            return moment(args.date).format('MMM YYYY')

        case 'week':
            return `${moment(args.date)
                .startOf('week')
                .format('DD MMM YYYY')} - ${moment(
                args.date,
            )
                .endOf('week')
                .format('DD MMM YYYY')}`

        case 'day':
            return moment(args.date).format(
                'ddd, DD MMM YYYY',
            )
    }
}
