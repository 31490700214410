import { Box, Button, Dialog, Divider, Grid, createStyles, makeStyles } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import cross_icon from '../../../../assests/images/cross_icon.svg'
import peopleGroup from '../../../../assests/images/people.svg'
import { useNotification } from '../../../Appointments/MaterialUi/Notification'
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiPaper-root': {
                // height: '389px',
                position: 'relative',
            },
            '& .MuiDialogContent-root': {
                overflow: 'auto',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '481px',
            },
        },
        modalBody: {
            // width: '481px',
            // height: '380px',
            background: 'white',
            borderColor: '#FFF',
            // paddingLeft: '26px',
            paddingRight: '17px',
            borderRadius: '8px',
            overflowY: 'auto',
            '&:focus': {
                outline: 'none', // Remove the focus outline
            },
        },
        label2: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        labelStyle: {
            color: ' #303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%' /* 22.4px */,
            marginBottom: '10px',
        },
        typoGraphy: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        select: {
            '& .MuiTypography-root': {
                color: '#303E4E !important',
            },
        },
        labletxt: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px' /* 125% */,
        },
    }),
)

const SharedContactModal = ({
    isOpenModalContact,
    contactModalClose,
    patientInfo,
    SubmitFunction,
    isLoading,
    phone_number,
    heading,
    height = '',
}) => {
    const notification = useNotification()
    const classes = useStyles()

    const handleSubmit = () => {
        SubmitFunction()
    }

    const cancelModal = () => {
        contactModalClose()
    }
    return (
        <Dialog
            style={{ pointerEvents: isLoading ? 'none' : '' }}
            open={isOpenModalContact}
            fullWidth
            className={classes.root}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '780px',
                },
            }}>
            <Box className={classes.modalBody} style={{ height: height || '380px' }}>
                {' '}
                {/* Set width */}
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        // border:"2px solid green"
                        // alignItems: 'center',
                    }}>
                    <Box style={{ marginTop: '15px' }}>
                        <img
                            src={cross_icon}
                            style={{ cursor: 'pointer' }}
                            onClick={contactModalClose}
                        />
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // border:"2px solid green"
                        // alignItems: 'center',
                    }}>
                    <Box className={classes.typoGraphy}>{heading}</Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // border:"2px solid green"
                        // alignItems: 'center',
                    }}>
                    <img src={peopleGroup} />
                </Box>
                <Grid container>
                    <Grid style={{ marginLeft: '44px', marginRight: '12px' }}>
                        <span className={classes.labletxt}>I</span>{' '}
                        <span className={classes.typoGraphy}>
                            {patientInfo?.first_name} {patientInfo?.last_name}
                        </span>{' '}
                        <span className={classes.labletxt}>
                            understand I consent to receive information about my care from Telemed2U
                            at{' '}
                        </span>
                        <span className={classes.typoGraphy}>{phone_number}</span>
                        <br />
                        <br />
                        <span className={classes.labletxt}>
                            By agreeing to this consent, others may have access to information
                            regarding my care.
                        </span>
                    </Grid>

                    <Grid item xs={12} style={{ paddingLeft: '15px' }}>
                        <Divider
                            style={{ marginTop: '67px', color: '#EAECF0', marginTop: '15px' }}
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        spacing={2}
                        style={{ marginTop: '15px',marginLeft: '44px', marginRight: '12px', justifyContent: 'center',alignItems: 'center',display: 'flex'}}>
                        <Grid item xs={6} style={{ marginTop: '3px',display: 'flex', justifyContent: 'right'  }}>
                            <Button
                                variant="contained"
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '192px',
                                    height: '34px',
                                    background: '#FFF',
                                    borderRadius: '5px',
                                    color: '#5571C6',
                                    border: '1px solid #5571C6',
                                    textTransform: 'none',
                                    pointerEvents: isLoading ? 'none' : '',
                                }}
                                onClick={cancelModal}>
                                {'Cancel'}
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            style={{ display: 'flex', justifyContent: 'left', marginTop: '2px' }}>
                            <LoadingButton
                                onClick={() => handleSubmit()}
                                loading={isLoading}
                                type="submit"
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '192px',
                                    height: '34px',
                                    background: '#5571C6',
                                    borderRadius: '5px',
                                    color: '#FFF',
                                    textTransform: 'none',
                                }}>
                                {'Agree'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Button onClick={closeModal} sx={{ mt: 2 }}>
Close Modal
</Button> */}
            </Box>
        </Dialog>
    )
}
export default SharedContactModal
