/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react'
import {
    connect,
    useDispatch,
    useSelector,
} from 'react-redux'

const VideoParticipant = ({
    participant,
    isCheck,
    isMute,
    isActive,
    disabledVideo,
}) => {
    const [videoTracks, setVideoTracks] = useState([])
    const [audioTracks, setAudioTracks] = useState([])
    const [styles, setStyles] = useState({})

    const dispatch = useDispatch()
    const videoRef = useRef()
    const audioRef = useRef()

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null)

    useEffect(() => {
        setVideoTracks(
            trackpubsToTracks(participant.videoTracks),
        )
        setAudioTracks(
            trackpubsToTracks(participant.audioTracks),
        )

        const trackSubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((videoTracks) => [
                    ...videoTracks,
                    track,
                ])
            } else if (track.kind === 'audio') {
                setAudioTracks((audioTracks) => [
                    ...audioTracks,
                    track,
                ])
            }
        }

        const trackUnsubscribed = (track) => {
            if (track.kind === 'video') {
                setVideoTracks((videoTracks) =>
                    videoTracks.filter((v) => v !== track),
                )
            } else if (track.kind === 'audio') {
                setAudioTracks((audioTracks) =>
                    audioTracks.filter((a) => a !== track),
                )
            }
        }

        participant.on('trackSubscribed', trackSubscribed)
        participant.on(
            'trackUnsubscribed',
            trackUnsubscribed,
        )

        return () => {
            setVideoTracks([])
            setAudioTracks([])
            participant.removeAllListeners()
        }
    }, [participant])

    useEffect(() => {
        const videoTrack = videoTracks[0]
        if (videoTrack) {
            videoTrack.attach(videoRef.current)
            return () => {
                videoTrack.detach()
            }
        }
    }, [videoTracks, disabledVideo])

    useEffect(() => {
        const audioTrack = audioTracks[0]
        if (audioTrack) {
            audioTrack.attach(audioRef.current)
            return () => {
                audioTrack.detach()
            }
        }
    }, [audioTracks])

    return (
        <div>
            <video
                className={`${
                    isCheck
                        ? 'video-participant'
                        : 'videoRemoteParticipant'
                } ${isActive ? 'active' : ''}`}
                ref={videoRef}
                autoPlay={true}
                style={styles}
            />
            <span id="show_sub"></span>
            <audio
                id="patient_audio"
                ref={audioRef}
                autoPlay={true}
            />
        </div>
    )
}

export default connect()(VideoParticipant)
