import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import TeleVolumnIcon from '../../icons/TeleVolumnIcon';
import TeleMuteVolumnIcon from '../../icons/TeleMuteVolumnIcon';
import TeleHighVolumnIcon from '../../icons/TeleHighVolumnIcon';
import { useAppState } from '../../state';
import { Settings } from '../../state/settings/settingsReducer';

const useStyles = makeStyles(()=>({
  buttons: {
    marginTop: '10px',
    marginBottom: '4px',
    padding: '0px',
    background: 'rgba(3, 12, 37, 0.75)',
    width: '50px',
    height: '50px',
    borderRadius: '18px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
    '&:hover': {
        background: 'rgba(34,43,55,0.5) !important',
    },
    
},
box_volumn: {
  padding:'16px 0',
  background: 'rgba(3, 12, 37, 0.75)',
  borderRadius: '18px',
  width: 50,

}
}))

const PrettoSlider = styled(Slider)({
  color: 'white !important',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid white',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function VerticalVolumn() {
  const classes = useStyles()
  const {  settings, dispatchSetting } = useAppState();
  const [openVolumn, setOpenVolumn] = React.useState(false)
  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const handleOpenVolumn = () => {
    setOpenVolumn(prev => true)
  }
  const handleCloseVolumn = () => {
    setOpenVolumn(prev => false)
  }
  
  const handleChangeVolume = (e: any) => {    
    dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as number });
  }

  return (
    <Grid container alignItems="center" direction="column" onMouseEnter={handleOpenVolumn} onMouseLeave={handleCloseVolumn}>
      {openVolumn && <Grid container alignItems ="center" direction="column" className={classes.box_volumn}>

        <TeleHighVolumnIcon />
        <Box sx={{ height: 200, marginTop: '16px' }}>
          <PrettoSlider
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: 'slider-vertical',
              },
            }}
            step={0.01}
            min={0}
            max={1}
            name="volume"
            orientation="vertical"
            value={settings.volume}
            onChange={handleChangeVolume}
            aria-label="Temperature"
            onKeyDown={preventHorizontalKeyboardNavigation}
          />
        </Box>

      </Grid>}

      <Button className={classes.buttons} onClick={() => {
        handleChangeVolume({ target: { value: settings.volume === 0 ? 0.5 : 0, name: "volume" } })
      }}>
            {settings.volume === 0 ? <TeleMuteVolumnIcon /> : <TeleVolumnIcon />}
        </Button>
    </Grid>
   
  );
}
