import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "600px",
    minHeight: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 32px)",
    },
    "& .inputSelect": {
      width: "calc(100% - 35px)",
    },
  },
  error_text:{
    color: 'white',
    marginLeft: 10,
    fontSize: 19,
  },
  header: {
    // background: "#263238",
    color: "#000000",
    fontSize: 19,
    fontWeight: 500,
  },
  closeIcon: {
    cursor: "pointer",
  },
  form: {
    width: "100%",
    marginTop: "1rem",
  },
  textInput: {
    width: "100%",
    color: "#777777",
    marginBottom: "2rem",
  },
  error_relationship: {
    marginLeft: 14,
    marginRight: 14,
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: 4,
    width: '100%', 
  },
  textSelectEl: {
    width: "100%",
    color: "#777777",
  },
  label: {
    fontSize: "14px",
    color: '#303E4E',
    paddingBottom: "0.5rem",
    width: "100%",
  },
  redText: {
    color: "red",
  },
  button: {
    background: "#5571C6",
    margin: "auto",
    width: "8rem",
    color: "#fff",
    marginBottom: "2rem",
    marginTop: "2rem",

    "&:hover": {
      background: "#5571C6",
    },
  },
  paper: {
    [theme.breakpoints.down("xs")]: {
      margin: "16px",
    },
  },
  notchedOutline: {
    borderColor: `#E9ECF1 !important`,
  }
}));
