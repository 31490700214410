import {
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import userImg from '../../../assests/images/erika_provider.png'
import {
    getClinicDetails,
    resetClinicDetails,
} from '../../../store/actions/clinic'
import {
    capitalizeFirstLetter,
    getLoggedInUserId,
} from '../../../utilities'
import '../MainProfile/MainProfile.scss'
import ClinicProfileEdit from './ClinicProfileEdit'

class ClinicProfile extends Component {
    state = {
        isEdit: false,
        isCreate: false,
        isView: false,
        isProfile: false,
        isDialog: false,
    }

    componentDidMount() {
        const {
            isEditMode,
            isCreateMode,
            isViewMode,
            isUserProfile,
            isProfile,
        } = this.props

        this.setState({
            isEdit: !!isEditMode,
            isCreate: !!isCreateMode,
            isView: !!isViewMode,
            isUserProfile,
            isProfile,
        })

        // get clinicDetails data
        this.fetchClinicDetails()
    }
    componentWillUnmount() {
        const {
            actions: { resetClinicDetails },
        } = this.props
        resetClinicDetails()
    }

    fetchClinicDetails = () => {
        const {
            isCreateMode,
            isUserProfile,
            userId,
            clinicDetails,
            actions: { getClinicDetails },
            isProfile,
        } = this.props

        const clinicId = isUserProfile
            ? userId
            : getLoggedInUserId()

        if (
            (isUserProfile &&
                !isCreateMode &&
                !Object.keys(clinicDetails).length) ||
            isProfile
        ) {
            getClinicDetails(clinicId)
        }
    }

    handleEdit = () => {
        const { isEdit, isView } = this.state
        this.setState({ isEdit: !isEdit, isView: !isView })
    }
    handleCloseEdit = () => {
        const { isEdit, isView } = this.state
        this.setState({ isEdit: !isEdit, isView: false })
        this.props.toggleToUserList()
    }

    render() {
        const { isCreate, isView } = this.state

        const { clinicDetails, toggleToUserList } =
            this.props

        return !isCreate &&
            !Object.keys(clinicDetails).length ? (
            <CircularProgress className="loader" />
        ) : (
            <>
                <Grid
                    container
                    className="profile-main-wrap">
                    {' '}
                    {isView ? (
                        <>
                            <Grid
                                item
                                xs={12}
                                className="bg-tab"></Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                className="tabs-main dFlex"
                                style={{
                                    padding: '1em 2em',
                                    marginBottom: '65vh',
                                }}>
                                <Grid
                                    item
                                    xs={5}
                                    className="dFlexVc">
                                    <img
                                        src={userImg}
                                        alt="Profile"
                                        className="profile-img"
                                    />
                                    <Grid className="profile-name-wrap-clinic clinic-add-prof">
                                        <Typography
                                            variant="h1"
                                            className="main-name">{`${capitalizeFirstLetter(
                                            clinicDetails?.clinic_name,
                                        )}`}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <ClinicProfileEdit
                            clinicDetails={clinicDetails}
                            isCreate={isCreate}
                            onSaveCallback={
                                toggleToUserList
                            }
                            onEditCallback={
                                this.handleCloseEdit
                            }
                        />
                    )}
                </Grid>
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        clinic: { clinicDetails },
    } = state

    return { clinicDetails }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    getClinicDetails,
                    resetClinicDetails,
                },
                dispatch,
            ),
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicProfile)
