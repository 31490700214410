import Swal from 'sweetalert2'

const customSwal = Swal.mixin({
    customClass: {
        confirmButton: 'swal-btn swal-btn-white',
        cancelButton: 'swal-btn swal-btn-blue',
        text: 'swal-text',
        htmlContainer: 'swal-html-container',
    },
})

export default ({
    title,
    text,
    icon,
    confirmButtonText,
    cancelButtonText,
    onConfirm,
    onDeny,
    width,
    reverseButtons,
}) => {
    customSwal
        .fire({
            title,
            text,
            icon,
            showCancelButton: true,
            confirmButtonText,
            cancelButtonText,
            reverseButtons,
            width: width || 572,
            customClass: 'custom-swal',
            heightAuto: false,
            // showCloseButton: true
        })
        .then((result) => {
            if (result.isConfirmed) {
                onConfirm && onConfirm()
            } else {
                onDeny && onDeny()
            }
        })
}
