import React from 'react';

export default function AvatarJoinRoomIcon({width = 273, height =265}) {
  return (
    <svg width={width} height={height} viewBox="0 0 274 265" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="137.5" cy="136" r="116" fill="url(#paint0_linear_0_3)"/>
<circle cx="148.5" cy="133" r="124.5" stroke="url(#paint1_linear_0_3)"/>
<circle cx="134.5" cy="127" r="124.5" stroke="url(#paint2_linear_0_3)"/>
<circle cx="133" cy="132.5" r="132" stroke="url(#paint3_linear_0_3)"/>
<defs>
<linearGradient id="paint0_linear_0_3" x1="197.917" y1="20" x2="76.6" y2="223" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="0.768998" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_0_3" x1="191" y1="38" x2="-3.49999" y2="212" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_0_3" x1="246" y1="185" x2="-17.5" y2="95.5" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_0_3" x1="-106.5" y1="54.5" x2="273.5" y2="235.5" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

    
  );
}
