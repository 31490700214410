import React from 'react'
import Booking from './PatientAppointment/Booking'
import HeaderNew from '../Header/HeaderNew'
import AppointmentDetails from '../Dashboard/AppointmentDetails'
import StepperComponents from './PatientAppointment/StepperComponentsIndex'

export default function PatientAppointment() {
    return (
        <>
            <StepperComponents />
        </>
    )
}
