import React from 'react'

export default function IconMinisize() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.71979 5.96667L7.06645 10.3133C7.57979 10.8267 8.41979 10.8267 8.93312 10.3133L13.2798 5.96667"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
