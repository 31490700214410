import * as actionType from '../actionType'
import {
    setLoading,
    setError,
    setErrorMessage,
} from './loader'
import {
    getPatientByUserId,
    getPatientList,
    getPatientListForUsers,
    updatePatientStatus as updatePatientStatusService,
    editPatientProfile,
    getPatientProfileById,
    savePatientProfile,
} from '../../services/Patient/patient'

export const setPatientDetails = (value) => {
    return {
        type: actionType.SET_PATIENT_DETAIL,
        value,
    }
}

export const setPatientList = (value) => ({
    type: actionType.SET_PATIENT_LIST,
    value,
})

export const setSearchPatientList = (value) => {
    return {
        type: actionType.SET_SEARCH_PATIENT_LIST,
        value,
    }
}

export const setUpdatePatientStatus = (value) => {
    return {
        type: actionType.UPDATE_SEARCH_PATIENT_STATUS,
        value,
    }
}

export const setEditPatientProfile = (value) => {
    return {
        type: actionType.SET_EDIT_PATIENT_PROFILE,
        value,
    }
}

export const setPatientProfile = (value) => {
    return {
        type: actionType.SET_PATIENT_PROFILE,
        value,
    }
}

export const resetPatientProfile = () => {
    return {
        type: actionType.RESET_PATIENT_PROFILE,
    }
}

export const getPatientDetails = (userId) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getPatientByUserId(userId)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setPatientDetails(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getPatientProfile = (userId) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getPatientProfileById(userId)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setPatientProfile(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const searchPatientList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getPatientListForUsers(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setSearchPatientList(res.data))
            })
            .catch((err) => {
                console.log(err, 'err')
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setSearchPatientList({}))
            })
    }
}

export const updatePatientStatus = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        updatePatientStatusService(payload)
            .then((res) => {
                dispatch(setLoading(false))

                // update row user status based on userId
                dispatch(setUpdatePatientStatus(payload))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const handleEditPatientProfile = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setErrorMessage(''))
        editPatientProfile(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setEditPatientProfile(res.data))
                dispatch(getPatientProfile(payload?.id))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setErrorMessage(err?.data.message))
            })
    }
}

export const handleSavePatientProfile = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setErrorMessage(''))
        savePatientProfile({ ...payload })
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setEditPatientProfile(res.data))
                onSuccess && onSuccess(res)
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setErrorMessage(err?.data?.error))
            })
    }
}

export const searchPatient = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getPatientList(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setPatientList(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setPatientList([]))
            })
    }
}
