import { Grid } from '@material-ui/core'
import React from 'react'
import Main from './Main'

function PatientInsurance() {
    return (
        <Grid conatiner>
            <Main />
        </Grid>
    )
}

export default PatientInsurance
