import moment from 'moment'
import * as Yup from 'yup'
import { VIEW } from '../constant'

export const INITIAL_VALUES = {
    timezone: '',
    slot_type: '',
    clinic_id: '',
    current_date: moment(),
    start_date: null,
    end_date: null,
    start: '',
    end: '',
    is_repeat: '',
    repeat_number: 1,
    repeat_type: VIEW.DAY,
    days_of_week: [moment().format('ddd')],
    day_number: 0,
    days_of_month: {},
    weekdays: [],
    clinics: [],
    month_types: [],
    month_type: 0,
}

export const validationSchema = Yup.object().shape({
    timezone: Yup.string().required('Timezone is required'),
    slot_type: Yup.string().required(
        'Slot type is required',
    ),
    clinic_id: Yup.string().when('slot_type', {
        is: 'exclusive',
        then: Yup.string().required(
            'Clinic Name is required',
        ),
    }),
    start_date: Yup.string().required(
        'Start Date is required',
    ),
    end_date: Yup.string().required('End Date is required'),
    start: Yup.string().required('Start Time is required'),
    end: Yup.string().required('End Time is required'),
    is_repeat: Yup.string().required(
        'Repeat Type is required',
    ),
    repeat_number: Yup.string().when('is_repeat', {
        is: 'true',
        then: Yup.string()
            .required('Repeat Frequency is required')
            .test(
                'repeat_number',
                'Repeat Frequency must be greater than 0',
                (value) => {
                    return value > 0
                },
            )
            .when('repeat_type', {
                is: VIEW.DAY,
                then: Yup.string().test(
                    'repeat_number',
                    'Repeat Frequency must be less than 31',
                    (value) => {
                        return value < 31
                    },
                ),
            })
            .when('repeat_type', {
                is: VIEW.WEEK,
                then: Yup.string().test(
                    'repeat_number',
                    'Repeat Frequency must be less than equal to 7',
                    (value) => {
                        return value <= 4
                    },
                ),
            })
            .when('repeat_type', {
                is: VIEW.MONTH,
                then: Yup.string().test(
                    'repeat_number',
                    'Repeat Frequency must be less than equal to 12',
                    (value) => {
                        return value <= 12
                    },
                ),
            })
            .when('repeat_type', {
                is: VIEW.YEAR,
                then: Yup.string().test(
                    'repeat_number',
                    'Repeat Frequency must be less than equal to 5',
                    (value) => {
                        return value <= 5
                    },
                ),
            }),
    }),
    repeat_type: Yup.string().when('is_repeat', {
        is: 'true',
        then: Yup.string().required(
            'Repeat Interval is required',
        ),
    }),
    days_of_week: Yup.array().when(
        ['is_repeat', 'repeat_type'],
        {
            is: (is_repeat, repeat_type) =>
                is_repeat === 'true' &&
                repeat_type === VIEW.WEEK,
            then: Yup.array()
                .min(1, 'Please select at least 1 day')
                .required('Repeat Interval is required'),
        },
    ),
})
