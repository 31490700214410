/* eslint-disable no-use-before-define */
import * as actionType from '../actionType'

const initialState = {
    clinicSearchList: [],
    clinicDetails: {},
    searchMeta: {
        currentPage: 1,
        totalCount: 0,
    },
    clinicEditSuccessMsg: '',
}

const clinic = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_SEARCH_CLINIC_LIST: {
            const {
                page,
                totalCount: totalClinicRecord,
                clinics,
            } = action.value
            const clinicSearchList = clinics?.length
                ? formatClinicListSearchResult(clinics)
                : []
            const totalCount =
                Math.ceil(totalClinicRecord / 10) ?? 0
            return {
                ...state,
                clinicSearchList,
                searchMeta: {
                    currentPage: page ?? 1,
                    totalCount,
                },
            }
        }
        case actionType.SET_CLINIC_DETAIL: {
            return {
                ...state,
                clinicDetails: action.value,
            }
        }
        case actionType.RESET_CLINIC_DETAIL: {
            return {
                ...state,
                clinicDetails: {},
            }
        }
        case actionType.SET_EDIT_CLINIC_DETAIL: {
            return {
                ...state,
                clinicEditSuccessMsg: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

const formatClinicListSearchResult = (clinicList) =>
    clinicList.map((clinicList) => {
        const {
            clinic_name,
            add1,
            add2,
            city,
            state,
            zip,
            id: user_id, //user_id because tablerow need user_id
            is_active,
        } = clinicList

        return {
            clinic_name,
            add1,
            add2,
            city,
            state,
            zip,
            user_id,
            is_active,
        }
    }) ?? []

export default clinic
