import React, { Component } from 'react'
import {
    getLoggedInUserRole,
    getLoggedInUserId,
} from '../../utilities'
import {
    Dropdown,
    Button as ButtonComponent,
    Table,
    SearchInput,
} from '../custom'
import LoadingPage, {
    LoaderType,
} from '../../utilities/loading-page'
import {
    UserOptions,
    getAllUserOptions,
    UserRoles,
    getUserListAccess,
} from '../../constants/user-roles'

import backIcon from '../../assests/images/back button_appointment form.svg'
import { Pagination } from '@material-ui/lab'

//profile pages
import PatientProfile from '../Profiles/PatientProfile'
import ProviderProfile from '../Profiles/ProviderProfile'
import TM2UAdminProfile from '../Profiles/TM2UAdmin'
import './UserComponent.scss'
import { withRouter } from 'react-router'
import { ProfileModes } from '../../constants/profileMode'
import ClinicAdminProfile from '../Profiles/ClinicAdminProfile'
import ClinicAdminList from '../../containers/ClinicAdmin/ClinicAdminList'
import ClinicProfile from '../Profiles/ClinicProfile'
import Swal from 'sweetalert2'
import { TablePagination } from '@material-ui/core'
import { Typography } from '@material-ui/core'
const PATIENT_HEADERS = [
    {
        refKey: 'patientName',
        name: 'Patient Name',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'dob',
        name: 'DoB',
        isSortable: true,
        rule: { alphaNumeric: false, date: true },
    },
    {
        refKey: 'phoneNumber',
        name: 'Phone No.',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'email',
        name: 'Email',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'associatedClinics',
        name: 'Associated Clinics',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: '-1',
        name: 'Actions',
        isSortable: false,
    },
]

const PROVIDER_HEADERS = [
    {
        refKey: 'providerName',
        name: 'Provider Name',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'speciality',
        name: 'Specialty',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'phoneNumber',
        name: 'Phone No.',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'email',
        name: 'Email',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'npiNumber',
        name: 'NPI Number',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'associatedClinics',
        name: 'Associated Clinics',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: '-1',
        name: 'Actions',
        isSortable: false,
    },
]
const CLINICAL_ADMIN_HEADERS = [
    {
        refKey: 'clinic_name',
        name: 'Clinic Name',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'add1',
        name: 'Address Line 1',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'add2',
        name: 'Address Line 2',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'city',
        name: 'City',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'state',
        name: 'State',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'zip',
        name: 'Zipcode',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: '-1',
        name: 'Actions',
        isSortable: false,
    },
]
const STAFF_HEADERS = [
    {
        refKey: 'staffName',
        name: 'Name',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'role_name',
        name: 'Role',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'phoneNumber',
        name: 'Phone No.',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: 'email',
        name: 'Email',
        isSortable: true,
        rule: { alphaNumeric: true, date: false },
    },
    {
        refKey: '-1',
        name: 'Actions',
        isSortable: false,
    },
]

class UserComponent extends Component {
    state = {
        selectedUserType: UserOptions.PATIENT,
        goBack: false,
        createProfile: false,
        viewProfile: false,
        editProfile: false,
        userId: null,
        historyCount: 0,
        currentPage: 1,
        searchText: '',
        resetSearch: false,
        isProvider: false,
    }

    componentWillMount() {
        const params = this.props.history.location.state

        if (!!params && params.mode) {
            this._redirectToProfile({
                role: params.role,
                userId: params.userId,
                createProfile:
                    params.mode === ProfileModes.CREATE,
                viewProfile:
                    params.mode === ProfileModes.VIEW,
                editProfile:
                    params.mode === ProfileModes.EDIT,
                isProvider:
                    params.role === UserRoles.PROVIDER,
                selectedUserType:
                    this._mapUserSelectedTypeWithRole(
                        params.role,
                    ),
            })
        }
    }

    componentDidMount() {
        /* const { patientSearchList } = this.props;
        if (!patientSearchList?.length) {
            this.searchProfileList();
        } */
        this.searchProfileList()
    }

    _mapUserSelectedTypeWithRole = (role) => {
        if (role === UserRoles.PATIENT) {
            return UserOptions.PATIENT
        } else if (role === UserRoles.PROVIDER) {
            return UserOptions.PROVIDER
        } else if (role === UserRoles.CLINIC_CORDINATION) {
            return UserOptions.CLINICAL_ADMIN
        } else if (
            role === UserRoles.SUPERVISOR ||
            role === UserRoles.TM2U_ADMIN ||
            role === UserRoles.TM2U_SUPER_ADMIN
        ) {
            return UserOptions.STAFF
        }
    }

    _redirectToProfile = (params) => {
        this.setState(params)
        this.toggleBackButton()
    }

    searchProfileList = () => {
        const {
            selectedUserType,
            currentPage,
            searchText,
        } = this.state

        const {
            actions: {
                searchPatientList,
                searchProviderList,
                // searchStaffingList,
                searchStaffListResult,
                searchClinicList,
                getClinicAdminProfileDetail,
            },
        } = this.props
        if (selectedUserType === UserOptions.PATIENT) {
            searchPatientList({
                page: currentPage,
                name: searchText,
            })
        }

        if (selectedUserType === UserOptions.PROVIDER) {
            searchProviderList({
                page: currentPage,
                filterString: searchText,
            })
        }

        /* if (selectedUserType === UserOptions.STAFF) {
            searchStaffingList({
                page: currentPage,
                filterString: searchText,
            });
        } */
        if (selectedUserType === UserOptions.STAFF) {
            searchStaffListResult({
                page: currentPage,
                filterString: searchText,
            })
        }
        if (
            selectedUserType === UserOptions.CLINICAL_ADMIN
        ) {
            const currentLoggedInUserRole =
                getLoggedInUserRole()
            const userId = getLoggedInUserId()
            if (
                currentLoggedInUserRole ===
                UserRoles.CLINIC_CORDINATION
            ) {
                getClinicAdminProfileDetail(userId)
            } else {
                searchClinicList({
                    page: currentPage,
                    filterString: searchText,
                })
            }
        }
    }

    handleDropdownChange = (value) => {
        //request data from api by passing based on user id and roles
        this.setState(
            {
                searchText: '',
                selectedUserType: value,
                currentPage: 1,
                goBack: false,
            },
            this.searchProfileList,
        )
    }

    handleAddButton = (event) => {
        // redirect to respective profile view page
        this.setState(
            {
                createProfile: true,
                viewProfile: false,
                editProfile: false,
                userId: null,
            },
            this.renderIndividualProfile,
        )
        this.toggleBackButton()
    }

    getHeader = () => {
        const { selectedUserType } = this.state

        switch (selectedUserType) {
            case UserOptions.PATIENT:
                return PATIENT_HEADERS
            case UserOptions.PROVIDER:
                return PROVIDER_HEADERS
            case UserOptions.STAFF:
                return STAFF_HEADERS
            case UserOptions.CLINICAL_ADMIN:
                return CLINICAL_ADMIN_HEADERS
            default:
                break
        }
    }

    handleActionViewClick = (userId, provider) => {
        const isProvider = provider ? true : false
        // redirect to respective profile view page
        const { selectedUserType } = this.state
        this.setState(
            {
                createProfile: false,
                viewProfile: true,
                userId,
                editProfile: false,
                isProvider: isProvider,
            },
            this.renderIndividualProfile,
        )
        this.toggleBackButton()
    }

    handleActionEditClick = (userId) => {
        // redirect to respective profile page
        this.setState(
            {
                createProfile: false,
                viewProfile: false,
                userId,
                editProfile: true,
            },
            this.renderIndividualProfile,
        )
        this.toggleBackButton()
    }

    handleActionStatusClick = (userId, isActive) => {
        // redirect to respective profile page
        const { selectedUserType } = this.state

        const {
            actions: {
                updatePatientStatus,
                updateProviderStatus,
                updateStaffStatus,
                updateClinicStatus,
            },
        } = this.props

        Swal.fire({
            title: `Are you sure you want to ${
                isActive ? 'deactivate' : 'activate'
            } this ${
                selectedUserType === 'Patients'
                    ? 'Patient'
                    : selectedUserType === 'Providers'
                    ? 'Provider'
                    : selectedUserType
            }?`,
            text: '',
            showCancelButton: true,
            confirmButtonColor: '#5571c6',
            cancelButtonColor: '#eee',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                if (
                    selectedUserType === UserOptions.PATIENT
                ) {
                    updatePatientStatus({
                        userId,
                        status: !isActive,
                    })
                }

                if (
                    selectedUserType ===
                    UserOptions.PROVIDER
                ) {
                    updateProviderStatus({
                        userId,
                        status: !isActive,
                    })
                }
                if (
                    selectedUserType === UserOptions.STAFF
                ) {
                    updateStaffStatus({
                        userId,
                        status: !isActive,
                    })
                }
                if (
                    selectedUserType ===
                    UserOptions.CLINICAL_ADMIN
                ) {
                    updateClinicStatus({
                        id: userId,
                        status: !isActive,
                    })
                }
            }
        })
    }

    handlePageChange = (e, page) => {
        const { currentPage } = this.state
        if (currentPage !== page + 1) {
            this.setState(
                { currentPage: page + 1 },
                this.searchProfileList,
            )
        }
    }

    toggleBackButton = () => {
        const { goBack } = this.state
        this.setState(
            { goBack: !goBack },
            this.searchProfileList,
        )
    }

    renderIndividualProfile = () => {
        const {
            selectedUserType,
            viewProfile,
            editProfile,
            createProfile,
            userId,
            isProvider,
        } = this.state

        const props = {
            isUserProfile: true,
            userId,
            isViewMode: viewProfile,
            isCreateMode: createProfile,
            isEditMode: editProfile,
            isProvider: isProvider,
            selectedUserType,
            toggleToUserList: this.toggleBackButton,
        }

        // eslint-disable-next-line default-case
        switch (selectedUserType) {
            case UserOptions.PATIENT:
                return <PatientProfile {...props} />
            case UserOptions.PROVIDER:
                return <ProviderProfile {...props} />
            case UserOptions.STAFF:
                return (
                    <TM2UAdminProfile
                        {...props}
                        isStaff={true}
                    />
                )
            case UserOptions.CLINICAL_ADMIN:
                if (viewProfile) {
                    // return <ClinicAdminProfile {...props} />;
                    return <ClinicAdminList {...props} />
                } else {
                    return <ClinicProfile {...props} />
                }
        }
    }

    handleSearch = (searchTerm) => {
        this.setState(
            {
                searchText: searchTerm,
                currentPage: 1,
                goBack: false,
            },
            this.searchProfileList,
        )
    }

    // get  searched data from reducer to display in table
    getSearchData = () => {
        const { selectedUserType } = this.state
        const {
            patientSearchList,
            providerSearchList,
            staffSearchList,
            clinicSearchList,
            clinicAdminProfile,

            patientSearchMeta: {
                totalCount: patientTotalCount,
            },
            providerSearchMeta: {
                totalCount: providerTotalCount,
            },
            staffSearchMeta: {
                totalCount: staffTotalCount,
            },
            clinicSearchMeta: {
                totalCount: clinicTotalCount,
            },
        } = this.props

        console.log(patientSearchList, 'patientSearchList')

        const searchData = {
            dataRows: [],
            totalCount: 0,
        }
        if (UserOptions.PATIENT === selectedUserType) {
            searchData.dataRows = patientSearchList
            searchData.totalCount = patientTotalCount
        }

        if (UserOptions.PROVIDER === selectedUserType) {
            searchData.dataRows = providerSearchList
            searchData.totalCount = providerTotalCount
        }

        if (UserOptions.STAFF === selectedUserType) {
            searchData.dataRows = staffSearchList

            searchData.totalCount = staffTotalCount
        }
        if (
            UserOptions.CLINICAL_ADMIN === selectedUserType
        ) {
            const currentLoggedInUserRole =
                getLoggedInUserRole()
            if (
                currentLoggedInUserRole ===
                UserRoles.CLINIC_CORDINATION
            ) {
                searchData.dataRows =
                    clinicAdminProfile &&
                    clinicAdminProfile?.clinic
                        ? [
                              {
                                  ...clinicAdminProfile.clinic,
                                  user_id:
                                      clinicAdminProfile
                                          .clinic.id,
                              },
                          ]
                        : []
                searchData.totalCount = clinicAdminProfile
                    ? 1
                    : 0
            } else {
                searchData.dataRows = clinicSearchList
                searchData.totalCount = clinicTotalCount
            }
        }
        return searchData
    }

    getUserOptions(role) {
        let options = getAllUserOptions()
        if (role === UserRoles.CLINIC_CORDINATION) {
            options = ['Patient', 'Provider', 'Clinic']
        }
        if (role === UserRoles.PROVIDER) {
            options = ['Patient']
        }
        return options
    }

    render() {
        const {
            selectedUserType,
            goBack,
            currentPage,
            resetSearch,
            searchText,
        } = this.state

        const { loading } = this.props

        const { dataRows, totalCount } =
            this.getSearchData()

        const headers = this.getHeader()

        const parentClass = 'user_container'
        const currentLoggedInUserRole =
            getLoggedInUserRole()
        const userOptions = this.getUserOptions(
            currentLoggedInUserRole,
        )
        const access = getUserListAccess(
            currentLoggedInUserRole,
            selectedUserType,
        )

        // rbac condition for button to shown for loggedin user
        const shouldShowButton =
            (UserOptions.PATIENT === selectedUserType &&
                [
                    UserRoles.TM2U_ADMIN,
                    UserRoles.TM2U_SUPER_ADMIN,
                    UserRoles.CLINIC_CORDINATION,
                ].includes(currentLoggedInUserRole)) ||
            (UserOptions.PROVIDER === selectedUserType &&
                currentLoggedInUserRole ===
                    UserRoles.TM2U_SUPER_ADMIN) ||
            (UserOptions.STAFF === selectedUserType &&
                currentLoggedInUserRole ===
                    UserRoles.TM2U_SUPER_ADMIN) ||
            (UserOptions.CLINICAL_ADMIN ===
                selectedUserType &&
                currentLoggedInUserRole ===
                    UserRoles.TM2U_SUPER_ADMIN)

        return (
            <div className={parentClass}>
                <div className={`${parentClass}__header`}>
                    <div
                        className={`${parentClass}__header-wrapper`}>
                        {goBack && (
                            <div
                                className={`${parentClass}__header-back_button`}
                                onClick={
                                    this.toggleBackButton
                                }
                                role="presentation">
                                <img
                                    className={`${parentClass}__header-back_button-icon`}
                                    src={backIcon}
                                    alt="back"
                                />
                                {/* <span
                                    className={`${parentClass}__header-back_button-label `}>
                                    Back
                                </span> */}
                            </div>
                        )}
                        <Typography
                            variant="h2"
                            className={`${parentClass}__header-label`}>
                            {selectedUserType}
                        </Typography>
                    </div>

                    <div
                        className={`${parentClass}__header__actions`}>
                        <Dropdown
                            id="user-dropdown"
                            className={`${parentClass}__header-user_dropdown`}
                            defaultValue={selectedUserType}
                            onChange={
                                this.handleDropdownChange
                            }
                            options={userOptions}
                        />

                        <SearchInput
                            placeholder={`Search by ${selectedUserType} Name ${
                                selectedUserType ===
                                'Patient'
                                    ? 'or DOB'
                                    : ''
                            }`}
                            onChange={this.handleSearch}
                            reset={resetSearch}
                            value={searchText}
                        />

                        {shouldShowButton && (
                            <ButtonComponent
                                onClick={
                                    this.handleAddButton
                                }>
                                {`Add ${selectedUserType}`}
                            </ButtonComponent>
                        )}
                    </div>
                </div>

                <div className={`${parentClass}__body`}>
                    {!goBack ? (
                        dataRows.length ? (
                            <>
                                {loading && (
                                    <LoadingPage
                                        type={
                                            LoaderType.LINEAR
                                        }
                                    />
                                )}

                                <Table
                                    tableData={dataRows}
                                    headers={headers}
                                    access={access}
                                    selectedUserType={
                                        selectedUserType
                                    }
                                    onActionViewClick={
                                        this
                                            .handleActionViewClick
                                    }
                                    onActionEditClick={
                                        this
                                            .handleActionEditClick
                                    }
                                    onActionStatusClick={
                                        this
                                            .handleActionStatusClick
                                    }
                                />
                                {totalCount > 0 ? (
                                    // <Pagination
                                    //     className={`${parentClass}__body__pagination`}
                                    //     count={totalCount}
                                    //     page={currentPage}
                                    //     onChange={
                                    //         this
                                    //             .handlePageChange
                                    //     }
                                    //     shape="rounded"
                                    // />
                                    <TablePagination
                                        count={totalCount}
                                        page={
                                            currentPage - 1
                                        }
                                        onPageChange={
                                            this
                                                .handlePageChange
                                        }
                                        component="div"
                                        rowsPerPage={10}
                                        rowsPerPageOptions={[]}
                                    />
                                ) : null}
                            </>
                        ) : loading ? (
                            <LoadingPage />
                        ) : (
                            <div>
                                {`No ${selectedUserType} records found.`}
                            </div>
                        )
                    ) : (
                        this.renderIndividualProfile()
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(UserComponent)
