import {
    FormHelperText,
    makeStyles,
    Theme,
} from '@material-ui/core'

export const ScheduleWindowHeader = makeStyles(
    (theme: Theme) => ({
        container: {
            // height: '100px',
            background: '#F4F4F6',
            fontFamily: 'Rubik !important',
            // borderBottom: '1px solid #E4E7E9',
            display: 'flex',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            padding: '1rem',
            flexDirection: 'column',
        },
        allConsultationMarkWrap: {
            width: '88%',
            marginLeft: '6%',
            marginTop: 'auto',
            marginBottom: '2em',
            borderRadius: '5px',
            boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        },
        text: {
            fontWeight: 'bold',
            marginRight: 2,
        },
        closeChatWindow: {
            cursor: 'pointer',
            display: 'flex',
            float: 'right',
            background: 'transparent',
            border: '0',
        },
        profile: {
            display: 'flex',
            flexDirection: 'row',
        },
        imag: {
            width: 60,
            height: 60,
        },
        imag1: {
            width: 20,
            height: 20,
            marginRight: '0.2em',
        },
        root: {
            // width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // flexBasis: '33.33%',
            flexShrink: 0,
            color: 'white',
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        topH: {
            backgroundColor: '#5571C6',
        },
        statusBar: {
            width: '200px',
            display: 'inline-table',
            verticalAlign: 'top',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
            fontFamily: 'Rubik !important',
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        buttonWrapper: {
            gap: '8px',
            justifyContent: 'center',
        },
        scrollableView: {
            overflowY: 'scroll',
            float: 'left',
            height: '20.5em',
            position: 'relative',
            marginTop: '1em',
        },
        mainProfileContainer: {
            margin: '0.4rem',
            // paddingRight: "10px",
            alignItems: 'center',
            fontFamily: 'Rubik !important',
        },
        pr8: {
            paddingRight: '16px',
        },
        textRight: {
            textAlign: 'right',
        },
        textCenter: {
            textAlign: 'center',
        },
        mt8: {
            marginTop: '16px',
        },
        mb8: {
            marginBottom: '16px',
        },
        m4: {
            margin: '8px',
        },
        mt4: {
            marginTop: '8px',
        },
        mt2: {
            marginTop: '4px',
        },
        w100: {
            width: '100%',
        },
        p1: {
            padding: '2px',
        },
        pl2: {
            paddingLeft: '4px',
        },
        pr2: {
            paddingRight: '4px',
        },
        borderR5: {
            borderRadius: '5px',
        },
        mr2: {
            marginRight: '4px',
        },
        horizontalLine: {
            width: '100%',
        },
        font18: {
            fontSize: '18px',
            fontFamily: 'Rubik !important',
        },
        font16: {
            fontSize: '16px',
            fontFamily: 'Rubik !important',
            textTransform: 'capitalize',
        },
        font14: {
            fontSize: '14px',
            fontFamily: 'Rubik !important',
        },
        font12: {
            fontSize: '12px',
            fontFamily: 'Rubik !important',
        },
        font20: {
            fontSize: '20px',
            fontFamily: 'Rubik !important',
            color: '#fff',
        },
        font11: {
            fontSize: '11px',
            fontFamily: 'Rubik !important',
        },
        font10: {
            fontSize: '10px',
            fontFamily: 'Rubik !important',
        },
        primaryColor: {
            color: '#5571C6',
            fontFamily: 'Rubik !important',
        },
        greyColor: {
            color: '#a9a9a9',
            fontFamily: 'Rubik !important',
        },
        badge: {
            backgroundColor: 'green',
            color: '#ffffff',
            fontFamily: 'Rubik !important',
        },
        AdmitButton: {
            backgroundColor: '#5571C6',
            color: '#ffffff',
            fontFamily: 'Rubik !important',
            height: '35px',
            // width: '89px',
            borderRadius: '4px',
            '&:hover': {
                background: '#5571C6',
            },
        },
        removeButton: {
            height: '35px',
            // width: '89px',
            borderRadius: '4px',
            border: '1px solid #5571C6',
            color: '#5571C6',
        },
        markBtn: {
            height: '35px',
            borderRadius: '4px',
            border: '1px solid #fff',
            color: '#fff',
        },
        noPatient: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '5px',
        },
        wordTextWrap: {
            wordWrap: 'break-word',
        },
        dFlex: {
            display: 'flex',
        },
        ml19: {
            marginLeft: '19px',
        },
        consultationStatusBg: {
            background: '#5B9798',
        },
        allConsultationWrap: {
            display: 'inline-block',
            transform: 'translateY(10vh)',
        },
        consultationStatusWrap: {
            padding: '10px 18px',
            alignItems: 'center',
            fontFamily: 'Rubik !important',
        },
        mt20: {
            marginTop: '20px',
        },
        mt1em: {
            marginTop: '1em',
        },
        consultationFormDialogModal: {
            padding: '20px',
            boxSizing: 'content-box',
            [theme.breakpoints.down('xs')]: {
                padding: '0px',
            },
            '& .MuiDialogContent-root-550': {
                padding: '0px',
            },
            '.MuiAccordionDetails-root-572': {
                padding: '0px 16px 16px 16px',
            },
        },
        closeIcon: {
            color: '#5571C6',
            float: 'right',
            cursor: 'pointer',
        },
        ptM1: {
            [theme.breakpoints.down('xs')]: {
                paddingTop: '1em',
            },
        },
        consultationHead: {
            fontSize: '32px',
            fontWeight: 'bold',
            color: '#132044',
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px',
                color: '#fff',
            },
        },
        consultationLabel: {
            fontSize: '18px',
            color: '#516983',
        },
        mt40: {
            marginTop: '40px',
        },
        radioGrpWrap: {
            width: '50%',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('xs')]: {
                width: '90%',
            },
        },
        width50: {
            width: '45%',
        },
        btnBorder: {
            border: '1px solid #5571C6',
            padding: '0.5em 4em',
        },
        submitBtn: {
            background: '#5571C6',
            color: '#fff',
            padding: '0.5em 4em',

            '&:hover': {
                background: '#5571C6',
            },
        },
        followupBtn: {
            background: '#5571C6',
            color: '#fff',
            padding: '0.1em 2em',
            float: 'right',
            margin: '1em 1em 1em 0',
            fontSize: '1em',
            '&:hover': {
                background: '#5571C6',
            },
        },
        mr30: {
            marginRight: '30px',
        },
        tableRoot: {
            border: '1px solid #B0BCE4',
            width: '99%',
        },
        trBorderRight: {
            borderRight: '1px solid #B0BCE4',
            borderBottom: '1px solid #B0BCE4',
        },
        fontWeight: {
            fontWeight: 'bold',
        },
        dFlexSB: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        mr2em: {
            marginRight: '2rem',
        },
        tableSubmit: {
            color: '#5571C6',
            cursor: 'pointer',
            fontWeight: 'bold',
        },
        dialogPaperMain: {
            '& .MuiDialog-paper-8': {
                margin: '0px',
            },
        },
        accordionLabel: {
            color: '#516983',
            fontSize: '14px',
            margin: '0px',
            fontWeight: 'bold',
        },
        placeholderText: {
            opacity: 0.5,
        },
        accordionValue: {
            color: '#13191F',
            fontSize: '14px',
            marginTop: '5px',
            // marginBottom:'0px'
        },
        '& .MuiAccordionSummary-content': {
            flexDirection: 'column',
        },
    }),
)
