import React from 'react'
import { Grid, MenuItem, Select } from '@material-ui/core'
import { Field } from 'formik'
import { useSelector } from 'react-redux'

const ProviderAssitantProviderView = ({
    assistantProvider,
    index,
    fieldArrayProps,
    setFieldValue,
    initialAssistantProvider,
    allValues,
    supervisor_id,
}) => {
    const { push, remove } = fieldArrayProps
    const activeProviderList = useSelector(
        (state) => state.provider.providerList,
    )
    activeProviderList &&
        activeProviderList.map((item) => {
            ;(item['value'] = item.user_id),
                (item[
                    'name'
                ] = `${item.first_name} ${item.last_name}`)
        })
    const cleanProviderList = activeProviderList.filter(
        (item) => item.first_name !== '',
    )
    let finalActiveProviderList = []
    if (supervisor_id) {
        finalActiveProviderList = cleanProviderList.filter(
            (item) => item.value !== supervisor_id,
        )
    }
    const top = `${index * 100}px`
    return (
        <Grid container spacing={2} key={index}>
            <Grid
                item
                xs={5}
                className={'mb1'}
                style={{ paddingRight: '0px' }}>
                <Field
                    name={`assitant_provider[${index}]`}
                    component={Select}
                    fullWidth
                    variant="outlined"
                    placeholder="Reporing Provider"
                    onChange={(e) => {
                        setFieldValue(
                            `assitant_provider[${index}]`,
                            e.target.value,
                        )
                    }}
                    value={assistantProvider}>
                    <MenuItem disabled value="none">
                        <span className="opacity05">
                            {'Select Provider'}
                        </span>
                    </MenuItem>
                    {finalActiveProviderList.map((item) => (
                        <MenuItem
                            value={item.value}
                            key={item.value}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Field>
            </Grid>

            <Grid item xs={2} className="flex">
                {index !== 0 &&
                    index === allValues.length - 1 && (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() =>
                                push(
                                    initialAssistantProvider,
                                )
                            }>
                            +
                        </button>
                    )}

                {index === 0 &&
                    (allValues.length === 1 ? (
                        <>
                            <button
                                type="button"
                                className="plus-wrap"
                                onClick={() =>
                                    push(
                                        initialAssistantProvider,
                                    )
                                }>
                                +
                            </button>
                            <button
                                type="button"
                                className="plus-wrap"
                                onClick={() =>
                                    setFieldValue(
                                        `assitant_provider[${index}]`,
                                        'none',
                                    )
                                }>
                                -
                            </button>
                        </>
                    ) : (
                        <button
                            type="button"
                            className="plus-wrap"
                            onClick={() => remove(index)}>
                            -
                        </button>
                    ))}
                {index !== 0 && (
                    <button
                        type="button"
                        className="plus-wrap "
                        onClick={() => remove(index)}>
                        -
                    </button>
                )}
            </Grid>
        </Grid>
    )
}

export default ProviderAssitantProviderView
