import * as yup from 'yup'
import { errorMessages } from './constants'

export const validation = yup.object().shape({
    person_filling: yup.string().default(['option1', 'option2', 'option3']).nullable(),
    first_name: yup
        .string()
        .required(errorMessages.firstName)
        .trim()
        .matches(/^[aA-zZ\s]+$/, errorMessages.firstName),
    last_name: yup
        .string()
        .required(errorMessages.lastName)
        .trim()
        .matches(/^[aA-zZ\s]+$/, errorMessages.firstName),
    preferred_name: yup
        .string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, errorMessages.firstName),
    dob: yup.string().required(errorMessages.dob).nullable().typeError(''),
    mobile: yup.string().required(errorMessages.phoneNumber),
    email: yup.string().email(errorMessages.validEmailId).required(errorMessages.validEmailId),
    gender: yup
        .string()
        .required()
        .required(errorMessages.gender)
        .test('gender', 'Please select Gender', (answers) => answers !== 'none'),
    address1: yup.string().required(errorMessages.address_line_1),
    address2: yup.string(),
    city: yup.string().required(errorMessages.city),
    state: yup.string().required(errorMessages.state),
    zip_code: yup.string().required(errorMessages.zip_code),
})
