import axios from 'axios'
import { config } from '../../../constants'

let token = localStorage.getItem('token')

const methods = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept',
        Authorization: `Bearer ${token}`,
    },
}

async function submitConsultationStatus(data: any) {
    // dev.telemed2u.com/login-service/appointment/consultationClose
    try {
        return await axios.post(
            `${config.dev.baseURL}/appointment/consultationClose`,
            data,
            methods,
        )
    } catch (err) {
        throw err
    }
}

async function submitConnectivityIssue(data: any) {
    // dev.telemed2u.com/login-service/appointment/consultationClose
    try {
        return await axios.post(
            `${config.dev.baseURL}/appointment/technical-issue`,
            data,
            methods,
        )
    } catch (err) {
        throw err
    }
}

async function getProviderAppointmentList(id: any) {
    // dev.telemed2u.com/login-service/appointment/findByproviderId/id
    try {
        return await axios.get(
            `${config.dev.baseURL}/appointment/findByproviderId/${id}`,
            methods,
        )
    } catch (err) {
        throw err
    }
}

async function getPatientCount(data: any) {
    // dev.telemed2u.com/login-service/appointment/findByproviderId/id
    try {
        return await axios.post(
            `${config.dev.baseURL}/getParticipant`,
            data,
            methods,
        )
    } catch (err) {
        throw err
    }
}

async function submitConsulationTracking(data: any) {
    // dev.telemed2u.com/login-service/appointment/findByproviderId/id
    try {
        return await axios.post(
            `${config.dev.baseURL}/providers/update-live-consultation`,
            data,
            methods,
        )
    } catch (err) {
        throw err
    }
}

async function submitSurveyForm(data: any) {
    // dev.telemed2u.com/login-service/appointment/findByproviderId/id
    try {
        return await axios.post(
            `${config.dev.baseURL}/dynamic-forms/send-survey-link`,
            data,
            methods,
        )
    } catch (err) {
        throw err
    }
}

async function deleteChatMessages(data: any) {
    let payload = { channel_id: data }
    try {
        return await axios.delete(
            `${config.dev.baseURL}/deleteChat`,
            {
                data: payload,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
    } catch (err) {
        throw err
    }
}

export {
    submitConsultationStatus,
    getProviderAppointmentList,
    getPatientCount,
    submitConsulationTracking,
    submitSurveyForm,
    deleteChatMessages,
    submitConnectivityIssue,
}
