import { Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Box } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { useLocation } from 'react-router-dom'
import {
    LocalAudioTrack,
    LocalVideoTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack,
} from 'twilio-video'
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting'
import usePublications from '../../hooks/usePublications/usePublications'
import useTrack from '../../hooks/useTrack/useTrack'
import ScreenShareIcon from '../../icons/ScreenShareIcon'
import TeleDisableVideoUserIcon from '../../icons/TeleDisableVideoUserIcon'
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator'
import PinIcon from './PinIcon/PinIcon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            height: 175,
            overflow: 'hidden',
            marginBottom: '2em',
            '& video': {
                filter: 'none',
                objectFit: 'contain !important',
            },
            paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
            boxShadow: '0px 5px 16px rgba(39, 46, 65, 0.25)',
            borderRadius: 16,
            background: '#303E4E',
            // [theme.breakpoints.down('sm')]: {
            //   height: theme.sidebarMobileHeight,
            //   width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
            //   marginRight: '8px',
            //   marginBottom: '0',
            //   fontSize: '10px',
            //   paddingTop: `${theme.sidebarMobileHeight - 2}px`,
            // },
        },
        innerContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
        infoContainer: {
            position: 'absolute',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            background: 'transparent',
            top: 0,
        },
        avatarContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
                '& svg': {
                    transform: 'scale(0.7)',
                },
            },
        },
        reconnectingContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(40, 42, 43, 0.75)',
            zIndex: 1,
        },
        screenShareIconContainer: {
            background: 'rgba(0, 0, 0, 0.5)',
            padding: '0.18em 0.3em',
            marginRight: '0.3em',
            display: 'flex',
            '& path': {
                fill: 'white',
            },
        },
        identity: {
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '0.18em 0.3em',
            borderRadius: '6px',
            margin: '4px',
            display: 'flex',
            alignItems: 'center',
        },
        infoRowBottom: {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 5,
            left: 5,
        },
        typeography: {
            color: 'white',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                width: 70,
                fontSize: '0.75rem',
            },
        },
        hideParticipant: {
            display: 'none',
        },
        cursorPointer: {
            cursor: 'pointer',
        },
    }),
)

interface ParticipantInfoProps {
    participant: Participant
    children: React.ReactNode
    onClick?: () => void
    isSelected?: boolean
    isLocalParticipant?: boolean
    hideParticipant?: boolean
}

export default function ParticipantInfo({
    participant,
    onClick,
    isSelected,
    children,
    isLocalParticipant,
    hideParticipant,
}: ParticipantInfoProps) {
    const publications = usePublications(participant)

    const location = useLocation()

    const audioPublication = publications.find((p) => p.kind === 'audio')
    const videoPublication = publications.find((p) => p.kind.includes('video'))

    const isVideoEnabled = Boolean(videoPublication)
    const isScreenShareEnabled = publications.find((p) => p.trackName.includes('screen'))

    const videoTrack = useTrack(videoPublication)
    const isVideoSwitchedOff = useIsTrackSwitchedOff(
        videoTrack as LocalVideoTrack | RemoteVideoTrack,
    )

    const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined
    // console.log("hello",audioTrack);
    const isParticipantReconnecting = useParticipantIsReconnecting(participant)

    const classes = useStyles()
    //removing the random numbers from the name
    const participantIdentityWithoutNumbers = `${participant?.identity?.split('_')[0]} ${
        participant?.identity?.split('_')[1]
    }`

    return (
        <Box
            sx={{ border: 'solid 1px #2c9120' }}
            className={clsx(classes.container, {
                [classes.hideParticipant]: hideParticipant,
                [classes.cursorPointer]: Boolean(onClick),
            })}
            onClick={onClick}
            data-cy-participant={participant.identity}>
            <div className={classes.infoContainer}>
                {/* <NetworkQualityLevel participant={participant} /> */}
                <div className={classes.infoRowBottom}>
                    {isScreenShareEnabled && (
                        <span className={classes.screenShareIconContainer}>
                            <ScreenShareIcon />
                        </span>
                    )}
                    <span className={classes.identity}>
                        <AudioLevelIndicator audioTrack={audioTrack} />
                        <Typography
                            variant="body1"
                            className={classes.typeography}
                            component="span">
                            {participantIdentityWithoutNumbers}{' '}
                            {location?.state?.roomMaster === participantIdentityWithoutNumbers
                                ? `( ${location?.state?.suffix} )`
                                : ''}
                        </Typography>
                    </span>
                </div>
                <div>{isSelected && <PinIcon />}</div>
            </div>
            <div className={classes.innerContainer}>
                {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div className={classes.avatarContainer}>
                        <TeleDisableVideoUserIcon />
                    </div>
                )}
                {isParticipantReconnecting && (
                    <div className={classes.reconnectingContainer}>
                        <Typography variant="body1" className={classes.typeography}>
                            Reconnecting...
                        </Typography>
                    </div>
                )}
                {children}
            </div>
        </Box>
    )
}
