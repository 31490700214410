import { Box, Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import CheckboxOption from '../../../../ClinicalNotes/reusable/CheckboxOptions'

const useStyles = makeStyles(() => ({
    popoverBody: {
        height: 140,
        width: 333,
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        border: '1px solid #D9D9D9',
        borderRadius: 5,
        '& .provider_filter_popover_heading': {
            color: '#667689 !important',
            fontWeight: '500 !important',
            fontSize: '14px !important',
        },
    },
    checkbox_container: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

function ProviderFilter({
    filterProps,
    handleFilterOpenOrClose,
    selectATimeProps,
    onChangeCheckboxValue,
}) {
    const classes = useStyles()
    const { selectATimeValues: value } = selectATimeProps

    return (
        <Popover
            open={Boolean(filterProps?.open)}
            anchorEl={filterProps?.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={() => handleFilterOpenOrClose()}>
            <Box className={classes.popoverBody}>
                <Typography className="provider_filter_popover_heading">Time of Day</Typography>

                <Box className={classes.checkbox_container}>
                    <CheckboxOption
                        checked={value.morning}
                        onChange={(event) =>
                            onChangeCheckboxValue(event?.target?.checked, 'morning')
                        }
                        option={{
                            label: `Morning: 8 AM - 12 PM (${value.timezone || 'Loading...'})`,
                            value: value.morning,
                        }}
                        customStyle={{
                            marginLeft: 5,
                        }}
                    />

                    <CheckboxOption
                        checked={value.afternoon}
                        onChange={(event) =>
                            onChangeCheckboxValue(event?.target?.checked, 'afternoon')
                        }
                        option={{
                            label: `Afternoon: 12 PM - 5 PM (${value.timezone || 'Loading...'})`,
                            value: value.afternoon,
                        }}
                        customStyle={{
                            marginLeft: 5,
                        }}
                    />

                    <CheckboxOption
                        checked={value.evening}
                        onChange={(event) =>
                            onChangeCheckboxValue(event?.target?.checked, 'evening')
                        }
                        option={{
                            label: `Evening: 5 PM - 10 PM (${value.timezone || 'Loading...'})`,
                            value: value.evening,
                        }}
                        customStyle={{
                            marginLeft: 5,
                        }}
                    />
                </Box>
            </Box>
        </Popover>
    )
}

export default ProviderFilter
