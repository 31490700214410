/* eslint-disable react/button-has-type */
import { Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'

const BtnIconTop = ({
    Icon,
    styles,
    background,
    onPress,
}) => {
    return (
        <>
            {Icon ? (
                <button
                    className="icon-svg-btn-top"
                    style={{ background: background }}
                    onClick={onPress}>
                    {Icon}
                </button>
            ) : null}
        </>
    )
}

export default BtnIconTop
