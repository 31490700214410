import { Grid } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import closeIcon from '../../../../../assests/images/icon_close.svg'

const CustomTextField = withStyles({
    root: {
        // '& .MuiOutlinedInput-root': {
        //     '& fieldset': {
        //         borderColor: '#e2e2e1',
        //     },
        // },
    },
})(TextField)

const PrimaryButton = withStyles((theme) => ({
    root: {
        color: '#FFF',
        backgroundColor: theme.palette.primary,
        width: '160px',
    },
}))(Button)

export function ConnectivityModal(props) {
    const parentClass = 'worklist'
    const [issueDescription, setIssueDescription] =
        React.useState('')
    const handleIssueChange = (e) => {
        setIssueDescription(e.target.value)
        props.handleIssueChangeValue(e.target.value)
    }

    return (
        <Modal
            open={props.opened}
            onClose={() => {
                props.onClose()
            }}>
            <div
                className={`${parentClass}__modal__container`}
                style={{ maxHeight: '45%' }}>
                <div
                    className={`${parentClass}__modal__header`}>
                    <Typography
                        id="modal-modal-title"
                        className="worklist__modal__title"
                        variant="inherit"
                        component="h3">
                        <strong>Connectivity Issue</strong>
                    </Typography>

                    <img
                        className={`${parentClass}__modal__cancel-btn`}
                        src={closeIcon}
                        role="presentation"
                        onClick={() => {
                            props.onClose()
                        }}
                        alt="close-icon"
                    />
                </div>
                <div
                    className={`${parentClass}__modal__body`}>
                    <Grid
                        container
                        spacing={4}
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={12}>
                            <FormControl
                                style={{
                                    width: '100%',
                                }}>
                                <Typography
                                    style={{
                                        color: '#516983',
                                        paddingBottom:
                                            '8px',
                                    }}>
                                    <strong>
                                        Please describe the
                                        issue
                                    </strong>
                                </Typography>
                                <CustomTextField
                                    id="remarks"
                                    aria-label="remarks"
                                    name="remarks"
                                    hiddenLabel
                                    size="medium"
                                    variant="outlined"
                                    onChange={
                                        handleIssueChange
                                    }
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                    helperText="Max 500 characters only"
                                    multiline
                                    rows={6}
                                    value={issueDescription}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div
                        className={`${parentClass}__modal__footer`}>
                        <PrimaryButton
                            variant="contained"
                            color="primary"
                            style={{
                                justifyContent: 'center',
                            }}
                            disabled={
                                !issueDescription.length
                            }
                            type="submit"
                            onClick={() => {
                                props.onConnectivityIssueSubmit()
                            }}>
                            Submit
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
