import * as actionType from '../actionType'

const initialState = {
    clinicalNotesList: [],
    totalCount: 0,
}

const clinicalNotes = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_CLINICAL_NOTES_LIST: {
            return {
                ...state,
                clinicalNotesList: action.value,
            }
        }
        case actionType.SET_CLINICAL_NOTES_COUNT: {
            return {
                ...state,
                totalCount: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

export default clinicalNotes
