import { makeStyles, Theme } from '@material-ui/core'

export const ChatsStyle = makeStyles((theme: Theme) => ({
    chats: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        marginTop: '0.2rem',
        marginRight: '1rem',
    },
    chatMessage: {
        maxWidth: '70%',
        display: 'flex',
        flexDirection: 'column',
    },
    chatText: {
        overflowX: 'hidden',
        borderRadius: '5px',
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.8rem',
        fontWeight: 400,
    },
    chatValue: {
        margin: 0,
        opacity: 1,
    },
    chatTime: {
        paddingLeft: '4px',
        fontSize: '0.7rem',
        fontWeight: 600,
        marginTop: 0,
        color: 'gray',
        float: 'right',
    },
    chatAvatar: {
        width: 'auto',
        fontSize: '1rem',
        fontWeight: 500,
    },
    profileImageContainer: {
        display: 'flex',
    },
    marginRight: {
        marginRight: '0.5rem',
    },
    marginLeft: {
        marginLeft: '0.5rem',
    },
    chatInitial: {
        height: '40px',
        width: '40px',
        borderRadius: '25px',
        backgroundColor: '#132044',
        textAlign: 'center',
    },
    nameInitial: {
        color: 'white',
        fontSize: '18px',
        lineHeight: '40px',
    },
}))
