import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import LoadingPage from '../../utilities/loading-page'
import { useHistory, useLocation } from 'react-router-dom'
import { verifyEmail } from '../../services/Login/login'

export default (props) => {
    const location = useLocation()
    const history = useHistory()
    const [message, setMessage] = useState(
        'Verifying email...',
    )
    const [isLoading, setLoading] = useState(true)
    useEffect(async () => {
        const query = new URLSearchParams(location.search)
        const id = query.get('id')
        if (id) {
            try {
                const response = await verifyEmail(`${id}`)
                const { data } = response
                if (
                    data?.hasOwnProperty(
                        'isEmailAlreadyUpdated',
                    )
                ) {
                    setMessage(
                        'Your email has been verified successfully.',
                    )
                    setLoading(false)
                    setTimeout(() => {
                        history.push('/')
                    }, 1000)
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
                setMessage(
                    'Something went wrong, please try again.',
                )
            }
        }
    }, [])
    return (
        <div>
            <Typography
                style={{
                    fontSize: 22,
                    fontWeight: 'bold',
                }}>
                {message}
            </Typography>
            {isLoading && <LoadingPage />}
        </div>
    )
}
