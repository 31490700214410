import {
    Button,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { confirmHomeStateAttestation } from '../../../services/Provider/provider'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
        '& .MuiDialogTitle-root': {
            padding: '16px 8px',
            [theme.breakpoints.up('sm')]: {
                padding: '16px 24px',
            },
        },
        '& .MuiButtonBase-root': {
            padding: '4px 16px',
            [theme.breakpoints.up('sm')]: {
                padding: '6px 16px',
            },
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    text_ct: {
        color: '#303E4E',
        lineHeight: 'normal',
        fontSize: '16px',
        padding: 8,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: '17px',
            padding: '15px 15px 45px',
            lineHeight: '26px',
        },
    },
    btn_cancel: {
        background: '#303E4E',
        width: 'calc((100% - 10px) / 2)',
        fontSize: 15,
        borderRadius: 12,
        color: 'white',
        fontWeight: 'normal',
        marginRight: '10px',
        '&:hover': {
            background: '#143254',
        },
    },
    btn_delete: {
        fontSize: 15,
        width: 'calc((100% - 10px) / 2)',
        borderRadius: 12,
        fontWeight: 'normal',
    },
    footer_btn: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '15px ',
    },
    title_box: {
        color: '#3bbad7',
        fontSize: '25px',
        padding: '15px 15px',
    },
}))

export default function AlertConfirmHomeStateDialog({
    open,
    handleClose,
    encounter_id,
}) {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)

    const handleConfirmHomeState = async (value) => {
        setIsLoading(true)
        if (encounter_id) {
            await confirmHomeStateAttestation(
                encounter_id,
                { is_confirm_location: value },
            )
                .then(() => {
                    console.log('success')
                })
                .catch(() => {
                    console.log('error')
                })
        }
        setIsLoading(false)
        handleClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Typography className={classes.title_box}>
                Notification
            </Typography>
            <Typography className={classes.text_ct}>
                Can you please confirm you are physically
                located in the same state as your home
                address?
            </Typography>
            <Box className={classes.footer_btn}>
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        handleConfirmHomeState(false)
                    }}
                    variant="contained"
                    className={classes.btn_cancel}>
                    No
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={() => {
                        handleConfirmHomeState(true)
                    }}
                    // autoFocus
                    variant="contained"
                    color="primary"
                    className={classes.btn_delete}>
                    Yes
                </Button>
            </Box>
            {/* <DialogActions>
            </DialogActions> */}
        </Dialog>
    )
}
